import { Order } from 'types';

export const mapOrderFromResponse = (queryResponse: any): Order => {
  const { createOrder } = queryResponse;

  return {
    id: createOrder.id,
    orderNumber: createOrder.orderNumber,
    createdAt: createOrder.createdAt,
    status: createOrder.status,
    orderType: {
      ...createOrder.orderType,
    },
    organizationObject: createOrder.organizationObject,
    purchaser: createOrder.purchaser,
  } as Order;
};
