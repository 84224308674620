import { SxProps } from '@mui/material';
import theme from 'themes/theme';

const transparentGrey = 'rgba(0, 0, 0, .28)';

export const BoxStepThroughSxProps: SxProps = {
  borderBottom: '1px solid #DCDCDC',
  minHeight: '82px',
  width: '100%',
};

const buttonBaseSx = {
  fontVariant: 'button',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '27px',
  mr: '16px',
};

export const ButtonStackSxProps: SxProps = {
  boxSizing: 'border-box',
  pr: {
    md: 0,
    lg: '16px',
  },
};

export const CancelButtonSxProps: SxProps = {
  ...buttonBaseSx,
  '&: hover': {
    backgroundColor: 'transparent',
  },
};

export const NextButtonSxProps: SxProps = {
  ...buttonBaseSx,
  '&:hover': {
    backgroundColor: theme.palette.colors.maizeYO,
  },
};

export const StackStepThroughSxProps: SxProps = {
  height: '100%',
  boxSizing: 'border-box',
  pl: {
    md: 0,
    lg: '28px',
  },
};

export const StepperSxProps: SxProps = {
  width: {
    sm: '75%',
    md: '75%',
    lg: '75%',
    xl: '50%',
  },

  '& .MuiStepIcon-root': {
    '&.Mui-active': {
      color: '#1976D2',
    },
    '&.Mui-completed': {
      color: '#1976D2',
    },
  },

  '& .MuiStepLabel-label.Mui-active, & .MuiStepLabel-label.Mui-completed': {
    fontWeight: 600,
  },

  '& .MuiStepIcon-root:not(.Mui-active):not(.Mui-completed)': {
    color: '#939394',
  },
  '& .Mui-disabled .MuiStepLabel-labelContainer': {
    color: '#939394',
  },
  '& .Mui-disabled.MuiStepConnector-root.MuiStepConnector-horizontal': {
    color: transparentGrey,
  },
  '& .Mui-disabled.MuiStepConnector-root.MuiStepConnector-horizontal .MuiStep-root':
    {
      color: transparentGrey,
    },
  '& .Mui-disabled .MuiStepConnector-line': {
    borderColor: transparentGrey,
  },
  '& .MuiStepIcon-root .MuiStepIcon-text': {
    fill: '#ffffff',
  },
  '& .MuiStepIcon-root.Mui-completed .MuiStepIcon-text, & .MuiStepIcon-root.Mui-active .MuiStepIcon-text':
    {
      fontWeight: 'bold',
    },
};
