/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { TextAreaOptionStackSx, TextAreaOptionInputSx } from './styles';
import { RetrievalOptionInputProps } from 'pages/Retrieval/components/ReviewStep/components/RetrievalOptionValueInput/types';

const TextAreaOption = (props: RetrievalOptionInputProps) => {
  const [textValue, setTextValue] = React.useState<string>(
    () => props.retrievalAnswer.optionValue
  );

  const answerTextArea = (optionValue: string = '') =>
    props.answerRetrievalOption({
      optionName: props.name,
      optionValue,
      optionId: props.id,
    });

  const handleTextFieldBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    answerTextArea(event.target.value);
    setTextValue(event.target.value);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTextValue(event.target.value);
  };

  return (
    <Box sx={TextAreaOptionStackSx}>
      <TextField
        helperText={props.prompt}
        aria-label={props.prompt}
        sx={TextAreaOptionInputSx}
        maxRows={5}
        multiline={true}
        onBlur={handleTextFieldBlur}
        onChange={handleChange}
        value={textValue}
      />
    </Box>
  );
};

export default TextAreaOption;
