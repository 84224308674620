import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { type EmployeeFilterState } from './types';

const DEFAULT_EMPLOYEE_FILTER_STATE = {};

export const employeeFilterSlice = createSlice({
  name: 'employeeFilterService',
  initialState: () => DEFAULT_EMPLOYEE_FILTER_STATE as EmployeeFilterState,
  reducers: {
    setEmployeeFilter: (
      state,
      action: PayloadAction<Record<string, string>>
    ) => {
      Object.entries(action.payload).forEach(([field, value]) => {
        state[field as keyof EmployeeFilterState] = value;
      });
    },
    resetField: (state, action: PayloadAction<string>) => {
      state[action.payload as keyof EmployeeFilterState] = undefined;
    },
    resetAllExcept: (state, action: PayloadAction<string>) => {
      Object.keys(state).forEach((field: string) => {
        if (field !== action.payload) {
          state[field as keyof typeof state] = undefined;
        }
      });
    },
    reset: () => DEFAULT_EMPLOYEE_FILTER_STATE,
  },
});
