import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import { TableSx } from './styles';

const ProductTableHeader = (props: { isProcurementToRecipient: boolean }) => {
  return (
    <Table sx={TableSx(props.isProcurementToRecipient)}>
      <TableHead>
        <TableRow>
          <TableCell width="45%" align="left">
            <Typography variant="subtitle1">Items</Typography>
          </TableCell>
          <TableCell align="right">
            <Typography variant="subtitle1">Quantity</Typography>
          </TableCell>
          <TableCell align="center" padding="none">
            <Typography variant="subtitle1">Unit Price</Typography>
          </TableCell>
          <TableCell align="left" padding="none">
            <Typography variant="subtitle1">Total Price</Typography>
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
    </Table>
  );
};

export default ProductTableHeader;
