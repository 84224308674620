import { alpha, styled, SxProps } from '@mui/material/styles';
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro';
import { FALLBACK_LUCK_7S_DATE_STRING } from 'global-constants';

const ODD_OPACITY = 1.0;

export const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  borderRadius: '6px 6px 10px 10px',
  [`& .${gridClasses.row}`]: {
    border: `2px solid transparent`, // removes the "content-shifting" effect when hovering a row
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.colors.grayF7, ODD_OPACITY),
      border: `2px solid ${theme.palette.colors.rockBlue}`,
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.colors.grayF7, ODD_OPACITY),
      border: `2px solid ${theme.palette.colors.rockBlue}`,
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.colors.grayF7, ODD_OPACITY),
        border: `2px solid ${theme.palette.colors.rockBlue}`,
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(theme.palette.colors.grayF7, ODD_OPACITY),
          border: `2px solid ${theme.palette.colors.rockBlue}`,
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        },
      },
    },
    '.MuiDataGrid-cell:focus': {
      outline: 'none',
    },
  },
  '.MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '.MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '.MuiDataGrid-cellCheckbox': {
    outline: 'none',
  },
  '.MuiDataGrid-cellCheckbox:focus': {
    outline: 'none',
  },
  '.MuiDataGrid-cellCheckbox:focus-within': {
    outline: 'none',
  },
  '.MuiDataGrid-footerContainer': {
    borderRadius: '0 0 10px 10px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2)',
  },
  '.MuiDataGrid-columnHeaders': {
    backgroundColor: `${theme.palette.colors.grayFB}`,
    minHeight: '45px !important',
    maxHeight: '45px !important',
    '&.enable-large-header-height': {
      minHeight: '72px !important',
      maxHeight: '72px !important',
    },
  },
  '.MuiDataGrid-pinnedColumnHeaders': {
    backgroundColor: `${theme.palette.colors.grayFB}`,
  },
  '.MuiDataGrid-toolbarContainer': {
    backgroundColor: `${theme.palette.colors.grayFB}`,
  },
  '.MuiButtonBase-root': {
    color: 'colors.strongBlue',
    '&:hover': {
      backgroundColor: 'colors.ghostWhite',
    },
  },
  '.MuiFormLabel-root.Mui-focused': {
    color: 'colors.strongBlue',
  },
  '.MuiDataGrid-columnHeaderTitle': {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
  },
  '& .MuiButtonBase-root.MuiRadio-root': {
    color: 'rgba(0, 0, 0, 0.2);',
  },
  '& .MuiRadio-root.Mui-checked': {
    color: '#517A85',
  },
  // STYLE below used to hide date-type cells that contain the fallback date
  [`& .MuiDataGrid-cellContent[title="${FALLBACK_LUCK_7S_DATE_STRING}"]`]: {
    color: 'transparent',
    cursor: 'default',
    pointerEvent: 'none',
  },
  '& .MuiDataGrid-virtualScroller': {
    '::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      width: '4px',
      height: '5px',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '11px',
      backgroundColor: 'rgba(0,0,0,.5)',
      WebkitBoxShadow: '0 0 1px rgba(255,255,255,.5)',
    },
    overflowX: 'hidden',
    '&.enable-horizontal-scrolling': {
      overflowX: 'auto',
    },
  },
  '& .MuiDataGrid-pinnedColumnHeaders--right': {
    padding: '0 4px',
  },
  '& .MuiDataGrid-columnHeaderCheckbox': {
    '&.hide-select-all-checkbox': {
      visibility: 'hidden',
    },
  },
  '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
}));

export const DataGridSx: SxProps = {
  '& .Mui-checked': {
    color: 'colors.woodSmoke',
  },
  '& .MuiButtonBase-root': {
    color: 'colors.woodSmoke',
    '&:hover': {
      backgroundColor: 'colors.ghostWhite',
    },
  },
  '& .MuiFormLabel-root.Mui-focused': {
    color: 'colors.strongBlue',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
  },
};
