export enum AssetsView {
  DEVICES = 'DEVICES',
  MERCHANDISE = 'MERCHANDISE',
}

// Asset Enums copy/pasted from BFF
export enum AssetDataStatusEnum {
  WIPED = 'Wiped',
  NOT_WIPED = 'Not Wiped',
  NOT_APPLICABLE = 'N/A',
}

export enum AssetColorsEnum {
  ABYSS_BLUE = 'Abyss Blue',
  GOLD = 'Gold',
  GRAPHITE = 'Graphite',
  GRAY = 'Gray',
  MIDNIGHT_BLUE = 'Midnight Blue',
  PLATINUM = 'Platinum',
  RED = 'Red',
  ROSE_GOLD = 'Rose Gold',
  SILVER = 'Silver',
  SPACE_GRAY = 'space gray',
  STEEL_GRAY = 'Steel Gray',
  STORM_GREY = 'Storm Grey',
  WHITE = 'White',
}

export enum AssetTypeEnum {
  ACCESSORY = 'Accessory',
  ALL_IN_ONE_VC_BAR = 'All-in-One VC Bar',
  CELL_PHONE = 'Cell Phone',
  CHARGER = 'Charger',
  CORD = 'Cord',
  CREDIT_CARD = 'Credit Card',
  DESKTOP = 'Desktop',
  HOTSPOT = 'Hotspot',
  KEYS = 'Keys',
  KEY_FOB = 'Key Fob',
  LAPTOP = 'Laptop',
  MOBILE = 'Mobile',
  MONITOR = 'Monitor',
  OCULUS = 'Oculus',
  PERIPHERAL = 'Peripheral',
  PRINTER = 'Printer',
  SWAG = 'Swag',
  SWAG_BOX = 'Swag Box',
  SWAG_KIT_L = 'Swag Kit - L',
  SWAG_KIT_M = 'Swag Kit - M',
  SWAG_KIT_S = 'Swag Kit - S',
  SWAG_KIT_XL = 'Swag Kit - XL',
  SWAG_KIT_XS = 'Swag Kit - XS',
  SWAG_KIT_XXL = 'Swag Kit - XXL',
  SWAG_KIT_XXXL = 'Swag Kit - XXXL',
  TABLET = 'Tablet',
  WEBCAM = 'Webcam',
}
