/* eslint-disable security/detect-object-injection */
/* eslint-disable no-restricted-syntax */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReviewSearchFilterState, ReviewFilterPayload } from './types';
import { RetrievalAssetTypes } from 'pages/Retrieval/constants';

const retrievalAssetTypes = Object.values(RetrievalAssetTypes);

const initialFilterTable = retrievalAssetTypes.reduce(
  (acc: Record<string, boolean>, assetType) => {
    acc[assetType] = false;
    return acc;
  },
  {}
);

const initialState = {
  searchTerm: null,
  filterByLookup: initialFilterTable,
} as ReviewSearchFilterState;

export const reviewSearchFilterSlice = createSlice({
  name: 'reviewSearchFilterSlice',
  initialState: () => initialState,
  reducers: {
    searchByName: (state, action: PayloadAction<string>) => {
      state.filterByLookup = initialFilterTable;
      state.searchTerm = action.payload;
    },
    clearSearchByName: (state) => {
      state.searchTerm = null;
    },
    changeFilter: (state, action: PayloadAction<ReviewFilterPayload>) => {
      const { assetType, checked } = action.payload;
      state.filterByLookup[assetType] = checked;
    },
  },
});
