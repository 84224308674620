import { POPOVER_FOOTER_MAX_HEIGHT } from '../ColumnSelect/styles';

export const FooterContainerSx = {
  display: 'flex',
  height: POPOVER_FOOTER_MAX_HEIGHT,
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  pr: '5%',
  fontWeight: '700',
};

export const IconSx = {
  pr: '2%',
  cursor: 'pointer',
};

export const TextSx = {
  cursor: 'pointer',
};
