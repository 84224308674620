import React from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import Avatar from 'components/Avatar';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import Popover from '@mui/material/Popover';
import SettingsIcon from '@mui/icons-material/Settings';
import Skeleton from '@mui/material/Skeleton';
import { getInitials } from 'services/getInitials';
import { useCollaboratorControllerSearch } from 'services/openapi/apiComponents';
import { UserAvatarProps } from './types';
import {
  AVATAR_SIZE,
  TEST_ID_APP_BAR_AVATAR,
  TEST_ID_APP_BAR_AVATAR_LINK,
  TEST_ID_APP_BAR_AVATAR_DROPDOWN,
} from './constants';
import { NavLinkStyles } from 'global-styles';
import { UserPermissions } from 'global-constants';
import useGetUserPermissions from 'hooks/useGetUserPermissions';
import { UserAvatarPopoverSx } from './styles';
import { NavigationContext } from 'context/NavigationContext';
import { capitalizeWords } from 'services/format';
import { setAuthToken } from 'store/slices';

const UserAvatar = (props: UserAvatarProps) => {
  const navigate = useNavigate();
  const navigator = React.useContext(NavigationContext);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<
    HTMLButtonElement | undefined
  >();
  const [popoverOpen, setPopoverOpen] = React.useState<boolean>(false);
  const userPermissions = useGetUserPermissions();
  const dispatch = useDispatch();

  const { user, isAuthenticated, isLoading, logout, loginWithRedirect } =
    useAuth0();

  const { data, isLoading: isFetchingLoggedInUser } =
    useCollaboratorControllerSearch(
      {
        queryParams: {
          email: user?.email,
        },
      },
      {
        enabled: !!user?.email,
        retry: false,
      }
    );

  const billingVisible = userPermissions.includes(
    UserPermissions.BILLING__VIEW
  );

  const handleOpen = (event: any) => {
    setAnchorEl(event?.currentTarget);
    setPopoverOpen(true);
  };

  const handleClose = () => {
    setPopoverOpen(false);
  };

  const gotoBilling = () => {
    navigate(navigator.pathToBilling());
    handleClose();
  };
  const gotoUserPermissions = () => {
    navigate(navigator.pathToAccountSettings());
    handleClose();
  };

  const handleLogout = () => {
    dispatch(setAuthToken('logged-out'));
  };

  if (!isAuthenticated || isLoading) return <></>;

  const loggedInUserInitial = getInitials(data?.items[0]);

  if (isFetchingLoggedInUser)
    return (
      <Skeleton
        variant="circular"
        animation="wave"
        width={AVATAR_SIZE}
        height={AVATAR_SIZE}
      />
    );

  return (
    <div>
      <NavLink
        to={props.navigator.pathToAccountSettings()}
        style={NavLinkStyles}
        onMouseEnter={handleOpen}
        onClick={handleClose}
        aria-haspopup="true"
        data-testid={TEST_ID_APP_BAR_AVATAR_LINK}
      >
        <Avatar
          text={loggedInUserInitial}
          bgcolor={theme.palette.colors.white}
          width={AVATAR_SIZE}
          height={AVATAR_SIZE}
          data-testid={TEST_ID_APP_BAR_AVATAR}
        />
      </NavLink>
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableRestoreFocus
        sx={UserAvatarPopoverSx}
      >
        <List
          sx={{ width: '250px' }}
          onMouseLeave={handleClose}
          data-testid={TEST_ID_APP_BAR_AVATAR_DROPDOWN}
        >
          <ListItem className="username-dropdown">
            {capitalizeWords(user?.nickname ?? '')}
          </ListItem>
          <ListItem className="user-email-dropdown">{user?.email}</ListItem>
          <Divider />
          <ListItemButton onClick={gotoUserPermissions}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            User Permissions
          </ListItemButton>
          {billingVisible ? (
            <ListItemButton onClick={gotoBilling}>
              <ListItemIcon>
                <PaymentOutlinedIcon />
              </ListItemIcon>
              Billing
            </ListItemButton>
          ) : null}
          <Divider />
          <ListItemButton onClick={handleLogout}>SIGN OUT</ListItemButton>
        </List>
      </Popover>
    </div>
  );
};

export default UserAvatar;
