/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { AssetFilterClearButtonSx } from 'pages/Assets/components/DevicesDatagrid/components/DeviceFilters/styles';
import { AssetDepotFilterProps } from './types';
import * as AssetDepotFilterStyles from './styles';
import { DeviceTypeFilterSettings } from './constants';

const AssetDepotFilter = (props: AssetDepotFilterProps) => {
  const styles = AssetDepotFilterStyles;

  const handleClose = () => {
    props.setChosenAssetDepot('');
  };

  const handleAssetDepotChange = (event: SelectChangeEvent) => {
    const { value: newChosenAssetDepot } = event.target;
    if (!newChosenAssetDepot || newChosenAssetDepot === props.chosenAssetDepot)
      return;
    props.setChosenAssetDepot(newChosenAssetDepot);
  };

  return (
    <Stack sx={styles.AssetDepotFilterStackSx}>
      {props.chosenAssetDepot ? (
        <Tooltip title="Clear" arrow>
          <IconButton
            size="small"
            aria-label="clear asset type filter"
            onClick={handleClose}
            sx={AssetFilterClearButtonSx}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      ) : null}

      <FormControl
        sx={styles.getAssetFilterFormControlSx(
          DeviceTypeFilterSettings.labelId
        )}
      >
        <InputLabel id={DeviceTypeFilterSettings.labelId}>
          {DeviceTypeFilterSettings.label}
        </InputLabel>
        <Select
          value={props.chosenAssetDepot}
          onChange={handleAssetDepotChange}
          sx={styles.AssetFilterSelectSx}
          placeholder={DeviceTypeFilterSettings.placeholder}
          label={DeviceTypeFilterSettings.label}
          labelId={DeviceTypeFilterSettings.labelId}
          id={DeviceTypeFilterSettings.inputId}
        >
          {props.assetDepotOptions.map((assetDepotOption: string) => (
            <MenuItem key={assetDepotOption} value={assetDepotOption}>
              {assetDepotOption}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default AssetDepotFilter;
