import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Modal } from 'components';
import { NewAndImprovedModalProps } from './types';
import classes from './NewAndImprovedModal.module.css';

const NewAndImprovedModal = (props: NewAndImprovedModalProps) => {
  const listCopy = [
    'Add new hires and send equipment directly through our system',
    'Add existing employees to order retrieval boxes to retrieve equipment',
    'Onboard multiple employees and devices at once for a seamless checkout',
  ];

  const fixListItemPadding = (index: number, arr: string[]): boolean =>
    index === arr.length - 1;

  const getListItemKey = (baseString: string) =>
    baseString.slice(0, 100).trimEnd().replaceAll(' ', '-').toLowerCase();

  return (
    <Modal
      open={props.open}
      modalBoxSXProps={{
        display: 'flex',
        flexDirection: 'column',
        height: '507px',
        justifyContent: 'flex-start',
        maxWidth: '100vw',
        width: '1028px',
        '@media (max-width:500px)': {
          height: '100vh',
        },
      }}
    >
      <>
        <Modal.Header
          ModalHeaderSXProps={{
            pt: '48px',
          }}
          ModalHeaderLabelSXProps={{
            '& hr': {
              borderBottom: 0,
              borderLeft: 0,
              borderRight: 0,
              borderTop: '2px solid #f9c54d',
              my: '24px',
              ml: 0,
              width: '108px',
            },
          }}
        >
          <Typography
            sx={{
              color: 'colors.offBlack',
              fontSize: '36px',
              fontWeight: 600,
              lineHeight: '1.14em',
            }}
          >
            Welcome to the new allwhere platform!
          </Typography>
          <Typography sx={{ mt: '8px', color: 'colors.gray70' }}>
            We've made some exciting updates to our marketplace to allow you to
            easily add, save and manage employee profiles.
          </Typography>
          <IconButton
            aria-label="close-new-and-improved-updates"
            onClick={props.handleClose}
            sx={{
              bgcolor: 'theme.palette.colors.gray75',
              position: 'absolute',
              right: '18px',
              top: '18px',
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <Typography
            sx={{
              color: 'colors.offBlack',
              fontSize: '16px',
              lineHeight: '19px',
              pb: '8px',
            }}
          >
            You can now:
          </Typography>
          <List
            sx={{
              pl: 0,
            }}
          >
            {listCopy.map((copy, copyIndex, listIndex) => (
              <ListItem
                key={getListItemKey(copy)}
                disablePadding
                sx={{
                  pb: fixListItemPadding(copyIndex, listIndex) ? 0 : '18px',
                }}
              >
                <ListItemIcon>
                  <Avatar sx={{ bgcolor: 'primary.main' }}>
                    {copyIndex + 1}
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary={copy} sx={{ color: 'colors.gray70' }} />
              </ListItem>
            ))}
          </List>
        </Modal.Body>
      </>
    </Modal>
  );
};

export default NewAndImprovedModal;
