import { SVGProps } from './types';
import { SvgIcon } from '@mui/material';

const SendPaperPlaneSVGIcon = (props: SVGProps) => {
  const colorFill = props?.fill ?? 'none'; //"#131414"
  const opacity = props?.opacity ?? 1.0;
  return (
    <SvgIcon sx={{ marginRight: '8px' }}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_12376_103513"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_12376_103513)">
          <path
            d="M3 20V4L22 12L3 20ZM5 17L16.85 12L5 7V10.5L11 12L5 13.5V17Z"
            fill="#131414"
            fill-opacity={opacity}
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export const SendPaperPlaneOutlined = SendPaperPlaneSVGIcon;

export const SendPaperPlane = () => {
  return <SendPaperPlaneSVGIcon fill="black" />;
};
