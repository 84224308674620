/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import { FeatureFlagNames } from 'enums';
import { nanoid } from '@reduxjs/toolkit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import HomeListItem from 'pages/HomeUpdate/components/HomeListItem';
import TextIconButton from 'components/TextIconButton';
import {
  WHATS_NEW_URL,
  WHATS_NEW_ITEMS,
  WHATS_NEW_TITLE_ID,
  CLIENT_ASSET_FILTER_ITEM,
  ASSET_STATUS_FILTER_ITEM,
} from './constants';
import * as WhatsNewStyles from './styles';

const WhatsNew = () => {
  const styles = WhatsNewStyles;
  const { isFlagOn } = useFeatureFlagService(
    FeatureFlagNames.COMPLEX_CLIENT_DEVICES_FILTERING,
    {}
  );

  const firstItem = isFlagOn()
    ? CLIENT_ASSET_FILTER_ITEM
    : ASSET_STATUS_FILTER_ITEM;

  const myWhatsNewItems = [firstItem, ...WHATS_NEW_ITEMS];

  return (
    <Box sx={styles.HomeWhatsNewBoxSx}>
      <List sx={styles.getHomeWhatsNewListSx(WHATS_NEW_TITLE_ID)}>
        <ListSubheader>
          <Typography variant="h6" id={WHATS_NEW_TITLE_ID}>
            What's new?
          </Typography>

          <TextIconButton
            text="View all"
            ariaLabel="Navigate back to Orders page"
            icon={<ArrowForwardIcon fontSize="small" />}
            onClick={() => window.open(WHATS_NEW_URL, '_blank')}
            textStyles={styles.ViewAllBtnTextStyles}
            endIcon
          />
        </ListSubheader>
        {myWhatsNewItems.map((item) => (
          <React.Fragment key={nanoid()}>
            <HomeListItem {...item} />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default WhatsNew;
