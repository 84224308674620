import { gql } from '@apollo/client';

export const CREATE_PROCESS_DEVICE_ORDER = gql`
  mutation createProcessOrder($purchaserId: ID!, $assetId: ID!) {
    createProcessOrder(
      processOrderCreateInput: { purchaserId: $purchaserId, assetId: $assetId }
    ) {
      id
    }
  }
`;
