/* eslint-disable sonarjs/cognitive-complexity */
import { useTheme } from '@mui/material';
import {
  OrderStatus,
  ClientOrderStatus,
  ClientOrderStatusEnum,
} from 'global-constants';
import { OrderStatusMapping } from 'pages/Orders/constants';
import { capitalizeWords } from 'services';

export const useGetOrderStatusConfig = (
  orderStatus: string,
  {
    showStatusName,
    showClientStatus,
  }: { showStatusName: boolean; showClientStatus: boolean }
) => {
  const theme = useTheme();

  let switchOrderStatus = orderStatus;

  if (showClientStatus && orderStatus in ClientOrderStatus) {
    switchOrderStatus =
      ClientOrderStatus[orderStatus as keyof typeof ClientOrderStatus];
  }

  switch (switchOrderStatus) {
    case OrderStatus.CREATED:
      return {
        text: showStatusName
          ? capitalizeWords(OrderStatus.CREATED)
          : OrderStatusMapping[OrderStatus.CREATED],
        color: theme.palette.colors.butterBlond,
        textColor: '',
        hoverText: 'Order has been placed; allwhere team actioning next step.',
      };
    case 'CANCELED':
    case OrderStatus.CANCELLED:
      return {
        text: showStatusName
          ? capitalizeWords(OrderStatus.CANCELLED)
          : OrderStatusMapping[OrderStatus.CANCELLED],
        color: theme.palette.colors.mercury,
        textColor: '',
        hoverText:
          'Order has been canceled as per approval from company admin.',
      };
    case OrderStatus.COMPLETE:
      return {
        text: showStatusName
          ? capitalizeWords(OrderStatus.COMPLETE)
          : OrderStatusMapping[OrderStatus.COMPLETE],
        color: theme.palette.colors.lushParadise,
        textColor: theme.palette.colors.white,
        hoverText:
          'Procurement: All items and shipments within the order have been delivered to final destination. Retrieval: Retrieval kit has been delivered to final destination.',
      };
    case OrderStatus.EXCEPTION:
      return {
        text: showStatusName
          ? capitalizeWords(OrderStatus.EXCEPTION)
          : OrderStatusMapping[OrderStatus.EXCEPTION],
        color: theme.palette.colors.chiGong,
        textColor: theme.palette.colors.white,
        hoverText:
          'Logistics issue delaying order fulfillment; allwhere actively working to address issue and complete order.',
      };
    case OrderStatus.EXPIRED_PENDING_RECIPIENT_INFORMATION:
      return {
        text: showStatusName
          ? capitalizeWords(OrderStatus.EXPIRED_PENDING_RECIPIENT_INFORMATION)
          : OrderStatusMapping[
              OrderStatus.EXPIRED_PENDING_RECIPIENT_INFORMATION
            ],
        color: theme.palette.colors.mercury,
        textColor: '',
        hoverText:
          'Recipient did not respond to ongoing requests for contact information for over 30 days; no shipments have been initiated.',
      };
    case OrderStatus.EXPIRED_PENDING_RETURN:
      return {
        text: showStatusName
          ? capitalizeWords(OrderStatus.EXPIRED_PENDING_RETURN)
          : OrderStatusMapping[OrderStatus.EXPIRED_PENDING_RETURN],
        color: theme.palette.colors.mercury,
        textColor: '',
        hoverText:
          'Recipient received retrieval kits, but has not initiated a return and has not responded to ongoing requests for over 30 days.',
      };
    case OrderStatus.IN_FULFILLMENT:
      return {
        text: showStatusName
          ? capitalizeWords(OrderStatus.IN_FULFILLMENT)
          : OrderStatusMapping[OrderStatus.IN_FULFILLMENT],
        color: theme.palette.colors.butterBlond,
        textColor: '',
        hoverText:
          'allwhere has received all required information from recipient; allwhere team actively fulfilling order with logistics partners and vendors.',
      };
    case OrderStatus.IN_RETURN:
      return {
        text: showStatusName
          ? capitalizeWords(OrderStatus.IN_RETURN)
          : OrderStatusMapping[OrderStatus.IN_RETURN],
        color: theme.palette.colors.butterBlond,
        textColor: '',
        hoverText: 'Retrieval kit has been shipped to final destination.',
      };
    case OrderStatus.PENDING_APPROVAL:
      return {
        text: OrderStatusMapping[OrderStatus.PENDING_APPROVAL],
        color: theme.palette.colors.chiGong,
        textColor: theme.palette.colors.white,
        hoverText:
          'All information has been received and the order is being prepared by allwhere.',
      };
    case OrderStatus.PENDING_RECIPIENT_INFORMATION:
      return {
        text: showStatusName
          ? capitalizeWords(OrderStatus.PENDING_RECIPIENT_INFORMATION)
          : OrderStatusMapping[OrderStatus.PENDING_RECIPIENT_INFORMATION],
        color: theme.palette.colors.chiGong,
        textColor: theme.palette.colors.white,
        hoverText:
          'Recipient form has been sent to collect required information; allwhere team awaiting response from recipient.',
      };
    case OrderStatus.PENDING_RETURN:
      return {
        text: showStatusName
          ? capitalizeWords(OrderStatus.PENDING_RETURN)
          : OrderStatusMapping[OrderStatus.PENDING_RETURN],
        color: theme.palette.colors.chiGong,
        textColor: theme.palette.colors.white,
        hoverText:
          'Retrieval kit has been delivered; awaiting recipient to ship equipment back to final destination.',
      };
    case OrderStatus.SHIPPED:
      return {
        text: showStatusName
          ? capitalizeWords(OrderStatus.SHIPPED)
          : OrderStatusMapping[OrderStatus.SHIPPED],
        color: theme.palette.colors.butterBlond,
        textColor: '',
        hoverText:
          'All items and shipments within the order have been scanned by the carrier and are in transit.',
      };
    case OrderStatus.PROCESSING_DEVICE:
      return {
        text: showStatusName
          ? capitalizeWords(OrderStatus.PROCESSING_DEVICE)
          : OrderStatusMapping[OrderStatus.PROCESSING_DEVICE],
        color: theme.palette.colors.butterBlond,
        textColor: '',
        hoverText:
          'This asset is currently undergoing our standard wipe and checking process. This device will be ready to redeploy once complete.',
      };
    case OrderStatus.PENDING_REPAIR:
      return {
        text: showStatusName
          ? capitalizeWords(OrderStatus.PENDING_REPAIR)
          : OrderStatusMapping[OrderStatus.PENDING_REPAIR],
        color: theme.palette.colors.butterBlond,
        textColor: '',
        hoverText:
          'This asset is currently undergoing our standard repair process. If this device is out of warranty we will need quote approval.',
      };
    case OrderStatus.PENDING_RECYCLE:
      return {
        text: showStatusName
          ? capitalizeWords(OrderStatus.PENDING_RECYCLE)
          : OrderStatusMapping[OrderStatus.PENDING_RECYCLE],
        color: theme.palette.colors.butterBlond,
        textColor: '',
        hoverText:
          'This asset is currently undergoing our standard recycle process. Your certificate of erasure will be provided after recycle.',
      };
    case OrderStatus.PENDING_CERTIFICATE:
      return {
        text: showStatusName
          ? capitalizeWords(OrderStatus.PENDING_CERTIFICATE)
          : OrderStatusMapping[OrderStatus.PENDING_CERTIFICATE],
        color: theme.palette.colors.butterBlond,
        textColor: '',
        hoverText:
          'Your asset has been recycled, we are now awaiting the certificate of erasure which will be provided to you upon receipt.',
      };
    case OrderStatus.QUOTE_IN_PROGRESS:
      return {
        text: showStatusName
          ? capitalizeWords(OrderStatus.QUOTE_IN_PROGRESS)
          : OrderStatusMapping[OrderStatus.QUOTE_IN_PROGRESS],
        color: theme.palette.colors.butterBlond,
        textColor: '',
        hoverText:
          'We are working to obtain a sales quote and will require your approval once obtained.',
      };
    case OrderStatus.PENDING_QUOTE_APPROVAL:
      return {
        text: showStatusName
          ? capitalizeWords(OrderStatus.PENDING_QUOTE_APPROVAL)
          : OrderStatusMapping[OrderStatus.PENDING_QUOTE_APPROVAL],
        color: theme.palette.colors.butterBlond,
        textColor: '',
        hoverText:
          'We have obtained a quote for this asset and are awaiting your approval. Upon approval we will finalize the sale.',
      };
    case ClientOrderStatusEnum.PREPARING_ORDER:
      return {
        text: showStatusName
          ? capitalizeWords(ClientOrderStatusEnum.PREPARING_ORDER)
          : 'Preparing Order',
        color: theme.palette.colors.butterBlond,
        textColor: '',
        hoverText:
          'All information has been received and the order is being prepared by allwhere.',
      };
    default:
      throw new Error(`Unsupported Order Status type: ${orderStatus}`);
  }
};
