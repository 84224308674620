/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Box from '@mui/material/Box';
import BuyAndHoldBannerImg from 'assets/buy-hold-banner-compressed-img.png';

const BuyAndHoldBanner = () => {
  return (
    <Box>
      <img src={BuyAndHoldBannerImg} alt="Buy and Hold Banner" />
    </Box>
  );
};

export default BuyAndHoldBanner;
