import React from 'react';
import { SxProps } from '@mui/material';

export const DatePickerStackSx: SxProps = {
  alignItems: 'center',
  '& > .MuiFormControl-root': {
    border: 'none',
    borderBottom: '1px solid',
    borderRadius: 0,
    height: '40px',
    px: 0,
  },
  '& fieldset': {
    border: 'none',
  },
};

export const DatePickerSx: SxProps = {
  alignSelf: 'center',
  width: '180px',
};

export const DateButtonBaseSx: SxProps = {
  p: 0,
  height: '30px',
  minWidth: '48px',
  '& .MuiTypography-root': {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '22px',
  },
  '&#cancel-button': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

export const DateSaveButtonSx: SxProps = {
  ...DateButtonBaseSx,
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
  borderRadius: '30px',
};

export const DateSpanStyle: React.CSSProperties = {
  paddingLeft: '5px',
  textDecoration: 'underline',
  cursor: 'pointer',
};
