import React from 'react';
import { TextField } from '@mui/material';
import { StepInputProps } from './types';
import { handleStepInputKeyDown } from './utils';
import { useStepInputValueChange } from './hooks';

export const StepInput = (props: StepInputProps) => {
  const { stepInputValueChange, ...rest } = props;
  const stepInputValue = props.value * props.step;

  const handleValueChanged = useStepInputValueChange({
    step: props.step,
    stepInputValueChange,
    minimum: props.minimum,
  });

  return (
    <TextField
      {...rest}
      value={stepInputValue}
      onChange={handleValueChanged}
      onKeyDown={handleStepInputKeyDown}
      type="number"
      inputProps={{
        ...props.inputProps,
        step: props.step,
        min: props.minimum,
        max: props.maximum,
      }}
    />
  );
};
