import { gql } from '@apollo/client';

export const SEARCH_MERCHANDISE_ITEMS = gql`
  query searchMerchandiseItems(
    $organizationId: ID!
    $offset: String
    $limit: Int
    $orderAsc: String
    $orderDesc: String
  ) {
    searchMerchandiseItems(
      merchandiseSearchInput: {
        organizationId: $organizationId
        offset: $offset
        limit: $limit
        orderAsc: $orderAsc
        orderDesc: $orderDesc
      }
    ) {
      merchandiseItems {
        id
        date
        title
        description
        inventoryQuantity
        deployedQuantity
        total
      }
      offset
    }
  }
`;
