import { useLocation } from 'react-router-dom';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthToken } from '../store/slices/global/selectors';
import { getLocalStorageValue } from '../api/storage';
import { setAuthToken } from '../store/slices';
import { useAuth0 } from '@auth0/auth0-react';

export const useSetAuthTokenToReduxFromLocalStorage = () => {
  const location = useLocation();

  const dispatch = useDispatch();

  React.useEffect(() => {
    const authToken = getLocalStorageValue('authToken');
    dispatch(setAuthToken(authToken));
  }, [location]);
};

export const useDoAuth0LoginFlow = () => {
  const {
    isAuthenticated,
    getAccessTokenSilently,
    isLoading,
    logout = () => {},
    loginWithRedirect = () => {},
  } = useAuth0();
  const authToken = useSelector(selectAuthToken);
  const dispatch = useDispatch();
  const location = useLocation();

  React.useEffect(() => {
    const auth0LoginFlow = async () => {
      if (isAuthenticated && !isLoading) {
        if (
          !['logged-out', 'unauthenticated'].includes(authToken) ||
          authToken === 'initial'
        ) {
          let token = '';
          try {
            token = await getAccessTokenSilently();
          } catch (e) {
            console.error('Could not get access token silently');
            console.error(e);
          }
          if (token) {
            dispatch(setAuthToken(token));
          } else {
            dispatch(setAuthToken('logged-out'));
            logout();
          }
        } else if (['logged-out', 'unauthenticated'].includes(authToken)) {
          dispatch(setAuthToken('logged-out'));
          logout();
        }
      } else if (!isAuthenticated && !isLoading) {
        dispatch(setAuthToken('logged-out'));
        loginWithRedirect();
      }
    };
    auth0LoginFlow();
  }, [authToken, location, isLoading, isAuthenticated]);
};
