const PeruFlagSVGIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="none"
    >
      <path fill="#D91023" d="m0,0h28v20H0" />
      <path fill="#FFF" d="m9.33,0h9.33v20H9.33" />
    </svg>
  );
};

export default PeruFlagSVGIcon;
