/* eslint-disable sort-exports/sort-exports */
import React, { ReactNode } from 'react';
import _capitalize from 'lodash/capitalize';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme, styled } from '@mui/material/styles';
import { Asset } from 'types';
import { RecipientAssetDetailProps, RecipientAssetDetail } from './types';
import {
  RecipientAssetsStackSx,
  RecipientAssetDividerSx,
  RecipientAssetDetailsSx,
  RecipientAssetImageSx,
} from './styles';
import {
  AssetType,
  ASSET_DETAILS_TO_CLEAR,
  ASSET_DETAILS_MAPPER,
} from './constants';
import OptionValueForm from 'pages/Retrieval/components/ReviewStep/components/RetrievalOptionValueForm';
import {
  RetrievalAsset,
  RetrievalOptionAnswer,
} from 'pages/Retrieval/RetrievalService/types';
import { RetrievalOption } from 'pages/Retrieval/components/ReviewStep/components/RetrievalOptionValueForm/types';
import AssetImage from './components/AssetImage';

const AssetGrid = styled(Grid, {
  shouldForwardProp: (prop: any) => prop !== 'isBottom',
})<{ isBottom: boolean }>(({ isBottom }) => ({
  marginTop: isBottom ? '16px' : 0,
}));

const AssetDetailsHeader = styled(Typography)(() => ({
  color: 'rgba(0, 0, 0, .42)',
  fontSize: '12px',
  wordWrap: 'break-word',
}));

const AssetDetailsValue = styled(Typography)(() => ({
  color: 'rgba(0, 0, 0, .87)',
  fontWeight: 500,
  wordWrap: 'break-word',
}));

const RecipientAssetDetails = (props: RecipientAssetDetailProps) => {
  const theme = useTheme();
  const getDisplayDetails = (detail: any) => {
    if (ASSET_DETAILS_TO_CLEAR.includes(String(detail))) return 'N/A';
    return detail || 'N/A';
  };

  const willRenderDetails = (asset: RetrievalAsset): boolean =>
    props.expanded && !asset?.removeAsset;

  const showDivider = (mapIndex: number) => mapIndex > 0 && props.expanded;

  const handleAssetCheck =
    (assetId: string) => (checkEvent: React.ChangeEvent<HTMLInputElement>) => {
      props.toggleAsset(props.employeeId, assetId, checkEvent.target.checked);
    };

  const getDetailKeyBase = (index: number, assetHeader: string) =>
    `${index > 9 ? '' : '0'}${index}-${props.firstName}-${
      props.lastName
    }-${assetHeader}`;

  const assignOptionAnswer =
    (assetId: string) => (answer: RetrievalOptionAnswer) => {
      props.answerRetrievalOption(props.employeeId, assetId, answer);
    };

  const renderAssetDetail = (
    mappedAsset: Asset,
    assetDetail: RecipientAssetDetail,
    baseKeyString: string
  ): ReactNode => {
    const { header, assetField, bottom: isBottom } = assetDetail;
    const detailValue = mappedAsset?.[assetField as keyof typeof mappedAsset];

    return (
      <AssetGrid
        isBottom={isBottom}
        item
        xs={4}
        key={`${baseKeyString}-${mappedAsset.id}`}
      >
        <AssetDetailsHeader>{header}</AssetDetailsHeader>
        <AssetDetailsValue>{getDisplayDetails(detailValue)}</AssetDetailsValue>
      </AssetGrid>
    );
  };

  const renderNothing = props.assets.length === 0;

  const getDeviceName = (asset: RetrievalAsset): string => {
    if (asset.addedAsset) {
      return `${_capitalize(asset.type)} - ${asset.serialNumber}`;
    }

    return `${asset.make} ${asset.model} ${
      asset.serialNumber ? `- ${asset.serialNumber}` : ''
    }`;
  };

  if (renderNothing) return null;

  return (
    <Stack direction="column" sx={RecipientAssetsStackSx(theme)} spacing={0}>
      {props.assets.map((asset, index) => {
        return (
          <>
            <Fade in={showDivider(index)} timeout={200} unmountOnExit>
              <Divider
                sx={RecipientAssetDividerSx(theme, willRenderDetails(asset))}
              />
            </Fade>
            <FormControlLabel
              className="recipient-assets-control-label"
              key={`asset-checkbox-${asset.name}-${props.firstName}-${props.lastName}`}
              label={getDeviceName(asset)}
              control={
                <Checkbox
                  checked={!asset?.removeAsset}
                  disableRipple
                  onChange={handleAssetCheck(asset.id)}
                />
              }
            />
            <div>
              <Collapse in={willRenderDetails(asset)} timeout="auto">
                <Grid container sx={RecipientAssetDetailsSx}>
                  <Grid item container sx={RecipientAssetImageSx} xs={3}>
                    <Grid item>
                      <AssetImage type={asset.type} />
                    </Grid>
                  </Grid>
                  <Grid item container xs>
                    {ASSET_DETAILS_MAPPER.map((assetDetail, index) =>
                      renderAssetDetail(
                        asset,
                        assetDetail,
                        getDetailKeyBase(index, assetDetail.header)
                      )
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <OptionValueForm
                      answerRetrievalOption={assignOptionAnswer(asset.id)}
                      collaboratorId={props.employeeId}
                      retrievalOptions={
                        props.retrievalOptionsByType[asset.type]
                      }
                      retrievalAnswers={
                        props.retrievalOptionsAnswerKey[asset.id]
                      }
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </div>
          </>
        );
      })}
    </Stack>
  );
};

export default RecipientAssetDetails;
