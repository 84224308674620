import { ViewableUploadMetadata } from 'store/shared/uploads/types';
import { UPLOAD_STATUS_MAPPER } from './constants';
import { DisplayableUpload } from './types';
import moment from 'moment';

export const mapViewableUploadsToDisplayableUploads = (
  viewableUploads: ViewableUploadMetadata[]
): DisplayableUpload[] =>
  viewableUploads.map((upload: ViewableUploadMetadata) => {
    return {
      ...upload,
      collaboratorName: upload.collaborator?.name || 'Unknown',
      statusLabel: UPLOAD_STATUS_MAPPER[upload.status].label,
      statusIcon: UPLOAD_STATUS_MAPPER[upload.status].icon,
      statusIconProps: UPLOAD_STATUS_MAPPER[upload.status].iconProps,
      formattedTimestamp: moment(upload.timestamp).format('MMM D, YYYY, h:mmA'),
    };
  });

export const getUploadErrorsText = (upload: DisplayableUpload) => {
  const errorLength = upload.errors.length;

  return `${errorLength > 0 ? errorLength : 'No'} error${
    errorLength !== 1 ? 's' : ''
  } found`;
};

export const getPaginationText = (offset = 0, limit = 0, count = 0) => {
  if (count === 0 || offset > count) {
    return '';
  }
  return `${offset + 1} - ${Math.min(offset + limit, count)} of ${count}`;
};

export const noMoreUploadsAvailable = (offset = 0, limit = 0, count = 0) =>
  offset + limit >= count;
