import { SxProps } from '@mui/material';
import theme from 'themes/theme';

const ORDER_ITEM_IMAGE_WIDTH = '64px';

export const OrderItemsInfoStackSx: SxProps = {
  gap: '4px',
  position: 'relative',
  width: '100%',
};

export const OrderItemsScrollBox: SxProps = {
  height: 'calc(75vh - 220px)',
  overflowY: 'scroll',
  width: '100%',
  '::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    width: '7px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: 'rgba(0,0,0,.5)',
    WebkitBoxShadow: '0 0 1px rgba(255,255,255,.5)',
  },
};

export const OrderItemsInfoGridSx: SxProps = {
  alignSelf: 'stretch',
  backgroundColor: 'colors.grayFA',
  borderRadius: '10px',
  boxSizing: 'border-box',
  padding: '12px',
  pt: 0,
  px: 0,
  width: '100%',
};

export const OrderItemsTitleStackSx: SxProps = {
  display: 'flex',
  flex: 1,

  '& .MuiTypography-root': {
    borderBottom: `1px solid rgba(19, 20, 20, 0.12)`,
    boxSizing: 'border-box',
    p: '16px',
    color: theme.palette.colors.woodSmoke,

    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '20px',

    '&.tracking-number-title': {
      width: '20%',
    },
    '&.shipment-status-title': {
      width: '20%',
    },
    '&.order-item-title': {
      flex: 1,
    },
    '&.asset-number-title': {
      width: '20%',
    },
  },
};

export const OrderItemsNumberBoxSx: SxProps = {
  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
};

export const OrderItemsImageBoxSx: SxProps = {
  ml: '8px',
  width: ORDER_ITEM_IMAGE_WIDTH,

  '&.blank-box': {
    backgroundColor: theme.palette.colors.grayBD,
    height: ORDER_ITEM_IMAGE_WIDTH,
  },
};

export const OrderItemsDeckGridContainerSx: SxProps = {
  width: '100%',
};

export const OrderItemsLeftGridBoxSx: SxProps = {
  display: 'inline-flex',
  justifyContent: 'flex-start',
};
