export const ADD_BY_ASSET_ID_INPUT_ID = 'add-order-item-by-assets-number-input';
export const ADD_BY_ASSET_ID_INPUT_LABEL_ID =
  'add-order-item-by-assets-number-label';

export const ASSET_ID_ERROR_ENTITY_NAME = 'Asset';
export const ASSET_ID_ERROR_ENTITY_FIELD_NAME = 'asset Id';
export const ASSET_ID = 'assetIds';
export const ASSET_ID_LABEL = 'Asset Id(s)';

export const ADD_ORDER_ITEM_BY_ASSET_NUMBER_INVALID_ERROR_MESSAGE =
  'Invalid entry detected. Asset Id can only be a series of digits/numbers.';
export const ADD_ORDER_ITEM_BY_ASSET_NUMBER_NOT_FOUND_ERROR_MESSAGE =
  'Asset not found. Asset Id as entered does not exist.';

export const ADD_BY_SERIAL_NUMBER_INPUT_ID =
  'add-order-item-by-serial-numbers-input';
export const ADD_BY_SERIAL_NUMBER_INPUT_LABEL_ID =
  'add-order-item-by-serial-numbers-label';

export const SERIAL_NUMBER_ERROR_ENTITY_NAME = 'Serial';
export const SERIAL_NUMBER_ERROR_ENTITY_FIELD_NAME = 'serial Number';
export const SERIAL_NUMBER = 'serialNumbers';
export const SERIAL_NUMBER_LABEL = 'Serial Number(s)';

export const ADD_ORDER_ITEMS_TEXT_FIELD_ROWS = 4;
