/* eslint-disable sort-exports/sort-exports */
import { SxProps } from '@mui/material/styles';
import { RETRIEVAL_TOP_BAR_HEIGHT } from 'pages/Retrieval/styles';

const SM_BREAKPOINT_PADDING_X = '15px';
const MD_BREAKPOINT_PADDING_X = '50px';
const LG_BREAKPOINT_PADDING_X = '144px';

export const SubmitStepSx: SxProps = {
  boxSizing: 'border-box',
  paddingBottom: 'calc(20% + 60px)',
  maxHeight: `calc(100vh - ${RETRIEVAL_TOP_BAR_HEIGHT})`,
  overflow: {
    md: 'scroll',
  },
  pt: '20px',
  px: {
    xs: SM_BREAKPOINT_PADDING_X,
    sm: SM_BREAKPOINT_PADDING_X,
    md: MD_BREAKPOINT_PADDING_X,
    lg: LG_BREAKPOINT_PADDING_X,
  },
  '& #submit-step-title-copy': {
    fontSize: '27px',
    fontWeight: 700,
  },
  '& #submit-step-title-block': {
    marginBottom: '2rem',
  },
};

export const ListItemSx: SxProps = {
  height: '40px',
  padding: '0',
};
