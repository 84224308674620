import { SxProps } from '@mui/material';
import theme from 'themes/theme';
import { DRAWER_WIDTH, HEADER_HEIGHT } from 'global-styles';
import {
  SEARCH_BAR_EXTERNAL_RADIUS,
  SEARCH_BAR_TYPE_SELECT_WIDTH,
  SEARCH_BAR_WHOLE_WIDTH,
  SEARCH_BAR_WHOLE_HEIGHT,
} from './constants';

export const ContentDivSx: SxProps = {
  margin: 'auto',
  mt: HEADER_HEIGHT,
  position: 'relative',
  '@media (max-width: 1199px)': {
    left: '0',
  },
  '@media (min-width: 1200px)': {
    left: `${DRAWER_WIDTH - DRAWER_WIDTH / 2}px`,
    width: '80%',
  },
  '@media (min-width: 1536px)': {
    left: `${DRAWER_WIDTH - DRAWER_WIDTH / 2}px`,
    maxWidth: '100%',
    width: '85%',
  },
  '& .devices-view-title': {
    fontSize: '36px',
    fontWeight: 500,
    lineHeight: '41.04px',
  },

  '& .devices-view-subtitle': {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
  },
};

export const MainStackSx: SxProps = {
  marginTop: '1.35rem',
};

export const InnerStackSx: SxProps = {
  marginTop: '0.75rem',
};

export const DataGridBoxSx: SxProps = {
  height: 'calc(100vh - 350px)',
  width: '100%',
  marginTop: '1.75rem',
};

export const AutoCompleteStackSx: SxProps = {
  backgroundColor: theme.palette.colors.grayF6,
  borderRadius: SEARCH_BAR_EXTERNAL_RADIUS,
  width: SEARCH_BAR_WHOLE_WIDTH,
  height: SEARCH_BAR_WHOLE_HEIGHT,
  position: 'relative',
  '&:hover': {
    outline: '1px solid',
  },

  // only need if the stack contains a searchIcon
  '& hr:first-of-type': {
    display: 'none',
  },
};

export const AutoCompleteSx: SxProps = {
  p: 0,
  backgroundColor: 'transparent',
  border: 'none',
  height: SEARCH_BAR_WHOLE_HEIGHT,
  position: 'relative',
  width: `calc(100% - ${SEARCH_BAR_EXTERNAL_RADIUS})`,

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },

  '& .MuiInputBase-root': {
    backgroundColor: 'transparent',
    borderTopLeftRadius: SEARCH_BAR_EXTERNAL_RADIUS,
    borderBottomLeftRadius: SEARCH_BAR_EXTERNAL_RADIUS,
  },

  '& .MuiFormControl-root': {
    backgroundColor: 'transparent',
  },

  '& .MuiInputBase-formControl': {
    backgroundColor: 'transparent',
    height: SEARCH_BAR_WHOLE_HEIGHT,
    py: 0,
  },

  '& .MuiAutocomplete-endAdornment': {
    '& .MuiAutocomplete-popupIndicator': {
      visibility: 'hidden',
    },
    '& .MuiAutocomplete-clearIndicator': {
      visibility: 'visible',
    },
  },
};

export const AutoCompleteSearchTextFieldSx: SxProps = {
  p: '0',
  pl: 3,
  borderRadius: 0,
  borderTopLeftRadius: SEARCH_BAR_EXTERNAL_RADIUS,
  borderBottomLeftRadius: SEARCH_BAR_EXTERNAL_RADIUS,
};

export const AutoCompleteSelectSx: SxProps = {
  p: 0,
  width: SEARCH_BAR_TYPE_SELECT_WIDTH,
  backgroundColor: 'transparent',
  borderTopRightRadius: SEARCH_BAR_EXTERNAL_RADIUS,
  borderBottomRightRadius: SEARCH_BAR_EXTERNAL_RADIUS,

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};

export const AutoCompleteSelectMenuSx: SxProps = {
  width: SEARCH_BAR_TYPE_SELECT_WIDTH,
};

export const AutoCompleteSearchIconSx: SxProps = {
  color: theme.palette.colors.gray75,
  position: 'absolute',
  top: '8px',
  left: '8px',
};

export const DownloadAllTextSx: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: '600',
  position: 'relative',
  top: '2px',
  pr: '40px',
};
