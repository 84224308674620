import React from 'react';
import { useForm } from 'react-hook-form';
import { NewModal } from 'components';
import InventoryReceiptForm from '../InventoryReceiptForm';
import {
  CANCEL_BUTTON_LABEL,
  EDIT_INVENTORY_RECEIPT_MODAL_TITLE,
  SAVE_BUTTON_LABEL,
} from './constants';
import { EditInventoryReceiptModalProps } from './types';
import { ModalPaperSx, OuterStackSx } from './styles';

const EditInventoryReceiptModal = (props: EditInventoryReceiptModalProps) => {
  const { handleSubmit } = useForm();

  React.useEffect(() => {
    if (props.open) {
      props.inventoryReceiptFormService.setContext('edit');
      props.inventoryReceiptFormService.setDefaultValues(
        props.inventoryReceipt
      );
    }

    return () => {
      props.inventoryReceiptFormService.setContext('none');
      props.inventoryReceiptFormService.setDefaultValues();
    };
  }, [props.open]);

  return (
    <NewModal
      form
      open={props.open}
      title={EDIT_INVENTORY_RECEIPT_MODAL_TITLE}
      onSubmit={handleSubmit(props.onSubmit)}
      submitButtonCopy={SAVE_BUTTON_LABEL}
      cancelButtonCopy={CANCEL_BUTTON_LABEL}
      disableSaveButton={!props.inventoryReceiptFormService.isValid}
      loading={props.inventoryReceiptFormService.mutationIsLoading}
      handleClose={props.handleClose}
      paperPropsSx={ModalPaperSx}
      outerStackPropsSx={OuterStackSx}
    >
      <InventoryReceiptForm
        inventoryReceipt={props.inventoryReceipt}
        inventoryReceiptFormService={props.inventoryReceiptFormService}
      />
    </NewModal>
  );
};

export default EditInventoryReceiptModal;
