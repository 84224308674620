import { gql } from '@apollo/client';

export const CREATE_TO_PROCUREMENT_TO_DEPOT_ORDER = gql`
  mutation createProcurementToDepotOrder(
    $depotId: ID!
    $purchaserId: ID!
    $items: [CreateProcurementItemsInput!]!
    $isRush: Boolean
    $isQuoteRequested: Boolean
    $checkoutNotes: String
    $shippingTypeId: String
  ) {
    createProcurementToDepotOrder(
      procurementToDepotOrderCreateInput: {
        depotId: $depotId
        purchaserId: $purchaserId
        isQuoteRequested: $isQuoteRequested
        items: $items
        isRush: $isRush
        checkoutNotes: $checkoutNotes
        shippingTypeId: $shippingTypeId
      }
    ) {
      id
    }
  }
`;
