/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import * as FilterDrawerFooterStyles from './styles';
import FilterDrawerFooterProps from './types';

const FilterDrawerFooter = (props: FilterDrawerFooterProps) => {
  const styles = FilterDrawerFooterStyles;
  const isFiltered = useSelector(props.selectIsFiltered(props.filterSubTree));
  if (!isFiltered) return <></>;

  const handleClearAllFilters = () => {
    props.onClearAllFilters(false);
  };

  return (
    <Box sx={styles.FilterDrawerBottomBoxSx}>
      <Button
        className="clear-all-filters-button"
        variant="contained"
        startIcon={<CloseSharpIcon />}
        onClick={handleClearAllFilters}
        data-testid={`${props.filterSubTree}-optional-clear-all-filter-button`}
        aria-label={`${props.filterSubTree}-optional-clear-all-filter-button`}
      >
        Clear all filters
      </Button>
    </Box>
  );
};

export default FilterDrawerFooter;
