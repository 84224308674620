import React from 'react';
import { StateKey } from 'store/shared/types';
import { FILTER_BANK_CONFIG } from 'components/complex_filter/constants';
import { selectAllChosenEntries } from 'store/shared/selectors';
import { renderCommonChipHOC } from 'components/complex_filter/utils';
import { UseGetFilterBankConfig } from 'components/complex_filter/types';
import {
  useGetDeselectByIdAndKey,
  useGetSoftResetFilter,
} from 'components/complex_filter/hooks';

export const useGetFilterBankConfig = (
  filterSubTree: StateKey
): UseGetFilterBankConfig | undefined => {
  const errorMessage = `FilterBankWrapper/hooks: ${filterSubTree} is not a valid filter/config key.`;
  const matchingConfig = FILTER_BANK_CONFIG[filterSubTree];

  if (!matchingConfig) {
    console.error(errorMessage);
    return undefined;
  }

  const { actions, ...restOfConfig } = matchingConfig;

  const deselectByIdAndKey = useGetDeselectByIdAndKey(
    actions.deselectFilterByIdAndKeyReducer
  );

  const renderBankContents = renderCommonChipHOC(deselectByIdAndKey);

  const restFilters = useGetSoftResetFilter(
    actions.softResetFilterStateReducer
  );

  const clearFilterBank = React.useCallback(() => {
    restFilters(true);
  }, []);

  return {
    filterSubTree,
    selectAllChosenEntries,
    renderBankContents,
    clearFilterBank,
    ...restOfConfig,
  };
};
