import { gql } from '@apollo/client';

export const ADD_ORDER_ITEM_BY_PRODUCT = gql`
  mutation createOrderItemsByProductVariants(
    $orderId: ID!
    $productVariantOrderItems: [ProductVariantOrderItems!]!
  ) {
    createOrderItemsByProductVariants(
      orderItemsByProductVariantsCreateInput: {
        orderId: $orderId
        productVariantOrderItems: $productVariantOrderItems
      }
    ) {
      id
    }
  }
`;
