import { createSlice } from '@reduxjs/toolkit';
import { FilterEntry, FilterEntryUnion } from 'store/shared/types';
import {
  deselectFilterByIdAndKeyReducer,
  resetFilterStateReducer,
  closeFilterDrawerReducer,
  openFilterDrawerReducer,
  toggleFilterDrawerReducer,
  upsertFilterEntryReducer,
  batchUpsertFilterEntriesReducer,
  openFilterInputReducer,
  closeFilterInputReducer,
  setSearchInputReducer,
  clearSearchInputReducer,
  selectOptionReducer,
  clearAvailableOptionsByKeyReducer,
  toggleFilterByKeyIdReducer,
  clearFilterEntriesByKeyReducer,
  setComplexFilterEntriesReducerHOF,
  replaceDateFilterEntryReducer,
  setDateModeReducer,
  setDateRangeReducer,
  setSingleDateReducer,
  clearDateByModeKeyReducer,
  resetFilterByEntryKeyReducerHOF,
  softResetFilterStateReducerHOF,
} from 'store/shared/reducers';
import { OrdersFilterState } from './types';

export const ORDERS_FILTERS_MAPPING_TABLE = {
  'order-number': 'orderNumber',
  'order-type': 'orderType',
  'order-date': 'orderDate',
  'order-status': 'status',
  'shipping-selection': 'shippingType',
  'tracking-number': 'trackingNumber',
  purchaser: 'purchaser',
  'asset-number': 'assetNumber',
  'shipment-status': 'shipmentStatus',
  recipient: 'recipient',
  'recipient-email': 'recipientEmail',
};

const DESELECT_ONLY_RESET_KEYS = [
  'order-type',
  'order-status',
  'shipping-selection',
  'shipment-status',
];

export const INITIAL_ORDER_FILTER_ENTRIES: FilterEntryUnion = {
  'order-number': { options: [] as FilterEntry[], searchInput: '' },
  'order-type': { options: [] as FilterEntry[], searchInput: '' },
  'order-date': {
    options: [] as FilterEntry[],
    searchInput: null,
    dateMode: 'range',
    range: [null, null],
    beforeDate: null,
    afterDate: null,
  },
  'order-status': { options: [] as FilterEntry[], searchInput: '' },
  'shipping-selection': { options: [] as FilterEntry[], searchInput: '' },
  'tracking-number': { options: [] as FilterEntry[], searchInput: '' },
  purchaser: { options: [] as FilterEntry[], searchInput: '' },
  'asset-number': { options: [] as FilterEntry[], searchInput: '' },
  'shipment-status': { options: [] as FilterEntry[], searchInput: '' },
  recipient: { options: [] as FilterEntry[], searchInput: '' },
  'recipient-email': { options: [] as FilterEntry[], searchInput: '' },
};

export const ordersInitialState: OrdersFilterState = {
  entries: INITIAL_ORDER_FILTER_ENTRIES,
  popover: { open: false },
  expandedFilter: null,
  complexSearchEntriesDTO: [],
};

export const orderFilterSlice = createSlice({
  name: 'ordersFilterSlice',
  initialState: ordersInitialState,
  reducers: {
    deselectFilterByIdAndKeyReducer,
    resetFilterStateReducer,
    closeFilterDrawerReducer,
    openFilterDrawerReducer,
    toggleFilterDrawerReducer,
    upsertFilterEntryReducer,
    batchUpsertFilterEntriesReducer,
    openFilterInputReducer,
    closeFilterInputReducer,
    setSearchInputReducer,
    clearSearchInputReducer,
    selectOptionReducer,
    clearAvailableOptionsByKeyReducer,
    toggleFilterByKeyIdReducer,
    clearFilterEntriesByKeyReducer,
    setComplexFilterEntriesReducer: setComplexFilterEntriesReducerHOF(
      ORDERS_FILTERS_MAPPING_TABLE
    ),
    resetFilterByEntryKeyReducer: resetFilterByEntryKeyReducerHOF(
      INITIAL_ORDER_FILTER_ENTRIES
    ),
    softResetFilterStateReducer: softResetFilterStateReducerHOF(
      DESELECT_ONLY_RESET_KEYS
    ),
    // optional reducers for date filters
    replaceDateFilterEntryReducer,
    setDateModeReducer,
    setDateRangeReducer,
    setSingleDateReducer,
    clearDateByModeKeyReducer,
  },
  extraReducers: (builder) => {},
});

export const ordersFilterReducer = orderFilterSlice.reducer;
export const orderFilterActions = orderFilterSlice.actions;
