import { NavigationRoutes } from 'global-constants';

/* eslint-disable security/detect-object-injection */
export const INFINITE_SCROLL_THRESHOLD = 0.8;
export const KITS = 'Kits';

export const DEFAULT_INSURANCE_OPTION = '$2000';
export const RETRIEVAL_PER_ITEM_CHARGE: Record<string, number> = {
  None: 0,
  $500: 7.5,
  $800: 12,
  $1000: 15,
  $1500: 22.5,
  $2000: 30,
  $2500: 37.5,
  $3000: 45,
};

export const RETRIEVAL_INSURANCE_OPTIONS = Object.keys(
  RETRIEVAL_PER_ITEM_CHARGE
);

// Logic to add different container Id based on the pathname
const CATALOG_PATHNAME_TO_IDS: [pathname: string, container_id: string][] = [
  [
    NavigationRoutes.SEND_EQUIPMENT,
    'PROCUREMENT_TO_RECIPIENT_PRODUCT_CATALOG_MAIN_CONTAINER_ID',
  ],
  [NavigationRoutes.CATALOG, 'READ_ONLY_PRODUCT_CATALOG_MAIN_CONTAINER_ID'],
];
const PRODUCT_CATALOG_CONTAINER_IDS_MAP: Map<string, string> = new Map();
CATALOG_PATHNAME_TO_IDS.forEach(([pathname, container_id]) => {
  PRODUCT_CATALOG_CONTAINER_IDS_MAP.set(pathname, container_id);
});

export { PRODUCT_CATALOG_CONTAINER_IDS_MAP };
