import {
  HomeOutlined,
  InventoryOutlined,
  SvgIconComponent,
  WorkOutline,
} from '@mui/icons-material';
import {
  COUNTRY_MATCHER,
  UNITED_STATES,
  US_STATE_MATCHER,
} from 'global-constants';
import { SelectableRecipientAddress } from 'types';

export const isAddressIncomplete = (address?: SelectableRecipientAddress) => {
  if (!address) return true;

  const missingRequiredFields = ![
    'streetAddress1',
    'city',
    'zipCode',
    'country',
    'phoneNumber',
  ].every((k: string) => !!address[k as keyof SelectableRecipientAddress]);
  const useState = address.country === COUNTRY_MATCHER[UNITED_STATES as string];
  return (
    missingRequiredFields ||
    (useState ? !address.state : !address.principalRegion)
  );
};

export const getAddressLabel = (address: SelectableRecipientAddress) => {
  const useState = UNITED_STATES === COUNTRY_MATCHER[address.country as string];
  return `${address.streetAddress1}${
    address.streetAddress2 ? ` ${address.streetAddress2},` : ','
  } ${address.city}, ${
    useState
      ? US_STATE_MATCHER[address.state as string]
      : address.principalRegion
  } ${address.zipCode}, ${COUNTRY_MATCHER[address.country as string]}`;
};

export const getAddressIcon = (
  address: SelectableRecipientAddress
): SvgIconComponent => {
  if (address.isHome) return HomeOutlined;
  if (address.isWork) return WorkOutline;
  return InventoryOutlined;
};
