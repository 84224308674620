import { createAsyncThunk } from '@reduxjs/toolkit';
import * as restOrdersAPI from 'api/rest/orders';

export const getOrdersCSV = createAsyncThunk(
  'orders/csv/getOrdersCSV',
  async (params: { userId: string; orgId: string }) => {
    const { userId, orgId } = params;
    return restOrdersAPI.getOrdersCSV(userId, orgId);
  }
);

export const getOrdersCSVDirect = createAsyncThunk(
  'orders/csv/getOrdersCSVDirect',
  async (params: { userId: string; orgId: string }) => {
    const { userId, orgId } = params;
    return restOrdersAPI.getOrdersCSVDirect(userId, orgId);
  }
);
