import {
  RecipientAddressFormProps,
  IRecipientAddressFormValues,
} from './types';
import { US_STATE_MATCHER, COUNTRY_MATCHER } from 'global-constants';

export const getRecipientAddressAttributes = (recipientType: string) => {
  const tempType = recipientType.toLowerCase();
  const RECIPIENT_ADDRESS = 'recipient-address';
  const FORM_ID = `${RECIPIENT_ADDRESS}-${tempType}`;

  return {
    selectRecipientCollaboratorsLabelId: `${RECIPIENT_ADDRESS}-${tempType}-recipient-select-label`,
    selectRecipientCollaboratorsId: `${RECIPIENT_ADDRESS}-${tempType}-recipient-select`,
    formId: FORM_ID,
    emailInputId: `${FORM_ID}-email-input`,
    firstNameInputId: `${FORM_ID}-first-name-input`,
    lastNameInputId: `${FORM_ID}-last-name-input`,
    streetAddress1InputId: `${FORM_ID}-street-address-1-input`,
    streetAddress2InputId: `${FORM_ID}-street-address-2-input`,
    cityInputId: `${FORM_ID}-city-input`,
    stateInputId: `${FORM_ID}-state-input`,
    principalRegionInputId: `${FORM_ID}-principal-region-input`,
    zipCodeInputId: `${FORM_ID}-zip-code-input`,
    countryInputId: `${FORM_ID}-country-input`,
    phoneNumberInputId: `${FORM_ID}-phone-number-input`,
  };
};

export const getRecipientAddressFormValues = (
  props: RecipientAddressFormProps
): IRecipientAddressFormValues => {
  const {
    addressId,
    firstName = '',
    lastName = '',
    refetchAll,
    state,
    phoneNumber = '',
    email = '',
    streetAddress1 = '',
    streetAddress2 = null,
    country = 'UNITED_STATES',
    city = '',
    zipCode = '',
    principalRegion = null,
    ...restProps
  } = props;

  const hasState = !!state;
  const hasWrongState = hasState && state.length !== 2;

  const fixedState = hasWrongState ? US_STATE_MATCHER[state] : state;
  const tempCountry = country || '';
  const wrongCountry = /[A-Z_]+/.test(tempCountry);
  const fixedCountry = wrongCountry
    ? COUNTRY_MATCHER[tempCountry]
    : tempCountry;

  return {
    firstName,
    lastName,
    phoneNumber,
    email,
    streetAddress1,
    streetAddress2,
    city,
    state: fixedState ?? null,
    principalRegion,
    zipCode,
    country: fixedCountry,
  };
};
