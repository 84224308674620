/**
 * Generated by @openapi-codegen
 *
 * @version 1.0
 */
import * as reactQuery from '@tanstack/react-query';
import { useApiContext, ApiContext } from './apiContext';
import type * as Fetcher from './apiFetcher';
import { apiFetch } from './apiFetcher';
import type * as Schemas from './apiSchemas';

export type AccountManagerControllerCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type AccountManagerControllerCreateVariables = {
  body: Schemas.AccountManagerCreate;
} & ApiContext['fetcherOptions'];

export const fetchAccountManagerControllerCreate = (
  variables: AccountManagerControllerCreateVariables
) =>
  apiFetch<
    Schemas.AccountManagerResponse,
    AccountManagerControllerCreateError,
    Schemas.AccountManagerCreate,
    {},
    {},
    {}
  >({ url: '/v1/account-managers', method: 'post', ...variables });

export const useAccountManagerControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AccountManagerResponse,
      AccountManagerControllerCreateError,
      AccountManagerControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AccountManagerResponse,
    AccountManagerControllerCreateError,
    AccountManagerControllerCreateVariables
  >(
    (variables: AccountManagerControllerCreateVariables) =>
      fetchAccountManagerControllerCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type AccountManagerControllerSearchQueryParams = {
  firstName?: string;
  lastName?: string;
  email?: string;
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type AccountManagerControllerSearchError =
  Fetcher.ErrorWrapper<undefined>;

export type AccountManagerControllerSearchVariables = {
  queryParams?: AccountManagerControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchAccountManagerControllerSearch = (
  variables: AccountManagerControllerSearchVariables
) =>
  apiFetch<
    Schemas.AccountManagerList,
    AccountManagerControllerSearchError,
    undefined,
    {},
    AccountManagerControllerSearchQueryParams,
    {}
  >({ url: '/v1/account-managers', method: 'get', ...variables });

export const useAccountManagerControllerSearch = <
  TData = Schemas.AccountManagerList
>(
  variables: AccountManagerControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AccountManagerList,
      AccountManagerControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.AccountManagerList,
    AccountManagerControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/account-managers',
      operationId: 'accountManagerControllerSearch',
      variables,
    }),
    () =>
      fetchAccountManagerControllerSearch({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type AccountManagerControllerRetrievePathParams = {
  id: string;
};

export type AccountManagerControllerRetrieveError =
  Fetcher.ErrorWrapper<undefined>;

export type AccountManagerControllerRetrieveVariables = {
  pathParams: AccountManagerControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchAccountManagerControllerRetrieve = (
  variables: AccountManagerControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.AccountManagerResponse,
    AccountManagerControllerRetrieveError,
    undefined,
    {},
    {},
    AccountManagerControllerRetrievePathParams
  >({ url: '/v1/account-managers/{id}', method: 'get', ...variables });

export const useAccountManagerControllerRetrieve = <
  TData = Schemas.AccountManagerResponse
>(
  variables: AccountManagerControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AccountManagerResponse,
      AccountManagerControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.AccountManagerResponse,
    AccountManagerControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/account-managers/{id}',
      operationId: 'accountManagerControllerRetrieve',
      variables,
    }),
    () =>
      fetchAccountManagerControllerRetrieve({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type AccountManagerControllerUpdatePathParams = {
  id: string;
};

export type AccountManagerControllerUpdateError =
  Fetcher.ErrorWrapper<undefined>;

export type AccountManagerControllerUpdateVariables = {
  body: Schemas.AccountManagerUpdate;
  pathParams: AccountManagerControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchAccountManagerControllerUpdate = (
  variables: AccountManagerControllerUpdateVariables
) =>
  apiFetch<
    Schemas.AccountManagerResponse,
    AccountManagerControllerUpdateError,
    Schemas.AccountManagerUpdate,
    {},
    {},
    AccountManagerControllerUpdatePathParams
  >({ url: '/v1/account-managers/{id}', method: 'patch', ...variables });

export const useAccountManagerControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AccountManagerResponse,
      AccountManagerControllerUpdateError,
      AccountManagerControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AccountManagerResponse,
    AccountManagerControllerUpdateError,
    AccountManagerControllerUpdateVariables
  >(
    (variables: AccountManagerControllerUpdateVariables) =>
      fetchAccountManagerControllerUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type AccountManagerControllerDeletePathParams = {
  id: string;
};

export type AccountManagerControllerDeleteError =
  Fetcher.ErrorWrapper<undefined>;

export type AccountManagerControllerDeleteVariables = {
  pathParams: AccountManagerControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchAccountManagerControllerDelete = (
  variables: AccountManagerControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    AccountManagerControllerDeleteError,
    undefined,
    {},
    {},
    AccountManagerControllerDeletePathParams
  >({ url: '/v1/account-managers/{id}', method: 'delete', ...variables });

export const useAccountManagerControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      AccountManagerControllerDeleteError,
      AccountManagerControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    AccountManagerControllerDeleteError,
    AccountManagerControllerDeleteVariables
  >(
    (variables: AccountManagerControllerDeleteVariables) =>
      fetchAccountManagerControllerDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type AddressControllerCreateError = Fetcher.ErrorWrapper<undefined>;

export type AddressControllerCreateVariables = {
  body: Schemas.AddressCreate;
} & ApiContext['fetcherOptions'];

export const fetchAddressControllerCreate = (
  variables: AddressControllerCreateVariables
) =>
  apiFetch<
    Schemas.AddressResponse,
    AddressControllerCreateError,
    Schemas.AddressCreate,
    {},
    {},
    {}
  >({ url: '/v1/address', method: 'post', ...variables });

export const useAddressControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AddressResponse,
      AddressControllerCreateError,
      AddressControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AddressResponse,
    AddressControllerCreateError,
    AddressControllerCreateVariables
  >(
    (variables: AddressControllerCreateVariables) =>
      fetchAddressControllerCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type AddressControllerSearchQueryParams = {
  /**
   * First line of the street address
   */
  streetAddress1?: string;
  /**
   * Second line of the street address
   */
  streetAddress2?: string;
  /**
   * The city
   */
  city?: string;
  /**
   * State. Only use when inside the US, otherwise use "principalRegion".
   */
  state?:
    | 'AL'
    | 'AK'
    | 'AZ'
    | 'AR'
    | 'CA'
    | 'CO'
    | 'CT'
    | 'DE'
    | 'DC'
    | 'FL'
    | 'GA'
    | 'HI'
    | 'ID'
    | 'IL'
    | 'IN'
    | 'IA'
    | 'KS'
    | 'KY'
    | 'LA'
    | 'ME'
    | 'MD'
    | 'MA'
    | 'MI'
    | 'MN'
    | 'MS'
    | 'MO'
    | 'MT'
    | 'NE'
    | 'NV'
    | 'NH'
    | 'NJ'
    | 'NM'
    | 'NY'
    | 'NC'
    | 'ND'
    | 'OH'
    | 'OK'
    | 'OR'
    | 'PA'
    | 'RI'
    | 'SC'
    | 'SD'
    | 'TN'
    | 'TX'
    | 'UT'
    | 'VT'
    | 'VA'
    | 'WA'
    | 'WV'
    | 'WI'
    | 'WY';
  /**
   * Provice or state. Only use when outside the US, otherwise use "state".
   */
  principalRegion?: string;
  /**
   * Zip code
   */
  zipCode?: string;
  /**
   * Country
   */
  country?:
    | 'Afghanistan'
    | 'Aland Islands'
    | 'Albania'
    | 'Algeria'
    | 'American Samoa'
    | 'Andorra'
    | 'Angola'
    | 'Anguilla'
    | 'Antarctica'
    | 'Antigua And Barbuda'
    | 'Argentina'
    | 'Armenia'
    | 'Aruba'
    | 'Australia'
    | 'Austria'
    | 'Azerbaijan'
    | 'Bahamas'
    | 'Bahrain'
    | 'Bangladesh'
    | 'Barbados'
    | 'Belarus'
    | 'Belgium'
    | 'Belize'
    | 'Benin'
    | 'Bermuda'
    | 'Bhutan'
    | 'Bolivia'
    | 'Bonaire Sint Eustatius Saba'
    | 'Bosnia And Herzegovina'
    | 'Botswana'
    | 'Bouvet Island'
    | 'Brazil'
    | 'British Indian Ocean Territory'
    | 'Brunei Darussalam'
    | 'Bulgaria'
    | 'Burkina Faso'
    | 'Burundi'
    | 'Cambodia'
    | 'Cameroon'
    | 'Canada'
    | 'Cape Verde'
    | 'Cayman Islands'
    | 'Central African Republic'
    | 'Chad'
    | 'Chile'
    | 'China'
    | 'Christmas Island'
    | 'Cocos Keeling Islands'
    | 'Colombia'
    | 'Comoros'
    | 'Congo'
    | 'Congo Democratic Republic'
    | 'Cook Islands'
    | 'Costa Rica'
    | 'Cote D Ivoire'
    | 'Croatia'
    | 'Cuba'
    | 'Cura\u00E7ao'
    | 'Cyprus'
    | 'Czech Republic'
    | 'Denmark'
    | 'Djibouti'
    | 'Dominica'
    | 'Dominican Republic'
    | 'Ecuador'
    | 'Egypt'
    | 'El Salvador'
    | 'Equatorial Guinea'
    | 'Eritrea'
    | 'Estonia'
    | 'Eswatini'
    | 'Ethiopia'
    | 'Falkland Islands'
    | 'Faroe Islands'
    | 'Fiji'
    | 'Finland'
    | 'France'
    | 'French Guiana'
    | 'French Polynesia'
    | 'French Southern Territories'
    | 'Gabon'
    | 'Gambia'
    | 'Georgia'
    | 'Germany'
    | 'Ghana'
    | 'Gibraltar'
    | 'Greece'
    | 'Greenland'
    | 'Grenada'
    | 'Guadeloupe'
    | 'Guam'
    | 'Guatemala'
    | 'Guernsey'
    | 'Guinea'
    | 'Guinea Bissau'
    | 'Guyana'
    | 'Haiti'
    | 'Heard Island Mcdonald Islands'
    | 'Holy See Vatican City State'
    | 'Honduras'
    | 'Hong Kong'
    | 'Hungary'
    | 'Iceland'
    | 'India'
    | 'Indonesia'
    | 'Iran'
    | 'Iraq'
    | 'Ireland'
    | 'Isle Of Man'
    | 'Israel'
    | 'Italy'
    | 'Jamaica'
    | 'Japan'
    | 'Jersey'
    | 'Jordan'
    | 'Kazakhstan'
    | 'Kenya'
    | 'Kiribati'
    | 'Korea'
    | 'Korea Democratic Peoples Republic'
    | 'Kuwait'
    | 'Kyrgyzstan'
    | 'Lao Peoples Democratic Republic'
    | 'Latvia'
    | 'Lebanon'
    | 'Lesotho'
    | 'Liberia'
    | 'Libyan Arab Jamahiriya'
    | 'Liechtenstein'
    | 'Lithuania'
    | 'Luxembourg'
    | 'Macao'
    | 'Macedonia'
    | 'Madagascar'
    | 'Malawi'
    | 'Malaysia'
    | 'Maldives'
    | 'Mali'
    | 'Malta'
    | 'Marshall Islands'
    | 'Martinique'
    | 'Mauritania'
    | 'Mauritius'
    | 'Mayotte'
    | 'Mexico'
    | 'Micronesia'
    | 'Moldova'
    | 'Monaco'
    | 'Mongolia'
    | 'Montenegro'
    | 'Montserrat'
    | 'Morocco'
    | 'Mozambique'
    | 'Myanmar'
    | 'Namibia'
    | 'Nauru'
    | 'Nepal'
    | 'Netherlands'
    | 'New Caledonia'
    | 'New Zealand'
    | 'Nicaragua'
    | 'Niger'
    | 'Nigeria'
    | 'Niue'
    | 'Norfolk Island'
    | 'Northern Mariana Islands'
    | 'Norway'
    | 'Oman'
    | 'Pakistan'
    | 'Palau'
    | 'Palestinian Territory'
    | 'Panama'
    | 'Papua New Guinea'
    | 'Paraguay'
    | 'Peru'
    | 'Philippines'
    | 'Pitcairn'
    | 'Poland'
    | 'Portugal'
    | 'Puerto Rico'
    | 'Qatar'
    | 'Reunion'
    | 'Romania'
    | 'Russian Federation'
    | 'Rwanda'
    | 'Saint Barthelemy'
    | 'Saint Helena'
    | 'Saint Kitts And Nevis'
    | 'Saint Lucia'
    | 'Saint Martin'
    | 'Saint Pierre And Miquelon'
    | 'Saint Vincent And Grenadines'
    | 'Samoa'
    | 'San Marino'
    | 'Sao Tome And Principe'
    | 'Saudi Arabia'
    | 'Senegal'
    | 'Serbia'
    | 'Seychelles'
    | 'Sierra Leone'
    | 'Singapore'
    | 'Sint Maarten'
    | 'Slovakia'
    | 'Slovenia'
    | 'Solomon Islands'
    | 'Somalia'
    | 'South Africa'
    | 'South Georgia And Sandwich Isl'
    | 'South Sudan'
    | 'Spain'
    | 'Sri Lanka'
    | 'Sudan'
    | 'Suriname'
    | 'Svalbard And Jan Mayen'
    | 'Swaziland'
    | 'Sweden'
    | 'Switzerland'
    | 'Syrian Arab Republic'
    | 'Taiwan'
    | 'Tajikistan'
    | 'Tanzania'
    | 'Thailand'
    | 'Timor Leste'
    | 'Togo'
    | 'Tokelau'
    | 'Tonga'
    | 'Trinidad And Tobago'
    | 'Tunisia'
    | 'Turkey'
    | 'Turkmenistan'
    | 'Turks And Caicos Islands'
    | 'Tuvalu'
    | 'Uganda'
    | 'Ukraine'
    | 'United Arab Emirates'
    | 'United Kingdom'
    | 'United States'
    | 'United States Outlying Islands'
    | 'Uruguay'
    | 'Uzbekistan'
    | 'Vanuatu'
    | 'Venezuela'
    | 'Viet Nam'
    | 'Virgin Islands British'
    | 'Virgin Islands U S'
    | 'Wallis And Futuna'
    | 'Western Sahara'
    | 'Yemen'
    | 'Zambia'
    | 'Zimbabwe';
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type AddressControllerSearchError = Fetcher.ErrorWrapper<undefined>;

export type AddressControllerSearchVariables = {
  queryParams?: AddressControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchAddressControllerSearch = (
  variables: AddressControllerSearchVariables
) =>
  apiFetch<
    Schemas.AddressList,
    AddressControllerSearchError,
    undefined,
    {},
    AddressControllerSearchQueryParams,
    {}
  >({ url: '/v1/address', method: 'get', ...variables });

export const useAddressControllerSearch = <TData = Schemas.AddressList>(
  variables: AddressControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AddressList,
      AddressControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.AddressList,
    AddressControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/address',
      operationId: 'addressControllerSearch',
      variables,
    }),
    () => fetchAddressControllerSearch({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type AddressControllerRetrievePathParams = {
  id: string;
};

export type AddressControllerRetrieveError = Fetcher.ErrorWrapper<undefined>;

export type AddressControllerRetrieveVariables = {
  pathParams: AddressControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchAddressControllerRetrieve = (
  variables: AddressControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.AddressResponse,
    AddressControllerRetrieveError,
    undefined,
    {},
    {},
    AddressControllerRetrievePathParams
  >({ url: '/v1/address/{id}', method: 'get', ...variables });

export const useAddressControllerRetrieve = <TData = Schemas.AddressResponse>(
  variables: AddressControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AddressResponse,
      AddressControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.AddressResponse,
    AddressControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/address/{id}',
      operationId: 'addressControllerRetrieve',
      variables,
    }),
    () => fetchAddressControllerRetrieve({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type AddressControllerUpdatePathParams = {
  id: string;
};

export type AddressControllerUpdateError = Fetcher.ErrorWrapper<undefined>;

export type AddressControllerUpdateVariables = {
  body?: Schemas.AddressUpdate;
  pathParams: AddressControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchAddressControllerUpdate = (
  variables: AddressControllerUpdateVariables
) =>
  apiFetch<
    Schemas.AddressResponse,
    AddressControllerUpdateError,
    Schemas.AddressUpdate,
    {},
    {},
    AddressControllerUpdatePathParams
  >({ url: '/v1/address/{id}', method: 'patch', ...variables });

export const useAddressControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AddressResponse,
      AddressControllerUpdateError,
      AddressControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AddressResponse,
    AddressControllerUpdateError,
    AddressControllerUpdateVariables
  >(
    (variables: AddressControllerUpdateVariables) =>
      fetchAddressControllerUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type AddressControllerDeletePathParams = {
  id: string;
};

export type AddressControllerDeleteError = Fetcher.ErrorWrapper<undefined>;

export type AddressControllerDeleteVariables = {
  pathParams: AddressControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchAddressControllerDelete = (
  variables: AddressControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    AddressControllerDeleteError,
    undefined,
    {},
    {},
    AddressControllerDeletePathParams
  >({ url: '/v1/address/{id}', method: 'delete', ...variables });

export const useAddressControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      AddressControllerDeleteError,
      AddressControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    AddressControllerDeleteError,
    AddressControllerDeleteVariables
  >(
    (variables: AddressControllerDeleteVariables) =>
      fetchAddressControllerDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type BulkOrganizationProductControllerCopyFromOrganizationError =
  Fetcher.ErrorWrapper<undefined>;

export type BulkOrganizationProductControllerCopyFromOrganizationVariables = {
  body: Schemas.BulkOrganizationProductCopy;
} & ApiContext['fetcherOptions'];

export const fetchBulkOrganizationProductControllerCopyFromOrganization = (
  variables: BulkOrganizationProductControllerCopyFromOrganizationVariables
) =>
  apiFetch<
    Schemas.OrganizationProductResponseList,
    BulkOrganizationProductControllerCopyFromOrganizationError,
    Schemas.BulkOrganizationProductCopy,
    {},
    {},
    {}
  >({ url: '/v1/bulk-organization-product', method: 'post', ...variables });

export const useBulkOrganizationProductControllerCopyFromOrganization = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OrganizationProductResponseList,
      BulkOrganizationProductControllerCopyFromOrganizationError,
      BulkOrganizationProductControllerCopyFromOrganizationVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.OrganizationProductResponseList,
    BulkOrganizationProductControllerCopyFromOrganizationError,
    BulkOrganizationProductControllerCopyFromOrganizationVariables
  >(
    (
      variables: BulkOrganizationProductControllerCopyFromOrganizationVariables
    ) =>
      fetchBulkOrganizationProductControllerCopyFromOrganization({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type OrganizationProductControllerCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type OrganizationProductControllerCreateVariables = {
  body: Schemas.OrganizationProductCreate;
} & ApiContext['fetcherOptions'];

export const fetchOrganizationProductControllerCreate = (
  variables: OrganizationProductControllerCreateVariables
) =>
  apiFetch<
    Schemas.OrganizationProductResponse,
    OrganizationProductControllerCreateError,
    Schemas.OrganizationProductCreate,
    {},
    {},
    {}
  >({ url: '/v1/organization-product', method: 'post', ...variables });

export const useOrganizationProductControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OrganizationProductResponse,
      OrganizationProductControllerCreateError,
      OrganizationProductControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.OrganizationProductResponse,
    OrganizationProductControllerCreateError,
    OrganizationProductControllerCreateVariables
  >(
    (variables: OrganizationProductControllerCreateVariables) =>
      fetchOrganizationProductControllerCreate({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type OrganizationProductControllerSearchQueryParams = {
  /**
   * The unique identifier of the associated organization. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  organizationId?: string;
  /**
   * The unique identifier of the associated product. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  productId?: string;
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type OrganizationProductControllerSearchError =
  Fetcher.ErrorWrapper<undefined>;

export type OrganizationProductControllerSearchVariables = {
  queryParams?: OrganizationProductControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchOrganizationProductControllerSearch = (
  variables: OrganizationProductControllerSearchVariables
) =>
  apiFetch<
    Schemas.OrganizationProductResponseList,
    OrganizationProductControllerSearchError,
    undefined,
    {},
    OrganizationProductControllerSearchQueryParams,
    {}
  >({ url: '/v1/organization-product', method: 'get', ...variables });

export const useOrganizationProductControllerSearch = <
  TData = Schemas.OrganizationProductResponseList
>(
  variables: OrganizationProductControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.OrganizationProductResponseList,
      OrganizationProductControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.OrganizationProductResponseList,
    OrganizationProductControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/organization-product',
      operationId: 'organizationProductControllerSearch',
      variables,
    }),
    () =>
      fetchOrganizationProductControllerSearch({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type OrganizationProductControllerRetrievePathParams = {
  id: string;
};

export type OrganizationProductControllerRetrieveError =
  Fetcher.ErrorWrapper<undefined>;

export type OrganizationProductControllerRetrieveVariables = {
  pathParams: OrganizationProductControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchOrganizationProductControllerRetrieve = (
  variables: OrganizationProductControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.OrganizationProductResponse,
    OrganizationProductControllerRetrieveError,
    undefined,
    {},
    {},
    OrganizationProductControllerRetrievePathParams
  >({ url: '/v1/organization-product/{id}', method: 'get', ...variables });

export const useOrganizationProductControllerRetrieve = <
  TData = Schemas.OrganizationProductResponse
>(
  variables: OrganizationProductControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.OrganizationProductResponse,
      OrganizationProductControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.OrganizationProductResponse,
    OrganizationProductControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/organization-product/{id}',
      operationId: 'organizationProductControllerRetrieve',
      variables,
    }),
    () =>
      fetchOrganizationProductControllerRetrieve({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type OrganizationProductControllerDeletePathParams = {
  id: string;
};

export type OrganizationProductControllerDeleteError =
  Fetcher.ErrorWrapper<undefined>;

export type OrganizationProductControllerDeleteVariables = {
  pathParams: OrganizationProductControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchOrganizationProductControllerDelete = (
  variables: OrganizationProductControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    OrganizationProductControllerDeleteError,
    undefined,
    {},
    {},
    OrganizationProductControllerDeletePathParams
  >({ url: '/v1/organization-product/{id}', method: 'delete', ...variables });

export const useOrganizationProductControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      OrganizationProductControllerDeleteError,
      OrganizationProductControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    OrganizationProductControllerDeleteError,
    OrganizationProductControllerDeleteVariables
  >(
    (variables: OrganizationProductControllerDeleteVariables) =>
      fetchOrganizationProductControllerDelete({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type OrganizationControllerCreateError = Fetcher.ErrorWrapper<undefined>;

export type OrganizationControllerCreateVariables = {
  body: Schemas.OrganizationCreate;
} & ApiContext['fetcherOptions'];

export const fetchOrganizationControllerCreate = (
  variables: OrganizationControllerCreateVariables
) =>
  apiFetch<
    Schemas.Organization,
    OrganizationControllerCreateError,
    Schemas.OrganizationCreate,
    {},
    {},
    {}
  >({ url: '/v1/organizations', method: 'post', ...variables });

export const useOrganizationControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Organization,
      OrganizationControllerCreateError,
      OrganizationControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.Organization,
    OrganizationControllerCreateError,
    OrganizationControllerCreateVariables
  >(
    (variables: OrganizationControllerCreateVariables) =>
      fetchOrganizationControllerCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type OrganizationControllerSearchQueryParams = {
  name?: string;
  billingNumber?: string;
  appleDepNumber?: string;
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type OrganizationControllerSearchError = Fetcher.ErrorWrapper<undefined>;

export type OrganizationControllerSearchVariables = {
  queryParams?: OrganizationControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchOrganizationControllerSearch = (
  variables: OrganizationControllerSearchVariables
) =>
  apiFetch<
    Schemas.OrganizationSearchResponse,
    OrganizationControllerSearchError,
    undefined,
    {},
    OrganizationControllerSearchQueryParams,
    {}
  >({ url: '/v1/organizations', method: 'get', ...variables });

export const useOrganizationControllerSearch = <
  TData = Schemas.OrganizationSearchResponse
>(
  variables: OrganizationControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.OrganizationSearchResponse,
      OrganizationControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.OrganizationSearchResponse,
    OrganizationControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/organizations',
      operationId: 'organizationControllerSearch',
      variables,
    }),
    () =>
      fetchOrganizationControllerSearch({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type OrganizationControllerRetrievePathParams = {
  id: string;
};

export type OrganizationControllerRetrieveError =
  Fetcher.ErrorWrapper<undefined>;

export type OrganizationControllerRetrieveVariables = {
  pathParams: OrganizationControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchOrganizationControllerRetrieve = (
  variables: OrganizationControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.Organization,
    OrganizationControllerRetrieveError,
    undefined,
    {},
    {},
    OrganizationControllerRetrievePathParams
  >({ url: '/v1/organizations/{id}', method: 'get', ...variables });

export const useOrganizationControllerRetrieve = <TData = Schemas.Organization>(
  variables: OrganizationControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.Organization,
      OrganizationControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.Organization,
    OrganizationControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/organizations/{id}',
      operationId: 'organizationControllerRetrieve',
      variables,
    }),
    () =>
      fetchOrganizationControllerRetrieve({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type OrganizationControllerUpdatePathParams = {
  id: string;
};

export type OrganizationControllerUpdateError = Fetcher.ErrorWrapper<undefined>;

export type OrganizationControllerUpdateVariables = {
  body?: Schemas.OrganizationUpdate;
  pathParams: OrganizationControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchOrganizationControllerUpdate = (
  variables: OrganizationControllerUpdateVariables
) =>
  apiFetch<
    Schemas.Organization,
    OrganizationControllerUpdateError,
    Schemas.OrganizationUpdate,
    {},
    {},
    OrganizationControllerUpdatePathParams
  >({ url: '/v1/organizations/{id}', method: 'patch', ...variables });

export const useOrganizationControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Organization,
      OrganizationControllerUpdateError,
      OrganizationControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.Organization,
    OrganizationControllerUpdateError,
    OrganizationControllerUpdateVariables
  >(
    (variables: OrganizationControllerUpdateVariables) =>
      fetchOrganizationControllerUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type OrganizationControllerDeletePathParams = {
  id: string;
};

export type OrganizationControllerDeleteError = Fetcher.ErrorWrapper<undefined>;

export type OrganizationControllerDeleteVariables = {
  pathParams: OrganizationControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchOrganizationControllerDelete = (
  variables: OrganizationControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    OrganizationControllerDeleteError,
    undefined,
    {},
    {},
    OrganizationControllerDeletePathParams
  >({ url: '/v1/organizations/{id}', method: 'delete', ...variables });

export const useOrganizationControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      OrganizationControllerDeleteError,
      OrganizationControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    OrganizationControllerDeleteError,
    OrganizationControllerDeleteVariables
  >(
    (variables: OrganizationControllerDeleteVariables) =>
      fetchOrganizationControllerDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type CheckoutControllerCreateError = Fetcher.ErrorWrapper<undefined>;

export type CheckoutControllerCreateVariables = {
  body: Schemas.CheckoutInputDto;
} & ApiContext['fetcherOptions'];

export const fetchCheckoutControllerCreate = (
  variables: CheckoutControllerCreateVariables
) =>
  apiFetch<
    Schemas.CheckoutOutputDto,
    CheckoutControllerCreateError,
    Schemas.CheckoutInputDto,
    {},
    {},
    {}
  >({ url: '/v1/checkout', method: 'post', ...variables });

export const useCheckoutControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CheckoutOutputDto,
      CheckoutControllerCreateError,
      CheckoutControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.CheckoutOutputDto,
    CheckoutControllerCreateError,
    CheckoutControllerCreateVariables
  >(
    (variables: CheckoutControllerCreateVariables) =>
      fetchCheckoutControllerCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type CollaboratorAddressControllerCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type CollaboratorAddressControllerCreateVariables = {
  body: Schemas.CollaboratorAddressCreate;
} & ApiContext['fetcherOptions'];

export const fetchCollaboratorAddressControllerCreate = (
  variables: CollaboratorAddressControllerCreateVariables
) =>
  apiFetch<
    Schemas.CollaboratorAddress,
    CollaboratorAddressControllerCreateError,
    Schemas.CollaboratorAddressCreate,
    {},
    {},
    {}
  >({ url: '/v1/collaborator-address', method: 'post', ...variables });

export const useCollaboratorAddressControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CollaboratorAddress,
      CollaboratorAddressControllerCreateError,
      CollaboratorAddressControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.CollaboratorAddress,
    CollaboratorAddressControllerCreateError,
    CollaboratorAddressControllerCreateVariables
  >(
    (variables: CollaboratorAddressControllerCreateVariables) =>
      fetchCollaboratorAddressControllerCreate({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type CollaboratorAddressControllerSearchQueryParams = {
  id?: string;
  addressId?: string;
  collaboratorId?: string;
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type CollaboratorAddressControllerSearchError =
  Fetcher.ErrorWrapper<undefined>;

export type CollaboratorAddressControllerSearchVariables = {
  queryParams?: CollaboratorAddressControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchCollaboratorAddressControllerSearch = (
  variables: CollaboratorAddressControllerSearchVariables
) =>
  apiFetch<
    Schemas.CollaboratorAddressList,
    CollaboratorAddressControllerSearchError,
    undefined,
    {},
    CollaboratorAddressControllerSearchQueryParams,
    {}
  >({ url: '/v1/collaborator-address', method: 'get', ...variables });

export const useCollaboratorAddressControllerSearch = <
  TData = Schemas.CollaboratorAddressList
>(
  variables: CollaboratorAddressControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.CollaboratorAddressList,
      CollaboratorAddressControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.CollaboratorAddressList,
    CollaboratorAddressControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/collaborator-address',
      operationId: 'collaboratorAddressControllerSearch',
      variables,
    }),
    () =>
      fetchCollaboratorAddressControllerSearch({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type CollaboratorAddressControllerRetrievePathParams = {
  id: string;
};

export type CollaboratorAddressControllerRetrieveError =
  Fetcher.ErrorWrapper<undefined>;

export type CollaboratorAddressControllerRetrieveVariables = {
  pathParams: CollaboratorAddressControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchCollaboratorAddressControllerRetrieve = (
  variables: CollaboratorAddressControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.CollaboratorAddress,
    CollaboratorAddressControllerRetrieveError,
    undefined,
    {},
    {},
    CollaboratorAddressControllerRetrievePathParams
  >({ url: '/v1/collaborator-address/{id}', method: 'get', ...variables });

export const useCollaboratorAddressControllerRetrieve = <
  TData = Schemas.CollaboratorAddress
>(
  variables: CollaboratorAddressControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.CollaboratorAddress,
      CollaboratorAddressControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.CollaboratorAddress,
    CollaboratorAddressControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/collaborator-address/{id}',
      operationId: 'collaboratorAddressControllerRetrieve',
      variables,
    }),
    () =>
      fetchCollaboratorAddressControllerRetrieve({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type CollaboratorAddressControllerUpdatePathParams = {
  id: string;
};

export type CollaboratorAddressControllerUpdateError =
  Fetcher.ErrorWrapper<undefined>;

export type CollaboratorAddressControllerUpdateVariables = {
  body?: Schemas.CollaboratorAddressUpdate;
  pathParams: CollaboratorAddressControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchCollaboratorAddressControllerUpdate = (
  variables: CollaboratorAddressControllerUpdateVariables
) =>
  apiFetch<
    Schemas.CollaboratorAddressResponse,
    CollaboratorAddressControllerUpdateError,
    Schemas.CollaboratorAddressUpdate,
    {},
    {},
    CollaboratorAddressControllerUpdatePathParams
  >({ url: '/v1/collaborator-address/{id}', method: 'patch', ...variables });

export const useCollaboratorAddressControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CollaboratorAddressResponse,
      CollaboratorAddressControllerUpdateError,
      CollaboratorAddressControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.CollaboratorAddressResponse,
    CollaboratorAddressControllerUpdateError,
    CollaboratorAddressControllerUpdateVariables
  >(
    (variables: CollaboratorAddressControllerUpdateVariables) =>
      fetchCollaboratorAddressControllerUpdate({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type CollaboratorAddressControllerDeletePathParams = {
  id: string;
};

export type CollaboratorAddressControllerDeleteError =
  Fetcher.ErrorWrapper<undefined>;

export type CollaboratorAddressControllerDeleteVariables = {
  pathParams: CollaboratorAddressControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchCollaboratorAddressControllerDelete = (
  variables: CollaboratorAddressControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    CollaboratorAddressControllerDeleteError,
    undefined,
    {},
    {},
    CollaboratorAddressControllerDeletePathParams
  >({ url: '/v1/collaborator-address/{id}', method: 'delete', ...variables });

export const useCollaboratorAddressControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      CollaboratorAddressControllerDeleteError,
      CollaboratorAddressControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    CollaboratorAddressControllerDeleteError,
    CollaboratorAddressControllerDeleteVariables
  >(
    (variables: CollaboratorAddressControllerDeleteVariables) =>
      fetchCollaboratorAddressControllerDelete({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type CollaboratorControllerCreateError = Fetcher.ErrorWrapper<undefined>;

export type CollaboratorControllerCreateVariables = {
  body: Schemas.CollaboratorCreate;
} & ApiContext['fetcherOptions'];

export const fetchCollaboratorControllerCreate = (
  variables: CollaboratorControllerCreateVariables
) =>
  apiFetch<
    Schemas.CollaboratorResponse,
    CollaboratorControllerCreateError,
    Schemas.CollaboratorCreate,
    {},
    {},
    {}
  >({ url: '/v1/collaborator', method: 'post', ...variables });

export const useCollaboratorControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CollaboratorResponse,
      CollaboratorControllerCreateError,
      CollaboratorControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.CollaboratorResponse,
    CollaboratorControllerCreateError,
    CollaboratorControllerCreateVariables
  >(
    (variables: CollaboratorControllerCreateVariables) =>
      fetchCollaboratorControllerCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type CollaboratorControllerSearchQueryParams = {
  firstName?: string;
  lastName?: string;
  email?: string;
  /**
   * TODO:
   * this should not be exposed.
   * it should be removed once the admin dashboard can do without this property
   */
  identityProviderId?: string;
  organizationId?: string;
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
  fullTextSearch?: string;
};

export type CollaboratorControllerSearchError = Fetcher.ErrorWrapper<undefined>;

export type CollaboratorControllerSearchVariables = {
  queryParams?: CollaboratorControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchCollaboratorControllerSearch = (
  variables: CollaboratorControllerSearchVariables
) =>
  apiFetch<
    Schemas.CollaboratorList,
    CollaboratorControllerSearchError,
    undefined,
    {},
    CollaboratorControllerSearchQueryParams,
    {}
  >({ url: '/v1/collaborator', method: 'get', ...variables });

export const useCollaboratorControllerSearch = <
  TData = Schemas.CollaboratorList
>(
  variables: CollaboratorControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.CollaboratorList,
      CollaboratorControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.CollaboratorList,
    CollaboratorControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/collaborator',
      operationId: 'collaboratorControllerSearch',
      variables,
    }),
    () =>
      fetchCollaboratorControllerSearch({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type CollaboratorControllerRetrievePathParams = {
  id: string;
};

export type CollaboratorControllerRetrieveError =
  Fetcher.ErrorWrapper<undefined>;

export type CollaboratorControllerRetrieveVariables = {
  pathParams: CollaboratorControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchCollaboratorControllerRetrieve = (
  variables: CollaboratorControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.CollaboratorResponse,
    CollaboratorControllerRetrieveError,
    undefined,
    {},
    {},
    CollaboratorControllerRetrievePathParams
  >({ url: '/v1/collaborator/{id}', method: 'get', ...variables });

export const useCollaboratorControllerRetrieve = <
  TData = Schemas.CollaboratorResponse
>(
  variables: CollaboratorControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.CollaboratorResponse,
      CollaboratorControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.CollaboratorResponse,
    CollaboratorControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/collaborator/{id}',
      operationId: 'collaboratorControllerRetrieve',
      variables,
    }),
    () =>
      fetchCollaboratorControllerRetrieve({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type CollaboratorControllerUpdatePathParams = {
  id: string;
};

export type CollaboratorControllerUpdateError = Fetcher.ErrorWrapper<undefined>;

export type CollaboratorControllerUpdateVariables = {
  body?: Schemas.CollaboratorUpdate;
  pathParams: CollaboratorControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchCollaboratorControllerUpdate = (
  variables: CollaboratorControllerUpdateVariables
) =>
  apiFetch<
    Schemas.CollaboratorResponse,
    CollaboratorControllerUpdateError,
    Schemas.CollaboratorUpdate,
    {},
    {},
    CollaboratorControllerUpdatePathParams
  >({ url: '/v1/collaborator/{id}', method: 'patch', ...variables });

export const useCollaboratorControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CollaboratorResponse,
      CollaboratorControllerUpdateError,
      CollaboratorControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.CollaboratorResponse,
    CollaboratorControllerUpdateError,
    CollaboratorControllerUpdateVariables
  >(
    (variables: CollaboratorControllerUpdateVariables) =>
      fetchCollaboratorControllerUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type CollaboratorControllerDeletePathParams = {
  id: string;
};

export type CollaboratorControllerDeleteError = Fetcher.ErrorWrapper<undefined>;

export type CollaboratorControllerDeleteVariables = {
  pathParams: CollaboratorControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchCollaboratorControllerDelete = (
  variables: CollaboratorControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    CollaboratorControllerDeleteError,
    undefined,
    {},
    {},
    CollaboratorControllerDeletePathParams
  >({ url: '/v1/collaborator/{id}', method: 'delete', ...variables });

export const useCollaboratorControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      CollaboratorControllerDeleteError,
      CollaboratorControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    CollaboratorControllerDeleteError,
    CollaboratorControllerDeleteVariables
  >(
    (variables: CollaboratorControllerDeleteVariables) =>
      fetchCollaboratorControllerDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProductVariantAttributeControllerCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type ProductVariantAttributeControllerCreateVariables = {
  body: Schemas.ProductVariantAttributeCreate;
} & ApiContext['fetcherOptions'];

export const fetchProductVariantAttributeControllerCreate = (
  variables: ProductVariantAttributeControllerCreateVariables
) =>
  apiFetch<
    Schemas.ProductVariantAttributeResponse,
    ProductVariantAttributeControllerCreateError,
    Schemas.ProductVariantAttributeCreate,
    {},
    {},
    {}
  >({ url: '/v1/product-variant-attribute', method: 'post', ...variables });

export const useProductVariantAttributeControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ProductVariantAttributeResponse,
      ProductVariantAttributeControllerCreateError,
      ProductVariantAttributeControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ProductVariantAttributeResponse,
    ProductVariantAttributeControllerCreateError,
    ProductVariantAttributeControllerCreateVariables
  >(
    (variables: ProductVariantAttributeControllerCreateVariables) =>
      fetchProductVariantAttributeControllerCreate({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type ProductVariantAttributeControllerSearchQueryParams = {
  /**
   * The product variant unique identifier. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  productVariantId?: string;
  /**
   * The product variant attribute name / key
   */
  name?: string;
  /**
   * The product variant attribute value
   */
  value?: string;
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type ProductVariantAttributeControllerSearchError =
  Fetcher.ErrorWrapper<undefined>;

export type ProductVariantAttributeControllerSearchVariables = {
  queryParams?: ProductVariantAttributeControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchProductVariantAttributeControllerSearch = (
  variables: ProductVariantAttributeControllerSearchVariables
) =>
  apiFetch<
    Schemas.ProductVariantAttributeResponseList,
    ProductVariantAttributeControllerSearchError,
    undefined,
    {},
    ProductVariantAttributeControllerSearchQueryParams,
    {}
  >({ url: '/v1/product-variant-attribute', method: 'get', ...variables });

export const useProductVariantAttributeControllerSearch = <
  TData = Schemas.ProductVariantAttributeResponseList
>(
  variables: ProductVariantAttributeControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ProductVariantAttributeResponseList,
      ProductVariantAttributeControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ProductVariantAttributeResponseList,
    ProductVariantAttributeControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/product-variant-attribute',
      operationId: 'productVariantAttributeControllerSearch',
      variables,
    }),
    () =>
      fetchProductVariantAttributeControllerSearch({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProductVariantAttributeControllerRetrievePathParams = {
  id: string;
};

export type ProductVariantAttributeControllerRetrieveError =
  Fetcher.ErrorWrapper<undefined>;

export type ProductVariantAttributeControllerRetrieveVariables = {
  pathParams: ProductVariantAttributeControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchProductVariantAttributeControllerRetrieve = (
  variables: ProductVariantAttributeControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.ProductVariantAttributeResponse,
    ProductVariantAttributeControllerRetrieveError,
    undefined,
    {},
    {},
    ProductVariantAttributeControllerRetrievePathParams
  >({ url: '/v1/product-variant-attribute/{id}', method: 'get', ...variables });

export const useProductVariantAttributeControllerRetrieve = <
  TData = Schemas.ProductVariantAttributeResponse
>(
  variables: ProductVariantAttributeControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ProductVariantAttributeResponse,
      ProductVariantAttributeControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ProductVariantAttributeResponse,
    ProductVariantAttributeControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/product-variant-attribute/{id}',
      operationId: 'productVariantAttributeControllerRetrieve',
      variables,
    }),
    () =>
      fetchProductVariantAttributeControllerRetrieve({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProductVariantAttributeControllerUpdatePathParams = {
  id: string;
};

export type ProductVariantAttributeControllerUpdateError =
  Fetcher.ErrorWrapper<undefined>;

export type ProductVariantAttributeControllerUpdateVariables = {
  body?: Schemas.ProductVariantAttributeUpdate;
  pathParams: ProductVariantAttributeControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchProductVariantAttributeControllerUpdate = (
  variables: ProductVariantAttributeControllerUpdateVariables
) =>
  apiFetch<
    Schemas.ProductVariantAttributeResponse,
    ProductVariantAttributeControllerUpdateError,
    Schemas.ProductVariantAttributeUpdate,
    {},
    {},
    ProductVariantAttributeControllerUpdatePathParams
  >({
    url: '/v1/product-variant-attribute/{id}',
    method: 'patch',
    ...variables,
  });

export const useProductVariantAttributeControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ProductVariantAttributeResponse,
      ProductVariantAttributeControllerUpdateError,
      ProductVariantAttributeControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ProductVariantAttributeResponse,
    ProductVariantAttributeControllerUpdateError,
    ProductVariantAttributeControllerUpdateVariables
  >(
    (variables: ProductVariantAttributeControllerUpdateVariables) =>
      fetchProductVariantAttributeControllerUpdate({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type ProductVariantAttributeControllerDeletePathParams = {
  id: string;
};

export type ProductVariantAttributeControllerDeleteError =
  Fetcher.ErrorWrapper<undefined>;

export type ProductVariantAttributeControllerDeleteVariables = {
  pathParams: ProductVariantAttributeControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchProductVariantAttributeControllerDelete = (
  variables: ProductVariantAttributeControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    ProductVariantAttributeControllerDeleteError,
    undefined,
    {},
    {},
    ProductVariantAttributeControllerDeletePathParams
  >({
    url: '/v1/product-variant-attribute/{id}',
    method: 'delete',
    ...variables,
  });

export const useProductVariantAttributeControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProductVariantAttributeControllerDeleteError,
      ProductVariantAttributeControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ProductVariantAttributeControllerDeleteError,
    ProductVariantAttributeControllerDeleteVariables
  >(
    (variables: ProductVariantAttributeControllerDeleteVariables) =>
      fetchProductVariantAttributeControllerDelete({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type ProductCategoryControllerCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type ProductCategoryControllerCreateVariables = {
  body: Schemas.ProductCategoryCreate;
} & ApiContext['fetcherOptions'];

export const fetchProductCategoryControllerCreate = (
  variables: ProductCategoryControllerCreateVariables
) =>
  apiFetch<
    Schemas.ProductCategoryDto,
    ProductCategoryControllerCreateError,
    Schemas.ProductCategoryCreate,
    {},
    {},
    {}
  >({ url: '/v1/product-category', method: 'post', ...variables });

export const useProductCategoryControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ProductCategoryDto,
      ProductCategoryControllerCreateError,
      ProductCategoryControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ProductCategoryDto,
    ProductCategoryControllerCreateError,
    ProductCategoryControllerCreateVariables
  >(
    (variables: ProductCategoryControllerCreateVariables) =>
      fetchProductCategoryControllerCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProductCategoryControllerSearchQueryParams = {
  /**
   * The unique identifier of the product category image. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  imageId?: string;
  /**
   * The category name.
   */
  name?: string;
  /**
   * The category description.
   */
  description?: string;
  /**
   * The index indicating the order of the categories.
   */
  index?: number;
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type ProductCategoryControllerSearchError =
  Fetcher.ErrorWrapper<undefined>;

export type ProductCategoryControllerSearchVariables = {
  queryParams?: ProductCategoryControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchProductCategoryControllerSearch = (
  variables: ProductCategoryControllerSearchVariables
) =>
  apiFetch<
    Schemas.ProductCategoryDtoList,
    ProductCategoryControllerSearchError,
    undefined,
    {},
    ProductCategoryControllerSearchQueryParams,
    {}
  >({ url: '/v1/product-category', method: 'get', ...variables });

export const useProductCategoryControllerSearch = <
  TData = Schemas.ProductCategoryDtoList
>(
  variables: ProductCategoryControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ProductCategoryDtoList,
      ProductCategoryControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ProductCategoryDtoList,
    ProductCategoryControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/product-category',
      operationId: 'productCategoryControllerSearch',
      variables,
    }),
    () =>
      fetchProductCategoryControllerSearch({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProductCategoryControllerRetrievePathParams = {
  id: string;
};

export type ProductCategoryControllerRetrieveError =
  Fetcher.ErrorWrapper<undefined>;

export type ProductCategoryControllerRetrieveVariables = {
  pathParams: ProductCategoryControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchProductCategoryControllerRetrieve = (
  variables: ProductCategoryControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.ProductCategoryDto,
    ProductCategoryControllerRetrieveError,
    undefined,
    {},
    {},
    ProductCategoryControllerRetrievePathParams
  >({ url: '/v1/product-category/{id}', method: 'get', ...variables });

export const useProductCategoryControllerRetrieve = <
  TData = Schemas.ProductCategoryDto
>(
  variables: ProductCategoryControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ProductCategoryDto,
      ProductCategoryControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ProductCategoryDto,
    ProductCategoryControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/product-category/{id}',
      operationId: 'productCategoryControllerRetrieve',
      variables,
    }),
    () =>
      fetchProductCategoryControllerRetrieve({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProductCategoryControllerUpdatePathParams = {
  id: string;
};

export type ProductCategoryControllerUpdateError =
  Fetcher.ErrorWrapper<undefined>;

export type ProductCategoryControllerUpdateVariables = {
  body?: Schemas.ProductCategoryUpdate;
  pathParams: ProductCategoryControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchProductCategoryControllerUpdate = (
  variables: ProductCategoryControllerUpdateVariables
) =>
  apiFetch<
    Schemas.ProductCategoryDto,
    ProductCategoryControllerUpdateError,
    Schemas.ProductCategoryUpdate,
    {},
    {},
    ProductCategoryControllerUpdatePathParams
  >({ url: '/v1/product-category/{id}', method: 'patch', ...variables });

export const useProductCategoryControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ProductCategoryDto,
      ProductCategoryControllerUpdateError,
      ProductCategoryControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ProductCategoryDto,
    ProductCategoryControllerUpdateError,
    ProductCategoryControllerUpdateVariables
  >(
    (variables: ProductCategoryControllerUpdateVariables) =>
      fetchProductCategoryControllerUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProductCategoryControllerDeletePathParams = {
  id: string;
};

export type ProductCategoryControllerDeleteError =
  Fetcher.ErrorWrapper<undefined>;

export type ProductCategoryControllerDeleteVariables = {
  pathParams: ProductCategoryControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchProductCategoryControllerDelete = (
  variables: ProductCategoryControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    ProductCategoryControllerDeleteError,
    undefined,
    {},
    {},
    ProductCategoryControllerDeletePathParams
  >({ url: '/v1/product-category/{id}', method: 'delete', ...variables });

export const useProductCategoryControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProductCategoryControllerDeleteError,
      ProductCategoryControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ProductCategoryControllerDeleteError,
    ProductCategoryControllerDeleteVariables
  >(
    (variables: ProductCategoryControllerDeleteVariables) =>
      fetchProductCategoryControllerDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProductVariantControllerCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type ProductVariantControllerCreateVariables = {
  body: Schemas.ProductVariantCreate;
} & ApiContext['fetcherOptions'];

export const fetchProductVariantControllerCreate = (
  variables: ProductVariantControllerCreateVariables
) =>
  apiFetch<
    Schemas.ProductVariantResponse,
    ProductVariantControllerCreateError,
    Schemas.ProductVariantCreate,
    {},
    {},
    {}
  >({ url: '/v1/product-variant', method: 'post', ...variables });

export const useProductVariantControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ProductVariantResponse,
      ProductVariantControllerCreateError,
      ProductVariantControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ProductVariantResponse,
    ProductVariantControllerCreateError,
    ProductVariantControllerCreateVariables
  >(
    (variables: ProductVariantControllerCreateVariables) =>
      fetchProductVariantControllerCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProductVariantControllerSearchQueryParams = {
  /**
   * The unique identifier of the associated product. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  productId?: string;
  /**
   * The unique identifier of the associated image. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  imageId?: string;
  /**
   * Stock Keeping Unit - unique identifier for a product variant
   */
  sku?: string;
  /**
   * The description of the product variant. Omit this value to use the product description
   */
  description?: string;
  /**
   * The price of the product variant at this vendor in USD
   *
   * @pattern ^-?\d{1,3}(?:,?\d{3}){0,3}\.?\d{2}$
   */
  price?: string;
  /**
   * If the product variant should show up in the product catalog, etc.
   *
   * @default true
   */
  live?: boolean;
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type ProductVariantControllerSearchError =
  Fetcher.ErrorWrapper<undefined>;

export type ProductVariantControllerSearchVariables = {
  queryParams?: ProductVariantControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchProductVariantControllerSearch = (
  variables: ProductVariantControllerSearchVariables
) =>
  apiFetch<
    Schemas.ProductVariantResponseList,
    ProductVariantControllerSearchError,
    undefined,
    {},
    ProductVariantControllerSearchQueryParams,
    {}
  >({ url: '/v1/product-variant', method: 'get', ...variables });

export const useProductVariantControllerSearch = <
  TData = Schemas.ProductVariantResponseList
>(
  variables: ProductVariantControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ProductVariantResponseList,
      ProductVariantControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ProductVariantResponseList,
    ProductVariantControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/product-variant',
      operationId: 'productVariantControllerSearch',
      variables,
    }),
    () =>
      fetchProductVariantControllerSearch({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProductVariantControllerRetrievePathParams = {
  id: string;
};

export type ProductVariantControllerRetrieveError =
  Fetcher.ErrorWrapper<undefined>;

export type ProductVariantControllerRetrieveVariables = {
  pathParams: ProductVariantControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchProductVariantControllerRetrieve = (
  variables: ProductVariantControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.ProductVariantResponse,
    ProductVariantControllerRetrieveError,
    undefined,
    {},
    {},
    ProductVariantControllerRetrievePathParams
  >({ url: '/v1/product-variant/{id}', method: 'get', ...variables });

export const useProductVariantControllerRetrieve = <
  TData = Schemas.ProductVariantResponse
>(
  variables: ProductVariantControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ProductVariantResponse,
      ProductVariantControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ProductVariantResponse,
    ProductVariantControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/product-variant/{id}',
      operationId: 'productVariantControllerRetrieve',
      variables,
    }),
    () =>
      fetchProductVariantControllerRetrieve({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProductVariantControllerUpdatePathParams = {
  id: string;
};

export type ProductVariantControllerUpdateError =
  Fetcher.ErrorWrapper<undefined>;

export type ProductVariantControllerUpdateVariables = {
  body?: Schemas.ProductVariantUpdate;
  pathParams: ProductVariantControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchProductVariantControllerUpdate = (
  variables: ProductVariantControllerUpdateVariables
) =>
  apiFetch<
    Schemas.ProductVariantResponse,
    ProductVariantControllerUpdateError,
    Schemas.ProductVariantUpdate,
    {},
    {},
    ProductVariantControllerUpdatePathParams
  >({ url: '/v1/product-variant/{id}', method: 'patch', ...variables });

export const useProductVariantControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ProductVariantResponse,
      ProductVariantControllerUpdateError,
      ProductVariantControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ProductVariantResponse,
    ProductVariantControllerUpdateError,
    ProductVariantControllerUpdateVariables
  >(
    (variables: ProductVariantControllerUpdateVariables) =>
      fetchProductVariantControllerUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProductVariantControllerDeletePathParams = {
  id: string;
};

export type ProductVariantControllerDeleteError =
  Fetcher.ErrorWrapper<undefined>;

export type ProductVariantControllerDeleteVariables = {
  pathParams: ProductVariantControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchProductVariantControllerDelete = (
  variables: ProductVariantControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    ProductVariantControllerDeleteError,
    undefined,
    {},
    {},
    ProductVariantControllerDeletePathParams
  >({ url: '/v1/product-variant/{id}', method: 'delete', ...variables });

export const useProductVariantControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProductVariantControllerDeleteError,
      ProductVariantControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ProductVariantControllerDeleteError,
    ProductVariantControllerDeleteVariables
  >(
    (variables: ProductVariantControllerDeleteVariables) =>
      fetchProductVariantControllerDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProductControllerCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProductControllerCreateVariables = {
  body: Schemas.ProductCreate;
} & ApiContext['fetcherOptions'];

export const fetchProductControllerCreate = (
  variables: ProductControllerCreateVariables
) =>
  apiFetch<
    Schemas.ProductResponse,
    ProductControllerCreateError,
    Schemas.ProductCreate,
    {},
    {},
    {}
  >({ url: '/v1/product', method: 'post', ...variables });

export const useProductControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ProductResponse,
      ProductControllerCreateError,
      ProductControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ProductResponse,
    ProductControllerCreateError,
    ProductControllerCreateVariables
  >(
    (variables: ProductControllerCreateVariables) =>
      fetchProductControllerCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProductControllerSearchQueryParams = {
  /**
   * The unique identifier of the product category. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  productCategoryId?: string;
  /**
   * The unique identifier of the organization. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  organizationId?: string;
  /**
   * The manufacturer of the product
   */
  make?: string;
  /**
   * The name of the product
   */
  name?: string;
  /**
   * The product description
   */
  description?: string;
  /**
   * If the product should show up in the product catalog, etc.
   *
   * @default true
   */
  live?: boolean;
  id?: string;
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type ProductControllerSearchError = Fetcher.ErrorWrapper<undefined>;

export type ProductControllerSearchVariables = {
  queryParams?: ProductControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchProductControllerSearch = (
  variables: ProductControllerSearchVariables
) =>
  apiFetch<
    Schemas.ProductResponseList,
    ProductControllerSearchError,
    undefined,
    {},
    ProductControllerSearchQueryParams,
    {}
  >({ url: '/v1/product', method: 'get', ...variables });

export const useProductControllerSearch = <TData = Schemas.ProductResponseList>(
  variables: ProductControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ProductResponseList,
      ProductControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ProductResponseList,
    ProductControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/product',
      operationId: 'productControllerSearch',
      variables,
    }),
    () => fetchProductControllerSearch({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProductControllerRetrievePathParams = {
  id: string;
};

export type ProductControllerRetrieveError = Fetcher.ErrorWrapper<undefined>;

export type ProductControllerRetrieveVariables = {
  pathParams: ProductControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchProductControllerRetrieve = (
  variables: ProductControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.ProductResponse,
    ProductControllerRetrieveError,
    undefined,
    {},
    {},
    ProductControllerRetrievePathParams
  >({ url: '/v1/product/{id}', method: 'get', ...variables });

export const useProductControllerRetrieve = <TData = Schemas.ProductResponse>(
  variables: ProductControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ProductResponse,
      ProductControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ProductResponse,
    ProductControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/product/{id}',
      operationId: 'productControllerRetrieve',
      variables,
    }),
    () => fetchProductControllerRetrieve({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProductControllerUpdatePathParams = {
  id: string;
};

export type ProductControllerUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ProductControllerUpdateVariables = {
  body?: Schemas.ProductUpdate;
  pathParams: ProductControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchProductControllerUpdate = (
  variables: ProductControllerUpdateVariables
) =>
  apiFetch<
    Schemas.ProductResponse,
    ProductControllerUpdateError,
    Schemas.ProductUpdate,
    {},
    {},
    ProductControllerUpdatePathParams
  >({ url: '/v1/product/{id}', method: 'patch', ...variables });

export const useProductControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ProductResponse,
      ProductControllerUpdateError,
      ProductControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ProductResponse,
    ProductControllerUpdateError,
    ProductControllerUpdateVariables
  >(
    (variables: ProductControllerUpdateVariables) =>
      fetchProductControllerUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProductControllerDeletePathParams = {
  id: string;
};

export type ProductControllerDeleteError = Fetcher.ErrorWrapper<undefined>;

export type ProductControllerDeleteVariables = {
  pathParams: ProductControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchProductControllerDelete = (
  variables: ProductControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    ProductControllerDeleteError,
    undefined,
    {},
    {},
    ProductControllerDeletePathParams
  >({ url: '/v1/product/{id}', method: 'delete', ...variables });

export const useProductControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProductControllerDeleteError,
      ProductControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ProductControllerDeleteError,
    ProductControllerDeleteVariables
  >(
    (variables: ProductControllerDeleteVariables) =>
      fetchProductControllerDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type VendorProductVariantControllerCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type VendorProductVariantControllerCreateVariables = {
  body: Schemas.VendorProductVariantCreate;
} & ApiContext['fetcherOptions'];

export const fetchVendorProductVariantControllerCreate = (
  variables: VendorProductVariantControllerCreateVariables
) =>
  apiFetch<
    Schemas.VendorProductVariantResponse,
    VendorProductVariantControllerCreateError,
    Schemas.VendorProductVariantCreate,
    {},
    {},
    {}
  >({ url: '/v1/vendor-product-variant', method: 'post', ...variables });

export const useVendorProductVariantControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.VendorProductVariantResponse,
      VendorProductVariantControllerCreateError,
      VendorProductVariantControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.VendorProductVariantResponse,
    VendorProductVariantControllerCreateError,
    VendorProductVariantControllerCreateVariables
  >(
    (variables: VendorProductVariantControllerCreateVariables) =>
      fetchVendorProductVariantControllerCreate({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type VendorProductVariantControllerSearchQueryParams = {
  /**
   * The unique identifier. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  id?: string;
  /**
   * The unique identifier of the Vendor. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  vendorId?: string;
  /**
   * The unique identifier of the Product Variant. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  productVariantId?: string;
  /**
   * The product variant url for the given vendor
   */
  vendorUrl?: string;
  /**
   * The price of the product variant at this vendor in USD
   *
   * @pattern ^-?\d{1,3}(?:,?\d{3}){0,3}\.?\d{2}$
   */
  price?: string;
  /**
   * If the product is in stock at this vendor
   */
  inStock?: boolean;
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type VendorProductVariantControllerSearchError =
  Fetcher.ErrorWrapper<undefined>;

export type VendorProductVariantControllerSearchVariables = {
  queryParams?: VendorProductVariantControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchVendorProductVariantControllerSearch = (
  variables: VendorProductVariantControllerSearchVariables
) =>
  apiFetch<
    Schemas.VendorProductVariantResponseList,
    VendorProductVariantControllerSearchError,
    undefined,
    {},
    VendorProductVariantControllerSearchQueryParams,
    {}
  >({ url: '/v1/vendor-product-variant', method: 'get', ...variables });

export const useVendorProductVariantControllerSearch = <
  TData = Schemas.VendorProductVariantResponseList
>(
  variables: VendorProductVariantControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.VendorProductVariantResponseList,
      VendorProductVariantControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.VendorProductVariantResponseList,
    VendorProductVariantControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/vendor-product-variant',
      operationId: 'vendorProductVariantControllerSearch',
      variables,
    }),
    () =>
      fetchVendorProductVariantControllerSearch({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type VendorProductVariantControllerRetrievePathParams = {
  id: string;
};

export type VendorProductVariantControllerRetrieveError =
  Fetcher.ErrorWrapper<undefined>;

export type VendorProductVariantControllerRetrieveVariables = {
  pathParams: VendorProductVariantControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchVendorProductVariantControllerRetrieve = (
  variables: VendorProductVariantControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.VendorProductVariantResponse,
    VendorProductVariantControllerRetrieveError,
    undefined,
    {},
    {},
    VendorProductVariantControllerRetrievePathParams
  >({ url: '/v1/vendor-product-variant/{id}', method: 'get', ...variables });

export const useVendorProductVariantControllerRetrieve = <
  TData = Schemas.VendorProductVariantResponse
>(
  variables: VendorProductVariantControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.VendorProductVariantResponse,
      VendorProductVariantControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.VendorProductVariantResponse,
    VendorProductVariantControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/vendor-product-variant/{id}',
      operationId: 'vendorProductVariantControllerRetrieve',
      variables,
    }),
    () =>
      fetchVendorProductVariantControllerRetrieve({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type VendorProductVariantControllerUpdatePathParams = {
  id: string;
};

export type VendorProductVariantControllerUpdateError =
  Fetcher.ErrorWrapper<undefined>;

export type VendorProductVariantControllerUpdateVariables = {
  body?: Schemas.VendorProductVariantUpdate;
  pathParams: VendorProductVariantControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchVendorProductVariantControllerUpdate = (
  variables: VendorProductVariantControllerUpdateVariables
) =>
  apiFetch<
    Schemas.VendorProductVariantResponse,
    VendorProductVariantControllerUpdateError,
    Schemas.VendorProductVariantUpdate,
    {},
    {},
    VendorProductVariantControllerUpdatePathParams
  >({ url: '/v1/vendor-product-variant/{id}', method: 'patch', ...variables });

export const useVendorProductVariantControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.VendorProductVariantResponse,
      VendorProductVariantControllerUpdateError,
      VendorProductVariantControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.VendorProductVariantResponse,
    VendorProductVariantControllerUpdateError,
    VendorProductVariantControllerUpdateVariables
  >(
    (variables: VendorProductVariantControllerUpdateVariables) =>
      fetchVendorProductVariantControllerUpdate({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type VendorProductVariantControllerDeletePathParams = {
  id: string;
};

export type VendorProductVariantControllerDeleteError =
  Fetcher.ErrorWrapper<undefined>;

export type VendorProductVariantControllerDeleteVariables = {
  pathParams: VendorProductVariantControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchVendorProductVariantControllerDelete = (
  variables: VendorProductVariantControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    VendorProductVariantControllerDeleteError,
    undefined,
    {},
    {},
    VendorProductVariantControllerDeletePathParams
  >({ url: '/v1/vendor-product-variant/{id}', method: 'delete', ...variables });

export const useVendorProductVariantControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      VendorProductVariantControllerDeleteError,
      VendorProductVariantControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    VendorProductVariantControllerDeleteError,
    VendorProductVariantControllerDeleteVariables
  >(
    (variables: VendorProductVariantControllerDeleteVariables) =>
      fetchVendorProductVariantControllerDelete({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type CheckoutFromInventoryControllerCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type CheckoutFromInventoryControllerCreateVariables = {
  body: Schemas.CheckoutWrappedInput;
} & ApiContext['fetcherOptions'];

export const fetchCheckoutFromInventoryControllerCreate = (
  variables: CheckoutFromInventoryControllerCreateVariables
) =>
  apiFetch<
    Record<string, any>,
    CheckoutFromInventoryControllerCreateError,
    Schemas.CheckoutWrappedInput,
    {},
    {},
    {}
  >({ url: '/v1/checkout-from-inventory', method: 'post', ...variables });

export const useCheckoutFromInventoryControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      CheckoutFromInventoryControllerCreateError,
      CheckoutFromInventoryControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Record<string, any>,
    CheckoutFromInventoryControllerCreateError,
    CheckoutFromInventoryControllerCreateVariables
  >(
    (variables: CheckoutFromInventoryControllerCreateVariables) =>
      fetchCheckoutFromInventoryControllerCreate({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type CredentialedCollaboratorControllerCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type CredentialedCollaboratorControllerCreateVariables = {
  body: Schemas.CredentialedCollaboratorCreate;
} & ApiContext['fetcherOptions'];

export const fetchCredentialedCollaboratorControllerCreate = (
  variables: CredentialedCollaboratorControllerCreateVariables
) =>
  apiFetch<
    Schemas.CredentialedCollaboratorResponse,
    CredentialedCollaboratorControllerCreateError,
    Schemas.CredentialedCollaboratorCreate,
    {},
    {},
    {}
  >({ url: '/v1/credentialed-collaborator', method: 'post', ...variables });

export const useCredentialedCollaboratorControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CredentialedCollaboratorResponse,
      CredentialedCollaboratorControllerCreateError,
      CredentialedCollaboratorControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.CredentialedCollaboratorResponse,
    CredentialedCollaboratorControllerCreateError,
    CredentialedCollaboratorControllerCreateVariables
  >(
    (variables: CredentialedCollaboratorControllerCreateVariables) =>
      fetchCredentialedCollaboratorControllerCreate({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type CredentialedCollaboratorControllerSearchQueryParams = {
  /**
   * The first name address of the collaborator.
   */
  firstName?: string;
  /**
   * The last name address of the collaborator.
   */
  lastName?: string;
  /**
   * The email address of the collaborator.
   */
  email?: string;
  /**
   * The organization the collaborator belongs to.
   */
  organizationId?: string;
  /**
   * The role of the Collaborator.
   */
  role?:
    | 'EXTERNAL_PRIMARY_ADMINISTRATOR'
    | 'EXTERNAL_SECONDARY_ADMINISTRATOR'
    | 'INTERNAL_ACCOUNT_MANAGER'
    | 'INTERNAL_ADMINISTRATOR'
    | 'INTERNAL_ENGINEER'
    | 'INTERNAL_PRODUCT'
    | 'INTERNAL_SALES_REPRESENTATIVE';
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type CredentialedCollaboratorControllerSearchError =
  Fetcher.ErrorWrapper<undefined>;

export type CredentialedCollaboratorControllerSearchVariables = {
  queryParams?: CredentialedCollaboratorControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchCredentialedCollaboratorControllerSearch = (
  variables: CredentialedCollaboratorControllerSearchVariables
) =>
  apiFetch<
    Schemas.CredentialedCollaboratorList,
    CredentialedCollaboratorControllerSearchError,
    undefined,
    {},
    CredentialedCollaboratorControllerSearchQueryParams,
    {}
  >({ url: '/v1/credentialed-collaborator', method: 'get', ...variables });

export const useCredentialedCollaboratorControllerSearch = <
  TData = Schemas.CredentialedCollaboratorList
>(
  variables: CredentialedCollaboratorControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.CredentialedCollaboratorList,
      CredentialedCollaboratorControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.CredentialedCollaboratorList,
    CredentialedCollaboratorControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/credentialed-collaborator',
      operationId: 'credentialedCollaboratorControllerSearch',
      variables,
    }),
    () =>
      fetchCredentialedCollaboratorControllerSearch({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type CredentialedCollaboratorControllerRetrievePathParams = {
  id: string;
};

export type CredentialedCollaboratorControllerRetrieveError =
  Fetcher.ErrorWrapper<undefined>;

export type CredentialedCollaboratorControllerRetrieveVariables = {
  pathParams: CredentialedCollaboratorControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchCredentialedCollaboratorControllerRetrieve = (
  variables: CredentialedCollaboratorControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.CredentialedCollaboratorResponse,
    CredentialedCollaboratorControllerRetrieveError,
    undefined,
    {},
    {},
    CredentialedCollaboratorControllerRetrievePathParams
  >({ url: '/v1/credentialed-collaborator/{id}', method: 'get', ...variables });

export const useCredentialedCollaboratorControllerRetrieve = <
  TData = Schemas.CredentialedCollaboratorResponse
>(
  variables: CredentialedCollaboratorControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.CredentialedCollaboratorResponse,
      CredentialedCollaboratorControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.CredentialedCollaboratorResponse,
    CredentialedCollaboratorControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/credentialed-collaborator/{id}',
      operationId: 'credentialedCollaboratorControllerRetrieve',
      variables,
    }),
    () =>
      fetchCredentialedCollaboratorControllerRetrieve({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type CredentialedCollaboratorControllerUpdatePathParams = {
  id: string;
};

export type CredentialedCollaboratorControllerUpdateError =
  Fetcher.ErrorWrapper<undefined>;

export type CredentialedCollaboratorControllerUpdateVariables = {
  body?: Schemas.CredentialedCollaboratorUpdate;
  pathParams: CredentialedCollaboratorControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchCredentialedCollaboratorControllerUpdate = (
  variables: CredentialedCollaboratorControllerUpdateVariables
) =>
  apiFetch<
    Schemas.CredentialedCollaboratorResponse,
    CredentialedCollaboratorControllerUpdateError,
    Schemas.CredentialedCollaboratorUpdate,
    {},
    {},
    CredentialedCollaboratorControllerUpdatePathParams
  >({
    url: '/v1/credentialed-collaborator/{id}',
    method: 'patch',
    ...variables,
  });

export const useCredentialedCollaboratorControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CredentialedCollaboratorResponse,
      CredentialedCollaboratorControllerUpdateError,
      CredentialedCollaboratorControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.CredentialedCollaboratorResponse,
    CredentialedCollaboratorControllerUpdateError,
    CredentialedCollaboratorControllerUpdateVariables
  >(
    (variables: CredentialedCollaboratorControllerUpdateVariables) =>
      fetchCredentialedCollaboratorControllerUpdate({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type CredentialedCollaboratorControllerDeletePathParams = {
  id: string;
};

export type CredentialedCollaboratorControllerDeleteError =
  Fetcher.ErrorWrapper<undefined>;

export type CredentialedCollaboratorControllerDeleteVariables = {
  pathParams: CredentialedCollaboratorControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchCredentialedCollaboratorControllerDelete = (
  variables: CredentialedCollaboratorControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    CredentialedCollaboratorControllerDeleteError,
    undefined,
    {},
    {},
    CredentialedCollaboratorControllerDeletePathParams
  >({
    url: '/v1/credentialed-collaborator/{id}',
    method: 'delete',
    ...variables,
  });

export const useCredentialedCollaboratorControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      CredentialedCollaboratorControllerDeleteError,
      CredentialedCollaboratorControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    CredentialedCollaboratorControllerDeleteError,
    CredentialedCollaboratorControllerDeleteVariables
  >(
    (variables: CredentialedCollaboratorControllerDeleteVariables) =>
      fetchCredentialedCollaboratorControllerDelete({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type DocumentControllerUploadError = Fetcher.ErrorWrapper<undefined>;

export type DocumentControllerUploadVariables = {
  body: Schemas.DocumentFromUrl;
} & ApiContext['fetcherOptions'];

export const fetchDocumentControllerUpload = (
  variables: DocumentControllerUploadVariables
) =>
  apiFetch<
    Schemas.DocumentResponse,
    DocumentControllerUploadError,
    Schemas.DocumentFromUrl,
    {},
    {},
    {}
  >({ url: '/v1/document', method: 'post', ...variables });

export const useDocumentControllerUpload = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.DocumentResponse,
      DocumentControllerUploadError,
      DocumentControllerUploadVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.DocumentResponse,
    DocumentControllerUploadError,
    DocumentControllerUploadVariables
  >(
    (variables: DocumentControllerUploadVariables) =>
      fetchDocumentControllerUpload({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DocumentControllerSearchQueryParams = {
  id?: string;
  name?: string;
  s3Bucket?: string;
  s3Key?: string;
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type DocumentControllerSearchError = Fetcher.ErrorWrapper<undefined>;

export type DocumentControllerSearchVariables = {
  queryParams?: DocumentControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDocumentControllerSearch = (
  variables: DocumentControllerSearchVariables
) =>
  apiFetch<
    Schemas.DocumentResponseList,
    DocumentControllerSearchError,
    undefined,
    {},
    DocumentControllerSearchQueryParams,
    {}
  >({ url: '/v1/document', method: 'get', ...variables });

export const useDocumentControllerSearch = <
  TData = Schemas.DocumentResponseList
>(
  variables: DocumentControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.DocumentResponseList,
      DocumentControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.DocumentResponseList,
    DocumentControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/document',
      operationId: 'documentControllerSearch',
      variables,
    }),
    () => fetchDocumentControllerSearch({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type DocumentControllerRetrievePathParams = {
  id: string;
};

export type DocumentControllerRetrieveError = Fetcher.ErrorWrapper<undefined>;

export type DocumentControllerRetrieveVariables = {
  pathParams: DocumentControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchDocumentControllerRetrieve = (
  variables: DocumentControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.DocumentResponse,
    DocumentControllerRetrieveError,
    undefined,
    {},
    {},
    DocumentControllerRetrievePathParams
  >({ url: '/v1/document/{id}', method: 'get', ...variables });

export const useDocumentControllerRetrieve = <TData = Schemas.DocumentResponse>(
  variables: DocumentControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.DocumentResponse,
      DocumentControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.DocumentResponse,
    DocumentControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/document/{id}',
      operationId: 'documentControllerRetrieve',
      variables,
    }),
    () => fetchDocumentControllerRetrieve({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type DocumentControllerUpdatePathParams = {
  id: string;
};

export type DocumentControllerUpdateError = Fetcher.ErrorWrapper<undefined>;

export type DocumentControllerUpdateVariables = {
  body?: Schemas.DocumentUpdate;
  pathParams: DocumentControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchDocumentControllerUpdate = (
  variables: DocumentControllerUpdateVariables
) =>
  apiFetch<
    Schemas.DocumentResponse,
    DocumentControllerUpdateError,
    Schemas.DocumentUpdate,
    {},
    {},
    DocumentControllerUpdatePathParams
  >({ url: '/v1/document/{id}', method: 'patch', ...variables });

export const useDocumentControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.DocumentResponse,
      DocumentControllerUpdateError,
      DocumentControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.DocumentResponse,
    DocumentControllerUpdateError,
    DocumentControllerUpdateVariables
  >(
    (variables: DocumentControllerUpdateVariables) =>
      fetchDocumentControllerUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DocumentControllerDeletePathParams = {
  id: string;
};

export type DocumentControllerDeleteError = Fetcher.ErrorWrapper<undefined>;

export type DocumentControllerDeleteVariables = {
  pathParams: DocumentControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchDocumentControllerDelete = (
  variables: DocumentControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    DocumentControllerDeleteError,
    undefined,
    {},
    {},
    DocumentControllerDeletePathParams
  >({ url: '/v1/document/{id}', method: 'delete', ...variables });

export const useDocumentControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DocumentControllerDeleteError,
      DocumentControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DocumentControllerDeleteError,
    DocumentControllerDeleteVariables
  >(
    (variables: DocumentControllerDeleteVariables) =>
      fetchDocumentControllerDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type HealthcheckControllerCheckError = Fetcher.ErrorWrapper<undefined>;

export type HealthcheckControllerCheckVariables = ApiContext['fetcherOptions'];

export const fetchHealthcheckControllerCheck = (
  variables: HealthcheckControllerCheckVariables
) =>
  apiFetch<undefined, HealthcheckControllerCheckError, undefined, {}, {}, {}>({
    url: '/healthcheck',
    method: 'get',
    ...variables,
  });

export const useHealthcheckControllerCheck = <TData = undefined>(
  variables: HealthcheckControllerCheckVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      undefined,
      HealthcheckControllerCheckError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, HealthcheckControllerCheckError, TData>(
    queryKeyFn({
      path: '/healthcheck',
      operationId: 'healthcheckControllerCheck',
      variables,
    }),
    () => fetchHealthcheckControllerCheck({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ImageControllerUploadError = Fetcher.ErrorWrapper<undefined>;

export type ImageControllerUploadVariables = {
  body: Schemas.ImageFromUrl;
} & ApiContext['fetcherOptions'];

/**
 * documenting alternative responses
 */
export const fetchImageControllerUpload = (
  variables: ImageControllerUploadVariables
) =>
  apiFetch<
    Schemas.ImageResponse,
    ImageControllerUploadError,
    Schemas.ImageFromUrl,
    {},
    {},
    {}
  >({ url: '/v1/image', method: 'post', ...variables });

/**
 * documenting alternative responses
 */
export const useImageControllerUpload = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ImageResponse,
      ImageControllerUploadError,
      ImageControllerUploadVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ImageResponse,
    ImageControllerUploadError,
    ImageControllerUploadVariables
  >(
    (variables: ImageControllerUploadVariables) =>
      fetchImageControllerUpload({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ImageControllerSearchQueryParams = {
  name?: string;
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type ImageControllerSearchError = Fetcher.ErrorWrapper<undefined>;

export type ImageControllerSearchVariables = {
  queryParams?: ImageControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

/**
 * documenting alternative responses
 */
export const fetchImageControllerSearch = (
  variables: ImageControllerSearchVariables
) =>
  apiFetch<
    Schemas.ImageResponseList,
    ImageControllerSearchError,
    undefined,
    {},
    ImageControllerSearchQueryParams,
    {}
  >({ url: '/v1/image', method: 'get', ...variables });

/**
 * documenting alternative responses
 */
export const useImageControllerSearch = <TData = Schemas.ImageResponseList>(
  variables: ImageControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ImageResponseList,
      ImageControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ImageResponseList,
    ImageControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/image',
      operationId: 'imageControllerSearch',
      variables,
    }),
    () => fetchImageControllerSearch({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ImageControllerRetrievePathParams = {
  id: string;
};

export type ImageControllerRetrieveError = Fetcher.ErrorWrapper<undefined>;

export type ImageControllerRetrieveVariables = {
  pathParams: ImageControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

/**
 * documenting alternative responses
 */
export const fetchImageControllerRetrieve = (
  variables: ImageControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.ImageResponse,
    ImageControllerRetrieveError,
    undefined,
    {},
    {},
    ImageControllerRetrievePathParams
  >({ url: '/v1/image/{id}', method: 'get', ...variables });

/**
 * documenting alternative responses
 */
export const useImageControllerRetrieve = <TData = Schemas.ImageResponse>(
  variables: ImageControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ImageResponse,
      ImageControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ImageResponse,
    ImageControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/image/{id}',
      operationId: 'imageControllerRetrieve',
      variables,
    }),
    () => fetchImageControllerRetrieve({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ImageControllerUpdatePathParams = {
  id: string;
};

export type ImageControllerUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ImageControllerUpdateVariables = {
  body?: Schemas.ImageUpdate;
  pathParams: ImageControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

/**
 * documenting alternative responses
 */
export const fetchImageControllerUpdate = (
  variables: ImageControllerUpdateVariables
) =>
  apiFetch<
    Schemas.ImageResponse,
    ImageControllerUpdateError,
    Schemas.ImageUpdate,
    {},
    {},
    ImageControllerUpdatePathParams
  >({ url: '/v1/image/{id}', method: 'patch', ...variables });

/**
 * documenting alternative responses
 */
export const useImageControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ImageResponse,
      ImageControllerUpdateError,
      ImageControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ImageResponse,
    ImageControllerUpdateError,
    ImageControllerUpdateVariables
  >(
    (variables: ImageControllerUpdateVariables) =>
      fetchImageControllerUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ImageControllerDeletePathParams = {
  id: string;
};

export type ImageControllerDeleteError = Fetcher.ErrorWrapper<undefined>;

export type ImageControllerDeleteVariables = {
  pathParams: ImageControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

/**
 * documenting alternative responses
 */
export const fetchImageControllerDelete = (
  variables: ImageControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    ImageControllerDeleteError,
    undefined,
    {},
    {},
    ImageControllerDeletePathParams
  >({ url: '/v1/image/{id}', method: 'delete', ...variables });

/**
 * documenting alternative responses
 */
export const useImageControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ImageControllerDeleteError,
      ImageControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ImageControllerDeleteError,
    ImageControllerDeleteVariables
  >(
    (variables: ImageControllerDeleteVariables) =>
      fetchImageControllerDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type NPSSurveyControllerCreateError = Fetcher.ErrorWrapper<undefined>;

export type NPSSurveyControllerCreateVariables = {
  body: Schemas.NPSPersonParams;
} & ApiContext['fetcherOptions'];

export const fetchNPSSurveyControllerCreate = (
  variables: NPSSurveyControllerCreateVariables
) =>
  apiFetch<
    Schemas.WebhookResponse,
    NPSSurveyControllerCreateError,
    Schemas.NPSPersonParams,
    {},
    {},
    {}
  >({ url: '/webhook/nps-survey', method: 'post', ...variables });

export const useNPSSurveyControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.WebhookResponse,
      NPSSurveyControllerCreateError,
      NPSSurveyControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.WebhookResponse,
    NPSSurveyControllerCreateError,
    NPSSurveyControllerCreateVariables
  >(
    (variables: NPSSurveyControllerCreateVariables) =>
      fetchNPSSurveyControllerCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type NotificationControllerCreateError = Fetcher.ErrorWrapper<undefined>;

export type NotificationControllerCreateVariables = {
  body: Schemas.NotificationCreate;
} & ApiContext['fetcherOptions'];

export const fetchNotificationControllerCreate = (
  variables: NotificationControllerCreateVariables
) =>
  apiFetch<
    Schemas.NotificationDto,
    NotificationControllerCreateError,
    Schemas.NotificationCreate,
    {},
    {},
    {}
  >({ url: '/v1/notification', method: 'post', ...variables });

export const useNotificationControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NotificationDto,
      NotificationControllerCreateError,
      NotificationControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.NotificationDto,
    NotificationControllerCreateError,
    NotificationControllerCreateVariables
  >(
    (variables: NotificationControllerCreateVariables) =>
      fetchNotificationControllerCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type NotificationControllerSearchQueryParams = {
  /**
   * The type of notification.
   */
  type?: 'EMPLOYEE_CONFIRMATION' | 'ORGANIZATION_CONFIRMATION';
  /**
   * The unique identifier of the associated order. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  orderId?: string;
  /**
   * The unique identifier of collaborator who receives it. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  collaboratorId?: string;
  /**
   * Reflects the current status of the notification. Eg: “new” means the notification was initiated but not sent yet.
   */
  status?: 'NEW' | 'SENT';
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type NotificationControllerSearchError = Fetcher.ErrorWrapper<undefined>;

export type NotificationControllerSearchVariables = {
  queryParams?: NotificationControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchNotificationControllerSearch = (
  variables: NotificationControllerSearchVariables
) =>
  apiFetch<
    Schemas.NotificationDtoList,
    NotificationControllerSearchError,
    undefined,
    {},
    NotificationControllerSearchQueryParams,
    {}
  >({ url: '/v1/notification', method: 'get', ...variables });

export const useNotificationControllerSearch = <
  TData = Schemas.NotificationDtoList
>(
  variables: NotificationControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.NotificationDtoList,
      NotificationControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.NotificationDtoList,
    NotificationControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/notification',
      operationId: 'notificationControllerSearch',
      variables,
    }),
    () =>
      fetchNotificationControllerSearch({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type NotificationControllerRetrievePathParams = {
  id: string;
};

export type NotificationControllerRetrieveError =
  Fetcher.ErrorWrapper<undefined>;

export type NotificationControllerRetrieveVariables = {
  pathParams: NotificationControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchNotificationControllerRetrieve = (
  variables: NotificationControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.NotificationDto,
    NotificationControllerRetrieveError,
    undefined,
    {},
    {},
    NotificationControllerRetrievePathParams
  >({ url: '/v1/notification/{id}', method: 'get', ...variables });

export const useNotificationControllerRetrieve = <
  TData = Schemas.NotificationDto
>(
  variables: NotificationControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.NotificationDto,
      NotificationControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.NotificationDto,
    NotificationControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/notification/{id}',
      operationId: 'notificationControllerRetrieve',
      variables,
    }),
    () =>
      fetchNotificationControllerRetrieve({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type NotificationControllerUpdatePathParams = {
  id: string;
};

export type NotificationControllerUpdateError = Fetcher.ErrorWrapper<undefined>;

export type NotificationControllerUpdateVariables = {
  body?: Schemas.NotificationUpdate;
  pathParams: NotificationControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchNotificationControllerUpdate = (
  variables: NotificationControllerUpdateVariables
) =>
  apiFetch<
    Schemas.NotificationDto,
    NotificationControllerUpdateError,
    Schemas.NotificationUpdate,
    {},
    {},
    NotificationControllerUpdatePathParams
  >({ url: '/v1/notification/{id}', method: 'patch', ...variables });

export const useNotificationControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NotificationDto,
      NotificationControllerUpdateError,
      NotificationControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.NotificationDto,
    NotificationControllerUpdateError,
    NotificationControllerUpdateVariables
  >(
    (variables: NotificationControllerUpdateVariables) =>
      fetchNotificationControllerUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type NotificationControllerDeletePathParams = {
  id: string;
};

export type NotificationControllerDeleteError = Fetcher.ErrorWrapper<undefined>;

export type NotificationControllerDeleteVariables = {
  pathParams: NotificationControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchNotificationControllerDelete = (
  variables: NotificationControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    NotificationControllerDeleteError,
    undefined,
    {},
    {},
    NotificationControllerDeletePathParams
  >({ url: '/v1/notification/{id}', method: 'delete', ...variables });

export const useNotificationControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      NotificationControllerDeleteError,
      NotificationControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    NotificationControllerDeleteError,
    NotificationControllerDeleteVariables
  >(
    (variables: NotificationControllerDeleteVariables) =>
      fetchNotificationControllerDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type OrderItemControllerCreateError = Fetcher.ErrorWrapper<undefined>;

export type OrderItemControllerCreateVariables = {
  body: Schemas.OrderItemCreate;
} & ApiContext['fetcherOptions'];

export const fetchOrderItemControllerCreate = (
  variables: OrderItemControllerCreateVariables
) =>
  apiFetch<
    Schemas.OrderItemResponse,
    OrderItemControllerCreateError,
    Schemas.OrderItemCreate,
    {},
    {},
    {}
  >({ url: '/v1/order-item', method: 'post', ...variables });

export const useOrderItemControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OrderItemResponse,
      OrderItemControllerCreateError,
      OrderItemControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.OrderItemResponse,
    OrderItemControllerCreateError,
    OrderItemControllerCreateVariables
  >(
    (variables: OrderItemControllerCreateVariables) =>
      fetchOrderItemControllerCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type OrderItemControllerSearchQueryParams = {
  /**
   * The unique identifier of the Order. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  orderId?: string;
  /**
   * The unique identifier of the Vendor Order. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  vendorOrderId?: string;
  /**
   * The unique identifier of the Product Variant. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  productVariantId?: string;
  /**
   * The unique identifier of the Shipment. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  shipmentItemId?: string;
  /**
   * The serial number of the device associated with this OrderItem
   */
  serialNumber?: string;
  /**
   * The amount allwhere paid for the product
   */
  vendorAmount?: string;
  /**
   * The amount at the time of checkout
   */
  checkoutAmount?: string;
  /**
   * The amount charged to the Organization
   */
  invoiceAmount?: string;
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type OrderItemControllerSearchError = Fetcher.ErrorWrapper<undefined>;

export type OrderItemControllerSearchVariables = {
  queryParams?: OrderItemControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchOrderItemControllerSearch = (
  variables: OrderItemControllerSearchVariables
) =>
  apiFetch<
    Schemas.OrderItemResponseList,
    OrderItemControllerSearchError,
    undefined,
    {},
    OrderItemControllerSearchQueryParams,
    {}
  >({ url: '/v1/order-item', method: 'get', ...variables });

export const useOrderItemControllerSearch = <
  TData = Schemas.OrderItemResponseList
>(
  variables: OrderItemControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.OrderItemResponseList,
      OrderItemControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.OrderItemResponseList,
    OrderItemControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/order-item',
      operationId: 'orderItemControllerSearch',
      variables,
    }),
    () => fetchOrderItemControllerSearch({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type OrderItemControllerRetrievePathParams = {
  id: string;
};

export type OrderItemControllerRetrieveError = Fetcher.ErrorWrapper<undefined>;

export type OrderItemControllerRetrieveVariables = {
  pathParams: OrderItemControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchOrderItemControllerRetrieve = (
  variables: OrderItemControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.OrderItemResponse,
    OrderItemControllerRetrieveError,
    undefined,
    {},
    {},
    OrderItemControllerRetrievePathParams
  >({ url: '/v1/order-item/{id}', method: 'get', ...variables });

export const useOrderItemControllerRetrieve = <
  TData = Schemas.OrderItemResponse
>(
  variables: OrderItemControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.OrderItemResponse,
      OrderItemControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.OrderItemResponse,
    OrderItemControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/order-item/{id}',
      operationId: 'orderItemControllerRetrieve',
      variables,
    }),
    () => fetchOrderItemControllerRetrieve({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type OrderItemControllerUpdatePathParams = {
  id: string;
};

export type OrderItemControllerUpdateError = Fetcher.ErrorWrapper<undefined>;

export type OrderItemControllerUpdateVariables = {
  body?: Schemas.OrderItemUpdate;
  pathParams: OrderItemControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchOrderItemControllerUpdate = (
  variables: OrderItemControllerUpdateVariables
) =>
  apiFetch<
    Schemas.OrderItemResponse,
    OrderItemControllerUpdateError,
    Schemas.OrderItemUpdate,
    {},
    {},
    OrderItemControllerUpdatePathParams
  >({ url: '/v1/order-item/{id}', method: 'patch', ...variables });

export const useOrderItemControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OrderItemResponse,
      OrderItemControllerUpdateError,
      OrderItemControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.OrderItemResponse,
    OrderItemControllerUpdateError,
    OrderItemControllerUpdateVariables
  >(
    (variables: OrderItemControllerUpdateVariables) =>
      fetchOrderItemControllerUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type OrderItemControllerDeletePathParams = {
  id: string;
};

export type OrderItemControllerDeleteError = Fetcher.ErrorWrapper<undefined>;

export type OrderItemControllerDeleteVariables = {
  pathParams: OrderItemControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchOrderItemControllerDelete = (
  variables: OrderItemControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    OrderItemControllerDeleteError,
    undefined,
    {},
    {},
    OrderItemControllerDeletePathParams
  >({ url: '/v1/order-item/{id}', method: 'delete', ...variables });

export const useOrderItemControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      OrderItemControllerDeleteError,
      OrderItemControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    OrderItemControllerDeleteError,
    OrderItemControllerDeleteVariables
  >(
    (variables: OrderItemControllerDeleteVariables) =>
      fetchOrderItemControllerDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type OrderControllerCreateError = Fetcher.ErrorWrapper<undefined>;

export type OrderControllerCreateVariables = {
  body: Schemas.OrderCreateDto;
} & ApiContext['fetcherOptions'];

export const fetchOrderControllerCreate = (
  variables: OrderControllerCreateVariables
) =>
  apiFetch<
    Schemas.OrderResponseDto,
    OrderControllerCreateError,
    Schemas.OrderCreateDto,
    {},
    {},
    {}
  >({ url: '/v1/order', method: 'post', ...variables });

export const useOrderControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OrderResponseDto,
      OrderControllerCreateError,
      OrderControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.OrderResponseDto,
    OrderControllerCreateError,
    OrderControllerCreateVariables
  >(
    (variables: OrderControllerCreateVariables) =>
      fetchOrderControllerCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type OrderControllerSearchQueryParams = {
  internalNotes?: string;
  checkoutNotes?: string;
  deliveredByDate?: string;
  organizationId?: string;
  purchaserId?: string;
  recipientId?: string;
  deliveryAddressId?: string;
  accountManagerId?: string;
  status?:
    | 'CANCELLED'
    | 'COMPLETE'
    | 'CREATED'
    | 'EXCEPTION'
    | 'IN_FULFILLMENT'
    | 'PENDING_APPROVAL'
    | 'PENDING_RECIPIENT_INFORMATION'
    | 'SHIPPED'
    | 'UNKNOWN';
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type OrderControllerSearchError = Fetcher.ErrorWrapper<undefined>;

export type OrderControllerSearchVariables = {
  queryParams?: OrderControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchOrderControllerSearch = (
  variables: OrderControllerSearchVariables
) =>
  apiFetch<
    Schemas.OrderResponseDtoList,
    OrderControllerSearchError,
    undefined,
    {},
    OrderControllerSearchQueryParams,
    {}
  >({ url: '/v1/order', method: 'get', ...variables });

export const useOrderControllerSearch = <TData = Schemas.OrderResponseDtoList>(
  variables: OrderControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.OrderResponseDtoList,
      OrderControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.OrderResponseDtoList,
    OrderControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/order',
      operationId: 'orderControllerSearch',
      variables,
    }),
    () => fetchOrderControllerSearch({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type OrderControllerUpdatePathParams = {
  id: string;
};

export type OrderControllerUpdateError = Fetcher.ErrorWrapper<undefined>;

export type OrderControllerUpdateVariables = {
  pathParams: OrderControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchOrderControllerUpdate = (
  variables: OrderControllerUpdateVariables
) =>
  apiFetch<
    Schemas.OrderResponseDto,
    OrderControllerUpdateError,
    undefined,
    {},
    {},
    OrderControllerUpdatePathParams
  >({ url: '/v1/order', method: 'patch', ...variables });

export const useOrderControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OrderResponseDto,
      OrderControllerUpdateError,
      OrderControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.OrderResponseDto,
    OrderControllerUpdateError,
    OrderControllerUpdateVariables
  >(
    (variables: OrderControllerUpdateVariables) =>
      fetchOrderControllerUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type OrderControllerDeletePathParams = {
  id: string;
};

export type OrderControllerDeleteError = Fetcher.ErrorWrapper<undefined>;

export type OrderControllerDeleteVariables = {
  pathParams: OrderControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchOrderControllerDelete = (
  variables: OrderControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    OrderControllerDeleteError,
    undefined,
    {},
    {},
    OrderControllerDeletePathParams
  >({ url: '/v1/order', method: 'delete', ...variables });

export const useOrderControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      OrderControllerDeleteError,
      OrderControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    OrderControllerDeleteError,
    OrderControllerDeleteVariables
  >(
    (variables: OrderControllerDeleteVariables) =>
      fetchOrderControllerDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type OrderControllerRetrievePathParams = {
  id: string;
};

export type OrderControllerRetrieveError = Fetcher.ErrorWrapper<undefined>;

export type OrderControllerRetrieveVariables = {
  pathParams: OrderControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchOrderControllerRetrieve = (
  variables: OrderControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.OrderResponseDto,
    OrderControllerRetrieveError,
    undefined,
    {},
    {},
    OrderControllerRetrievePathParams
  >({ url: '/v1/order/{id}', method: 'get', ...variables });

export const useOrderControllerRetrieve = <TData = Schemas.OrderResponseDto>(
  variables: OrderControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.OrderResponseDto,
      OrderControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.OrderResponseDto,
    OrderControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/order/{id}',
      operationId: 'orderControllerRetrieve',
      variables,
    }),
    () => fetchOrderControllerRetrieve({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProductCatalogControllerGetByOrganizationQueryParams = {
  /**
   * The unique identifier of the associated organization. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The unique identifier of the associated category. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  productCategoryId?: string;
  /**
   * The name of the product
   */
  name?: string;
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type ProductCatalogControllerGetByOrganizationError =
  Fetcher.ErrorWrapper<undefined>;

export type ProductCatalogControllerGetByOrganizationVariables = {
  queryParams: ProductCatalogControllerGetByOrganizationQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchProductCatalogControllerGetByOrganization = (
  variables: ProductCatalogControllerGetByOrganizationVariables
) =>
  apiFetch<
    Schemas.ProductCatalog,
    ProductCatalogControllerGetByOrganizationError,
    undefined,
    {},
    ProductCatalogControllerGetByOrganizationQueryParams,
    {}
  >({ url: '/v1/product-catalog', method: 'get', ...variables });

export const useProductCatalogControllerGetByOrganization = <
  TData = Schemas.ProductCatalog
>(
  variables: ProductCatalogControllerGetByOrganizationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ProductCatalog,
      ProductCatalogControllerGetByOrganizationError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ProductCatalog,
    ProductCatalogControllerGetByOrganizationError,
    TData
  >(
    queryKeyFn({
      path: '/v1/product-catalog',
      operationId: 'productCatalogControllerGetByOrganization',
      variables,
    }),
    () =>
      fetchProductCatalogControllerGetByOrganization({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProductImageControllerCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProductImageControllerCreateVariables = {
  body: Schemas.ProductImageCreate;
} & ApiContext['fetcherOptions'];

export const fetchProductImageControllerCreate = (
  variables: ProductImageControllerCreateVariables
) =>
  apiFetch<
    Schemas.ProductImageResponse,
    ProductImageControllerCreateError,
    Schemas.ProductImageCreate,
    {},
    {},
    {}
  >({ url: '/v1/product-image', method: 'post', ...variables });

export const useProductImageControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ProductImageResponse,
      ProductImageControllerCreateError,
      ProductImageControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ProductImageResponse,
    ProductImageControllerCreateError,
    ProductImageControllerCreateVariables
  >(
    (variables: ProductImageControllerCreateVariables) =>
      fetchProductImageControllerCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProductImageControllerSearchQueryParams = {
  /**
   * The unique identifier of the product. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  productId?: string;
  /**
   * The unique identifier of the image. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  imageId?: string;
  /**
   * The index indicating the order of the images belonging to this product.
   */
  index?: number;
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type ProductImageControllerSearchError = Fetcher.ErrorWrapper<undefined>;

export type ProductImageControllerSearchVariables = {
  queryParams?: ProductImageControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchProductImageControllerSearch = (
  variables: ProductImageControllerSearchVariables
) =>
  apiFetch<
    Schemas.ProductImageResponseList,
    ProductImageControllerSearchError,
    undefined,
    {},
    ProductImageControllerSearchQueryParams,
    {}
  >({ url: '/v1/product-image', method: 'get', ...variables });

export const useProductImageControllerSearch = <
  TData = Schemas.ProductImageResponseList
>(
  variables: ProductImageControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ProductImageResponseList,
      ProductImageControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ProductImageResponseList,
    ProductImageControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/product-image',
      operationId: 'productImageControllerSearch',
      variables,
    }),
    () =>
      fetchProductImageControllerSearch({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProductImageControllerRetrievePathParams = {
  id: string;
};

export type ProductImageControllerRetrieveError =
  Fetcher.ErrorWrapper<undefined>;

export type ProductImageControllerRetrieveVariables = {
  pathParams: ProductImageControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchProductImageControllerRetrieve = (
  variables: ProductImageControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.ProductImageResponse,
    ProductImageControllerRetrieveError,
    undefined,
    {},
    {},
    ProductImageControllerRetrievePathParams
  >({ url: '/v1/product-image/{id}', method: 'get', ...variables });

export const useProductImageControllerRetrieve = <
  TData = Schemas.ProductImageResponse
>(
  variables: ProductImageControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ProductImageResponse,
      ProductImageControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ProductImageResponse,
    ProductImageControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/product-image/{id}',
      operationId: 'productImageControllerRetrieve',
      variables,
    }),
    () =>
      fetchProductImageControllerRetrieve({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProductImageControllerUpdatePathParams = {
  id: string;
};

export type ProductImageControllerUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ProductImageControllerUpdateVariables = {
  body?: Schemas.ProductImageUpdate;
  pathParams: ProductImageControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchProductImageControllerUpdate = (
  variables: ProductImageControllerUpdateVariables
) =>
  apiFetch<
    Schemas.ProductImageResponse,
    ProductImageControllerUpdateError,
    Schemas.ProductImageUpdate,
    {},
    {},
    ProductImageControllerUpdatePathParams
  >({ url: '/v1/product-image/{id}', method: 'patch', ...variables });

export const useProductImageControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ProductImageResponse,
      ProductImageControllerUpdateError,
      ProductImageControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ProductImageResponse,
    ProductImageControllerUpdateError,
    ProductImageControllerUpdateVariables
  >(
    (variables: ProductImageControllerUpdateVariables) =>
      fetchProductImageControllerUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProductImageControllerDeletePathParams = {
  id: string;
};

export type ProductImageControllerDeleteError = Fetcher.ErrorWrapper<undefined>;

export type ProductImageControllerDeleteVariables = {
  pathParams: ProductImageControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchProductImageControllerDelete = (
  variables: ProductImageControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    ProductImageControllerDeleteError,
    undefined,
    {},
    {},
    ProductImageControllerDeletePathParams
  >({ url: '/v1/product-image/{id}', method: 'delete', ...variables });

export const useProductImageControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProductImageControllerDeleteError,
      ProductImageControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ProductImageControllerDeleteError,
    ProductImageControllerDeleteVariables
  >(
    (variables: ProductImageControllerDeleteVariables) =>
      fetchProductImageControllerDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProductDetailsControllerGetByProductIdPathParams = {
  productId: string;
};

export type ProductDetailsControllerGetByProductIdError =
  Fetcher.ErrorWrapper<undefined>;

export type ProductDetailsControllerGetByProductIdVariables = {
  pathParams: ProductDetailsControllerGetByProductIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchProductDetailsControllerGetByProductId = (
  variables: ProductDetailsControllerGetByProductIdVariables
) =>
  apiFetch<
    Schemas.ProductDetailsResponse,
    ProductDetailsControllerGetByProductIdError,
    undefined,
    {},
    {},
    ProductDetailsControllerGetByProductIdPathParams
  >({ url: '/v1/product-details/{productId}', method: 'get', ...variables });

export const useProductDetailsControllerGetByProductId = <
  TData = Schemas.ProductDetailsResponse
>(
  variables: ProductDetailsControllerGetByProductIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ProductDetailsResponse,
      ProductDetailsControllerGetByProductIdError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ProductDetailsResponse,
    ProductDetailsControllerGetByProductIdError,
    TData
  >(
    queryKeyFn({
      path: '/v1/product-details/{productId}',
      operationId: 'productDetailsControllerGetByProductId',
      variables,
    }),
    () =>
      fetchProductDetailsControllerGetByProductId({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProductVariantByAttributesControllerGetByAttributesPathParams = {
  productId: string;
};

export type ProductVariantByAttributesControllerGetByAttributesQueryParams = {
  /**
   * @example {"size":"XL","color":"blue"}
   */
  attributes: {
    [key: string]: string;
  };
};

export type ProductVariantByAttributesControllerGetByAttributesError =
  Fetcher.ErrorWrapper<undefined>;

export type ProductVariantByAttributesControllerGetByAttributesVariables = {
  pathParams: ProductVariantByAttributesControllerGetByAttributesPathParams;
  queryParams: ProductVariantByAttributesControllerGetByAttributesQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchProductVariantByAttributesControllerGetByAttributes = (
  variables: ProductVariantByAttributesControllerGetByAttributesVariables
) =>
  apiFetch<
    Schemas.ProductVariantByAttributeResponse,
    ProductVariantByAttributesControllerGetByAttributesError,
    undefined,
    {},
    ProductVariantByAttributesControllerGetByAttributesQueryParams,
    ProductVariantByAttributesControllerGetByAttributesPathParams
  >({
    url: '/v1/product-variant-by-attributes/{productId}',
    method: 'get',
    ...variables,
  });

export const useProductVariantByAttributesControllerGetByAttributes = <
  TData = Schemas.ProductVariantByAttributeResponse
>(
  variables: ProductVariantByAttributesControllerGetByAttributesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ProductVariantByAttributeResponse,
      ProductVariantByAttributesControllerGetByAttributesError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ProductVariantByAttributeResponse,
    ProductVariantByAttributesControllerGetByAttributesError,
    TData
  >(
    queryKeyFn({
      path: '/v1/product-variant-by-attributes/{productId}',
      operationId: 'productVariantByAttributesControllerGetByAttributes',
      variables,
    }),
    () =>
      fetchProductVariantByAttributesControllerGetByAttributes({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type PostDeliveryControllerCreateError = Fetcher.ErrorWrapper<undefined>;

export type PostDeliveryControllerCreateVariables = {
  body: Schemas.PostDeliveryParams;
} & ApiContext['fetcherOptions'];

export const fetchPostDeliveryControllerCreate = (
  variables: PostDeliveryControllerCreateVariables
) =>
  apiFetch<
    Schemas.WebhookResponse,
    PostDeliveryControllerCreateError,
    Schemas.PostDeliveryParams,
    {},
    {},
    {}
  >({
    url: '/webhook/post-delivery/PostDelivery',
    method: 'post',
    ...variables,
  });

export const usePostDeliveryControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.WebhookResponse,
      PostDeliveryControllerCreateError,
      PostDeliveryControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.WebhookResponse,
    PostDeliveryControllerCreateError,
    PostDeliveryControllerCreateVariables
  >(
    (variables: PostDeliveryControllerCreateVariables) =>
      fetchPostDeliveryControllerCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ReturnCreationControllerCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type ReturnCreationControllerCreateVariables = {
  body: Schemas.ReturnCreationParams;
} & ApiContext['fetcherOptions'];

export const fetchReturnCreationControllerCreate = (
  variables: ReturnCreationControllerCreateVariables
) =>
  apiFetch<
    undefined,
    ReturnCreationControllerCreateError,
    Schemas.ReturnCreationParams,
    {},
    {},
    {}
  >({ url: '/webhook/return-creation', method: 'post', ...variables });

export const useReturnCreationControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ReturnCreationControllerCreateError,
      ReturnCreationControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ReturnCreationControllerCreateError,
    ReturnCreationControllerCreateVariables
  >(
    (variables: ReturnCreationControllerCreateVariables) =>
      fetchReturnCreationControllerCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type RecipientFormControllerCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type RecipientFormControllerCreateVariables = {
  body: Schemas.RecipientFormCreate;
} & ApiContext['fetcherOptions'];

export const fetchRecipientFormControllerCreate = (
  variables: RecipientFormControllerCreateVariables
) =>
  apiFetch<
    Schemas.RecipientFormResponse,
    RecipientFormControllerCreateError,
    Schemas.RecipientFormCreate,
    {},
    {},
    {}
  >({ url: '/v1/recipient-form', method: 'post', ...variables });

export const useRecipientFormControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RecipientFormResponse,
      RecipientFormControllerCreateError,
      RecipientFormControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.RecipientFormResponse,
    RecipientFormControllerCreateError,
    RecipientFormControllerCreateVariables
  >(
    (variables: RecipientFormControllerCreateVariables) =>
      fetchRecipientFormControllerCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type RecipientFormControllerSearchQueryParams = {
  /**
   * The unique identifier of the associated order. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  orderId?: string;
  /**
   * Reflects the current status.
   */
  status?: 'CANCELED' | 'CREATED' | 'PENDING' | 'RECEIVED';
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type RecipientFormControllerSearchError =
  Fetcher.ErrorWrapper<undefined>;

export type RecipientFormControllerSearchVariables = {
  queryParams?: RecipientFormControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchRecipientFormControllerSearch = (
  variables: RecipientFormControllerSearchVariables
) =>
  apiFetch<
    Schemas.RecipientFormList,
    RecipientFormControllerSearchError,
    undefined,
    {},
    RecipientFormControllerSearchQueryParams,
    {}
  >({ url: '/v1/recipient-form', method: 'get', ...variables });

export const useRecipientFormControllerSearch = <
  TData = Schemas.RecipientFormList
>(
  variables: RecipientFormControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.RecipientFormList,
      RecipientFormControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.RecipientFormList,
    RecipientFormControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/recipient-form',
      operationId: 'recipientFormControllerSearch',
      variables,
    }),
    () =>
      fetchRecipientFormControllerSearch({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type RecipientFormControllerRetrievePathParams = {
  id: string;
};

export type RecipientFormControllerRetrieveError =
  Fetcher.ErrorWrapper<undefined>;

export type RecipientFormControllerRetrieveVariables = {
  pathParams: RecipientFormControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchRecipientFormControllerRetrieve = (
  variables: RecipientFormControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.RecipientFormResponse,
    RecipientFormControllerRetrieveError,
    undefined,
    {},
    {},
    RecipientFormControllerRetrievePathParams
  >({ url: '/v1/recipient-form/{id}', method: 'get', ...variables });

export const useRecipientFormControllerRetrieve = <
  TData = Schemas.RecipientFormResponse
>(
  variables: RecipientFormControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.RecipientFormResponse,
      RecipientFormControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.RecipientFormResponse,
    RecipientFormControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/recipient-form/{id}',
      operationId: 'recipientFormControllerRetrieve',
      variables,
    }),
    () =>
      fetchRecipientFormControllerRetrieve({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type RecipientFormControllerUpdatePathParams = {
  id: string;
};

export type RecipientFormControllerUpdateError =
  Fetcher.ErrorWrapper<undefined>;

export type RecipientFormControllerUpdateVariables = {
  body?: Schemas.RecipientFormUpdate;
  pathParams: RecipientFormControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchRecipientFormControllerUpdate = (
  variables: RecipientFormControllerUpdateVariables
) =>
  apiFetch<
    Schemas.RecipientFormResponse,
    RecipientFormControllerUpdateError,
    Schemas.RecipientFormUpdate,
    {},
    {},
    RecipientFormControllerUpdatePathParams
  >({ url: '/v1/recipient-form/{id}', method: 'patch', ...variables });

export const useRecipientFormControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RecipientFormResponse,
      RecipientFormControllerUpdateError,
      RecipientFormControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.RecipientFormResponse,
    RecipientFormControllerUpdateError,
    RecipientFormControllerUpdateVariables
  >(
    (variables: RecipientFormControllerUpdateVariables) =>
      fetchRecipientFormControllerUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type RecipientFormControllerDeletePathParams = {
  id: string;
};

export type RecipientFormControllerDeleteError =
  Fetcher.ErrorWrapper<undefined>;

export type RecipientFormControllerDeleteVariables = {
  pathParams: RecipientFormControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchRecipientFormControllerDelete = (
  variables: RecipientFormControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    RecipientFormControllerDeleteError,
    undefined,
    {},
    {},
    RecipientFormControllerDeletePathParams
  >({ url: '/v1/recipient-form/{id}', method: 'delete', ...variables });

export const useRecipientFormControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RecipientFormControllerDeleteError,
      RecipientFormControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    RecipientFormControllerDeleteError,
    RecipientFormControllerDeleteVariables
  >(
    (variables: RecipientFormControllerDeleteVariables) =>
      fetchRecipientFormControllerDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type RecipientControllerCreateError = Fetcher.ErrorWrapper<undefined>;

export type RecipientControllerCreateVariables = {
  body: Schemas.RecipientCreate;
} & ApiContext['fetcherOptions'];

export const fetchRecipientControllerCreate = (
  variables: RecipientControllerCreateVariables
) =>
  apiFetch<
    Schemas.Recipient,
    RecipientControllerCreateError,
    Schemas.RecipientCreate,
    {},
    {},
    {}
  >({ url: '/v1/recipient', method: 'post', ...variables });

export const useRecipientControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Recipient,
      RecipientControllerCreateError,
      RecipientControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.Recipient,
    RecipientControllerCreateError,
    RecipientControllerCreateVariables
  >(
    (variables: RecipientControllerCreateVariables) =>
      fetchRecipientControllerCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type RecipientControllerSearchQueryParams = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  organizationName?: string;
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type RecipientControllerSearchError = Fetcher.ErrorWrapper<undefined>;

export type RecipientControllerSearchVariables = {
  queryParams?: RecipientControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchRecipientControllerSearch = (
  variables: RecipientControllerSearchVariables
) =>
  apiFetch<
    undefined,
    RecipientControllerSearchError,
    undefined,
    {},
    RecipientControllerSearchQueryParams,
    {}
  >({ url: '/v1/recipient', method: 'get', ...variables });

export const useRecipientControllerSearch = <TData = undefined>(
  variables: RecipientControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      undefined,
      RecipientControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, RecipientControllerSearchError, TData>(
    queryKeyFn({
      path: '/v1/recipient',
      operationId: 'recipientControllerSearch',
      variables,
    }),
    () => fetchRecipientControllerSearch({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type RecipientControllerRetrievePathParams = {
  id: string;
};

export type RecipientControllerRetrieveError = Fetcher.ErrorWrapper<undefined>;

export type RecipientControllerRetrieveVariables = {
  pathParams: RecipientControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchRecipientControllerRetrieve = (
  variables: RecipientControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.Recipient,
    RecipientControllerRetrieveError,
    undefined,
    {},
    {},
    RecipientControllerRetrievePathParams
  >({ url: '/v1/recipient/{id}', method: 'get', ...variables });

export const useRecipientControllerRetrieve = <TData = Schemas.Recipient>(
  variables: RecipientControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.Recipient,
      RecipientControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.Recipient,
    RecipientControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/recipient/{id}',
      operationId: 'recipientControllerRetrieve',
      variables,
    }),
    () => fetchRecipientControllerRetrieve({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type RecipientControllerUpdatePathParams = {
  id: string;
};

export type RecipientControllerUpdateError = Fetcher.ErrorWrapper<undefined>;

export type RecipientControllerUpdateVariables = {
  body?: Schemas.RecipientUpdate;
  pathParams: RecipientControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchRecipientControllerUpdate = (
  variables: RecipientControllerUpdateVariables
) =>
  apiFetch<
    Schemas.Recipient,
    RecipientControllerUpdateError,
    Schemas.RecipientUpdate,
    {},
    {},
    RecipientControllerUpdatePathParams
  >({ url: '/v1/recipient/{id}', method: 'patch', ...variables });

export const useRecipientControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Recipient,
      RecipientControllerUpdateError,
      RecipientControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.Recipient,
    RecipientControllerUpdateError,
    RecipientControllerUpdateVariables
  >(
    (variables: RecipientControllerUpdateVariables) =>
      fetchRecipientControllerUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type RecipientControllerDeletePathParams = {
  id: string;
};

export type RecipientControllerDeleteError = Fetcher.ErrorWrapper<undefined>;

export type RecipientControllerDeleteVariables = {
  pathParams: RecipientControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchRecipientControllerDelete = (
  variables: RecipientControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    RecipientControllerDeleteError,
    undefined,
    {},
    {},
    RecipientControllerDeletePathParams
  >({ url: '/v1/recipient/{id}', method: 'delete', ...variables });

export const useRecipientControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RecipientControllerDeleteError,
      RecipientControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    RecipientControllerDeleteError,
    RecipientControllerDeleteVariables
  >(
    (variables: RecipientControllerDeleteVariables) =>
      fetchRecipientControllerDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type RetrievalShippingLabelControllerCreateRetrievalLabelsError =
  Fetcher.ErrorWrapper<undefined>;

export type RetrievalShippingLabelControllerCreateRetrievalLabelsVariables = {
  body: Schemas.RetrievalShippingLabelCreate;
} & ApiContext['fetcherOptions'];

export const fetchRetrievalShippingLabelControllerCreateRetrievalLabels = (
  variables: RetrievalShippingLabelControllerCreateRetrievalLabelsVariables
) =>
  apiFetch<
    Schemas.RetrievalShippingLabelsResponse,
    RetrievalShippingLabelControllerCreateRetrievalLabelsError,
    Schemas.RetrievalShippingLabelCreate,
    {},
    {},
    {}
  >({ url: '/v1/retrieval-shipping-label', method: 'post', ...variables });

export const useRetrievalShippingLabelControllerCreateRetrievalLabels = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RetrievalShippingLabelsResponse,
      RetrievalShippingLabelControllerCreateRetrievalLabelsError,
      RetrievalShippingLabelControllerCreateRetrievalLabelsVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.RetrievalShippingLabelsResponse,
    RetrievalShippingLabelControllerCreateRetrievalLabelsError,
    RetrievalShippingLabelControllerCreateRetrievalLabelsVariables
  >(
    (
      variables: RetrievalShippingLabelControllerCreateRetrievalLabelsVariables
    ) =>
      fetchRetrievalShippingLabelControllerCreateRetrievalLabels({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type ShippingControllerCreateError = Fetcher.ErrorWrapper<undefined>;

export type ShippingControllerCreateVariables = {
  body: Schemas.ShippingCreate;
} & ApiContext['fetcherOptions'];

export const fetchShippingControllerCreate = (
  variables: ShippingControllerCreateVariables
) =>
  apiFetch<
    Schemas.ShippingResponse,
    ShippingControllerCreateError,
    Schemas.ShippingCreate,
    {},
    {},
    {}
  >({ url: '/v1/shipping', method: 'post', ...variables });

export const useShippingControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ShippingResponse,
      ShippingControllerCreateError,
      ShippingControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ShippingResponse,
    ShippingControllerCreateError,
    ShippingControllerCreateVariables
  >(
    (variables: ShippingControllerCreateVariables) =>
      fetchShippingControllerCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ShipmentCarrierControllerCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type ShipmentCarrierControllerCreateVariables = {
  body: Schemas.ShipmentCarrierCreate;
} & ApiContext['fetcherOptions'];

export const fetchShipmentCarrierControllerCreate = (
  variables: ShipmentCarrierControllerCreateVariables
) =>
  apiFetch<
    Schemas.ShipmentCarrierResponse,
    ShipmentCarrierControllerCreateError,
    Schemas.ShipmentCarrierCreate,
    {},
    {},
    {}
  >({ url: '/v1/shipment-carrier', method: 'post', ...variables });

export const useShipmentCarrierControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ShipmentCarrierResponse,
      ShipmentCarrierControllerCreateError,
      ShipmentCarrierControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ShipmentCarrierResponse,
    ShipmentCarrierControllerCreateError,
    ShipmentCarrierControllerCreateVariables
  >(
    (variables: ShipmentCarrierControllerCreateVariables) =>
      fetchShipmentCarrierControllerCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ShipmentCarrierControllerSearchQueryParams = {
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type ShipmentCarrierControllerSearchError =
  Fetcher.ErrorWrapper<undefined>;

export type ShipmentCarrierControllerSearchVariables = {
  queryParams?: ShipmentCarrierControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchShipmentCarrierControllerSearch = (
  variables: ShipmentCarrierControllerSearchVariables
) =>
  apiFetch<
    Schemas.ShipmentCarrierList,
    ShipmentCarrierControllerSearchError,
    undefined,
    {},
    ShipmentCarrierControllerSearchQueryParams,
    {}
  >({ url: '/v1/shipment-carrier', method: 'get', ...variables });

export const useShipmentCarrierControllerSearch = <
  TData = Schemas.ShipmentCarrierList
>(
  variables: ShipmentCarrierControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ShipmentCarrierList,
      ShipmentCarrierControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ShipmentCarrierList,
    ShipmentCarrierControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/shipment-carrier',
      operationId: 'shipmentCarrierControllerSearch',
      variables,
    }),
    () =>
      fetchShipmentCarrierControllerSearch({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ShipmentCarrierControllerRetrievePathParams = {
  id: string;
};

export type ShipmentCarrierControllerRetrieveError =
  Fetcher.ErrorWrapper<undefined>;

export type ShipmentCarrierControllerRetrieveVariables = {
  pathParams: ShipmentCarrierControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchShipmentCarrierControllerRetrieve = (
  variables: ShipmentCarrierControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.ShipmentCarrierResponse,
    ShipmentCarrierControllerRetrieveError,
    undefined,
    {},
    {},
    ShipmentCarrierControllerRetrievePathParams
  >({ url: '/v1/shipment-carrier/{id}', method: 'get', ...variables });

export const useShipmentCarrierControllerRetrieve = <
  TData = Schemas.ShipmentCarrierResponse
>(
  variables: ShipmentCarrierControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ShipmentCarrierResponse,
      ShipmentCarrierControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ShipmentCarrierResponse,
    ShipmentCarrierControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/shipment-carrier/{id}',
      operationId: 'shipmentCarrierControllerRetrieve',
      variables,
    }),
    () =>
      fetchShipmentCarrierControllerRetrieve({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ShipmentCarrierControllerDeletePathParams = {
  id: string;
};

export type ShipmentCarrierControllerDeleteError =
  Fetcher.ErrorWrapper<undefined>;

export type ShipmentCarrierControllerDeleteVariables = {
  pathParams: ShipmentCarrierControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchShipmentCarrierControllerDelete = (
  variables: ShipmentCarrierControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    ShipmentCarrierControllerDeleteError,
    undefined,
    {},
    {},
    ShipmentCarrierControllerDeletePathParams
  >({ url: '/v1/shipment-carrier/{id}', method: 'delete', ...variables });

export const useShipmentCarrierControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ShipmentCarrierControllerDeleteError,
      ShipmentCarrierControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ShipmentCarrierControllerDeleteError,
    ShipmentCarrierControllerDeleteVariables
  >(
    (variables: ShipmentCarrierControllerDeleteVariables) =>
      fetchShipmentCarrierControllerDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ShipmentItemControllerCreateError = Fetcher.ErrorWrapper<undefined>;

export type ShipmentItemControllerCreateVariables = {
  body: Schemas.ShipmentItemCreate;
} & ApiContext['fetcherOptions'];

export const fetchShipmentItemControllerCreate = (
  variables: ShipmentItemControllerCreateVariables
) =>
  apiFetch<
    Schemas.ShipmentItem,
    ShipmentItemControllerCreateError,
    Schemas.ShipmentItemCreate,
    {},
    {},
    {}
  >({ url: '/v1/shipment-item', method: 'post', ...variables });

export const useShipmentItemControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ShipmentItem,
      ShipmentItemControllerCreateError,
      ShipmentItemControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ShipmentItem,
    ShipmentItemControllerCreateError,
    ShipmentItemControllerCreateVariables
  >(
    (variables: ShipmentItemControllerCreateVariables) =>
      fetchShipmentItemControllerCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ShipmentItemControllerSearchQueryParams = {
  id?: string;
  shipmentId?: string;
  externalOrderItemId?: string;
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type ShipmentItemControllerSearchError = Fetcher.ErrorWrapper<undefined>;

export type ShipmentItemControllerSearchVariables = {
  queryParams?: ShipmentItemControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchShipmentItemControllerSearch = (
  variables: ShipmentItemControllerSearchVariables
) =>
  apiFetch<
    undefined,
    ShipmentItemControllerSearchError,
    undefined,
    {},
    ShipmentItemControllerSearchQueryParams,
    {}
  >({ url: '/v1/shipment-item', method: 'get', ...variables });

export const useShipmentItemControllerSearch = <TData = undefined>(
  variables: ShipmentItemControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      undefined,
      ShipmentItemControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    undefined,
    ShipmentItemControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/shipment-item',
      operationId: 'shipmentItemControllerSearch',
      variables,
    }),
    () =>
      fetchShipmentItemControllerSearch({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ShipmentItemControllerRetrievePathParams = {
  id: string;
};

export type ShipmentItemControllerRetrieveError =
  Fetcher.ErrorWrapper<undefined>;

export type ShipmentItemControllerRetrieveVariables = {
  pathParams: ShipmentItemControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchShipmentItemControllerRetrieve = (
  variables: ShipmentItemControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.ShipmentItem,
    ShipmentItemControllerRetrieveError,
    undefined,
    {},
    {},
    ShipmentItemControllerRetrievePathParams
  >({ url: '/v1/shipment-item/{id}', method: 'get', ...variables });

export const useShipmentItemControllerRetrieve = <TData = Schemas.ShipmentItem>(
  variables: ShipmentItemControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ShipmentItem,
      ShipmentItemControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ShipmentItem,
    ShipmentItemControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/shipment-item/{id}',
      operationId: 'shipmentItemControllerRetrieve',
      variables,
    }),
    () =>
      fetchShipmentItemControllerRetrieve({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ShipmentItemControllerUpdatePathParams = {
  id: string;
};

export type ShipmentItemControllerUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ShipmentItemControllerUpdateVariables = {
  body?: Schemas.ShipmentItemUpdate;
  pathParams: ShipmentItemControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchShipmentItemControllerUpdate = (
  variables: ShipmentItemControllerUpdateVariables
) =>
  apiFetch<
    Schemas.ShipmentItem,
    ShipmentItemControllerUpdateError,
    Schemas.ShipmentItemUpdate,
    {},
    {},
    ShipmentItemControllerUpdatePathParams
  >({ url: '/v1/shipment-item/{id}', method: 'patch', ...variables });

export const useShipmentItemControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ShipmentItem,
      ShipmentItemControllerUpdateError,
      ShipmentItemControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ShipmentItem,
    ShipmentItemControllerUpdateError,
    ShipmentItemControllerUpdateVariables
  >(
    (variables: ShipmentItemControllerUpdateVariables) =>
      fetchShipmentItemControllerUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ShipmentItemControllerDeletePathParams = {
  id: string;
};

export type ShipmentItemControllerDeleteError = Fetcher.ErrorWrapper<undefined>;

export type ShipmentItemControllerDeleteVariables = {
  pathParams: ShipmentItemControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchShipmentItemControllerDelete = (
  variables: ShipmentItemControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    ShipmentItemControllerDeleteError,
    undefined,
    {},
    {},
    ShipmentItemControllerDeletePathParams
  >({ url: '/v1/shipment-item/{id}', method: 'delete', ...variables });

export const useShipmentItemControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ShipmentItemControllerDeleteError,
      ShipmentItemControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ShipmentItemControllerDeleteError,
    ShipmentItemControllerDeleteVariables
  >(
    (variables: ShipmentItemControllerDeleteVariables) =>
      fetchShipmentItemControllerDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ShipmentControllerCreateError = Fetcher.ErrorWrapper<undefined>;

export type ShipmentControllerCreateVariables = {
  body: Schemas.ShipmentCreate;
} & ApiContext['fetcherOptions'];

export const fetchShipmentControllerCreate = (
  variables: ShipmentControllerCreateVariables
) =>
  apiFetch<
    Schemas.Shipment,
    ShipmentControllerCreateError,
    Schemas.ShipmentCreate,
    {},
    {},
    {}
  >({ url: '/v1/shipment', method: 'post', ...variables });

export const useShipmentControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Shipment,
      ShipmentControllerCreateError,
      ShipmentControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.Shipment,
    ShipmentControllerCreateError,
    ShipmentControllerCreateVariables
  >(
    (variables: ShipmentControllerCreateVariables) =>
      fetchShipmentControllerCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ShipmentControllerSearchQueryParams = {
  id?: string;
  vendorOrderId?: string;
  destinationAddressId?: string;
  originAddressId?: string;
  carrier?:
    | 'AMAZON'
    | 'AMAZON_ORDER'
    | 'DELIVER_IT'
    | 'DHL'
    | 'DHL_GLOBAL_MAIL'
    | 'FEDEX'
    | 'LASERSHIP'
    | 'UNKNOWN'
    | 'UPS'
    | 'USPS';
  status?:
    | 'ATTEMPT_FAILED'
    | 'AVAILABLE_FOR_PICKUP'
    | 'CREATED'
    | 'DELIVERED'
    | 'EXCEPTION'
    | 'EXPIRED'
    | 'INFO_RECEIVED'
    | 'IN_TRANSIT'
    | 'OUT_FOR_DELIVERY'
    | 'PENDING';
  legacyId?: string;
  vendorOrderNumber?: string;
  /**
   * @format date-time
   */
  deliveredAt?: string;
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type ShipmentControllerSearchError = Fetcher.ErrorWrapper<undefined>;

export type ShipmentControllerSearchVariables = {
  queryParams?: ShipmentControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchShipmentControllerSearch = (
  variables: ShipmentControllerSearchVariables
) =>
  apiFetch<
    undefined,
    ShipmentControllerSearchError,
    undefined,
    {},
    ShipmentControllerSearchQueryParams,
    {}
  >({ url: '/v1/shipment', method: 'get', ...variables });

export const useShipmentControllerSearch = <TData = undefined>(
  variables: ShipmentControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, ShipmentControllerSearchError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, ShipmentControllerSearchError, TData>(
    queryKeyFn({
      path: '/v1/shipment',
      operationId: 'shipmentControllerSearch',
      variables,
    }),
    () => fetchShipmentControllerSearch({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ShipmentControllerRetrievePathParams = {
  id: string;
};

export type ShipmentControllerRetrieveError = Fetcher.ErrorWrapper<undefined>;

export type ShipmentControllerRetrieveVariables = {
  pathParams: ShipmentControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchShipmentControllerRetrieve = (
  variables: ShipmentControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.Shipment,
    ShipmentControllerRetrieveError,
    undefined,
    {},
    {},
    ShipmentControllerRetrievePathParams
  >({ url: '/v1/shipment/{id}', method: 'get', ...variables });

export const useShipmentControllerRetrieve = <TData = Schemas.Shipment>(
  variables: ShipmentControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.Shipment,
      ShipmentControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.Shipment,
    ShipmentControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/shipment/{id}',
      operationId: 'shipmentControllerRetrieve',
      variables,
    }),
    () => fetchShipmentControllerRetrieve({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ShipmentControllerUpdatePathParams = {
  id: string;
};

export type ShipmentControllerUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ShipmentControllerUpdateVariables = {
  body?: Schemas.ShipmentUpdate;
  pathParams: ShipmentControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchShipmentControllerUpdate = (
  variables: ShipmentControllerUpdateVariables
) =>
  apiFetch<
    Schemas.Shipment,
    ShipmentControllerUpdateError,
    Schemas.ShipmentUpdate,
    {},
    {},
    ShipmentControllerUpdatePathParams
  >({ url: '/v1/shipment/{id}', method: 'patch', ...variables });

export const useShipmentControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Shipment,
      ShipmentControllerUpdateError,
      ShipmentControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.Shipment,
    ShipmentControllerUpdateError,
    ShipmentControllerUpdateVariables
  >(
    (variables: ShipmentControllerUpdateVariables) =>
      fetchShipmentControllerUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ShipmentControllerDeletePathParams = {
  id: string;
};

export type ShipmentControllerDeleteError = Fetcher.ErrorWrapper<undefined>;

export type ShipmentControllerDeleteVariables = {
  pathParams: ShipmentControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchShipmentControllerDelete = (
  variables: ShipmentControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    ShipmentControllerDeleteError,
    undefined,
    {},
    {},
    ShipmentControllerDeletePathParams
  >({ url: '/v1/shipment/{id}', method: 'delete', ...variables });

export const useShipmentControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ShipmentControllerDeleteError,
      ShipmentControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ShipmentControllerDeleteError,
    ShipmentControllerDeleteVariables
  >(
    (variables: ShipmentControllerDeleteVariables) =>
      fetchShipmentControllerDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ShipmentTrackingControllerCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type ShipmentTrackingControllerCreateVariables = {
  body: Schemas.TrackingEventParams;
} & ApiContext['fetcherOptions'];

export const fetchShipmentTrackingControllerCreate = (
  variables: ShipmentTrackingControllerCreateVariables
) =>
  apiFetch<
    undefined,
    ShipmentTrackingControllerCreateError,
    Schemas.TrackingEventParams,
    {},
    {},
    {}
  >({ url: '/webhook/shipment-tracking', method: 'post', ...variables });

export const useShipmentTrackingControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ShipmentTrackingControllerCreateError,
      ShipmentTrackingControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ShipmentTrackingControllerCreateError,
    ShipmentTrackingControllerCreateVariables
  >(
    (variables: ShipmentTrackingControllerCreateVariables) =>
      fetchShipmentTrackingControllerCreate({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type TrackingControllerCreateError = Fetcher.ErrorWrapper<undefined>;

export type TrackingControllerCreateVariables = {
  body: Schemas.TrackingCreate;
} & ApiContext['fetcherOptions'];

export const fetchTrackingControllerCreate = (
  variables: TrackingControllerCreateVariables
) =>
  apiFetch<
    Schemas.Tracking,
    TrackingControllerCreateError,
    Schemas.TrackingCreate,
    {},
    {},
    {}
  >({ url: '/v1/tracking', method: 'post', ...variables });

export const useTrackingControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Tracking,
      TrackingControllerCreateError,
      TrackingControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.Tracking,
    TrackingControllerCreateError,
    TrackingControllerCreateVariables
  >(
    (variables: TrackingControllerCreateVariables) =>
      fetchTrackingControllerCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrackingControllerSearchQueryParams = {
  id?: string;
  shipmentId?: string;
  trackingLink?: string;
  trackingNumber?: string;
  externalProviderId?: string;
  externalProvider?: 'AFTERSHIP';
  /**
   * @format date-time
   */
  expectedDeliveryDate?: string;
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type TrackingControllerSearchError = Fetcher.ErrorWrapper<undefined>;

export type TrackingControllerSearchVariables = {
  queryParams?: TrackingControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchTrackingControllerSearch = (
  variables: TrackingControllerSearchVariables
) =>
  apiFetch<
    undefined,
    TrackingControllerSearchError,
    undefined,
    {},
    TrackingControllerSearchQueryParams,
    {}
  >({ url: '/v1/tracking', method: 'get', ...variables });

export const useTrackingControllerSearch = <TData = undefined>(
  variables: TrackingControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, TrackingControllerSearchError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, TrackingControllerSearchError, TData>(
    queryKeyFn({
      path: '/v1/tracking',
      operationId: 'trackingControllerSearch',
      variables,
    }),
    () => fetchTrackingControllerSearch({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type TrackingControllerRetrievePathParams = {
  id: string;
};

export type TrackingControllerRetrieveError = Fetcher.ErrorWrapper<undefined>;

export type TrackingControllerRetrieveVariables = {
  pathParams: TrackingControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrackingControllerRetrieve = (
  variables: TrackingControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.Tracking,
    TrackingControllerRetrieveError,
    undefined,
    {},
    {},
    TrackingControllerRetrievePathParams
  >({ url: '/v1/tracking/{id}', method: 'get', ...variables });

export const useTrackingControllerRetrieve = <TData = Schemas.Tracking>(
  variables: TrackingControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.Tracking,
      TrackingControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.Tracking,
    TrackingControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/tracking/{id}',
      operationId: 'trackingControllerRetrieve',
      variables,
    }),
    () => fetchTrackingControllerRetrieve({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type TrackingControllerUpdatePathParams = {
  id: string;
};

export type TrackingControllerUpdateError = Fetcher.ErrorWrapper<undefined>;

export type TrackingControllerUpdateVariables = {
  body?: Schemas.TrackingUpdate;
  pathParams: TrackingControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrackingControllerUpdate = (
  variables: TrackingControllerUpdateVariables
) =>
  apiFetch<
    Schemas.Tracking,
    TrackingControllerUpdateError,
    Schemas.TrackingUpdate,
    {},
    {},
    TrackingControllerUpdatePathParams
  >({ url: '/v1/tracking/{id}', method: 'patch', ...variables });

export const useTrackingControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Tracking,
      TrackingControllerUpdateError,
      TrackingControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.Tracking,
    TrackingControllerUpdateError,
    TrackingControllerUpdateVariables
  >(
    (variables: TrackingControllerUpdateVariables) =>
      fetchTrackingControllerUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrackingControllerDeletePathParams = {
  id: string;
};

export type TrackingControllerDeleteError = Fetcher.ErrorWrapper<undefined>;

export type TrackingControllerDeleteVariables = {
  pathParams: TrackingControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrackingControllerDelete = (
  variables: TrackingControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    TrackingControllerDeleteError,
    undefined,
    {},
    {},
    TrackingControllerDeletePathParams
  >({ url: '/v1/tracking/{id}', method: 'delete', ...variables });

export const useTrackingControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrackingControllerDeleteError,
      TrackingControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    TrackingControllerDeleteError,
    TrackingControllerDeleteVariables
  >(
    (variables: TrackingControllerDeleteVariables) =>
      fetchTrackingControllerDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ShipmentCreationControllerCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type ShipmentCreationControllerCreateVariables = {
  body: Schemas.ShipmentCreationParams;
} & ApiContext['fetcherOptions'];

export const fetchShipmentCreationControllerCreate = (
  variables: ShipmentCreationControllerCreateVariables
) =>
  apiFetch<
    undefined,
    ShipmentCreationControllerCreateError,
    Schemas.ShipmentCreationParams,
    {},
    {},
    {}
  >({ url: '/webhook/shipment-creation', method: 'post', ...variables });

export const useShipmentCreationControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ShipmentCreationControllerCreateError,
      ShipmentCreationControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ShipmentCreationControllerCreateError,
    ShipmentCreationControllerCreateVariables
  >(
    (variables: ShipmentCreationControllerCreateVariables) =>
      fetchShipmentCreationControllerCreate({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type VendorOrderInvoiceControllerCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type VendorOrderInvoiceControllerCreateVariables = {
  body: Schemas.VendorOrderInvoiceCreate;
} & ApiContext['fetcherOptions'];

export const fetchVendorOrderInvoiceControllerCreate = (
  variables: VendorOrderInvoiceControllerCreateVariables
) =>
  apiFetch<
    Schemas.VendorOrderInvoiceResponse,
    VendorOrderInvoiceControllerCreateError,
    Schemas.VendorOrderInvoiceCreate,
    {},
    {},
    {}
  >({ url: '/v1/vendor-order-invoice', method: 'post', ...variables });

export const useVendorOrderInvoiceControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.VendorOrderInvoiceResponse,
      VendorOrderInvoiceControllerCreateError,
      VendorOrderInvoiceControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.VendorOrderInvoiceResponse,
    VendorOrderInvoiceControllerCreateError,
    VendorOrderInvoiceControllerCreateVariables
  >(
    (variables: VendorOrderInvoiceControllerCreateVariables) =>
      fetchVendorOrderInvoiceControllerCreate({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type VendorOrderInvoiceControllerSearchQueryParams = {
  /**
   * The unique identifier of the vendor order. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  vendorOrderId?: string;
  /**
   * The unique identifier of the document. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  documentId?: string;
  /**
   * A link to the invoice with the external Vendor Order (typically generated by the accounting software).
   */
  externalInvoiceUrl?: string;
  /**
   * The confirmation number provided for the Vendor Order.
   */
  invoiceNumber?: string;
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type VendorOrderInvoiceControllerSearchError =
  Fetcher.ErrorWrapper<undefined>;

export type VendorOrderInvoiceControllerSearchVariables = {
  queryParams?: VendorOrderInvoiceControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchVendorOrderInvoiceControllerSearch = (
  variables: VendorOrderInvoiceControllerSearchVariables
) =>
  apiFetch<
    Schemas.VendorOrderInvoiceResponseList,
    VendorOrderInvoiceControllerSearchError,
    undefined,
    {},
    VendorOrderInvoiceControllerSearchQueryParams,
    {}
  >({ url: '/v1/vendor-order-invoice', method: 'get', ...variables });

export const useVendorOrderInvoiceControllerSearch = <
  TData = Schemas.VendorOrderInvoiceResponseList
>(
  variables: VendorOrderInvoiceControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.VendorOrderInvoiceResponseList,
      VendorOrderInvoiceControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.VendorOrderInvoiceResponseList,
    VendorOrderInvoiceControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/vendor-order-invoice',
      operationId: 'vendorOrderInvoiceControllerSearch',
      variables,
    }),
    () =>
      fetchVendorOrderInvoiceControllerSearch({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type VendorOrderInvoiceControllerRetrievePathParams = {
  id: string;
};

export type VendorOrderInvoiceControllerRetrieveError =
  Fetcher.ErrorWrapper<undefined>;

export type VendorOrderInvoiceControllerRetrieveVariables = {
  pathParams: VendorOrderInvoiceControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchVendorOrderInvoiceControllerRetrieve = (
  variables: VendorOrderInvoiceControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.VendorOrderInvoiceResponse,
    VendorOrderInvoiceControllerRetrieveError,
    undefined,
    {},
    {},
    VendorOrderInvoiceControllerRetrievePathParams
  >({ url: '/v1/vendor-order-invoice/{id}', method: 'get', ...variables });

export const useVendorOrderInvoiceControllerRetrieve = <
  TData = Schemas.VendorOrderInvoiceResponse
>(
  variables: VendorOrderInvoiceControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.VendorOrderInvoiceResponse,
      VendorOrderInvoiceControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.VendorOrderInvoiceResponse,
    VendorOrderInvoiceControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/vendor-order-invoice/{id}',
      operationId: 'vendorOrderInvoiceControllerRetrieve',
      variables,
    }),
    () =>
      fetchVendorOrderInvoiceControllerRetrieve({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type VendorOrderInvoiceControllerUpdatePathParams = {
  id: string;
};

export type VendorOrderInvoiceControllerUpdateError =
  Fetcher.ErrorWrapper<undefined>;

export type VendorOrderInvoiceControllerUpdateVariables = {
  body?: Schemas.VendorOrderInvoiceUpdate;
  pathParams: VendorOrderInvoiceControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchVendorOrderInvoiceControllerUpdate = (
  variables: VendorOrderInvoiceControllerUpdateVariables
) =>
  apiFetch<
    Schemas.VendorOrderInvoiceResponse,
    VendorOrderInvoiceControllerUpdateError,
    Schemas.VendorOrderInvoiceUpdate,
    {},
    {},
    VendorOrderInvoiceControllerUpdatePathParams
  >({ url: '/v1/vendor-order-invoice/{id}', method: 'patch', ...variables });

export const useVendorOrderInvoiceControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.VendorOrderInvoiceResponse,
      VendorOrderInvoiceControllerUpdateError,
      VendorOrderInvoiceControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.VendorOrderInvoiceResponse,
    VendorOrderInvoiceControllerUpdateError,
    VendorOrderInvoiceControllerUpdateVariables
  >(
    (variables: VendorOrderInvoiceControllerUpdateVariables) =>
      fetchVendorOrderInvoiceControllerUpdate({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type VendorOrderInvoiceControllerDeletePathParams = {
  id: string;
};

export type VendorOrderInvoiceControllerDeleteError =
  Fetcher.ErrorWrapper<undefined>;

export type VendorOrderInvoiceControllerDeleteVariables = {
  pathParams: VendorOrderInvoiceControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchVendorOrderInvoiceControllerDelete = (
  variables: VendorOrderInvoiceControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    VendorOrderInvoiceControllerDeleteError,
    undefined,
    {},
    {},
    VendorOrderInvoiceControllerDeletePathParams
  >({ url: '/v1/vendor-order-invoice/{id}', method: 'delete', ...variables });

export const useVendorOrderInvoiceControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      VendorOrderInvoiceControllerDeleteError,
      VendorOrderInvoiceControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    VendorOrderInvoiceControllerDeleteError,
    VendorOrderInvoiceControllerDeleteVariables
  >(
    (variables: VendorOrderInvoiceControllerDeleteVariables) =>
      fetchVendorOrderInvoiceControllerDelete({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type VendorOrderQuoteControllerCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type VendorOrderQuoteControllerCreateVariables = {
  body: Schemas.VendorOrderQuoteCreate;
} & ApiContext['fetcherOptions'];

export const fetchVendorOrderQuoteControllerCreate = (
  variables: VendorOrderQuoteControllerCreateVariables
) =>
  apiFetch<
    Schemas.VendorOrderQuoteResponse,
    VendorOrderQuoteControllerCreateError,
    Schemas.VendorOrderQuoteCreate,
    {},
    {},
    {}
  >({ url: '/v1/vendor-order-quote', method: 'post', ...variables });

export const useVendorOrderQuoteControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.VendorOrderQuoteResponse,
      VendorOrderQuoteControllerCreateError,
      VendorOrderQuoteControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.VendorOrderQuoteResponse,
    VendorOrderQuoteControllerCreateError,
    VendorOrderQuoteControllerCreateVariables
  >(
    (variables: VendorOrderQuoteControllerCreateVariables) =>
      fetchVendorOrderQuoteControllerCreate({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type VendorOrderQuoteControllerSearchQueryParams = {
  /**
   * The unique identifier of the vendor order. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  vendorOrderId?: string;
  /**
   * The unique identifier of the document. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  documentId?: string;
  /**
   * A link to the quote with the external Vendor Order (typically generated by the accounting software).
   */
  externalQuoteUrl?: string;
  /**
   * The confirmation number provided for the Vendor Order.
   */
  quoteNumber?: string;
  /**
   * The total cost of the items included in the Vendor Order in USD
   *
   * @pattern ^-?\d{1,3}(?:,?\d{3}){0,3}\.?\d{2}$
   */
  subtotalAmount?: string;
  /**
   * The price paid for shipping in USD
   *
   * @pattern ^-?\d{1,3}(?:,?\d{3}){0,3}\.?\d{2}$
   */
  shippingAmount?: string;
  /**
   * The amount of tax paid in USD
   *
   * @pattern ^-?\d{1,3}(?:,?\d{3}){0,3}\.?\d{2}$
   */
  taxAmount?: string;
  /**
   * The total cost of the Vendor Order in USD
   *
   * @pattern ^-?\d{1,3}(?:,?\d{3}){0,3}\.?\d{2}$
   */
  totalAmount?: string;
  /**
   * The time that the Vendor Order was placed with the Vendor
   *
   * @format date-time
   */
  quotedAt?: string;
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type VendorOrderQuoteControllerSearchError =
  Fetcher.ErrorWrapper<undefined>;

export type VendorOrderQuoteControllerSearchVariables = {
  queryParams?: VendorOrderQuoteControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchVendorOrderQuoteControllerSearch = (
  variables: VendorOrderQuoteControllerSearchVariables
) =>
  apiFetch<
    Schemas.VendorOrderQuoteResponseList,
    VendorOrderQuoteControllerSearchError,
    undefined,
    {},
    VendorOrderQuoteControllerSearchQueryParams,
    {}
  >({ url: '/v1/vendor-order-quote', method: 'get', ...variables });

export const useVendorOrderQuoteControllerSearch = <
  TData = Schemas.VendorOrderQuoteResponseList
>(
  variables: VendorOrderQuoteControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.VendorOrderQuoteResponseList,
      VendorOrderQuoteControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.VendorOrderQuoteResponseList,
    VendorOrderQuoteControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/vendor-order-quote',
      operationId: 'vendorOrderQuoteControllerSearch',
      variables,
    }),
    () =>
      fetchVendorOrderQuoteControllerSearch({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type VendorOrderQuoteControllerRetrievePathParams = {
  id: string;
};

export type VendorOrderQuoteControllerRetrieveError =
  Fetcher.ErrorWrapper<undefined>;

export type VendorOrderQuoteControllerRetrieveVariables = {
  pathParams: VendorOrderQuoteControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchVendorOrderQuoteControllerRetrieve = (
  variables: VendorOrderQuoteControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.VendorOrderQuoteResponse,
    VendorOrderQuoteControllerRetrieveError,
    undefined,
    {},
    {},
    VendorOrderQuoteControllerRetrievePathParams
  >({ url: '/v1/vendor-order-quote/{id}', method: 'get', ...variables });

export const useVendorOrderQuoteControllerRetrieve = <
  TData = Schemas.VendorOrderQuoteResponse
>(
  variables: VendorOrderQuoteControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.VendorOrderQuoteResponse,
      VendorOrderQuoteControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.VendorOrderQuoteResponse,
    VendorOrderQuoteControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/vendor-order-quote/{id}',
      operationId: 'vendorOrderQuoteControllerRetrieve',
      variables,
    }),
    () =>
      fetchVendorOrderQuoteControllerRetrieve({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type VendorOrderQuoteControllerUpdatePathParams = {
  id: string;
};

export type VendorOrderQuoteControllerUpdateError =
  Fetcher.ErrorWrapper<undefined>;

export type VendorOrderQuoteControllerUpdateVariables = {
  body?: Schemas.VendorOrderQuoteUpdate;
  pathParams: VendorOrderQuoteControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchVendorOrderQuoteControllerUpdate = (
  variables: VendorOrderQuoteControllerUpdateVariables
) =>
  apiFetch<
    Schemas.VendorOrderQuoteResponse,
    VendorOrderQuoteControllerUpdateError,
    Schemas.VendorOrderQuoteUpdate,
    {},
    {},
    VendorOrderQuoteControllerUpdatePathParams
  >({ url: '/v1/vendor-order-quote/{id}', method: 'patch', ...variables });

export const useVendorOrderQuoteControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.VendorOrderQuoteResponse,
      VendorOrderQuoteControllerUpdateError,
      VendorOrderQuoteControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.VendorOrderQuoteResponse,
    VendorOrderQuoteControllerUpdateError,
    VendorOrderQuoteControllerUpdateVariables
  >(
    (variables: VendorOrderQuoteControllerUpdateVariables) =>
      fetchVendorOrderQuoteControllerUpdate({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type VendorOrderQuoteControllerDeletePathParams = {
  id: string;
};

export type VendorOrderQuoteControllerDeleteError =
  Fetcher.ErrorWrapper<undefined>;

export type VendorOrderQuoteControllerDeleteVariables = {
  pathParams: VendorOrderQuoteControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchVendorOrderQuoteControllerDelete = (
  variables: VendorOrderQuoteControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    VendorOrderQuoteControllerDeleteError,
    undefined,
    {},
    {},
    VendorOrderQuoteControllerDeletePathParams
  >({ url: '/v1/vendor-order-quote/{id}', method: 'delete', ...variables });

export const useVendorOrderQuoteControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      VendorOrderQuoteControllerDeleteError,
      VendorOrderQuoteControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    VendorOrderQuoteControllerDeleteError,
    VendorOrderQuoteControllerDeleteVariables
  >(
    (variables: VendorOrderQuoteControllerDeleteVariables) =>
      fetchVendorOrderQuoteControllerDelete({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type VendorControllerCreateError = Fetcher.ErrorWrapper<undefined>;

export type VendorControllerCreateVariables = {
  body: Schemas.VendorCreate;
} & ApiContext['fetcherOptions'];

export const fetchVendorControllerCreate = (
  variables: VendorControllerCreateVariables
) =>
  apiFetch<
    Schemas.VendorResponse,
    VendorControllerCreateError,
    Schemas.VendorCreate,
    {},
    {},
    {}
  >({ url: '/v1/vendor', method: 'post', ...variables });

export const useVendorControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.VendorResponse,
      VendorControllerCreateError,
      VendorControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.VendorResponse,
    VendorControllerCreateError,
    VendorControllerCreateVariables
  >(
    (variables: VendorControllerCreateVariables) =>
      fetchVendorControllerCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type VendorControllerSearchQueryParams = {
  /**
   * The name of the vendor
   */
  name?: string;
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type VendorControllerSearchError = Fetcher.ErrorWrapper<undefined>;

export type VendorControllerSearchVariables = {
  queryParams?: VendorControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchVendorControllerSearch = (
  variables: VendorControllerSearchVariables
) =>
  apiFetch<
    Schemas.VendorResponseList,
    VendorControllerSearchError,
    undefined,
    {},
    VendorControllerSearchQueryParams,
    {}
  >({ url: '/v1/vendor', method: 'get', ...variables });

export const useVendorControllerSearch = <TData = Schemas.VendorResponseList>(
  variables: VendorControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.VendorResponseList,
      VendorControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.VendorResponseList,
    VendorControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/vendor',
      operationId: 'vendorControllerSearch',
      variables,
    }),
    () => fetchVendorControllerSearch({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type VendorControllerRetrievePathParams = {
  id: string;
};

export type VendorControllerRetrieveError = Fetcher.ErrorWrapper<undefined>;

export type VendorControllerRetrieveVariables = {
  pathParams: VendorControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchVendorControllerRetrieve = (
  variables: VendorControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.VendorResponse,
    VendorControllerRetrieveError,
    undefined,
    {},
    {},
    VendorControllerRetrievePathParams
  >({ url: '/v1/vendor/{id}', method: 'get', ...variables });

export const useVendorControllerRetrieve = <TData = Schemas.VendorResponse>(
  variables: VendorControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.VendorResponse,
      VendorControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.VendorResponse,
    VendorControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/vendor/{id}',
      operationId: 'vendorControllerRetrieve',
      variables,
    }),
    () => fetchVendorControllerRetrieve({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type VendorControllerUpdatePathParams = {
  id: string;
};

export type VendorControllerUpdateError = Fetcher.ErrorWrapper<undefined>;

export type VendorControllerUpdateVariables = {
  body?: Schemas.VendorUpdate;
  pathParams: VendorControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchVendorControllerUpdate = (
  variables: VendorControllerUpdateVariables
) =>
  apiFetch<
    Schemas.VendorResponse,
    VendorControllerUpdateError,
    Schemas.VendorUpdate,
    {},
    {},
    VendorControllerUpdatePathParams
  >({ url: '/v1/vendor/{id}', method: 'patch', ...variables });

export const useVendorControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.VendorResponse,
      VendorControllerUpdateError,
      VendorControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.VendorResponse,
    VendorControllerUpdateError,
    VendorControllerUpdateVariables
  >(
    (variables: VendorControllerUpdateVariables) =>
      fetchVendorControllerUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type VendorControllerDeletePathParams = {
  id: string;
};

export type VendorControllerDeleteError = Fetcher.ErrorWrapper<undefined>;

export type VendorControllerDeleteVariables = {
  pathParams: VendorControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchVendorControllerDelete = (
  variables: VendorControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    VendorControllerDeleteError,
    undefined,
    {},
    {},
    VendorControllerDeletePathParams
  >({ url: '/v1/vendor/{id}', method: 'delete', ...variables });

export const useVendorControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      VendorControllerDeleteError,
      VendorControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    VendorControllerDeleteError,
    VendorControllerDeleteVariables
  >(
    (variables: VendorControllerDeleteVariables) =>
      fetchVendorControllerDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type VendorOrderControllerCreateError = Fetcher.ErrorWrapper<undefined>;

export type VendorOrderControllerCreateVariables = {
  body: Schemas.VendorOrderCreate;
} & ApiContext['fetcherOptions'];

export const fetchVendorOrderControllerCreate = (
  variables: VendorOrderControllerCreateVariables
) =>
  apiFetch<
    Schemas.VendorOrderResponse,
    VendorOrderControllerCreateError,
    Schemas.VendorOrderCreate,
    {},
    {},
    {}
  >({ url: '/v1/vendor-order', method: 'post', ...variables });

export const useVendorOrderControllerCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.VendorOrderResponse,
      VendorOrderControllerCreateError,
      VendorOrderControllerCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.VendorOrderResponse,
    VendorOrderControllerCreateError,
    VendorOrderControllerCreateVariables
  >(
    (variables: VendorOrderControllerCreateVariables) =>
      fetchVendorOrderControllerCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type VendorOrderControllerSearchQueryParams = {
  /**
   * The unique identifier. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  id?: string;
  /**
   * The unique identifier of the Vendor. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  orderId?: string;
  /**
   * The unique identifier of the Vendor. (A UUID specified by RFC4122).
   *
   * @format uuid
   */
  vendorId?: string;
  /**
   * Confirmation number of the order
   */
  confirmationNumber?: string;
  /**
   * If the product is in stock at this vendor
   *
   * @format date-time
   */
  placedAt?: string;
  /**
   * @minimum 1
   * @maximum 100
   * @default 50
   */
  limit?: number;
  /**
   * @minimum 0
   * @default 0
   */
  offset?: number;
  orderAsc?: string;
  orderDesc?: string;
};

export type VendorOrderControllerSearchError = Fetcher.ErrorWrapper<undefined>;

export type VendorOrderControllerSearchVariables = {
  queryParams?: VendorOrderControllerSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchVendorOrderControllerSearch = (
  variables: VendorOrderControllerSearchVariables
) =>
  apiFetch<
    Schemas.VendorOrderResponseList,
    VendorOrderControllerSearchError,
    undefined,
    {},
    VendorOrderControllerSearchQueryParams,
    {}
  >({ url: '/v1/vendor-order', method: 'get', ...variables });

export const useVendorOrderControllerSearch = <
  TData = Schemas.VendorOrderResponseList
>(
  variables: VendorOrderControllerSearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.VendorOrderResponseList,
      VendorOrderControllerSearchError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.VendorOrderResponseList,
    VendorOrderControllerSearchError,
    TData
  >(
    queryKeyFn({
      path: '/v1/vendor-order',
      operationId: 'vendorOrderControllerSearch',
      variables,
    }),
    () => fetchVendorOrderControllerSearch({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type VendorOrderControllerRetrievePathParams = {
  id: string;
};

export type VendorOrderControllerRetrieveError =
  Fetcher.ErrorWrapper<undefined>;

export type VendorOrderControllerRetrieveVariables = {
  pathParams: VendorOrderControllerRetrievePathParams;
} & ApiContext['fetcherOptions'];

export const fetchVendorOrderControllerRetrieve = (
  variables: VendorOrderControllerRetrieveVariables
) =>
  apiFetch<
    Schemas.VendorOrderResponse,
    VendorOrderControllerRetrieveError,
    undefined,
    {},
    {},
    VendorOrderControllerRetrievePathParams
  >({ url: '/v1/vendor-order/{id}', method: 'get', ...variables });

export const useVendorOrderControllerRetrieve = <
  TData = Schemas.VendorOrderResponse
>(
  variables: VendorOrderControllerRetrieveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.VendorOrderResponse,
      VendorOrderControllerRetrieveError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.VendorOrderResponse,
    VendorOrderControllerRetrieveError,
    TData
  >(
    queryKeyFn({
      path: '/v1/vendor-order/{id}',
      operationId: 'vendorOrderControllerRetrieve',
      variables,
    }),
    () =>
      fetchVendorOrderControllerRetrieve({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type VendorOrderControllerUpdatePathParams = {
  id: string;
};

export type VendorOrderControllerUpdateError = Fetcher.ErrorWrapper<undefined>;

export type VendorOrderControllerUpdateVariables = {
  body?: Schemas.VendorOrderUpdate;
  pathParams: VendorOrderControllerUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchVendorOrderControllerUpdate = (
  variables: VendorOrderControllerUpdateVariables
) =>
  apiFetch<
    Schemas.VendorOrderResponse,
    VendorOrderControllerUpdateError,
    Schemas.VendorOrderUpdate,
    {},
    {},
    VendorOrderControllerUpdatePathParams
  >({ url: '/v1/vendor-order/{id}', method: 'patch', ...variables });

export const useVendorOrderControllerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.VendorOrderResponse,
      VendorOrderControllerUpdateError,
      VendorOrderControllerUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.VendorOrderResponse,
    VendorOrderControllerUpdateError,
    VendorOrderControllerUpdateVariables
  >(
    (variables: VendorOrderControllerUpdateVariables) =>
      fetchVendorOrderControllerUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type VendorOrderControllerDeletePathParams = {
  id: string;
};

export type VendorOrderControllerDeleteError = Fetcher.ErrorWrapper<undefined>;

export type VendorOrderControllerDeleteVariables = {
  pathParams: VendorOrderControllerDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchVendorOrderControllerDelete = (
  variables: VendorOrderControllerDeleteVariables
) =>
  apiFetch<
    undefined,
    VendorOrderControllerDeleteError,
    undefined,
    {},
    {},
    VendorOrderControllerDeletePathParams
  >({ url: '/v1/vendor-order/{id}', method: 'delete', ...variables });

export const useVendorOrderControllerDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      VendorOrderControllerDeleteError,
      VendorOrderControllerDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    VendorOrderControllerDeleteError,
    VendorOrderControllerDeleteVariables
  >(
    (variables: VendorOrderControllerDeleteVariables) =>
      fetchVendorOrderControllerDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type QueryOperation =
  | {
      path: '/v1/account-managers';
      operationId: 'accountManagerControllerSearch';
      variables: AccountManagerControllerSearchVariables;
    }
  | {
      path: '/v1/account-managers/{id}';
      operationId: 'accountManagerControllerRetrieve';
      variables: AccountManagerControllerRetrieveVariables;
    }
  | {
      path: '/v1/address';
      operationId: 'addressControllerSearch';
      variables: AddressControllerSearchVariables;
    }
  | {
      path: '/v1/address/{id}';
      operationId: 'addressControllerRetrieve';
      variables: AddressControllerRetrieveVariables;
    }
  | {
      path: '/v1/organization-product';
      operationId: 'organizationProductControllerSearch';
      variables: OrganizationProductControllerSearchVariables;
    }
  | {
      path: '/v1/organization-product/{id}';
      operationId: 'organizationProductControllerRetrieve';
      variables: OrganizationProductControllerRetrieveVariables;
    }
  | {
      path: '/v1/organizations';
      operationId: 'organizationControllerSearch';
      variables: OrganizationControllerSearchVariables;
    }
  | {
      path: '/v1/organizations/{id}';
      operationId: 'organizationControllerRetrieve';
      variables: OrganizationControllerRetrieveVariables;
    }
  | {
      path: '/v1/collaborator-address';
      operationId: 'collaboratorAddressControllerSearch';
      variables: CollaboratorAddressControllerSearchVariables;
    }
  | {
      path: '/v1/collaborator-address/{id}';
      operationId: 'collaboratorAddressControllerRetrieve';
      variables: CollaboratorAddressControllerRetrieveVariables;
    }
  | {
      path: '/v1/collaborator';
      operationId: 'collaboratorControllerSearch';
      variables: CollaboratorControllerSearchVariables;
    }
  | {
      path: '/v1/collaborator/{id}';
      operationId: 'collaboratorControllerRetrieve';
      variables: CollaboratorControllerRetrieveVariables;
    }
  | {
      path: '/v1/product-variant-attribute';
      operationId: 'productVariantAttributeControllerSearch';
      variables: ProductVariantAttributeControllerSearchVariables;
    }
  | {
      path: '/v1/product-variant-attribute/{id}';
      operationId: 'productVariantAttributeControllerRetrieve';
      variables: ProductVariantAttributeControllerRetrieveVariables;
    }
  | {
      path: '/v1/product-category';
      operationId: 'productCategoryControllerSearch';
      variables: ProductCategoryControllerSearchVariables;
    }
  | {
      path: '/v1/product-category/{id}';
      operationId: 'productCategoryControllerRetrieve';
      variables: ProductCategoryControllerRetrieveVariables;
    }
  | {
      path: '/v1/product-variant';
      operationId: 'productVariantControllerSearch';
      variables: ProductVariantControllerSearchVariables;
    }
  | {
      path: '/v1/product-variant/{id}';
      operationId: 'productVariantControllerRetrieve';
      variables: ProductVariantControllerRetrieveVariables;
    }
  | {
      path: '/v1/product';
      operationId: 'productControllerSearch';
      variables: ProductControllerSearchVariables;
    }
  | {
      path: '/v1/product/{id}';
      operationId: 'productControllerRetrieve';
      variables: ProductControllerRetrieveVariables;
    }
  | {
      path: '/v1/vendor-product-variant';
      operationId: 'vendorProductVariantControllerSearch';
      variables: VendorProductVariantControllerSearchVariables;
    }
  | {
      path: '/v1/vendor-product-variant/{id}';
      operationId: 'vendorProductVariantControllerRetrieve';
      variables: VendorProductVariantControllerRetrieveVariables;
    }
  | {
      path: '/v1/credentialed-collaborator';
      operationId: 'credentialedCollaboratorControllerSearch';
      variables: CredentialedCollaboratorControllerSearchVariables;
    }
  | {
      path: '/v1/credentialed-collaborator/{id}';
      operationId: 'credentialedCollaboratorControllerRetrieve';
      variables: CredentialedCollaboratorControllerRetrieveVariables;
    }
  | {
      path: '/v1/document';
      operationId: 'documentControllerSearch';
      variables: DocumentControllerSearchVariables;
    }
  | {
      path: '/v1/document/{id}';
      operationId: 'documentControllerRetrieve';
      variables: DocumentControllerRetrieveVariables;
    }
  | {
      path: '/healthcheck';
      operationId: 'healthcheckControllerCheck';
      variables: HealthcheckControllerCheckVariables;
    }
  | {
      path: '/v1/image';
      operationId: 'imageControllerSearch';
      variables: ImageControllerSearchVariables;
    }
  | {
      path: '/v1/image/{id}';
      operationId: 'imageControllerRetrieve';
      variables: ImageControllerRetrieveVariables;
    }
  | {
      path: '/v1/notification';
      operationId: 'notificationControllerSearch';
      variables: NotificationControllerSearchVariables;
    }
  | {
      path: '/v1/notification/{id}';
      operationId: 'notificationControllerRetrieve';
      variables: NotificationControllerRetrieveVariables;
    }
  | {
      path: '/v1/order-item';
      operationId: 'orderItemControllerSearch';
      variables: OrderItemControllerSearchVariables;
    }
  | {
      path: '/v1/order-item/{id}';
      operationId: 'orderItemControllerRetrieve';
      variables: OrderItemControllerRetrieveVariables;
    }
  | {
      path: '/v1/order';
      operationId: 'orderControllerSearch';
      variables: OrderControllerSearchVariables;
    }
  | {
      path: '/v1/order/{id}';
      operationId: 'orderControllerRetrieve';
      variables: OrderControllerRetrieveVariables;
    }
  | {
      path: '/v1/product-catalog';
      operationId: 'productCatalogControllerGetByOrganization';
      variables: ProductCatalogControllerGetByOrganizationVariables;
    }
  | {
      path: '/v1/product-image';
      operationId: 'productImageControllerSearch';
      variables: ProductImageControllerSearchVariables;
    }
  | {
      path: '/v1/product-image/{id}';
      operationId: 'productImageControllerRetrieve';
      variables: ProductImageControllerRetrieveVariables;
    }
  | {
      path: '/v1/product-details/{productId}';
      operationId: 'productDetailsControllerGetByProductId';
      variables: ProductDetailsControllerGetByProductIdVariables;
    }
  | {
      path: '/v1/product-variant-by-attributes/{productId}';
      operationId: 'productVariantByAttributesControllerGetByAttributes';
      variables: ProductVariantByAttributesControllerGetByAttributesVariables;
    }
  | {
      path: '/v1/recipient-form';
      operationId: 'recipientFormControllerSearch';
      variables: RecipientFormControllerSearchVariables;
    }
  | {
      path: '/v1/recipient-form/{id}';
      operationId: 'recipientFormControllerRetrieve';
      variables: RecipientFormControllerRetrieveVariables;
    }
  | {
      path: '/v1/recipient';
      operationId: 'recipientControllerSearch';
      variables: RecipientControllerSearchVariables;
    }
  | {
      path: '/v1/recipient/{id}';
      operationId: 'recipientControllerRetrieve';
      variables: RecipientControllerRetrieveVariables;
    }
  | {
      path: '/v1/shipment-carrier';
      operationId: 'shipmentCarrierControllerSearch';
      variables: ShipmentCarrierControllerSearchVariables;
    }
  | {
      path: '/v1/shipment-carrier/{id}';
      operationId: 'shipmentCarrierControllerRetrieve';
      variables: ShipmentCarrierControllerRetrieveVariables;
    }
  | {
      path: '/v1/shipment-item';
      operationId: 'shipmentItemControllerSearch';
      variables: ShipmentItemControllerSearchVariables;
    }
  | {
      path: '/v1/shipment-item/{id}';
      operationId: 'shipmentItemControllerRetrieve';
      variables: ShipmentItemControllerRetrieveVariables;
    }
  | {
      path: '/v1/shipment';
      operationId: 'shipmentControllerSearch';
      variables: ShipmentControllerSearchVariables;
    }
  | {
      path: '/v1/shipment/{id}';
      operationId: 'shipmentControllerRetrieve';
      variables: ShipmentControllerRetrieveVariables;
    }
  | {
      path: '/v1/tracking';
      operationId: 'trackingControllerSearch';
      variables: TrackingControllerSearchVariables;
    }
  | {
      path: '/v1/tracking/{id}';
      operationId: 'trackingControllerRetrieve';
      variables: TrackingControllerRetrieveVariables;
    }
  | {
      path: '/v1/vendor-order-invoice';
      operationId: 'vendorOrderInvoiceControllerSearch';
      variables: VendorOrderInvoiceControllerSearchVariables;
    }
  | {
      path: '/v1/vendor-order-invoice/{id}';
      operationId: 'vendorOrderInvoiceControllerRetrieve';
      variables: VendorOrderInvoiceControllerRetrieveVariables;
    }
  | {
      path: '/v1/vendor-order-quote';
      operationId: 'vendorOrderQuoteControllerSearch';
      variables: VendorOrderQuoteControllerSearchVariables;
    }
  | {
      path: '/v1/vendor-order-quote/{id}';
      operationId: 'vendorOrderQuoteControllerRetrieve';
      variables: VendorOrderQuoteControllerRetrieveVariables;
    }
  | {
      path: '/v1/vendor';
      operationId: 'vendorControllerSearch';
      variables: VendorControllerSearchVariables;
    }
  | {
      path: '/v1/vendor/{id}';
      operationId: 'vendorControllerRetrieve';
      variables: VendorControllerRetrieveVariables;
    }
  | {
      path: '/v1/vendor-order';
      operationId: 'vendorOrderControllerSearch';
      variables: VendorOrderControllerSearchVariables;
    }
  | {
      path: '/v1/vendor-order/{id}';
      operationId: 'vendorOrderControllerRetrieve';
      variables: VendorOrderControllerRetrieveVariables;
    };
