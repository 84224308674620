import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import { NavLinkStyles } from 'global-styles';
import { NavLinkListItemSx, LinkTextSx } from 'components/Sidebar/styles';
import { NavLinksProps } from './types';
import { NavLinkItem } from 'components/Sidebar/types';
import SubNavList from './components/SubNavList';

const NavLinks = (props: NavLinksProps) => {
  const theme = useTheme();
  const { propsContainerSx } = props;
  const viewableSidebarNavItems = props.navLinkItems.filter(
    (item) => !item.hide
  );

  const handleOnClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    navLinkItem: NavLinkItem
  ) => {
    if (navLinkItem.isCollapsible) {
      event.preventDefault(); // to not trigger a route change
      if (navLinkItem.key === 'orders') {
        const assetNavLink = viewableSidebarNavItems.find(
          (item) => item.key === 'assets'
        );
        if (assetNavLink && assetNavLink.isCollapsible) {
          assetNavLink.setCollapseExplicit(false);
        }
      } else if (navLinkItem.key === 'assets') {
        const ordersNavLink = viewableSidebarNavItems.find(
          (item) => item.key === 'orders'
        );
        if (ordersNavLink && ordersNavLink.isCollapsible) {
          ordersNavLink.setCollapseExplicit(false);
        }
      }

      navLinkItem.setCollapse();
    }
  };

  return (
    <Stack sx={propsContainerSx}>
      {viewableSidebarNavItems.map((navLinkItem) => {
        const appendSx = navLinkItem?.extraSx ?? {};
        const MainLinkItemSx = {
          ...NavLinkListItemSx(theme, navLinkItem.isActive),
          ...appendSx,
        };

        const RRDLink = navLinkItem.target ? Link : NavLink;

        if (navLinkItem.noLink) {
          return (
            <ListItem key={navLinkItem.key} sx={appendSx}>
              <Box sx={LinkTextSx(theme)}>{navLinkItem.text}</Box>
            </ListItem>
          );
        }

        return (
          <span key={navLinkItem.key}>
            <RRDLink
              to={navLinkItem.to}
              key={navLinkItem.key}
              style={NavLinkStyles}
              target={navLinkItem.target}
              rel={navLinkItem.rel}
              onClick={(event) => handleOnClick(event, navLinkItem)}
            >
              <ListItem key={navLinkItem.key} sx={MainLinkItemSx}>
                {navLinkItem.prefixIcon ? (
                  <ListItemIcon>{navLinkItem.prefixIcon}</ListItemIcon>
                ) : null}
                <ListItemText
                  primary={navLinkItem.text}
                  sx={LinkTextSx(theme)}
                />
                {navLinkItem.suffixIcon ? (
                  <ListItemIcon sx={{ display: 'contents' }}>
                    {navLinkItem.suffixIcon}
                  </ListItemIcon>
                ) : null}
              </ListItem>
            </RRDLink>
            {navLinkItem.isCollapsible ? (
              <SubNavList
                open={navLinkItem.isCollapsed}
                navLinkItem={navLinkItem}
              />
            ) : null}
          </span>
        );
      })}
    </Stack>
  );
};

export default NavLinks;
