import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as PremiumShippingRadioBoxStyles from './styles';
import { PremiumShippingRadioBoxProps } from './types';

const PremiumShippingRadioBox = (props: PremiumShippingRadioBoxProps) => {
  const styles = PremiumShippingRadioBoxStyles;
  const isSelectedClassName =
    props.selectedShippingTypeId === props.shippingTypeId ? 'selected' : '';

  return (
    <Card sx={styles.ShippingCardSx} className={isSelectedClassName}>
      <CardContent sx={styles.ShippingCardContentSx}>
        <Stack sx={styles.ShippingCardOuterStackSx}>
          <Radio sx={styles.RadioButtonSx} value={props.shippingTypeId} />
          <Stack sx={styles.ShippingCardInnerTopStackSx}>
            <Typography sx={styles.ShippingCardOptionTextSx}>
              {props.title}
            </Typography>

            <Box sx={styles.ShippingCardOptionSubTextBoxSx}>
              {props.subTexts.map((subText, index: number) => (
                <Typography sx={styles.getShippingCardOptionSubTextSx(index)}>
                  {subText}
                </Typography>
              ))}
            </Box>
          </Stack>
        </Stack>
        <Stack sx={styles.ShippingCardInnerBottomStackSx}>
          <Typography className="bottom-shipping-card-text">
            {props.bottomText}
          </Typography>
          <Typography className="bottom-shipping-card-sub-text">
            {props.bottomSubText}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default PremiumShippingRadioBox;
