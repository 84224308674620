import React from 'react';
import { Routes as Switch, Route, Navigate } from 'react-router-dom';
import { NavigationContext } from 'context/NavigationContext';
import Procurement from 'pages/Procurement';
import Orders from 'pages/Orders';
import Assets from 'pages/Assets';
import RequestNewItem from 'pages/RequestNewItem';
import AccountSettings from 'pages/AccountSettings';
import { ProtectedRoute } from 'components';
import useRenderAppbarComponents from 'hooks/useRenderAppbarComponents';
import useSetSentryUser from 'hooks/useSetSentryUser';
import Retrieval from 'pages/Retrieval';
import OscarDemo from 'pages/OscarDemo';
import Billing from 'pages/Billing';
import Catalog from 'pages/Catalog';
import Home from 'pages/Home';
import HomeUpdate from 'pages/HomeUpdate';
import { RetrievalStepSubRoutes } from 'global-constants';
import { LocationStepWrapped } from 'pages/Retrieval/components/LocationStep';
import { EmployeeStepWrapped } from 'pages/Retrieval/components/EmployeeStep';
import { ReviewStepWrapped } from 'pages/Retrieval/components/ReviewStep';
import { SubmitStepWrapped } from 'pages/Retrieval/components/SubmitStep';
import Employees from 'pages/Employees';
import AssignFlow from 'pages/AssignFlow';
import OrderManagement from 'pages/OrderManagement';
import { SiteMaintenanceContext } from 'context/SiteMaintenanceContext';
import { usePollForLatestDeployment } from './globals/hooks/polling';
import AssetDetails from 'pages/AssetDetails';
import AssetManagement from 'pages/AssetManagement';
import EmployeeDetails from 'pages/EmployeeDetails';
import OrderDetails from 'pages/OrderDetails';
import { BuyAndHold } from 'pages/BuyAndHold';

const Routes = () => {
  /* REMOVE below to render Routes and NOT Acumatica Maintenance Page */
  // const siteMaintenanceContext = React.useContext(SiteMaintenanceContext);
  // if (siteMaintenanceContext.showMaintenancePage) return null;
  // TODO - this hook should not be in thie file. This file should only be concerned with setting routes
  useRenderAppbarComponents();
  useSetSentryUser();
  usePollForLatestDeployment();

  const navigator = React.useContext(NavigationContext);

  return (
    <Switch>
      <Route
        path={navigator.pathToHome()}
        element={<ProtectedRoute component={HomeUpdate} />}
      />
      <Route
        path={navigator.pathToSendEquipment()}
        element={<ProtectedRoute component={Procurement} />}
      />
      <Route
        path={navigator.pathToBuyAndHold()}
        element={<ProtectedRoute component={BuyAndHold} />}
      />
      <Route
        path={navigator.pathToOrders()}
        element={<ProtectedRoute component={Orders} />}
      />
      <Route
        path={`${navigator.pathToOrders()}/:id`}
        element={<ProtectedRoute component={OrderDetails} />}
      />
      <Route
        path={navigator.pathToAssets()}
        element={<ProtectedRoute component={Assets} />}
      />
      <Route
        path={`${navigator.pathToAssets()}/:id`}
        element={<ProtectedRoute component={AssetDetails} />}
      />
      <Route
        path={navigator.pathToRequestNewItem()}
        element={<ProtectedRoute component={RequestNewItem} />}
      />
      <Route
        path={navigator.pathToAccountSettings()}
        element={<ProtectedRoute component={AccountSettings} />}
      />
      <Route
        path={navigator.pathToEmployees()}
        element={<ProtectedRoute component={Employees} />}
      />
      <Route
        path={`${navigator.pathToEmployees()}/:id`}
        element={<ProtectedRoute component={EmployeeDetails} />}
      />
      <Route
        path={navigator.pathToRetrieval()}
        element={<ProtectedRoute component={Retrieval} />}
      >
        <Route
          path={RetrievalStepSubRoutes.LOCATION}
          element={<LocationStepWrapped />}
        />
        <Route
          path={RetrievalStepSubRoutes.EMPLOYEES}
          element={<EmployeeStepWrapped />}
        />
        <Route
          path={RetrievalStepSubRoutes.REVIEW}
          element={<ReviewStepWrapped />}
        />
        <Route
          path={RetrievalStepSubRoutes.SUBMIT}
          element={<SubmitStepWrapped />}
        />
      </Route>
      <Route
        path={navigator.pathToAssignDeviceFlow()}
        element={
          <ProtectedRoute
            component={(props: any) => <AssignFlow {...props} />}
            hideAppBar
            hideSidebar
            hideGlobalFooter
          />
        }
      />
      <Route
        path={navigator.pathToCatalog()}
        element={<ProtectedRoute component={Catalog} />}
      />
      <Route path={navigator.pathToOscarDemo()} element={<OscarDemo />} />
      <Route path={navigator.pathToBilling()} element={<Billing />} />
      <Route
        path={navigator.pathToOrderManagement()}
        element={
          <ProtectedRoute
            component={(props: any) => <OrderManagement {...props} />}
            hideSidebar
            hideGlobalFooter
          />
        }
      />
      <Route
        path={navigator.pathToAssetManagement()}
        element={
          <ProtectedRoute
            component={(props: any) => <AssetManagement {...props} />}
            hideSidebar
            hideGlobalFooter
          />
        }
      />
      <Route path="*" element={<Navigate to={navigator.pathToHome()} />} />
    </Switch>
  );
};

export default Routes;
