/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'store/types';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { assetManagementSlice } from 'store/slices';
import { useTheme } from '@mui/material/styles';
import * as AMDashActionStyles from './styles';
import GAIconButton from 'components/buttons/google_analytics/GAIconButton';

const AssetManagementActionButtons = () => {
  const theme = useTheme();
  const styles = AMDashActionStyles;
  const dispatch = useDispatch();

  const enableDelete = useSelector<GlobalState, boolean>(
    (state) => state.assetManagement.enableDelete
  );

  const enableEdit = useSelector<GlobalState, boolean>(
    (state) => state.assetManagement.enableEdit
  );

  const handleEditButtonClick = () => {
    dispatch(assetManagementSlice.actions.openEditActionModalReducer());
  };

  const handleDeleteButtonClick = () => {
    dispatch(assetManagementSlice.actions.openDeleteActionModalReducer());
  };

  return (
    <ButtonGroup sx={styles.ButtonGroupSx}>
      <Tooltip title="Edit Device">
        <GAIconButton
          gaContext={{
            navigates_to: 'N/A',
            purpose: 'Edit Device',
            textCopy: 'Edit icon',
          }}
          disabled={!enableEdit}
          aria-disabled={!enableEdit}
          disableRipple
          color="secondary"
          sx={styles.ActionIconButtonSx}
          onClick={handleEditButtonClick}
        >
          <EditOutlinedIcon />
        </GAIconButton>
      </Tooltip>

      <Tooltip title="Delete Device">
        <GAIconButton
          gaContext={{
            navigates_to: 'N/A',
            purpose: 'Delete Device',
            textCopy: 'Delete icon',
          }}
          disabled={!enableDelete}
          aria-disabled={!enableDelete}
          disableRipple
          color="secondary"
          className="delete-button"
          sx={styles.ActionIconButtonSx}
          onClick={handleDeleteButtonClick}
        >
          <DeleteIcon fill={theme.palette.colors.black} />
        </GAIconButton>
      </Tooltip>
    </ButtonGroup>
  );
};

export default AssetManagementActionButtons;
