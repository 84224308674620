import React from 'react';

const ColumbiaFlagSVGIcon = () => {
  return (
    <svg
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Adornment/Flag">
        <rect id="Mask" width="28" height="20" rx="2" fill="white" />
        <mask
          id="mask0_3141_38684"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="28"
          height="20"
        >
          <rect id="Mask_2" width="28" height="20" rx="2" fill="white" />
        </mask>
        <g mask="url(#mask0_3141_38684)">
          <g id="Rectangle 2" filter="url(#filter0_d_3141_38684)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 14.6654H28V9.33203H0V14.6654Z"
              fill="#0748AE"
            />
          </g>
          <g id="Rectangle 2_2" filter="url(#filter1_d_3141_38684)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 20.0013H28V14.668H0V20.0013Z"
              fill="#DE2035"
            />
          </g>
          <path
            id="Rectangle 2_3"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 9.33333H28V0H0V9.33333Z"
            fill="#FFD935"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_3141_38684"
          x="0"
          y="9.33203"
          width="28"
          height="5.33203"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3141_38684"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3141_38684"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_3141_38684"
          x="0"
          y="14.668"
          width="28"
          height="5.33203"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3141_38684"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3141_38684"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ColumbiaFlagSVGIcon;
