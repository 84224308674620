import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import { NavLinkStyles } from 'global-styles';
import { getMobileNavLinks, MobileNavLinkItem } from './utils';
import { MobileMenuProps } from './types';

const MobileMenu = (props: MobileMenuProps) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const getMobileKey = (
    navLinkItem: MobileNavLinkItem,
    mappedIndex: number
  ): string => {
    const { key, to, text } = navLinkItem;
    return `${mappedIndex}--${to}--${key}--${text}`;
  };

  const mobileNavLinks = getMobileNavLinks(props.navigator);

  return (
    <Box display={{ xs: 'flex', lg: 'none' }}>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: 'block', lg: 'none' },
        }}
      >
        {mobileNavLinks.map((mobileNavLink, index) =>
          mobileNavLink.show ? (
            <NavLink
              to={mobileNavLink.to}
              key={getMobileKey(mobileNavLink, index)}
              style={NavLinkStyles}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center" color="secondary">
                  {mobileNavLink.text}
                </Typography>
              </MenuItem>
            </NavLink>
          ) : null
        )}
      </Menu>
    </Box>
  );
};

export default MobileMenu;
