import { getGridStringOperators } from '@mui/x-data-grid-pro';

export const DEVICES_HEADER_TOOLTIP_COPY =
  'We only display devices allocated to the user and in their possession. If you do not see a device below, please add the device to the users profile in the following step.';

export const filterOperators = getGridStringOperators().filter(
  (operator) => operator.value === 'contains'
);

export const INVALID_DATE = 'Invalid date';

export const NON_APPLICABLE = 'N/A';

export enum EmployeeFilterColumns {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
}
