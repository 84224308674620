/* eslint-disable sort-exports/sort-exports */
import { useSelector } from 'react-redux';
import { GlobalState } from 'store/types';
import { getMatchingOptions } from 'store/slices';
import Box from '@mui/material/Box';
import AMDashComplexTypeAhead from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/components/AMDashComplexTypeAhead';
import { ComplexTypeAheadContainerProps } from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/types';
import { ASM_DASH_DRAWER_INPUT_BOX_CLASS } from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/constants';

const ComplexTypeAheadContainer = (props: ComplexTypeAheadContainerProps) => {
  const { availableOptionsName, selectedOptionsName } = getMatchingOptions(
    props.filterKey
  );

  if (!availableOptionsName || !selectedOptionsName) return null;

  const availableTypeAheadOptions = useSelector<GlobalState, string[][]>(
    (state) =>
      state.assetManagement.filters[
        availableOptionsName as keyof typeof state.assetManagement.filters
      ]
  );

  const selectedTypeAheadOptions = useSelector<GlobalState, string[][]>(
    (state) =>
      state.assetManagement.filters[
        selectedOptionsName as keyof typeof state.assetManagement.filters
      ]
  );

  return (
    <Box className={ASM_DASH_DRAWER_INPUT_BOX_CLASS}>
      <AMDashComplexTypeAhead
        {...props}
        availableOptions={availableTypeAheadOptions}
        selectedOptions={selectedTypeAheadOptions}
      />
    </Box>
  );
};

export default ComplexTypeAheadContainer;
