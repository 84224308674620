import React from 'react';
import { AppDispatch } from 'store';
import { useDispatch, useSelector } from 'react-redux';

import { createCSVFile, downloadFile } from 'shared/utils';
import {
  getOrdersCSV,
  getOrdersCSVDirect,
} from 'store/slices/orders/csv/actions';
import { setOrdersCSVDownloadURL, resetState } from 'store/slices/orders/csv';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import useGetGlobalPurchaser from 'hooks/useGetGlobalPurchaser';
import { notificationSlice } from 'store/slices';
import {
  selectOrdersCSVLoadingState,
  selectOrdersCSVURL,
  selectOrdersCSVData,
  selectOrdersCSVErrorMessage,
} from 'store/slices/orders/csv/selectors';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import { FeatureFlagNames } from 'enums';

export const useHandleDownloadAllDataClick = () => {
  const organization = useGetUserOrganization();
  const collaborator = useGetGlobalPurchaser();
  const dispatch = useDispatch<AppDispatch>();
  const { isFlagOn: getIsCSVWithDirectDownload } = useFeatureFlagService(
    FeatureFlagNames.RELEASE_CSV_DOWNLOAD_LINK,
    {
      debugFlags: true,
    }
  );
  const isCSVWithDirectDownload = getIsCSVWithDirectDownload();
  return React.useCallback(async () => {
    if (organization && collaborator) {
      if (isCSVWithDirectDownload) {
        dispatch(
          getOrdersCSVDirect({
            userId: collaborator.id,
            orgId: organization.id,
          })
        );
        return;
      }
      dispatch(
        getOrdersCSV({ userId: collaborator.id, orgId: organization.id })
      );
    }
  }, [organization, collaborator]);
};

export const useHandleCSVDownloadError = () => {
  const loadingState = useSelector(selectOrdersCSVLoadingState);
  const errorMessage = useSelector(selectOrdersCSVErrorMessage);
  const dispatch = useDispatch();
  return React.useEffect(() => {
    if (loadingState === 'rejected') {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: errorMessage,
        })
      );
    }
  }, [loadingState, errorMessage]);
};

export const useFileDownload = () => {
  const { isFlagOn: getIsCSVWithDirectDownload } = useFeatureFlagService(
    FeatureFlagNames.RELEASE_CSV_DOWNLOAD_LINK,
    {
      debugFlags: true,
    }
  );
  const url = useSelector(selectOrdersCSVURL);
  const data = useSelector(selectOrdersCSVData);
  const dispatch = useDispatch();
  const isCSVWithDirectDownload = getIsCSVWithDirectDownload();
  return React.useEffect(() => {
    if (data && isCSVWithDirectDownload) {
      createCSVFile(data, 'all-orders', 'csv');
    } else if (url && !isCSVWithDirectDownload) {
      downloadFile(url, 'all-orders', 'csv');
      dispatch(setOrdersCSVDownloadURL(''));
    }
    return () => {
      dispatch(resetState());
    };
  }, [url, data]);
};
