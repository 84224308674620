import { gql } from '@apollo/client';

export const UPDATE_ORDER = gql`
  mutation updateOrder(
    $id: ID!
    $recipientId: ID
    $dropRecipientId: ID
    $depotId: ID
  ) {
    updateOrder(
      orderUpdateInput: {
        id: $id
        recipientId: $recipientId
        dropRecipientId: $dropRecipientId
        depotId: $depotId
      }
    ) {
      id
      recipientId
      dropRecipientId
      depotId
    }
  }
`;
