import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { NavigationContext } from 'context/NavigationContext';
import { NavLinkItem } from 'components/Sidebar/types';

export const useGetPreDividerNavLinks = () => {
  const { pathname, search } = useLocation();
  const activePathname = `${pathname}${search}`;
  const isActivePathnameOrders = activePathname.includes('/orders');
  const isActivePathnameAssets = activePathname.includes('/assets');

  const [openOrders, setOpenOrders] = React.useState(
    () => isActivePathnameOrders
  );
  const [openAssets, setOpenAssets] = React.useState(
    () => isActivePathnameAssets
  );

  const theme = useTheme();
  const whiteCollapsibleArrowColor = theme.palette.colors.white;

  const navigator = React.useContext(NavigationContext);

  const navLinks: NavLinkItem[] = [
    {
      key: 'home',
      to: navigator.pathToHome(),
      text: 'Home',
      isActive: activePathname === navigator.pathToHome(),
      isCollapsible: false,
      subNavLinkItems: [],
    },
    {
      key: 'employees',
      to: navigator.pathToEmployees(),
      text: 'Employees',
      isActive: activePathname === navigator.pathToEmployees(),
      isCollapsible: false,
      subNavLinkItems: [],
    },

    {
      key: 'orders',
      to: '',
      text: 'Orders',
      isActive: false,
      isCollapsible: true,
      isCollapsed: openOrders,
      setCollapse: () => setOpenOrders(!openOrders),
      setCollapseExplicit: (collapse) => setOpenOrders(collapse),
      suffixIcon: openOrders ? (
        <KeyboardArrowDownIcon htmlColor={whiteCollapsibleArrowColor} />
      ) : (
        <KeyboardArrowRightIcon htmlColor={whiteCollapsibleArrowColor} />
      ),
      subNavLinkItems: [
        {
          key: 'all-orders',
          to: navigator.pathToAllOrders(),
          text: 'All orders',
          isActive: activePathname === navigator.pathToAllOrders(),
        },
        {
          key: 'retrievals',
          to: navigator.pathToAllRetrievals(),
          text: 'Retrievals',
          isActive: activePathname === navigator.pathToAllRetrievals(),
        },
        {
          key: 'follow-up-required',
          to: navigator.pathToFollowUpRequired(),
          text: 'Follow up required',
          isActive: activePathname === navigator.pathToFollowUpRequired(),
        },
      ],
    },
    {
      key: 'assets',
      to: '',
      text: 'Assets',
      isActive: false,
      isCollapsible: true,
      isCollapsed: openAssets,
      setCollapse: () => setOpenAssets(!openAssets),
      setCollapseExplicit: (collapse) => setOpenAssets(collapse),
      suffixIcon: openAssets ? (
        <KeyboardArrowDownIcon htmlColor={whiteCollapsibleArrowColor} />
      ) : (
        <KeyboardArrowRightIcon htmlColor={whiteCollapsibleArrowColor} />
      ),
      subNavLinkItems: [
        {
          key: 'devices',
          to: navigator.pathToAssetsDevices(),
          text: 'Devices',
          isActive: activePathname === navigator.pathToAssetsDevices(),
        },
        {
          key: 'merchandise',
          to: navigator.pathToAssetsMerchandise(),
          text: 'Merchandise',
          isActive: activePathname === navigator.pathToAssetsMerchandise(),
        },
      ],
    },
    {
      key: 'catalog',
      to: navigator.pathToCatalog(),
      text: 'Your catalog',
      isActive: activePathname === navigator.pathToCatalog(),
      isCollapsible: false,
      subNavLinkItems: [],
    },
  ];

  return navLinks;
};
