import React from 'react';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { ColumnContentsProps } from './types';

import {
  ListItemSx,
  ListItemLeftSx,
  ListItemRightSx,
  ContentsContainerSx,
} from './styles';

function ColumnContents<T>(props: ColumnContentsProps<T>) {
  const {
    columns,
    searchInput,
    onHandleDragEnd: handleDragEnd,
    onColumnToggle: handleOnColumnToggle,
  } = props;
  return (
    <Stack sx={ContentsContainerSx}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable" isDropDisabled={!!searchInput}>
          {(dropProvided) => (
            <List {...dropProvided.droppableProps} ref={dropProvided.innerRef}>
              {columns.map((c, index) => {
                if (c.hidden) return null;
                return (
                  <Draggable key={c.id} draggableId={c.id} index={index}>
                    {(provided) => (
                      <ListItem
                        sx={ListItemSx}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Stack sx={ListItemLeftSx}>
                          <Switch
                            color="secondary"
                            checked={c.active}
                            onChange={handleOnColumnToggle(c)}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                          {c.displayName}
                        </Stack>
                        <Stack sx={ListItemRightSx}>
                          <Typography color="colors.gray94">
                            <DragIndicatorIcon />
                          </Typography>
                        </Stack>
                      </ListItem>
                    )}
                  </Draggable>
                );
              })}
              {dropProvided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </Stack>
  );
}

export default ColumnContents;
