import React from 'react';
import { useTheme } from '@mui/material';
import {
  GridColDef,
  GridRowSelectionModel,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as DepotTableColumnsStyles from './styles';

export const useGetDepotTableColumns = (
  rowSelectionModel: GridRowSelectionModel,
  rowHoveringModel: string
): GridColDef[] => {
  const theme = useTheme();
  const styles = DepotTableColumnsStyles;

  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderHeader: () => <></>,
      filterable: false,
      renderCell(params) {
        const isRowSelected = rowSelectionModel.length
          ? params.id === rowSelectionModel[0]
          : false;
        const disabled = !params.row.selectable;
        const isHovered = params.id.toString() === rowHoveringModel;
        return (
          <Radio
            id={`depot_id_${params.id}_radio_button`}
            checked={isRowSelected}
            disabled={disabled}
          />
        );
      },
    },
    {
      field: 'depotCountry',
      sortable: false,
      headerName: 'Depot Location',
      flex: 1,
      valueGetter: (params) => `${params.row?.displayName ?? ''}`,
      renderCell: (params) => {
        const Flag = params.row.flag as () => JSX.Element;
        const disabled = !params.row.selectable;
        const isHovered = params.id.toString() === rowHoveringModel;
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={styles.getDepotLocationColumnSx(isHovered, disabled)}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              spacing="16px"
            >
              {Flag ? <Flag /> : null}
              <Typography variant="body2" className="depot-name-text">
                {params.value}
              </Typography>
            </Stack>
            {disabled ? (
              <Typography
                variant="body2"
                className="not-available-depot-tooltip"
              >
                Contact{' '}
                <Box
                  component="a"
                  hidden={!isHovered}
                  href="mailto:hello@allwhere.co"
                  sx={styles.MailToDepotBoxSx}
                >
                  hello@allwhere.co
                </Box>
                for Support!
              </Typography>
            ) : (
              <></>
            )}
          </Stack>
        );
      },
    },
  ];
};
