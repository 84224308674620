/* eslint-disable security/detect-object-injection */
import React from 'react';
import {
  DEFAULT_INSURANCE_OPTION,
  RETRIEVAL_INSURANCE_OPTIONS,
} from 'pages/Procurement/components/ProductCatalog/constants';
import { MenuItem, Select, Typography, useTheme } from '@mui/material';
import { RetrievalInsuranceProps } from './types';
import FieldInputWrapper from '../FieldInputWrapper';

const RetrievalInsuranceSelection = (props: RetrievalInsuranceProps) => {
  const theme = useTheme();

  return (
    <>
      <FieldInputWrapper>
        <Typography variant="body2" color={theme.palette.colors.grayWN}>
          Insurance Coverage Amount
        </Typography>
        <Select
          variant="filled"
          sx={{ height: '34px' }}
          defaultValue={DEFAULT_INSURANCE_OPTION}
          {...props.register('insuranceAmount', {
            required: false,
          })}
        >
          {RETRIEVAL_INSURANCE_OPTIONS.map((insuranceOption) => (
            <MenuItem key={insuranceOption} value={insuranceOption}>
              {insuranceOption}
            </MenuItem>
          ))}
        </Select>
      </FieldInputWrapper>
    </>
  );
};

export default RetrievalInsuranceSelection;
