export const BannerContentSx = {
  display: 'flex',
  flexDirection: 'row',
  color: 'white',
};

export const BannerIconSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  mr: '10px',
};

export const BannerButtonSx = {
  backgroundColor: 'white',
  borderRadius: '30px',
  color: 'black',
  height: '36px',
  width: '116px',
  fontSize: '14px',
  fontWeight: '600',
  display: 'display',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0',
  top: '-14px',
  ml: '10px',
  ':hover': {
    backgroundColor: 'lightgray',
  },
};

export const BannerInnerContentSx = {
  display: 'flex',
  flexDirection: 'row',
};
