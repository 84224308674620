import { FormModeContext } from 'global-constants';
import { EMPLOYEE_ADDRESS_BASE_IDS } from './constants';

export const getEmployeeAddressIds = (
  addressType: 'work' | 'home',
  formContext: FormModeContext.ADD | FormModeContext.EDIT,
  addressId: string = ''
) => {
  const prefix = `${formContext}-${addressType}-`;
  const postFix = addressId ? `-${addressId}` : '';

  return Object.entries(EMPLOYEE_ADDRESS_BASE_IDS).reduce(
    (acc, [key, baseId]) => {
      acc[key] = `${prefix}${baseId}${postFix}`;
      return acc;
    },
    {} as Record<string, string>
  );
};
