import React from 'react';
import { useTheme } from '@mui/material';
import { SVGProps } from './types';

const ClosedBoxSVGIcon = (props: SVGProps) => {
  const theme = useTheme();
  const originalSize = 54;
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;
  const colors = props?.color ?? theme.palette.colors.woodSmoke;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={width}
        height={height}
        rx={width / 2}
        fill="transparent"
        fill-opacity="0.04"
      />
      <path
        d="M27.3793 13.1873L27.3793 13.1873C27.1363 13.0876 26.8639 13.0876 26.6209 13.1873L26.6209 13.1873L14.469 18.1795C14.286 18.2547 14.1294 18.3826 14.0193 18.5469C13.9091 18.7113 13.8503 18.9046 13.8503 19.1025V34.8975C13.8503 35.0954 13.9091 35.2888 14.0193 35.4531L14.1334 35.3766L14.0193 35.4531C14.1294 35.6175 14.286 35.7454 14.469 35.8206L26.6208 40.8127C26.8614 40.9117 27.131 40.9125 27.372 40.8152L27.3748 40.8142L27.3782 40.8128L27.3859 40.8098L27.5092 40.7593L28.7326 40.2568L39.5313 35.8206C39.7143 35.7454 39.8708 35.6175 39.981 35.4531C40.0911 35.2888 40.1499 35.0954 40.1499 34.8975V19.1025C40.1499 18.9046 40.0911 18.7113 39.9809 18.5469C39.8708 18.3826 39.7143 18.2547 39.5313 18.1795L39.479 18.3067L39.5313 18.1795L27.3793 13.1873ZM27.4349 40.7683C27.4385 40.7621 27.4428 40.7529 27.447 40.7423C27.4456 40.7493 27.4438 40.7551 27.4417 40.7595C27.4397 40.7623 27.4374 40.7653 27.4349 40.7683ZM27.3913 40.8065L27.3957 40.8041C27.3927 40.8058 27.3879 40.8084 27.3913 40.8065ZM27.2022 40.7257C27.2038 40.7254 27.2053 40.725 27.2068 40.7246C27.2079 40.7296 27.2091 40.7346 27.2104 40.7395C27.2075 40.7349 27.2048 40.7303 27.2022 40.7257ZM27.2741 40.8081C27.2901 40.8147 27.3057 40.8194 27.3165 40.8217C27.3085 40.8211 27.3 40.8202 27.2932 40.8189C27.2876 40.8167 27.2811 40.813 27.2741 40.8081ZM15.846 20.6636L19.5193 22.1726L19.5193 22.1727L19.5243 22.1745L20.3797 22.4877L21.2353 22.8774L21.2353 22.8775L21.2401 22.8795L26.0022 24.8358V38.4009L15.846 34.2287V20.6636ZM36.6141 19.1387L27.0001 23.0882L22.8752 21.3937L32.2006 17.3256L36.6141 19.1387ZM27.0001 15.1891L29.6301 16.2695L20.3046 20.3376L17.3861 19.1386L27.0001 15.1891ZM27.998 38.4009V24.8358L38.1542 20.6636V34.2287L27.998 38.4009Z"
        fill={colors}
        stroke={colors}
        stroke-width="0.275001"
      />
    </svg>
  );
};

export default ClosedBoxSVGIcon;
