import React from 'react';
import ComplexFilterBank from 'components/complex_filter_bank/ComplexFilterBank';
import { useGetFilterBankConfig } from './hooks';

const OrdersComplexFilterBank = () => {
  const filterBankConfig = useGetFilterBankConfig('orders');

  if (!filterBankConfig) {
    return <></>;
  }

  return <ComplexFilterBank {...filterBankConfig} />;
};

export default OrdersComplexFilterBank;
