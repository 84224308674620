import React from 'react';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { Backdrop, CircularProgress, Container } from '@mui/material';
import styles from './GlobalLoadingIndicator.module.css';
import { useAuth0 } from '@auth0/auth0-react';

const GlobalLoadingIndicator = () => {
  const { isLoading } = useAuth0();
  // We can add query keys to the method to tell RQ
  // which queries/mutations we want to show this loading
  // indicator for
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  const hasLoadingQueries = isFetching > 0 || isMutating > 0 || isLoading;

  return (
    <Backdrop open={hasLoadingQueries} sx={{ zIndex: 999 }}>
      <Container>
        <div className={styles['loading-container']}>
          <CircularProgress size={125} />
        </div>
      </Container>
    </Backdrop>
  );
};

export default GlobalLoadingIndicator;
