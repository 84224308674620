import React from 'react';
import { GridCallbackDetails, GridPaginationModel } from '@mui/x-data-grid-pro';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
  DEFAULT_PAGINATION_MODEL,
  GENERIC_PAGE_SIZE_OPTIONS,
} from 'global-constants';
import { Button } from 'components';
import DataGrid from 'components/DataGrid';
import {
  ENABLE_HORIZONTAL_SCROLLING_CLASS_NAME,
  ENABLE_LARGE_HEADER_HEIGHT_CLASS_NAME,
  PaginationChangeReason,
} from 'components/DataGrid/constants';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { InventoryReceipt } from 'types';
import AddInventoryReceiptModal from './components/AddInventoryReceiptModal';
import DeleteInventoryReceiptModal from './components/DeleteInventoryReceiptModal';
import { useGetInventoryReceiptsColumns } from './hooks/useGetInventoryReceiptsColumns';
import * as styles from './styles';
import { InventoryReceiptsProps } from './types';
import { SEARCH_INVENTORY_RECEIPTS } from './queries';
import { InventoryReceiptActionModals } from './enums';
import EditInventoryReceiptModal from './components/EditInventoryReceiptModal';

const InventoryReceipts = (props: InventoryReceiptsProps) => {
  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>(DEFAULT_PAGINATION_MODEL);

  const showDeleteInventoryReceiptModal =
    props.inventoryReceiptFormService.inventoryReceiptActionModal ===
    InventoryReceiptActionModals.DELETE_INVENTORY_RECEIPT;

  const showAddInventoryReceiptModal =
    props.inventoryReceiptFormService.inventoryReceiptActionModal ===
    InventoryReceiptActionModals.ADD_INVENTORY_RECEIPT;

  const showEditInventoryReceiptModal =
    props.inventoryReceiptFormService.inventoryReceiptActionModal ===
    InventoryReceiptActionModals.EDIT_INVENTORY_RECEIPT;

  const showInventoryActionModal = (
    modal: InventoryReceiptActionModals,
    inventoryReceipt?: InventoryReceipt
  ) => {
    props.inventoryReceiptFormService.setInventoryReceiptActionModal(modal);
    props.inventoryReceiptFormService.setSelectedInventoryReceipt(
      inventoryReceipt
    );
  };

  const handleCloseInventoryReceiptActionModal = () => {
    props.inventoryReceiptFormService.setInventoryReceiptActionModal(
      InventoryReceiptActionModals.NONE
    );
    props.inventoryReceiptFormService.setSelectedInventoryReceipt(undefined);
  };

  const { data, loading } = useSafeQuery(SEARCH_INVENTORY_RECEIPTS, {
    variables: {
      assetId: props.device.id,
      limit: paginationModel.pageSize,
      offset: paginationModel.page * paginationModel.pageSize,
      orderDesc: 'createdAt',
    },
  });

  const rows = data?.searchInventoryReceipts?.inventoryReceipts || [];
  const count = data?.searchInventoryReceipts?.count || 0;
  const columns = useGetInventoryReceiptsColumns({
    setInventoryReceiptAction: showInventoryActionModal,
  });

  const handleOnPaginationModelChange = (
    model: GridPaginationModel,
    details: GridCallbackDetails
  ) => {
    if (details?.reason === PaginationChangeReason.SET_PAGINATION_MODEL) {
      setPaginationModel(model);
    }
  };

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        width="100%"
        spacing={2}
        sx={{ ml: '-8px' }}
      >
        <Grid item xs={6}>
          <Typography variant="subtitle2">Inventory asset records</Typography>
        </Grid>
        <Grid item xs={6} textAlign="end">
          <Button
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Add',
              purpose: 'Adds Inventory Asset Record',
            }}
            size="small"
            color="secondary"
            variant="outlined"
            startIcon={<AddIcon />}
            sx={styles.AddAssetButtonSx}
            onClick={() =>
              showInventoryActionModal(
                InventoryReceiptActionModals.ADD_INVENTORY_RECEIPT
              )
            }
          >
            <Typography sx={styles.AddAssetButtonTextSx}>Add</Typography>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            rows={rows}
            columns={columns}
            loading={loading}
            rowCount={count}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            pageSizeOptions={GENERIC_PAGE_SIZE_OPTIONS}
            onPaginationModelChange={handleOnPaginationModelChange}
            disableRowSelectionOnClick
            hideFooterSelectedRowCount
            slotProps={{
              panel: {
                placement: 'bottom-end',
              },
            }}
            classes={{
              virtualScroller: ENABLE_HORIZONTAL_SCROLLING_CLASS_NAME,
              columnHeaders: ENABLE_LARGE_HEADER_HEIGHT_CLASS_NAME,
            }}
          />
        </Grid>
      </Grid>
      <AddInventoryReceiptModal
        assetId={props.device.id}
        open={showAddInventoryReceiptModal}
        handleClose={handleCloseInventoryReceiptActionModal}
        inventoryReceiptFormService={props.inventoryReceiptFormService}
        onSubmit={props.onSubmit}
      />
      <DeleteInventoryReceiptModal
        inventoryReceiptId={
          props.inventoryReceiptFormService.selectedInventoryReceipt?.id || ''
        }
        open={showDeleteInventoryReceiptModal}
        handleClose={handleCloseInventoryReceiptActionModal}
      />
      <EditInventoryReceiptModal
        open={showEditInventoryReceiptModal}
        handleClose={props.handleCancel}
        inventoryReceipt={
          props.inventoryReceiptFormService
            .selectedInventoryReceipt as InventoryReceipt
        }
        inventoryReceiptFormService={props.inventoryReceiptFormService}
        onSubmit={props.onSubmit}
      />
    </>
  );
};

export default InventoryReceipts;
