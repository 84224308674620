import { gql } from '@apollo/client';

export const GET_ORDER_ITEMS_BY_ORDER_ID = gql`
  query getAllOrderItems($orderId: String!) {
    getAllOrderItems(orderId: $orderId) {
      id
      orderId
      orderItemNumber
      assetId
      insuranceRequested
      insuranceCharged
      serialNumber
      quantity
      processDevice
      mdmPin
      knownIssues
      inboundOrderItemId
      protectionPlan
      productVariant {
        product {
          name
        }
        sku
        productVariantAttributes {
          value
        }
      }
      asset {
        assetNumber
        make
        model
        assetType {
          id
          name
        }
      }
    }
  }
`;
