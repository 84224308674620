import { combineSlices } from '@reduxjs/toolkit';
import { ordersCSVSlice } from './csv';
import { orderFilterSlice } from './filter';
import { orderSearchSlice } from './search';

export const ordersSlice = combineSlices({
  csv: ordersCSVSlice.reducer,
  filter: orderFilterSlice.reducer,
  search: orderSearchSlice.reducer,
});
