/* eslint-disable prettier/prettier */
import { gql } from '@apollo/client';

export const GET_ALL_IN_TRANSIT_ORDERS_TOTAL = gql`
  query searchOrdersTransitData($organizationId: String) {
    searchOrders(
      searchOrdersInput: {
        organizationId: $organizationId
        status: [SHIPPED, IN_RETURN]
      }
    ) {
      count
    }
  }
`;

export const GET_ALL_IN_PROGRESS_ORDERS_TOTAL = gql`
  query searchOrders($organizationId: String) {
    searchOrders(
      searchOrdersInput: {
        organizationId: $organizationId
        status: [
          PENDING_RECYCLE
          IN_FULFILLMENT
          PROCESSING_DEVICE
          PENDING_APPROVAL
          PENDING_REPAIR
          PENDING_CERTIFICATE
          PENDING_QUOTE_APPROVAL
          QUOTE_IN_PROGRESS
        ]
      }
    ) {
      count
    }
  }
`;

export const GET_ALL_NEEDS_ATTENTION_ORDERS_TOTAL = gql`
  query searchOrders($organizationId: String) {
    searchOrders(
      searchOrdersInput: {
        organizationId: $organizationId
        status: [PENDING_RETURN, PENDING_RECIPIENT_INFORMATION]
      }
    ) {
      count
    }
  }
`;
