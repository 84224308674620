/* eslint-disable sort-exports/sort-exports */
import { useSelector } from 'react-redux';
import { GlobalState } from 'store/types';
import { getMatchingOptions } from 'store/slices';
import Box from '@mui/material/Box';
import AMDashComplexDropDown from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/components/AMDashComplexDropDown';
import { AMDComplexDropDownContainerProps } from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/types';
import { ASM_DASH_DRAWER_INPUT_BOX_CLASS } from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/constants';

const AssetFieldComplexDropDown = (props: AMDComplexDropDownContainerProps) => {
  const { availableOptionsName, selectedOptionsName } = getMatchingOptions(
    props.filterKey
  );

  if (!availableOptionsName || !selectedOptionsName) return null;

  const availableDropdownOptions = useSelector<GlobalState, string[][]>(
    (state) =>
      state.assetManagement.filters[
        availableOptionsName as keyof typeof state.assetManagement.filters
      ]
  );

  const selectedDropdownOptions = useSelector<GlobalState, string[][]>(
    (state) =>
      state.assetManagement.filters[
        selectedOptionsName as keyof typeof state.assetManagement.filters
      ]
  );

  return (
    <Box className={ASM_DASH_DRAWER_INPUT_BOX_CLASS}>
      <AMDashComplexDropDown
        {...props}
        availableOptions={availableDropdownOptions}
        selectedOptions={selectedDropdownOptions}
      />
    </Box>
  );
};

export default AssetFieldComplexDropDown;
