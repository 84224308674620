/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import allwhereBoxRunningAltColors from 'assets/allwhereBoxRunningAltColors.png';
import timeSavedSlice from 'assets/timeSavedPieSlice.png';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { GET_ALL_COMPLETE_ORDERS_TOTAL } from './queries';
import * as TimeSavingsStyles from './styles';
import { TIME_SAVINGS_INFO_TEXT } from './constants';

const TimeSavings = () => {
  const styles = TimeSavingsStyles;
  const userOrganization = useGetUserOrganization();
  const [totalHoursSaved, setTotalHoursSaved] = React.useState('');

  useSafeQuery(GET_ALL_COMPLETE_ORDERS_TOTAL, {
    variables: {
      organizationId: userOrganization?.id,
    },
    skip: !userOrganization?.id,
    nextFetchPolicy: 'cache-and-network',
    onCompleted(data) {
      if (typeof (data?.searchOrders?.count ?? '') !== 'number') return;
      const orderCount = data?.searchOrders?.count ?? 0;
      const totalHoursSaved = orderCount * 5;
      setTotalHoursSaved(() => totalHoursSaved.toLocaleString());
    },
  });

  return (
    <Container sx={styles.HomeTimeSavingsBoxSx}>
      <Stack flexDirection="row" justifyContent="space-between">
        <Stack
          flexDirection="row"
          sx={styles.HomeTimeSavingsInfoGraphicSx}
          justifyContent="center"
          alignItems="center"
          gap="16px"
        >
          <Box>
            <img
              src={timeSavedSlice}
              alt="time-saved-on-orders-pie-graph-slice"
              width="80px"
            />
          </Box>
          <Stack flexDirection="column" justifyContent="space-between">
            <Typography className="time-savings-title">
              Total time saved
              <span style={styles.InfoSpanStyle}>
                <Tooltip
                  title={TIME_SAVINGS_INFO_TEXT}
                  placement="bottom"
                  arrow
                >
                  <InfoOutlinedIcon sx={styles.InfoIconSx} fontSize="small" />
                </Tooltip>
              </span>
            </Typography>
            {totalHoursSaved ? (
              <Typography className="time-savings-amount">
                {`${totalHoursSaved}h`}
              </Typography>
            ) : (
              <></>
            )}
            {!totalHoursSaved ? (
              <Skeleton
                variant="circular"
                sx={styles.HomeTimeSavingsSkeletonSx}
              />
            ) : (
              <></>
            )}
          </Stack>
        </Stack>
        <Box>
          <img
            src={allwhereBoxRunningAltColors}
            alt="allwhere=box-running"
            width="146px"
          />
        </Box>
      </Stack>
    </Container>
  );
};

export default TimeSavings;
