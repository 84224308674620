export const getAssetMgmtFilterFields = (
  filterField: string,
  searchQueryField?: string
) => {
  const filterAllCaps = filterField.toUpperCase();
  const filterLowCase = filterField.toLowerCase();
  const filterFieldSentenceCase = filterAllCaps[0] + filterLowCase.slice(1);

  return {
    placeholder: filterFieldSentenceCase,
    label: filterFieldSentenceCase,
    stackId: `asset-filter-${filterLowCase}-stack-container`,
    labelId: `asset-filter-${filterLowCase}-input-label`,
    inputId: `asset-filter-${filterLowCase}-input`,
    searchAssetsQueryField: searchQueryField ?? filterLowCase,
  };
};
