import { CartItem } from 'pages/Procurement/hooks/useProcurementService/types';
import { Collaborator } from 'types';

export const getTotalNumberOfItemsInTheCart = (
  cart: CartItem[],
  selectedCollaborators: Collaborator[]
): number => {
  const numberOfItems = cart.reduce(
    (previousValue, currentValue) => previousValue + currentValue.quantity,
    0
  );

  // For now, in one procurement workflow, every item selected will apply to all selected collaborators
  // Thereform we need to multiply the total items the user wishes to purchase with the number
  // of selected collaborators to correctly reflect the number of items in the cart
  return numberOfItems * selectedCollaborators.length;
};
