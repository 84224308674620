import { DETAILS_TABLE_HEIGHT } from '../../UploadErrorDetails/styles';

export const UploadErrorsDataGridSx = {
  position: 'relative',
  top: '50px',
  width: '100%',
  mb: '50px',
  height: `calc(${DETAILS_TABLE_HEIGHT} - 50px)`,

  '.MuiDataGrid-virtualScroller': {
    overflowX: 'auto !important',
  },

  '.MuiDataGrid-row': {
    width: '100%',
  },

  '.MuiDataGrid-cell:nth-of-type(3)': {
    maxWidth: '100% !important',
    width: '100%',
  },

  '.MuiDataGrid-cell:not(.MuiDataGrid-cell--withRenderer)': {
    display: 'none',
  },
};
