import { Navigator } from 'context/NavigationContext';
import { RetrievalStepSubRoutes } from 'global-constants';

export type MobileNavLinkItem = {
  key: string;
  to: string;
  text: string;
  show: boolean;
};
export const getMobileNavLinks = (
  navigator: Navigator
): MobileNavLinkItem[] => {
  return [
    {
      key: 'home',
      to: navigator.pathToHome(),
      text: 'Home',
      show: true,
    },
    {
      key: 'employees',
      to: navigator.pathToEmployees(),
      text: 'Employees',
      show: true,
    },
    {
      key: 'orders',
      to: navigator.pathToAllOrders(),
      text: 'Orders',
      show: true,
    },
    {
      key: 'devices',
      to: navigator.pathToAssetsDevices(),
      text: 'Devices',
      show: true,
    },
    {
      key: 'merchandise',
      to: navigator.pathToAssetsMerchandise(),
      text: 'Merchandise',
      show: true,
    },
    {
      key: 'catalog',
      to: navigator.pathToCatalog(),
      text: 'Your catalog',
      show: true,
    },
    {
      key: 'send-equipment',
      to: navigator.pathToSendEquipment(),
      text: 'Send equipment',
      show: true,
    },
    {
      key: 'start-retrieval',
      to: navigator.pathToRetrieval(RetrievalStepSubRoutes.LOCATION),
      text: 'Start retrieval',
      show: true,
    },
    {
      key: 'buy-and-hold',
      to: navigator.pathToBuyAndHold(),
      text: 'Buy & Hold',
      show: true,
    },
  ];
};
