import Stack from '@mui/material/Stack';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { UploadHistoryItemProps } from './types';

import {
  UploadHistoryItemContentSx,
  UploadHistoryItemInfoSx,
  UploadHistoryItemFileNameSx,
  UploadHistoryItemInfoRowSx,
  UploadHistoryItemErrorsButtonTextSx,
  UploadHistoryItemStatusBoxSx,
} from './styles';
import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { UPLOAD_ITEM_VALIDATING_TOOLTIP_COPY } from './constants';
import { ArrowRightIcon } from '@mui/x-date-pickers';
import TextIconButton from 'components/TextIconButton';
import { UploadStatus } from 'api/rest/uploads/constants';
import { getUploadErrorsText } from '../utils';

function UploadHistoryItem(props: UploadHistoryItemProps) {
  const { upload, onUploadSelect } = props;

  return (
    <Stack sx={UploadHistoryItemContentSx}>
      <Stack sx={UploadHistoryItemInfoSx}>
        <Stack direction="column" width="100%">
          <Stack direction="row" sx={UploadHistoryItemInfoRowSx}>
            <Typography variant="subtitle1" sx={UploadHistoryItemFileNameSx}>
              {upload.originalFileName}
            </Typography>
            <Box sx={UploadHistoryItemStatusBoxSx}>
              <upload.statusIcon {...upload.statusIconProps} />
              <Typography variant="body2" fontWeight="600">
                {upload.statusLabel}
              </Typography>
              {upload.status === UploadStatus.PENDING && (
                <Tooltip title={UPLOAD_ITEM_VALIDATING_TOOLTIP_COPY} arrow>
                  <InfoOutlined />
                </Tooltip>
              )}
            </Box>
          </Stack>
          <Stack direction="row" sx={UploadHistoryItemInfoRowSx}>
            <Typography variant="body2" fontWeight="300">
              {upload.formattedTimestamp} | {upload.collaboratorName}
            </Typography>
            {upload.errors.length ? (
              <TextIconButton
                endIcon={true}
                icon={<ArrowRightIcon color="error" />}
                text={getUploadErrorsText(upload)}
                onClick={() => onUploadSelect(upload.timestamp)}
                ariaLabel="View errors"
                textStyles={UploadHistoryItemErrorsButtonTextSx}
              />
            ) : (
              <Typography
                variant="body2"
                {...(upload.invalidFileError && { color: 'error' })}
              >
                {upload.invalidFileError ||
                  (upload.status !== UploadStatus.PENDING
                    ? 'No errors found'
                    : '')}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default UploadHistoryItem;
