import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UserPermissions } from 'global-constants';
import { NavigationContext } from 'context/NavigationContext';

export const usePermissionRedirect = (
  userPermissions: UserPermissions[],
  permissionToCheck: UserPermissions
) => {
  const navigate = useNavigate();
  const navigator = React.useContext(NavigationContext);
  React.useEffect(() => {
    const arePermissionsLoaded = !userPermissions.includes(
      UserPermissions.NOT__LOADED
    );

    const containsPermission = userPermissions.includes(permissionToCheck);

    if (arePermissionsLoaded && !containsPermission) {
      navigate(navigator.pathToHome());
    }
  }, [userPermissions]);
};
