/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useSelector } from 'react-redux';
import { SxProps } from '@mui/material';
import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterCounterBadge from 'components/complex_filter/FilterCounterBadge';
import * as styles from './styles';
import FilterToggleButtonProps from './types';

const FilterToggleButton = (props: FilterToggleButtonProps) => {
  const getPopoverOpen = props.selectPopoverOpen(props.filterSubTree);
  const popoverOpen = useSelector(getPopoverOpen);
  const isFiltered = useSelector(props.selectIsFiltered(props.filterSubTree));

  const FilterBtnOverrideSx: SxProps = props?.FilterButtonOverrideSx ?? {};
  const filterCounterBadgeProps = {
    filterSubTree: props.filterSubTree,
    selectAllChosenEntries: props.selectAllChosenEntries,
    selectFilterBadgeSx: props?.selectFilterBadgeSx ?? {},
    selectFilterBadgeTestId: props?.selectFilterBadgeTestId ?? '',
  };

  return (
    <Button
      variant="contained"
      onClick={props.onToggleFilterButton}
      startIcon={<FilterListIcon />}
      endIcon={<FilterCounterBadge {...filterCounterBadgeProps} />}
      sx={styles.getFilterButtonSxHOF(
        popoverOpen,
        isFiltered,
        FilterBtnOverrideSx
      )}
    >
      {props.buttonCopy}
    </Button>
  );
};

export default FilterToggleButton;
