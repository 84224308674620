import { IconButton, Snackbar, Typography } from '@mui/material';
import { NewUploadNotificationProps } from './types';
import { NEW_UPLOAD_NOTIFICATION_HIDE_DELAY } from './constants';
import { NewUploadNotificationSx } from './styles';
import React from 'react';
import { getNewUploadNotificationMessage } from './utils';
import { CheckCircleOutline } from '@mui/icons-material';

function NewUploadNotification(props: NewUploadNotificationProps) {
  const { domainLabel, show, onClose, onViewNewUpload } = props;

  const handleViewClick = React.useCallback(() => {
    onClose();
    onViewNewUpload();
  }, [show]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleViewClick}
        >
          <Typography fontSize="small">View</Typography>
        </IconButton>
      }
      open={show}
      message={
        <>
          <CheckCircleOutline />
          {getNewUploadNotificationMessage(domainLabel)}
        </>
      }
      autoHideDuration={NEW_UPLOAD_NOTIFICATION_HIDE_DELAY}
      sx={NewUploadNotificationSx}
      onClose={onClose}
    />
  );
}

export default NewUploadNotification;
