/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useGetUserPermissions from 'hooks/useGetUserPermissions';
import { UserPermissions } from 'global-constants';
import MaintenancePage from 'components/MaintenancePage';

export const SiteMaintenanceContext = React.createContext({
  showMaintenancePage: false,
});

export const SiteMaintenanceProvider = (props: any) => {
  const userPermissions = useGetUserPermissions();
  const bypassAcumaticaMaintenance = userPermissions.includes(
    UserPermissions.MAINTENANCE_BYPASS_ACUMATICA
  );
  const { isAuthenticated } = useAuth0();

  const value = React.useMemo(
    () => ({
      showMaintenancePage: isAuthenticated
        ? !bypassAcumaticaMaintenance
        : false,
    }),
    [bypassAcumaticaMaintenance, isAuthenticated]
  );

  const renderMaintenancePage = () =>
    isAuthenticated && !bypassAcumaticaMaintenance;

  return (
    <SiteMaintenanceContext.Provider value={value}>
      {props.children}
      {/* REMOVE below to NOT render Acumatica MaintenancePage */}
      {/* {renderMaintenancePage() ? <MaintenancePage /> : null} */}
    </SiteMaintenanceContext.Provider>
  );
};
