import React from 'react';
import Pill from 'components/Pill';
import { AssetStatusPillProps } from './types';
import { useGetAssetStatusDetailsConfig } from './hooks/useGetAssetStatusDetailsConfig';
import { useGetAssetStatusTableConfig } from './hooks/useGetAssetStatusTableConfig';
import { ChipLabelSx } from './styles';
import { AssetStatusPillViews } from './constants';

const AssetStatusPill = (props: AssetStatusPillProps) => {
  if (!props.status) return null;

  const getStatusConfig = () => {
    const fallbackPillConfig = {
      text: '',
      color: '',
      textColor: '',
    };

    if (props.pillView === AssetStatusPillViews.DETAILS && props.status) {
      return useGetAssetStatusDetailsConfig(props.status);
    }

    if (props.pillView === AssetStatusPillViews.TABLE && props.status) {
      return useGetAssetStatusTableConfig(props.status);
    }

    if (!props.status) return fallbackPillConfig;
    return fallbackPillConfig;
  };

  const config = getStatusConfig();

  return (
    <Pill
      size="small"
      text={config.text}
      color={config.color}
      textColor={config.textColor}
      chipOverrideSx={ChipLabelSx}
    />
  );
};

export default AssetStatusPill;
