import { FilterPlaceholders } from 'pages/AssetManagement/constants';

export const ASSET_NUMBERS_AVATAR_TESTID = 'asset-numbers-filter-total-avatar';
export const DEPOT_LOCATIONS_AVATAR_TESTID =
  'depot-location-filter-total-avatar';
export const ORGANIZATION_AVATAR_TESTID = 'organizations-filter-total-avatar';
export const ASSET_STATUS_AVATAR_TESTID = 'asset-status-filter-total-avatar';

export const ALLWHERE_INFO_LOOKUP_FIELDS = ['assetNumber'];

export const ALLWHERE_INFO_ASSET_STATUSES = [
  FilterPlaceholders.CLEAR_ALL_FILTER_OPTION,
  'Activation Locked',
  'Allocated',
  'Donated',
  'HOLD',
  'In Inventory',
  'In inventory - device issue',
  'Lost',
  'MDM Locked',
  'Pending Allocation',
  'Pending Certification',
  'Pending Quote Approval',
  'Pending Recycle',
  'Pending Repair',
  'Pending Retrieval',
  'Pending Sale',
  'Pending Wipe',
  'Processing Device',
  'Quote in Progress',
  'Recovery Locked',
  'Recycled',
  'Sold',
];

const ASSET_STATUS_DROPDOWN_OPTIONS = ALLWHERE_INFO_ASSET_STATUSES.map(
  (status) => [status, status]
);
export const ALLWHERE_INFO_ASSET_TYPE_OPTIONS = ASSET_STATUS_DROPDOWN_OPTIONS;
