import { gql } from '@apollo/client';

export const CANCEL_ORDER = gql`
  mutation cancelOrder($id: ID!) {
    cancelOrder(orderCancelInput: { id: $id }) {
      id
      status
      orderNumber
    }
  }
`;
