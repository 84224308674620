/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import OptionInfoTooltip from '../OptionTooltip';
import { TextFieldOuterSx, TextFieldInputSx } from './styles';
import { RetrievalOptionInputProps } from 'pages/Retrieval/components/ReviewStep/components/RetrievalOptionValueInput/types';

const TextFieldOption = (props: RetrievalOptionInputProps) => {
  const [textFieldValue, setTextFieldValue] = React.useState<string>(
    () => props.retrievalAnswer.optionValue
  );

  const answerTextField = (optionValue: string = '') =>
    props.answerRetrievalOption({
      optionName: props.name,
      optionValue,
      optionId: props.id,
    });

  const handleTextFieldBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    answerTextField(event.target.value);
    setTextFieldValue(event.target.value);
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTextFieldValue(event.target.value);
  };

  return (
    <Stack direction="row" justifyContent="space-between" sx={TextFieldOuterSx}>
      <Stack direction="row" justifyContent="flex-start" alignItems="center">
        <Typography>{props.prompt}</Typography>
        <OptionInfoTooltip description={props.description} />
      </Stack>
      <TextField
        defaultValue={' '}
        variant="standard"
        label={props.placeholder}
        sx={TextFieldInputSx}
        onBlur={handleTextFieldBlur}
        value={textFieldValue}
        onChange={handleTextFieldChange}
      />
    </Stack>
  );
};

export default TextFieldOption;
