/* eslint-disable sort-exports/sort-exports */
/* eslint-disable sonarjs/cognitive-complexity */
import { nanoid } from '@reduxjs/toolkit';
import { DatePicker } from '@mui/x-date-pickers';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import VerifiedIcon from '@mui/icons-material/Verified';
import { MM_DD_YYYY_SLASH_FORMAT } from 'global-constants';
import { formatDate } from 'services';
import * as EditDateInlineFormStyles from './styles';
import { EditDateInlineFormProps } from './types';
import { EditDateInlineFormModes } from './constants';
import GAButton from '../buttons/google_analytics/GAButton';
import GALoadingButton from '../buttons/google_analytics/GALoadingButton';

const EditDateInlineForm = (props: EditDateInlineFormProps) => {
  const styles = EditDateInlineFormStyles;
  const baseId = props.idBaseName ? `${props.idBaseName}-` : '';
  const saveButtonName = props.saveButtonName ?? 'Save';
  const cancelButtonName = props.cancelButtonName ?? 'Cancel';
  const editButtonName = props.editButtonName ?? 'Edit';
  const addButtonName = props.addButtonName ?? 'Add';
  const key = props.key ?? nanoid();

  return (
    <>
      {props.formMode === EditDateInlineFormModes.EDIT && key ? (
        <Stack
          id={`${baseId}picker-stack`}
          sx={styles.DatePickerStackSx}
          direction="row"
        >
          <DatePicker
            sx={styles.DatePickerSx}
            value={props.datePickerValue}
            disableFuture={props?.disableFutureDates ?? false}
            // @ts-ignore
            onChange={props.onDateChange}
          />
          <Stack
            id="save-cancel-button-stack"
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            sx={{ ml: '12px', position: 'relative', top: '2px' }}
          >
            <GALoadingButton
              gaContext={{
                navigates_to: 'N/A',
                textCopy: saveButtonName,
                purpose: 'Saves modifications to asset',
              }}
              disabled={props?.disableSaveButton ?? false}
              variant="contained"
              loading={false}
              color="primary"
              sx={styles.DateSaveButtonSx}
              onClick={props.handleSaveClick}
              loadingIndicator={<CircularProgress color="primary" size={20} />}
            >
              <Typography variant="button">{saveButtonName}</Typography>
            </GALoadingButton>
            <GAButton
              gaContext={{
                navigates_to: 'N/A',
                textCopy: cancelButtonName,
                purpose: 'Cancels device modification',
              }}
              id="cancel-button"
              color="secondary"
              onClick={props.handleCancelClick}
              sx={styles.DateButtonBaseSx}
            >
              <Typography variant="button">{cancelButtonName}</Typography>
            </GAButton>
          </Stack>
        </Stack>
      ) : null}

      {props.formMode === EditDateInlineFormModes.VIEW && key ? (
        <Typography variant="body1">
          {formatDate(props?.dateValue ?? '', MM_DD_YYYY_SLASH_FORMAT)}
          {props?.dateValue && props?.isVerified ? (
            <span
              style={{
                paddingLeft: '5px',
                position: 'relative',
                top: '4px',
              }}
            >
              <VerifiedIcon color="primary" fontSize="small" />
            </span>
          ) : null}

          <Tooltip
            title={`Edit ${props.editTitleField}`}
            placement="bottom-start"
            arrow
          >
            <span
              aria-role="button"
              id={`${baseId}edit-date-span-role-button`}
              role="button"
              onClick={props.handleEditClick}
              style={{ ...styles.DateSpanStyle }}
            >
              {editButtonName}
            </span>
          </Tooltip>
        </Typography>
      ) : null}

      {props.formMode === EditDateInlineFormModes.ADD ? (
        <Stack>
          <span
            aria-role="button"
            id={`${baseId}add-date-span-role-button`}
            role="button"
            onClick={props.handleAddClick}
            style={{ ...styles.DateSpanStyle }}
          >
            {addButtonName}
          </span>
        </Stack>
      ) : null}
    </>
  );
};

export default EditDateInlineForm;
