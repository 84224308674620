import { SxProps } from '@mui/material';
import {
  NEW_MODAL_MAX_HEIGHT,
  NEW_MODAL_MAX_WIDTH,
} from 'components/NewModal/constants';
import theme from 'themes/theme';

import { PAPER_WIDTH, PAPER_HEIGHT } from './constants';

export const PaperPropsSx: SxProps = {
  height: {
    xs: '100vh',
    sm: '100vh',
    md: PAPER_HEIGHT,
    lg: PAPER_HEIGHT,
    xl: PAPER_HEIGHT,
  },
  maxWidth: PAPER_WIDTH,
  width: PAPER_WIDTH,
};

export const OuterStackPropsSx: SxProps = {
  height: {
    xs: NEW_MODAL_MAX_HEIGHT,
    sm: NEW_MODAL_MAX_WIDTH,
    md: PAPER_HEIGHT,
    lg: PAPER_HEIGHT,
    xl: PAPER_HEIGHT,
  },
};

export const ContainerStackSx = { height: '90px' };

export const SecondaryButtonSx = {
  position: 'relative',
  top: '1px',
  padding: 0,
  margin: 0,
  width: '45px !important',
  height: '40px',
  '& .MuiTypography-root': {
    fontWeight: '700 !important',
  },
  color: theme.palette.colors.black,
};

export const PrimaryButtonSx = {
  position: 'relative',
  top: '1px',
  padding: 0,
  margin: 0,
  width: '45px !important',
  height: '40px',
  '& .MuiTypography-root': {
    fontWeight: '700 !important',
    color: 'white',
  },
  bgcolor: theme.palette.colors.burntSiennaR,
  '&:hover': {
    bgcolor: theme.palette.colors.chiGong,
  },
};
