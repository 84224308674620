import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ConfirmOrderCreationCancelProps } from './types';
import {
  CancelDialogSx,
  CancelButtonGroupSx,
  CancelButtonsStackSx,
  CancelOuterStackSx,
  CancelMainTitleSx,
} from './styles';

const ConfirmOrderCreationCancelDialog = (
  props: ConfirmOrderCreationCancelProps
) => {
  const handleCloseClick = () => props.onClose();
  const handleCancelClick = () => props.onCancel();

  return (
    <Dialog
      open={props.open}
      sx={CancelDialogSx}
      disableEscapeKeyDown
      onClose={handleCloseClick}
    >
      <Stack
        alignItems="flex-start"
        direction="column"
        justifyContent="space-between"
        sx={CancelOuterStackSx}
      >
        <Stack>
          <Typography variant="h5" sx={CancelMainTitleSx}>
            Are you sure you want to cancel creating a new order?
          </Typography>
          <Typography>
            If canceled, changes will not be saved and order will not be
            created.
          </Typography>
        </Stack>
        <Stack
          alignItems="flex-end"
          direction="row-reverse"
          sx={CancelButtonsStackSx}
        >
          <ButtonGroup
            aria-label="cancel-retrieval-step-button-group"
            disableRipple
            variant="text"
            sx={CancelButtonGroupSx}
          >
            <Button onClick={handleCloseClick}>CLOSE</Button>
            <Button onClick={handleCancelClick} variant="contained">
              YES, CANCEL
            </Button>
          </ButtonGroup>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default ConfirmOrderCreationCancelDialog;
