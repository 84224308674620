import { SxProps } from '@mui/material';

export const TextFieldOuterSx: SxProps = {
  mt: '16px',
};

export const TextFieldInputSx: SxProps = {
  borderBottom: '1px solid',
  borderRadius: 0,
  p: 0,
  maxWidth: '220px',
  '& .MuiFormLabel-root': {
    left: '5px',
  },
};
