import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { RecipientsDataGridProps } from './types';
import DataGrid from 'components/DataGrid';
import { useGetRecipientsColumns } from './hooks/useGetRecipientsColumns';
import { DataGridSx } from 'components/DataGrid/styles';
import * as OrderSummaryStyles from '../../styles';
import * as RecipientDataGridSx from './styles';
import {
  ALLWHERE_RECIPIENT_ADDRESS_SELECTION_COPY,
  DEFAULT_PAGINATION_MODEL,
  GENERIC_PAGE_SIZE_OPTIONS,
} from 'global-constants';
import { useGridApiRef } from '@mui/x-data-grid-pro';

const RecipientsDataGrid = (props: RecipientsDataGridProps) => {
  const styles = RecipientDataGridSx;
  const parentStyles = OrderSummaryStyles;

  const columns = useGetRecipientsColumns(
    props.orderLevelEmailType,
    props.handleSingleEmailChange,
    props.handleAllEmailChange,
    props.handleRemoveRecipient,
    props.handleAddressChange,
    props.handleAddAddress
  );

  return (
    <>
      <Box pt="8px" pb="8px" mb=".5rem">
        <Stack spacing={0.75}>
          <Typography sx={parentStyles.SectionHeaderSx}>
            Select a contact email for the recipients(s)
          </Typography>
          <Typography sx={parentStyles.SubSectionHeaderSx}>
            {ALLWHERE_RECIPIENT_ADDRESS_SELECTION_COPY}
          </Typography>
        </Stack>
      </Box>
      <DataGrid
        dataGridSx={styles.DataGridSx}
        apiRef={useGridApiRef()}
        pagination
        paginationMode="server"
        paginationModel={DEFAULT_PAGINATION_MODEL}
        disableRowSelectionOnClick
        rows={props.collaborators}
        rowCount={props.collaborators.length}
        columns={columns}
        pageSizeOptions={GENERIC_PAGE_SIZE_OPTIONS}
        slotProps={{
          panel: {
            placement: 'bottom-end',
            sx: DataGridSx,
          },
        }}
        getDetailPanelHeight={() => 'auto'}
        slots={{}}
        onRowClick={() => {}}
        onCellClick={() => {}}
        sortingMode="server"
        disableMultipleColumnsSorting
      />
    </>
  );
};

export default RecipientsDataGrid;
