import { UploadStatus } from 'api/rest/uploads/constants';
import { ViewableToDisplayableUploadMapper } from './types';
import {
  Cancel,
  CheckCircleSharp,
  TimelapseOutlined,
} from '@mui/icons-material';

export const UPLOAD_STATUS_MAPPER: ViewableToDisplayableUploadMapper = {
  [UploadStatus.COMPLETED]: {
    label: 'Complete',
    icon: CheckCircleSharp,
    iconProps: { color: 'success' },
  },
  [UploadStatus.PENDING]: {
    label: 'Validating',
    icon: TimelapseOutlined,
    iconProps: { color: 'primary' },
  },
  [UploadStatus.FAILED]: {
    label: 'Failed',
    icon: Cancel,
    iconProps: { color: 'warning' },
  },
};
