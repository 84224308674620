import { gql } from '@apollo/client';

export const ADMIN_GET_ASSETS_BY_SERIAL_NUMBER = gql`
  query adminSearchAssets($serialNumber: String) {
    adminSearchAssets(assetsAdminSearchInput: { serialNumber: $serialNumber }) {
      assets {
        id
        serialNumber
      }
    }
  }
`;

export const ADMIN_GET_ASSETS_BY_ASSET_NUMBER = gql`
  query adminSearchAssets($assetNumber: String) {
    adminSearchAssets(assetsAdminSearchInput: { assetNumber: $assetNumber }) {
      assets {
        id
        assetNumber
      }
    }
  }
`;

export const GET_INVENTORY_RECEIPTS_BY_THIRD_PARTY_ASSET_ID = gql`
  query searchInventoryReceipts($identifier: String) {
    searchInventoryReceipts(
      inventoryReceiptSearchInput: { identifier: $identifier }
    ) {
      inventoryReceipts {
        id
        assetId
        identifier
      }
    }
  }
`;
