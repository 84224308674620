import { gql } from '@apollo/client';

export const GET_ASSET_AUTOCOMPLETE_SUGGESTIONS = gql`
  query getAssetAutocompleteSuggestions(
    $organizationId: ID
    $searchTerm: String!
    $searchBy: String!
    $limit: Int = 15
    $searchField: String!
  ) {
    getAssetAutocompleteSuggestions(
      assetFieldSuggestionsInput: {
        organizationId: $organizationId
        searchTerm: $searchTerm
        skipValues: []
        searchBy: $searchBy
        limit: $limit
        searchField: $searchField
      }
    ) {
      field
      matches
      matchIds
    }
  }
`;

export const GET_ASSIGNEE_EMAIL_OPTIONS = gql`
  query getCollaboratorAutocompleteSuggestions(
    $organizationId: ID
    $searchTerm: String!
    $limit: Int = 15
  ) {
    getCollaboratorAutocompleteSuggestions(
      collaboratorFieldSuggestionsInput: {
        organizationId: $organizationId
        searchTerm: $searchTerm
        limit: $limit
        searchBy: "iLike"
        searchField: "anyEmail"
      }
    ) {
      fields
      matchIds
      matches
    }
  }
`;

export const GET_ASSIGNEE_NAME_OPTIONS = gql`
  query getCollaboratorAutocompleteSuggestions(
    $searchTerm: String!
    $limit: Int = 15
    $organizationId: ID
  ) {
    getCollaboratorAutocompleteSuggestions(
      collaboratorFieldSuggestionsInput: {
        organizationId: $organizationId
        searchTerm: $searchTerm
        limit: $limit
        searchBy: "iLike"
        searchField: "firstOrLastName"
      }
    ) {
      fields
      matchIds
      matches
    }
  }
`;

// Dropdown queries
export const GET_FIRST_100_DEPOTS = gql`
  query searchDepots {
    searchDepots(input: { limit: 100 }) {
      depots {
        id
        name
      }
    }
  }
`;

export const GET_ALL_ASSET_TYPES = gql`
  query {
    getAllAssetTypes {
      id
      name
    }
  }
`;

// DO NOT REMOVE, Breaks GQL startup
export const DUMMY_NODE_GET_NOTHING = gql`
  query {
    searchDepots(input: { limit: 0 }) {
      count
    }
  }
`;
