import { gql } from '@apollo/client';

export const RETRIEVE_RECIPIENT = gql`
  query retrieveRecipient($id: String!) {
    retrieveRecipient(id: $id) {
      id
      firstName
      lastName
      phoneNumber
      email
      address {
        id
        streetAddress1
        streetAddress2
        city
        state
        principalRegion
        zipCode
        country
      }
    }
  }
`;

export const GET_DEPOTS = gql`
  query {
    searchDepots(input: { limit: 100 }) {
      count
      depots {
        id
        name
      }
    }
  }
`;
