export enum ShipmentStatusEnum {
  /**
   * Carrier attempted to deliver but failed,,
   * and usually leaves a notice and will try to deliver again.
   */
  ATTEMPT_FAILED = 'ATTEMPT_FAILED',

  /**
   * The package arrived at a pickup point near you and is available for pickup.
   */
  AVAILABLE_FOR_PICKUP = 'AVAILABLE_FOR_PICKUP',

  /**
   * The default value if no value is supply during creation.
   */
  CREATED = 'CREATED',

  /**
   * The shipment was delivered successfully.
   */
  DELIVERED = 'DELIVERED',

  /**
   * Custom hold,, undelivered,, returned shipment to sender or any shipping exceptions.
   */
  EXCEPTION = 'EXCEPTION',

  /**
   * Shipment has no tracking information for 30 days since added.
   */
  EXPIRED = 'EXPIRED',

  /**
   * Carrier has accepted or picked up shipment from shipper. The shipment is on the way.
   */
  INFO_RECEIVED = 'INFO_RECEIVED',

  /**
   * Carrier has received request from shipper and is about to pick up the shipment.
   */
  IN_TRANSIT = 'IN_TRANSIT',

  /**
   * Carrier is about to deliver the shipment ,, or it is ready to pickup.
   */
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',

  /**
   * New shipments added that are pending to track,,
   * or new shipments without tracking information available yet.
   */
  PENDING = 'PENDING',
}
