/* eslint-disable sort-exports/sort-exports */
import { SxProps } from '@mui/material';
import theme from 'themes/theme';

const MAIN_STACK_SIZE_SM = 'calc(100vh - 112px)';
const MAIN_STACK_SIZE_MD = 'calc(613px - 48px)';

export const BillingDialogSx: SxProps = {
  '& .MuiDialog-paper': {
    borderRadius: '24px',
    p: '24px',
  },
  '&: .MuiFormHelperText-root': {
    position: 'absolute',
    bottom: '-20px',
  },
};

export const BillingDialogPaperSx: SxProps = {
  height: {
    xs: '100vh',
    sm: '100vh',
    md: '613px',
    lg: '613px',
    xl: '613px',
  },
  maxWidth: '760px',
  width: '100vw',
};

export const BillingMainStackSx: SxProps = {
  height: {
    xs: MAIN_STACK_SIZE_SM,
    sm: MAIN_STACK_SIZE_SM,
    md: MAIN_STACK_SIZE_MD,
    lg: MAIN_STACK_SIZE_MD,
    xl: MAIN_STACK_SIZE_MD,
  },
};

export const BillingMainTitleSx: SxProps = {
  fontWeight: 700,
  lineHeight: '38.4px',
};

export const BillingUseAcctTextSx: SxProps = {
  fontWeight: 600,
  position: 'relative',
  '& > span': {
    fontSize: '14px',
    fontWeight: 400,
    position: 'absolute',
    left: 0,
    top: '24px',
  },
};

export const BillingConsentSx: SxProps = {
  position: 'relative',

  '& > span': {
    position: 'absolute',
    p: 0,
    left: '10px',
    top: 0,
  },
  '& .MuiTypography-root': {
    ml: '42px',
    width: 'calc(100% - 36px)',
  },
};

export const BillingCheckboxSx: SxProps = {
  '&.Mui-checked': {
    color: theme.palette.colors.woodSmoke,
  },
};

export const BillingButtonsStackSx: SxProps = {
  position: 'absolute',
  bottom: 0,
};

export const BillingCancelButtonSx: SxProps = {
  color: '#000000',
  fontWeight: 500,
};

export const BillingSubmitButtonSx: SxProps = {
  fontWeight: 500,
  '&:hover': {
    backgroundColor: theme.palette.colors.mustardY,
  },
};

export const BillingErrorHelperSx: SxProps = {
  position: 'absolute',
  bottom: '-20px',
  '&: .MuiFormHelperText-root': {
    position: 'absolute',
    bottom: '-20px',
  },
};

export const BillingQuestionTextSx: SxProps = {
  mt: '10px',
  fontSize: '14px',
  fontWeight: 500,
  '& .MuiLink-root': {
    color: theme.palette.colors.strongBlue,
    textDecorationColor: theme.palette.colors.strongBlue,
  },
};

export const BillingConsentCheckboxTextSx: SxProps = {
  fontSize: '14px',
};
