/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useDispatch } from 'react-redux';
import { assetManagementSlice } from 'store/slices';
import { Avatar as MuiAvatar } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';
import GenericAutocomplete from 'components/GenericAutocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useDebouncedSearch from 'hooks/useDebouncedSearch';
import { ASM_DASH_DRAWER_INPUT_WIDTH } from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/constants';
import * as AMDashComplexFilterStyles from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/styles';
import { AMDashComplexTypeAheadProps } from './types';
import { FilterPlaceholders } from 'pages/AssetManagement/constants';
import * as AMDashComplexTypeAheadStyles from './styles';

const AMDashComplexTypeAhead = (props: AMDashComplexTypeAheadProps) => {
  const styles = AMDashComplexTypeAheadStyles;
  const drawerStyles = AMDashComplexFilterStyles;
  const dispatch = useDispatch();

  const [lookAheadOpen, setLookAheadOpen] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState('');
  const debouncedSearch = useDebouncedSearch(searchInput, 300);

  const handleInputChange = (_e: any, value: any) => {
    setSearchInput(value);
  };

  React.useEffect(() => {
    if (debouncedSearch.length <= 1) return;
    props.handleDebounceEffect(debouncedSearch);
  }, [debouncedSearch]);

  const icon = (
    <CheckBoxOutlineBlankIcon fontSize="small" style={{ color: '#131314' }} />
  );

  const checkedIcon = (
    <CheckBoxIcon fontSize="small" style={{ color: '#131314' }} />
  );

  const isSelected = (displayValue: string, selected: boolean) => {
    const selections = props.selectedOptions || [];
    const optionsTable = Object.fromEntries(selections);

    return Boolean(optionsTable[displayValue]) || selected;
  };

  const clearAll = () => {
    dispatch(
      assetManagementSlice.actions.clearFilterByTypeReducer(props.filterKey)
    );
  };

  const getPlaceholder = (): string => {
    if (lookAheadOpen) {
      return 'Start typing';
    }
    return props.selectedOptions.length > 0
      ? props.renderInputLabel
      : 'Start typing';
  };

  const handleOnLookAheadChange = (event: any, value: any, reason: string) => {
    const lookupOptionTable = Object.fromEntries(value as [string, string][]);

    if (reason === 'selectOption') {
      dispatch(
        assetManagementSlice.actions.selectFilterOptionReducer({
          filterKey: props.filterKey,
          option: value[value.length - 1],
        })
      );
    }

    const removeValue = props.selectedOptions.find(
      (option: string[]) => !lookupOptionTable[option[0]]
    ) ?? ['', ''];

    const noOptionFound = !removeValue[0];

    if (reason === 'removeOption' && !noOptionFound) {
      const removeValue = props.selectedOptions.find(
        (option: string[]) => !lookupOptionTable[option[0]]
      ) ?? ['', ''];

      dispatch(
        assetManagementSlice.actions.removeSelectFilterOptionReducer({
          filterKey: props.filterKey,
          displayValue: removeValue[0],
        })
      );
    }

    if (noOptionFound) {
      console.warn('No value to remove');
    }
  };

  const getOptionListItem = (
    listProps: React.HTMLAttributes<HTMLLIElement>,
    option: string[],
    selected: boolean
  ) => {
    const isClearAllOption =
      option[0] === FilterPlaceholders.CLEAR_ALL_FILTER_OPTION;
    const hasSelections = Boolean(props.selectedOptions.length);

    if (isClearAllOption && hasSelections) {
      return (
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={styles.AMDashClearStackSx}
        >
          <Box sx={{ pl: 0, fontWeight: 600 }}>Applied</Box>
          <Button
            disableRipple
            startIcon={<CloseIcon />}
            sx={styles.AMDashClearAllButtonSx}
            onClick={() => clearAll()}
          >
            <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
              Clear all
            </Typography>
          </Button>
        </Stack>
      );
    }

    if (isClearAllOption) return null;

    return (
      <li
        {...listProps}
        style={{
          backgroundColor: selected ? '#f6f6f6' : 'transparent',
          ...styles.OptionListItemCSS,
        }}
        data-option-uuid={option[1]}
      >
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={isSelected(option[0], selected)}
        />
        <Typography sx={styles.AMDashOptionsCopy}>{option[0]}</Typography>
      </li>
    );
  };

  const getSelectedValuesAvatar = () => {
    const hasSelections = Boolean(props.selectedOptions?.length ?? 0);
    const typeAheadClosed = !lookAheadOpen;
    const shouldRender = hasSelections && typeAheadClosed;

    return (
      <MuiAvatar
        data-testid={props.counterAvatarTestId}
        sx={styles.getAMDashTypeAheadFilterCountSx(shouldRender)}
      >
        {props.selectedOptions.length}
      </MuiAvatar>
    );
  };

  return (
    <>
      <GenericAutocomplete
        multiple
        disableCloseOnSelect
        disableClearable
        loading={false}
        open={lookAheadOpen}
        onOpen={() => setLookAheadOpen(true)}
        onClose={() => setLookAheadOpen(false)}
        onChange={handleOnLookAheadChange}
        onInputChange={handleInputChange}
        value={props.selectedOptions}
        getOptionLabel={(option) => option[0]}
        componentsProps={{
          clearIndicator: {
            style: {
              display: 'none',
            },
          },
          popper: {
            sx: {
              width: ASM_DASH_DRAWER_INPUT_WIDTH,
              '& .Mui-focused': {
                backgroundColor: 'rgba(0,0,0,.12) !important',
              },
            },
          },
        }}
        renderOption={(props, option, { selected }) =>
          getOptionListItem(props, option, selected)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={getPlaceholder()}
            label={props.renderInputLabel}
            aria-label={props.renderInputLabel}
            sx={drawerStyles.AutoCompleteSearchTextFieldSx}
          />
        )}
        sx={drawerStyles.AutoCompleteSx}
        options={props.availableOptions}
      />
      {getSelectedValuesAvatar()}
    </>
  );
};

export default AMDashComplexTypeAhead;
