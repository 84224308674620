export const EARLIEST_DATE_ENGINEER_CAN_UPDATE_HOLIDAYS_LIST = '2024-12-26';

export const DEFAULT_DATE_FORMAT = 'MMM DD, yyyy';

export const HOLIDAYS_UNTIL_CHRISTMAS_EOY = [
  '2023-01-02',
  '2023-01-16',
  '2023-02-20',
  '2023-05-29',
  '2023-06-19',
  '2023-07-04',
  '2023-09-04',
  '2023-10-09',
  '2023-11-10',
  '2023-11-23',
  '2023-11-24',
  '2023-12-22',
  '2023-12-25',
  '2024-01-01',
  '2024-01-15',
  '2024-02-19',
  '2024-05-27',
  '2024-06-19',
  '2024-07-04',
  '2024-09-02',
  '2024-10-14',
  '2024-11-11',
  '2024-11-28',
  '2024-11-29',
  '2024-12-24',
  '2024-12-25',
];

export const HOLIDAYS_LIST_WILL_EXPIRE_WARNING =
  'INTERNAL_ENGINEER_WARNING:\n\nHolidays list is out of date. Please update the holidays list. \nSee src/services/date/constants.ts';
