/* eslint-disable no-underscore-dangle */
/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';
import { ApolloError } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingIndictor from 'components/LoadingIndicator';
import TextIconButton from 'components/TextIconButton';
import EmployeeActionsGroup from 'pages/Employees/components/EmployeeActionsGroup';
import ArchiveEmployeeModal from 'pages/Employees/components/ArchiveEmployeeConfirmationModal';
import EmployeeEditModal from 'pages/Employees/components/EmployeeEditModal';
import {
  NotificationMessages,
  CustomNotifications,
} from 'components/GlobalToastNotification/constants';
import { Entities, HttpCodes, FormModeContext } from 'global-constants';
import { NavigationContext } from 'context/NavigationContext';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { useSafeMutation } from 'hooks/useSafeMutation';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import { GlobalState } from 'store/types';
import { GlobalModals } from 'store/constants';
import { modalSlice, notificationSlice } from 'store/slices';
import * as EmployeeDetailStyles from './styles';
import { RETRIEVE_EMPLOYEE } from './queries';
import { DELETE_COLLABORATOR_BY_ID } from './mutations';
import { formatEmployeeDetails } from './utils';
import { FeatureFlagNames } from 'enums';
import {
  EMPLOYEE_DETAILS_TESTID,
  PERSONAL_EMAIL_TESTID,
  WORK_EMAIL_TESTID,
} from './constants';
import GAButton from 'components/buttons/google_analytics/GAButton';
import * as Sentry from '@sentry/react';

const EmployeeDetails = () => {
  const styles = EmployeeDetailStyles;
  const { id: employeeId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigator = React.useContext(NavigationContext);

  const {
    OnFlagTrueWrapper: EmployeeEmailOptionsEnabledDisplay,
    OnFlagFalseWrapper: EmployeeEmailOptionsDisabledDisplay,
  } = useFeatureFlagService(FeatureFlagNames.EMPLOYEE_EMAIL_OPTIONS, {
    debugFlags: true,
  });

  const currentModal = useSelector<GlobalState>(
    (state) => state.modal.currentModal
  );
  const [showArchiveEmployeeModal, setShowArchiveEmployeeModal] =
    React.useState(false);

  const { data, loading } = useSafeQuery(RETRIEVE_EMPLOYEE, {
    variables: {
      id: employeeId,
    },
    fetchPolicy: 'network-only',
  });

  const employeeDetailsObj = data?.collaborator || undefined;
  const {
    fullName,
    firstName,
    lastName,
    // birthday,
    email,
    personalEmail,
    workEmail,
    phoneNumber,
    homeAddress,
    workAddress,
    team,
    hireDate,
    startDate,
    homeAddresses,
    workAddresses,
  } = formatEmployeeDetails(employeeDetailsObj);

  const formatAddressString = (addressString: string) => {
    const multiLineText = addressString
      .split('\n')
      .map((addressLine, index) => (
        <React.Fragment key={index}>
          {addressLine}
          {index < addressString.split('\n').length - 1 && <br />}
        </React.Fragment>
      ));

    return <Typography variant="body1">{multiLineText}</Typography>;
  };

  const displayAddresses = (addresses: string[] | null) => {
    if (!addresses) return null;
    return addresses.map((address, index) => (
      <>
        {formatAddressString(address)}
        {index < addresses.length - 1 && <br />}
      </>
    ));
  };

  const [mutate, { loading: loadingMutation }] = useSafeMutation(
    DELETE_COLLABORATOR_BY_ID
  );

  const showEditEmployeeInfoModal = () => {
    dispatch(
      modalSlice.actions.setCurrentModal(GlobalModals.EDIT_EMPLOYEE_INFORMATION)
    );
  };

  const handleCloseEditEmployeeInfoModal = () => {
    dispatch(modalSlice.actions.reset());
  };

  const openArchiveEmployeeModal = () => {
    setShowArchiveEmployeeModal(true);
  };

  const closeArchiveEmployeeModal = () => {
    setShowArchiveEmployeeModal(false);
  };

  const archiveEmployee = () => {
    mutate({
      variables: { id: data?.collaborator?.id },
      onCompleted(data: any) {
        navigate(navigator.pathToEmployees());

        const noticeContent =
          data?.deleteCollaborator?.data?.message ??
          NotificationMessages.CHANGES_SAVED_SUCCESS;

        closeArchiveEmployeeModal();
        // refetchCollaboratorsQuery();
        dispatch(
          notificationSlice.actions.setNotice({
            showNotice: true,
            noticeContent,
          })
        );
      },
      onError(apolloErr: ApolloError) {
        const { graphQLErrors = [{ message: '' }] } = apolloErr;
        const errorNotice = graphQLErrors[0].message.includes(
          `${HttpCodes.NOT_FOUND}`
        )
          ? CustomNotifications.entityNotFound(Entities.EMPLOYEE)
          : NotificationMessages.GENERIC_SOMETHING_WENT_WRONG;

        closeArchiveEmployeeModal();
        dispatch(
          notificationSlice.actions.setNotice({
            showNotice: true,
            noticeContent: errorNotice,
          })
        );
      },
    });
  };

  const showEditEmployeeModal =
    currentModal === GlobalModals.EDIT_EMPLOYEE_INFORMATION &&
    !!employeeDetailsObj;

  const buttonGroupSx = {
    position: 'relative',
    right: 'unset',
    top: '-10px',
  };

  return (
    <>
      <LoadingIndictor loading={loading || loadingMutation} />
      <Container
        sx={styles.MainContainerDivSx}
        data-testid={EMPLOYEE_DETAILS_TESTID}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          mt="10"
        >
          <TextIconButton
            text="Back to Employees"
            ariaLabel="Navigate back to Employees page"
            icon={<ArrowBackIcon />}
            onClick={() => navigate(navigator.pathToEmployees())}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h2">Employee Details</Typography>
          <EmployeeActionsGroup
            employee={employeeDetailsObj}
            showEditEmployeeInfoModal={showEditEmployeeInfoModal}
            openArchiveEmployeeModal={openArchiveEmployeeModal}
            EmployeeActionsButtonGroupSx={buttonGroupSx}
          />
        </Stack>
        <Stack sx={styles.ContentDivSx} alignItems="flex-start">
          <Typography variant="h6">{fullName}</Typography>
          <Divider sx={styles.TitleDividerSx} />
          <Typography variant="subtitle2">Personal Information</Typography>
          <Stack sx={styles.EmployeeInfoStackSx} direction="row">
            <Grid container sx={styles.EmployeeInfoGridSx}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="overline" color="colors.gray60">
                  FIRST NAME
                </Typography>
                <Typography variant="body1">{firstName}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="overline" color="colors.gray60">
                  LAST NAME
                </Typography>
                <Typography variant="body1">{lastName}</Typography>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={4}>
                <Typography variant="overline" color="colors.gray60">
                  BIRTHDAY
                </Typography>
                <Typography variant="body1">{birthday}</Typography>
              </Grid> */}
            </Grid>
          </Stack>
          <Stack sx={styles.EmployeeInfoStackSx}>
            <Typography variant="subtitle2">Contact Information</Typography>
            <Grid container sx={styles.EmployeeInfoGridSx}>
              <EmployeeEmailOptionsEnabledDisplay>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  data-testid={PERSONAL_EMAIL_TESTID}
                >
                  <Typography variant="overline" color="colors.gray60">
                    PERSONAL EMAIL
                  </Typography>
                  <Typography variant="body1">{personalEmail}</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  data-testid={WORK_EMAIL_TESTID}
                >
                  <Typography variant="overline" color="colors.gray60">
                    WORK EMAIL
                  </Typography>
                  <Typography variant="body1">{workEmail}</Typography>
                </Grid>
              </EmployeeEmailOptionsEnabledDisplay>
              <EmployeeEmailOptionsDisabledDisplay>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="overline" color="colors.gray60">
                    CONTACT EMAIL
                  </Typography>
                  <Typography variant="body1">{email}</Typography>
                </Grid>
              </EmployeeEmailOptionsDisabledDisplay>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="overline" color="colors.gray60">
                  MOBILE PHONE
                </Typography>
                <Typography variant="body1">{phoneNumber}</Typography>
              </Grid>
            </Grid>
          </Stack>

          <Stack sx={styles.EmployeeInfoStackSx}>
            <Typography variant="subtitle2">Addresses</Typography>
            <Grid container sx={styles.EmployeeInfoGridSx}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="overline" color="colors.gray60">
                  HOME ADDRESS
                </Typography>
                {displayAddresses(homeAddresses)}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="overline"
                  color="colors.gray60"
                  display="block"
                >
                  WORK ADDRESS
                </Typography>
                {displayAddresses(workAddresses)}
              </Grid>
            </Grid>
          </Stack>

          <Stack sx={styles.EmployeeInfoStackSx}>
            <Typography variant="subtitle2">Employment information</Typography>
            <Grid container sx={styles.EmployeeInfoGridSx}>
              <Grid item xs={12} sm={4}>
                <Typography variant="overline" color="colors.gray60">
                  TEAM
                </Typography>
                <Typography variant="body1">{team}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="overline"
                  color="colors.gray60"
                  display="block"
                >
                  HIRE DATE
                </Typography>
                <Typography variant="body1">{hireDate}</Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography
                  variant="overline"
                  color="colors.gray60"
                  display="block"
                >
                  START DATE
                </Typography>
                <Typography variant="body1">{startDate}</Typography>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </Container>
      <EmployeeEditModal
        employee={employeeDetailsObj}
        open={showEditEmployeeModal}
        formMode={FormModeContext.EDIT}
        handleClose={handleCloseEditEmployeeInfoModal}
        refetchEmployees={() => {}}
        leftBottomAction={
          <GAButton
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Archive employee',
              purpose: 'Archives An Employee',
            }}
            id="archive-button"
            color="error"
            onClick={openArchiveEmployeeModal}
          >
            <Typography variant="button">
              <strong>Archive employee</strong>
            </Typography>
          </GAButton>
        }
      />
      <ArchiveEmployeeModal
        open={showArchiveEmployeeModal}
        onSubmit={archiveEmployee}
        onCancel={() => setShowArchiveEmployeeModal(false)}
      />
    </>
  );
};

export default EmployeeDetails;
