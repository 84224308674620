import { OrderStatus } from 'global-constants';

export const OrderStatusMapping = {
  [OrderStatus.CREATED]: 'Processing',
  [OrderStatus.CANCELLED]: 'Canceled',
  [OrderStatus.COMPLETE]: 'Complete',
  [OrderStatus.EXCEPTION]: 'Exception',
  [OrderStatus.EXPIRED_PENDING_RECIPIENT_INFORMATION]:
    'Expired - Pending Recipient Information',
  [OrderStatus.EXPIRED_PENDING_RETURN]: 'Expired - Pending Return',
  [OrderStatus.IN_FULFILLMENT]: 'In Fulfillment',
  [OrderStatus.IN_RETURN]: 'In Return',
  [OrderStatus.PENDING_APPROVAL]: 'Preparing Order',
  [OrderStatus.PENDING_RECIPIENT_INFORMATION]: 'Pending recipient response',
  [OrderStatus.PENDING_RETURN]: 'Pending return',
  [OrderStatus.SHIPPED]: 'Shipped',
  [OrderStatus.PROCESSING_DEVICE]: 'Processing Device',
  [OrderStatus.PENDING_REPAIR]: 'Pending Repair',
  [OrderStatus.PENDING_RECYCLE]: 'Pending Recycle',
  [OrderStatus.PENDING_CERTIFICATE]: 'Pending Certificate',
  [OrderStatus.QUOTE_IN_PROGRESS]: 'Quote in progress',
  [OrderStatus.PENDING_QUOTE_APPROVAL]: 'Pending Quote Approval',
};

export const OM_ORGANIZATION_INPUT_ID =
  'order-management-filters-organization-input';
export const DEFAULT_FILTER_WIDTH = '212px';
export const DEFAULT_FILTER_HEIGHT = '40px';
export const DISABLED_FILTER_TOOLTIP_TITLE =
  'Current View Tab disables and overrides this filter';

export const OM_ORDER_TYPE_FILTER_SETTINGS = {
  placeholder: 'Order Type',
  label: 'Order Type',
  labelId: 'order-mgmt-order-type-filter-label',
  inputId: 'order-mgmt-order-type-filter-input',
  clearButtonId: 'order-mgmt-order-type-filter-clear-button',
};

export const OM_ORDER_STATUS_FILTER_SETTINGS = {
  placeholder: 'Order Status',
  label: 'Order Status',
  labelId: 'order-status-filter-label',
  inputId: 'order-status-filter-input',
};

export const OM_ORDER_MANAGER_FILTER_SETTINGS = {
  placeholder: 'Order Manager',
  label: 'Order Manager',
  labelId: 'order-manager-filter-label',
  inputId: 'order-manager-filter-input',
};

export const OM_DEPOT_FILTER_SETTINGS = {
  placeholder: 'Depot',
  label: 'Depot',
  labelId: 'order-depot-filter-label',
  inputId: 'order-depot-filter-input',
};
