/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import GenericAutocomplete from 'components/GenericAutocomplete';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import useDebouncedSearch from 'hooks/useDebouncedSearch';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import {
  DEVICE_FILTERS_WIDTH,
  DEVICE_FILTERS_HEIGHT,
} from 'pages/Assets/components/DevicesDatagrid/components/DeviceFilters/constants';
import { GET_ASSETS_BY_MEMORY } from './queries';
import { AssetMemoryFilterProps } from './types';
import { ASSET_FILTER_INPUT_ID, ASSET_TEXTFIELD_LABEL } from './constants';
import * as AssetMemoryFilterStyles from './styles';

const AssetMemoryFilter = (props: AssetMemoryFilterProps) => {
  const styles = AssetMemoryFilterStyles;
  const organization = useGetUserOrganization();
  const [assetMemory, setAssetMemory] = React.useState('');
  const [displaySizeOptions, setDisplaySizeOptions] = React.useState<string[]>(
    []
  );
  const debouncedAssetMemory = useDebouncedSearch(assetMemory, 300);

  const [fetchAssetsByMemory] = useSafeLazyQuery(GET_ASSETS_BY_MEMORY, {
    onCompleted(data) {
      const { searchAssets } = data;
      const assetSet = new Set<string>();
      const assets = searchAssets?.assets || [];
      if (!assets.length) return;
      const tempOptions = assets
        .map((asset: any) => asset.memory)
        .filter((memory: string) => {
          if (assetSet.has(memory)) return false;
          assetSet.add(memory);
          return true;
        });

      setDisplaySizeOptions(tempOptions);
    },
  });

  const handleInputChange = (_e: any, value: any) => {
    setAssetMemory(value);
  };

  const clearAllAutoCompleteOptions = () => {
    const clearTextIconButton: HTMLElement = document.querySelector(
      `#${ASSET_FILTER_INPUT_ID} .MuiAutocomplete-clearIndicator`
    ) as HTMLElement;
    if (clearTextIconButton) {
      clearTextIconButton.click();
    }
    setAssetMemory('');
    setDisplaySizeOptions([]);
    props.setChosenAssetMemory('');
  };

  const handleAutoCompleteChange = (event: any, value: any) => {
    if (value === null) {
      clearAllAutoCompleteOptions();
      return;
    }

    props.setChosenAssetMemory(value);
  };

  React.useEffect(() => {
    if (!organization?.id || !debouncedAssetMemory) return;
    fetchAssetsByMemory({
      variables: {
        organizationId: organization.id,
        iLike: {
          field: 'memory',
          value: `%${debouncedAssetMemory}%`,
        },
      },
    });
  }, [organization?.id, debouncedAssetMemory]);

  React.useEffect(() => {
    if (props.chosenAssetMemory) return;
    clearAllAutoCompleteOptions();
  }, [props.chosenAssetMemory]);

  return (
    <Stack
      direction="column"
      sx={styles.AutoCompleteStackSx}
      id={ASSET_FILTER_INPUT_ID}
    >
      <GenericAutocomplete
        loading={false}
        onChange={handleAutoCompleteChange}
        onInputChange={handleInputChange}
        componentsProps={{
          popper: {
            style: {
              width: DEVICE_FILTERS_WIDTH,
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Start typing"
            label={ASSET_TEXTFIELD_LABEL}
            aria-label={ASSET_TEXTFIELD_LABEL}
            sx={styles.AutoCompleteSearchTextFieldSx}
          />
        )}
        sx={styles.AutoCompleteSx}
        options={displaySizeOptions}
      />
    </Stack>
  );
};

export default AssetMemoryFilter;
