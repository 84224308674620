import USAFlagSVGIcon from 'components/Icons/flags/USAFlagSVGIcon';
import UKFlagSVGIcon from 'components/Icons/flags/UKFlagSVGIcon';
import SpainFlagSVGIcon from 'components/Icons/flags/SpainFlagSVGIcon';
import ColumbiaFlagSVGIcon from 'components/Icons/flags/ColumbiaFlagSVGIcon';
import MexicoFlagSVGIcon from 'components/Icons/flags/MexicoFlagSVGIcon';
import BrazilFlagSVGIcon from 'components/Icons/flags/BrazilFlagSVGIcon';
import UruguayFlagSVGIcon from 'components/Icons/flags/UruguayFlagSVGIcon';
import { COUNTRY_MATCHER } from 'global-constants';
import IrelandFlagSVGIcon from 'components/Icons/flags/IrelandFlagSVGIcon';
import PeruFlagSVGIcon from 'components/Icons/flags/PeruFlagSVGIcon';
import ArgentinaFlagSVGIcon from 'components/Icons/flags/ArgentinaFlagSVGIcon';
import NoFlagSVGIcon from 'components/Icons/flags/NoFlagSVGIcon';

export const DEPOT_TABLE_TITLE_ID = 'depot-table-title-id';

export const DEPOT_FLAG_MAPPING = {
  [COUNTRY_MATCHER['United States']]: USAFlagSVGIcon,
  [COUNTRY_MATCHER['United Kingdom']]: UKFlagSVGIcon,
  [COUNTRY_MATCHER.Spain]: SpainFlagSVGIcon,
  [COUNTRY_MATCHER.Colombia]: ColumbiaFlagSVGIcon,
  [COUNTRY_MATCHER.Mexico]: MexicoFlagSVGIcon,
  [COUNTRY_MATCHER.Brazil]: BrazilFlagSVGIcon,
  [COUNTRY_MATCHER.Uruguay]: UruguayFlagSVGIcon,
  [COUNTRY_MATCHER.Ireland]: IrelandFlagSVGIcon,
  [COUNTRY_MATCHER.Peru]: PeruFlagSVGIcon,
  [COUNTRY_MATCHER.Argentina]: ArgentinaFlagSVGIcon,
  NONE: NoFlagSVGIcon,
};
