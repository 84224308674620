/* eslint-disable sort-exports/sort-exports */
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ComplexFilterBankProps from './types';
import * as DefaultComplexFilterBankStyles from './styles';
import { CLEAR_BUTTON_WIDTH } from './constants';

const ComplexFilterBank = (props: ComplexFilterBankProps) => {
  const styles = DefaultComplexFilterBankStyles;
  const {
    filterSubTree,
    selectAllChosenEntries,
    renderBankContents,
    clearFilterBank,
    baseSelectorPrefix = 'complex-filter-bank-',
    clearButtonWidth = CLEAR_BUTTON_WIDTH,
    bankSxOverride = {},
    clearButtonSxOverride = {},
  } = props;

  const bankContents = useSelector(selectAllChosenEntries(filterSubTree)) || [];

  const buttonBoxClassName = `${baseSelectorPrefix}${filterSubTree}-button-box`;
  const componentTestId = `${baseSelectorPrefix}${filterSubTree}-component-test-id`;

  const ComplexFilterBankStackSx = {
    ...styles.getComplexFilterBankStackSx(buttonBoxClassName, clearButtonWidth),
    ...bankSxOverride,
  };

  const ComplexFilterClearAllSx = {
    ...styles.ComplexFilterClearAllSx,
    ...clearButtonSxOverride,
  };

  if (!bankContents.length) return <></>;

  return (
    <Stack
      data-testid={componentTestId}
      direction="row"
      justifyContent="space-between"
      sx={ComplexFilterBankStackSx}
    >
      <Box sx={styles.FilterBankPillBoxSX}>
        <Stack direction="row" sx={styles.FilterBankBoundingStackSx}>
          {bankContents.map(renderBankContents)}
        </Stack>
      </Box>
      <Box className={buttonBoxClassName}>
        <Button
          disableRipple
          startIcon={<CloseIcon />}
          sx={ComplexFilterClearAllSx}
          onClick={clearFilterBank}
        >
          <Typography>Clear all filters</Typography>
        </Button>
      </Box>
    </Stack>
  );
};

export default ComplexFilterBank;
