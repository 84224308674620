import React from 'react';
import { TransitionGroup } from 'react-transition-group';
import { Table, TableBody, TableContainer, Typography } from '@mui/material';
import { Button, Modal } from 'components';
import { SelectedCollaboratorModalProps } from './types';
import CollaboratorTableRow from './components/CollaboratorTableRow';

const SelectedCollaboratorsModal = (props: SelectedCollaboratorModalProps) => {
  const containerRef = React.useRef(null);

  return (
    <Modal open={props.open}>
      <>
        <Modal.Header>
          <p>Allocating to</p>
        </Modal.Header>
        <Modal.Body>
          <TableContainer
            sx={{
              height: '300px',
            }}
          >
            <Table>
              <TableBody
                // This background color is causing weird issues when resizing the browser to
                // a smaller size. One can see the red outline the table cells. Going to remove the
                // color for now to ensure no one sees this.
                // sx={{ backgroundColor: 'colors.burntSiennaR' }}
                ref={containerRef}
              >
                <TransitionGroup>
                  {props.selectedCollaborators.map((selectedCollaborator) => (
                    <CollaboratorTableRow
                      key={selectedCollaborator.email}
                      collaborator={selectedCollaborator}
                      containerRef={containerRef}
                      removeSelectedCollaborator={
                        props.removeSelectedCollaborator
                      }
                    />
                  ))}
                </TransitionGroup>
              </TableBody>
            </Table>
          </TableContainer>
        </Modal.Body>
        <Modal.Footer>
          <Button
            gaContext={{
              textCopy: 'Back',
              navigates_to: 'Order Summary',
              purpose: 'Navigates Back To Order Summary',
            }}
            variant="contained"
            onClick={props.handleClose}
          >
            <Typography variant="caption">Back</Typography>
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  );
};

export default SelectedCollaboratorsModal;
