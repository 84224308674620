import React from 'react';
import { Pill } from 'components';
import { ProductStatus } from 'global-constants';
import { capitalize } from 'services';
import { InventoryReceiptProductStatusPillProps } from './types';

const InventoryReceiptProductStatusPill = (
  props: InventoryReceiptProductStatusPillProps
) => {
  const color =
    props.status === ProductStatus.COMPLETE
      ? 'colors.goldFusion'
      : 'colors.burntSiennaR';

  return (
    <Pill
      size="small"
      toolTipArrow
      text={capitalize(props.status)}
      color={color}
      textColor="white"
      chipOverrideSx={props.sx}
    />
  );
};

export default InventoryReceiptProductStatusPill;
