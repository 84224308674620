import { SxProps } from '@mui/material';
import {
  MODAL_PAPER_PROPS_HEIGHT,
  MODAL_OUTER_STACK_PROPS_HEIGHT,
} from './constants';

export const PaperPropsSx: SxProps = {
  height: {
    md: MODAL_PAPER_PROPS_HEIGHT,
    lg: MODAL_PAPER_PROPS_HEIGHT,
    xl: MODAL_PAPER_PROPS_HEIGHT,
  },
  maxWidth: {
    xs: '100vw',
    sm: '100vw',
    md: '650px',
    lg: '650px',
    xl: '650px',
  },
};

export const OuterStacksPropsSx: SxProps = {
  height: {
    md: MODAL_OUTER_STACK_PROPS_HEIGHT,
    lg: MODAL_OUTER_STACK_PROPS_HEIGHT,
    xl: MODAL_OUTER_STACK_PROPS_HEIGHT,
  },
};
