import {
  RetrievalAsset,
  RetrievalCollaborator,
  RetrievalOptionAnswer,
} from 'pages/Retrieval/RetrievalService/types';

export const getInsuranceInfo = (
  asset: RetrievalAsset,
  optionAnswerKey: Record<string, RetrievalOptionAnswer[]>
) => {
  const insuranceInfo = optionAnswerKey[asset.id].find((answer) =>
    (answer?.optionName ?? '').toLowerCase().includes('insurance')
  );

  if (!insuranceInfo || insuranceInfo.optionValue === 'null') return '0';

  return insuranceInfo.optionValue;
};

/**
 * @description removes assets with the field "removeAsset" if it's true
 */
export const pruneUncheckedAssets = (
  collaborator: RetrievalCollaborator
): RetrievalCollaborator => {
  const clonedCollaborator = { ...collaborator };
  clonedCollaborator.assets = clonedCollaborator.assets
    .slice()
    .filter((asset) => !asset?.removeAsset);
  return clonedCollaborator;
};
