import { gql } from '@apollo/client';

export const SEARCH_ORDER_MANAGERS = gql`
  query searchOrderManagers(
    $limit: Int
    $orderAsc: String
    $orderDesc: String
    $email: String
    $firstName: String
    $lastName: String
  ) {
    searchOrderManagers(
      orderManagerSearchInput: {
        limit: $limit
        orderAsc: $orderAsc
        orderDesc: $orderDesc
        email: $email
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      orderManagers {
        id
        firstName
        lastName
        email
      }
    }
  }
`;
