import { BackendOperations } from 'global-constants';
import {
  HEADLESS_ASSET_CSV_HEADERS,
  ASSET_ID,
  ASSET_NUMBER,
} from './constants';

export const getAssetCSVHeaders = (operationsBackend: any): string[] => {
  const backendOperationValues = Object.values(BackendOperations);
  const valueNotFound =
    backendOperationValues.findIndex((val) => val === operationsBackend) === -1;

  if (valueNotFound)
    throw new Error(`Invalid Backend Operations: ${operationsBackend} found.`);

  const firstHeader =
    operationsBackend === BackendOperations.ACUMATICA ? ASSET_NUMBER : ASSET_ID;
  return [firstHeader, ...HEADLESS_ASSET_CSV_HEADERS];
};
