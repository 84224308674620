import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import * as DropdownStyles from './styles';
import { DropdownProps } from './types';
import {
  Button,
  Container,
  FormControl,
  InputLabel,
  Menu,
  Typography,
} from '@mui/material';
import React from 'react';
import { Add, CheckOutlined } from '@mui/icons-material';

const Dropdown = (props: DropdownProps) => {
  const styles = DropdownStyles;
  const [open, setOpen] = React.useState(false);
  const onChangeEvent = (event: any) => {
    setOpen(false);
    props.handleSearchOptionChange(event);
  };
  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMenu(event.currentTarget);
    if (props.onMenuToggle) props.onMenuToggle(true);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
    if (props.onMenuToggle) props.onMenuToggle(false);
  };

  const handleMenuOptionSelect = (item: any) => {
    props.handleSearchOptionChange({ target: item });
    handleCloseMenu();
  };

  const renderSelectValue = (selected: Element) => {
    if (props.children) return <>{props.children}</>;
    if (!selected) return <Typography>{props.placeholderText}</Typography>;
    const selectedItem = props.items.find((item) => item.value === selected);
    if (!selectedItem) return <Typography>{props.placeholderText}</Typography>;
    return (
      <>
        {selectedItem?.icon ? <selectedItem.icon sx={styles.IconSx} /> : null}
        <Typography sx={{ overflow: 'hidden' }}>
          {selectedItem.label || 'N/A'}
        </Typography>
      </>
    );
  };

  const dropdownSx = {
    ...styles.DropdownSelectSx,
    ...(props.dropdownSx || {}),
  };

  const dropdownItemSx = {
    ...styles.MenuItemSx,
    ...(props.dropdownItemSx || {}),
  };

  return (
    <>
      {(() => {
        switch (props.type) {
          case 'Select':
            return (
              <FormControl>
                <InputLabel sx={props.labelSx}>
                  {props.placeholderText}
                </InputLabel>
                <Select
                  disabled={props.disabled}
                  data-testid="dropdown-select-test-id"
                  sx={{
                    ...dropdownSx,
                  }}
                  value={props.selectedItem}
                  open={open}
                  onClick={() => setOpen(!props.disabled && !open)}
                  onChange={onChangeEvent}
                  aria-placeholder="Select an option"
                  renderValue={renderSelectValue}
                  error={props.error}
                  SelectDisplayProps={{
                    style: {
                      paddingTop: '8px',
                      paddingBottom: '8px',
                      display: 'flex',
                    },
                  }}
                >
                  {props.items.map((item) => (
                    <MenuItem
                      data-testid="dropdown-select-item-test-id"
                      sx={dropdownItemSx}
                      value={item.value}
                    >
                      {item.icon && <item.icon sx={styles.IconSx} />}
                      {item.label}
                    </MenuItem>
                  ))}
                  {props.allowAddSelectItem && (
                    <Button
                      data-testid="dropdown-add-select-item-test-id"
                      disableRipple
                      startIcon={<Add />}
                      sx={styles.AddSelectItemSx}
                      onClick={props.handleAddSelectItem}
                    >
                      <Typography>
                        {props.addSelectItemText || 'Add select item'}
                      </Typography>
                    </Button>
                  )}
                </Select>
              </FormControl>
            );
          case 'Menu':
          default:
            return (
              <>
                <Container sx={dropdownSx} onClick={handleOpenMenu}>
                  {props.children}
                </Container>
                <Menu
                  anchorEl={anchorElMenu}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElMenu)}
                  onClose={handleCloseMenu}
                  data-testid="dropdown-menu-test-id"
                >
                  {props.items.map((item) => (
                    <MenuItem
                      data-testid="dropdown-menu-item-test-id"
                      sx={dropdownItemSx}
                      value={item.value}
                      onClick={() => handleMenuOptionSelect(item)}
                    >
                      {item.icon && <item.icon sx={styles.IconSx} />}
                      {item.label}
                      {props.showCheckIcon &&
                        item.value === props.selectedItem && (
                          <Container sx={DropdownStyles.checkIconSx}>
                            <CheckOutlined />
                          </Container>
                        )}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            );
        }
      })()}
    </>
  );
};

export default Dropdown;
