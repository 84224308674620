import { SxProps } from '@mui/material';
import { ACTION_BUTTONS_DIAMETER } from './constants';

export const EmployeeActionButtonGroupSx: SxProps = {
  position: 'absolute',
  right: '-16px',
  top: 0,
};

export const getEmployeeActionButtonGroupSx = (
  buttonCount: number = 2
): SxProps => ({
  position: 'absolute',
  right: 0,
  top: 0,
  width: `calc(${ACTION_BUTTONS_DIAMETER} * ${buttonCount} + 16px * (${buttonCount} - 1))`,
  display: 'inline-flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const ActionIconButtonSx: SxProps = {
  backgroundColor: 'colors.maizeYO',
  width: '48px',
  height: '48px',
  '& svg': {
    width: '100%',
    height: '100%',
  },
  ':disabled': {
    backgroundColor: 'colors.wildSandWN',
    '& .custom-stroke': {
      stroke: '#C0C0C0',
    },
    '& .custom-fill': {
      fill: '#C0C0C0',
    },
  },
};
