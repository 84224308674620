import { SxProps } from '@mui/material';

export const AssetTypeFilterStackSx: SxProps = {
  height: '20px',
  position: 'relative',
};

export const getAssetFilterFormControlSx = (inputLabelId: string): SxProps => ({
  [`& #${inputLabelId}`]: {
    top: '-10px',
    '&.MuiInputLabel-shrink': {
      top: 0,
    },
  },
});

export const AssetFilterSelectSx: SxProps = {
  width: '165px',
  '& .MuiSelect-select': {
    py: '6px',
  },
};
