import { RootState } from 'store';

export const selectSearchedQueryVariable = (state: RootState) =>
  state.assets.comboBoxFilter.searchedQueryVariable;

export const selectClearFilters = (state: RootState) =>
  state.assets.comboBoxFilter.clearFilters;

export const selectComplexSearch = (state: RootState) =>
  state.assets.comboBoxFilter.complexEntry;
