import React from 'react';
import {
  GridToolbarFilterButton,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro';

const CustomGridToolbar = () => {
  return (
    <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
      <GridToolbarFilterButton sx={{ padding: 0 }} />
    </GridToolbarContainer>
  );
};

export default CustomGridToolbar;
