import { gql } from '@apollo/client';

export const GET_ASSETS_BY_MODEL = gql`
  query getAssetsByModel(
    $organizationId: ID!
    $model: String
    $iLike: AssetILikeParam
  ) {
    searchAssets(
      assetsSearchInput: {
        organizationId: $organizationId
        model: $model
        iLikeFilter: $iLike
        offset: "0"
      }
    ) {
      assets {
        id
        model
      }
      count
    }
  }
`;
