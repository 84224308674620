export const setLocalStorageValue = (key: string, value: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.log(
      `Could not set local storage item by key: ${key}, value: ${value}`
    );
  }
};

export const getLocalStorageValue = (key: string): any => {
  let item = {};
  try {
    item = JSON.parse(localStorage.getItem(key) || '{}');
  } catch (e) {
    console.warn('Could not get local storage item by key:', key);
    return undefined;
  }
  return item;
};
