import Box from '@mui/material/Box';

import { DetailsTableProps } from './types';

import { UploadErrorsDataGridSx } from './styles';
import DataGrid from 'components/DataGrid';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useGetDetailsColumns } from './hooks/useGetDetailsColumns';
import { DataGridSx } from 'components/DataGrid/styles';
import { ENABLE_HORIZONTAL_SCROLLING_CLASS_NAME } from 'components/DataGrid/constants';

function DetailsTable(props: DetailsTableProps) {
  const { selectedUpload } = props;

  const rows =
    selectedUpload?.errors.map((error, index) => ({ ...error, id: index })) ||
    [];

  const columns = useGetDetailsColumns();

  return (
    <Box sx={UploadErrorsDataGridSx}>
      <DataGrid
        sx={UploadErrorsDataGridSx}
        apiRef={useGridApiRef()}
        disableRowSelectionOnClick
        rows={rows}
        rowCount={rows.length}
        columns={columns}
        slotProps={{
          panel: {
            placement: 'bottom-end',
            sx: DataGridSx,
          },
        }}
        classes={{
          virtualScroller: ENABLE_HORIZONTAL_SCROLLING_CLASS_NAME,
        }}
        getDetailPanelHeight={() => 'auto'}
        sortingMode="server"
        disableMultipleColumnsSorting
        autoHeight={false}
      />
    </Box>
  );
}

export default DetailsTable;
