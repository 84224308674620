import React from 'react';
import { Avatar, Typography } from '@mui/material';
import { BubbleProps } from './types';

const Bubble = (props: BubbleProps) => {
  const shouldRender = props?.show ?? true;
  if (!shouldRender) return <></>;

  return (
    <Avatar
      sx={{
        bgcolor: props?.bgcolor ?? 'colors.wildSandWN',
        color: props?.color ?? 'colors.mineShaftWN',
        ...(props?.bubbleSx ?? {}),
      }}
    >
      <Typography variant="body1" sx={{ ...(props?.textSx ?? {}) }}>
        {props.text}
      </Typography>
    </Avatar>
  );
};

export default Bubble;
