import React from 'react';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  useTheme,
} from '@mui/material';
import { CircleTwoTone, CheckCircle } from '@mui/icons-material';
import { Collaborator } from 'types';
import { Avatar } from 'components';
import { CollaboratorTableProps } from './types';
import styles from './CollaboratorTable.module.css';
import { getInitials } from 'services/getInitials';
import { DEFAULT_LIMIT } from '../../constants';

const CollaboratorTable = (props: CollaboratorTableProps) => {
  const theme = useTheme();

  const isNextButtonDisabled = props.collaborators.length < DEFAULT_LIMIT;

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const userCheckedCollaborator = JSON.parse(
      e.currentTarget.value
    ) as Collaborator;

    if (checked) {
      props.addSelectedCollaborator(userCheckedCollaborator);
    } else {
      props.removeSelectedCollaborator(userCheckedCollaborator);
    }
  };

  const displayRowDetails = (from: number, to: number, count: number) => {
    // Assigning row numbers in the collaborator table based on pagination. Limit is 10 per page.
    // We may allow users to change the limit in the future
    if (props.totalNumOfCollaboratorsInOrg < DEFAULT_LIMIT) {
      from = 1;
    } else if (to - from !== DEFAULT_LIMIT - 1) {
      from = props.page * DEFAULT_LIMIT;
      to = props.totalNumOfCollaboratorsInOrg;
    }
    return `${from} - ${to} of ${count}`;
  };

  return (
    <TableContainer sx={{ marginTop: '1rem' }}>
      <Table>
        <TableBody>
          {props.collaborators.map((collaborator) => (
            <TableRow key={collaborator.email}>
              <TableCell align="left" padding="none" width="10%">
                <Checkbox
                  disableRipple={true}
                  value={JSON.stringify(collaborator)}
                  onChange={handleOnChange}
                  checked={
                    props.selectedCollaborators.find(
                      (selectedCollaborator) =>
                        selectedCollaborator.email === collaborator.email
                    ) !== undefined
                  }
                  icon={
                    <CircleTwoTone
                      fontSize="large"
                      htmlColor={theme.palette.colors.grayD9}
                    />
                  }
                  checkedIcon={
                    <CheckCircle fontSize="large" color="secondary" />
                  }
                />
              </TableCell>
              <TableCell align="left" padding="none" width="28%">
                <div className={styles['name-table-cell']}>
                  <Avatar
                    text={getInitials(collaborator)}
                    height={40}
                    width={40}
                  />
                  {collaborator.firstName} {collaborator.lastName}
                </div>
              </TableCell>
              <TableCell align="left" padding="none" width="62%">
                {collaborator.email}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TablePagination
            count={props.totalNumOfCollaboratorsInOrg}
            page={props.page}
            rowsPerPage={props.collaborators.length}
            rowsPerPageOptions={[]}
            onPageChange={(_e, newPage) => {
              props.setPage(newPage);
            }}
            labelDisplayedRows={({ from, to, count }) =>
              displayRowDetails(from, to, count)
            }
            nextIconButtonProps={{
              disabled: isNextButtonDisabled,
            }}
          />
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default CollaboratorTable;
