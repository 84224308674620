import { gql } from '@apollo/client';

export const CREATE_ASSET = gql`
  mutation createAsset(
    $organizationId: ID!
    $assetTypeId: ID!
    $status: AssetStatusEnum!
    $assigneeId: ID
    $serialNumber: String
    $make: String
    $model: String
    $color: String
    $cosmeticCondition: AssetCosmeticCondition
    $displaySize: String
    $memory: String
    $storage: String
    $processor: String
    $keyboard: String
    $deviceAssessment: String
    $warrantyExpiration: DateTime
    $purchaseDate: DateTime
    $isWarrantyExpirationVerified: Boolean
    $isPurchaseDateVerified: Boolean
  ) {
    createAsset(
      assetCreateInput: {
        organizationId: $organizationId
        assetTypeId: $assetTypeId
        status: $status
        assigneeId: $assigneeId
        serialNumber: $serialNumber
        make: $make
        model: $model
        color: $color
        cosmeticCondition: $cosmeticCondition
        displaySize: $displaySize
        memory: $memory
        storage: $storage
        processor: $processor
        keyboard: $keyboard
        deviceAssessment: $deviceAssessment
        warrantyExpiration: $warrantyExpiration
        purchaseDate: $purchaseDate
        isWarrantyExpirationVerified: $isWarrantyExpirationVerified
        isPurchaseDateVerified: $isPurchaseDateVerified
      }
    ) {
      id
      assetNumber
      warrantyExpiration
      purchaseDate
      isWarrantyExpirationVerified
      isPurchaseDateVerified
    }
  }
`;
