/* eslint-disable sort-exports/sort-exports */
import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const RetrievalDialogSx: SxProps = {
  '& .MuiDialog-paper': {
    borderRadius: '24px',
    p: '24px',
    pl: '36px',
    position: 'relative',
  },
  '&: .MuiFormHelperText-root': {
    position: 'absolute',
    bottom: '-20px',
  },
  '& .MuiIconButton-root': {
    color: theme.palette.colors.gray94,
    position: 'absolute',
    right: '24px',
  },
};

export const RetrievalDialogPaperSx: SxProps = {
  height: {
    xs: '100vh',
    sm: '100vh',
    md: '730px',
    lg: '730px',
    xl: '730px',
  },
  maxWidth: '789px',
  width: '100vw',
};

export const RetrievalInfoModalBoxSx = {
  display: 'inline-flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  '& img': {
    position: 'relative',
    right: '12px',
  },
};

export const RetrievalInfoModalTitleSx: SxProps = {
  fontSize: '36px',
  fontWeight: 600,
  mt: '25px',
};

export const RetrievalInfoModalBodyCopySx = (
  options: Record<string, any> | undefined = undefined
): SxProps => ({
  fontSize: '18px',
  ...(options && { ...options }),
  // fontWeight: 600,
});

export const RetrievalInfoModalListStyleSx: SxProps = {
  listStyleType: 'disc',
  pl: '24px',
  pt: '24px',
  '& .MuiListItem-root': {
    display: 'list-item',
    pb: 0,
    pl: 0,
  },
};

export const RetrievalInfoModalFAQSx: SxProps = {
  color: theme.palette.colors.gray94,
  fontSize: '16px',
  textAlign: 'center',
  '& .MuiLink-root': {
    color: theme.palette.colors.strongBlue,
    fontWeight: 600,
    textDecorationColor: 'transparent',
    '&:hover': {
      textDecorationColor: 'transparent',
    },
  },
};
