import React from 'react';
import {
  GridColDef,
  GridRowSelectionModel,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-pro';
import Radio from '@mui/material/Radio';
import { capitalize } from 'services/string';
import {
  DEFAULT_BLANK_TABLE_CELL_VALUE,
  AssetStatusSelfDescriptive,
} from 'global-constants';
import { AssetStatusMapping } from 'pages/Assets/constants';
import AssetStatusPill from 'components/AssetStatusPill';
import { AssetStatusPillViews } from 'components/AssetStatusPill/constants';
import Pill from 'components/Pill';
import { useTheme, useMediaQuery } from '@mui/material';

const useGetAssetManagementColumns = (
  rowSelectionModel: GridRowSelectionModel,
  rowHoveringModel: string
  // eslint-disable-next-line sonarjs/cognitive-complexity
): GridColDef[] => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderHeader: () => <></>,
      filterable: false,
      renderCell(params) {
        const isRowSelected = rowSelectionModel.length
          ? params.id === rowSelectionModel[0]
          : false;
        const isHovered = params.id.toString() === rowHoveringModel;
        const showRadioButton = isRowSelected || isHovered;
        return (
          <Radio
            id={`device_id_${params.id}_radio_button`}
            sx={{
              visibility: showRadioButton ? 'visible' : 'hidden',
            }}
            checked={isRowSelected}
          />
        );
      },
    },
    {
      field: 'organization',
      headerName: 'Organization',
      flex: isLargeScreen ? 2 : 1,
      sortable: false,
      filterable: false,
      valueGetter(params) {
        return params?.row?.organization.name ?? DEFAULT_BLANK_TABLE_CELL_VALUE;
      },
    },
    {
      field: 'thirdPartyAssetId',
      headerName: '3p Asset ID',
      flex: 1,
      sortable: false,
      filterable: true,
      valueGetter(params) {
        return params?.row?.thirdPartyAssetId ?? DEFAULT_BLANK_TABLE_CELL_VALUE;
      },
    },
    {
      field: 'serialNumber',
      headerName: 'Serial Number',
      flex: isLargeScreen ? 2 : 1,
      sortable: true,
      filterable: true,
      valueGetter(params) {
        return params?.row?.serialNumber ?? DEFAULT_BLANK_TABLE_CELL_VALUE;
      },
    },
    {
      field: 'model',
      headerName: 'Model',
      flex: isLargeScreen ? 2 : 1,
      sortable: true,
      filterable: true,
      valueGetter(params) {
        return params?.row?.model ?? DEFAULT_BLANK_TABLE_CELL_VALUE;
      },
    },
    {
      field: 'modelNumber',
      headerName: 'Model Number',
      flex: 1,
      sortable: true,
      filterable: true,
      valueGetter(params) {
        return params?.row?.modelNumber ?? DEFAULT_BLANK_TABLE_CELL_VALUE;
      },
    },
    {
      field: 'dataStatus',
      headerName: 'Data Status',
      flex: 1,
      sortable: true,
      filterable: true,
      valueGetter(params) {
        return params?.row?.dataStatus ?? DEFAULT_BLANK_TABLE_CELL_VALUE;
      },
      renderCell: (params) => {
        const pillColor =
          params.value === 'Wiped'
            ? theme.palette.colors.goldFusion
            : theme.palette.colors.chiGong;
        return (
          <>
            {params.value.length === 1 ? (
              <div>{params.value}</div>
            ) : (
              <Pill
                text={params.value}
                textColor={theme.palette.colors.white}
                color={pillColor}
              />
            )}
          </>
        );
      },
    },
    {
      field: 'assetNumber',
      headerName: 'Asset Number',
      flex: 1,
      sortable: true,
      filterable: true,
      valueGetter(params) {
        return params?.row?.assetNumber ?? DEFAULT_BLANK_TABLE_CELL_VALUE;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      sortable: true,
      filterable: false,
      type: 'singleSelect',
      renderCell: (params) => {
        return (
          <AssetStatusPill
            status={
              params?.row?.status as unknown as AssetStatusSelfDescriptive
            }
            pillView={AssetStatusPillViews.TABLE}
          />
        );
      },
      valueOptions: Object.entries(AssetStatusMapping).map(
        ([value, label]) => ({
          value,
          label,
        })
      ),
    },
  ];
};

export default useGetAssetManagementColumns;
