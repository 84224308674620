/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Divider from '@mui/material/Divider';
import GenericAutocomplete from 'components/GenericAutocomplete';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import {
  OrderSearchBy,
  ORDER_INPUT_FIELDS,
  SEARCH_BAR_TYPE_SELECT_WIDTH,
  SEARCH_BAR_WHOLE_WIDTH,
  DEFAULT_AUTOCOMPLETE_OPTIONS,
  ORDERS_SEARCH_BAR_CLEAR_INDICATOR_ID,
} from 'pages/Orders/constants';
import {
  OrderSearchByOptions,
  AutoCompleteOptionTuple,
} from 'pages/Orders/types';
import useDebouncedSearch from 'hooks/useDebouncedSearch';
import {
  resetComboSearch,
  resetNonComboSearch,
  setSearchFilterByKey,
} from 'store/slices/orders/search';
import { orderFilterActions } from 'store/slices/orders/filter';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import {
  SEARCH_ORDERS_BY_ORDER_NUMBER,
  GET_COLLABORATORS_BY_NAME,
} from 'pages/Orders/queries';
import { selectIsFiltered } from 'store/shared/selectors';
import * as OrderLookAheadSearchStyles from './styles';

const OrderLookAheadSearch = () => {
  const styles = OrderLookAheadSearchStyles;
  const userOrganization = useGetUserOrganization();
  const dispatch = useDispatch();
  const hasOrderFilters = useSelector(selectIsFiltered('orders'));

  const [lookAheadOption, setLookAheadOption] = React.useState<
    string | undefined
  >();

  const [autocompleteOptions, setAutocompleteOptions] = React.useState<
    AutoCompleteOptionTuple[]
  >(DEFAULT_AUTOCOMPLETE_OPTIONS);

  const [orderSearchType, setOrderSearchType] =
    React.useState<OrderSearchByOptions>(OrderSearchBy.ORDER_NUMBER);
  const [orderTerm, setOrderTerm] = React.useState<string>('');
  const debouncedSearchTerm = useDebouncedSearch(orderTerm);

  const { placeholder, label, inputId, queryField } =
    ORDER_INPUT_FIELDS[orderSearchType];

  const [isFocused, setIsFocused] = React.useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const resetAutoCompleteOptions = () => {
    setOrderTerm('');
    setAutocompleteOptions(DEFAULT_AUTOCOMPLETE_OPTIONS);
  };

  const resetLookAheadOptions = () => {
    setLookAheadOption(undefined);
    dispatch(resetComboSearch());
  };

  const resetAllOtherFilters = () => {
    dispatch(resetNonComboSearch());
  };

  const handleClearTextIconClick = () => {
    setOrderTerm('');
    resetAutoCompleteOptions();
    resetLookAheadOptions();
  };

  const handleSearchTermChange = (
    e: React.SyntheticEvent<Element, Event>,
    value: any,
  ) => {
    if (!value) {
      resetAutoCompleteOptions();
      resetAllOtherFilters();
      return;
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    resetAllOtherFilters();
    setLookAheadOption(value[0]);
    dispatch(
      setSearchFilterByKey({
        key: 'lookAheadOption',
        value: value[0],
      }),
    );
    dispatch(
      orderFilterActions.softResetFilterStateReducer({
        closeFilterDrawer: true,
      }),
    );
  };

  const handleSearchTypeChange = () => {
    const clearTextIconButton: HTMLElement = document.getElementById(
      ORDERS_SEARCH_BAR_CLEAR_INDICATOR_ID,
    ) as HTMLElement;
    if (clearTextIconButton) {
      handleClearTextIconClick();
      clearTextIconButton.click();
    }
    // eslint-disable-next-line security/detect-object-injection
    setOrderSearchType((prevState) => ORDER_INPUT_FIELDS[prevState].opposite);
    resetAutoCompleteOptions();
    resetAllOtherFilters();
    resetLookAheadOptions();
  };

  const [fetchCollaboratorsByName] = useSafeLazyQuery(
    GET_COLLABORATORS_BY_NAME,
    {
      onCompleted(data) {
        const { collaborators: fetchedCollaborators } = data;
        const { collaborators: collaboratorsData } = fetchedCollaborators;
        if (!collaboratorsData.length) return;
        const collaboratorOptions = collaboratorsData
          .slice()
          .sort((a: any, b: any) => a.lastName.localeCompare(b.lastName))
          .map((collaborator: any) => [
            collaborator?.id,
            `${collaborator?.firstName} ${collaborator?.lastName} (${collaborator?.email})`,
          ]);
        setAutocompleteOptions(collaboratorOptions);
        dispatch(resetNonComboSearch());
      },
    },
  );

  const [fetchOrderOptions] = useSafeLazyQuery(SEARCH_ORDERS_BY_ORDER_NUMBER, {
    onCompleted(data) {
      const { searchOrders } = data;
      const { orders } = searchOrders;
      if (!orders?.length ?? 0) return;
      const orderOptions = orders
        .slice()
        .sort((a: any, b: any) => a.orderNumber.localeCompare(b.orderNumber))
        .filter(
          (order: any, index: number) =>
            !(index > 0 && order.id === orders[index - 1].id),
        )
        .map((order: any) => [
          order?.orderNumber,
          `Order number: ${order?.orderNumber}`,
        ]);

      setAutocompleteOptions(orderOptions);
      dispatch(resetNonComboSearch());
    },
  });

  const handleDebouncedSearch = (
    searchTerm: string,
    searchType: OrderSearchByOptions,
  ) => {
    if (!searchTerm) return;
    if (searchType === OrderSearchBy.ORDER_NUMBER) {
      fetchOrderOptions({
        variables: {
          organizationId: userOrganization?.id,
          orderNumber: searchTerm,
          limit: 100,
        },
      });
    }
    if (searchType === OrderSearchBy.RECIPIENT_NAME) {
      fetchCollaboratorsByName({
        variables: {
          organizationId: userOrganization?.id,
          firstOrLastName: searchTerm,
        },
      });
    }
  };

  React.useEffect(() => {
    if (!debouncedSearchTerm || /\s/.test(debouncedSearchTerm)) return;
    handleDebouncedSearch(debouncedSearchTerm, orderSearchType);
  }, [debouncedSearchTerm]);

  // orderSearchType is NOT the same as queryField
  React.useEffect(() => {
    dispatch(
      setSearchFilterByKey({
        key: 'lookAheadOptionType',
        value: orderSearchType,
      }),
    );
  }, [orderSearchType]);

  React.useEffect(() => {
    if (!lookAheadOption || !hasOrderFilters) return;
    resetAutoCompleteOptions();
    resetLookAheadOptions();
  }, [hasOrderFilters]);

  return (
    <Stack
      direction="row"
      sx={{
        ...styles.AutoCompleteStackSx,
        mt: 'rem',
        outline: `2px solid ${isFocused ? 'black' : 'transparent'}`,
      }}
      divider={<Divider orientation="vertical" variant="middle" flexItem />}
    >
      <SearchIcon sx={styles.AutoCompleteSearchIconSx} />
      <GenericAutocomplete
        placeholder={placeholder}
        sx={styles.AutoCompleteSx}
        onChange={handleSearchTermChange}
        onInputChange={(_e, value) => {
          setOrderTerm(value);
        }}
        componentsProps={{
          popper: {
            style: {
              width: SEARCH_BAR_WHOLE_WIDTH,
            },
          },
          // STYLING - for options dropdown below
          paper: {
            style: {
              position: 'relative',
              top: '3px',
              left: '85px',
            },
          },
          clearIndicator: {
            id: ORDERS_SEARCH_BAR_CLEAR_INDICATOR_ID,
            onClick: handleClearTextIconClick,
          },
        }}
        renderInput={(params) => {
          const {
            inputProps: { value, ...restInputProps },
            ...restParams
          } = params;
          const restOfParams = { ...restParams, inputProps: restInputProps };
          return (
            <TextField
              {...restOfParams}
              id={inputId}
              value={orderTerm}
              variant="outlined"
              placeholder={placeholder}
              onFocus={handleFocus}
              onBlur={handleBlur}
              sx={styles.AutoCompleteSearchTextFieldSx}
            />
          );
        }}
        // @ts-ignore
        getOptionLabel={(option: AutoCompleteOptionTuple) => option[1]}
        options={autocompleteOptions}
      />
      <Select
        sx={styles.AutoCompleteSelectSx}
        value={orderSearchType}
        onChange={handleSearchTypeChange}
        MenuProps={{
          sx: styles.AutoCompleteSelectMenuSx,
        }}
        SelectDisplayProps={{
          style: {
            width: SEARCH_BAR_TYPE_SELECT_WIDTH,
            paddingTop: '8px',
            paddingBottom: '8px',
          },
        }}
      >
        {Object.values(OrderSearchBy).map((searchByOption: string) => (
          <MenuItem value={searchByOption}>
            {
              ORDER_INPUT_FIELDS[
                searchByOption as keyof typeof ORDER_INPUT_FIELDS
              ].optionLabel
            }
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

export default OrderLookAheadSearch;
