import { SxProps } from '@mui/material';
import theme from 'themes/theme';
import { MINIMUM_PREMIUM_SHIPPING_RADIO_GROUP_WIDTH } from 'global-styles';

export const SummarySubmitButtonSx: SxProps = {
  '&.Mui-disabled.MuiLoadingButton-root': {
    border: '1px solid rgba(249, 197, 77, 0.5)',
  },
  '.MuiLoadingButton-loadingIndicator': {
    color: theme.palette.colors.maizeYO,
  },
};

export const BackButtonSx: SxProps = {
  color: theme.palette.colors.woodSmoke,
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '24px',
  width: '78px',
  minWidth: '78px',
  maxWidth: '78px',
  height: '36px',
  borderRadius: '4px',
  paddingLeft: '0',
  paddingRight: '0',
  '&:hover': {
    border: `1px solid ${theme.palette.colors.woodSmoke}`,
    backgroundColor: 'transparent',
  },
  '&:focus': {
    border: `2px solid ${theme.palette.colors.woodSmoke}`,
    backgroundColor: theme.palette.colors.heartOfIce,
  },
};

export const SummaryTextHeaderSx: SxProps = {
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
};

export const StepperSx: SxProps = {
  '& .MuiStepConnector-line': {
    display: 'none',
  },
  '& .MuiStep-root': {
    paddingBottom: '16px',
  },
  '& .MuiPaper-root': {
    boxShadow: 'none',
  },
};

export const StepperStepLabelSx: SxProps = {
  fontWeight: '700',
  fontSize: '20px',
  lineHeight: '22.8px',
};

export const AccordionSx: SxProps = {
  borderRadius: '8px',
  '& .Mui-expanded.MuiAccordionSummary-root': {
    backgroundColor: '#1314140A',
  },
};

export const AccordionDetailsSx: SxProps = {
  borderRight: `.5px solid ${theme.palette.colors.grayD5}`,
  borderLeft: `.5px solid ${theme.palette.colors.grayD5}`,
  borderRadius: '0 0 8px 8px',
};

export const AccordionContinueButtonStackSx: SxProps = {
  width: '100%',
  position: 'relative',
  pt: '24px',
};

export const AccordionContinueButtonSx: SxProps = {
  padding: '6px 16px',
  minWidth: '94px',
  height: '36px',
  '& .MuiTypography-root': {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    fontFamily: 'Inter',
  },
  '&#cancel-button': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  borderRadius: '50px',
  border: `1px solid ${theme.palette.colors.woodSmoke}`,
};

export const SectionHeaderSx: SxProps = {
  fontWeight: '700',
  fontSize: '20px',
  lineHeight: '24px',
  fontStyle: 'normal',
  fontFamily: 'PPPangramSans',
};

export const SubSectionHeaderSx: SxProps = {
  fontSize: '14px',
  lineHeight: '20.02px',
  color: 'colors.gray60',
};
