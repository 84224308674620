/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { nanoid } from '@reduxjs/toolkit';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import OptionInfoTooltip from '../OptionTooltip';
import { RadioOptionOuterSx, RadioOptionInputSx } from './styles';
import { RetrievalOptionValue } from 'pages/Retrieval/components/ReviewStep/components/RetrievalOptionValueForm/types';
import { RetrievalOptionInputProps } from 'pages/Retrieval/components/ReviewStep/components/RetrievalOptionValueInput/types';

const RadioOption = (props: RetrievalOptionInputProps) => {
  const getUniqueRadioKeys = (
    optionVal: RetrievalOptionValue,
    mapIndex: number
  ) => `radio-option--${optionVal.name}--${mapIndex}--${nanoid()}`;

  /**
   * @description sort function that arranges by index and accounts for TypeScript
   * not playing well with possibly undefined values (index)
   */
  const arrangeByIndex = (
    optionValueA: RetrievalOptionValue,
    optionValueB: RetrievalOptionValue
  ) => {
    const noIndexA = !('index' in optionValueA);
    const noIndexB = !('index' in optionValueB);

    if (noIndexA || noIndexB) return 0;

    const valueA = optionValueA?.index ?? 0;
    const valueB = optionValueB?.index ?? 0;

    if (valueA < valueB) return -1;
    if (valueA > valueB) return 1;
    return 0;
  };

  const getDefaultOptionValue = () =>
    props.retrievalOptionValues?.find((option) => option.selected)?.value ??
    null;

  const handleRadioButtonChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    const clickedOptionValue = props.retrievalOptionValues.find(
      (optionVal) => optionVal.value === value
    );

    if (!clickedOptionValue) return;

    props.answerRetrievalOption({
      optionId: props.id,
      optionName: props.name,
      optionValueId: clickedOptionValue.id,
      optionValue: value,
    });
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={RadioOptionOuterSx}
    >
      <Stack direction="row" justifyContent="flex-start" alignItems="center">
        <Typography>{props.prompt}</Typography>
        <OptionInfoTooltip description={props.description} />
      </Stack>
      <RadioGroup
        defaultValue={getDefaultOptionValue()}
        value={props.retrievalAnswer.optionValue}
        row={true}
        onChange={handleRadioButtonChange}
        sx={RadioOptionInputSx}
      >
        {props.retrievalOptionValues
          .slice()
          .sort(arrangeByIndex)
          .map((option, index) => {
            const radioOptionKey = getUniqueRadioKeys(option, index);

            return (
              <FormControlLabel
                key={radioOptionKey}
                label={option.name}
                value={option.value}
                control={<Radio />}
              />
            );
          })}
      </RadioGroup>
    </Stack>
  );
};

export default RadioOption;
