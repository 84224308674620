import React from 'react';
import { Widget } from '@typeform/embed-react';
import styles from './OscarDemo.module.css';

// This is a Demo page for Oscar to use when pitching to investors

//This form id value is needed to let typeform know which form to embed in our application
const OSCAR_DEMO_FORM_ID = 'https://p760lpl0krg.typeform.com/allwhereeng';

const OscarDemo = () => {
  return (
    <div id="oscar-demo" className={styles.content}>
      <Widget
        id={OSCAR_DEMO_FORM_ID}
        style={{ width: '100%', height: '100%' }}
        className="oscar-demo-form"
      />
    </div>
  );
};

export default OscarDemo;
