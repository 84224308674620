import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { CancelConfirmProps } from './types';
import {
  TEST_ID_CANCEL_CONFIRMATION_MAIN,
  TEST_ID_CANCEL_CONFIRMATION_BUTTON_GROUP,
} from './constants';
import {
  CancelDialogSx,
  CancelButtonGroupSx,
  CancelButtonsStackSx,
  CancelOuterStackSx,
  CancelMainTitleSx,
} from './styles';
import GAButton from 'components/buttons/google_analytics/GAButton';

const CancelConfirmation = (props: CancelConfirmProps) => {
  const handleCloseClick = () => props.onClose();
  const handleCancelClick = () => props.onCancel();

  return (
    <Dialog
      open={props.open}
      sx={CancelDialogSx}
      data-testid={TEST_ID_CANCEL_CONFIRMATION_MAIN}
      disableEscapeKeyDown
      onClose={handleCloseClick}
    >
      <Stack
        alignItems="flex-start"
        direction="column"
        justifyContent="space-between"
        sx={CancelOuterStackSx}
      >
        <Stack>
          <Typography variant="h5" sx={CancelMainTitleSx}>
            Are you sure you want to cancel?
          </Typography>
          <Typography>Your selections will not be saved.</Typography>
        </Stack>
        <Stack
          alignItems="flex-end"
          direction="row-reverse"
          sx={CancelButtonsStackSx}
        >
          <ButtonGroup
            aria-label="cancel-retrieval-step-button-group"
            data-testid={TEST_ID_CANCEL_CONFIRMATION_BUTTON_GROUP}
            disableRipple
            variant="text"
            sx={CancelButtonGroupSx}
          >
            <GAButton
              gaContext={{
                textCopy: 'Close',
                navigates_to: 'N/A',
                purpose: 'Closes cancel retrieval modal',
              }}
              onClick={handleCloseClick}
            >
              CLOSE
            </GAButton>
            <GAButton
              gaContext={{
                navigates_to: 'N/A',
                textCopy: 'Yes, Cancel',
                purpose: 'Cancels retrieval',
              }}
              onClick={handleCancelClick}
              variant="contained"
            >
              YES, CANCEL
            </GAButton>
          </ButtonGroup>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default CancelConfirmation;
