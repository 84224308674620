/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { HomeFactCardProps } from './types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import * as HomeStatCardStyles from './styles';

const HomeStatCard = (props: HomeFactCardProps) => {
  const styles = HomeStatCardStyles;

  return (
    <Grid
      id={`${props.baseId}-stat-card`}
      item
      flexDirection="column"
      justifyContent="flex-start"
      gap="2px"
      sx={styles.getHomePageStatCardGridContainerSx(
        props.cardBgColor,
        props.iconFill
      )}
    >
      <Grid item xs={12} flexDirection="row">
        <Typography sx={styles.HomePageStatCardTitleSx} color="colors.gray60">
          {props.displayText}
          <span style={styles.InfoSpanStyle}>
            <Tooltip title={props.tooltipText} placement="bottom" arrow>
              <InfoOutlinedIcon sx={styles.InfoIconSx} fontSize="small" />
            </Tooltip>
          </span>
        </Typography>
      </Grid>
      <Grid item xs={12} flexDirection="row">
        <Box
          sx={styles.HomePageStatCardIconBoxSx}
          className={`${props.baseId}-icon-box`}
        >
          {props.icon}
          {props.displayStat.length ? (
            <span className="display-stat">&nbsp;{props.displayStat}</span>
          ) : (
            <Skeleton variant="circular" sx={styles.HomePageStatSkeletonSx} />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default HomeStatCard;
