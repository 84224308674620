import React from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const CondenseIcon = () => {
  return (
    <ExpandLessIcon
      sx={{ color: 'colors.gray75', width: '24px', height: '24px' }}
    />
  );
};

export default CondenseIcon;
