export const POPOVER_MAX_HEIGHT_PAPER = '590px';
export const POPOVER_MAX_WIDTH_PAPER = '600px';
export const POPOVER_HEADER_HEIGHT = '120px';
export const POPOVER_TOP_BOX_HEIGHT = '64px';
export const ICON_TOP = '2px';
export const POPOVER_CONTENT_MAX_HEIGHT = `calc(${POPOVER_MAX_HEIGHT_PAPER} - ${POPOVER_HEADER_HEIGHT} - ${ICON_TOP})`;

export const ButtonBoxSx = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
  width: '100%',
};

export const IconTextSx = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: '600',
  top: ICON_TOP,
  '&.MuiTypography-root.MuiTypography-button': {
    position: 'relative',
    top: '1px',
  },
};

export const NewUploadsBubbleSx = (count: number) => ({
  backgroundColor: 'colors.maizeYO',
  height: '20px',
  position: 'relative',
  left: '5px',
  width: '20px',
  '&>.MuiTypography-root': {
    transform: `scale(${count > 9 ? 0.9 : 1})`,
  },
});

export const NewUploadsTextSx = {
  fontSize: '12px',
  fontWeight: 600,
  position: 'relative',
  color: 'colors.woodSmoke',
};

export const PopoverContainerSx = {
  display: 'flex',
  padding: '0',
  margin: '0',
  height: POPOVER_MAX_HEIGHT_PAPER,
};

export const PopoverPaperContainerSx = {
  display: 'flex',
  mt: '10px',
  width: POPOVER_MAX_WIDTH_PAPER,
  minHeight: '610px',
};

export const ContainerSx = {
  display: 'flex',
  height: '100%',
};

export const UploadPopoverContainer = {
  height: '30px',
  borderRadius: '30px',
  pl: '10px',
  pr: '10px',
  pt: '2px',
  pb: '2px',
  ':hover': {
    backgroundColor: 'rgba(19, 20, 20, 0.04)',
  },
};
