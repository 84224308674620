import {
  INPUT,
  DEVICE_FILTER,
} from 'pages/Assets/components/DevicesDatagrid/components/DeviceFilters/constants';

export const DEPOT = 'Depot';

export const DeviceTypeFilterSettings = {
  placeholder: `${DEPOT} location`,
  label: `${DEPOT} location`,
  labelId: `${DEVICE_FILTER}depot-${INPUT}-label`,
  inputId: `${DEVICE_FILTER}depot-${INPUT}`,
};
