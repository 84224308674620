import { gql } from '@apollo/client';

export const FETCH_RETRIEVAL_ORIGINS = gql`
  query retrievalOrigins {
    retrievalOrigins {
      id
      index
      countryName
      retrievalDepots {
        depotId
        name
        retrievalRouteId
        allowCustomDepot
      }
    }
  }
`;
