import { SxProps } from '@mui/material';
import theme from 'themes/theme';
import { ColorProps } from 'types';

const DEFAULT_NOTIFICATION_TEXT_COLOR = theme.palette.colors.white;
const DEFAULT_NOTIFICATION_BG_COLOR = theme.palette.colors.notificationBG;

export const getGlobalNotificationSx = (colorProps: ColorProps): SxProps => {
  const { backgroundColor: bgColor, textColor } = colorProps;

  return {
    mt: '80px',
    '& .MuiPaper-root.MuiSnackbarContent-root': {
      ...(bgColor && { backgroundColor: bgColor }),
    },
    '& .MuiSnackbarContent-message': {
      ...(textColor && { color: textColor }),
    },
  };
};
