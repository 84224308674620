import { SxProps } from '@mui/material';

export const CSVTextSx = (loading: boolean): SxProps => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: '600',
  position: 'relative',
  color: loading ? 'gray' : 'black',
});

export const LoadingSx = (loading: boolean) => ({
  height: '30px',
  borderRadius: '30px',
  border: 'none',
  pl: '10px',
  pr: '10px',
  pt: '2px',
  pb: '2px',
  backgroundColor: loading ? 'rgba(19, 20, 20, 0.12)' : 'inherit',
  '&:hover': !loading
    ? {
        backgroundColor: 'rgba(19, 20, 20, 0.04)',
        color: 'white',
      }
    : {
        cursor: 'not-allowed',
      },
});

export const LoadingSpinnerSx = {
  color: 'gray',
};
