/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import * as GenericVerticalStepperStyles from './styles';
import { GenericVerticalStepperProps } from './types';

const GenericVerticalStepper = <T extends {}>(
  props: GenericVerticalStepperProps<T>
) => {
  if (!props.steps.length) return null;
  const styles = GenericVerticalStepperStyles;

  const mainTitleOverrideSx = {
    ...props.mainTitleSx,
    ...styles.StepperMainTitleSx,
  };

  const stepperBoxParamsSx = {
    stepConnectorSx: props?.stepConnectorSx ?? {},
    stepLabelSx: props?.stepLabelSx ?? {},
  };

  const stepperBoxOverrideSx = {
    ...styles.getStepperBoxSx({ ...stepperBoxParamsSx }),
    ...(props?.stepperBoxSx ?? {}),
  };

  const renderSteps = () => props.renderStepsFunction(props.steps);

  return (
    <Stack direction="column">
      <Typography sx={mainTitleOverrideSx}>{props.stepperTitle}</Typography>
      <Box sx={stepperBoxOverrideSx}>
        <Stepper
          activeStep={props?.activeStep ?? undefined}
          orientation="vertical"
        >
          {renderSteps()}
        </Stepper>
      </Box>
    </Stack>
  );
};

export default GenericVerticalStepper;
