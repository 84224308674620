import { POPOVER_MAX_HEIGHT_PAPER } from 'components/columns/ColumnSelect/styles';
import {
  POPOVER_MAX_WIDTH_PAPER,
  POPOVER_TOP_BOX_HEIGHT,
} from '../../UploadPopover/styles';

export const DETAILS_CONTAINER_HEIGHT = `calc(${POPOVER_MAX_HEIGHT_PAPER} - ${POPOVER_TOP_BOX_HEIGHT})`;
export const DETAILS_HEADER_HEIGHT = '150PX';
export const DETAILS_TABLE_HEIGHT = `calc(${DETAILS_CONTAINER_HEIGHT} - ${DETAILS_HEADER_HEIGHT})`;

export const UploadErrorsContainerSx = {
  display: 'flex',
  width: POPOVER_MAX_WIDTH_PAPER,
  height: DETAILS_CONTAINER_HEIGHT,
  overflow: 'auto',
  px: '24px',
};
