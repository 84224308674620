import theme from 'themes/theme';

export const UploadHistoryItemContentSx = {
  width: 'auto',
  border: '1px solid #E0E0E0',
  borderRadius: '10px',
  gap: '8px',
  display: 'flex',
  alignItems: 'center',
  py: '8px',
  my: '4px',
};

export const UploadHistoryItemInfoSx = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  px: '8px',
};

export const UploadHistoryItemInfoRowSx = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  px: '8px',
  gap: '50px',
};

export const UploadHistoryItemFileNameSx = {
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '28px',
  letterSpacing: '0.15px',
  maxWidth: '350px',
};

export const UploadHistoryItemErrorsButtonTextSx = {
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '20.02px',
  font: 'Inter, PPPangramSans',
  color: theme.palette.colors.chiGong,
};

export const UploadHistoryItemStatusBoxSx = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  gap: '4px',
};
