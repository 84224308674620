import { gql } from '@apollo/client';

export const UPDATE_ORDER_NOTIFICATIONS_FLAG = gql`
  mutation updateOrder($id: ID!, $sendOrderNotifications: Boolean) {
    updateOrder(
      orderUpdateInput: {
        id: $id
        sendOrderNotifications: $sendOrderNotifications
      }
    ) {
      id
      sendOrderNotifications
    }
  }
`;

export const SEND_NOTIFICATION = gql`
  mutation sendNotification($recordId: ID!, $notificationTypeId: ID!) {
    sendNotification(
      notificationSendInput: {
        recordId: $recordId
        notificationTypeId: $notificationTypeId
      }
    ) {
      requestSent
    }
  }
`;

// TODO: Remove logic when the feature flag is removed
export const DEPRECATED_SEND_NOTIFICATION = gql`
  mutation sendOrderNotification($orderId: ID!, $orderNotificationTypeId: ID!) {
    sendOrderNotification(
      orderNotificationSendInput: {
        orderId: $orderId
        orderNotificationTypeId: $orderNotificationTypeId
      }
    ) {
      requestSent
    }
  }
`;
