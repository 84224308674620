import { createSlice } from '@reduxjs/toolkit';

import {
  getOrdersPendingReducer,
  getOrdersFulfilledReducer,
  getOrdersRejectedReducer,
  getOrdersDirectPendingReducer,
  getOrdersDirectFulfilledReducer,
  getOrdersDirectRejectedReducer,
  setOrdersCSVDownloadURLReducer,
} from './reducers';
import { getOrdersCSV, getOrdersCSVDirect } from './actions';
import { OrdersCSVInitialState } from './types';

const initialState: OrdersCSVInitialState = {
  loadingState: 'idle',
  data: '',
  url: '',
  errorMessage: '',
};

export const ordersCSVSlice = createSlice({
  name: 'ordersCSVSlice',
  initialState,
  reducers: {
    setOrdersCSVDownloadURLReducer,
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getOrdersCSV.pending, getOrdersPendingReducer);
    builder.addCase(getOrdersCSV.fulfilled, getOrdersFulfilledReducer);
    builder.addCase(getOrdersCSV.rejected, getOrdersRejectedReducer);
    builder.addCase(getOrdersCSVDirect.pending, getOrdersDirectPendingReducer);
    builder.addCase(
      getOrdersCSVDirect.fulfilled,
      getOrdersDirectFulfilledReducer
    );
    builder.addCase(
      getOrdersCSVDirect.rejected,
      getOrdersDirectRejectedReducer
    );
  },
});

export const {
  resetState,
  setOrdersCSVDownloadURLReducer: setOrdersCSVDownloadURL,
} = ordersCSVSlice.actions;
