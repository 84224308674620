export const BILLING_CHECKBOX = {
  USE_ACCOUNT: 'USE_ACCOUNT',
  CONSENT_USE: 'CONSENT_USE',
};

export const BILLING_MODAL_COPY = {
  MAIN_TEXT: 'Set up automatic billing!',
  SUB_TEXT:
    'Stay up to date with your invoices by entering your bank account information for future billing. Your account will be automatically charged when your invoice is ready.',
  USE_CHECKBOX_TEXT: 'Use this account information in the future',
  USE_CHECKBOX_SUB_TEXT: 'We will store this information securely',
  CONSENT_CHECKBOX_TEXT:
    'I acknowledge that I have reviewed, understood, and agreed to this authorization. I authorize allwhere to initiate ACH debits and credits to the business bank account indicated above, provided each transaction is initiated according to the terms of the agreement.',
};
