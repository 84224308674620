import {
  GridCallbackDetails,
  GridCellParams,
  GridPaginationModel,
  GridRowSelectionModel,
  GridScrollParams,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import DataGrid from 'components/DataGrid';
import CustomGridToolbar from 'components/CustomGridToolbar';
import React from 'react';
import { CollaboratorDataGridProps } from './type';
import { Collaborator } from 'types';
import { DataGridSx } from 'components/DataGrid/styles';
import {
  GENERIC_PAGE_SIZE_OPTIONS,
  GRID_SORT_FIELD_TO_SERVER_TABLE,
  SortDirection,
} from 'global-constants';
import { useGetCollaboratorColumns } from 'hooks/useGetCollaboratorColumns';
import { DEFAULT_COLLABORATOR_SORT_ORDER } from '../../constants';
import { Box } from '@mui/material';

export const CollaboratorDataGrid = (props: CollaboratorDataGridProps) => {
  const apiRef = useGridApiRef();

  const [selectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>(
      () =>
        props.selectedCollaborators.map(
          (collaborator: Collaborator) => collaborator.id
        ) as GridRowSelectionModel
    );

  const collaboratorColumns = useGetCollaboratorColumns(selectionModel);

  const onRowSelectionModelChange = (ids: GridRowSelectionModel) => {
    if (selectionModel.length < ids.length) {
      const newId = ids.find((id) => !selectionModel.includes(id));
      const collaborator = props.collaboratorsData.collaborators.find(
        (collaborator) => collaborator.id === newId
      ) as Collaborator;
      props.handleAddSelectedCollaborator(collaborator);
    } else if (selectionModel.length > ids.length) {
      const removedId = selectionModel.find((id) => !ids.includes(id));
      const collaborator = props.collaboratorsData.collaborators.find(
        (collaborator) => collaborator.id === removedId
      ) as Collaborator;
      props.handleRemoveSelectedCollaborator(collaborator);
    }
    setSelectionModel(ids);
  };

  const handleOnCellClick = (params: GridCellParams) => {
    const currentGridScrollIndex = apiRef.current?.getScrollPosition();
    props.handleUpdateCurrentGridScrollIndex(currentGridScrollIndex);
    if (selectionModel.includes(params.id)) {
      onRowSelectionModelChange(
        selectionModel.filter((id) => id !== params.id)
      );
    } else {
      onRowSelectionModelChange([...selectionModel, params.id]);
    }
  };

  const handleOnPaginationModelChange = (model: GridPaginationModel) => {
    let newPaginationModel;
    if (model.pageSize !== props.paginationModel.pageSize) {
      newPaginationModel = { ...model, page: 0 };
    } else {
      newPaginationModel = model;
    }
    props.handleSetCollaboratorPagination(
      newPaginationModel.page,
      model.pageSize
    );
  };

  const handleSortModelChange = (
    sortModel: GridSortModel,
    _callbackDetails: GridCallbackDetails
  ): void => {
    if (!sortModel.length) {
      props.handleSetCollaboratorSortOrder(DEFAULT_COLLABORATOR_SORT_ORDER);
      return;
    }
    const { field, sort: sortDirection } = sortModel[0];

    const serverSortDirection = GRID_SORT_FIELD_TO_SERVER_TABLE[
      sortDirection as keyof typeof GRID_SORT_FIELD_TO_SERVER_TABLE
    ] as SortDirection;

    props.handleSetCollaboratorSortOrder({
      direction: serverSortDirection,
      field,
    });
  };

  // TODO: this is not ideal and should be removed when a better solution is found. Since the datagrid needs to update state of parent components,
  // react rerenders the datagrid every time an update happens (ie. cell is clicked). This causes the data grid to scroll to the top every time
  // This logic is in place to scroll to the last position after the datagrid rerenders. However, it's not ideal to directly use the api
  // to manipulate the datagrid as this solution is hacky
  React.useEffect(() => {
    if (!props.renderGridScrollIndex) return;
    Promise.resolve().then(() =>
      apiRef?.current?.scroll(
        props.renderGridScrollIndex as Partial<GridScrollParams>
      )
    );
  }, [props.renderGridScrollIndex]);

  return (
    <Box sx={{ height: 'calc(100vh - 240px)', position: 'relative' }}>
      <DataGrid
        apiRef={apiRef}
        autoHeight={false}
        pagination
        paginationMode="server"
        filterMode="server"
        disableRowSelectionOnClick
        customToolbar={CustomGridToolbar}
        rows={props.collaboratorsData.collaborators}
        rowCount={props.collaboratorsData.count}
        rowSelectionModel={selectionModel}
        columns={collaboratorColumns}
        loading={props.loadingCollaborators}
        paginationModel={props.paginationModel}
        onPaginationModelChange={handleOnPaginationModelChange}
        pageSizeOptions={GENERIC_PAGE_SIZE_OPTIONS}
        onSortModelChange={handleSortModelChange}
        onCellClick={handleOnCellClick}
        sortingMode="server"
        disableColumnPinning
        disableColumnReorder
        disableColumnSelector
        disableMultipleColumnsSorting
        disableMultipleRowSelection
        hideFooterSelectedRowCount
        keepNonExistentRowsSelected
        checkboxSelection
        slotProps={{
          panel: {
            placement: 'bottom-end',
            sx: DataGridSx,
          },
          filterPanel: {
            sx: { visibility: 'hidden' },
          },
        }}
      />
    </Box>
  );
};
