import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer';
import OrdersSearchState, { OrdersSearchStateKeys } from './types';

// resetLookAheadOptions
export const resetComboSearchReducer = (
  state: WritableDraft<OrdersSearchState>
) => {
  state.lookAheadOption = '';
};

// resetAllFilters
export const resetNonComboSearchReducer = (
  state: WritableDraft<OrdersSearchState>
) => {
  state.orderNumberFilter = '';
  state.orderTypeFilter = '';
  state.statusFilter = '';
};

export const setLookAheadOptionReducer = (
  state: WritableDraft<OrdersSearchState>,
  action: PayloadAction<{
    optionType: string;
    optionValue: string;
  }>
) => {
  state.lookAheadOptionType = action.payload.optionType;
  state.lookAheadOption = action.payload.optionValue;
};

export const setSearchFilterByKeyReducer = (
  state: WritableDraft<OrdersSearchState>,
  action: PayloadAction<{ key: OrdersSearchStateKeys; value: string }>
) => {
  state[action.payload.key] = action.payload.value;
};

export const clearSearchFilterByKeyReducer = (
  state: WritableDraft<OrdersSearchState>,
  action: PayloadAction<OrdersSearchStateKeys>
) => {
  const resetValue =
    action.payload === 'lookAheadOptionType' ? 'orderNumber' : '';
  state[action.payload] = resetValue;
};
