import React from 'react';
import { Menu, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { OrderManager } from 'types';
import { OrderManagerRadioGroupSx, OrderManagerRadioSx } from './styles';
import { OrderManagerMenuProps } from './types';

const OrderManagerMenu = (props: OrderManagerMenuProps) => {
  return (
    <Menu open={props.open} anchorEl={props.anchorEl?.current}>
      <RadioGroup name="order-manager" sx={OrderManagerRadioGroupSx}>
        {props.orderManagers.map((orderManager: OrderManager) => (
          <FormControlLabel
            key={orderManager.id}
            value={orderManager.id}
            htmlFor={orderManager.id}
            label={`${orderManager.firstName} ${orderManager.lastName}`}
            control={
              <Radio
                id={orderManager.id}
                sx={OrderManagerRadioSx}
                checked={orderManager?.id === props.assignedOrderManagerId}
              />
            }
          />
        ))}
      </RadioGroup>
    </Menu>
  );
};

export default OrderManagerMenu;
