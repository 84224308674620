import { SxProps } from '@mui/material';

export const ProductDetailsContainerSx: SxProps = {
  alignItems: 'flex-start',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  padding: '2rem 4.75rem',
  gap: '5rem',
  overflowY: {
    lg: 'scroll',
  },
  height: {
    xs: '100vh',
    lg: '85vh',
  },
  '::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    width: '7px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: 'rgba(0,0,0,.5)',
    WebkitBoxShadow: '0 0 1px rgba(255,255,255,.5)',
  },
};

export const DividerSx: SxProps = {
  borderBottom: '0',
  borderLeft: '0',
  borderRight: '0',
  borderTop: '2px solid #f9c54d',
  marginLeft: '0',
  marginTop: '5px',
  width: '81px',
};

// This is not type `SxProps` because textarea component does not accept `sx` props
export const ProductDescriptionSx: React.CSSProperties = {
  border: 'none',
  color: '#122023',
  outline: 'none',
  resize: 'none',
  width: '100%',
};

export const AddToOrderButtonSx: SxProps = {
  '&.Mui-disabled': {
    backgroundColor: '#B7B7B7',
    color: '#575757',
  },
};

export const ProductQuantityInputSx: SxProps = {
  backgroundColor: 'transparent',
  width: '100px',
  p: 0,
  '&: .MuiFormHelperText-root': {
    color: 'transparent',
    position: 'absolute',
    bottom: '-20px',
  },
};
