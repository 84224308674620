import moment from 'moment';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { GridColDef, GridColumnHeaderTitle } from '@mui/x-data-grid-pro';
import {
  filterOperators,
  INVALID_DATE,
  NON_APPLICABLE,
  DEVICES_HEADER_TOOLTIP_COPY,
} from './constants';
import { FeatureFlagNames } from 'enums';
import useFeatureFlagService from 'hooks/useFeatureFlagService';

export const getEmployeeStepColumns = (
  isLargeScreen: boolean
): GridColDef[] => {
  const { isFlagOff: isEmployeeEmailOptionsDisabled } = useFeatureFlagService(
    FeatureFlagNames.EMPLOYEE_EMAIL_OPTIONS,
    {
      debugFlags: true,
    }
  );

  const employeeStepColumns: GridColDef[] = [
    {
      field: 'lastName',
      headerName: 'Last name',
      minWidth: 200,
      filterOperators,
      sortable: false,
    },
    {
      field: 'firstName',
      headerName: 'First name',
      minWidth: 200,
      filterOperators,
      sortable: false,
    },
    {
      field: 'workEmail',
      headerName: 'Work email',
      minWidth: 220,
      filterOperators,
      sortable: false,
    },
    {
      field: 'personalEmail',
      headerName: 'Personal email',
      minWidth: 220,
      filterOperators,
      sortable: false,
    },
    {
      field: 'assets',
      minWidth: isLargeScreen ? 300 : 200,
      filterable: false,
      sortable: false,
      renderHeader: () => {
        return (
          <>
            <GridColumnHeaderTitle label="Devices" columnWidth={0} />
            &nbsp;
            <Tooltip title={DEVICES_HEADER_TOOLTIP_COPY}>
              <InfoOutlinedIcon />
            </Tooltip>
          </>
        );
      },
      renderCell: (data: any) => {
        const assets = data.value;
        return (
          <List disablePadding>
            {assets.map((asset: any) => (
              <ListItem key={asset.id} sx={{ height: '50px', padding: '0' }}>
                <Typography
                  variant="body2"
                  sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {asset.make} {asset.model}
                </Typography>
              </ListItem>
            ))}
          </List>
        );
      },
    },
    {
      field: 'displaySize',
      headerName: 'Display size',
      minWidth: 200,
      filterable: false,
      sortable: false,
      valueGetter: (params) => {
        return params.row.assets;
      },
      renderCell: (data: any) => {
        const assets = data.value;
        return (
          <List disablePadding>
            {assets.map((asset: any) => (
              <ListItem key={asset.id} sx={{ height: '50px', padding: '0' }}>
                <Typography
                  variant="body2"
                  sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {asset.displaySize}
                </Typography>
              </ListItem>
            ))}
          </List>
        );
      },
    },
    {
      field: 'serialNumber',
      headerName: 'Serial Number',
      minWidth: 200,
      filterable: false,
      sortable: false,
      valueGetter: (params) => {
        return params.row.assets;
      },
      renderCell: (data: any) => {
        const assets = data.value;
        return (
          <List disablePadding>
            {assets.map((asset: any) => (
              <ListItem key={asset.id} sx={{ height: '50px', padding: '0' }}>
                <Typography variant="body2" noWrap>
                  {asset.serialNumber}
                </Typography>
              </ListItem>
            ))}
          </List>
        );
      },
    },
    {
      field: 'assetID',
      headerName: 'Asset ID',
      minWidth: 200,
      filterable: false,
      sortable: false,
      valueGetter: (params) => {
        return params.row.assets;
      },
      renderCell: (data: any) => {
        const assets = data.value;
        return (
          <List disablePadding>
            {assets.map((asset: any) => (
              <ListItem key={asset.id} sx={{ height: '50px', padding: '0' }}>
                <Typography variant="body2" noWrap>
                  {asset.id}
                </Typography>
              </ListItem>
            ))}
          </List>
        );
      },
    },
    {
      field: 'memory',
      headerName: 'Memory',
      minWidth: 200,
      filterable: false,
      sortable: false,
      valueGetter: (params) => {
        return params.row.assets;
      },
      renderCell: (data: any) => {
        const assets = data.value;
        return (
          <List disablePadding>
            {assets.map((asset: any) => (
              <ListItem key={asset.id} sx={{ height: '50px', padding: '0' }}>
                <Typography variant="body2" noWrap>
                  {asset.memory}
                </Typography>
              </ListItem>
            ))}
          </List>
        );
      },
    },
    {
      field: 'storage',
      headerName: 'Storage',
      minWidth: 200,
      filterable: false,
      sortable: false,
      valueGetter: (params) => {
        return params.row.assets;
      },
      renderCell: (data: any) => {
        const assets = data.value;
        return (
          <List disablePadding>
            {assets.map((asset: any) => (
              <ListItem key={asset.id} sx={{ height: '50px', padding: '0' }}>
                <Typography variant="body2" noWrap>
                  {asset.storage}
                </Typography>
              </ListItem>
            ))}
          </List>
        );
      },
    },
    {
      field: 'dateOfLastCheckOut',
      headerName: 'Date issued',
      minWidth: 200,
      filterable: false,
      sortable: false,
      valueGetter: (params) => {
        return params.row.assets;
      },
      renderCell: (data: any) => {
        const assets = data.value;
        return (
          <List disablePadding>
            {assets.map((asset: any) => {
              const formattedDateIssued = moment(asset.dateIssued).format(
                'lll'
              );
              const dateIssued =
                formattedDateIssued === INVALID_DATE || !asset.dateIssued
                  ? NON_APPLICABLE
                  : formattedDateIssued;
              return (
                <ListItem key={asset.id} sx={{ height: '50px', padding: '0' }}>
                  <Typography variant="body2" noWrap>
                    {dateIssued}
                  </Typography>
                </ListItem>
              );
            })}
          </List>
        );
      },
    },
  ];

  if (isEmployeeEmailOptionsDisabled()) {
    const emailColumn = {
      field: 'email',
      headerName: 'Contact Email',
      minWidth: 200,
      filterOperators,
      sortable: false,
    };
    employeeStepColumns.splice(2, 2, emailColumn);
  }
  return employeeStepColumns;
};
