/* eslint-disable sonarjs/no-identical-expressions */
import React from 'react';
import { Box, Modal as MuiModal, SxProps, Typography } from '@mui/material';
import {
  ModalBodyProps,
  ModalFooterProps,
  ModalHeaderProps,
  ModalProps,
} from './types';
import styles from './Modal.module.css';

const baseModalBoxSX: SxProps = {
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  left: '50%',
  outline: 'none',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '800px',
};

const Modal = (props: ModalProps) => {
  const fallbackOnClose = () => {
    console.log('triggered Fallback handle Close function');
  };

  return (
    <MuiModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      data-test-id="modal-mui-body"
      open={props.open}
      onClose={props?.onClose ?? fallbackOnClose}
      sx={{ ...props.modalSXProps }}
    >
      <Box
        sx={{
          ...baseModalBoxSX,
          ...(!!props.modalBoxSXProps && props.modalBoxSXProps),
        }}
      >
        {props.children}
      </Box>
    </MuiModal>
  );
};

const Header = (props: ModalHeaderProps) => {
  const baseHeaderSX = {
    paddingLeft: '40px',
    paddingTop: '40px',
    '& .Mui-Typography': {
      pb: '24px',
    },
  };

  const baseHeaderCopySX = {
    '& hr': {
      borderBottom: 0,
      borderLeft: 0,
      borderRight: 0,
      borderTop: '2px solid #f9c54d',
      ml: 0,
      width: '81px',
    },
  };

  return (
    <Box
      data-test-id="modal-header-main"
      sx={{
        ...baseHeaderSX,
        ...(!!props.ModalHeaderSXProps && props.ModalHeaderSXProps),
      }}
    >
      <Typography
        variant="h5"
        sx={{
          ...baseHeaderCopySX,
          ...(props.ModalHeaderLabelSXProps && props.ModalHeaderLabelSXProps),
        }}
      >
        {props.children}
        {props.hideHeaderDivider ? null : <hr />}
      </Typography>
    </Box>
  );
};

Modal.Header = Header;

const Body = (props: ModalBodyProps) => {
  const baseBodySX = {
    mb: '3.5rem',
    p: '0 40px',
  };

  return (
    <Box
      sx={{
        ...baseBodySX,
        ...(props.ModalBodySXProps && props.ModalBodySXProps),
      }}
    >
      {props.children}
    </Box>
  );
};

Modal.Body = Body;

const Footer = (props: ModalFooterProps) => (
  <div className={styles.footer}>{props.children}</div>
);
Modal.Footer = Footer;

export default Modal;
