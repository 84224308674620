import ColumnSelect from 'components/columns/ColumnSelect';
import {
  useGetConfig,
  useUpdateColumnsLocalStorage,
  useLoadInitialStateFromLocalStorage,
} from './hooks';

const AssetsColumnSelect = () => {
  const config = useGetConfig();
  useUpdateColumnsLocalStorage();
  useLoadInitialStateFromLocalStorage();
  return <ColumnSelect {...config} />;
};

export default AssetsColumnSelect;
