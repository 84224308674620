import React from 'react';
import { useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';

import { selectGlobalNotificationBanner } from 'store/slices/global/selectors';

import * as styles from './styles';

const NotificationBanner = () => {
  const globalNotificationBanner = useSelector(selectGlobalNotificationBanner);
  if (!globalNotificationBanner.show) return null;
  return (
    <Stack sx={styles.NotificationBannerContainerSx}>
      {globalNotificationBanner.content}
    </Stack>
  );
};

export default NotificationBanner;
