import { gql } from '@apollo/client';

export const CREATE_ORDER = gql`
  mutation createOrder(
    $purchaserId: ID!
    $orderTypeId: ID!
    $organizationId: ID!
  ) {
    createOrder(
      createOrderInput: {
        purchaserId: $purchaserId
        orderTypeId: $orderTypeId
        organizationId: $organizationId
      }
    ) {
      id
      orderNumber
      status
      createdAt
      dueDate
      orderType {
        id
        name
      }
      purchaser {
        id
        firstName
        lastName
        email
      }
      organizationObject {
        id
        name
      }
      orderType {
        id
        name
      }
    }
  }
`;
