import { Theme } from '@mui/material';
import { ShipmentStatusEnum } from 'enums';
import { capitalizeWords } from 'services';

export const getShipmentStatusConfig = (
  shipmentStatus: ShipmentStatusEnum,
  theme: Theme
) => {
  const textColor = theme.palette.colors.white;
  const hoverText = '';
  const configTable = {
    [ShipmentStatusEnum.ATTEMPT_FAILED]: {
      text: capitalizeWords(ShipmentStatusEnum.ATTEMPT_FAILED),
      color: theme.palette.colors.burntSiennaR,
      textColor,
      hoverText,
    },
    [ShipmentStatusEnum.AVAILABLE_FOR_PICKUP]: {
      text: capitalizeWords(ShipmentStatusEnum.AVAILABLE_FOR_PICKUP),
      color: theme.palette.colors.pelorous,
      textColor,
      hoverText,
    },
    [ShipmentStatusEnum.CREATED]: {
      text: capitalizeWords(ShipmentStatusEnum.CREATED),
      color: theme.palette.colors.pelorous,
      textColor,
      hoverText,
    },
    [ShipmentStatusEnum.DELIVERED]: {
      text: capitalizeWords(ShipmentStatusEnum.DELIVERED),
      color: theme.palette.colors.goldFusion,
      textColor,
      hoverText,
    },
    [ShipmentStatusEnum.EXCEPTION]: {
      text: capitalizeWords(ShipmentStatusEnum.EXCEPTION),
      color: theme.palette.colors.pelorous,
      textColor,
      hoverText,
    },
    [ShipmentStatusEnum.EXPIRED]: {
      text: capitalizeWords(ShipmentStatusEnum.EXPIRED),
      color: theme.palette.colors.jaffa,
      textColor,
      hoverText,
    },
    [ShipmentStatusEnum.INFO_RECEIVED]: {
      text: capitalizeWords(ShipmentStatusEnum.INFO_RECEIVED),
      color: theme.palette.colors.pelorous,
      textColor,
      hoverText,
    },
    [ShipmentStatusEnum.IN_TRANSIT]: {
      text: capitalizeWords(ShipmentStatusEnum.IN_TRANSIT),
      color: theme.palette.colors.pelorous,
      textColor,
      hoverText,
    },
    [ShipmentStatusEnum.OUT_FOR_DELIVERY]: {
      text: capitalizeWords(ShipmentStatusEnum.OUT_FOR_DELIVERY),
      color: theme.palette.colors.pelorous,
      textColor,
      hoverText,
    },
    [ShipmentStatusEnum.PENDING]: {
      text: capitalizeWords(ShipmentStatusEnum.PENDING),
      color: theme.palette.colors.pelorous,
      textColor,
      hoverText,
    },
  };

  return configTable[shipmentStatus];
};
