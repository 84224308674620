import React from 'react';
import { SVGProps } from './types';

const GiftSVGIcon = (props: SVGProps) => {
  const originalWidth = 48;
  const originalHeight = 52;
  const colorFill = props?.fill ?? 'white';
  const width = props?.width ?? originalWidth;
  const height = props?.height ?? originalHeight;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9901 15.8399C17.1801 19.4699 15.6601 23.8999 15.6601 28.5699V32.9899H18.4101V28.5699C18.4101 24.1599 19.9801 19.9899 22.8401 16.6999V48.2999H6.6101C4.8101 48.2999 3.3501 46.8399 3.3501 45.0399V19.0999C3.3501 17.2999 4.8101 15.8399 6.6101 15.8399H19.9901ZM13.9701 7.49989C13.9701 5.36989 15.7001 3.63989 17.8301 3.63989C19.9601 3.63989 21.6901 5.36989 21.6901 7.49989V11.3599H17.8301C15.7001 11.3599 13.9701 9.62989 13.9701 7.49989ZM26.7201 7.49989C26.7201 5.36989 28.4501 3.63989 30.5801 3.63989C32.7101 3.63989 34.4401 5.36989 34.4401 7.49989C34.4401 9.62989 32.7101 11.3599 30.5801 11.3599H26.7201V7.49989ZM41.8101 15.8399C43.6101 15.8399 45.0701 17.2999 45.0701 19.0999V45.0399C45.0701 46.8399 43.6101 48.2999 41.8101 48.2999H25.5801V16.6999C28.4401 19.9999 30.0101 24.1599 30.0101 28.5699V32.9899H32.7601V28.5699C32.7601 23.8999 31.2301 19.4699 28.4301 15.8399H41.8101ZM0.600098 19.0999V45.0399C0.600098 48.3499 3.2901 51.0499 6.6001 51.0499H41.8001C45.1101 51.0499 47.8101 48.3599 47.8101 45.0399V19.0999C47.8101 15.7899 45.1201 13.0899 41.8001 13.0899H34.1001C35.9501 11.9199 37.1901 9.85989 37.1901 7.49989C37.1901 3.84989 34.2301 0.889893 30.5801 0.889893C27.5301 0.889893 24.9701 2.95989 24.2001 5.76989C23.4401 2.95989 20.8801 0.889893 17.8201 0.889893C14.1701 0.889893 11.2101 3.84989 11.2101 7.49989C11.2101 9.84989 12.4401 11.9199 14.3001 13.0899H6.6001C3.2901 13.0899 0.600098 15.7799 0.600098 19.0999Z"
        fill={colorFill}
      />
    </svg>
  );
};

export default GiftSVGIcon;
