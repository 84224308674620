import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  GENERIC_PAGE_SIZE_OPTIONS,
  GRID_SORT_FIELD_TO_SERVER_TABLE,
  SortDirection,
  DEFAULT_PAGE_SIZE,
  OrderStatus,
} from 'global-constants';
import {
  GridPaginationModel,
  GridRowId,
  GridSortModel,
  GridRowParams,
  GridCellParams,
  GridTreeNode,
} from '@mui/x-data-grid-pro';
import DataGrid from 'components/DataGrid';
import { DataGridSx } from 'components/DataGrid/styles';
import { useAdminModePrompt } from 'hooks/useAdminModePrompt';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import { FeatureFlagNames, ShipmentStatusEnum } from 'enums';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import {
  DEFAULT_ORDERS_DASH_SORT_MODEL,
  ORDER_INPUT_FIELDS,
} from 'pages/Orders/constants';
import { NewOrderItem, OrderType } from 'types';
import { OrdersDatagridProps } from './types';
import {
  GET_ORDER_ITEMS_BY_ORDER_ID,
  GET_RETRIEVAL_ORDER_TYPES,
  SEARCH_ORDERS,
  SEARCH_ORDERS_V2,
} from './queries';
import { useGetOrdersColumns } from './hooks/useGetOrdersColumns';
import CondenseIcon from './components/CondenseIcon';
import ExpandIcon from './components/ExpandIcon';
import ExpandedRowOrderItemDisplay from './components/ExpandedRowOrderItemDisplay';
import useGetOrdersDataGridState from 'pages/Orders/hooks/useGetOrdersDataGridState';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import { selectOrderSearchState } from 'store/slices/orders/search/selectors';
import {
  selectAllChosenEntries,
  selectIsFiltered,
} from 'store/shared/selectors';
import { getWhereClauseFromFilterEntries } from './utils';
import { orderFilterActions } from 'store/slices/orders/filter';
import { resetComboSearch } from 'store/slices/orders/search';

const OrdersDatagrid = (props: OrdersDatagridProps) => {
  const { isFlagOn: isNewFilterEnabled } = useFeatureFlagService(
    FeatureFlagNames.COMPLEX_CLIENT_ORDERS_FILTERING,
    {
      debugFlags: true,
    },
  );
  const {
    apiRef,
    paginationModel,
    setPaginationModel,
    ordersSort,
    setOrdersSort,
  } = useGetOrdersDataGridState();
  const userOrganization = useGetUserOrganization();
  const hasUserOrg = Boolean(userOrganization?.id) ?? false;
  const orderSearchState = useSelector(selectOrderSearchState);
  const hasOrderFilters = useSelector(selectIsFiltered('orders'));
  const selectedFilterEntries = useSelector(selectAllChosenEntries('orders'));
  const complexWhereClause = getWhereClauseFromFilterEntries(
    selectedFilterEntries,
  );
  const {
    orderTypeFilter,
    statusFilter,
    orderNumberFilter,
    lookAheadOptionType,
    lookAheadOption,
  } = orderSearchState;

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const viewParam = searchParams?.get('view') ?? 'ALL';

  const { isFlagOn: hasAccessOrderDetailsPage } = useFeatureFlagService(
    FeatureFlagNames.ORDER_ITEMS_DETAILS_VIEW,
  );

  const hasOrderDetailsPagePermission = hasAccessOrderDetailsPage();

  // Row Hovering
  const [rowHoveringModel, setRowHoveringModel] = React.useState(() => '');

  const handleOnRowOver = (event: any) => {
    const rowId = event.currentTarget.getAttribute('data-id');
    if (!rowId) {
      console.warn(
        'Row id not found in event target. Are you populating the data-id attribute?',
      );
      return;
    }
    setRowHoveringModel(rowId);
  };

  const handleOnRowLeave = () => {
    setRowHoveringModel('');
  };

  //Expanded rows
  const [expandedRowIds, setExpandedRowIds] = React.useState<GridRowId[]>([]);
  const [expandedRowData, setExpandedRowData] = React.useState<
    Record<string, NewOrderItem[]>
  >({});

  const [fetchOrderItemsByOrderId, { loading: isOrderItemsLoading }] =
    useSafeLazyQuery(GET_ORDER_ITEMS_BY_ORDER_ID);

  const columns = useGetOrdersColumns(hasOrderDetailsPagePermission, {
    rowHoveringModel,
    onFollowUpView: props.onFollowUpView,
  });

  const handleOnPaginationModelChange = (model: GridPaginationModel) => {
    if (model.pageSize !== paginationModel.pageSize) {
      setPaginationModel({ ...model, page: 0 });
    } else {
      setPaginationModel(model);
    }
  };

  const handleSortModelChange = (sortModel: GridSortModel): void => {
    if (!sortModel.length) {
      setOrdersSort(DEFAULT_ORDERS_DASH_SORT_MODEL);
      return;
    }

    const { field, sort: sortDirection } = sortModel[0];

    const serverSortDirection = GRID_SORT_FIELD_TO_SERVER_TABLE[
      sortDirection as keyof typeof GRID_SORT_FIELD_TO_SERVER_TABLE
    ] as SortDirection;

    setOrdersSort({
      direction: serverSortDirection,
      field,
    });
  };

  const handleExpandableRow = (params: GridRowParams<any>): React.ReactNode => {
    let displayOrderItems: NewOrderItem[] = [];
    const { id: paramsId = undefined } = params;

    if (paramsId && paramsId in expandedRowData) {
      displayOrderItems = expandedRowData[
        paramsId as keyof typeof expandedRowData
      ].map((orderItem: NewOrderItem) => ({
        ...orderItem,
        shipmentStatus: orderItem?.shipmentStatus ?? ShipmentStatusEnum.PENDING,
      }));
    }

    return (
      <ExpandedRowOrderItemDisplay
        key={params.id}
        orderItems={displayOrderItems}
        order={params.row}
      />
    );
  };

  const openOrCloseRow = (rowId: GridRowId) => {
    if (expandedRowIds.includes(rowId)) {
      const newArray = expandedRowIds.filter(
        (expandedRowId) => expandedRowId !== rowId,
      );
      setExpandedRowIds(newArray);
    } else {
      fetchOrderItemsByOrderId({
        variables: {
          orderId: rowId.toString(),
        },
        onCompleted(data) {
          setExpandedRowData({
            ...expandedRowData,

            [rowId.toString()]: data.searchOrderItems.orderItems,
          });
          setExpandedRowIds([...expandedRowIds, rowId]);
        },
      });
      if (expandedRowData[rowId.toString()]) {
        setExpandedRowIds([...expandedRowIds, rowId]);
      }
    }
  };

  const handleRowClick = (params: GridRowParams<any>) => {
    const rowId = params.id;
    openOrCloseRow(rowId);
  };

  const handleCellClick = (
    params: GridCellParams<any, unknown, unknown, GridTreeNode>,
  ) => {
    const rowId = params.id;
    openOrCloseRow(rowId);
  };

  const { data: orderTypesData } = useSafeQuery(GET_RETRIEVAL_ORDER_TYPES);
  const retrievalOrderTypes: OrderType[] =
    orderTypesData?.searchOrderTypes.orderTypes ?? [];

  const orderBy = ordersSort
    ? {
        [ordersSort.field]:
          ordersSort.direction === 'orderDesc' ? 'desc' : 'asc',
      }
    : { createdAt: 'desc' };

  const { queryField: oldLookAheadField } =
    ORDER_INPUT_FIELDS[lookAheadOptionType as keyof typeof ORDER_INPUT_FIELDS];
  const oldQueryVariables = {
    organizationId: userOrganization?.id,
    view: viewParam !== 'ALL' ? viewParam : undefined,
    limit: paginationModel.pageSize,
    offset: paginationModel.page * paginationModel.pageSize,
    orderNumber: orderNumberFilter,
    ...(statusFilter && { status: statusFilter }),
    ...(orderTypeFilter && { orderTypeName: orderTypeFilter }),
    ...(ordersSort && {
      [ordersSort.direction]: ordersSort.field,
    }),
    ...(lookAheadOption && { [oldLookAheadField]: lookAheadOption }),
  };

  const hasSearchCombo =
    !!lookAheadOption && selectedFilterEntries.length === 0;

  const newQueryVariables = {
    where: {
      AND: [
        { createdAt: { gt: 1 } },
        {
          organizationId: hasUserOrg
            ? { equals: userOrganization?.id }
            : { not: null },
        },
      ] as any,
    },
    orderBy,
    take: paginationModel.pageSize,
    skip: paginationModel.page * paginationModel.pageSize,
    // distinct: ['id'],
  };

  if (viewParam === 'FOLLOW_UP') {
    newQueryVariables.where.AND = [
      ...newQueryVariables.where.AND,
      {
        status: {
          in: [
            OrderStatus.PENDING_RECIPIENT_INFORMATION as string,
            OrderStatus.PENDING_RETURN as string,
          ],
        },
      },
    ];
  }

  if (viewParam === 'RETRIEVALS' && retrievalOrderTypes.length) {
    const retrievalOrderTypeIds = retrievalOrderTypes.map(({ id }) => id);

    newQueryVariables.where.AND = [
      ...newQueryVariables.where.AND,
      {
        orderTypeId: {
          in: retrievalOrderTypeIds,
        },
      },
    ];
  }

  if (hasSearchCombo) {
    const comboWhereClause =
      lookAheadOptionType === 'orderNumber'
        ? { orderNumber: { equals: lookAheadOption } }
        : {
            recipient: { is: { collaboratorId: { equals: lookAheadOption } } },
          };

    newQueryVariables.where.AND = [
      ...newQueryVariables.where.AND,
      comboWhereClause,
    ];
  }

  if (complexWhereClause.length) {
    newQueryVariables.where.AND = [
      ...newQueryVariables.where.AND,
      ...complexWhereClause,
    ];
  }

  const { data, loading: isSearchOrdersLoading } = useSafeQuery(
    isNewFilterEnabled() ? SEARCH_ORDERS_V2 : SEARCH_ORDERS,
    {
      variables: isNewFilterEnabled() ? newQueryVariables : oldQueryVariables,
      skip: isNewFilterEnabled() ? !retrievalOrderTypes.length : !hasUserOrg,
      fetchPolicy: 'network-only',
    },
  );

  const orders = isNewFilterEnabled()
    ? data?.v2FindAndCountOrders?.orders ?? []
    : data?.searchOrders?.orders ?? [];

  const ordersCount = isNewFilterEnabled()
    ? data?.v2FindAndCountOrders?.count ?? 0
    : data?.searchOrders?.count ?? 0;

  const showOrderTableLoadingIndicator =
    isSearchOrdersLoading || isOrderItemsLoading || props.showLoadingIndicator;

  React.useEffect(() => {
    if (!props.followUpBannerShown) return;
    setPaginationModel({ ...paginationModel, pageSize: 10 });
  }, [props.followUpBannerShown]);

  React.useEffect(() => {
    if (props.followUpBannerShown) return;
    setPaginationModel(() => {
      const { pageSize, ...rest } = paginationModel;
      const newPageSize = pageSize === 10 ? DEFAULT_PAGE_SIZE : pageSize;
      return { ...rest, pageSize: newPageSize };
    });
  }, [props.followUpBannerShown]);

  React.useEffect(() => {
    if (!hasOrderFilters) return;
    dispatch(resetComboSearch());
  }, [hasOrderFilters]);

  useAdminModePrompt();

  return (
    <DataGrid
      apiRef={apiRef}
      pagination
      disableColumnMenu
      autoHeight={false}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={handleOnPaginationModelChange}
      disableRowSelectionOnClick
      rows={orders}
      rowCount={ordersCount}
      columns={columns}
      loading={showOrderTableLoadingIndicator}
      pageSizeOptions={GENERIC_PAGE_SIZE_OPTIONS}
      slotProps={{
        panel: {
          placement: 'bottom-end',
          sx: DataGridSx,
        },
        row: {
          onMouseOver: handleOnRowOver,
          onMouseLeave: handleOnRowLeave,
        },
      }}
      getDetailPanelContent={handleExpandableRow}
      getDetailPanelHeight={() => 'auto'}
      slots={{
        detailPanelExpandIcon: ExpandIcon,
        detailPanelCollapseIcon: CondenseIcon,
      }}
      onRowClick={handleRowClick}
      onCellClick={handleCellClick}
      detailPanelExpandedRowIds={expandedRowIds}
      sortingMode="server"
      onSortModelChange={handleSortModelChange}
      disableMultipleColumnsSorting
    />
  );
};

export default OrdersDatagrid;
