import { SxProps } from '@mui/material';

export const TabsSx: SxProps = {
  '& .MuiTab-root': {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
    '&.Mui-selected': {
      color: 'colors.strongBlue',
    },
  },

  '& .MuiTabs-indicator': {
    backgroundColor: 'colors.strongBlue',
  },
};
