// eslint-disable-next-line import/no-cycle
import { AutoCompleteOptionTuple } from './types';
import {
  OrderStatus,
  InternalOrderType,
  SortDirection,
} from 'global-constants';

export enum OrderPathToTitle {
  ALL = 'All Orders',
  RETRIEVALS = 'Retrievals',
  FOLLOW_UP = 'Follow up',
}

//Trick MUI Datagrid that there are a lot of rows to enable pagination
export const FAKE_ROW_COUNT = 1000000;

export enum OrdersFilterColumns {
  ORDER_NUMBER = 'recipientOrderID',
  INTERNAL_ORDER_TYPE = 'internalOrderType',
  RECIPIENT_NAME = 'recipientName',
  ORDER_DATE = 'orderDate',
  STATUS = 'status',
}

export const OrderStatusMapping = {
  [OrderStatus.CREATED]: 'Processing',
  [OrderStatus.CANCELLED]: 'Canceled',
  [OrderStatus.COMPLETE]: 'Complete',
  [OrderStatus.EXCEPTION]: 'Exception',
  [OrderStatus.EXPIRED_PENDING_RECIPIENT_INFORMATION]:
    'Expired - Pending Recipient Information',
  [OrderStatus.EXPIRED_PENDING_RETURN]: 'Expired - Pending Return',
  [OrderStatus.IN_FULFILLMENT]: 'In Fulfillment',
  [OrderStatus.IN_RETURN]: 'In Return',
  [OrderStatus.PENDING_APPROVAL]: 'Preparing Order',
  [OrderStatus.PENDING_RECIPIENT_INFORMATION]: 'Pending recipient response',
  [OrderStatus.PENDING_RETURN]: 'Pending return',
  [OrderStatus.SHIPPED]: 'Shipped',
  [OrderStatus.PROCESSING_DEVICE]: 'Processing Device',
  [OrderStatus.PENDING_REPAIR]: 'Pending Repair',
  [OrderStatus.PENDING_RECYCLE]: 'Pending Recycle',
  [OrderStatus.PENDING_CERTIFICATE]: 'Pending Certificate',
  [OrderStatus.QUOTE_IN_PROGRESS]: 'Quote in progress',
  [OrderStatus.PENDING_QUOTE_APPROVAL]: 'Pending Quote Approval',
};

// Deprecated
export const InternalOrderTypeMapping = {
  [InternalOrderType.PROCUREMENT]: 'Procurement',
  [InternalOrderType.RETRIEVAL]: 'Retrieval',
  [InternalOrderType.BUY_AND_HOLD]: 'Buy and Hold',
  [InternalOrderType.STORAGE]: 'Storage',
  [InternalOrderType.BULK_RETRIEVAL]: 'Bulk Retrieval',
  [InternalOrderType.PROCESS_DEVICE]: 'Process Device',
  [InternalOrderType.RESELL]: 'Resell',
  [InternalOrderType.RECYCLE]: 'Recycle',
  [InternalOrderType.REPAIR]: 'Repair',
};

export const OrderTypeOptions = {
  PROCUREMENT_TO_RECIPIENT: 'Procurement to Recipient',
  PROCUREMENT_TO_DEPOT: 'Procurement to Depot',
  DEPLOYMENT: 'Deployment',
  BULK_RETRIEVAL: 'Bulk Retrieval',
  DROP_RETRIEVAL: 'Drop Retrieval',
  PROCESS: 'Process',
  RETRIEVAL: 'Retrieval',
  RESELL: 'Resell',
  RECYCLE: 'Recycle',
  REPAIR: 'Repair',
};

export enum OrdersDashboardColumnFieldNames {
  RECIPIENT_ORDER_ID = 'recipientOrderID',
  INTERNAL_ORDER_TYPE = 'internalOrderType',
  RECIPIENT_NAME = 'recipientName',
  ORDER_DATE = 'orderDate',
  FINAL_DESTINATION = 'finalDestination',
  STATUS = 'status',
}

export enum OrdersDatabaseColumnNames {
  RECIPIENT_ORDER_ID = 'Recipient order ID',
  RECIPIENT_NAME = 'Recipient name',
  ORDER_DATE = 'Time placed',
}

//Deprecated
export const DEFAULT_ORDERS_SORT_MODEL = [{ field: 'orderDate', sort: 'desc' }];

export const DEFAULT_ORDERS_DASH_SORT_MODEL = {
  field: 'createdAt',
  direction: SortDirection.ORDER_DESCENDING,
};

export const ORDER_NUMBER_RECIPIENT_NAME_SEARCH_PLACEHOLDER =
  'Start typing order number or recipient name';

export enum OrderSearchBy {
  ORDER_NUMBER = 'orderNumber',
  RECIPIENT_NAME = 'recipientName',
}

export const ORDER_INPUT_FIELDS = {
  [OrderSearchBy.ORDER_NUMBER]: {
    placeholder: 'Search for order by order number',
    optionLabel: 'Order number',
    label: 'Type order number',
    labelId: 'order-search-by-order-number-auto-complete',
    inputId: 'order-search-by-order-number-auto-complete-label',
    opposite: OrderSearchBy.RECIPIENT_NAME,
    queryField: 'orderNumber',
  },
  [OrderSearchBy.RECIPIENT_NAME]: {
    placeholder: 'Search for order by recipient name',
    optionLabel: 'Recipient name',
    label: 'Type recipient name',
    labelId: 'order-search-by-recipient-name-auto-complete',
    inputId: 'order-search-by-recipient-name-auto-complete-label',
    opposite: OrderSearchBy.ORDER_NUMBER,
    queryField: 'recipientCollaboratorId',
  },
};

export const SEARCH_BAR_EXTERNAL_RADIUS = '50px';
export const SEARCH_BAR_TYPE_SELECT_WIDTH = '190px';
export const SEARCH_BAR_WHOLE_WIDTH = '575px';
export const SEARCH_BAR_WHOLE_HEIGHT = '36px';

export const DEFAULT_AUTOCOMPLETE_OPTIONS: AutoCompleteOptionTuple[] = [
  ['', 'No options'],
];

export const ORDERS_SEARCH_BAR_CLEAR_INDICATOR_ID =
  'orders-search-bar-clear-indicator';

export const FOLLOW_UP_BANNER_FAQS_URL =
  'https://faq.allwhere.co/en_us/expiration-policy-ByHt6iS2i';
