import { SxProps } from '@mui/material';

export const TimeStampBaseTextSx: SxProps = {
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '143%',
  textAlign: 'left',
};

export const PendingTimeStampTextSx: SxProps = {
  ...TimeStampBaseTextSx,
  color: '#1314148A',
};

export const FailedTimeStampTextSx: SxProps = {
  ...TimeStampBaseTextSx,
  color: 'colors.burntSiennaR',
};

export const SentTimeStampTextSx: SxProps = {
  ...TimeStampBaseTextSx,
  color: 'colors.goldFusion',
};
