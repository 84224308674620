import { SxProps } from '@mui/material';
import theme from 'themes/theme';
import { POPOVER_TOP_BOX_HEIGHT } from '../UploadPopover/styles';

export const UploadPopoverBookendSx: SxProps = {
  alignItems: 'center',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  px: '24px',
  py: '8px',
};

export const UploadPopoverTopBoxSx: SxProps = {
  borderBottom: `1px solid #c9c9c9`,
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  height: POPOVER_TOP_BOX_HEIGHT,
  justifyContent: 'space-between',
  position: 'sticky',
  top: 0,
  zIndex: 1,
  backgroundColor: theme.palette.colors.white,
  ...UploadPopoverBookendSx,
};

export const UploadPopoverTitleSx: SxProps = {
  fontFamily: 'PPPangramSans',
  fontSize: '16px',
  fontWeight: 700,
};
