export const TotalSummaryContainerSx = { width: '25%' };

export const TotalSummaryTitleSx = {
  mb: '30px',
  mt: '15px',
  fontSize: '16px',
  fontWeight: '700',
};

export const TotalSummaryContentSx = {
  width: '100%',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  borderRadius: '10px',
  padding: '20px',
};

export const TotalSx = { fontWeight: '700', mt: '15px !important' };

export const SubmitButtonContainerSx = { mt: '15px' };
export const SubmitButtonSx = { height: '36px' };
export const SubmitLabelSx = { fontWeight: '700' };
