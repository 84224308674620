import csvDownload from 'json-to-csv-export';

export const downloadDataToCSV = (
  data: any[],
  headers: string[],
  filename: string,
  delimiter = ','
): void => {
  const csvDownloadArgs = {
    data,
    headers,
    filename,
    delimiter,
  };
  csvDownload(csvDownloadArgs);
};
