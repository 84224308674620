import React from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import useGetUserPermissions from 'hooks/useGetUserPermissions';
import useGetUserOrganization from 'hooks/useGetUserOrganization';

const useFeatureFlagConfig = () => {
  const ldClient = useLDClient();
  const hookUserPermissions = useGetUserPermissions() ?? [];
  const userOrganization = useGetUserOrganization();

  const authContext = {
    key: 'auth',
    permissions: hookUserPermissions as string[],
  };

  const organizationContext = {
    key: 'organization',
    id: userOrganization?.id ?? 'N/A',
    hrisSynced: !!userOrganization?.hrisEmployeeDataSyncedAt,
  };

  const clientContext = {
    kind: 'multi',
    auth: authContext,
    organization: organizationContext,
  };

  React.useEffect(() => {
    if (!ldClient || !ldClient?.identify || !userOrganization) return;
    ldClient?.identify(clientContext).then(() => {
      console.log('FeatureFlagConfigProvider: client instance identified');
    });
  }, [
    ldClient?.identify,
    hookUserPermissions.length,
    userOrganization,
    userOrganization?.id,
  ]);
};

export default useFeatureFlagConfig;
