import { RetrievalCollaborator } from 'pages/Retrieval/RetrievalService/types';
import { RetrievalOption } from 'pages/Retrieval/components/ReviewStep/components/RetrievalOptionValueForm/types';

export const getCollaboratorsAssetLookup = (
  collaborators: RetrievalCollaborator[]
): Record<string, any> =>
  collaborators.reduce((acc: Record<string, any>, collaborator) => {
    acc[collaborator.id] = {};
    const hasAssets = !!collaborator.assets.length;

    if (hasAssets) {
      collaborator.assets.forEach((asset) => {
        acc[collaborator.id][asset.type] = true;
      });
    }
    return acc;
  }, {});

export const getDefaultAnswers = (optionsLookup: Record<string, any>) => {
  const optionLookupEntries = Object.entries(optionsLookup);
  const noOptions = optionLookupEntries.length === 0;
  const value: Record<string, any> = {};

  if (noOptions) return value;

  optionLookupEntries.forEach(([key, options]) => {
    const tempOptionAnswers = options.map((option: RetrievalOption) => {
      const optionId = option.id;
      const hasAnswers = option.retrievalOptionValues.length > 0;

      let optionValue = '';
      let optionValueId = '';
      let optionName = '';

      if (hasAnswers) {
        option.retrievalOptionValues.forEach((retrievalOptionValue) => {
          if (retrievalOptionValue.selected) {
            optionValue =
              retrievalOptionValue?.value ?? retrievalOptionValue.name;
            optionValueId = retrievalOptionValue.id;
            optionName = option.name;
          }
        });
      }

      return {
        optionId,
        optionName,
        optionValue,
        optionValueId,
      };
    });

    value[key] = tempOptionAnswers;
  });
  return value;
};
