import React from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MACRO_CASE_REGEX } from 'global-constants';
import { capitalizeWords } from 'services';

const useSliceState = (
  defaultInitialState: Record<string, any>,
  baseNameMacroCase: string,
  purgeOptions: {
    zeroes?: boolean;
    falseValues?: boolean;
    nulls?: boolean;
  } = {
    nulls: true,
  }
) => {
  const isMacroCase = MACRO_CASE_REGEX.test(baseNameMacroCase);
  if (!isMacroCase)
    throw new Error(
      `Parameter baseNameMacroCase: ${baseNameMacroCase} must be in macro case. Ex: "MY_MACRO_CASE"`
    );

  const READ_ONLY_RECORD_KEYS = [...Object.keys(defaultInitialState)] as const;
  type RecordSliceFieldsUnion = typeof READ_ONLY_RECORD_KEYS[number];
  type RecordSliceState = typeof defaultInitialState;

  const sliceName = capitalizeWords(baseNameMacroCase).split(' ').join();

  const recordSlice = createSlice({
    name: sliceName,
    initialState: defaultInitialState,
    reducers: {
      setFieldValues: (
        state,
        action: PayloadAction<Partial<RecordSliceState>>
      ) => {
        return {
          ...state,
          ...action.payload,
        };
      },
      resetField: (state, action: PayloadAction<RecordSliceFieldsUnion>) => {
        return {
          ...state,
          [action.payload]: null,
        };
      },
      resetFields: (state, action: PayloadAction<RecordSliceFieldsUnion[]>) => {
        const updatedState = action.payload.reduce(
          (acc: Record<RecordSliceFieldsUnion, any>, filterKey) => {
            acc[filterKey] = defaultInitialState[filterKey];
            return acc;
          },
          {}
        );

        return {
          ...state,
          ...updatedState,
        };
      },
      reset: () => defaultInitialState,
    },
  });

  const [recordFields, dispatch] = React.useReducer(
    recordSlice.reducer,
    recordSlice.getInitialState()
  );

  const shouldPassValue = (keyValue: any) => {
    let passes = true;
    if (purgeOptions.zeroes && keyValue === 0) passes = false;
    if (purgeOptions.falseValues && keyValue === false) passes = false;
    if (purgeOptions.nulls && keyValue === null) passes = false;
    return passes;
  };

  const reducedFieldsTable = Object.keys(recordFields).reduce(
    (acc: Partial<RecordSliceState>, fieldKey: string) => {
      const tempFieldValue = recordFields[fieldKey as keyof RecordSliceState];
      if (shouldPassValue(tempFieldValue)) {
        acc[fieldKey as RecordSliceFieldsUnion] = tempFieldValue;
      }
      return acc;
    },
    {}
  );

  const fieldsList: string[] = Object.keys(defaultInitialState);
  const changedFieldsList = fieldsList.slice().filter((field: string) => {
    if (field in reducedFieldsTable) {
      return reducedFieldsTable[field] !== defaultInitialState[field];
    }
    return false;
  });

  return {
    reducedFieldsTable,
    allFieldsList: fieldsList,
    changedFieldsList,
    initialState: defaultInitialState,
    setFieldValues: (fieldValues: Record<RecordSliceFieldsUnion, any>) =>
      dispatch(recordSlice.actions.setFieldValues(fieldValues)),
    resetField: (targetField: RecordSliceFieldsUnion) =>
      dispatch(recordSlice.actions.resetField(targetField)),
    resetFields: (targetFields: RecordSliceFieldsUnion[]) =>
      dispatch(recordSlice.actions.resetFields(targetFields)),
    resetAllFields: () => dispatch(recordSlice.actions.reset()),
  };
};

export default useSliceState;
