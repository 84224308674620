import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components';
import { Box, Container, Stack, Typography, Dialog } from '@mui/material';
import allwhereBoxRunning from 'assets/allwhereBoxRunning.png';
import { NavigationContext } from 'context/NavigationContext';
import { ORDER_SUBMITTED_SUBTITLE, ORDER_SUBMITTED_TITLE } from './constants';
import {
  ContainerSx,
  ContainerStackSx,
  GoToOrdersButtonSx,
  GoToOrdersTypographySx,
  OrderSubmittedTitleSx,
  OrderSubmittedSubtitleSx,
  PaperSx,
  ImageSx,
  CloseButtonSx,
} from './styles';
import { UnlockOrderSubmittedModalProps } from './types';
import CloseIcon from '@mui/icons-material/Close';
import GAIconButton from 'components/buttons/google_analytics/GAIconButton';

const UnlockOrderSubmittedModal = (props: UnlockOrderSubmittedModalProps) => {
  const navigate = useNavigate();
  const navigator = React.useContext(NavigationContext);
  const { setShowUnlockOrderSubmittedModal } = props;

  const handleClose = React.useCallback(() => {
    setShowUnlockOrderSubmittedModal(false);
  }, []);

  return (
    <Dialog open={props.open} onClose={handleClose} sx={PaperSx}>
      <Container sx={ContainerSx}>
        <GAIconButton
          gaContext={{
            navigates_to: 'N/A',
            textCopy: 'Close Icon',
            purpose: 'Closes unlock submitted order modal',
          }}
          aria-label="close-modal-icon-button"
          onClick={handleClose}
          sx={CloseButtonSx}
        >
          <CloseIcon />
        </GAIconButton>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={ContainerStackSx}
        >
          <Box sx={ImageSx}>
            <img src={allwhereBoxRunning} alt="allwhere=box-running" />
          </Box>
          <br />
          <Stack alignItems="center" justifyContent="center">
            <Typography variant="h3" sx={OrderSubmittedTitleSx}>
              {ORDER_SUBMITTED_TITLE}
            </Typography>
            <Typography variant="body1" sx={OrderSubmittedSubtitleSx}>
              {ORDER_SUBMITTED_SUBTITLE}
            </Typography>
          </Stack>
          <br />
          <Button
            gaContext={{
              navigates_to: 'Orders',
              textCopy: 'View in Orders',
              purpose: 'Navigates To Orders',
            }}
            variant="contained"
            sx={GoToOrdersButtonSx}
            onClick={() => {
              navigate(navigator.pathToUnlockDeviceOrders());
            }}
          >
            <Typography sx={GoToOrdersTypographySx}>View in Orders</Typography>
          </Button>
        </Stack>
      </Container>
    </Dialog>
  );
};

export default UnlockOrderSubmittedModal;
