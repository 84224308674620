import React from 'react';
import { Container, Stack } from '@mui/system';
import { Typography } from '@mui/material';
import { DRAWER_WIDTH } from 'global-styles';

const PickGlobalDataRetoolPrompt = () => {
  return (
    <Container>
      <Stack
        spacing={1.25}
        flexWrap="nowrap"
        marginTop="2rem"
        marginLeft={`${DRAWER_WIDTH}px`}
      >
        <Stack spacing={1.25}>
          <Typography variant="h5">
            Please select an organization & purchaser to view dashboard
          </Typography>
        </Stack>
      </Stack>
    </Container>
  );
};

export default PickGlobalDataRetoolPrompt;
