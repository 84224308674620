import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { SelectChangeEvent } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { GridPaginationModel, GridRowParams } from '@mui/x-data-grid-pro';
import {
  DEFAULT_PAGINATION_MODEL,
  GENERIC_PAGE_SIZE_OPTIONS,
  UserPermissions,
} from 'global-constants';
import DataGrid from 'components/DataGrid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TextIconButton from 'components/TextIconButton';
import { SearchScopeDropdown } from 'components';
import useDebouncedQuery from 'hooks/useDebouncedSearch';
import useGetUserPermissions from 'hooks/useGetUserPermissions';
import { usePermissionRedirect } from 'hooks/usePermissionRedirect';
import { useSafeMutation } from 'hooks/useSafeMutation';
import { useSafeQuery } from 'hooks/useSafeQuery';
import {
  AutoCompleteOptionTuple,
  DEFAULT_AUTOCOMPLETE_OPTIONS,
  Order,
  OrderManager,
} from 'types';
import { useGetOrderManagementColumns } from './hooks/useGetOrderManagementColumns';
import DetailedOrderView from './components/DetailedOrderView';
import OrderCancelModal from './components/OrderCancelModal';
import OrderFiltersBar from './components/OrderFiltersBar';
import CreateNewOrderModal from './components/CreateNewOrderModal';
import ViewTabs from './components/ViewTabs';
import { SEARCH_ORDERS } from './queries';
import * as OrderManagementStyles from './styles';
import { OrderView, OrderManagementFilterFields } from './enum';
import { UPDATE_ORDER_MANAGER_ON_ORDER } from './mutations';
import useCreateOrderState from './hooks/useCreateOrderState';
import { SEARCH_ORDER_MANAGERS } from './hooks/useGetOrderManagementColumns/queries';
import {
  OM_SEARCH_OPTIONS,
  OrderManagementSuggestionFilterName,
  OM_FILTER_SLICE_NAME,
  OM_DEFAULT_FILTER_STATE,
  OM_CLEAR_ALL_FILTERS_BUTTON_ID,
} from './constants';
import { SearchOption } from 'components/SearchScopeDropdown/types';
import ManagementDashboardToggle from 'components/ManagementToggle';
import Spacer from 'components/Spacer';
import { useGetOrderSuggestions } from './hooks/useGetOrderSuggestions';
import useSliceState from 'hooks/useSliceState';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import { FeatureFlagNames } from 'enums/feature-flags';

const OrderManagement = () => {
  const auth0 = useAuth0();
  const { isFlagOn: isPremiumShippingEnabled } = useFeatureFlagService(
    FeatureFlagNames.PREMIUM_SHIPPING
  );
  const styles = OrderManagementStyles;
  const [selectedOrder, setSelectedOrder] = React.useState<Order | undefined>(
    undefined
  );
  const [orderManagerId, setOrderManagerId] = React.useState('');
  const [disableStatusFilter, setDisableStatusFilter] = React.useState(false);
  const [disableOrderTypeFilter, setDisableOrderTypeFilter] =
    React.useState(false);
  const userPermissions = useGetUserPermissions();
  const createOrderState = useCreateOrderState();
  const {
    setOrderDetailsByOrderType,
    hasAssets,
    hasProducts,
    toDepot,
    toRecipient,
    toDropRecipient,
    isUseCreateOrderStateReady,
    purchasersSelectable: selectableCollaborators = [],
  } = createOrderState;

  //If user is not permitted to view OrderManagement, redirect to home page
  usePermissionRedirect(
    userPermissions,
    UserPermissions.ORDER__MANAGEMENT__VIEW
  );

  const [viewTab, setViewTab] = React.useState<OrderView>(OrderView.ALL_ORDERS);
  const {
    reducedFieldsTable: availableOMFiltersTable,
    resetField: resetOMFilterField,
    resetAllFields: resetAllOMFilterFields,
    setFieldValues: setOMFilterFieldValues,
  } = useSliceState(OM_DEFAULT_FILTER_STATE, OM_FILTER_SLICE_NAME);

  // Searching state - START
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const debouncedSearchTerm = useDebouncedQuery(searchTerm);
  const [searchOption, setSearchOption] = React.useState<SearchOption>(
    OM_SEARCH_OPTIONS[0]
  );
  const [autocompleteOptions, setAutocompleteOptions] = React.useState<
    AutoCompleteOptionTuple[]
  >(DEFAULT_AUTOCOMPLETE_OPTIONS);
  const [selectedSuggestion, setSelectedSuggestion] =
    React.useState<AutoCompleteOptionTuple | null>(null);
  const [filtersArePresent, setFiltersArePresent] = React.useState(false);

  const searchPlaceholder = `Search for order by ${searchOption.displayName.toLowerCase()}`;

  useGetOrderSuggestions(
    debouncedSearchTerm,
    searchOption.filterName as OrderManagementSuggestionFilterName,
    setAutocompleteOptions
  );

  const handleSearchTermChange = (e: any) => {
    if (e) {
      setSearchTerm(e.target.value);
    }
  };

  const resetSearchTerm = () => {
    setSearchTerm('');
    setAutocompleteOptions(DEFAULT_AUTOCOMPLETE_OPTIONS);
  };

  const handleSearchOptionChange = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;
    const option = OM_SEARCH_OPTIONS.find(
      (searchOption) => searchOption.filterName === value
    );
    if (option) {
      setSearchOption(option);
      setSelectedSuggestion(null);
    }
  };

  const handleSuggestionSelection = (
    _event: React.SyntheticEvent<Element, Event>,
    value: any
  ) => {
    setSelectedSuggestion(value);
    const clearAllFiltersButton: HTMLElement = document.getElementById(
      `${OM_CLEAR_ALL_FILTERS_BUTTON_ID}`
    ) as HTMLElement;

    if (clearAllFiltersButton) {
      clearAllFiltersButton.click();
    }
  };
  // Searching state - END

  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>(DEFAULT_PAGINATION_MODEL);

  const { data: orderManagerData, loading: searchOrderManagersLoading } =
    useSafeQuery(SEARCH_ORDER_MANAGERS);

  const {
    data,
    loading: searchOrdersLoading,
    refetch: refetchSearchOrdersQuery,
  } = useSafeQuery(SEARCH_ORDERS, {
    variables: {
      offset: paginationModel.page * paginationModel.pageSize,
      limit: paginationModel.pageSize,
      view: viewTab,
      orderDesc: 'createdAt',
      isPremiumShipping: isPremiumShippingEnabled(),
      ...(orderManagerId ? { orderManagerId } : {}),
      ...(selectedSuggestion
        ? {
            [selectedSuggestion[0]]: selectedSuggestion[1],
          }
        : {}),
      ...availableOMFiltersTable,
    },
    onCompleted(data) {
      const orders = data?.searchOrders?.orders || ([] as Order[]);

      if (selectedOrder) {
        orders.forEach((order: Order) => {
          if (order.id === selectedOrder.id) {
            setSelectedOrder(order);
          }
        });
      }
    },
  });

  const [mutate, { loading: updateOrderLoading }] = useSafeMutation(
    UPDATE_ORDER_MANAGER_ON_ORDER
  );

  const rows = data?.searchOrders?.orders || [];
  const rowCount = data?.searchOrders?.count || 0;
  const columns = useGetOrderManagementColumns(mutate);

  const handleOnPaginationModelChange = (model: GridPaginationModel) => {
    if (model.pageSize !== paginationModel.pageSize) {
      setPaginationModel({ ...model, page: 0 });
    } else {
      setPaginationModel(model);
    }
  };

  const [createNewOrderModalOpen, setCreateNewOrderModalOpen] =
    React.useState<boolean>(false);

  const [openCancelOrderModal, setOpenCancelOrderModal] =
    React.useState<boolean>(false);

  const activateOrderCancelModal = () => {
    if (!selectedOrder) return;
    setOpenCancelOrderModal(true);
  };

  const openCreateNewOrderModal = () => {
    setCreateNewOrderModalOpen(true);
  };

  const closeCreateNewOrderModal = () => {
    setCreateNewOrderModalOpen(false);
  };

  const enableToggleableFilters = () => {
    setDisableStatusFilter(false);
    setDisableOrderTypeFilter(false);
  };

  const onViewTabChangeAdjustFilters = (viewTabParam: OrderView) => {
    switch (viewTabParam) {
      case OrderView.ALL_NEW_ORDERS:
      case OrderView.CANCELLED:
      case OrderView.EXPIRED:
        resetOMFilterField(OrderManagementFilterFields.STATUS);
        setDisableStatusFilter(true);
        setDisableOrderTypeFilter(false);
        break;
      // case OrderView.KITS:
      //   break;
      case OrderView.PROCUREMENT:
      case OrderView.REDEPLOYMENT:
      case OrderView.RETRIEVALS:
        resetOMFilterField(OrderManagementFilterFields.ORDER_TYPE_NAME);
        setDisableStatusFilter(false);
        setDisableOrderTypeFilter(true);
        break;
      // case OrderView.ALL_ORDERS:
      //   break;
      default:
        enableToggleableFilters();
        break;
    }
  };

  React.useEffect(() => {
    //When view tab changes, reset pagination, search term & order manager id filter
    setPaginationModel({ ...paginationModel, page: 0 });
    setSearchTerm('');
    setSelectedSuggestion(null);
    setAutocompleteOptions(DEFAULT_AUTOCOMPLETE_OPTIONS);
    setOrderManagerId('');
    onViewTabChangeAdjustFilters(viewTab);
  }, [viewTab]);

  React.useEffect(() => {
    if (!selectedOrder?.id) return;
    setOrderDetailsByOrderType(selectedOrder.orderType.name);
  }, [selectedOrder?.id]);

  const handleOnRowClick = (a: GridRowParams) => {
    const {
      orderType: { name },
    } = a.row as Order;
    setSelectedOrder(a.row as Order);
  };

  const handleDetailedOrderViewModalClose = (
    refetchSearchQuery: boolean = false
  ) => {
    if (refetchSearchQuery) {
      refetchSearchOrdersQuery();
    }
    setSelectedOrder(undefined);
  };

  const closeCancelOrderModal = (refetchSearchQuery: boolean = false) => {
    if (refetchSearchQuery) {
      refetchSearchOrdersQuery();
    }
    setOpenCancelOrderModal(false);
    setSelectedOrder(undefined);
  };

  const updateSelectedOrder = (orderUpdates: Partial<Order>) => {
    if (!selectedOrder) return;
    setSelectedOrder(() => ({ ...selectedOrder, ...orderUpdates }));
  };

  const showTableLoadingIndicator =
    searchOrdersLoading || updateOrderLoading || searchOrderManagersLoading;

  const handleOnAssignedToMeChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      const orderManager =
        orderManagerData?.searchOrderManagers.orderManagers.filter(
          (orderManager: OrderManager) =>
            orderManager.email === auth0.user?.email
        ) as OrderManager[];
      if (orderManager.length) {
        setOrderManagerId(orderManager[0].id);
      }
      return;
    }
    setOrderManagerId('');
  };

  return (
    <>
      <Container sx={styles.MainContainerSx}>
        <Stack direction="column">
          <ManagementDashboardToggle />
          <Spacer height="16px" />
        </Stack>
        <Stack direction="column">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb="24px"
          >
            <Typography sx={styles.MainHeaderSx}>Order Management</Typography>
            <TextIconButton
              text="Create new order"
              ariaLabel="create-new-order-btn"
              variant="outlined"
              disabled={!isUseCreateOrderStateReady()}
              icon={<AddCircleOutlineIcon />}
              buttonStyles={styles.AddOrderButtonStylesSx}
              textStyles={styles.AddOrderButtonTextStylesSx}
              onClick={openCreateNewOrderModal}
            />
          </Stack>
        </Stack>
        <ViewTabs viewTab={viewTab} setViewTab={setViewTab} />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt="12px"
        >
          <Box minWidth="px">
            <SearchScopeDropdown
              searchTerm={searchTerm}
              resetSearchTerm={resetSearchTerm}
              handleSearchTermChange={handleSearchTermChange}
              selectedSearchOption={searchOption}
              searchOptions={OM_SEARCH_OPTIONS}
              handleSearchOptionChange={handleSearchOptionChange}
              suggestions={autocompleteOptions}
              selectedSuggestion={selectedSuggestion}
              handleSuggestionSelection={handleSuggestionSelection}
              placeholder={searchPlaceholder}
            />
          </Box>
          <FormControlLabel
            sx={{ mr: 0 }}
            control={
              <Switch
                color="secondary"
                checked={!!orderManagerId}
                inputProps={{ 'aria-label': 'controlled' }}
                onChange={handleOnAssignedToMeChange}
              />
            }
            label="Only Assigned to me"
          />
        </Stack>
        <OrderFiltersBar
          setFilters={setOMFilterFieldValues}
          resetAllFilters={resetAllOMFilterFields}
          resetFilter={resetOMFilterField}
          filtersTable={availableOMFiltersTable}
          disableStatusFilter={disableStatusFilter}
          disableOrderTypeFilter={disableOrderTypeFilter}
        />
        <Box sx={styles.DataGridBoxSx}>
          <DataGrid
            rows={rows}
            rowCount={rowCount}
            columns={columns}
            autoHeight={false}
            rowSelection={false}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={handleOnPaginationModelChange}
            loading={showTableLoadingIndicator}
            pageSizeOptions={GENERIC_PAGE_SIZE_OPTIONS}
            onRowClick={handleOnRowClick}
          />
        </Box>
      </Container>
      <DetailedOrderView
        open={!!selectedOrder}
        order={selectedOrder}
        handleClose={handleDetailedOrderViewModalClose}
        openCancelOrderModal={activateOrderCancelModal}
        refetchOrders={refetchSearchOrdersQuery}
        updateSelectedOrder={updateSelectedOrder}
        createOrderState={createOrderState}
      />
      <OrderCancelModal
        openModal={openCancelOrderModal}
        handleClose={closeCancelOrderModal}
        orderId={selectedOrder?.id || ''}
      />
      {isUseCreateOrderStateReady() ? (
        <CreateNewOrderModal
          open={createNewOrderModalOpen}
          handleClose={closeCreateNewOrderModal}
          createOrderState={createOrderState}
          setSelectedOrder={setSelectedOrder}
        />
      ) : null}
    </>
  );
};

export default OrderManagement;
