import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';

import { notificationSlice } from 'store/slices';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';
import NewModal from 'components/NewModal';
import UnlockDevice from 'components/unlock_device/UnlockDevice';
import UnlockDeviceConfirmation from '../UnlockDeviceConfirmation';
import { useSafeMutation } from 'hooks/useSafeMutation';

import { UnlockDeviceProps, UnlockDeviceFormType } from './types';
import { CREATE_UNLOCK_DEVICE_ORDER } from './queries';
import { UNLOCK_DEVICE_TITLE, SAVE_BUTTON_COPY } from './constants';
import {
  PaperPropsSx,
  TitleIconSx,
  FormChildrenBoxSx,
  FormSx,
  OuterStackPropsSx,
  InnerFormStackSx,
} from './styles';

const UnlockDeviceModal = (props: UnlockDeviceProps) => {
  const {
    open,
    device,
    purchaserId,
    organizationId,
    handleClose,
    setShowUnlockOrderSubmittedModal,
    refetchSearchAssets,
    enableActionsByDevice,
  } = props;

  if (!device || !organizationId || !purchaserId) return <></>;

  const [showCancelButtonConfirmation, setShowCancelButtonConfirmation] =
    useState<boolean>(false);

  const [isAssetLoading, setIsAssetLoading] = useState<boolean>(false);

  const form = useForm<UnlockDeviceFormType>();

  React.useEffect(() => {
    if (!open) {
      form.reset();
    }
  }, [open, form]);

  const dispatch = useDispatch();

  const [mutate, { loading: isCreateUnlockOrderLoading }] = useSafeMutation(
    CREATE_UNLOCK_DEVICE_ORDER
  );

  const handleCloseModal = () => {
    setShowCancelButtonConfirmation(true);
  };

  const handleCloseConfirmation = React.useCallback(() => {
    setShowCancelButtonConfirmation(false);
  }, []);

  const handleSubmitConfirmation = React.useCallback(() => {
    handleClose();
    setShowCancelButtonConfirmation(false);
  }, []);

  const code = form.watch('code');
  const notes = form.watch('notes');

  const onSubmit = React.useCallback(() => {
    setIsAssetLoading(true);
    if (code && purchaserId && device?.id) {
      mutate({
        variables: {
          assetId: device?.id,
          purchaserId,
          mdmPin: code,
          checkoutNotes: notes,
        },
        onError() {
          handleClose();
        },
        async onCompleted() {
          dispatch(
            notificationSlice.actions.setNotice({
              showNotice: true,
              noticeContent: NotificationMessages.ORDER_CREATE_REQUEST_SENT,
            })
          );
          await refetchSearchAssets();
          setIsAssetLoading(false);
          handleClose();
          setTimeout(() => {
            setShowUnlockOrderSubmittedModal(true);
          });
        },
      });
    }
  }, [device?.id, purchaserId, code, notes]);

  React.useEffect(() => {
    enableActionsByDevice(device);
  }, [device]);

  const loading = isCreateUnlockOrderLoading || isAssetLoading;

  return (
    <>
      <NewModal
        form
        open={open}
        title={UNLOCK_DEVICE_TITLE}
        TitleIcon={<LockOpenOutlinedIcon sx={TitleIconSx} />}
        hideCTAs
        handleClose={handleCloseModal}
        onSubmit={() => {}}
        dividerPlacement="top"
        submitButtonCopy={SAVE_BUTTON_COPY}
        loading={loading}
        outerStackPropsSx={OuterStackPropsSx}
        paperPropsSx={PaperPropsSx}
        formChildrenBoxSx={FormChildrenBoxSx}
        formSx={FormSx}
        innerFormStackSx={InnerFormStackSx}
      >
        <UnlockDevice
          device={device}
          onSubmit={form.handleSubmit(onSubmit)}
          form={form}
          loading={loading}
        />
      </NewModal>
      <UnlockDeviceConfirmation
        open={showCancelButtonConfirmation}
        handleClose={handleCloseConfirmation}
        handleSubmit={handleSubmitConfirmation}
        showCloseButtonTopRight={false}
      />
    </>
  );
};

export default UnlockDeviceModal;
