import { Collaborator } from 'types';
import {
  getCapitalizedCountry,
  getFullNameState,
  formatDate,
  removeNullFields,
} from 'services';
import {
  UNITED_STATES,
  ADDRESS_FIELDS_REQUIRED,
  COUNTRY_MATCHER,
  US_STATE_MATCHER,
} from 'global-constants';
import { AddressLikeObject, EmployeeFormModalInputs } from './types';
import {
  EDIT_EMPLOYEE_FORM_INPUT_KEYS,
  EMPLOYEE_DATE_TYPE_FIELDS,
} from './constants';

export const getEditEmployeeFormInitialValues = (employee: Collaborator) =>
  EDIT_EMPLOYEE_FORM_INPUT_KEYS.reduce((acc: Record<string, any>, key) => {
    const valueAtKey =
      key in employee ? employee[key as keyof typeof employee] : null;
    acc[key] = valueAtKey;
    if (
      EMPLOYEE_DATE_TYPE_FIELDS.some((field: string) => field === key) &&
      typeof valueAtKey === 'string'
    ) {
      acc[key] = formatDate(valueAtKey, 'YYYY-MM-DD');
    }

    if (key === 'group' && Array.isArray(valueAtKey)) {
      acc[key] = valueAtKey.join(', ');
    }

    if (key === 'phoneNumber' && !valueAtKey) {
      acc[key] = '';
    }
    return acc;
  }, {});

export const getAddressLikeObject = (
  addressLikeObject: AddressLikeObject,
  options: { fixLocales?: boolean; passRestOfAddressLikeObject?: boolean } = {
    fixLocales: false,
  }
) => {
  const {
    streetAddress1,
    streetAddress2,
    zipCode,
    principalRegion,
    city,
    state,
    country,
    ...restOfAddressLikeObject
  } = addressLikeObject;
  return {
    streetAddress1,
    streetAddress2,
    zipCode,
    city,
    principalRegion,
    state: options.fixLocales ? getFullNameState(state) : state,
    country: options.fixLocales
      ? getCapitalizedCountry(country as string)
      : country,
    ...(options.passRestOfAddressLikeObject ? restOfAddressLikeObject : {}),
  };
};

export const isAdmissibleAddress = (addressLikeObject: AddressLikeObject) => {
  const allRequiredFields = ADDRESS_FIELDS_REQUIRED.every(
    (field: string) =>
      !!addressLikeObject[field as keyof typeof addressLikeObject]
  );
  const { country, principalRegion, state } = addressLikeObject;
  const isUSA = country === UNITED_STATES;
  const countryMatches = isUSA ? !!state : !!principalRegion;
  return countryMatches && allRequiredFields;
};

export const getCreateCollaboratorPayload = (
  formData: EmployeeFormModalInputs,
  organizationId: string = ''
) => {
  const {
    hireDate,
    group,
    startDate,
    phoneNumber,
    email,
    personalEmail,
    workEmail,
    ...restOfFormData
  } = formData;
  return {
    ...removeNullFields(restOfFormData),
    email: personalEmail || workEmail || email,
    ...(workEmail && { workEmail }),
    ...(personalEmail && { personalEmail }),
    ...(organizationId && { organizationId }),
    ...(phoneNumber && { phoneNumber }),
    ...(group && { group: group.split(', ') }),
    ...(startDate && {
      startDate: new Date(startDate).toISOString(),
    }),
    ...(hireDate && {
      hireDate: new Date(hireDate).toISOString(),
    }),
  };
};

export const getUpdateCollaboratorPayload = (
  collaboratorId: string,
  formData: EmployeeFormModalInputs
) => {
  const addCollaboratorPayload = getCreateCollaboratorPayload(formData);
  return {
    id: collaboratorId,
    ...addCollaboratorPayload,
    email: formData.personalEmail || undefined,
  };
};

export const getCreateAddressPayload = (addressData: any) => ({
  streetAddress1: addressData.streetAddress1,
  streetAddress2: addressData.streetAddress2,
  city: addressData.city,
  zipCode: addressData.zipCode,
  ...(addressData.country && {
    country: COUNTRY_MATCHER[addressData.country],
  }),
  principalRegion: addressData.principalRegion || null,
  ...(addressData.state
    ? { state: US_STATE_MATCHER[addressData.state] }
    : { state: null }),
});

export const getUpdateAddressPayload = (updateData: any) => {
  const {
    principalRegion,
    state,
    country: countryRaw,
    ...restOfUpdateData
  } = updateData;
  const country = countryRaw && COUNTRY_MATCHER[countryRaw as string];
  const isUSA = country === 'UNITED_STATES';
  return {
    ...restOfUpdateData,
    ...(country && { country }),
    ...(isUSA
      ? { principalRegion: null, state: US_STATE_MATCHER[state as string] }
      : { state: null, principalRegion }),
  };
};
