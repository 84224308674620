import { Collaborator, Organization } from 'types';
import { DelightedScriptArgs } from './types';
import { SURVEY_CONFIG } from './constants';
import { Navigator } from 'context/NavigationContext';

export const getScript = (clientId: string, name: string) => {
  return `!function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],c=0;c<i.length;c++){var s=i[c];a[s]=a[s]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(s)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var u=t.getElementsByTagName("script")[0];u.parentNode.insertBefore(o,u)}}(window,document,"${clientId}","${name}");`;
};

export const getCall = (
  name: string,
  purchaser: Collaborator,
  organization: Organization
) => {
  return `
    setTimeout(function() {
      console.log("Attempting To Show Survey: ${name}");
      ${name}.survey({
        email: "${purchaser.email}", 
        name: "${purchaser.firstName} ${purchaser.lastName}",               
        properties: {                      
            name: "${name}",
            organization: "${organization.name}",
        }
      });
    }, 500);
  `;
};

export const getDelightedCalls = (args: DelightedScriptArgs) => {
  const { organization, purchaser, pageSpecificActiveSurvey } = args;
  if (!organization || !purchaser) return null;

  let calls: string | null = '';
  if (!pageSpecificActiveSurvey) {
    SURVEY_CONFIG.keys.forEach((config) => {
      if (config.page === undefined) {
        calls += getCall(config.name, purchaser, organization);
      }
    });
  } else if (pageSpecificActiveSurvey) {
    SURVEY_CONFIG.keys.forEach((config) => {
      if (config.page === pageSpecificActiveSurvey) {
        calls += getCall(config.name, purchaser, organization);
      }
    });
  }
  return calls;
};

export const generateSurveyScripts = (): string[] => {
  const script: string[] = [];
  SURVEY_CONFIG.keys.forEach((global) => {
    script.push(getScript(global.clientId, global.name));
  });
  return script;
};

export const generateSurveyCalls = (
  pathname: string,
  purchaser: Collaborator,
  organization: Organization,
  navigator: Navigator
): string | null => {
  let surveys: string | null = null;
  if (pathname.match(navigator.pathToAssets())) {
    surveys = getDelightedCalls({
      purchaser,
      organization,
      pageSpecificActiveSurvey: 'assets',
    });
  } else if (pathname.match(navigator.pathToOrders())) {
    surveys = getDelightedCalls({
      purchaser,
      organization,
      pageSpecificActiveSurvey: 'orders',
    });
  } else if (pathname.match(navigator.pathToEmployees())) {
    surveys = getDelightedCalls({
      purchaser,
      organization,
      pageSpecificActiveSurvey: 'employees',
    });
  } else {
    surveys = getDelightedCalls({
      purchaser,
      organization,
      pageSpecificActiveSurvey: undefined,
    });
  }

  return surveys;
};

/* Delighted has no api support for closing a survey.
   Because of this, we must programatically click on the close button once its
   available in the dom.  Because the delighted snippet gets dynamically loaded
   on the dashboard, we must detect dom changes, and manually access the button
   element once its available, *and* we navigate away from a page.  The watchForAddedTarget
   uses MutationObserver api to do this.
 */
export const watchForAddedTarget = (
  targetRoot: string,
  target: string,
  callback: (mutation: MutationRecord, data: unknown) => void,
  parent: Node
) => {
  const config = {
    attributes: true,
    childList: true,
    subtree: true,
  };
  const observer = new MutationObserver((mutationList) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
        const nodes: HTMLElement[] = Array.from(
          mutation.addedNodes
        ) as HTMLElement[];
        for (let i = 0; i < nodes.length; i++) {
          const node = nodes[i];
          if (node && node.matches && node.matches(targetRoot)) {
            const foundNode = node.querySelector(target);
            callback(mutation, {
              foundNode,
            });
          }
        }
      }
    }
  });

  observer.observe(parent, config);
};
