import {
  HomeListItemProps as HomeListItem,
  LinkItemTuple,
} from 'pages/HomeUpdate/components/HomeListItem/types';

const ASSET_STATUS_NOTIFICATIONS_URL =
  'https://faq.allwhere.co/en_us/release-notes-asset-status-notifications-HyxYjV4bR';

const ASSET_STATUS_NOTE: LinkItemTuple = [
  ASSET_STATUS_NOTIFICATIONS_URL,
  'here',
  'allwhere will now notify the admins in our system of asset status changes so that our customers can have better visibility into assets that might require some additional attention. Additionally we have a number of exciting releases coming this week and next. To learn more about this and upcoming features, click ',
  '.',
];

export const WHATS_NEW_URL =
  'https://faq.allwhere.co/en_us/categories/release-notes-ByJaXpKZh';

const NO_BULLETS = { subListHasBullets: false };

export const CLIENT_ASSET_FILTER_ITEM: HomeListItem = {
  listItemTitle: 'Asset Filters Improved',
  listItemSubList: [
    [
      'https://faq.allwhere.co/en_us/release-notes-asset-filters-improved-B1a3EsKwR',
      'here',
      'We have created a new filter drawer that contains all asset filters. In addition there is new “Or” filtering functionality, alongside some additional filters! Click',
      'to learn more.',
    ],
  ],
  ...NO_BULLETS,
};

export const ASSET_STATUS_FILTER_ITEM: HomeListItem = {
  listItemTitle: 'Asset Status Notifications and more!',
  listItemSubList: [ASSET_STATUS_NOTE],
  ...NO_BULLETS,
};

export const WHATS_NEW_ITEMS: HomeListItem[] = [
  {
    listItemTitle: 'Asset Column Configurations',
    listItemSubList: [
      [
        'https://faq.allwhere.co/en_us/release-notes-asset-column-configurations-HyNzxzJU0',
        'here',
        'Customize the columns on the Asset Dashboard to better suit your needs. Click',
        'to learn more!',
      ],
    ],
    ...NO_BULLETS,
  },

  {
    listItemTitle: 'Performance Updates',
    listItemSubList: [
      [
        'https://faq.allwhere.co/en_us/release-notes-performance-updates-SyY4J9VSC',
        'here',
        'The loading time and stability of the Orders and Assets Dashboards has been greatly improved. Click',
        'to learn more!',
      ],
    ],
    ...NO_BULLETS,
  },
];

export const WHATS_NEW_TITLE_ID = 'home-update-whats-new-title';
