import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { BoxSx } from './styles';
import { QuickAssetDetailsProps } from './types';

const QuickAssetDetails = (props: QuickAssetDetailsProps) => {
  return (
    <Box sx={BoxSx}>
      {/* TODO: Add an asset image here when/if data model supports it */}
      <Typography variant="subtitle2">
        {props.device.make} {props.device.model}
      </Typography>
    </Box>
  );
};

export default QuickAssetDetails;
