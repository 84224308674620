import { gql } from '@apollo/client';

export const GET_ASSIGNEE_NAME_OPTIONS = gql`
  query getCollaboratorAutocompleteSuggestions(
    $searchTerm: String!
    $limit: Int = 15
  ) {
    getCollaboratorAutocompleteSuggestions(
      collaboratorFieldSuggestionsInput: {
        searchTerm: $searchTerm
        limit: $limit
        searchBy: "iLike"
        searchField: "firstOrLastName"
      }
    ) {
      fields
      matchIds
      matches
    }
  }
`;

export const GET_ASSIGNEE_EMAIL_OPTIONS = gql`
  query getCollaboratorAutocompleteSuggestions(
    $searchTerm: String!
    $limit: Int = 15
  ) {
    getCollaboratorAutocompleteSuggestions(
      collaboratorFieldSuggestionsInput: {
        searchTerm: $searchTerm
        limit: $limit
        searchBy: "iLike"
        searchField: "anyEmail"
      }
    ) {
      fields
      matchIds
      matches
    }
  }
`;
