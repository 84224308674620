import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const gerOrderItemCardSx = (isLoading: boolean = false): SxProps => ({
  '&:not(:last-child)': {
    mb: '16px',
  },
  border: `1px solid ${theme.palette.colors.grayD5}`,
  height: 'fit-content',
  opacity: isLoading ? 0.5 : 1,
  pointerEvents: isLoading ? 'none' : 'auto',
  position: 'relative',
  pt: '16px',
  px: '16px',
  width: '100%',
});

export const OrderItemCardSx: SxProps = {
  '&:not(:last-child)': {
    mb: '16px',
  },
  border: `1px solid ${theme.palette.colors.grayD5}`,
  height: '240px',
  pb: '8px',
  position: 'relative',
  pt: '16px',
  px: '16px',
  width: '100%',
};

export const OrderItemCardHeaderSx: SxProps = {
  p: 0,
  '& .MuiTypography-root': {
    fontSize: '16px',
    fontWeight: 700,
    px: 0,
    py: 0,
  },
};

export const OrderItemCardContentSx: SxProps = {
  m: 0,
  mt: '8px',
  p: 0,
  position: 'relative',

  '& .order-details-card-title': {
    color: '#949394',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '2em',
  },
  '&:last-child': {
    pb: '8px',
  },
};

export const OrderItemCardSingleSelectInputSx: SxProps = {
  borderRadius: 0,
  p: 0,
  '& .MuiSelect-select': {
    borderBottom: '1px solid',
    mr: '5px',
  },

  '& .MuiFormLabel-root': {
    backgroundColor: theme.palette.colors.white,
    px: '5px',
  },
};

export const OrderItemCardTextFieldSx: SxProps = {
  '& .MuiTextField-root': {
    backgroundColor: 'transparent',
    borderBottom: `1.5px solid ${theme.palette.colors.black}`,
    borderRadius: 0,
    p: 0,
  },

  '& .MuiFormLabel-root': {
    backgroundColor: theme.palette.colors.white,
  },

  '& .MuiInputBase-input': {
    pt: 0,
  },
};

export const OrderItemCardQuantityFieldSx: SxProps = {
  backgroundColor: 'transparent',
  maxWidth: '180px',
  p: 0,
  '&: .MuiFormHelperText-root': {
    color: 'transparent',
    position: 'absolute',
    bottom: '-20px',
  },
};

export const OrderItemCardDeleteButtonSx: SxProps = {
  color: theme.palette.colors.burntSiennaR,

  '& .MuiTypography-root': {
    lineHeight: '24px',
    pt: '6px',
  },
};

export const getOrderItemCardProtectionSx = (
  hasProtectionPlan: boolean
): SxProps => ({
  color: hasProtectionPlan
    ? theme.palette.colors.curiousBlue
    : theme.palette.colors.burntSiennaR,
  position: 'absolute',
  top: '16px',
  right: '16px',
  '& .MuiSvgIcon-root': {
    width: '36px',
    height: 'auto',
  },
  cursor: 'not-allowed',
});
