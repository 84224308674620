import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import NewModal from 'components/NewModal';
import { GreenCheckmark } from 'components/Icons';
import {
  CopySx,
  IconCloseIconButtonSx,
  NewModalPaperSx,
  TitleSx,
} from './styles';
import { DeviceAssignedConfirmationModalProps } from './types';
import { CLOSE_MODAL_AFTER_SECONDS } from 'global-constants';
import GAIconButton from 'components/buttons/google_analytics/GAIconButton';

const DeviceAssignedConfirmationModal = (
  props: DeviceAssignedConfirmationModalProps
) => {
  function closeModal() {
    // Since this component is `mounted` whether visible or not, this method can be invoked
    // thus closing other modals currently open
    if (props.open) {
      if (props.refetchSearchAssets) props.refetchSearchAssets();
      props.handleClose();
    }
  }

  setTimeout(closeModal, CLOSE_MODAL_AFTER_SECONDS);

  return (
    <NewModal
      open={props.open}
      handleClose={props.handleClose}
      paperPropsSx={NewModalPaperSx}
    >
      <GAIconButton
        gaContext={{
          navigates_to: 'N/A',
          textCopy: 'Close Icon',
          purpose: 'Closes Assign Device Modal',
        }}
        onClick={props.handleClose}
        sx={IconCloseIconButtonSx}
      >
        <CloseIcon />
      </GAIconButton>
      <Stack paddingTop="4rem">
        <GreenCheckmark />
        <Typography sx={TitleSx}>Device successfully assigned</Typography>
        <Typography sx={CopySx}>
          The device will be sent to the recipient after the address form is
          completed.
        </Typography>
      </Stack>
    </NewModal>
  );
};

export default DeviceAssignedConfirmationModal;
