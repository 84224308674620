import {
  INPUT,
  DEVICE_FILTER,
} from 'pages/Assets/components/DevicesDatagrid/components/DeviceFilters/constants';
import { AssetStatusMapping } from 'pages/Assets/constants';

export const STATUS = 'Status';
const LC_STATUS = STATUS.toLowerCase();

export const DeviceStatusFilterSettings = {
  placeholder: STATUS,
  label: STATUS,
  labelId: `${DEVICE_FILTER}${LC_STATUS}-${INPUT}-label`,
  inputId: `${DEVICE_FILTER}${LC_STATUS}-${INPUT}`,
  searchAssetsQueryField: LC_STATUS,
};

export const ASSET_STATUS_OPTIONS = Object.values(AssetStatusMapping);
export const ASSET_STATUS_OPTIONS_LOOKUP = Object.keys(
  AssetStatusMapping
).reduce((acc, assetStatusKey: string) => {
  const assetStatusValue =
    AssetStatusMapping[assetStatusKey as keyof typeof AssetStatusMapping];
  // eslint-disable-next-line security/detect-object-injection
  acc[assetStatusValue] = `MONO_${assetStatusKey}`;
  return acc;
}, {} as Record<string, string>);
