import { combineReducers, configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import {
  modalSlice,
  topNavSlice,
  notificationSlice,
  procurementToDepotSlice,
  assetManagementSlice,
  assetsSlice,
  ordersSlice,
  globalSlice,
} from './slices';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const rootReducer = combineReducers({
  modal: modalSlice.reducer,
  topNav: topNavSlice.reducer,
  notification: notificationSlice.reducer,
  procurementToDepot: procurementToDepotSlice.reducer,
  assetManagement: assetManagementSlice.reducer,
  global: globalSlice.reducer,
  assets: assetsSlice,
  orders: ordersSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat(sentryReduxEnhancer),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
