import { SxProps } from '@mui/material';
import {
  MAIN_STACK_SIZE_MD,
  MAIN_STACK_SIZE_SM,
} from 'components/NewModal/constants';

export const ArchiveModalPaperPropsSx: SxProps = {
  height: '348px',
  width: '532px',
};

export const ArchiveModalMainStackSx: SxProps = {
  height: {
    xs: MAIN_STACK_SIZE_SM,
    sm: MAIN_STACK_SIZE_SM,
    md: MAIN_STACK_SIZE_MD,
    lg: MAIN_STACK_SIZE_MD,
    xl: MAIN_STACK_SIZE_MD,
  },
};

export const ArchiveModalTitleSx: SxProps = {
  fontWeight: 700,
  lineHeight: '38.4px',
};

export const ArchiveModalButtonBaseSx: SxProps = {
  padding: '6px 16px',
  width: 'fit-content',
  height: '36px',
  '& .MuiTypography-root': {
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '24px',
  },
  '&#cancel-button': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};
