import { PremiumShippingTypeNames } from 'enums';
import { EARLIEST_DELIVERY_DATE } from './constants';

export const getPremiumShippingDisplayInfo = (
  premiumShippingName: string,
  options: { lookup: Record<string, any> }
) => {
  const tempInfo = options.lookup[premiumShippingName];
  const {
    shippingTypeName,
    description,
    price,
    formattedDate,
    shippingTypeId,
  } = tempInfo;

  const title =
    PremiumShippingTypeNames.OVERNIGHT === shippingTypeName
      ? `Request ${shippingTypeName.toLowerCase()} shipping`
      : `${shippingTypeName} shipping`;

  const subTexts = description.split(';') as string[];
  const bottomText = price === '0.00' ? 'Free shipping' : `+$${price}`;

  return {
    shippingTypeId,
    title,
    subTexts,
    bottomText,
    bottomSubText: `${EARLIEST_DELIVERY_DATE} ${formattedDate}`,
  };
};
