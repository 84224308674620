import React from 'react';
import { GridPaginationModel, useGridApiRef } from '@mui/x-data-grid-pro';
import { DEFAULT_PAGINATION_MODEL } from 'global-constants';
import { SortOrder } from 'types';
import { OrdersDataGridState } from './types';
import { DEFAULT_ORDERS_DASH_SORT_MODEL } from 'pages/Orders/constants';

const useGetOrdersDataGridState = (): OrdersDataGridState => {
  const apiRef = useGridApiRef();
  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>(DEFAULT_PAGINATION_MODEL);
  const [ordersSort, setOrdersSort] = React.useState<SortOrder>(
    DEFAULT_ORDERS_DASH_SORT_MODEL
  );

  return {
    apiRef,
    paginationModel,
    setPaginationModel,
    ordersSort,
    setOrdersSort,
  };
};

export default useGetOrdersDataGridState;
