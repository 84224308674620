/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { nanoid } from '@reduxjs/toolkit';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ProcurementFlowStep } from 'pages/Procurement/hooks/useProcurementService/constants';
import * as BuyAndHoldStyles from 'pages/BuyAndHold/styles';
import { Button, Footer } from 'components';
import BuyAndHoldBanner from 'pages/BuyAndHold/components/Banner';
import BuyAndHoldCTA from './components/BuyAndHoldCTA';
import DepotTable from './components/DepotTable';
import { DepotViewTestId } from './constants';
import * as DepotViewStyles from './styles';
import { DepotViewProps } from './types';

const DepotView = (props: DepotViewProps) => {
  const parentStyles = BuyAndHoldStyles;
  const styles = DepotViewStyles;
  const [depotTableKey, setDepotTableKey] = React.useState<string>(
    'depot-table-key-unique'
  );

  const onCancelButtonClick = () => {
    setDepotTableKey(nanoid());
    props.workflow.removeSelectedDepot();
  };

  const onMarketplaceButtonClick = () => {
    props.workflow.updateProcurementFlowStep(
      ProcurementFlowStep.PRODUCT_CATALOG
    );
  };

  const showDepotSelectFooter = () =>
    props.workflow.selectedDepotLocation?.id &&
    props.workflow.flowStep === ProcurementFlowStep.COLLABORATOR_VIEW;

  return (
    <>
      <Container
        data-testid={DepotViewTestId.MAIN_TEST_ID}
        sx={parentStyles.MainContainerSx}
      >
        <Stack
          sx={parentStyles.getContentStackSx()}
          direction="column"
          data-testid={DepotViewTestId.CONTENTS_TEST_ID}
        >
          <BuyAndHoldBanner />
          <Stack
            sx={styles.DepotViewLowerStackSx}
            data-testid={DepotViewTestId.DEPOT_UNIQUE_CONTENTS_STACK_TEST_ID}
          >
            <DepotTable key={depotTableKey} workflow={props.workflow} />
            <BuyAndHoldCTA />
          </Stack>
        </Stack>
      </Container>
      {showDepotSelectFooter() ? (
        <Footer>
          <Container>
            <Typography variant="caption" color="colors.offWhite">
              1 depot selected
            </Typography>
          </Container>
          <Button
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Cancel',
              purpose: 'Cancels Depot Selection',
            }}
            variant="outlined"
            onClick={onCancelButtonClick}
          >
            Cancel
          </Button>
          <Button
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Continue to marketplace',
              purpose: 'Continues to marketplace',
            }}
            variant="contained"
            sx={styles.DepotViewContinueButtonSx}
            onClick={onMarketplaceButtonClick}
          >
            <Typography>Continue to marketplace</Typography>
          </Button>
        </Footer>
      ) : null}
    </>
  );
};

export default DepotView;
