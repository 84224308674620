import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DeviceFormProps } from './types';
import {
  ReadOnlyStackSx,
  ReadOnlyTextLabel,
  ReadOnlyTextValue,
  TextFieldSx,
} from './styles';
import { AssetConditionEnum, AssetStatusEnum } from 'global-constants';
import { AssetType as AssetTypeShape } from 'types';
import EmployeeSearch from 'components/EmployeeSearch';
import { GET_ALL_ASSET_TYPES } from './queries';
import { useSafeQuery } from 'hooks/useSafeQuery';

const DeviceForm = (props: DeviceFormProps) => {
  const { data, loading: loadingAssetTypes } =
    useSafeQuery(GET_ALL_ASSET_TYPES);

  const assetTypeOptions = data?.getAllAssetTypes || [];

  const handleAssetStatusChange = (e: SelectChangeEvent<string>) => {
    props.deviceModalService.setAssetStatus(e.target.value);
  };

  const handleCosmeticConditionChange = (e: SelectChangeEvent<string>) => {
    props.deviceModalService.setCosmeticCondition(
      e.target.value as AssetConditionEnum
    );
  };

  const handleAssetTypeChange = (e: SelectChangeEvent<string>) => {
    const assetTypeIdValue = e.target.value;
    const assetType = assetTypeOptions.find(
      (assetType: AssetTypeShape) => assetType.id === assetTypeIdValue
    );
    props.deviceModalService.setAssetType(assetType);
  };

  const getPurchaseDateValue = () =>
    props.deviceModalService.purchaseDate
      ? new Date(props.deviceModalService.purchaseDate)
          .toISOString()
          .split('T')[0]
      : undefined;

  const getWarrantyExpirationValue = () =>
    props.deviceModalService.warrantyExpiration
      ? new Date(props.deviceModalService.warrantyExpiration)
          .toISOString()
          .split('T')[0]
      : undefined;

  const assetConditionOptions = Object.entries(AssetConditionEnum);

  const enforceRequiredFields = props.context === 'add';

  React.useEffect(() => {
    if (props.context === 'add') {
      props.deviceModalService.clearForm();
    }
  }, [props.context]);

  return (
    <Stack>
      <Stack mb="14px">
        <Typography variant="subtitle2">Allwhere info</Typography>
      </Stack>
      <Grid container columnSpacing={'1rem'} rowSpacing={'1.5rem'}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Stack sx={ReadOnlyStackSx}>
            <Typography sx={ReadOnlyTextLabel}>Asset number</Typography>
            <Typography sx={ReadOnlyTextValue}>
              {props.device?.assetNumber || 'N/A'}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={5}>
          <FormControl sx={{ maxWidth: '250px', width: '100%' }}>
            <InputLabel id="device-status-label">
              {enforceRequiredFields ? 'Status *' : 'Status'}
            </InputLabel>
            <Select
              labelId="device-status-label"
              id="device-status-select"
              value={props.deviceModalService.assetStatus}
              variant="outlined"
              label="Status"
              onChange={handleAssetStatusChange}
              required={enforceRequiredFields}
            >
              {Object.entries(AssetStatusEnum).map(([key, value]) => (
                <MenuItem value={key}>{value}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={4}>
          <Stack sx={ReadOnlyStackSx}>
            <Typography sx={ReadOnlyTextLabel}>
              Last allwhere order item
            </Typography>
            <Typography sx={ReadOnlyTextValue}>
              {props.device?.lastOrderItemNumber ?? 'N/A'}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Stack mt="16px" mb="14px">
        <Typography variant="subtitle2">General</Typography>
      </Stack>
      <Grid container columnSpacing={'2rem'} rowSpacing={'1rem'}>
        <Grid item xs={12} sm={3}>
          <EmployeeSearch
            selectedEmployee={props.deviceModalService.selectedEmployee}
            setSelectedEmployee={props.deviceModalService.setSelectedEmployee}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Stack sx={ReadOnlyStackSx}>
            <Typography sx={ReadOnlyTextLabel}>Depot location</Typography>
            <Typography sx={ReadOnlyTextValue}>
              {props.device?.depot?.name ?? 'N/A'}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Stack mt="16px" mb="14px">
        <Typography variant="subtitle2">Device specs</Typography>
      </Stack>
      <Grid container rowSpacing={'1.5rem'}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="serial-number-text-field"
            label="Serial number"
            value={props.deviceModalService.serialNumber}
            onChange={(e) =>
              props.deviceModalService.setSerialNumber(e.target.value)
            }
            sx={TextFieldSx}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl sx={{ maxWidth: '300px', width: '100%' }}>
            <InputLabel id="asset-type-label">
              {enforceRequiredFields ? 'Type *' : 'Type'}
            </InputLabel>
            <Select
              labelId="asset-type-label"
              id="asset-type-select"
              value={props.deviceModalService.assetType?.id}
              variant="outlined"
              label="Type"
              onChange={handleAssetTypeChange}
              required={enforceRequiredFields}
              aria-required={enforceRequiredFields}
            >
              {assetTypeOptions.map((assetType: AssetTypeShape) => (
                <MenuItem key={assetType.id} value={assetType.id}>
                  {assetType.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl sx={{ maxWidth: '300px', width: '100%' }}>
            <InputLabel id="cosmetic-condition-label">Condition</InputLabel>
            <Select
              labelId="cosmetic-condition-label"
              id="cosmetic-condition-select"
              value={props.deviceModalService.cosmeticCondition}
              variant="outlined"
              label="Condition"
              onChange={handleCosmeticConditionChange}
            >
              {assetConditionOptions.map(([key, value]) => (
                <MenuItem key={key} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="asset-make-text-field"
            label="Make"
            value={props.deviceModalService.make}
            onChange={(e) => props.deviceModalService.setMake(e.target.value)}
            sx={TextFieldSx}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="asset-model-text-field"
            label="Model"
            value={props.deviceModalService.model}
            onChange={(e) => props.deviceModalService.setModel(e.target.value)}
            sx={TextFieldSx}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="asset-assessment-text-field"
            label="Device Issue"
            value={props.deviceModalService.deviceAssessment}
            onChange={(e) =>
              props.deviceModalService.setDeviceAssessment(e.target.value)
            }
            sx={TextFieldSx}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="asset-color-text-field"
            label="Color"
            value={props.deviceModalService.color}
            onChange={(e) => props.deviceModalService.setColor(e.target.value)}
            sx={TextFieldSx}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="asset-display-size-text-field"
            label="Display size"
            value={props.deviceModalService.displaySize}
            onChange={(e) =>
              props.deviceModalService.setDisplaySize(e.target.value)
            }
            sx={TextFieldSx}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="asset-memory-text-field"
            label="Memory"
            value={props.deviceModalService.memory}
            onChange={(e) => props.deviceModalService.setMemory(e.target.value)}
            sx={TextFieldSx}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="asset-storage-text-field"
            label="Storage"
            value={props.deviceModalService.storage}
            onChange={(e) =>
              props.deviceModalService.setStorage(e.target.value)
            }
            sx={TextFieldSx}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="asset-processor-text-field"
            label="Processor"
            value={props.deviceModalService.processor}
            onChange={(e) =>
              props.deviceModalService.setProcessor(e.target.value)
            }
            sx={TextFieldSx}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="asset-keyboard-configuration-text-field"
            label="Keyboard configuration"
            value={props.deviceModalService.keyboard}
            onChange={(e) =>
              props.deviceModalService.setKeyboard(e.target.value)
            }
            sx={TextFieldSx}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="asset-purchase-date-text-field"
            type="date"
            size="medium"
            variant="outlined"
            label="Purchase date"
            InputLabelProps={{
              shrink: true,
              placeholder: '',
            }}
            value={getPurchaseDateValue()}
            onChange={(e) =>
              props.deviceModalService.setPurchaseDate(e.target.value)
            }
            sx={TextFieldSx}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="asset-warranty-expiration-text-field"
            type="date"
            size="medium"
            variant="outlined"
            label="Warranty expiration"
            InputLabelProps={{
              shrink: true,
              placeholder: '',
            }}
            value={getWarrantyExpirationValue()}
            onChange={(e) =>
              props.deviceModalService.setWarrantyExpiration(e.target.value)
            }
            sx={TextFieldSx}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default DeviceForm;
