import { formatDate } from 'services';
import { SortOrder } from 'types';

export const flattenDevicesData = (data: any) => {
  const {
    inventoryLog,
    depot,
    assetType,
    assignee,
    purchaseDate,
    warrantyExpiration,
    ...restOfData
  } = data;
  const hasLastCheckIn = Boolean(inventoryLog?.dateOfLastCheckIn ?? 0);
  const hasLastCheckOut = Boolean(inventoryLog?.dateOfLastCheckOut ?? 0);
  const hasDepotLocation = Boolean(depot?.name ?? 0);
  const hasAssignee = Boolean(assignee?.id ?? 0);

  return {
    ...restOfData,
    dateOfLastCheckIn: hasLastCheckIn ? inventoryLog.dateOfLastCheckIn : '',
    dateOfLastCheckOut: hasLastCheckOut
      ? inventoryLog.dateOfLastCheckOut
      : null,
    assignee: hasAssignee ? `${assignee.firstName} ${assignee.lastName}` : '',
    assigneeEmail: hasAssignee ? assignee.email : '',
    depotLocation: hasDepotLocation ? depot.name : '',
    type: assetType.name || '',
    purchaseDateDisplay: renderDisplayDate(purchaseDate),
    warrantyExpirationDisplay: renderDisplayDate(warrantyExpiration),
  };
};

const renderDisplayDate = (rawValue: string) => {
  let dateDisplay = '-';
  if (rawValue) {
    const cutDate = rawValue.split('T')[0];
    dateDisplay = formatDate(cutDate, 'MM/DD/YYYY');
  }
  return dateDisplay;
};

export const getSortParams = (sortOrder: SortOrder) => {
  const { direction, field } = sortOrder;
  return { [direction]: field };
};
