/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Button, NewModal } from 'components';
import { ARE_YOU_SURE_TEXT, CHANGES_REFLECTED_TEXT, HEADER } from './constants';
import * as styles from './styles';
import { SaveConfirmationModalProps } from './types';

const SaveConfirmationModal = (props: SaveConfirmationModalProps) => {
  if (!props.isOpen) return null;

  return (
    <NewModal
      open={props.isOpen}
      handleClose={props.handleClose}
      paperPropsSx={styles.PaperSx}
    >
      <Stack
        direction="column"
        spacing={0}
        justifyContent="space-between"
        height="100%"
      >
        <Stack rowGap="1rem">
          <Typography variant="h4" sx={styles.VariantH4Sx}>
            {HEADER}
          </Typography>
          <Typography variant="body1">
            <div>{ARE_YOU_SURE_TEXT}</div>
            <div>{CHANGES_REFLECTED_TEXT}</div>
          </Typography>
        </Stack>
        <Stack sx={styles.ButtonGroupSx}>
          <Button
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Continue editing',
              purpose: 'Continues to edit asset',
            }}
            variant="text"
            onClick={props.handleClose}
            sx={{ justifyContent: 'flex-end' }}
          >
            <Typography variant="button" sx={styles.CancelTextSx}>
              Continue editing
            </Typography>
          </Button>
          <Button
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Save changes',
              purpose: 'Save Asset Modifications',
            }}
            onClick={props.handleSave}
            variant="contained"
          >
            <Typography variant="button" sx={styles.BaseButtonTextSx}>
              Save changes
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </NewModal>
  );
};

export default SaveConfirmationModal;
