import { SxProps } from '@mui/material';

export const ProductQuantityInputSx: SxProps = {
  backgroundColor: 'transparent',
  width: '100px',
  p: 0,
  '&: .MuiFormHelperText-root': {
    color: 'transparent',
    position: 'absolute',
    bottom: '-20px',
  },
};
