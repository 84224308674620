import { SxProps } from '@mui/material';

export const BoxSx: SxProps = {
  border: '1px solid #C2C2C2',
  borderLeft: 'none',
  borderRight: 'none',
  padding: '12px 0',

  '& .device-info-outer-stack': {
    gap: {
      xs: 10,
      sm: 15,
    },
  },

  '& .device-info-header': {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '24px',
    color: 'colors.gray94',
  },

  '& .device-info-content': {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '22.88px',
  },
};
