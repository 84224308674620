/* eslint-disable sonarjs/cognitive-complexity */
import { capitalizeWords, formatToMonthDayYear } from 'services';
import { DEFAULT_BLANK_TABLE_CELL_VALUE } from 'global-constants';
import { EmployeeDetailsDataType, EmployeeDetailsTextContent } from './types';
import { DEFAULT_EMPLOYEE_DETAILS_TEXT_CONTENT } from './constants';

const formatAddress = (address: any): string => {
  if (!address) return '-';
  const {
    streetAddress1,
    streetAddress2,
    country,
    city,
    state,
    principalRegion,
    zipCode,
  } = address;
  return `${streetAddress1}, \n${
    streetAddress2 ? `${streetAddress2}\n` : ''
  } ${city}, ${
    capitalizeWords(state || '') || capitalizeWords(principalRegion || '')
  } ${zipCode}\n${capitalizeWords(country)}`;
};

export const formatEmployeeDetails = (
  employeeInfo: EmployeeDetailsDataType
): EmployeeDetailsTextContent => {
  if (!employeeInfo) return DEFAULT_EMPLOYEE_DETAILS_TEXT_CONTENT;
  const formattedHomeAddresses = [];
  const formattedWorkAddresses = [];

  const formattedValue = {
    ...DEFAULT_EMPLOYEE_DETAILS_TEXT_CONTENT,
  };

  formattedValue.firstName = employeeInfo.firstName;
  formattedValue.lastName = employeeInfo.lastName;
  formattedValue.fullName = `${employeeInfo.firstName} ${employeeInfo.lastName}`;
  // formattedValue.birthday = employeeInfo.birthday
  //   ? formatDate(employeeInfo.birthday, MM_DD_YYYY_SLASH_FORMAT)
  //   : '-';
  formattedValue.email = employeeInfo.email || '-';
  formattedValue.personalEmail = employeeInfo.personalEmail || '-';
  formattedValue.workEmail = employeeInfo.workEmail || '-';
  formattedValue.phoneNumber = employeeInfo.phoneNumber || '-';
  formattedValue.homeAddress = '-';
  formattedValue.workAddress = '-';
  formattedValue.team = employeeInfo.group?.join(', ') || '-';
  formattedValue.hireDate = employeeInfo.hireDate
    ? formatToMonthDayYear(employeeInfo.hireDate)
    : DEFAULT_BLANK_TABLE_CELL_VALUE;
  formattedValue.startDate = employeeInfo.startDate
    ? formatToMonthDayYear(employeeInfo.startDate)
    : DEFAULT_BLANK_TABLE_CELL_VALUE;

  if (Array.isArray(employeeInfo.homeAddresses)) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < employeeInfo.homeAddresses.length; i++) {
      const formattedHomeAddress = formatAddress(employeeInfo.homeAddresses[i]);
      formattedHomeAddresses.push(formattedHomeAddress);
    }
  }

  if (Array.isArray(employeeInfo.workAddresses)) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < employeeInfo.workAddresses.length; i++) {
      const formattedWorkAddress = formatAddress(employeeInfo.workAddresses[i]);
      formattedWorkAddresses.push(formattedWorkAddress);
    }
  }

  if (formattedHomeAddresses.length) {
    formattedValue.homeAddresses = formattedHomeAddresses;
  }

  if (formattedWorkAddresses.length) {
    formattedValue.workAddresses = formattedWorkAddresses;
  }

  return formattedValue;
};
