/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';
import moment from 'moment';
import { useTheme } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {
  GridActionsCellItem,
  GridColDef,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from '@mui/x-data-grid-pro';
import { NavigationContext } from 'context/NavigationContext';
import Avatar from 'components/Avatar';
import OrderStatusPill from 'components/OrderStatusPill';
import { OrderStatusMapping, OrderTypeOptions } from 'pages/Orders/constants';
import { formatDate } from 'services/date';
import useMediaQuery from '@mui/material/useMediaQuery';

export const useGetOrdersColumns = (
  canViewDetails: boolean,
  columnOptions: {
    rowHoveringModel: string;
    onFollowUpView?: boolean;
  } = {
    rowHoveringModel: '',
    onFollowUpView: false,
  }
): GridColDef[] => {
  const theme = useTheme();
  const navigator = React.useContext(NavigationContext);

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  let orderColumns = [
    {
      field: 'orderNumber',
      headerName: 'Order number',
      flex: 1,
      renderCell: (data: any) => {
        const numberOfOrderItems = data.row.orderItems?.length ?? 0;
        const { orderNumber } = data.row;

        return (
          <Stack direction="row" spacing={1}>
            <Avatar
              text={
                <Typography variant="caption">{numberOfOrderItems}</Typography>
              }
              width={24}
              height={24}
              color={theme.palette.colors.white}
              bgcolor={theme.palette.colors.grayBD}
            />
            <Typography variant="body2">{orderNumber}</Typography>
          </Stack>
        );
      },
    },
    {
      field: 'orderType',
      headerName: 'Type',
      flex: isLargeScreen ? 2 : 1,
      filterable: false,
      type: 'singleSelect',
      sortable: false,
      valueOptions: Object.entries(OrderTypeOptions).map(([value, label]) => {
        return {
          label,
          value,
        };
      }),
      renderCell: (data: any) => {
        return (
          <Typography variant="body2">{data.row.orderType.name}</Typography>
        );
      },
    },
    {
      field: 'recipientName',
      headerName: 'Recipient',
      flex: 1,
      filterable: false,
      sortable: false,
      valueGetter(params: any) {
        if (params.row.dropRecipient) {
          return `${params.row.dropRecipient.firstName} ${params.row.dropRecipient.lastName}`;
        }
        if (params.row.recipient) {
          return `${params.row.recipient.firstName} ${params.row.recipient.lastName}`;
        }
        return '';
      },
    },
    {
      field: 'createdAt',
      headerName: 'Order date',
      flex: isLargeScreen ? 2 : 1,
      type: 'date',
      filterable: false,
      renderCell: (data: any) => {
        const formattedDate = formatDate(data.value);

        return <Typography variant="body2">{formattedDate}</Typography>;
      },
      valueGetter(params: any) {
        return new Date(formatDate(params.row.createdAt));
      },
    },
    {
      field: 'finalDestination',
      headerName: 'Final destination',
      flex: isLargeScreen ? 2 : 1,
      filterable: false,
      sortable: false,
      valueGetter(params: any) {
        if (params.row?.finalDestination) {
          return params.row?.finalDestination;
        }
        return '';
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      type: 'singleSelect',
      sortable: false,
      filterable: false,
      valueOptions: Object.entries(OrderStatusMapping).map(([value, label]) => {
        return {
          label,
          value,
        };
      }),
      renderCell: (data: any) => {
        return <OrderStatusPill showClientStatus status={data.value} />;
      },
    },
    {
      // Moves the row expand/collapse to the right since defined last here
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    },
  ];

  if (columnOptions.onFollowUpView) {
    orderColumns.splice(3, 0, {
      field: 'recipientEmail',
      headerName: 'Contact email',
      flex: 1,
      filterable: false,
      sortable: false,
      valueGetter(params: any) {
        if (params.row.recipient?.email) {
          return params.row.recipient.email;
        }
        return '';
      },
    });

    orderColumns.splice(5, 0, {
      field: 'daysUntilExpirationDate',
      headerName: 'Days until expiration',
      flex: 1,
      filterable: false,
      sortable: false,
      valueGetter(params: any) {
        if (params.row.lastOrderStatusTransition?.createdAt) {
          const dateString = (
            params.row.lastOrderStatusTransition?.createdAt ?? ''
          ).split('T')[0];
          const daysPassed = moment().diff(dateString, 'days');
          return daysPassed >= 30 ? 0 : 30 - daysPassed;
        }
        return '';
      },
    });
  }

  if (canViewDetails) {
    orderColumns.splice(1, 0, {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    });
    orderColumns.splice(orderColumns.length - 1, 1);
    orderColumns = [
      ...orderColumns,
      {
        field: 'details',
        headerName: '',
        type: 'string',
        width: 120,
        filterable: false,
        sortable: false,
        renderCell(params: any) {
          const isRowHovered = params.id === columnOptions.rowHoveringModel;
          const handleCellClick = (event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            if (!isRowHovered) return;

            const securePathToOrderDetails =
              navigator.constructSecurePathToOrderDetails(
                params.id.toString(),
                params.row.organizationId
              );

            window.open(securePathToOrderDetails, '_blank');
          };

          return (
            <Tooltip title="Order Details" arrow>
              <span
                onClick={handleCellClick}
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                }}
              >
                {isRowHovered ? (
                  <>
                    <InfoOutlinedIcon
                      fontSize="small"
                      style={{ marginRight: '4px', cursor: 'pointer' }}
                    />
                    <span
                      className="details-text"
                      style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        borderBottom: '1.5px solid rgba(0, 0, 0, 0.4)',
                      }}
                    >
                      <Typography
                        style={{ fontWeight: 'bold', cursor: 'pointer' }}
                      >
                        Details
                      </Typography>
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </span>
            </Tooltip>
          );
        },
      },
    ];
  }
  return orderColumns;
};
