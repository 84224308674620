import { POPOVER_MAX_WIDTH_PAPER } from '../../UploadPopover/styles';

export const UploadHistoryContainerSx = {
  display: 'flex',
  width: POPOVER_MAX_WIDTH_PAPER,
  height: 'auto',
  overflow: 'auto',
  px: '24px',
  mb: '24px',
};

export const NoUploadsContainerSx = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  mt: '50px',
};

export const NoUploadsTextSx = {
  fontSize: '20px',
};
