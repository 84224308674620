import { SxProps } from '@mui/material';
import theme from 'themes/theme';
import { FilterDrawerBookendSx } from 'components/complex_filter/ComplexFilterDrawer/styles';

export const FilterDrawerTopBoxSx: SxProps = {
  borderBottom: `1px solid #c9c9c9`,
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  height: '64px',
  justifyContent: 'space-between',
  ...FilterDrawerBookendSx,
};

export const FilterTitleSx: SxProps = {
  fontFamily: 'PPPangramSans',
  fontSize: '16px',
  fontWeight: 700,
};
