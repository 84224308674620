const NoFlagSVGIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="none"
    >
      <rect fill="#BDBDBD" width="28" height="20" />
    </svg>
  );
};

export default NoFlagSVGIcon;
