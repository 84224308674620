import { SxProps } from '@mui/material/styles';

export const SwipeableImagesViewSx: SxProps = {
  maxWidth: {
    xs: 275,
    xl: 500,
  },
  maxHeight: {
    xs: 275,
    xl: 500,
  },
  marginLeft: '4rem',
  marginTop: '2.5rem',
  marginBottom: '1rem',
};
