/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { BillingModalContext } from 'context/BillingModalProvider';
import {
  BillingDialogSx,
  BillingMainStackSx,
  BillingMainTitleSx,
  BillingDialogPaperSx,
  BillingUseAcctTextSx,
  BillingConsentSx,
  BillingCheckboxSx,
  BillingCancelButtonSx,
  BillingSubmitButtonSx,
  BillingQuestionTextSx,
  BillingConsentCheckboxTextSx,
} from './styles';
import { BillingFormValues } from './types';
import { BILLING_CHECKBOX, BILLING_MODAL_COPY } from './constants';
import BillingModalSpacer from './components/BillingModalSpacer';
import BillingForm from './components/BillingForm';

const BillingStatusDialog = (props: any) => {
  const billingContext = React.useContext(BillingModalContext);
  const handleClose = () => billingContext.setOpen(false);

  const [checkedUseAccount, setCheckedUseAccount] =
    React.useState<boolean>(false);
  const [checkedConsentUse, setCheckedConsentUse] =
    React.useState<boolean>(false);

  const handleFormSubmit = (formData: BillingFormValues | FieldValues) => {
    const { accountName, accountType, routingNumber, accountNumber } = formData;
    billingContext.postBillingInfoQuery({
      accountName,
      accountType,
      routingNumber,
      accountNumber,
    });
    handleClose();
  };

  const {
    control,
    getValues,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<BillingFormValues | FieldValues>({
    defaultValues: {
      accountName: billingContext.accountName,
      accountNumber: billingContext.accountNumber,
      accountType: billingContext.accountType,
      routingNumber: billingContext.routingNumber,
      confirmAccountNumber: billingContext.accountNumber,
      confirmRoutingNumber: billingContext.routingNumber,
    },
  });

  const watchAll = watch();

  const handleCheckboxChangeHoF =
    (originCheckbox: string) =>
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (originCheckbox === BILLING_CHECKBOX.USE_ACCOUNT) {
        setCheckedUseAccount(checked);
      }
      if (originCheckbox === BILLING_CHECKBOX.CONSENT_USE) {
        setCheckedConsentUse(checked);
      }
    };

  const disabledSubmit = !(
    checkedUseAccount &&
    checkedConsentUse &&
    Object.values(watchAll).every((watchItem) => !!watchItem)
  );

  return (
    <Dialog
      open={billingContext.open}
      sx={BillingDialogSx}
      onClose={handleClose}
      PaperProps={{ sx: { ...BillingDialogPaperSx } }}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack
          direction="column"
          spacing={0}
          justifyContent="space-between"
          sx={BillingMainStackSx}
        >
          <Stack direction="column" spacing={0}>
            <Typography variant="h5" sx={BillingMainTitleSx}>
              {BILLING_MODAL_COPY.MAIN_TEXT}
            </Typography>
            <Typography>{BILLING_MODAL_COPY.SUB_TEXT}</Typography>
            <BillingForm
              control={control}
              errors={errors}
              getValues={getValues}
              register={register}
            />
            <BillingModalSpacer myHeight="16px" />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    aria-checked={checkedUseAccount}
                    checked={checkedUseAccount}
                    disableRipple
                    sx={BillingCheckboxSx}
                    onChange={handleCheckboxChangeHoF(
                      BILLING_CHECKBOX.USE_ACCOUNT
                    )}
                  />
                }
                label={
                  <Typography sx={BillingUseAcctTextSx}>
                    {BILLING_MODAL_COPY.USE_CHECKBOX_TEXT}
                    <span>{BILLING_MODAL_COPY.USE_CHECKBOX_SUB_TEXT}</span>
                  </Typography>
                }
              />
            </FormGroup>
            <BillingModalSpacer />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    aria-checked={checkedConsentUse}
                    checked={checkedConsentUse}
                    disableRipple
                    sx={BillingCheckboxSx}
                    onChange={handleCheckboxChangeHoF(
                      BILLING_CHECKBOX.CONSENT_USE
                    )}
                  />
                }
                label={
                  <Typography sx={BillingConsentCheckboxTextSx}>
                    {BILLING_MODAL_COPY.CONSENT_CHECKBOX_TEXT}
                  </Typography>
                }
                sx={BillingConsentSx}
              />
            </FormGroup>
          </Stack>
          <Stack
            direction="row"
            spacing={0}
            justifyContent="space-between"
            sx={{ width: '100%' }}
          >
            <Box sx={{ width: '250px', height: '40px' }}>
              <Typography sx={BillingQuestionTextSx}>
                If you have any questions, please reach out to{' '}
                <Link
                  href="mailto:billing@allwhere.co"
                  target="_blank"
                  rel="noopener"
                >
                  billing@allwhere.co
                </Link>
              </Typography>
            </Box>
            <DialogActions>
              <Button
                variant="text"
                onClick={handleClose}
                sx={BillingCancelButtonSx}
              >
                NOT RIGHT NOW
              </Button>
              <Button
                disabled={disabledSubmit}
                variant="contained"
                sx={BillingSubmitButtonSx}
                value="submit"
                type="submit"
              >
                SAVE INFORMATION
              </Button>
            </DialogActions>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  );
};

export default BillingStatusDialog;
