/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import GenericAutocomplete from 'components/GenericAutocomplete';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import useDebouncedSearch from 'hooks/useDebouncedSearch';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import {
  DEVICE_FILTERS_WIDTH,
  DEVICE_FILTERS_HEIGHT,
} from 'pages/Assets/components/DevicesDatagrid/components/DeviceFilters/constants';
import { GET_ASSETS_BY_STORAGE } from './queries';
import { AssetStorageFilterProps } from './types';
import { ASSET_FILTER_INPUT_ID, ASSET_TEXTFIELD_LABEL } from './constants';
import * as AssetStorageFilterStyles from './styles';

const AssetStorageFilter = (props: AssetStorageFilterProps) => {
  const styles = AssetStorageFilterStyles;
  const organization = useGetUserOrganization();
  const [assetStorage, setAssetStorage] = React.useState('');
  const [displaySizeOptions, setDisplaySizeOptions] = React.useState<string[]>(
    []
  );
  const debouncedAssetStorage = useDebouncedSearch(assetStorage, 300);

  const [fetchAssetsByStorage] = useSafeLazyQuery(GET_ASSETS_BY_STORAGE, {
    onCompleted(data) {
      const { searchAssets } = data;
      const assetSet = new Set<string>();
      const assets = searchAssets?.assets || [];
      if (!assets.length) return;
      const tempOptions = assets
        .map((asset: any) => asset.storage)
        .filter((storage: string) => {
          if (assetSet.has(storage)) return false;
          assetSet.add(storage);
          return true;
        });

      setDisplaySizeOptions(tempOptions);
    },
  });

  const handleInputChange = (_e: any, value: any) => {
    setAssetStorage(value);
  };

  const clearAllAutoCompleteOptions = () => {
    const clearTextIconButton: HTMLElement = document.querySelector(
      `#${ASSET_FILTER_INPUT_ID} .MuiAutocomplete-clearIndicator`
    ) as HTMLElement;
    if (clearTextIconButton) {
      clearTextIconButton.click();
    }
    setAssetStorage('');
    setDisplaySizeOptions([]);
    props.setChosenAssetStorage('');
  };

  const handleAutoCompleteChange = (event: any, value: any) => {
    if (value === null) {
      clearAllAutoCompleteOptions();
      return;
    }

    props.setChosenAssetStorage(value);
  };

  React.useEffect(() => {
    if (!organization?.id || !debouncedAssetStorage) return;
    fetchAssetsByStorage({
      variables: {
        organizationId: organization.id,
        iLike: {
          field: 'storage',
          value: `%${debouncedAssetStorage}%`,
        },
      },
    });
  }, [organization?.id, debouncedAssetStorage]);

  React.useEffect(() => {
    if (props.chosenAssetStorage) return;
    clearAllAutoCompleteOptions();
  }, [props.chosenAssetStorage]);

  return (
    <Stack
      direction="column"
      sx={styles.AutoCompleteStackSx}
      id={ASSET_FILTER_INPUT_ID}
    >
      <GenericAutocomplete
        loading={false}
        onChange={handleAutoCompleteChange}
        onInputChange={handleInputChange}
        componentsProps={{
          popper: {
            style: {
              width: DEVICE_FILTERS_WIDTH,
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Start typing"
            label={ASSET_TEXTFIELD_LABEL}
            aria-label={ASSET_TEXTFIELD_LABEL}
            sx={styles.AutoCompleteSearchTextFieldSx}
          />
        )}
        sx={styles.AutoCompleteSx}
        options={displaySizeOptions}
      />
    </Stack>
  );
};

export default AssetStorageFilter;
