import React from 'react';
import theme from 'themes/theme';
import { SxProps } from '@mui/material';
import * as ALLWHERE_INFO_CONSTANTS from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/components/AllwhereInfoFilterSection/constants';
import * as ASSIGNEE_INFO_CONSTANT from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/components/AssigneeInfoFilterSection/constants';
import { AMDashDeviceInfoTestId } from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/components/DeviceInfoFilterSection/constants';
import { AutoCompleteGlobalCopySx } from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/styles';

export const AMDashClearStackSx: SxProps = {
  fontSize: '12px',
  fontWeight: 600,
  height: '34px',
  px: '16px',
  '& .clear-all-btn': {
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
};

const AVATAR_TOP = '10px';

export const getAMDashTypeAheadFilterCountSx = (
  shouldRender: boolean
): SxProps => ({
  display: shouldRender ? 'flex' : 'none',
  fontSize: '12px',
  backgroundColor: theme.palette.colors.woodSmoke,
  position: 'absolute',
  height: '20px',
  width: '20px',
  zIndex: 1550,

  [`&[data-testid="${ALLWHERE_INFO_CONSTANTS.ASSET_NUMBERS_AVATAR_TESTID}"]`]: {
    top: AVATAR_TOP,
    right: '63px',
  },

  [`&[data-testid="${ALLWHERE_INFO_CONSTANTS.ORGANIZATION_AVATAR_TESTID}"]`]: {
    top: AVATAR_TOP,
    right: '75px',
  },

  [`&[data-testid="${ASSIGNEE_INFO_CONSTANT.ASSIGNEE_EMAIL_AVATAR_TESTID}"]`]: {
    top: AVATAR_TOP,
    right: '55px',
  },

  [`&[data-testid="${ASSIGNEE_INFO_CONSTANT.ASSIGNEE_NAME_AVATAR_TESTID}"]`]: {
    top: AVATAR_TOP,
    right: '102px',
  },

  [`&[data-testid="${AMDashDeviceInfoTestId.ASSET_MAKE}"]`]: {
    top: AVATAR_TOP,
    right: '130px',
  },

  [`&[data-testid="${AMDashDeviceInfoTestId.ASSET_MODEL}"]`]: {
    top: AVATAR_TOP,
    right: '124px',
  },

  [`&[data-testid="${AMDashDeviceInfoTestId.ASSET_CONDITION}"]`]: {
    top: AVATAR_TOP,
    right: '100px',
  },

  [`&[data-testid="${AMDashDeviceInfoTestId.ASSET_KEYBOARD}"]`]: {
    top: AVATAR_TOP,
    right: '46px',
  },

  [`&[data-testid="${AMDashDeviceInfoTestId.ASSET_SERIAL_NO}"]`]: {
    top: AVATAR_TOP,
    right: '64px',
  },

  [`&[data-testid="${AMDashDeviceInfoTestId.ASSET_DEVICE_ISSUE}"]`]: {
    top: AVATAR_TOP,
    right: '76px',
  },

  [`&[data-testid="${AMDashDeviceInfoTestId.ASSET_COLOR}"]`]: {
    top: AVATAR_TOP,
    right: '130px',
  },

  [`&[data-testid="${AMDashDeviceInfoTestId.ASSET_DISPLAY_SIZE}"]`]: {
    top: AVATAR_TOP,
    right: '80px',
  },

  '& .MuiInputLabel-root': {
    top: '-6px',
    '&.MuiInputLabel-shrink': {
      top: 0,
    },
  },
});

export const AMDashClearOptionsPanelSx: SxProps = {
  background: 'red',
  display: 'flex',
  position: 'absolute',
  top: '42px',
  height: '40px',
  zIndex: 2500,
};

export const AMDashClearAllButtonSx: SxProps = {
  backgroundColor: 'transparent',
  borderRadius: '5px',
  color: theme.palette.colors.woodSmoke,
  fontSize: '12px !important',
  fontWeight: 600,
  height: '40px',
  maxHeight: '100%',
  minWidth: '80px',
  px: 0,
  '&:hover': {
    backgroundColor: theme.palette.colors.transparentGrey,
  },
};

export const AMDashOptionsCopy: SxProps = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  ...AutoCompleteGlobalCopySx,
};

// CSS Properties
export const OptionListItemCSS: React.CSSProperties = {
  fontSize: '14px',
  maxHeight: '42px',
  paddingLeft: '6px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};
