import React from 'react';
import Typography from '@mui/material/Typography';
import { TextIconButtonProps } from './types';
import { DefaultTextSx } from './styles';
import GAIconButton from '../buttons/google_analytics/GAIconButton';

const TextIconButton = (props: TextIconButtonProps) => {
  const { size, onClick, endIcon = false, ...restProps } = props;
  const iconButtonSx = {
    ...(props.buttonStyles ?? {}),
    padding: 0,
  };

  return (
    <GAIconButton
      gaContext={{
        navigates_to: 'N/A',
        textCopy: props.text,
        purpose: props.ariaLabel,
      }}
      aria-label={props.ariaLabel}
      color={props?.color ?? 'secondary'} // for now, default to secondary
      size={props.size ?? 'medium'}
      disableRipple
      disabled={!!props.disabled}
      onClick={props.onClick}
      sx={iconButtonSx}
      {...restProps}
    >
      {endIcon ? (
        <Typography variant="button" sx={props.textStyles ?? DefaultTextSx}>
          {`${props.text}\u00A0 `}
        </Typography>
      ) : (
        props.icon
      )}
      <Typography variant="button" sx={props.textStyles ?? DefaultTextSx}>
        {endIcon ? <></> : `\u00A0 ${props.text}`}
      </Typography>
      {endIcon ? props.icon : <></>}
    </GAIconButton>
  );
};

export default TextIconButton;
