import * as MaterialIcons from '@mui/icons-material';
import { BackBoxSVGIcon, SmoothRecycleSVGIcon } from 'components/Icons';
import theme from 'themes/theme';
import { OrderStatus } from 'global-constants';

const CANCELED = 'CANCELED';

const ACTIVE_ICON_BLUE = theme.palette.colors.pelorous;
const ACTIVE_ICON_GREEN = theme.palette.colors.goldFusion;
const ACTIVE_ICON_ORANGE = theme.palette.colors.jaffa;
const ACTIVE_ICON_RED = theme.palette.colors.burntSiennaR;

export const INACTIVE_ICON_GRAY = theme.palette.colors.grayBD;

// CANCELLED is a special case and needs removal
export const ORDER_TRANSITION_STATUSES = [
  CANCELED,
  ...Object.keys(OrderStatus).slice(1),
];

export const ORDER_STATUS_HISTORY_MAP = {
  [OrderStatus.COMPLETE]: {
    activeColor: ACTIVE_ICON_GREEN,
    statusIcon: MaterialIcons.CheckCircleOutlineOutlined,
  },
  [OrderStatus.SHIPPED]: {
    activeColor: ACTIVE_ICON_GREEN,
    statusIcon: MaterialIcons.LocalShippingOutlined,
  },
  [OrderStatus.EXPIRED_PENDING_RECIPIENT_INFORMATION]: {
    activeColor: ACTIVE_ICON_ORANGE,
    statusIcon: MaterialIcons.ErrorOutlineOutlined,
  },
  [OrderStatus.EXPIRED_PENDING_RETURN]: {
    activeColor: ACTIVE_ICON_ORANGE,
    statusIcon: MaterialIcons.ErrorOutlineOutlined,
  },
  [OrderStatus.CREATED]: {
    activeColor: ACTIVE_ICON_BLUE,
    statusIcon: MaterialIcons.ContentPasteOutlined,
  },
  [OrderStatus.EXCEPTION]: {
    activeColor: ACTIVE_ICON_BLUE,
    statusIcon: MaterialIcons.CallMissedOutgoingOutlined,
  },
  [OrderStatus.IN_FULFILLMENT]: {
    activeColor: ACTIVE_ICON_BLUE,
    statusIcon: MaterialIcons.AutoFixHighOutlined,
  },
  [OrderStatus.IN_RETURN]: {
    activeColor: ACTIVE_ICON_BLUE,
    statusIcon: BackBoxSVGIcon,
  },
  [OrderStatus.PENDING_APPROVAL]: {
    activeColor: ACTIVE_ICON_BLUE,
    statusIcon: MaterialIcons.AccessTimeOutlined,
  },
  [OrderStatus.PENDING_RETURN]: {
    activeColor: ACTIVE_ICON_BLUE,
    statusIcon: BackBoxSVGIcon,
  },
  [OrderStatus.PROCESSING_DEVICE]: {
    altCopy: 'Processing',
    activeColor: ACTIVE_ICON_BLUE,
    statusIcon: MaterialIcons.SettingsOutlined,
  },
  // MAY NEED TO BE REMOVED..,
  [OrderStatus.PENDING_RECIPIENT_INFORMATION]: {
    activeColor: ACTIVE_ICON_BLUE,
    statusIcon: MaterialIcons.AccessTimeOutlined,
  },
  [OrderStatus.PENDING_REPAIR]: {
    activeColor: ACTIVE_ICON_BLUE,
    statusIcon: MaterialIcons.BuildOutlined,
  },
  [OrderStatus.PENDING_RECYCLE]: {
    activeColor: ACTIVE_ICON_BLUE,
    statusIcon: SmoothRecycleSVGIcon,
  },
  [OrderStatus.PENDING_CERTIFICATE]: {
    activeColor: ACTIVE_ICON_BLUE,
    statusIcon: MaterialIcons.AccessTimeOutlined,
  },
  [OrderStatus.QUOTE_IN_PROGRESS]: {
    activeColor: ACTIVE_ICON_BLUE,
    statusIcon: MaterialIcons.ComputerOutlined,
  },
  [OrderStatus.PENDING_QUOTE_APPROVAL]: {
    activeColor: ACTIVE_ICON_BLUE,
    statusIcon: MaterialIcons.AccessTimeOutlined,
  },
  CANCELED: {
    activeColor: ACTIVE_ICON_RED,
    statusIcon: MaterialIcons.Close,
  },
};
