export enum OrderDetailTab {
  ORDER_DETAILS = 'ORDER_DETAILS',
  RECIPIENT_INFO = 'RECIPIENT_INFO',
  ORDER_STATUS_HISTORY = 'ORDER_STATUS_HISTORY',
  NOTIFICATIONS = 'NOTIFICATIONS',
  CUSTOMER_NOTES = 'CUSTOMER_NOTES',
}

export enum AddOrderItemByEnum {
  ASSET = 'ASSET',
  PRODUCT = 'PRODUCT',
}
