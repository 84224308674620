import React from 'react';
import { NavLink } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { NavLinkStyles } from 'global-styles';
import { SubNavLinkListItemSx, LinkTextSx } from '../../../../styles';
import { SubNavListProps } from './types';
import { SubNavLinkListSx } from './styles';

const SubNavList = (props: SubNavListProps) => {
  const theme = useTheme();
  return (
    <Collapse in={props.open} timeout="auto" unmountOnExit>
      <List component="div" sx={SubNavLinkListSx}>
        <Stack spacing={0.5}>
          {props.navLinkItem.subNavLinkItems.map((subNavLinkItem) => (
            <NavLink
              to={subNavLinkItem.to}
              key={subNavLinkItem.key}
              style={NavLinkStyles}
            >
              <ListItem
                key={subNavLinkItem.key}
                sx={SubNavLinkListItemSx(theme, subNavLinkItem.isActive)}
              >
                <ListItemText sx={LinkTextSx(theme, true)}>
                  {subNavLinkItem.text}
                </ListItemText>
              </ListItem>
            </NavLink>
          ))}
        </Stack>
      </List>
    </Collapse>
  );
};

export default SubNavList;
