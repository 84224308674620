import React from 'react';

export const useCursorBasedPagination = () => {
  const [cursors, setCursors] = React.useState<string[]>(['']);
  const [index, setIndex] = React.useState<number>(0);

  const addCursor = (newCursor: string) => {
    if (!cursors.includes(newCursor)) setCursors([...cursors, newCursor]);
  };

  const getCurrentCursorOffset = () => {
    if (!cursors[index]) return undefined;
    return cursors[index];
  };

  const shouldDisableBackButton = () => {
    return index === 0;
  };

  const shouldDisableNextButton = () => {
    return cursors[cursors.length - 1] === null && index === cursors.length - 2;
  };

  const incrementIndex = () => {
    setIndex(index + 1);
  };

  const decrementIndex = () => {
    setIndex(index - 1);
  };

  const resetState = () => {
    setCursors(['']);
    setIndex(0);
  };
  return {
    addCursor,
    getCurrentCursorOffset,
    shouldDisableBackButton,
    shouldDisableNextButton,
    incrementIndex,
    decrementIndex,
    resetState,
  };
};
