/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import FilterListIcon from '@mui/icons-material/FilterList';
import { assetManagementSlice } from 'store/slices';
import AMDashComplexFilterDrawer from 'pages/AssetManagement/components/AMDashComplexFilterDrawer';
import AMDashComplexFilterCount from './components/AMDashComplexFilterCount';
import { GlobalState } from 'store/types';
import * as AMDashComplexFilterStyles from './styles';
import GAButton from 'components/buttons/google_analytics/GAButton';

const AMDashComplexFilterToggle = () => {
  const styles = AMDashComplexFilterStyles;
  const dispatch = useDispatch();
  const filterDrawerOpen = useSelector<GlobalState, boolean>(
    (state) => state.assetManagement.filterOpen
  );
  const hasFilters = useSelector<GlobalState, boolean>(
    (state) => state.assetManagement.isFiltered
  );

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const handleFilterClick = () => {
    dispatch(assetManagementSlice.actions.toggleFilterDrawerReducer());
  };

  return (
    <Box sx={styles.FilterButtonBoxSx} ref={buttonRef}>
      <GAButton
        gaContext={{
          navigates_to: 'N/A',
          textCopy: 'Filters',
          purpose: 'Opens AM Dashboard Filters',
        }}
        variant="contained"
        startIcon={<FilterListIcon />}
        endIcon={<AMDashComplexFilterCount />}
        sx={styles.getFilterButtonSx(filterDrawerOpen, hasFilters)}
        onClick={handleFilterClick}
      >
        Filters
      </GAButton>
      <AMDashComplexFilterDrawer buttonRef={buttonRef} />
    </Box>
  );
};

export default AMDashComplexFilterToggle;
