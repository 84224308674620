import React from 'react';
import { SVGProps } from './types';

const RecycleDeviceSVGIcon = (props: SVGProps) => {
  const originalSize = 43;
  const colorFill = props?.fill ?? 'white';
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="custom-fill"
        d="M17.8069 17.4448C17.9313 17.5163 18.0725 17.5541 18.2161 17.5545C18.3596 17.5545 18.5006 17.5165 18.6248 17.4445C18.749 17.3724 18.8521 17.269 18.9234 17.1444L21.2912 13.0429C21.4504 12.8082 21.7154 12.6676 21.9991 12.6676C22.2827 12.6676 22.5477 12.8082 22.707 13.0428L25.2741 17.4895L23.679 18.4105L23.1096 18.7393L27.4657 19.9065L27.7289 19.9769L27.7993 19.7139L28.7963 15.9931L28.9665 15.3579L28.397 15.6868L26.689 16.673L24.1216 12.2257C23.8336 11.7265 23.3788 11.3449 22.8375 11.1477C22.2959 10.9508 21.7024 10.9508 21.1608 11.1477C20.6195 11.3449 20.1648 11.7265 19.8767 12.2257L17.5082 16.3285C17.3997 16.516 17.3702 16.7392 17.4262 16.9486C17.4822 17.158 17.6191 17.3364 17.807 17.4448L17.8069 17.4448Z"
        fill={colorFill}
      />
      <path
        className="custom-fill"
        d="M31.6718 25.3005L29.3035 21.198V21.1978C29.1951 21.0101 29.0165 20.873 28.8071 20.817C28.5977 20.7608 28.3747 20.7901 28.187 20.8987C27.9993 21.0072 27.8625 21.186 27.8065 21.3954C27.7507 21.6048 27.7804 21.8278 27.8891 22.0155L30.2572 26.1179C30.4028 26.3707 30.4024 26.6821 30.2565 26.9347C30.1106 27.1873 29.8412 27.3431 29.5493 27.3436H24.4517V24.7792L21.2629 27.968L21.0703 28.1608L24.4517 31.5421V28.9779H29.5493C30.4252 28.9776 31.2343 28.5102 31.6721 27.7518C32.1099 26.9932 32.1097 26.0588 31.6718 25.3005Z"
        fill={colorFill}
      />
      <path
        className="custom-fill"
        d="M19.2763 27.3391H14.4518C14.1599 27.3389 13.8902 27.1832 13.7441 26.9305C13.598 26.6777 13.5974 26.3664 13.743 26.1132L16.3482 21.6015L18.0558 22.5874L18.6253 22.916L18.4551 22.2809L17.4581 18.5601L17.3877 18.2969L17.1245 18.3673L12.7684 19.5345L13.3378 19.8633L14.9333 20.7845L12.3282 25.2958L12.3284 25.296C12.0402 25.7952 11.9371 26.38 12.0373 26.9476C12.1374 27.5152 12.4342 28.0294 12.8758 28.3999C13.3174 28.7704 13.8753 28.9734 14.4517 28.9734H19.2762C19.5681 28.9734 19.8378 28.8176 19.9837 28.5648C20.1299 28.312 20.1299 28.0004 19.9837 27.7476C19.8378 27.4948 19.5681 27.3391 19.2762 27.3391L19.2763 27.3391Z"
        fill={colorFill}
      />
    </svg>
  );
};

export default RecycleDeviceSVGIcon;
