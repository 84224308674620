import { gql } from '@apollo/client';

export const SEARCH_NOTIFICATION_TYPES = gql`
  query searchNotificationsTypes($limit: Int!, $recordType: String!) {
    searchNotificationsTypes(
      notificationTypeSearchInput: { limit: $limit, recordType: $recordType }
    ) {
      notificationTypes {
        id
        name
      }
    }
  }
`;
export const DEPRECATED_SEARCH_ORDER_NOTIFICATION_TYPES = gql`
  query searchOrderNotificationsTypes($limit: Int!) {
    searchOrderNotificationsTypes(
      orderNotificationTypeSearchInput: { limit: $limit }
    ) {
      orderNotificationTypes {
        id
        name
      }
    }
  }
`;
