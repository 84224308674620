import {
  RetrievalAsset,
  RetrievalOptionAnswer,
  RetrievalCollaborator,
} from 'pages/Retrieval/RetrievalService/types';
import { AssetPrice } from '../../types';
import { INSURANCE_MULTIPLIER } from 'pages/Retrieval/components/SubmitStep/constants';

export const calculateSubtotal =
  (
    retrievalOptionAnswerKey: Record<string, RetrievalOptionAnswer[]>
  ): Function =>
  (
    employees: RetrievalCollaborator[],
    assetPrices: AssetPrice[],
    retrievalRouteId: string
  ): number => {
    const assets = employees.flatMap((employee) => employee.assets);

    return assets.reduce(
      (accumulator, currentValue) =>
        accumulator +
        getAssetPrice(assetPrices, currentValue, retrievalRouteId) +
        getInsurancePrice(currentValue.id, retrievalOptionAnswerKey),
      0
    );
  };

const getAssetPrice = (
  assetPrices: AssetPrice[],
  asset: RetrievalAsset,
  retrievalRouteId: string
): number => {
  const assetPrice = assetPrices.find(
    (assetPrice) =>
      assetPrice.retrievalRouteId === retrievalRouteId &&
      assetPrice.assetTypeName.toLowerCase() === asset.type.toLowerCase()
  );
  return assetPrice?.price as number;
};

const getInsurancePrice = (
  assetId: string,
  retrievalOptionAnswerKey: Record<string, RetrievalOptionAnswer[]>
) => {
  const insuranceInfo = retrievalOptionAnswerKey[assetId].find((answer) =>
    answer.optionName?.toLowerCase().includes('insurance')
  );

  if (!insuranceInfo || insuranceInfo.optionValue === 'null') return 0;

  return Number(insuranceInfo.optionValue) * INSURANCE_MULTIPLIER;
};

export const calculateServiceFee = (subtotal: number): number => {
  const SERVICE_FEE_PERCENTAGE = 0.04;

  return subtotal * SERVICE_FEE_PERCENTAGE;
};

export const calculateShippingCost = (
  employees: RetrievalCollaborator[],
  expeditedCharge: number = 40
): number => {
  const expeditedEmployees = employees.filter((employee) => employee.isRush);

  return expeditedEmployees.reduce((acc, employee) => {
    const shippingCostByEmployee = employee.assets.length * expeditedCharge;
    return acc + shippingCostByEmployee;
  }, 0);
};

export const calculateSalesTax = (
  subtotal: number,
  serviceFee: number
): number => {
  const SALES_TAX_RATE = 0.0725;

  return (subtotal + serviceFee) * SALES_TAX_RATE;
};
