import React from 'react';
import {
  Box,
  IconButton,
  Stack,
  TableRow,
  TableCell,
  Collapse,
  Slide,
  useTheme,
  Table,
  TableBody,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  isPriceValid,
  getLineItemTotalPrice,
} from 'pages/Procurement/components/OrderSummary/utils';
import { StepInput } from 'components/inputs/StepInput';
import { RETAIL_FALLBACK_PRICE } from 'pages/Procurement/components/OrderSummary/constants';
import { ProductLineRowProps } from './types';
import { formatNumberToDecimalString } from 'services/format';
import { ADD_PROTECTION_PLAN } from 'pages/Procurement/components/ProductCatalog/components/ProductDetailsModal/components/LaptopProtectionPlan/constants';
import * as styles from './styles';

const SLIDE_TRANSITION_TIMEOUT = 300;
const APPEAR = false;

const ProductLineRow = (props: ProductLineRowProps) => {
  const [animateIn, setAnimateIn] = React.useState(true);
  const theme = useTheme();

  const onClickDelete = () => {
    setAnimateIn(false);
  };

  const getInsuranceCharge = (lineItem: Record<string, any>) =>
    lineItem?.insuranceCharge ?? 0;

  const RETAIL_UNIT_PRICE = formatNumberToDecimalString(
    parseFloat(props.lineItem.productVariantPrice) +
      getInsuranceCharge(props.lineItem)
  );

  const RETAIL_SUBTOTAL_PRICE = formatNumberToDecimalString(
    getLineItemTotalPrice(
      parseFloat(props.lineItem.productVariantPrice) +
        getInsuranceCharge(props.lineItem),
      props.lineItem.quantity *
        (props.orderTypeFlow === 'procurement'
          ? props.numberOfSelectedCollaborators
          : 1)
    )
  );

  const step =
    props.orderTypeFlow === 'procurement'
      ? props.numberOfSelectedCollaborators
      : 1;

  const showProtectionPlanLineItem =
    props.lineItem?.protectionPlan &&
    props.lineItem?.protectionPlan === ADD_PROTECTION_PLAN;

  const { lineItem, removeProductVariantFromCart } = props;

  const handleQuantityChange = (value: number) => {
    const { productVariantId } = props.lineItem;
    props.changeQuantityOfProductVariant(productVariantId, value);
  };

  React.useEffect(() => {
    if (!animateIn) {
      setTimeout(() => {
        removeProductVariantFromCart({
          productVariantId: lineItem.productVariantId,
          insuranceAmount: lineItem.insuranceAmount,
          protectionPlan: lineItem.protectionPlan,
        });
      }, SLIDE_TRANSITION_TIMEOUT);
    }
  }, [animateIn, lineItem, removeProductVariantFromCart]);

  return (
    <Collapse
      in={animateIn}
      appear={APPEAR}
      sx={{
        width: '100%',
        maxWidth: props.isProcurementToRecipient ? '100%' : '700px',
      }}
    >
      <Table>
        <TableBody
          sx={{ backgroundColor: 'colors.burntSiennaR' }}
          ref={props.containerRef}
        >
          <Slide
            appear={APPEAR}
            container={props.containerRef.current}
            direction="right"
            easing={{
              enter: theme.transitions.easing.sharp,
              exit: theme.transitions.easing.sharp,
            }}
            in={animateIn}
            timeout={SLIDE_TRANSITION_TIMEOUT}
          >
            <TableRow sx={{ backgroundColor: 'white' }}>
              <TableCell width="50%" align="left">
                <Box sx={{ fontWeight: 'light', color: 'colors.mineShaftWN' }}>
                  {props.lineItem.productName}
                </Box>
                <Stack paddingLeft="0.25rem">
                  {props.lineItem?.variantValues && (
                    <>
                      {Object.values(props.lineItem.variantValues).map(
                        (variant) => (
                          <Box
                            key={variant}
                            sx={{ fontWeight: 'light', color: '#333333' }}
                          >
                            {variant}
                          </Box>
                        )
                      )}
                    </>
                  )}
                  {showProtectionPlanLineItem ? (
                    <Box sx={{ fontWeight: 'light', color: '#333333' }}>
                      {props.lineItem?.protectionPlan}
                    </Box>
                  ) : null}
                </Stack>
              </TableCell>
              <TableCell align="center" width="12.5%">
                <StepInput
                  step={step}
                  minimum={step}
                  variant="outlined"
                  value={props.lineItem.quantity}
                  sx={styles.ProductQuantityInputSx}
                  stepInputValueChange={handleQuantityChange}
                />
              </TableCell>
              <TableCell align="right" padding="none" width="12.5%">
                {isPriceValid(props.lineItem.productVariantPrice)
                  ? `$${RETAIL_UNIT_PRICE}`
                  : RETAIL_FALLBACK_PRICE}
              </TableCell>
              <TableCell align="right" padding="none" width="12.5%">
                {isPriceValid(props.lineItem.productVariantPrice)
                  ? ` $${RETAIL_SUBTOTAL_PRICE}`
                  : RETAIL_FALLBACK_PRICE}
              </TableCell>
              <TableCell padding="none" align="center" width="12.5%">
                <IconButton size="small" disableRipple onClick={onClickDelete}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          </Slide>
        </TableBody>
      </Table>
    </Collapse>
  );
};

export default ProductLineRow;
