import { createAsyncThunk } from '@reduxjs/toolkit';
import * as restAssetsAPI from 'api/rest/assets';
import * as restUploadsAPI from 'api/rest/uploads';
import * as restCollaboratorsAPI from 'api/rest/collaborators';
import { UploadDomain } from 'api/rest/uploads/constants';
import { UploadMetadataQuery } from 'api/rest/uploads/types';

export const getAssetsCSV = createAsyncThunk(
  'assets/csv/getAssetsCSV',
  async (params: { userId: string; orgId: string }) => {
    const { userId, orgId } = params;
    return restAssetsAPI.getAssetsCSV(userId, orgId);
  }
);

export const getAssetsCSVDirect = createAsyncThunk(
  'assets/csv/getAssetsCSVDirect',
  async (params: { userId: string; orgId: string }) => {
    const { userId, orgId } = params;
    return restAssetsAPI.getAssetsCSVDirect(userId, orgId);
  }
);

export const uploadAssets = createAsyncThunk(
  'assets/csv/uploadAssets',
  async (params: {
    organizationId: string;
    collaboratorId: string;
    file: File;
  }) => {
    const { organizationId, collaboratorId, file } = params;
    const form = new FormData();
    form.append('uploadFile', file);
    form.append('organizationId', organizationId);
    form.append('collaboratorId', collaboratorId);
    form.append('domainName', UploadDomain.ASSET);
    return restUploadsAPI.uploadData(form);
  }
);

export const downloadAssetTemplate = createAsyncThunk(
  'assets/csv/downloadAssetTemplate',
  async () => {
    return restUploadsAPI.downloadTemplate(UploadDomain.ASSET);
  }
);

export const downloadAssetErrors = createAsyncThunk(
  'assets/csv/downloadAssetErrors',
  async (params: { organizationId: string; timestamp: number }) => {
    const { organizationId, timestamp } = params;
    return restUploadsAPI.downloadErrors(organizationId, timestamp);
  }
);

export const searchAssetUploads = createAsyncThunk(
  'assets/csv/searchAssetUploads',
  async (params: UploadMetadataQuery) => {
    return restUploadsAPI.searchUploads(params);
  }
);

export const getAssetUploadCollaborator = createAsyncThunk(
  'assets/csv/getAssetUploadCollaborator',
  async (params: { collaboratorId: string }) => {
    return restCollaboratorsAPI.getCollaborator(params.collaboratorId);
  }
);
