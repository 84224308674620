import { gql } from '@apollo/client';

export const CREATE_RETRIEVAL_ORDER = gql`
  mutation createRetrievalOrders(
    $purchaserId: ID!
    $routeId: ID!
    $recipients: [CreateRetrievalRecipientInput!]!
    $dropRecipient: CreateRecipientInput
    $checkoutNotes: String
  ) {
    createRetrievalOrders(
      retrievalOrdersCreateInput: {
        purchaserId: $purchaserId
        routeId: $routeId
        recipients: $recipients
        dropRecipient: $dropRecipient
        checkoutNotes: $checkoutNotes
      }
    ) {
      id
    }
  }
`;
