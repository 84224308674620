import { gql } from '@apollo/client';

export const CREATE_COLLABORATOR = gql`
  mutation CreateCollaborator(
    $firstName: String!
    $lastName: String!
    $email: String!
    $personalEmail: String
    $workEmail: String
    $organizationId: ID!
  ) {
    collaboratorCreate(
      collaboratorCreateInput: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        personalEmail: $personalEmail
        workEmail: $workEmail
        organizationId: $organizationId
      }
    ) {
      id
      firstName
      lastName
      email
      personalEmail
      workEmail
      retrievableAssets {
        id
      }
    }
  }
`;
