import React from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const DEFAULT_ASSET_MGMT_FILTER_VALUES = {
  assetUUID: null,
  thirdPartyAssetId: null,
  serialNumber: null,
  modelNumber: null,
  dataStatus: null,
  assetNumber: null,
};

const ASSET_MGMT_FILTER_KEYS = [
  ...Object.keys(DEFAULT_ASSET_MGMT_FILTER_VALUES),
] as const;

export type AssetManagementFilters = typeof ASSET_MGMT_FILTER_KEYS[number];
type AssetManagementFilterSliceState = typeof DEFAULT_ASSET_MGMT_FILTER_VALUES;

const AssetManagementFilterSlice = createSlice({
  name: 'AssetManagementFilterSlice',
  initialState: DEFAULT_ASSET_MGMT_FILTER_VALUES,
  reducers: {
    setFieldValues: (
      state,
      action: PayloadAction<Partial<AssetManagementFilterSliceState>>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetField: (state, action: PayloadAction<AssetManagementFilters>) => {
      return {
        ...state,
        [action.payload]: null,
      };
    },
    resetFields: (state, action: PayloadAction<AssetManagementFilters[]>) => {
      const updatedState = action.payload.reduce(
        (acc: Record<AssetManagementFilters, any>, filterKey) => {
          acc[filterKey] = null;
          return acc;
        },
        {}
      );
      return {
        ...state,
        ...updatedState,
      };
    },
    reset: () => DEFAULT_ASSET_MGMT_FILTER_VALUES,
  },
});

const useAssetManagementFilters = () => {
  const [assetManagementFilterFields, dispatch] = React.useReducer(
    AssetManagementFilterSlice.reducer,
    AssetManagementFilterSlice.getInitialState()
  );

  const reducedFilterFields = Object.keys(assetManagementFilterFields).reduce(
    (acc: Partial<AssetManagementFilterSliceState>, fieldKey: string) => {
      const tempFieldValue =
        assetManagementFilterFields[
          fieldKey as keyof AssetManagementFilterSliceState
        ];
      if (tempFieldValue) {
        // eslint-disable-next-line security/detect-object-injection
        acc[fieldKey as keyof typeof acc] = tempFieldValue;
      }
      return acc;
    },
    {}
  );
  return {
    reducedFilterFields,
    setFilterField: (fieldValue: Record<AssetManagementFilters, any>) =>
      dispatch(AssetManagementFilterSlice.actions.setFieldValues(fieldValue)),
    resetAllFilterFields: () =>
      dispatch(AssetManagementFilterSlice.actions.reset()),
    resetFilterField: (targetField: AssetManagementFilters) =>
      dispatch(AssetManagementFilterSlice.actions.resetField(targetField)),
    resetFilterFields: (targetFields: AssetManagementFilters[]) =>
      dispatch(AssetManagementFilterSlice.actions.resetFields(targetFields)),
    resetAllFields: () => dispatch(AssetManagementFilterSlice.actions.reset()),
  };
};

export default useAssetManagementFilters;
