import { SxProps } from '@mui/material';

export const ReadOnlyStackSx: SxProps = {
  rowGap: '4px',
};

export const ReadOnlyTextLabel: SxProps = {
  color: 'colors.gray60',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '12px',
  letterSpacing: '0.15px',
};

export const ReadOnlyTextValue: SxProps = {
  color: 'colors.black',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
};

export const TextFieldSx: SxProps = {
  backgroundColor: 'transparent',
  p: 0,
  '&: .MuiFormHelperText-root': {
    color: 'transparent',
    position: 'absolute',
    bottom: '-20px',
  },
  width: '75%',
};
