export const UPLOAD_HISTORY_HEADER_COPY = 'Upload history';

export const LOOK_BACK_OPTIONS = [
  {
    label: 'Last 2 weeks',
    value: 14,
  },
  {
    label: 'Last month',
    value: 30,
  },
  {
    label: 'Last 3 months',
    value: 90,
  },
  {
    label: 'Last 6 months',
    value: 180,
  },
  {
    label: 'Last year',
    value: 365,
  },
  {
    label: 'All time',
    value: 0,
  },
];
