import { FileUploadService } from 'api/rest/config';
import { AxiosResponse } from 'axios';
import { UploadMetadata, UploadMetadataQuery } from './types';
import { UploadDomain } from './constants';

export const uploadData = async (data: FormData) => {
  return FileUploadService.post<FormData, Promise<AxiosResponse>>('', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const searchUploads = async (query: UploadMetadataQuery) => {
  const returnTotalCount = !!query.limit && !Number.isNaN(query.offset);
  return FileUploadService.get<
    { items: UploadMetadata[]; count: number },
    AxiosResponse
  >('', {
    params: query,
  }).then((response) => ({
    items: response.data.items,
    ...(returnTotalCount && { count: response.data.count }),
  }));
};

export const downloadTemplate = async (domainName: UploadDomain) => {
  return FileUploadService.get<
    { domainName: UploadDomain },
    Promise<AxiosResponse>
  >(`/domain/${domainName}/template`);
};

export const downloadErrors = async (
  organizationId: string,
  timestamp: number
) => {
  return FileUploadService.get<
    { organizationId: string; timestamp: number },
    Promise<AxiosResponse>
  >(`/organization/${organizationId}/timestamp/${timestamp}/errors`);
};
