/* eslint-disable security/detect-object-injection */
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { type BillingInfo } from './types';
import { DEFAULT_EMPTY_BILLING_INFO_VALUES } from './constants';

export const billingInfoSlice = createSlice({
  name: 'billingInfoService',
  initialState: () => DEFAULT_EMPTY_BILLING_INFO_VALUES as any,
  reducers: {
    setBillingInfo: (state, action: PayloadAction<BillingInfo>) => {
      Object.entries(action.payload).forEach(([field, value]) => {
        state[field] = value;
      });
    },
    reset: () => DEFAULT_EMPTY_BILLING_INFO_VALUES,
  },
});
