import { SxProps } from '@mui/material';
import theme from 'themes/theme';
import { MouseCursorStyle } from 'global-constants';
import { DetailViewContentContainerSx } from 'pages/OrderManagement/components/DetailedOrderView/styles';

export const CustomerNotesContainerSx: SxProps = {
  cursor: MouseCursorStyle.NOT_ALLOWED,
  outlineColor: 'transparent',
  ...DetailViewContentContainerSx,
};

export const CustomerNotesTypographySx: SxProps = {
  borderBottom: `1px solid ${theme.palette.colors.gray80}`,
  backgroundColor: theme.palette.colors.alabasterN,
  boxSizing: 'border-box',
  borderRadius: 0,
  height: '100%',
  width: '100%',
  opacity: 0.8,
  p: 2,
  outlineColor: 'transparent',

  cursor: MouseCursorStyle.NOT_ALLOWED,
  color: theme.palette.colors.gray60,
};
