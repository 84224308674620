import { OrderStatus, InternalOrderType } from 'global-constants';
import { formatDate, getTodaysDate, downloadDataToCSV } from 'services';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import { OrderStatusMapping, InternalOrderTypeMapping } from '../constants';
import { GET_ALL_ORDERS_BY_ORG, GET_ALL_RECIPIENT_ORDERS } from './queries';
import { Order } from 'types';
import { constructRecipientName } from './utils';
import { getItemProductTitle } from '../components/OrdersDatagrid/utils';

export const useDownloadAllOrders = () => {
  const userOrganization = useGetUserOrganization();

  const [
    fetchAllRecipientOrders,
    { loading: isFetchingAllRecipientOrdersLoading },
  ] = useSafeLazyQuery(GET_ALL_RECIPIENT_ORDERS, {
    onCompleted: (data) => {
      const orderData = data.getAllRecipientOrders;
      const transformedOrderData = orderData.map((order: any) => {
        return {
          ...order,
          status: OrderStatusMapping[order.status as OrderStatus],
          internalOrderType:
            InternalOrderTypeMapping[
              order.internalOrderType as InternalOrderType
            ],
          orderDate: formatDate(order.orderDate),
        };
      });
      const todaysDate = getTodaysDate('MMM_DD_yyyy');
      const orderDataCSVFilename = `${userOrganization?.name}_Orders_${todaysDate}`;
      const orderDataCSVHeaders = [
        'Recipient',
        'Order #',
        'Type',
        'No. Order Items',
        'Products',
        'Status',
        'Order Date',
        'Purchaser',
        'Checkout Notes',
      ];
      downloadDataToCSV(
        transformedOrderData,
        orderDataCSVHeaders,
        orderDataCSVFilename
      );
    },
    fetchPolicy: 'network-only',
  });

  const [fetchAllOrders, { loading: isFetchingAllOrdersLoading }] =
    useSafeLazyQuery(GET_ALL_ORDERS_BY_ORG, {
      onCompleted: (data) => {
        const orderData = data.getAllOrders as Order[];
        const transformedOrderData = orderData.map((order: Order) => {
          const {
            orderType: { name: orderTypeName = '' },
          } = order;
          return {
            recipient: constructRecipientName(order),
            orderNumber: order.orderNumber,
            type: orderTypeName,
            numberOfOrderItems: order.orderItems?.length || 0,
            products: order.orderItems
              ?.map(
                (orderItem) =>
                  `${getItemProductTitle(orderItem, orderTypeName)}`
              )
              .toString()
              .replace(/,/g, '\n'),
            status: OrderStatusMapping[order.status as OrderStatus],
            orderDate: formatDate(order.createdAt),
            purchaser: order?.purchaser
              ? `${order.purchaser?.firstName} ${order.purchaser?.lastName}`
              : '',
            checkoutNotes: order.checkoutNotes || '',
          };
        });
        const todaysDate = getTodaysDate('MMM_DD_yyyy');
        const orderDataCSVFilename = `${userOrganization?.name}_Orders_${todaysDate}`;
        const orderDataCSVHeaders = [
          'Recipient',
          'Order #',
          'Type',
          'No. Order Items',
          'Products',
          'Status',
          'Order Date',
          'Purchaser',
          'Checkout Notes',
        ];
        downloadDataToCSV(
          transformedOrderData,
          orderDataCSVHeaders,
          orderDataCSVFilename
        );
      },
      fetchPolicy: 'network-only',
    });

  const returnDeprecatedQuery =
    process.env.REACT_APP_OPERATIONS_BACKEND !== 'acumatica';

  return {
    fetchAllOrders: returnDeprecatedQuery
      ? fetchAllRecipientOrders
      : fetchAllOrders,
    isFetchingAllOrdersLoading: returnDeprecatedQuery
      ? isFetchingAllRecipientOrdersLoading
      : isFetchingAllOrdersLoading,
  };
};
