import { SxProps } from '@mui/material';
import theme from 'themes/theme';

const FilterBankChipSx: SxProps = {
  backgroundColor: theme.palette.colors.grayE0,
  height: '24px',
  mb: '8px !important',

  '&.MuiButtonBase-root.MuiChip-root': {
    mb: '8px !important',
    mr: '8px',
  },

  '& .MuiChip-label': {
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '18px',
  },

  '& .MuiChip-deleteIcon': {
    color: theme.palette.colors.woodSmoke,
    opacity: 0.3,
  },
};

export default FilterBankChipSx;
