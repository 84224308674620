import { gql } from '@apollo/client';

export const SEARCH_DEPOT_SERVICES = gql`
  query depotServices(
    $depotId: ID
    $orderTypeId: ID
    $orderTypeName: String
    $assetStatus: AssetStatusEnum
    $assetTypeId: ID
    $limit: Int
  ) {
    depotServices(
      depotServicesSearchInput: {
        depotId: $depotId
        orderTypeId: $orderTypeId
        orderTypeName: $orderTypeName
        assetStatus: $assetStatus
        assetTypeId: $assetTypeId
        limit: $limit
      }
    ) {
      depotServices {
        id
        depot {
          id
          name
          displayName
          isActive
          address {
            id
            country
          }
        }
        assetService {
          id
          orderTypeId
          assetStatus
          assetTypeId
        }
      }
      count
    }
  }
`;
