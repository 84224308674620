import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Container from '@mui/material/Container';
import { NewModal } from 'components';
import { useSafeMutation } from 'hooks/useSafeMutation';
import { notificationSlice } from 'store/slices';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';
import { AddDeviceModalProps } from './types';
import { ModalPaperSx } from './styles';
import {
  ADD_DEVICE_MODAL_TITLE,
  CANCEL_BUTTON_LABEL,
  SAVE_BUTTON_LABEL,
} from './constants';
import DeviceForm from '../DeviceForm';
import { useDeviceModal } from '../EditDeviceModal/hooks/useDeviceModal';
import { CREATE_ASSET } from './mutations';

const AddDeviceModal = (props: AddDeviceModalProps) => {
  const { handleSubmit } = useForm();

  const dispatch = useDispatch();

  const deviceModalService = useDeviceModal();

  const [mutate, { loading }] = useSafeMutation(CREATE_ASSET);

  const handleSubmitOnClick = () => {
    const payload = deviceModalService.constructCreateAssetPayload(
      props.organizationId
    );
    mutate({
      variables: payload,
      update: (cache) => {
        cache.modify({
          optimistic: true,
          fields: {
            searchAssets(data) {},
          },
        });
      },
      onCompleted: (data) => {
        const newAssetNumber = data?.createAsset?.assetNumber;
        dispatch(
          notificationSlice.actions.setNotice({
            showNotice: true,
            noticeContent: `New device created with asset number: ${newAssetNumber}`,
          })
        );
        deviceModalService.clearForm();
        if (props.refetchSearchAssets) props.refetchSearchAssets();
        props.handleClose();
      },
    });
  };

  const handleCancel = () => {
    props.handleClose();
  };

  const disableSaveButton =
    !deviceModalService.assetType || !deviceModalService.assetStatus;

  return (
    <NewModal
      form
      open={props.open}
      title={ADD_DEVICE_MODAL_TITLE}
      onSubmit={handleSubmit(handleSubmitOnClick)}
      submitButtonCopy={SAVE_BUTTON_LABEL}
      cancelButtonCopy={CANCEL_BUTTON_LABEL}
      disableSaveButton={disableSaveButton}
      loading={loading}
      handleClose={handleCancel}
      paperPropsSx={ModalPaperSx}
    >
      <Container sx={{ margin: '1rem 0 2rem 0' }}>
        <DeviceForm deviceModalService={deviceModalService} context="add" />
      </Container>
    </NewModal>
  );
};

export default AddDeviceModal;
