import { SxProps, Theme } from '@mui/material';

export const RecipientCardSx = (theme: Theme): SxProps => ({
  border: `1px solid ${theme.palette.colors.grayD5}`,
  pb: '8px',
  position: 'relative',
  pt: '16px',
  px: '16px',
  width: '100%',
});

export const RecipientCardHeaderSx: SxProps = {
  p: 0,
  '& .MuiTypography-root': {
    fontSize: '16px',
    fontWeight: 700,
    px: 0,
    py: 0,
  },
};

export const RecipientCardHeaderButtonSx = (theme: Theme): SxProps => ({
  borderRadius: 0,
  display: 'inline-flex',
  justifyContent: 'flex-start',
  px: 0,
  py: 0,
  width: '100%',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  '& > .MuiTypography-root': {
    color: theme.palette.colors.black,
  },
});

export const RecipientCardContentSx: SxProps = {
  m: 0,
  px: 0,
  top: '10px',

  '& .retrieval-recipient-card-expedited-stack': {
    '& .MuiFormLabel-root': {
      fontSize: '.75rem',
      textTransform: 'uppercase',
    },
  },

  '& .retrieval-recipient-card-expedited-button-group': {
    pt: '5px',
    '& .MuiButtonBase-root.Mui-selected': {
      fontWeight: 600,
    },
    '& .MuiButtonBase-root': {
      py: 0,
    },
  },

  '& .retrieval-recipient-card-expedited-tooltip > .MuiSvgIcon-root': {
    pl: '2px',
    position: 'relative',
    top: '3px',
  },
};

export const RecipientCardActionSx: SxProps = {
  position: 'absolute',
  right: 0,
  top: 0,
};

export const RecipientCardButtonGroupSx = (theme: Theme): SxProps => ({
  borderRadius: '0 !important',
  pt: '20px',

  '& > .MuiButtonBase-root.MuiButton-root': {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    color: theme.palette.colors.strongBlue,
    p: '2px 4px',
    pointerEvents: 'auto',

    '&:hover': {
      backgroundColor: `${theme.palette.colors.strongBlue}18`,
    },

    '&:last-child': {
      ml: '20px',
    },

    '& > .MuiTypography-root': {
      fontSize: '13px',
      fontWeight: 600,
    },
  },
});

export const EmployeeAddedTextSx: SxProps = {
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  ml: '20px',
};

export const RecipientCardSectionCopySx: SxProps = {
  fontSize: '14px',
  lineHeight: '20.02px',
  color: 'colors.gray60',

  '& > .MuiLink-root': {
    fontWeight: 600,
    color: 'colors.woodSmoke',
  },
};

export const RecipientCardSelectItemsSx: SxProps = {
  pt: '10px',
  pb: '15px',
  height: '90px',
  justifyContent: 'flex-start',
};

export const RecipientCardEmailSx: SxProps = {
  mr: '20px',
  height: '95%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

export const RecipientCardShippingSx: SxProps = {
  height: '60%',
  display: 'flex',
  flexDirection: 'column',
  pt: '10px',
  justifyContent: 'space-between',
};

export const RecipientCardEmailDropdownSx: SxProps = {
  width: '320px',
  height: '40px',
};
