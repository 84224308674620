import theme from 'themes/theme';
import { SxProps } from '@mui/material';

export const FilterBtnCountIconSx: SxProps = {
  backgroundColor: theme.palette.colors.woodSmoke,
  height: '20px',
  position: 'relative',
  top: '1px',
  width: '20px',

  '& .MuiTypography-root': {
    fontSize: '12px',
  },
};
