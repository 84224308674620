import _ from 'lodash';
import {
  CartItem,
  RemoveProductVariantPayload,
  SelectedCollaborator,
} from './types';
import { RecipientAddress } from 'types';

export const changeQuantityOfProductVariantIdAndReturnCart = (
  cart: CartItem[],
  productVariantId: string,
  operand: number,
  operation: 'add' | 'subtract' | 'replace'
): CartItem[] => {
  const cartCopy = _.cloneDeep(cart);
  const productVariantIndex = cart.findIndex(
    (cartItem) => cartItem.productVariantId === productVariantId
  );

  if (operation === 'replace') {
    cartCopy[productVariantIndex].quantity = operand;
    return cartCopy;
  }

  operation === 'add'
    ? (cartCopy[productVariantIndex].quantity += operand)
    : (cartCopy[productVariantIndex].quantity -= operand);

  return cartCopy;
};

export const isProductVariantInCart = (
  productVariantId: string,
  cart: CartItem[],
  specialValues: {
    insuranceAmount: string | undefined;
    protectionPlan: string | undefined;
  }
) => {
  const item = cart.find((item) => item.productVariantId === productVariantId);

  const specialValueToCheck = specialValues.insuranceAmount
    ? 'insuranceAmount'
    : 'protectionPlan';

  if (
    item &&
    item[specialValueToCheck] &&
    item[specialValueToCheck] !== specialValues[specialValueToCheck]
  ) {
    return false;
  }

  return !!item;
};

export const removeProductVariantFromCart = (
  cart: CartItem[],
  payload: RemoveProductVariantPayload
) => {
  const newCart: CartItem[] = [];

  const cartCopy = _.cloneDeep(cart);

  const specialValueToCheck = payload.insuranceAmount
    ? 'insuranceAmount'
    : 'protectionPlan';

  const indexOfObjectToRemove = cartCopy
    .map((cartItem) => cartItem[specialValueToCheck])
    .indexOf(payload[specialValueToCheck]);

  cartCopy.forEach((productVariantIdObj, index) => {
    if (index !== indexOfObjectToRemove) {
      newCart.push(productVariantIdObj);
    }
  });

  return newCart;
};

export const findCollaboratorToUpdate = (
  selectedCollaborators: SelectedCollaborator[],
  collaboratorId: string
) => {
  return selectedCollaborators
    .map((collaborator, index) => ({
      collaborator,
      index,
    }))
    .find(({ collaborator, ..._ }) => collaborator.id === collaboratorId);
};
