import React from 'react';
import { styled } from '@mui/material';
import { FooterProps } from './types';
import styles from './Footer.module.css';

// One of MUI's solution to add margin to a fixed components
const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

const Footer = (props: FooterProps) => {
  return (
    <>
      <Offset />
      <div className={styles.container}>
        <div className={styles['btn-container']}>{props.children}</div>
      </div>
    </>
  );
};

export default Footer;
