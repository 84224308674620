import React from 'react';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Button, NewModal } from 'components';
import GALoadingButton from 'components/buttons/google_analytics/GALoadingButton';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';
import { useSafeMutation } from 'hooks/useSafeMutation';
import { notificationSlice } from 'store/slices';
import { ACTION_IS_IRREVERSIBLE_DELETED, ARE_YOU_SURE } from './constants';
import { DELETE_INVENTORY_RECEIPT } from './mutations';
import {
  CancelTextSx,
  DeleteTextSx,
  ModalPaperPropsSx,
  ModalTitleSx,
} from './styles';
import { DeleteInventoryReceiptModalProps } from './types';
import { InventoryReceipt } from 'types';

const DeleteInventoryReceiptModal = (
  props: DeleteInventoryReceiptModalProps
) => {
  const [mutate, { loading }] = useSafeMutation(DELETE_INVENTORY_RECEIPT);

  const dispatch = useDispatch();

  const handleOnDeleteClick = () => {
    mutate({
      variables: {
        id: props.inventoryReceiptId,
      },
      onCompleted() {
        dispatch(
          notificationSlice.actions.setNotice({
            showNotice: true,
            noticeContent: NotificationMessages.CHANGES_SAVED_SUCCESS,
          })
        );
        props.handleClose();
      },
      update(cache, { data: { deleteInventoryReceipt } }) {
        cache.modify({
          optimistic: true,
          fields: {
            searchInventoryReceipts(data) {
              const inventoryReceipts = data.inventoryReceipts || [];
              return inventoryReceipts.filter(
                (inventoryReceipt: InventoryReceipt) =>
                  inventoryReceipt.id !== deleteInventoryReceipt.id
              );
            },
          },
        });
      },
    });
  };

  return (
    <NewModal
      open={props.open}
      handleClose={props.handleClose}
      paperPropsSx={ModalPaperPropsSx}
    >
      <Stack justifyContent="space-between" height="100%">
        <Stack spacing={1}>
          <Typography sx={ModalTitleSx}>{ARE_YOU_SURE}</Typography>
          <Typography variant="body1">
            {ACTION_IS_IRREVERSIBLE_DELETED}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={1}
          width="100%"
          sx={{
            '& > button': {
              p: '8px 12px',
            },
          }}
        >
          <Button
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'No, Cancel',
              purpose: 'Cancels Inventory Receipt Deletion',
            }}
            variant="text"
            onClick={props.handleClose}
            sx={{ justifyContent: 'flex-end' }}
          >
            <Typography variant="button" sx={CancelTextSx}>
              No, cancel
            </Typography>
          </Button>
          <GALoadingButton
            gaContext={{
              navigates_to: '',
              textCopy: 'Yes, delete',
              purpose: 'Deletes Inventory Receipt',
            }}
            onClick={handleOnDeleteClick}
            variant="contained"
            color="warning"
            loading={loading}
          >
            <Typography variant="button" sx={DeleteTextSx}>
              Yes, delete
            </Typography>
          </GALoadingButton>
        </Stack>
      </Stack>
    </NewModal>
  );
};

export default DeleteInventoryReceiptModal;
