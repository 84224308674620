import Stack from '@mui/material/Stack';
import { Divider, Typography } from '@mui/material';

import React from 'react';
import {
  SUBTOTAL_LABEL,
  SUBTOTAL_AMOUNT,
  SERVICE_FEE_LABEL,
  SERVICE_FEE_AMOUNT,
  TAX_LABEL,
  TAX_AMOUNT,
  TOTAL_LABEL,
  TOTAL_AMOUNT,
  SUBMIT_BUTTON_LABEL,
} from './constants';
import {
  TotalSx,
  TotalSummaryContainerSx,
  TotalSummaryTitleSx,
  TotalSummaryContentSx,
  SubmitButtonContainerSx,
  SubmitButtonSx,
  SubmitLabelSx,
} from './styles';

import { TotalSummaryProps } from './types';
import GALoadingButton from '../../buttons/google_analytics/GALoadingButton';

const TotalSummary = (props: TotalSummaryProps) => {
  const { onSubmit, form, loading } = props;

  const isButtonDisabled = !form.watch('code');

  return (
    <Stack direction="column" sx={TotalSummaryContainerSx}>
      <Stack sx={TotalSummaryTitleSx}>Summary</Stack>
      <Stack sx={TotalSummaryContentSx} spacing={1}>
        <Stack direction="row" justifyContent="space-between">
          <Stack>{SUBTOTAL_LABEL}</Stack>
          <Stack>{SUBTOTAL_AMOUNT}</Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Stack>{SERVICE_FEE_LABEL}</Stack>
          <Stack>{SERVICE_FEE_AMOUNT}</Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Stack>{TAX_LABEL}</Stack>
          <Stack>{TAX_AMOUNT}</Stack>
        </Stack>
        <Divider />
        <Stack direction="row" justifyContent="space-between" sx={TotalSx}>
          <Stack>{TOTAL_LABEL}</Stack>
          <Stack>{TOTAL_AMOUNT}</Stack>
        </Stack>
      </Stack>
      <Stack sx={SubmitButtonContainerSx}>
        <GALoadingButton
          gaContext={{
            navigates_to: 'N/A',
            purpose: 'Submits an order',
            textCopy: SUBMIT_BUTTON_LABEL,
          }}
          variant="contained"
          sx={SubmitButtonSx}
          onClick={onSubmit}
          disabled={isButtonDisabled || loading}
          loading={loading}
        >
          <Typography sx={SubmitLabelSx}>{SUBMIT_BUTTON_LABEL}</Typography>
        </GALoadingButton>
      </Stack>
    </Stack>
  );
};

export default TotalSummary;
