import { gql } from '@apollo/client';

export const CREATE_RECYCLE_DEVICE_ORDER = gql`
  mutation createRecycleOrder($purchaserId: ID!, $assetId: ID!) {
    createRecycleOrder(
      recycleOrderCreateInput: { purchaserId: $purchaserId, assetId: $assetId }
    ) {
      id
    }
  }
`;
