import React, { useCallback } from 'react';
import { sendButtonClickEvent } from './utils';
import { GAButtonClickHandlerProps } from './types';

export const useGAButtonClickHandler = (props: GAButtonClickHandlerProps) => {
  return useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (props.onClick) props.onClick(event);
      sendButtonClickEvent(props.gaContext);
    },
    [props]
  );
};
