import * as Sentry from '@sentry/react';
import { ApolloError } from '@apollo/client';

const { REACT_APP_SENTRY_DSN, REACT_APP_SENTRY_ENV, REACT_APP_SENTRY_RELEASE } =
  process.env;

const onUnhandledError = (event: ErrorEvent) => {
  try {
    Sentry.captureException(event, (scope) => {
      scope.setContext('context', { event: JSON.stringify(event) });
      return scope;
    });
  } catch (e) {
    console.error('Could not send unhandled error to sentry');
  }
};

const onUnhandledException = (event: PromiseRejectionEvent) => {
  try {
    Sentry.captureException(event, (scope) => {
      scope.setContext('context', {
        event: JSON.stringify(event),
        reason: event.reason,
      });
      return scope;
    });
  } catch (e) {
    console.error('Could not send unhandled exception to sentry');
  }
};

export const init = () => {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN || '',
    environment: REACT_APP_SENTRY_ENV || '',
    release: REACT_APP_SENTRY_RELEASE || '',
    integrations: [
      Sentry.browserProfilingIntegration(),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.extraErrorDataIntegration({ depth: 20 }),
    ],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/(app.|store.staging.|store.development.)?allwhere\.co/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    profilesSampleRate: 1.0,
  });
  // window.removeEventListener('error', onUnhandledError);
  // window.removeEventListener('unhandledrejection', onUnhandledException);
  window.onerror = (event, source, lineno, colno, error) => {
    if (error instanceof ApolloError) {
      for (const err of error.graphQLErrors) {
        if (err.extensions?.code === 'UNAUTHENTICATED') {
          return;
        }
      }
    }
    try {
      Sentry.captureException(error, (scope) => {
        scope.setContext('context', {
          event: JSON.stringify(event),
          source,
          lineno,
          colno,
        });
        return scope;
      });
    } catch (e) {
      console.error('Could not send unhandled error to sentry');
    }
  };
  // window.addEventListener('error', onUnhandledError);
  // window.addEventListener('unhandledrejection', onUnhandledException);
};
