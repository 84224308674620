// eslint-disable-next-line import/no-cycle
import { AssetManagementFilterEnumKeys } from './types';

const ASSET_NO = 'ASSET_NO';
const DEPOTS = 'DEPOTS';
const STATUS = 'STATUS';
const ORGS = 'ORGS';
const ASSIGNEES = 'ASSIGNEES';
const ASSIGNEE_EMAILS = 'ASSIGNEE_EMAILS';
const ASSET_TYPES = 'ASSET_TYPES';
const ASSET_MAKES = 'ASSET_MAKES';
const ASSET_MODELS = 'ASSET_MODELS';
const CONDITIONS = 'CONDITIONS';
const KEY_CONFIGS = 'KEY_CONFIGS';
const SERIAL_NO = 'SERIAL_NO';
const DEVICE_ISSUES = 'DEVICE_ISSUES';
const COLORS = 'COLORS';
const DISPLAY_SIZES = 'DISPLAY_SIZES';
const DATA_STATUS = 'DATA_STATUS';

export const ASSET_MANAGEMENT_FILTER_KEYS: Record<
  AssetManagementFilterEnumKeys,
  AssetManagementFilterEnumKeys
> = {
  ASSET_NO,
  DEPOTS,
  STATUS,
  ORGS,
  ASSIGNEES,
  ASSIGNEE_EMAILS,
  ASSET_TYPES,
  ASSET_MAKES,
  ASSET_MODELS,
  CONDITIONS,
  KEY_CONFIGS,
  SERIAL_NO,
  DEVICE_ISSUES,
  COLORS,
  DISPLAY_SIZES,
  DATA_STATUS,
};

export const AM_DASH_FILTER_KEY_QUERY_FIELD_INDEX: Record<
  AssetManagementFilterEnumKeys,
  [queryField: string, queryFieldIndex: number]
> = {
  ASSET_NO: ['assetNumber', 0],
  DEPOTS: ['depotId', 1],
  STATUS: ['status', 0],
  ORGS: ['organizationId', 1],
  ASSIGNEES: ['assigneeId', 1],
  ASSIGNEE_EMAILS: ['assigneeId', 1],
  ASSET_TYPES: ['assetTypeId', 1],
  ASSET_MAKES: ['make', 0],
  ASSET_MODELS: ['model', 0],
  CONDITIONS: ['cosmeticCondition', 0],
  KEY_CONFIGS: ['keyboard', 0],
  SERIAL_NO: ['serialNumber', 0],
  DEVICE_ISSUES: ['deviceAssessment', 0],
  COLORS: ['color', 0],
  DISPLAY_SIZES: ['displaySize', 0],
  DATA_STATUS: ['dataStatus', 0],
};

export enum GlobalModals {
  NONE = 'NONE',
  RETRIEVAL_CONFIRMATION = 'RETRIEVAL_CONFIRMATION',
  EQUIPMENT_SENT_CONFIRMATION = 'EQUIPMENT_SENT_CONFIRMATION',
  ADD_DEVICE = 'ADD_DEVICE',
  EDIT_DEVICE = 'EDIT_DEVICE',
  ADD_EMPLOYEE_INFORMATION = 'ADD_EMPLOYEE_INFORMATION',
  EDIT_EMPLOYEE_INFORMATION = 'EDIT_EMPLOYEE_INFORMATION',
  PROCESS_DEVICE = 'PROCESS_DEVICE',
  RECYCLE_DEVICE = 'RECYCLE_DEVICE',
  REPAIR_DEVICE = 'REPAIR_DEVICE',
  RESELL_DEVICE = 'RESELL_DEVICE',
  DELETE_DEVICE = 'DELETE_DEVICE',
  DEVICE_ASSIGNED_CONFIRMATION = 'DEVICE_ASSIGNED_CONFIRMATION',
  ASSET_IMAGES = 'ASSET_IMAGES',
  UNSAVED_CHANGES = 'UNSAVED_CHANGES',
  UNLOCK_DEVICE = 'UNLOCK_DEVICE',
  UPLOAD_CONFIRMATION = 'UPLOAD_CONFIRMATION',
}

export enum AssetManagementAvailableOptions {
  ASSET_NO = 'availableAssetNumbers',
  DEPOTS = 'availableDepots',
  STATUS = 'availableStatuses',
  ORGS = 'availableOrgs',
  ASSIGNEES = 'availableAssignees',
  ASSIGNEE_EMAILS = 'availableAssigneeEmails',
  ASSET_TYPES = 'availableTypes',
  ASSET_MAKES = 'availableMakes',
  ASSET_MODELS = 'availableModels',
  CONDITIONS = 'availableConditions',
  KEY_CONFIGS = 'availableKeyConfigs',
  SERIAL_NO = 'availableSerialNumbers',
  DEVICE_ISSUES = 'availableDeviceIssues',
  COLORS = 'availableColors',
  DISPLAY_SIZES = 'availableDisplaySizes',
  DATA_STATUS = 'availableDataStatuses',
}

export enum AssetManagementSelectedOptions {
  ASSET_NO = 'selectedAssetNumbers',
  DEPOTS = 'selectedDepots',
  STATUS = 'selectedStatuses',
  ORGS = 'selectedOrgs',
  ASSIGNEES = 'selectedAssignees',
  ASSIGNEE_EMAILS = 'selectedAssigneeEmails',
  ASSET_TYPES = 'selectedTypes',
  ASSET_MAKES = 'selectedMakes',
  ASSET_MODELS = 'selectedModels',
  CONDITIONS = 'selectedConditions',
  KEY_CONFIGS = 'selectedKeyConfigs',
  SERIAL_NO = 'selectedSerialNumbers',
  DEVICE_ISSUES = 'selectedDeviceIssues',
  COLORS = 'selectedColors',
  DISPLAY_SIZES = 'selectedDisplaySizes',
  DATA_STATUS = 'selectedDataStatuses',
}
