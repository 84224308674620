import { createSlice } from '@reduxjs/toolkit';

import {
  setColumnsOpenReducer,
  setColumnsChangedReducer,
  setColumnsReducer,
  setAllColumnsReducer,
  swapColumnsReducer,
  setAllColumnsActiveReducer,
  setSearchInputReducer,
  setInitializedReducer,
  resetStateReducer,
} from './reducers';

import { INITIAL_STATE } from './constants';

export const columnsSlice = createSlice({
  name: 'columnsSlice',
  initialState: INITIAL_STATE(),
  reducers: {
    setColumnsOpenReducer,
    setColumnsChangedReducer,
    setColumnsReducer,
    setAllColumnsReducer,
    swapColumnsReducer,
    setAllColumnsActiveReducer,
    setSearchInputReducer,
    setInitializedReducer,
    resetStateReducer,
  },
});

const {
  setColumnsOpenReducer: setColumnsOpen,
  setColumnsChangedReducer: setColumnsChanged,
  setColumnsReducer: setColumns,
  swapColumnsReducer: swapColumns,
  setAllColumnsReducer: setAllColumns,
  resetStateReducer: resetState,
  setSearchInputReducer: setSearchInput,
  setAllColumnsActiveReducer: setAllColumnsActive,
  setInitializedReducer: setInitialized,
} = columnsSlice.actions;

export {
  setColumnsOpen,
  setColumnsChanged,
  setColumns,
  setAllColumns,
  swapColumns,
  resetState,
  setSearchInput,
  setAllColumnsActive,
  setInitialized,
};
