import React from 'react';
import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const HomeTimeSavingsBoxSx: SxProps = {
  backgroundColor: theme.palette.colors.merino,
  borderRadius: '10px',
  boxSizing: 'border-box',
  p: '16px 24px',
  width: '100%',
};

export const HomeTimeSavingsInfoGraphicSx: SxProps = {
  '& .time-savings-title': {
    color: theme.palette.colors.woodSmoke,
    fontFamily: 'Inter',
    fontSize: '18px',
    lineHeight: '150%',
  },
  '& .time-savings-amount': {
    color: theme.palette.colors.woodSmoke,
    fontSize: '44px',
    fontWeight: 500,
    lineHeight: 'normal',
  },
};

export const HomeTimeSavingsSkeletonSx: SxProps = {
  width: '42px',
  height: '42px',
};

export const InfoSpanStyle: React.CSSProperties = {
  paddingLeft: '5px',
  position: 'relative',
  top: '4px',
  width: '8px',
};

export const InfoIconSx: SxProps = {
  color: theme.palette.colors.gray70,
};
