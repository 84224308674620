export const PRODUCT_SKU_INPUT_ID = 'add-order-item-by-product-sku-input';
export const PRODUCT_QUANTITY_TEXT_ID =
  'add-order-item-by-product-quantity-text-input';

export const ADD_ORDER_ITEM_BY_PRODUCT_SKU_ERROR_MESSAGE =
  'SKU format should be composed of only numbers with the highest possible value being 9999.';

export const PRODUCT_QUANTITY_MIN = 1;

export const PRODUCT_VARIANT = 'Product Variant';
export const PRODUCT_SKU_FIELD = 'sku';

export const QUANTITY_FIELD = 'quantity';
