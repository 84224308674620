import { SxProps } from '@mui/material';
import theme from 'themes/theme';
import { ASSET_FILTER_HEIGHT, ASSET_FILTER_WIDTH } from './constants';

export const AssetFilterStackSx: SxProps = {
  position: 'relative',
  '&#asset-filter-status-stack-container': {
    '& .MuiButtonBase-root.MuiIconButton-root': {
      position: 'absolute',
      top: '7px',
    },
  },
};

export const getAssetFilterFormControlSx = (inputLabelId: string): SxProps => ({
  height: ASSET_FILTER_HEIGHT,
  p: 0,
  width: ASSET_FILTER_WIDTH,
  [`& #${inputLabelId}`]: {
    width: ASSET_FILTER_WIDTH,
    top: '-10px',
    '&.MuiInputLabel-shrink': {
      top: 0,
    },
  },

  '& .MuiOutlinedInput-root': {
    width: ASSET_FILTER_WIDTH,
  },

  '& .MuiButtonBase-root.MuiIconButton-root': {
    border: '1px solid',
  },
});

export const AssetFilterSelectSx: SxProps = {
  width: '172px',
  '& .MuiSelect-select': {
    py: '6px',
  },

  '& #asset-filter-status-input': {
    lineHeight: '28px',
  },
};

export const AssetFilterClearButtonSx: SxProps = {
  backgroundColor: `${theme.palette.colors.grayF6} !important`,
  height: '24px',
  position: 'absolute',
  right: '30px',
  transformOrigin: 'top right',
  top: '5px',
  width: '24px',
  zIndex: 1,
  '&:hover': {
    backgroundColor: 'inherit',
  },
};

export const AssetFilterTitleSx: SxProps = {
  color: theme.palette.colors.gray60,
  fontSize: '12px',
  lineHeight: 1,
};

export const DeviceFilterClearButtonSx: SxProps = {
  pr: 0,
  py: 0,
  color: theme.palette.colors.offBlack,
  '&:hover': {
    opacity: 0.6,
  },
  '& .MuiTypography-root': {
    fontSize: '14px',
    fontWeight: 600,
  },
  '& .MuiButton-startIcon': {
    mr: '4px',
  },
};

export const AssetFilterFormControlSx: SxProps = {
  m: 0,
  p: 0,
  width: ASSET_FILTER_WIDTH,
  '& .MuiInputBase-formControl': {
    height: ASSET_FILTER_HEIGHT,
    p: 0,
  },

  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    position: 'relative',
  },

  '& .MuiFormControl-root.MuiTextField-root': {
    pl: 0,
  },
};

/* START of Organization Styling */
export const AutoCompleteStackSx: SxProps = {
  width: ASSET_FILTER_WIDTH,
  height: ASSET_FILTER_HEIGHT,
  position: 'relative',
};

export const AutoCompleteSearchTextFieldSx: SxProps = {
  p: '0',
  pl: 3,
  borderRadius: 0,
};

export const AutoCompleteSx: SxProps = {
  p: 0,
  backgroundColor: 'transparent',
  border: 'none',
  height: ASSET_FILTER_HEIGHT,
  position: 'relative',

  '& .MuiInputBase-root': {
    backgroundColor: 'transparent',
  },

  '& .MuiFormControl-root': {
    backgroundColor: 'transparent',
    pl: 0,

    '& .MuiFormLabel-root.MuiInputLabel-root': {
      top: '-10px',
      '&.MuiInputLabel-shrink': {
        top: '0px',
      },
    },

    '& .MuiOutlinedInput-input': {},
  },

  '& .MuiInputBase-formControl': {
    backgroundColor: 'transparent',
    height: ASSET_FILTER_HEIGHT,
    py: 0,
  },
};
/* END of Organization Styling */
