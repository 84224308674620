/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Button, NewModal } from 'components';
import {
  ARE_YOU_SURE_TEXT,
  CANCEL_TEXT,
  CHANGES_REFLECTED_TEXT,
  CONTINUE_TEXT,
  HEADER,
} from './constants';
import * as styles from './styles';
import { UploadConfirmationModalProps } from './types';

const UploadConfirmationModal = (props: UploadConfirmationModalProps) => {
  if (!props.isOpen) return null;

  return (
    <NewModal
      open={props.isOpen}
      handleClose={props.handleCancel}
      paperPropsSx={styles.PaperSx}
    >
      <Stack
        direction="column"
        spacing={0}
        justifyContent="space-between"
        height="100%"
      >
        <Stack rowGap="1rem">
          <Typography variant="h4" sx={styles.VariantH4Sx}>
            {HEADER}
          </Typography>
          <Typography variant="body1">
            <div>{ARE_YOU_SURE_TEXT}</div>
            <div>{CHANGES_REFLECTED_TEXT}</div>
          </Typography>
        </Stack>
        <Stack sx={styles.ButtonGroupSx}>
          <Button
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Continue editing',
              purpose: 'Continues to edit asset',
            }}
            variant="text"
            onClick={props.handleCancel}
            sx={{ justifyContent: 'flex-end' }}
          >
            <Typography variant="button" sx={styles.CancelTextSx}>
              {CANCEL_TEXT}
            </Typography>
          </Button>
          <Button
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Save changes',
              purpose: 'Save Asset Modifications',
            }}
            onClick={props.handleContinue}
            variant="contained"
          >
            <Typography variant="button" sx={styles.BaseButtonTextSx}>
              {CONTINUE_TEXT}
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </NewModal>
  );
};

export default UploadConfirmationModal;
