import { SxProps } from '@mui/material';

const STANDARD_HEIGHT = '40px';
const MAX_BASE_HEIGHT_DIFF = 310;
const MAX_HEIGHT_FILTERED_DIFF = 375;
const MAX_BASE_HEIGHT = `calc(98vh - ${MAX_BASE_HEIGHT_DIFF}px)`;
const MAX_HEIGHT_FILTERED = `calc(98vh - ${MAX_HEIGHT_FILTERED_DIFF}px)`;

export const getFilterDrawerContentSx: (
  hasFilters: boolean,
  overrideSx?: SxProps
) => SxProps = (hasFilters: boolean, overrideSx: SxProps = {}) => ({
  width: '500px',
  borderTopLeftRadius: hasFilters ? 0 : '10px',
  borderTopRightRadius: hasFilters ? 0 : '10px',
  px: '32px',
  pb: '16px',
  pt: 0,
  maxHeight: hasFilters ? MAX_HEIGHT_FILTERED : MAX_BASE_HEIGHT,
  ...overrideSx,
});

export const DialogContentContainerSx: SxProps = {
  width: '100%',
};

export const DialogSectionGridSx: SxProps = {
  boxSizing: 'border-box',
  pb: '8px',
  pt: '16px',
};

export const DialogLayoutItemBoxSx: SxProps = {
  boxSizing: 'border-box',
  height: STANDARD_HEIGHT,
  maxHeight: STANDARD_HEIGHT,
};

export const DialogLayoutTypeSx: SxProps = {
  color: 'colors.woodSmoke',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '157%',
};

export const GridItemBoxSx: SxProps = {
  position: 'relative',
  width: '100%',
};
