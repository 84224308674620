export const INSURANCE_OPTIONS = [
  {
    optionText: 'None',
    value: {
      insuranceCharged: '0',
      // request amount passed needs to be in decimal form
      insuranceRequested: '0.00',
    },
  },
  {
    optionText: '$500 ($7.50 cost)',
    value: {
      insuranceCharged: '$7.50',
      insuranceRequested: '500.00',
    },
  },
  {
    optionText: '$1000 ($15 cost)',
    value: {
      insuranceCharged: '$15',
      insuranceRequested: '1000.00',
    },
  },
  {
    optionText: '$1500 ($22.50 cost)',
    value: {
      insuranceCharged: '$22.50',
      insuranceRequested: '1500.00',
    },
  },
  {
    optionText: '$2000 ($30.00 cost)',
    value: {
      insuranceCharged: '$30',
      insuranceRequested: '2000.00',
    },
  },
  {
    optionText: '$2500 ($37.50 cost)',
    value: {
      insuranceCharged: '$37.50',
      insuranceRequested: '2500.00',
    },
  },
  {
    optionText: '$3000 ($45.00 cost)',
    value: {
      insuranceCharged: '$45',
      insuranceRequested: '3000.00',
    },
  },
];

export const ORDER_ITEM_TITLE_FALLBACKS = {
  assetMake: 'MISSING_ASSET_MAKE',
  assetModel: 'MISSING_ASSET_MODEL',
};

export const ORDER_ITEM_TITLE_MAX_LENGTH = 60;

export const RETRIEVAL_KIT = 'Retrieval Kit';
export const INBOUND = 'Inbound';
export const OUTBOUND = 'Outbound';

export const INBOUND_RETRIEVAL_KIT = `${INBOUND} ${RETRIEVAL_KIT}`;
export const OUTBOUND_RETRIEVAL_KIT = `${OUTBOUND} ${RETRIEVAL_KIT}`;
