import { gql } from '@apollo/client';

export const SEARCH_COLLABORATORS = gql`
  query collaborators(
    $organizationId: ID!
    $offset: Int!
    $limit: Int!
    $firstName: String
    $orderAsc: String
  ) {
    collaborators(
      collaboratorSearchInput: {
        organizationId: $organizationId
        offset: $offset
        limit: $limit
        firstName: $firstName
        orderAsc: $orderAsc
      }
    ) {
      collaborators {
        id
        firstName
        lastName
        email
      }
      count
    }
  }
`;

export const RETRIEVE_COLLABORATOR = gql`
  query collaborator($id: String!) {
    collaborator(id: $id) {
      id
      firstName
      lastName
      email
    }
  }
`;
