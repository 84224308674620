import { RetrievalAssetTypes } from 'pages/Retrieval/constants';
import { capitalizeWords } from 'services/format';
import { IAddAssetFormValues } from './types';

const RetrievalAssetTypesList = Object.values(RetrievalAssetTypes) as string[];

export const RETRIEVAL_ASSET_TYPES = RetrievalAssetTypesList.map((assetType) =>
  capitalizeWords(assetType)
);
export const RETRIEVAL_ASSET_TYPES_VALUE_LOOKUP = RETRIEVAL_ASSET_TYPES.reduce(
  (table: Record<string, any>, dType, index) => {
    table[`${dType}`] =
      RetrievalAssetTypesList[index as keyof typeof RetrievalAssetTypesList];
    return table;
  },
  {}
);

export const DefaultAddAssetValues: IAddAssetFormValues = {
  assetType: RetrievalAssetTypes.LAPTOP, // LAPTOP but renders 'Laptop'
  serialNumber: '',
  make: '',
  memory: '',
  processor: '',
  displaySize: '',
  model: '',
  storage: '',
  modelNumber: '',
  color: '',
  doesNotKnowSerialNumber: false,
};
