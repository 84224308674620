import { SxProps } from '@mui/material';
import { EMBEDDED_APP_MAX_HEIGHT, HEADER_HEIGHT } from 'global-styles';

export const ContentDivSx: SxProps = {
  bottom: 0,
  height: '100%',
  left: '20rem',
  maxHeight: EMBEDDED_APP_MAX_HEIGHT,
  position: 'absolute',
  right: 0,
  top: HEADER_HEIGHT,
};
