import { UPLOAD_ERRORS_FOUND_INFO_POSTFIX_COPY } from './constants';

export const getUploadedCountsText = (
  notUploadedCount: number,
  totalCount: number,
  domain: string
) => {
  const domainLabel =
    totalCount === 1 ? domain.slice(0, domain.length - 1) : domain;
  const verb = totalCount === 1 ? 'was' : 'were';
  return `${notUploadedCount} of ${totalCount} ${domainLabel} ${verb} not imported`;
};

export const getTotalErrorsText = (errorCount: number) => {
  const errorLabel = errorCount === 1 ? 'error' : 'errors';
  return `${errorCount} ${errorLabel} ${UPLOAD_ERRORS_FOUND_INFO_POSTFIX_COPY}`;
};
