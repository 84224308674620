export const NotificationBannerContainerSx = {
  display: 'flex',
  position: 'fixed',
  justifyContent: 'center',
  alignItems: 'center',
  top: '0',
  width: '100%',
  zIndex: '9000',
  backgroundColor: 'rgba(52, 155, 168, 1)',
  height: '78px',
};
