import { SxProps } from '@mui/material';
import theme from 'themes/theme';
import { AMDashDeviceInfoTestId } from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/components/DeviceInfoFilterSection/constants';

export const getAMDashComplexDropDownClearStackSx = (
  shouldRender: boolean
): SxProps => ({
  display: shouldRender ? 'flex' : 'none',
  fontSize: '12px',
  fontWeight: 600,
  height: '34px',
  px: '16px',
  '& .clear-all-btn': {
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
  '& .clear-box-copy': {
    fontWeight: 600,
  },
});

export const AMDashComplexDropDownMenuItemSx: SxProps = {
  maxHeight: '42px',
  pl: '8px',
  '& > .MuiCheckbox-root': {
    color: '#131314 !important',
  },
};

export const AMComplexSelectFormControlSx: SxProps = {
  minHeight: '40px',
  height: '40px',
  width: '100%',

  '&.MuiInputLabel-shrink': {
    top: '-10px',
  },

  '& .MuiInputLabel-shrink.Mui-focused, & .MuiFormLabel-root': {
    background: 'white',
    px: '2px',
  },

  '& .MuiFormLabel-root.MuiInputLabel-root': {
    top: '-8px',
    '&.MuiInputLabel-shrink': {
      top: '1px',
    },
  },

  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, & .Mui-focused.MuiFormLabel-root, & .Mui-focused.MuiInputLabel-root':
    {
      color: theme.palette.colors.woodSmoke,
      borderColor: theme.palette.colors.woodSmoke,
    },

  '& .MuiInputBase-formControl.MuiOutlinedInput-root': {
    height: '100%',
  },
};

export const AMComplexSelectSx: SxProps = {
  width: '100%',
};

const AVATAR_TOP = '10px';

export const getAMDashDropdownFilterCountSx = (visible: boolean): SxProps => ({
  display: visible ? 'flex' : 'none',
  fontSize: '12px',
  backgroundColor: theme.palette.colors.woodSmoke,
  position: 'absolute',
  height: '20px',
  width: '20px',
  zIndex: 1550,

  '&[data-testid="depot-location-filter-total-avatar"]': {
    top: '10px',
    right: '58px',
  },

  '&[data-testid="asset-status-filter-total-avatar"]': {
    top: '10px',
    right: '126px',
  },

  [`&[data-testid="${AMDashDeviceInfoTestId.ASSET_TYPE}"]`]: {
    top: AVATAR_TOP,
    right: '135px',
  },

  [`&[data-testid="${AMDashDeviceInfoTestId.ASSET_CONDITION}"]`]: {
    top: AVATAR_TOP,
    right: '100px',
  },

  [`&[data-testid="${AMDashDeviceInfoTestId.ASSET_DATA_STATUS}"]`]: {
    top: AVATAR_TOP,
    right: '84px',
  },
});

export const AMDashClearAllButtonSx: SxProps = {
  backgroundColor: 'transparent',
  borderRadius: '5px',
  color: theme.palette.colors.woodSmoke,
  fontSize: '12px',
  fontWeight: 600,
  height: '40px',
  maxHeight: '100%',
  minWidth: '80px',
  px: '12px',
  '&:hover': {
    backgroundColor: theme.palette.colors.transparentGrey,
  },
};
