/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Tooltip from '@mui/material/Tooltip';
import { EmployeeActionsGroupProps } from './types';
import * as EmployeeActionsGroupStyles from './styles';
import { useAuth0 } from '@auth0/auth0-react';
import GAIconButton from 'components/buttons/google_analytics/GAIconButton';

const EmployeeActionsGroup = (props: EmployeeActionsGroupProps) => {
  const { user } = useAuth0();
  const styles = EmployeeActionsGroupStyles;

  const disableArchiveEmployee =
    !props.employee || props.employee?.id === user?.collaboratorId;

  const handleClickEditEmployee = () => {
    props.showEditEmployeeInfoModal();
  };

  const handleClickArchiveEmployee = () => {
    props.openArchiveEmployeeModal();
  };

  const isEditEmployeeDisabled = () =>
    !props.employee || !!props.employee?.hrisEmployeeId;

  const buttonGroupSx = {
    ...styles.getEmployeeActionButtonGroupSx(),
    ...props.EmployeeActionsButtonGroupSx,
  };

  return (
    <ButtonGroup sx={buttonGroupSx}>
      <Tooltip title="Edit Employee" arrow placement="bottom">
        <span>
          <GAIconButton
            size="large"
            disableRipple
            color="secondary"
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Edit Icon',
              purpose: 'Opens edit employee modal',
            }}
            sx={styles.ActionIconButtonSx}
            onClick={handleClickEditEmployee}
            disabled={isEditEmployeeDisabled()}
          >
            <EditOutlinedIcon />
          </GAIconButton>
        </span>
      </Tooltip>
      <Tooltip title="Archive Employee" arrow placement="bottom">
        <span>
          <GAIconButton
            size="large"
            disableRipple
            color="secondary"
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Archive Icon',
              purpose: 'Opens archive employee modal',
            }}
            sx={styles.ActionIconButtonSx}
            onClick={handleClickArchiveEmployee}
            disabled={disableArchiveEmployee}
          >
            <ArchiveOutlinedIcon />
          </GAIconButton>
        </span>
      </Tooltip>
    </ButtonGroup>
  );
};

export default EmployeeActionsGroup;
