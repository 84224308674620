import { gql } from '@apollo/client';

export const RETRIEVE_ORDER_DETAILS = gql`
  query order($id: String!) {
    order(id: $id) {
      id
      organizationId
      orderNumber
      createdAt
      dueDate
      rush
      finalDestination
      status
      shippingType {
        name
      }
      orderType {
        id
        name
        toRecipient
        toDropRecipient
      }
      orderItems {
        source
      }
      recipient {
        id
        firstName
        lastName
        email
        address {
          streetAddress1
          streetAddress2
          city
          state
          principalRegion
          zipCode
          country
        }
      }
    }
  }
`;
