import React from 'react';
//@ts-ignore
import Retool from 'react-retool';
import Container from '@mui/material/Container';
import { ContentDivSx } from './styles';
import { RETOOL_BASE_URL, UserPermissions } from 'global-constants';
import PickGlobalDataRetoolPrompt from 'components/PickGlobalDataRetoolPrompt';
import useGetGlobalPurchaser from 'hooks/useGetGlobalPurchaser';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import useGetUserPermissions from 'hooks/useGetUserPermissions';

const AccountSettings = () => {
  const globalPurchaser = useGetGlobalPurchaser();
  const userOrganization = useGetUserOrganization();
  const userPermissions = useGetUserPermissions();
  const canToggleOrganization = userPermissions.includes(
    UserPermissions.ORGANIZATION__TOGGLE
  );

  //Admin users
  if (
    (!globalPurchaser?.email || !userOrganization?.id) &&
    canToggleOrganization
  )
    return <PickGlobalDataRetoolPrompt />;

  //Non-admin users
  if (!globalPurchaser?.email || !userOrganization?.id) return <></>;

  const data = {
    '#customerEmail': globalPurchaser.email,
  };

  const releaseVersion = '0.1.0';

  const url = `${RETOOL_BASE_URL}/${process.env.REACT_APP_RETOOL_ID_ACCOUNT_SETTINGS}?_environment=${process.env.REACT_APP_RETOOL_ENVIRONMENT}&_releaseVersion=${releaseVersion}`;

  return (
    <Container sx={ContentDivSx}>
      <Retool
        width="100%"
        height="100%"
        frameborder="0"
        url={url}
        data={data}
      />
    </Container>
  );
};

export default AccountSettings;
