import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const RadioOptionOuterSx: SxProps = {
  mt: '16px',
};

export const RadioOptionInputSx: SxProps = {
  minWidth: '188px',

  '& .MuiRadio-root': {
    color: theme.palette.colors.black,
    '&.Mui-checked ~ .MuiTypography-root': {
      fontWeight: 600,
    },
    '&.Mui-checked': {
      color: theme.palette.colors.strongBlue,
    },
  },

  '& .MuiFormControlLabel-root': {
    '&:not(:last-child)': {
      mr: '35px',
    },
  },
};
