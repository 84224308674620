import React from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { getInitials } from 'services/getInitials';
import { Avatar } from 'components';
import styles from './Person.module.css';
import { PersonProps } from './types';

const Person = (props: PersonProps) => {
  const initials = getInitials(props.collaborator);

  const theme = useTheme();

  const onClickDelete = () => {
    props.removeSelectedCollaborator(props.collaborator);
  };

  return (
    <div className={styles['person-container']}>
      <Avatar text={initials} width={40} height={40} />
      <Typography variant="body2">
        {props.collaborator.firstName} {props.collaborator.lastName}
      </Typography>
      <DeleteIcon
        sx={{ cursor: 'pointer' }}
        htmlColor={theme.palette.colors.silverWN}
        onClick={() => onClickDelete()}
      />
    </div>
  );
};

export default Person;
