import React from 'react';
import { FilterEntry } from 'store/shared/types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as TypeaheadStyles from 'components/complex_filter/fields/Typeahead/styles';
import * as FieldStyles from 'components/complex_filter/fields/styles';

export const renderOptionListItemHOC =
  (
    clearAllSelections: (evt: any) => void,
    options: {
      hasSelections: boolean;
      entriesLength: number;
      baseSelectorPrefix: string;
    }
  ) =>
  (
    listProps: React.HTMLAttributes<HTMLLIElement>,
    option: FilterEntry,
    selected: boolean
  ): JSX.Element => {
    const { hasSelections, entriesLength, baseSelectorPrefix } = options;
    const isFirst =
      Number((listProps as unknown as any)?.['data-option-index']) === 0;
    const shouldRenderClearAll =
      hasSelections && isFirst && Boolean(entriesLength);

    return (
      <>
        {shouldRenderClearAll ? (
          <Stack
            key={`${baseSelectorPrefix}-clear-all-stack`}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            sx={FieldStyles.getClearStackSx(
              shouldRenderClearAll,
              baseSelectorPrefix,
              TypeaheadStyles.ClearStackOverrideSx
            )}
          >
            <Box
              className={`${baseSelectorPrefix}${FieldStyles.CLASS_NAMES_SUFFIX.appliedBox}`}
            >
              Applied
            </Box>
            <Button
              component="button" // Add the component prop and set it to "button"
              disableRipple
              startIcon={<CloseIcon />}
              sx={FieldStyles.ClearAllButtonSx}
              onClick={clearAllSelections}
            >
              <Typography
                className={`${baseSelectorPrefix}${FieldStyles.CLASS_NAMES_SUFFIX.clearAllType}`}
              >
                Clear all
              </Typography>
            </Button>
          </Stack>
        ) : (
          <></>
        )}
        <li
          key={option.id}
          {...listProps}
          style={TypeaheadStyles.getOptionListItemCSS(
            selected,
            shouldRenderClearAll
          )}
          data-option-uuid={option.id}
        >
          <Checkbox
            icon={
              <CheckBoxOutlineBlankIcon
                fontSize="small"
                style={{ color: '#131314' }}
              />
            }
            checkedIcon={
              <CheckBoxIcon fontSize="small" style={{ color: '#131314' }} />
            }
            style={{ marginRight: 8 }}
            checked={option.selected}
          />
          <Typography sx={TypeaheadStyles.ListOptionsTextSx}>
            {option.displayName}
          </Typography>
        </li>
      </>
    );
  };
