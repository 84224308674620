import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const SummaryCardSx: SxProps = {
  borderRadius: '10px',
  border: '1px solid rgba(19, 20, 20, 0.12)',
  boxShadow: 'none',
  padding: '16px 24px',
};

export const InfoIconSx: SxProps = {
  color: theme.palette.colors.gray75,
  verticalAlign: 'text-bottom',
  marginLeft: '3px',
};
