export const DEFAULT_HEADER_HEIGHT = 52;

export const DEFAULT_ROW_HEIGHT = 52;

export const DEFAULT_GRID_FILTER_MODE = 'client';

export const DEFAULT_PAGINATION_MODE = 'client';

// FILTER REASONS
// 'upsertFilterItem' | 'upsertFilterItems' | 'deleteFilterItem' | 'changeLogicOperator' | 'restoreState'
export enum FilterChangeReason {
  UPSERT_ITEM = 'upsertFilterItem',
  DELETE_ITEM = 'deleteFilterItem',
}

export enum PaginationChangeReason {
  SET_PAGINATION_MODEL = 'setPaginationModel',
  STATE_RESTORE_PREPROCESSING = 'stateRestorePreProcessing',
}

export const ENABLE_HORIZONTAL_SCROLLING_CLASS_NAME =
  'enable-horizontal-scrolling';

export const ENABLE_LARGE_HEADER_HEIGHT_CLASS_NAME =
  'enable-large-header-height';

export const HIDE_SELECT_ALL_CHECKBOX_CLASS_NAME = 'hide-select-all-checkbox';
