/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import OrderStatusPill from 'components/OrderStatusPill';
import { isStringEnumKey } from 'services';
import { OrderStatus } from 'global-constants';
import * as OrderDetailsPanelStyles from './styles';
import { OrderDetailsPanelProps } from './types';

const OrderDetailsPanel = (props: OrderDetailsPanelProps) => {
  const styles = OrderDetailsPanelStyles;

  const formatAddressString = (addressString: string) => {
    const multiLineText = addressString
      .split('\n')
      .map((addressLine, index) => (
        <React.Fragment key={index}>
          {addressLine}
          {index < addressString.split('\n').length - 1 && <br />}
        </React.Fragment>
      ));

    return <Typography variant="body1">{multiLineText}</Typography>;
  };

  const getOrderStatusPill = (status: string) =>
    isStringEnumKey(status, OrderStatus) ? (
      <OrderStatusPill status={status as OrderStatus} />
    ) : (
      <Typography variant="overline" color="colors.gray60">
        {status}
      </Typography>
    );

  return (
    <Grid item container direction="column" sx={{ width: '100%' }}>
      <Typography variant="subtitle2">Allwhere info</Typography>
      <Stack sx={styles.OrderDetailsInfoStackSx} direction="row">
        <Grid container sx={styles.OrderDetailsInfoGridSx}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="overline" color="colors.gray60">
              ORDER NUMBER
            </Typography>
            <Typography variant="body1">{props.orderNumber}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="overline" color="colors.gray60">
              ORDER TYPE
            </Typography>
            <Typography variant="body1">{props.orderType}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="overline" color="colors.gray60">
              ORDER DATE
            </Typography>
            <Typography variant="body1">{props.orderDate}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="overline" color="colors.gray60">
              SOURCE
            </Typography>
            <Typography variant="body1">{props.source}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="overline" color="colors.gray60">
              SHIPPING
            </Typography>
            <Typography variant="body1">{props.shipping}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="overline" color="colors.gray60">
              ORDER STATUS
            </Typography>
            <Typography variant="body1">
              {getOrderStatusPill(props.orderStatus)}
            </Typography>
          </Grid>
        </Grid>
      </Stack>

      <Typography variant="subtitle2" pt="20px" pb="10px">
        Recipient info
      </Typography>
      <Stack sx={styles.OrderDetailsInfoStackSx} direction="row">
        <Grid container sx={styles.OrderDetailsInfoGridSx}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="overline" color="colors.gray60">
              RECIPIENT
            </Typography>
            <Typography variant="body1">{props.recipient}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="overline" color="colors.gray60">
              CONTACT EMAIL
            </Typography>
            <Typography variant="body1">{props.recipientEmail}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {/** empty 3rd block to wrap next blocks on a new row */}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="overline" color="colors.gray60">
              RECIPIENT ADDRESS
            </Typography>
            <Typography variant="body1">
              {formatAddressString(props.recipientAddress)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="overline" color="colors.gray60">
              FINAL DESTINATION
            </Typography>
            <Typography variant="body1">
              {formatAddressString(props.finalDestination)}
            </Typography>
          </Grid>
        </Grid>
      </Stack>
    </Grid>
  );
};

export default OrderDetailsPanel;
