/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import NewModal from 'components/NewModal';
import { RecipientChangeText } from './constants';
import { ConfirmRecipientSourceModalProps } from './types';
import { UNREACHABLE } from 'global-constants';

const ConfirmRecipientSourceModal = (
  props: ConfirmRecipientSourceModalProps
) => {
  const {
    recipientChangeState: stateProps,
    setRecipientChangeState: setState,
  } = props;

  const onCloseModal = () => {
    setState({ ...stateProps, openModal: false, origin: UNREACHABLE });
  };

  const onSubmitChangeOfRecipient = (
    event: React.FormEvent<HTMLInputElement> | any
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setState({ ...stateProps, openModal: false, changed: true });
  };

  const getTitle = (recipientName: string = 'N/A') =>
    `${RecipientChangeText.TITLE}, ${recipientName}?`;

  return (
    <NewModal
      form
      open={stateProps.openModal}
      title={getTitle(stateProps.collaboratorName)}
      subtitle={RecipientChangeText.SUBTITLE}
      submitButtonCopy={RecipientChangeText.CHANGE_BUTTON_TEXT}
      handleClose={onCloseModal}
      onSubmit={onSubmitChangeOfRecipient}
    />
  );
};

export default ConfirmRecipientSourceModal;
