import { ViewableUploadMetadata } from 'store/shared/uploads/types';
import { getLookbackTimestamp } from 'store/shared/uploads/utils';

export const getErrorsFileName = (uploadFileName?: string) => {
  return uploadFileName
    ? `${uploadFileName.replace('.csv', '')}_errors`
    : 'errors';
};

export const getPersistableUploads = (
  uploads: ViewableUploadMetadata[],
  lookback = 3
) => {
  const cutOffTimestamp = getLookbackTimestamp(lookback);
  return uploads.filter(
    (upload) => upload.timestamp > cutOffTimestamp && upload.viewed
  );
};
