import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import TextIconButton from 'components/TextIconButton';
import DownloadIcon from 'components/Icons/DownloadIcon';

import { CSVDownloadButtonProps } from './types';
import { CSVTextSx, LoadingSx, LoadingSpinnerSx } from './styles';

const CSVDownloadButton = (props: CSVDownloadButtonProps) => {
  const { text, ariaLabel, onClick, loading } = props;
  return (
    <Stack justifyContent="center">
      <Box sx={LoadingSx(loading)}>
        <TextIconButton
          disabled={loading}
          text={loading ? 'Preparing CSV...' : text}
          textStyles={CSVTextSx(loading)}
          ariaLabel={ariaLabel}
          onClick={onClick}
          icon={
            loading ? (
              <CircularProgress size={18} sx={LoadingSpinnerSx} />
            ) : (
              <DownloadIcon />
            )
          }
        />
      </Box>
    </Stack>
  );
};

export default CSVDownloadButton;
