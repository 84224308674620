import { gql } from '@apollo/client';

export const DELETE_INVENTORY_RECEIPT = gql`
  mutation deleteInventoryReceipt($id: String!) {
    deleteInventoryReceipt(id: $id) {
      success
      message
    }
  }
`;
