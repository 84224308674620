/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useSelector } from 'react-redux';
import MuiAvatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FilterEntry } from 'store/shared/types';
import DropDownProps from './types';
import * as FieldStyles from '../styles';
import * as DropDownStyles from './styles';

const DropDown = (props: DropDownProps) => {
  const styles = DropDownStyles;
  const matchingConfig = props.dropdownHandlersConfig[props.filterKey];

  if (!matchingConfig) return <></>;

  const {
    makeSelectionText,
    inputLabel,
    counterOffsetRight = 0,
  } = matchingConfig;

  const getExpandedInput = props.selectExpandedFilter(props.filterSubTree);
  const expandedInput = useSelector(getExpandedInput);
  const isOpen = expandedInput === props.filterKey;

  const getAvailableEntries = props.selectAvailableEntries(
    props.filterSubTree,
    props.filterKey
  );

  const availableEntries: FilterEntry[] =
    useSelector(getAvailableEntries) || [];
  const filled = availableEntries.length > 0;

  const getSelectedEntries = props.selectChosenEntries(
    props.filterSubTree,
    props.filterKey
  );
  const selectedEntries: FilterEntry[] = useSelector(getSelectedEntries) || [];

  const selectEntriesByIdTable: Record<string, FilterEntry> =
    Object.fromEntries(selectedEntries.map((entry) => [entry.id, entry]));

  props.loadAsyncDropdownOptions(props.filterKey);
  props.loadDropDownOptions(props.filterKey, filled);

  const handleClose = () => {
    props.onCloseInput();
  };

  const handleOpen = () => {
    props.onOpenInput(props.filterKey);
  };

  const clearAllSelections = () => {
    props.clearAllByKey(props.filterKey);
  };

  const counterVisible = Boolean(selectedEntries?.length ?? 0) && !isOpen;
  const hasSelections = selectedEntries.length > 0;

  const getRenderValueText = () =>
    selectedEntries.length ? inputLabel : makeSelectionText;

  const hasOptions = availableEntries.length > 0;

  return (
    <>
      <FormControl fullWidth sx={styles.SelectFormControlSx}>
        <InputLabel>{inputLabel}</InputLabel>
        <Select
          multiple
          disabled={!hasOptions}
          aria-disabled={!hasOptions}
          value={selectedEntries}
          open={isOpen}
          onOpen={handleOpen}
          onClose={handleClose}
          onChange={props.onSelectDropdown(props.filterKey)}
          sx={styles.DropDownSelectSx}
          renderValue={getRenderValueText}
        >
          {hasSelections ? (
            <ListSubheader>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={styles.getClearStackSx(props.baseSelectorPrefix)}
              >
                <Box
                  className={`${props.baseSelectorPrefix}${FieldStyles.CLASS_NAMES_SUFFIX.appliedBox}`}
                >
                  Applied
                </Box>
                <Button
                  component="button" // Add the component prop and set it to "button"
                  disableRipple
                  startIcon={<CloseIcon />}
                  sx={FieldStyles.ClearAllButtonSx}
                  onClick={clearAllSelections}
                >
                  <Typography
                    className={`${props.baseSelectorPrefix}${FieldStyles.CLASS_NAMES_SUFFIX.clearAllType}`}
                  >
                    Clear all
                  </Typography>
                </Button>
              </Stack>
            </ListSubheader>
          ) : (
            <div className="has-no-clear-stack" />
          )}
          {hasOptions ? (
            availableEntries.map((entry: FilterEntry, index: number) => {
              return (
                <MenuItem
                  key={entry.value}
                  value={entry.id}
                  sx={styles.getDropDownOptionsMenuItemSx(
                    Boolean(selectEntriesByIdTable[entry.id])
                  )}
                >
                  <Checkbox
                    checked={Boolean(selectEntriesByIdTable[entry.id])}
                  />
                  <Typography>{entry.displayName}</Typography>
                </MenuItem>
              );
            })
          ) : (
            <MenuItem sx={styles.DropDownOptionsMenuItemSx}>
              <Typography>{makeSelectionText}</Typography>
            </MenuItem>
          )}
        </Select>
      </FormControl>
      <MuiAvatar
        sx={styles.getDropdownFilterCountSx(
          counterVisible,
          counterOffsetRight || 0
        )}
      >
        {selectedEntries.length}
      </MuiAvatar>
    </>
  );
};

export default DropDown;
