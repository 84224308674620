import { createSlice } from '@reduxjs/toolkit';
import {
  resetComboSearchReducer,
  resetNonComboSearchReducer,
  setSearchFilterByKeyReducer,
  clearSearchFilterByKeyReducer,
  setLookAheadOptionReducer,
} from './reducers';
import OrdersSearchState from './types';

export const INITIAL_ORDERS_SEARCH_STATE: OrdersSearchState = {
  orderTypeFilter: '',
  statusFilter: '',
  orderNumberFilter: '',
  lookAheadOptionType: 'orderNumber',
  lookAheadOption: '',
};

export const orderSearchSlice = createSlice({
  name: 'orderSearchSlice',
  initialState: INITIAL_ORDERS_SEARCH_STATE,
  reducers: {
    resetComboSearchReducer,
    resetNonComboSearchReducer,
    setSearchFilterByKeyReducer,
    clearSearchFilterByKeyReducer,
    setLookAheadOptionReducer,
    resetState: () => INITIAL_ORDERS_SEARCH_STATE,
  },
});

export const {
  resetState,
  resetComboSearchReducer: resetComboSearch,
  resetNonComboSearchReducer: resetNonComboSearch,
  setSearchFilterByKeyReducer: setSearchFilterByKey,
  clearSearchFilterByKeyReducer: clearSearchFilterByKey,
  setLookAheadOptionReducer: setLookAheadOption,
} = orderSearchSlice.actions;
