/* eslint-disable sort-exports/sort-exports */
import { SxProps } from '@mui/material';

export const BillingOuterFormStackSx: SxProps = {
  boxSizing: 'border-box',
  pt: '25px',
  width: '100%',
};

export const BillingInnerFormStackSx: SxProps = {
  position: 'relative',
  width: 'calc(50% - 10px)',
};

export const BillingTextFieldBaseSx: SxProps = {
  backgroundColor: 'transparent',
  p: 0,
  '&: .MuiFormHelperText-root': {
    color: 'transparent',
    position: 'absolute',
    bottom: '-20px',
  },
};
