/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Box from '@mui/material/Box';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FilterDrawerHeaderProps from './types';
import * as FilterDrawerHeaderStyles from './styles';

const FilterDrawerHeader = (props: FilterDrawerHeaderProps) => {
  const styles = FilterDrawerHeaderStyles;

  return (
    <Box sx={styles.FilterDrawerTopBoxSx}>
      <Typography sx={styles.FilterTitleSx}>Filter by</Typography>
      <IconButton
        onClick={props.onClosePopover}
        data-testid={`${props.filterSubTree}-filter-by-close-icon`}
        aria-label={`${props.filterSubTree}-filter-by-close-icon`}
      >
        <CloseSharpIcon />
      </IconButton>
    </Box>
  );
};

export default FilterDrawerHeader;
