import React from 'react';
import useTheme from '@mui/material/styles/useTheme';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import FieldInputWrapper from '../FieldInputWrapper';
import { LaptopProtectionPlanProps } from './types';
import { NO_PROTECTION_PLAN, PROTECTION_PLAN_OPTIONS } from './constants';

const LaptopProtectionPlan = (props: LaptopProtectionPlanProps) => {
  const theme = useTheme();

  return (
    <FieldInputWrapper>
      <Typography variant="body2" color={theme.palette.colors.grayWN}>
        Protection plan *
      </Typography>
      <Select
        variant="filled"
        sx={{ height: '34px' }}
        defaultValue={NO_PROTECTION_PLAN}
        {...props.register('protectionPlan', {
          required: true,
        })}
      >
        {PROTECTION_PLAN_OPTIONS.map((protectionPlanOption) => (
          <MenuItem key={protectionPlanOption} value={protectionPlanOption}>
            {protectionPlanOption}
          </MenuItem>
        ))}
      </Select>
    </FieldInputWrapper>
  );
};

export default LaptopProtectionPlan;
