import theme from 'themes/theme';

export const AssetContainerSx = { width: '75%' };
export const AssetSx = {
  mb: '15px',
  mt: '15px',
  fontSize: '16px',
  fontWeight: '700',
};

export const DeviceInfoColumnSx = {
  width: '33%',
};

export const AssetRowSx = {
  ml: '15px',
  mr: '15px',
  padding: '15px',
  bgcolor: theme.palette.colors.grayFA,
};
