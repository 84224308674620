import React from 'react';

const MexicoFlagSVGIcon = () => {
  return (
    <svg
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.25"
        y="0.25"
        width="27.5"
        height="19.5"
        rx="1.75"
        fill="white"
        stroke="#F5F5F5"
        stroke-width="0.5"
      />
      <mask
        id="mask0_3141_38712"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="28"
        height="20"
      >
        <rect
          x="0.25"
          y="0.25"
          width="27.5"
          height="19.5"
          rx="1.75"
          fill="white"
          stroke="white"
          stroke-width="0.5"
        />
      </mask>
      <g mask="url(#mask0_3141_38712)">
        <rect x="18.6667" width="9.33333" height="20" fill="#E3283E" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 20H9.33333V0H0V20Z"
          fill="#128A60"
        />
        <path
          d="M11.9999 9.33203C11.9999 8.96384 11.7014 8.66536 11.3333 8.66536C10.9651 8.66536 10.6666 8.96384 10.6666 9.33203H11.9999ZM12.3289 12.2168C12.6474 12.4016 13.0554 12.2932 13.2402 11.9748C13.4249 11.6563 13.3166 11.2483 12.9981 11.0635L12.3289 12.2168ZM15.0756 11.0185C14.7654 11.2169 14.6748 11.6292 14.8731 11.9394C15.0715 12.2495 15.4838 12.3402 15.794 12.1418L15.0756 11.0185ZM17.3333 9.33203C17.3333 8.96384 17.0348 8.66536 16.6666 8.66536C16.2984 8.66536 15.9999 8.96384 15.9999 9.33203H17.3333ZM10.6666 9.33203C10.6666 10.5651 11.3365 11.6409 12.3289 12.2168L12.9981 11.0635C12.3998 10.7164 11.9999 10.0705 11.9999 9.33203H10.6666ZM15.794 12.1418C16.7182 11.5507 17.3333 10.5134 17.3333 9.33203H15.9999C15.9999 10.0397 15.6329 10.6621 15.0756 11.0185L15.794 12.1418Z"
          fill="#8C9157"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.0001 10.668C14.7365 10.668 15.3334 9.77254 15.3334 8.66797C15.3334 7.5634 14.7365 6.66797 14.0001 6.66797C13.2637 6.66797 12.6667 7.5634 12.6667 8.66797C12.6667 9.77254 13.2637 10.668 14.0001 10.668Z"
          fill="#C59262"
        />
      </g>
    </svg>
  );
};

export default MexicoFlagSVGIcon;
