import { SxProps } from '@mui/material';
import theme from 'themes/theme';

const TRANSPARENT_BLACK_54 = 'rgba(0, 0, 0, 0.54)';

export const ReviewNameSearchSx: SxProps = {
  backgroundColor: 'transparent',
  p: 0,

  '& .MuiInputBase-input': {
    pl: '40px',
  },

  '& .MuiFormLabel-root': {
    pl: '30px',
    '&.MuiInputLabel-shrink': {
      pl: 0,
    },
  },
};

export const ReviewSearchGlassSx: SxProps = {
  color: TRANSPARENT_BLACK_54,
  position: 'absolute',
  top: '15px',
  left: '15px',
};

export const ReviewSearchClearSx: SxProps = {
  color: TRANSPARENT_BLACK_54,
  position: 'absolute',
  top: '8px',
  right: '15px',
  zIndex: 2,
};

export const ReviewSearchButtonSx: SxProps = {
  backgroundColor: theme.palette.colors.strongBlue,
  borderRadius: '4px',
  pl: '45px',
  width: '100%',

  '&:hover': {
    backgroundColor: theme.palette.colors.strongBlue,
  },
  '& .MuiTypography-root': {
    color: theme.palette.colors.white,
    fontSize: '15px',
    fontWeight: 600,
    lineHeight: '26px',
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.colors.grayEF,

    '& .MuiTypography-root': {
      color: theme.palette.colors.gray70,
    },
  },
};
