/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import NewModal from 'components/NewModal';
import { capitalizeWords } from 'services/format';
import { useSafeMutation } from 'hooks/useSafeMutation';
import { notificationSlice } from 'store/slices';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';
import { SEARCH_ORDERS } from 'pages/OrderManagement/queries';
import {
  CHANGE_ORDER_STATUS_SUBTITLE,
  SUBMIT_BUTTON_COPY,
  CANCEL_BUTTON_COPY,
} from './constants';
import { OrderStatusChangeModalProps } from './types';
import * as OrderStatusChangeModalStyles from './styles';
import { UPDATE_ORDER_STATUS } from './mutations';

const OrderStatusChangeModal = (props: OrderStatusChangeModalProps) => {
  if (!props.order || !props.newStatus) return <></>;

  const dispatch = useDispatch();
  const client = useApolloClient();
  const styles = OrderStatusChangeModalStyles;

  const getChangeOrderStatusTitle = (macroCaseStatus: string) => {
    const headlessStatus = macroCaseStatus.replace('MONO_', '');
    const capitalCaseStatus = capitalizeWords(headlessStatus);

    return `Are you sure you want to change the status of this order to "${capitalCaseStatus}"?`;
  };

  const onCloseStatusChangeModal = (updateStatusHistory = false) => {
    props.handleClose(updateStatusHistory);
  };

  const [mutate, { loading }] = useSafeMutation(UPDATE_ORDER_STATUS);

  const refetchQueries = async () => {
    await client.refetchQueries({
      include: [SEARCH_ORDERS],
    });
  };

  const notifyOnSuccess = () => {
    dispatch(
      notificationSlice.actions.setNotice({
        showNotice: true,
        noticeContent: NotificationMessages.CHANGES_SAVED_SUCCESS,
      })
    );
  };

  const updateOrderStatus = () => {
    if (!props.order || !props.newStatus) return;
    mutate({
      variables: {
        id: props.order.id,
        status: props.newStatus,
      },
      onCompleted: () => {
        props.updateSelectedOrder({ status: props.newStatus });
        refetchQueries();
        notifyOnSuccess();
        onCloseStatusChangeModal(true);
      },
    });
  };

  const onOrderStatusSubmit = (
    event: React.FormEvent<HTMLInputElement> | any
  ) => {
    event.stopPropagation();
    event.preventDefault();
    updateOrderStatus();
  };

  return (
    <NewModal
      form
      open={props.open}
      title={getChangeOrderStatusTitle(props.newStatus)}
      subtitle={CHANGE_ORDER_STATUS_SUBTITLE}
      submitButtonCopy={SUBMIT_BUTTON_COPY}
      cancelButtonCopy={CANCEL_BUTTON_COPY}
      disableSaveButton={false}
      loading={loading}
      onSubmit={onOrderStatusSubmit}
      handleClose={onCloseStatusChangeModal}
      paperPropsSx={styles.PaperPropsSx}
      outerStackPropsSx={styles.OuterStacksPropsSx}
    />
  );
};

export default OrderStatusChangeModal;
