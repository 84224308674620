import { FilterPlaceholders } from 'pages/AssetManagement/constants';

export enum AMDashDeviceInfoTestId {
  ASSET_TYPE = 'amd-device-info-asset-type',
  ASSET_MAKE = 'amd-device-info-asset-make',
  ASSET_MODEL = 'amd-device-info-asset-model',
  ASSET_CONDITION = 'amd-device-info-asset-condition',
  ASSET_KEYBOARD = 'amd-device-info-asset-keyboard',
  ASSET_SERIAL_NO = 'amd-device-info-asset-serial-no',
  ASSET_DEVICE_ISSUE = 'amd-device-info-asset-device-issue',
  ASSET_COLOR = 'amd-device-info-asset-color',
  ASSET_DISPLAY_SIZE = 'amd-device-info-asset-display-size',
  ASSET_DATA_STATUS = 'amd-device-info-asset-data-status',
}

export const ASSET_DATA_STATUS_OPTIONS: string[][] = [
  [FilterPlaceholders.CLEAR_ALL_FILTER_OPTION, ''],
  ['N/A', 'N/A'],
  ['Not Wiped', 'Not Wiped'],
  ['SEE NOTES', 'SEE NOTES'],
  ['Wiped', 'Wiped'],
  ['Wiped by user', 'Wiped by user'],
];

export const ASSET_COSMETIC_CONDITION_OPTIONS: string[][] = [
  [FilterPlaceholders.CLEAR_ALL_FILTER_OPTION, ''],
  ['S - New', 'S - New'],
  ['A - Like New', 'A - Like New'],
  ['B - Very Good', 'B - Very Good'],
  ['C - Good', 'C - Good'],
  ['D - Fair', 'D - Fair'],
];
