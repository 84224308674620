import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastNotificationState } from 'store/types';
import { DEFAULT_NOTIFICATION_TIMEOUT_MS } from 'global-constants';

const notificationInitialState: ToastNotificationState = {
  showNotice: false,
  noticeContent: null,
  hideNoticeDelay: DEFAULT_NOTIFICATION_TIMEOUT_MS,
};

export const notificationSlice = createSlice({
  name: 'notificationService',
  initialState: () => notificationInitialState,
  reducers: {
    setNotice: (
      state,
      action: PayloadAction<Partial<ToastNotificationState>>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    reset: () => notificationInitialState,
  },
});
