/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  GridCellParams,
  GridRowSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import DataGrid from 'components/DataGrid';
import * as DepotViewStyles from 'pages/BuyAndHold/components/DepotView/styles';
import { useGetDepotTableColumns } from './hooks/useGetDepotTableColumns';
import * as DepotTableStyles from './styles';
import { DEPOT_TABLE_TITLE_ID, DEPOT_FLAG_MAPPING } from './constants';
import { DepotTableProps } from './types';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { SEARCH_DEPOT_SERVICES } from './queries';
import { OrderType } from 'global-constants';
import { AllwhereDepot, DepotService, Order } from 'types';
import { LoadingIndicator } from 'components';

const DepotTable = (props: DepotTableProps) => {
  const parentStyles = DepotViewStyles;
  const styles = DepotTableStyles;
  const [rowSelectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>(() => []);
  const [rowHoveringModel, setRowHoveringModel] = React.useState(() => '');

  const apiRef = useGridApiRef();
  const depotColumns = useGetDepotTableColumns(
    rowSelectionModel,
    rowHoveringModel
  );

  const { data: depotDepotServiceData, loading: depotsLoading } = useSafeQuery(
    SEARCH_DEPOT_SERVICES,
    {
      variables: {
        orderTypeName: OrderType.PROCUREMENT_TO_DEPOT,
        limit: 100,
      },
    }
  );

  const depotRows =
    depotDepotServiceData?.depotServices?.depotServices.map(
      (depotService: DepotService) => ({
        ...depotService.depot,
        selectable: true,
        flag: DEPOT_FLAG_MAPPING[depotService.depot.address?.country || 'NONE'],
      })
    ) ?? [];

  const onRowSelectionModelChange = (ids: GridRowSelectionModel) => {
    const isRowBeingUnSelected = ids.length === 0;
    if (isRowBeingUnSelected) {
      setSelectionModel([]);
      props.workflow.removeSelectedDepot();
    } else {
      const selectedDepotId = ids[ids.length - 1];
      setSelectionModel([selectedDepotId]);
      const selectedDepot = depotRows.find(
        (depot: AllwhereDepot) => depot.id === selectedDepotId
      );
      props.workflow.addSelectedDepot(selectedDepot);
    }
  };

  const handleOnCellClick = (params: GridCellParams) => {
    if (params.id === rowSelectionModel[0]) {
      onRowSelectionModelChange([]);
    } else {
      onRowSelectionModelChange([params.id]);
    }
  };

  const handleOnRowOver = (event: any) => {
    const rowId = event.currentTarget.getAttribute('data-id');
    setRowHoveringModel(rowId);
  };

  const handleOnRowLeave = () => {
    setRowHoveringModel('');
  };

  React.useEffect(() => {
    if (rowSelectionModel.length || !props.workflow.selectedDepotLocation)
      return;
    setSelectionModel([props.workflow.selectedDepotLocation?.id ?? '']);
  }, [rowSelectionModel.length, props.workflow.selectedDepotLocation]);

  return depotsLoading ? (
    <LoadingIndicator />
  ) : (
    <Stack sx={parentStyles.DepotViewSelectionStackSx}>
      <Stack sx={styles.DepotTableContentsSx}>
        <Typography id={DEPOT_TABLE_TITLE_ID}>
          Select depot to be sent to:
        </Typography>
        <Box sx={styles.DepotTableBoxSx}>
          <DataGrid
            apiRef={apiRef}
            checkboxSelection
            columns={depotColumns}
            dataGridSx={styles.DepotTableBoxSx}
            disableMultipleColumnsSorting
            disableMultipleRowSelection
            hideFooter
            onCellClick={handleOnCellClick}
            paginationMode="client"
            rowCount={depotRows.length}
            rows={depotRows}
            rowSelectionModel={rowSelectionModel}
            slotProps={{
              panel: {
                placement: 'bottom-end',
              },
              filterPanel: {
                sx: { visibility: 'hidden' },
              },
              row: {
                onMouseOver: handleOnRowOver,
                onMouseLeave: handleOnRowLeave,
              },
            }}
            sortingMode="client"
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default DepotTable;
