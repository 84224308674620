import { gql } from '@apollo/client';

export const DELETE_DEVICE = gql`
  mutation deleteAsset($id: String!) {
    deleteAsset(id: $id) {
      success
      message
    }
  }
`;
