import React from 'react';
import { StateKey } from 'store/shared/types';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import UseGetFilterDrawerConfig from 'components/complex_filter/ComplexFilterDrawer/types';
import { useGetFilterDrawerConfig as useGetFilterDrawerConfigBase } from 'components/complex_filter/hooks';
import {
  getOrderPurchaserQueryVariablesHOF,
  getRecipientQueryVariablesHOF,
  getOrderNumberQueryVariablesHOF,
  getAssetQueryVariablesHOF,
} from 'pages/Orders/filters/utils';

export const useGetFilterDrawerConfig = (
  filterSubTree: StateKey,
): UseGetFilterDrawerConfig | undefined => {
  const userOrg = useGetUserOrganization();
  const baseFilterDrawerConfig = useGetFilterDrawerConfigBase(filterSubTree);

  if (!baseFilterDrawerConfig) return undefined;

  // purchaser
  React.useEffect(() => {
    baseFilterDrawerConfig.filterModalConfig.typeAheadHandlers[
      'asset-number'
    ].getQueryVariables = getAssetQueryVariablesHOF('assetNumber', {
      searchBy: 'iLike',
      ...(userOrg?.id ? { organizationId: userOrg?.id } : {}),
    });

    baseFilterDrawerConfig.filterModalConfig.typeAheadHandlers[
      'order-number'
    ].getQueryVariables = getOrderNumberQueryVariablesHOF({
      ...(userOrg?.id ? { organizationId: userOrg?.id } : {}),
    });

    baseFilterDrawerConfig.filterModalConfig.typeAheadHandlers.purchaser.getQueryVariables =
      getOrderPurchaserQueryVariablesHOF({
        ...(userOrg?.id ? { organizationId: userOrg?.id } : {}),
      });

    baseFilterDrawerConfig.filterModalConfig.typeAheadHandlers.recipient.getQueryVariables =
      getRecipientQueryVariablesHOF(true, {
        ...(userOrg?.id ? { organizationId: userOrg?.id } : {}),
      });

    baseFilterDrawerConfig.filterModalConfig.typeAheadHandlers[
      'recipient-email'
    ].getQueryVariables = getRecipientQueryVariablesHOF(false, {
      ...(userOrg?.id ? { organizationId: userOrg?.id } : {}),
    });
  }, [userOrg?.id]);

  return {
    ...baseFilterDrawerConfig,
  } as UseGetFilterDrawerConfig;
};
