import { gql } from '@apollo/client';

export const SEARCH_DEPOT_SERVICES = gql`
  query depotServices(
    $depotId: ID!
    $assetStatus: AssetStatusEnum
    $assetTypeId: ID
  ) {
    depotServices(
      depotServicesSearchInput: {
        depotId: $depotId
        assetStatus: $assetStatus
        assetTypeId: $assetTypeId
      }
    ) {
      depotServices {
        id
        depot {
          id
          name
        }
        assetService {
          id
          orderTypeId
          orderType {
            id
            name
          }
          assetStatus
          assetTypeId
          assetType {
            id
            name
          }
        }
      }
      count
    }
  }
`;

export const SEARCH_ASSET_SERVICES = gql`
  query assetServices(
    $depotId: ID!
    $assetStatus: AssetStatusEnum
    $assetTypeId: ID
  ) {
    assetServices(
      assetServicesSearchInput: {
        depotId: $depotId
        assetStatus: $assetStatus
        assetTypeId: $assetTypeId
      }
    ) {
      assetServices {
        id
        orderTypeId
        orderType {
          id
          name
        }
        assetStatus
        assetTypeId
        assetType {
          id
          name
        }
      }
      count
    }
  }
`;
