import { RecipientAssetDetail } from './types';

export const ASSET_DETAILS_TO_CLEAR = ['SEE NOTES'];

export const ASSET_DETAILS_MAPPER: RecipientAssetDetail[] = [
  { header: 'DATE ISSUED', assetField: 'dateIssued', bottom: false },
  { header: 'SERIAL NUMBER', assetField: 'serialNumber', bottom: false },
  { header: 'ASSET ID', assetField: 'id', bottom: false },
  { header: 'DISPLAY SIZE', assetField: 'displaySize', bottom: true },
  { header: 'MODEL NUMBER', assetField: 'modelNumber', bottom: true },
  { header: 'COLOR', assetField: 'color', bottom: true },
];

export enum AssetType {
  LAPTOP = 'LAPTOP',
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
}
