import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, Stack, Typography } from '@mui/material';
import * as BuyAndHoldStyles from 'pages/BuyAndHold/styles';
import { procurementToDepotSlice } from 'store/slices';
import allwhereBoxRunning from 'assets/allwhereBoxRunning.png';
import { Button } from 'components';
import { NavigationContext } from 'context/NavigationContext';
import { ProcurementFlowStep } from 'pages/Procurement/hooks/useProcurementService/constants';
import {
  ORDER_SUBMITTED_SUBTITLE,
  ORDER_SUBMITTED_TITLE,
  QUOTE_REQUESTED_SUBTITLE,
  QUOTE_REQUESTED_TITLE,
} from './constants';
import { OrderSubmittedProps } from './types';

export const OrderSubmitted = (props: OrderSubmittedProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigator = React.useContext(NavigationContext);
  const { quoteRequested } = props.workflow;

  React.useEffect(() => {
    // Resetting count total (sidebar) on component mount
    dispatch(procurementToDepotSlice.actions.setCartTotal(0));
    return () => {
      // Removing local storage on component unmount
      localStorage.removeItem('buy_and_hold');
    };
  }, []);

  return (
    <Container maxWidth="md" sx={{ ...BuyAndHoldStyles.MainContainerSx }}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={BuyAndHoldStyles.getContentStackSx('0', {
          rowGap: '24px',
        })}
      >
        <img
          src={allwhereBoxRunning}
          alt="allwhere=box-running"
          height="190px"
          width="204px"
        />
        <Stack alignItems="center" justifyContent="center">
          <Typography
            variant="h3"
            sx={{ fontSize: '32px', fontWeight: 500, lineHeight: 'normal' }}
          >
            {quoteRequested ? QUOTE_REQUESTED_TITLE : ORDER_SUBMITTED_TITLE}
          </Typography>
          <Typography variant="body1">
            {quoteRequested
              ? QUOTE_REQUESTED_SUBTITLE
              : ORDER_SUBMITTED_SUBTITLE}
          </Typography>
        </Stack>
        <Button
          gaContext={{
            textCopy: 'Go to Orders',
            navigates_to: 'Orders',
            purpose: 'Navigates To Orders',
          }}
          variant="contained"
          onClick={() => {
            props.workflow.updateProcurementFlowStep(
              ProcurementFlowStep.COLLABORATOR_VIEW
            );
            navigate(navigator.pathToAllOrders());
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '24px',
            }}
          >
            Go to Orders
          </Typography>
        </Button>
      </Stack>
    </Container>
  );
};
