import { UseProcurementService } from 'pages/Procurement/hooks/useProcurementService/types';
import { ProductCategoryDto } from 'services/openapi/apiSchemas';

export const isThereRetrievalProductInCart = (
  cart: UseProcurementService['cart'],
  retrievalCategory: ProductCategoryDto | undefined
) => {
  const retrievalCategoryId = retrievalCategory?.id;

  return cart.some(
    (cartItem) =>
      cartItem.productCategoryId &&
      cartItem?.productCategoryId === retrievalCategoryId
  );
};
