import React from 'react';
import { Chip } from '@mui/material';
import Bubble from './components/Bubble';
import Person from './components/Person';
import styles from './PeopleBar.module.css';
import { PeopleBarProps } from './types';
import { ProcurementFlowModals } from 'pages/Procurement/hooks/useProcurementService/constants';

const PeopleBar = (props: PeopleBarProps) => {
  const firstThreeCollaborators = props.selectedCollaborators.slice(0, 3);
  const restOfCollaborators = props.selectedCollaborators.slice(3);

  const onClickShowModal = () => {
    props.setCurrentModal(ProcurementFlowModals.SELECTED_COLLABORATORS_EDIT);
  };

  return (
    <div className={styles['people-bar']}>
      <div className={styles['people-container']}>
        <Bubble text="To" />
        {firstThreeCollaborators.map((selectedCollaborator) => (
          <Person
            key={selectedCollaborator.email}
            collaborator={selectedCollaborator}
            removeSelectedCollaborator={props.removeSelectedCollaborator}
          />
        ))}
        {restOfCollaborators.length > 0 && (
          <Chip
            sx={{ cursor: 'pointer' }}
            label={`+${restOfCollaborators.length} More...`}
            onClick={() => onClickShowModal()}
          />
        )}
      </div>
    </div>
  );
};

export default PeopleBar;
