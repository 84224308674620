import {
  INPUT,
  DeviceFilters,
  DEVICE_FILTER,
} from 'pages/Assets/components/DevicesDatagrid/components/DeviceFilters/constants';

export const TYPE = 'Type';

export const DeviceTypeFilterSettings = {
  placeholder: TYPE,
  label: TYPE,
  labelId: `${DEVICE_FILTER}type-${INPUT}-label`,
  inputId: `${DEVICE_FILTER}type-${INPUT}`,
  searchAssetsQueryField: DeviceFilters.ASSET_TYPE_NAME,
};
