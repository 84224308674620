/* eslint-disable sort-exports/sort-exports */
import ComplexFilterDrawer from 'components/complex_filter/ComplexFilterDrawer';
import { useGetFilterDrawerConfig } from './hooks';

const AssetComplexFilterDrawer = () => {
  const filterDrawerConfig = useGetFilterDrawerConfig('assets');

  if (!filterDrawerConfig) return <></>;

  return <ComplexFilterDrawer {...filterDrawerConfig} />;
};

export default AssetComplexFilterDrawer;
