import theme from 'themes/theme';
import { SxProps } from '@mui/material';
import {
  AMDashComplexFilterTestIds,
  ASM_DASH_DRAWER_INPUT_BOX_CLASS,
  ASM_DASH_DRAWER_INPUT_HEIGHT,
  ASM_DASH_DRAWER_INPUT_WIDTH,
  ASM_DASH_DRAWER_CLEAR_ALL_BUTTON_ID,
  ASM_DASH_DRAWER_FILTER_BY_CLOSE_ICON_ID,
} from './constants';

export const getFilterDrawerDialogSx = (options: {
  top: number;
  left: number;
}): SxProps => ({
  width: '500px',
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent',
  },
  '& .MuiPaper-root.MuiDialog-paper': {
    borderRadius: '10px',
    top: `calc(${options.top}px + 10px)`,
    position: 'absolute',
    transformOrigin: 'top right',
    left: `calc(${options.left}px - 440px)`,
  },
});

export const FilterDrawerDialogSx: SxProps = {
  left: 'unset',
  right: '8.5vw',
  top: '220px',
  width: '500px',
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent',
  },
  '& .MuiPaper-root.MuiDialog-paper': {
    borderRadius: '10px',
    position: 'absolute',
    transformOrigin: 'top right',
  },
};

export const FilterTitleSx: SxProps = {
  fontFamily: 'PPPangramSans',
  fontSize: '16px',
  fontWeight: 700,
  px: '32px',
  py: '20px',
};

export const getContentBoxStacksSx = (hasFilters: boolean): SxProps => ({
  height: '620px',
  maxHeight: hasFilters ? '390px' : '440px',
  overflowY: 'scroll',
  position: 'relative',
  px: '32px',
  py: '16px',
  width: '500px',
  '::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    width: '4px',
    height: '5px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '11px',
    backgroundColor: 'rgba(0,0,0,.5)',
    WebkitBoxShadow: '0 0 1px rgba(255,255,255,.5)',
  },
});

export const AMDashComplexFiltersContainerBoxSx: SxProps = {
  bottom: '10px',
  height: '520px',
  position: 'absolute',
  right: 0,
  width: '620px',
};

export const ComplexFilterTopSectionSx: SxProps = {
  borderBottom: `1px solid ${theme.palette.colors.transparentGrey}`,
  [`& #${ASM_DASH_DRAWER_FILTER_BY_CLOSE_ICON_ID}`]: {
    color: 'colors.woodSmoke',
    mr: '32px',
  },
};

export const AMComplexFilterSectionTitleSx: SxProps = {
  color: theme.palette.colors.woodSmoke,
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '157%',
  pb: '8px',
};

export const AMComplexFilterStackSx: SxProps = {
  boxSizing: 'border-box',
  pb: '16px',
  [`&[data-testid="${AMDashComplexFilterTestIds.ALLWHERE_INFO}"]`]: {
    height: '125px',
    mb: '16px',
  },
  [`&[data-testid="${AMDashComplexFilterTestIds.ASSIGNEE_INFO}"]`]: {
    height: '75px',
    mb: '16px',
  },
  [`&[data-testid="${AMDashComplexFilterTestIds.DEVICE_INFO}"]`]: {
    height: '280px',
  },
  [`& .${ASM_DASH_DRAWER_INPUT_BOX_CLASS}`]: {
    height: ASM_DASH_DRAWER_INPUT_HEIGHT,
    maxHeight: ASM_DASH_DRAWER_INPUT_HEIGHT,
    maxWidth: ASM_DASH_DRAWER_INPUT_WIDTH,
    position: 'relative',
    width: ASM_DASH_DRAWER_INPUT_WIDTH,
    '& li.MuiAutocomplete-option.Mui-focused': {
      background: 'rgba(0,0,0,.12)',
    },
  },
};

export const ComplexFilterBottomSectionSx: SxProps = {
  borderTop: `1px solid ${theme.palette.colors.transparentGrey}`,
  boxShadow: 'none',
  boxSizing: 'border-box',
  minHeight: '52px',
  px: '32px',
  [`& button#${ASM_DASH_DRAWER_CLEAR_ALL_BUTTON_ID}`]: {
    backgroundColor: 'transparent',
    borderRadius: '10px',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    px: '12px',
    '&:hover': {
      backgroundColor: theme.palette.colors.transparentGrey,
    },
  },
};

export const AutoCompleteSx: SxProps = {
  p: 0,
  backgroundColor: 'transparent',
  border: 'none',
  height: ASM_DASH_DRAWER_INPUT_HEIGHT,
  position: 'relative',

  // remove the inner-chips w/ delete buttons
  '& .MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-filledDefault.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium':
    {
      display: 'none',
    },

  // controls outline color when focused, title/label text color, & border color when active
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, & .Mui-focused.MuiFormLabel-root, & .Mui-focused.MuiInputLabel-root':
    {
      color: theme.palette.colors.woodSmoke,
      borderColor: theme.palette.colors.woodSmoke,
    },

  '& .MuiInputBase-root': {
    backgroundColor: 'transparent',
  },

  '& .MuiFormControl-root': {
    backgroundColor: 'transparent',
    pl: 0,

    '& .MuiFormLabel-root.MuiInputLabel-root': {
      top: '-8px',
      '&.MuiInputLabel-shrink': {
        top: '0px',
      },
    },

    '& .MuiOutlinedInput-input': {},
  },

  '& .MuiInputBase-formControl': {
    backgroundColor: 'transparent',
    height: ASM_DASH_DRAWER_INPUT_HEIGHT,
    py: 0,
  },
};

export const AutoCompleteSearchTextFieldSx: SxProps = {
  fontSize: '14px',
  p: '0',
  pl: 3,
  borderRadius: 0,
  textOverflow: 'ellipsis',
};

export const AutoCompleteGlobalCopySx: SxProps = {
  fontSize: '14px',
  lineHeight: '20px',
};
