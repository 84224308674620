import React from 'react';
import Stack from '@mui/material/Stack';

import UnlockDeviceForm from '../UnlockDeviceForm';
import TotalSummary from '../TotalSummary';
import DeviceInfo from '../DeviceInfo';
import { getDeviceMap } from './utils';

import { UnlockDeviceProps } from './types';

import {
  AssetContainerSx,
  AssetSx,
  AssetRowSx,
  DeviceInfoColumnSx,
} from './styles';

const UnlockDevice = (props: UnlockDeviceProps) => {
  const { device, onSubmit, form, loading } = props;

  const deviceMap = getDeviceMap(device);

  return (
    <Stack>
      <Stack
        className="unlock-device-stack"
        direction="row"
        justifyContent="flex-start"
      >
        <Stack direction="column" sx={AssetContainerSx}>
          <Stack sx={AssetSx}>Asset</Stack>
          <Stack direction="row" sx={AssetRowSx}>
            {deviceMap.map((info, index) => (
              <Stack
                key={index}
                direction="column"
                sx={DeviceInfoColumnSx}
                spacing={2}
              >
                {info.map((item) => (
                  <DeviceInfo {...item} />
                ))}
              </Stack>
            ))}
          </Stack>
        </Stack>
        <TotalSummary onSubmit={onSubmit} form={form} loading={loading} />
      </Stack>
      <UnlockDeviceForm device={device} form={form} loading={loading} />
    </Stack>
  );
};

export default UnlockDevice;
