/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDebouncedQuery from 'hooks/useDebouncedSearch';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import GenericAutocomplete from 'components/GenericAutocomplete';
import {
  DevicesSearchBy,
  DEVICES_SEARCH_BY_DEFAULT,
  DEVICES_SEARCH_TERM_TYPES,
  DEVICES_OPTIONS_TABLE,
  SEARCH_BAR_WHOLE_WIDTH,
  DEVICES_SEARCH_BAR_CLEAR_INDICATOR_ID,
  GetDevicesAutocompleteByQuery,
} from 'pages/Assets/components/DevicesDatagrid/constants';
import { assetFilterActions } from 'store/slices/assets/filter';
import {
  GET_ASSETS,
  SEARCH_COLLABORATORS,
} from 'pages/Assets/components/DevicesDatagrid/queries';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import { selectIsFiltered } from 'store/shared/selectors';
import {
  setSearchQueryVariable,
  setClearFilters,
  resetComboBoxFilter,
  setComplexEntry,
} from 'store/slices/assets/comboBoxFilter';
import * as SearchComboBoxStyles from './styles';
import { Collaborator } from 'types';
import SearchComboBoxProps from './types';

const SearchComboBox = (props: SearchComboBoxProps) => {
  const dispatch = useDispatch();
  const styles = SearchComboBoxStyles;
  const isFiltered = useSelector(selectIsFiltered('assets'));

  const [searchBy, setSearchBy] = React.useState<DevicesSearchBy>(
    DEVICES_SEARCH_BY_DEFAULT
  );
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [searchOptionsLookup, setSearchOptionsLookup] = React.useState<Record<
    string,
    string
  > | null>(null);
  const [searchOptions, setSearchOptions] = React.useState<string[]>([]);
  const debouncedSearchTerm = useDebouncedQuery(searchTerm, 300);

  const shouldFetchAssets =
    DEVICES_OPTIONS_TABLE[searchBy].searchQueryName ===
    GetDevicesAutocompleteByQuery.SEARCH_ASSETS;

  const shouldFetchCollaborators =
    DEVICES_OPTIONS_TABLE[searchBy].searchQueryName ===
    GetDevicesAutocompleteByQuery.COLLABORATORS;

  const clearAllAutoCompleteOptions = () => {
    setSearchOptions([]);
    setSearchTerm('');
    setSearchOptionsLookup(null);
    dispatch(resetComboBoxFilter());
  };

  const handleSearchByChange = (event: any) => {
    const clearTextIconButton: HTMLElement = document.getElementById(
      DEVICES_SEARCH_BAR_CLEAR_INDICATOR_ID
    ) as HTMLElement;
    if (clearTextIconButton) {
      clearTextIconButton.click();
    }
    clearAllAutoCompleteOptions();
    const searchByValue = event.target.value as DevicesSearchBy;
    setSearchBy(searchByValue);
  };

  const [fetchAssetsOptions, { loading: isFetchingAssetOptions }] =
    useSafeLazyQuery(GET_ASSETS, {
      onCompleted(data) {
        const { searchAssets } = data;
        const assets = searchAssets?.assets || [];
        if (!assets.length) return;
        const optionsValueLookup = assets.reduce(
          (acc: Record<string, string>, asset: any) => {
            const optionRawValue =
              asset[DEVICES_OPTIONS_TABLE[searchBy].searchQueryField];
            const optionName = `${DEVICES_OPTIONS_TABLE[searchBy].optionPrefix}${optionRawValue}`;
            acc[optionName] = optionRawValue;
            return acc;
          },
          {}
        );
        setSearchOptionsLookup(optionsValueLookup);
        setSearchOptions(() => Object.keys(optionsValueLookup));
      },
    });

  const [
    fetchCollaborators,
    { loading: isFetchingCollaboratorOptions, data: collaboratorData },
  ] = useSafeLazyQuery(SEARCH_COLLABORATORS, {
    onCompleted(data) {
      const { collaborators: fetchCollaborators } = data;
      const collaborators = fetchCollaborators?.collaborators || [];
      if (!collaborators.length) return;
      const optionsValueLookup = collaborators.reduce(
        (acc: Record<string, string>, collaborator: any) => {
          const optionRawValue = collaborator.email;
          const optionName = `${DEVICES_OPTIONS_TABLE[searchBy].optionPrefix}${collaborator.firstName} ${collaborator.lastName}`;
          acc[optionName] = optionRawValue;
          return acc;
        },
        {}
      );

      setSearchOptionsLookup(optionsValueLookup);
      setSearchOptions(() => Object.keys(optionsValueLookup));
    },
  });

  const collaboratorLookupMemo: Record<string, Collaborator> =
    React.useMemo(() => {
      const collaborators =
        collaboratorData?.collaborators?.collaborators ?? [];
      if (!collaborators.length) return {};

      return Object.fromEntries(
        collaborators.map((collaborator: any) => [
          collaborator.email,
          collaborator,
        ])
      );
    }, [isFetchingCollaboratorOptions, collaboratorData]);

  const isLoadingAutocompleteOptions =
    isFetchingAssetOptions || isFetchingCollaboratorOptions;

  const handleAutoCompleteChange = (event: any, value: any) => {
    if (value === null) {
      clearAllAutoCompleteOptions();
      return;
    }

    const queryFieldValue = searchOptionsLookup?.[value] ?? '';
    if (!queryFieldValue) return;
    dispatch(setClearFilters(true));

    let complexSearchTerm = '';
    let complexSearchValues: string[] = [];

    if (searchBy === DevicesSearchBy.ASSIGNEE_NAME) {
      const assigneeId = collaboratorLookupMemo[queryFieldValue].id;
      complexSearchTerm = 'assigneeId';
      complexSearchValues = [assigneeId];
    }

    if (searchBy === DevicesSearchBy.ASSET_ID) {
      complexSearchTerm = 'assetNumber';
      complexSearchValues = [queryFieldValue];
    }

    if (searchBy === DevicesSearchBy.SERIAL_NO) {
      complexSearchTerm = 'serialNumber';
      complexSearchValues = [queryFieldValue];
    }

    dispatch(
      setComplexEntry({
        complexSearchTerm,
        complexSearchValues,
      })
    );
    dispatch(
      setSearchQueryVariable({
        [DEVICES_OPTIONS_TABLE[searchBy].searchAssetsQueryField]:
          queryFieldValue,
      })
    );

    dispatch(
      assetFilterActions.resetFilterStateReducer({ closeFilterDrawer: true })
    );
    props.resetPageState();
  };

  const fetchOptionsData = (query: Function) => {
    query({
      variables: {
        organizationId: props.organizationId,
        limit: 25,
        offset: shouldFetchCollaborators ? 0 : '0',
        ...(shouldFetchAssets && {
          orderAsc: DEVICES_OPTIONS_TABLE[searchBy].searchAssetsQueryField,
        }),
        ...(debouncedSearchTerm
          ? {
              [DEVICES_OPTIONS_TABLE[searchBy].searchQueryField]:
                debouncedSearchTerm,
            }
          : {}),
      },
      fetchPolicy: 'network-only',
    });
  };

  React.useEffect(() => {
    // regex to check if search term is a selection/option
    if (!debouncedSearchTerm || /:\s/.test(debouncedSearchTerm)) return;
    let passedQuery: null | Function = null;
    if (shouldFetchAssets) {
      passedQuery = fetchAssetsOptions;
    }

    if (shouldFetchCollaborators) {
      passedQuery = fetchCollaborators;
    }

    if (!passedQuery) return;
    fetchOptionsData(passedQuery);
  }, [debouncedSearchTerm, shouldFetchAssets, shouldFetchCollaborators]);

  React.useEffect(() => {
    if (!isFiltered) return;
    clearAllAutoCompleteOptions();
  }, [isFiltered]);

  return (
    <Stack
      direction="row"
      sx={styles.AutoCompleteStackSx}
      divider={<Divider orientation="vertical" variant="middle" flexItem />}
    >
      <SearchIcon sx={styles.AutoCompleteSearchIconSx} />
      <GenericAutocomplete
        loading={isLoadingAutocompleteOptions}
        onChange={handleAutoCompleteChange}
        onInputChange={(_e, value) => setSearchTerm(value)}
        componentsProps={{
          popper: {
            style: {
              width: SEARCH_BAR_WHOLE_WIDTH,
            },
          },
          // STYLING - for options dropdown below
          paper: {
            style: styles.AutocompletePaperCSS,
          },
          clearIndicator: {
            id: DEVICES_SEARCH_BAR_CLEAR_INDICATOR_ID,
            sx: styles.getClearIndicatorSx(searchTerm),
          },
        }}
        renderInput={(params) => {
          const {
            inputProps: { value, ...restInputProps },
            ...restParams
          } = params;
          const restOfParams = { ...restParams, inputProps: restInputProps };
          return (
            <TextField
              {...restOfParams}
              value={searchTerm}
              placeholder={DEVICES_OPTIONS_TABLE[searchBy].textPlaceholder}
              sx={styles.AutoCompleteSearchTextFieldSx}
            />
          );
        }}
        options={searchOptions}
        sx={styles.AutoCompleteSx}
      />
      <Select
        sx={styles.AutoCompleteSelectSx}
        value={searchBy}
        onChange={handleSearchByChange}
        MenuProps={{
          sx: styles.AutoCompleteSelectMenuSx,
        }}
        SelectDisplayProps={{
          style: styles.SelectDisplayCSS,
        }}
      >
        {DEVICES_SEARCH_TERM_TYPES.map((searchTermType: string) => (
          <MenuItem value={searchTermType}>
            {
              DEVICES_OPTIONS_TABLE[
                searchTermType as keyof typeof DEVICES_OPTIONS_TABLE
              ].selectName
            }
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

export default SearchComboBox;
