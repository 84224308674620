import { SxProps } from '@mui/material';

export const DisclaimerSummaryTextSx: SxProps = {
  fontWeight: '400',
  fontSize: '18px',
  lineHeight: '20.52px',
};

export const DisclaimerContentHeaderTextSx: SxProps = {
  fontSize: '18px',
  fontWeight: '600',
  lineHeight: '22px',
  letterSpacing: '0em',
  textAlign: 'left',
};

export const DisclaimerContentBodyTextSx: SxProps = {
  fontSize: '18px',
  fontWeight: '400',
  lineHeight: '22px',
  letterSpacing: '0em',
  textAlign: 'left',
};
