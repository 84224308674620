import React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NavigationContext } from 'context/NavigationContext';
import { BackendOperations, UserPermissions } from 'global-constants';
import useGetUserPermissions from 'hooks/useGetUserPermissions';
import { GlobalModals } from 'store/constants';
import { modalSlice } from 'store/slices';
import {
  ProcessDeviceSVGIcon,
  RepairDeviceSVGIcon,
  ResellDeviceSVGIcon,
  AllocateDeviceSVGIcon,
  RecycleDeviceSVGIcon,
} from 'components/Icons';
import { AssetActionsProps } from './types';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import { FeatureFlagNames } from 'enums';

import {
  ActionIconButtonSx,
  ButtonGroupSx,
  RecycleActionIconButtonSx,
} from './styles';
import useGetGlobalPurchaser from 'hooks/useGetGlobalPurchaser';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import GAIconButton from 'components/buttons/google_analytics/GAIconButton';

const AssetActions = (props: AssetActionsProps) => {
  const userPermissions = useGetUserPermissions();
  const purchaser = useGetGlobalPurchaser();
  const organization = useGetUserOrganization();

  const { OnFlagTrueWrapper: OnDeviceUnlockOnWrapper } = useFeatureFlagService(
    FeatureFlagNames.RELEASE_DEVICE_UNLOCK,
    {
      debugFlags: true,
    }
  );

  // Or create a new permission for this?
  const isUserInternal = userPermissions.includes(
    UserPermissions.ORDER__MANAGEMENT__VIEW
  );

  //Navigation
  const navigate = useNavigate();
  const navigator = React.useContext(NavigationContext);

  const theme = useTheme();
  const dispatch = useDispatch();

  const handleCreateDeviceOnClick = () => {
    dispatch(modalSlice.actions.setCurrentModal(GlobalModals.ADD_DEVICE));
  };

  const handleEditDeviceOnClick = () => {
    dispatch(modalSlice.actions.setCurrentModal(GlobalModals.EDIT_DEVICE));
  };

  const handleProcessDeviceOnClick = () => {
    dispatch(modalSlice.actions.setCurrentModal(GlobalModals.PROCESS_DEVICE));
  };

  const handleRecycleDeviceOnClick = () => {
    dispatch(modalSlice.actions.setCurrentModal(GlobalModals.RECYCLE_DEVICE));
  };

  const handleRepairDeviceOnClick = () => {
    dispatch(modalSlice.actions.setCurrentModal(GlobalModals.REPAIR_DEVICE));
  };

  const handleUnlockDeviceOnClick = () => {
    dispatch(modalSlice.actions.setCurrentModal(GlobalModals.UNLOCK_DEVICE));
  };

  const handleResellDeviceOnClick = () => {
    dispatch(modalSlice.actions.setCurrentModal(GlobalModals.RESELL_DEVICE));
  };

  const handleDeleteDeviceOnClick = () => {
    dispatch(modalSlice.actions.setCurrentModal(GlobalModals.DELETE_DEVICE));
  };

  const handleAssignDeviceOnClick = () => {
    navigate(navigator.pathToAssignDeviceFlow(), {
      state: {
        device: props.device,
      },
    });
  };

  return (
    <ButtonGroup sx={ButtonGroupSx}>
      {isUserInternal ? (
        <Tooltip title="Create Device">
          <span>
            <GAIconButton
              gaContext={{
                navigates_to: 'N/A',
                textCopy: 'Plus icon',
                purpose: 'Opens Create Device Modal',
              }}
              size="large"
              disableRipple
              color="secondary"
              sx={ActionIconButtonSx}
              onClick={handleCreateDeviceOnClick}
              disabled={!!props.device}
            >
              <AddIcon />
            </GAIconButton>
          </span>
        </Tooltip>
      ) : null}
      {isUserInternal ? (
        <Tooltip title="Edit Device">
          <span>
            <GAIconButton
              gaContext={{
                navigates_to: 'N/A',
                textCopy: 'Edit icon',
                purpose: 'Opens Edit Device Modal',
              }}
              size="large"
              disableRipple
              color="secondary"
              sx={ActionIconButtonSx}
              onClick={handleEditDeviceOnClick}
              disabled={!props.enableEditDeviceAction}
            >
              <EditOutlinedIcon />
            </GAIconButton>
          </span>
        </Tooltip>
      ) : null}
      <Tooltip title="Process Device">
        <span>
          <GAIconButton
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Settings icon',
              purpose: 'Opens Process Device Modal',
            }}
            size="large"
            disableRipple
            color="secondary"
            sx={ActionIconButtonSx}
            onClick={handleProcessDeviceOnClick}
            disabled={!props.enableProcessDeviceAction}
          >
            <ProcessDeviceSVGIcon
              fill={theme.palette.colors.black}
              stroke={theme.palette.colors.black}
            />
          </GAIconButton>
        </span>
      </Tooltip>
      <Tooltip title="Repair Device">
        <span>
          <GAIconButton
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Repair icon',
              purpose: 'Opens Repair Device Modal',
            }}
            size="large"
            disableRipple
            color="secondary"
            sx={ActionIconButtonSx}
            onClick={handleRepairDeviceOnClick}
            disabled={!props.enableRepairDeviceAction}
          >
            <RepairDeviceSVGIcon
              fill={theme.palette.colors.black}
              stroke={theme.palette.colors.black}
            />
          </GAIconButton>
        </span>
      </Tooltip>
      <OnDeviceUnlockOnWrapper>
        <Tooltip title="Unlock Device">
          <span>
            <GAIconButton
              gaContext={{
                navigates_to: 'N/A',
                textCopy: 'Unlock icon',
                purpose: 'Opens Unlock Device Modal',
              }}
              size="large"
              disableRipple
              color="secondary"
              sx={ActionIconButtonSx}
              onClick={handleUnlockDeviceOnClick}
              disabled={
                !props.enableUnlockDeviceAction || !purchaser || !organization
              }
            >
              <LockOpenOutlinedIcon />
            </GAIconButton>
          </span>
        </Tooltip>
      </OnDeviceUnlockOnWrapper>
      <Tooltip title="Recycle Device">
        <span>
          <GAIconButton
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Recycle icon',
              purpose: 'Opens Recycle Device Modal',
            }}
            size="large"
            disableRipple
            color="secondary"
            sx={RecycleActionIconButtonSx}
            onClick={handleRecycleDeviceOnClick}
            disabled={!props.enableRecycleDeviceAction}
          >
            <RecycleDeviceSVGIcon
              fill={theme.palette.colors.black}
              stroke={theme.palette.colors.black}
            />
          </GAIconButton>
        </span>
      </Tooltip>
      <Tooltip title="Resell Device">
        <span>
          <GAIconButton
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Resell icon',
              purpose: 'Opens Resell Device Modal',
            }}
            size="large"
            disableRipple
            color="secondary"
            sx={ActionIconButtonSx}
            onClick={handleResellDeviceOnClick}
            disabled={!props.enableResellDeviceAction}
          >
            <ResellDeviceSVGIcon
              fill={theme.palette.colors.black}
              stroke={theme.palette.colors.black}
            />
          </GAIconButton>
        </span>
      </Tooltip>
      <Tooltip title="Assign Device">
        <span>
          <GAIconButton
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Assign icon',
              purpose: 'Opens Assign Device Modal',
            }}
            size="large"
            disableRipple
            color="secondary"
            sx={ActionIconButtonSx}
            onClick={handleAssignDeviceOnClick}
            disabled={!props.enableAllocateDeviceAction}
          >
            <AllocateDeviceSVGIcon stroke={theme.palette.colors.black} />
          </GAIconButton>
        </span>
      </Tooltip>
      {isUserInternal ? (
        <Tooltip title="Delete Device">
          <span>
            <GAIconButton
              gaContext={{
                navigates_to: 'N/A',
                textCopy: 'Delete icon',
                purpose: 'Opens Delete Device Modal',
              }}
              size="large"
              disableRipple
              color="secondary"
              sx={ActionIconButtonSx}
              onClick={handleDeleteDeviceOnClick}
              disabled={!props.enableDeleteDeviceAction}
            >
              <DeleteIcon fill={theme.palette.colors.black} />
            </GAIconButton>
          </span>
        </Tooltip>
      ) : null}
    </ButtonGroup>
  );
};

export default AssetActions;
