import React from 'react';

const DownloadIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.40625 15C1.03125 15 0.703125 14.8594 0.421875 14.5781C0.140625 14.2969 0 13.9688 0 13.5938V10.2422H1.40625V13.5938H13.5938V10.2422H15V13.5938C15 13.9688 14.8594 14.2969 14.5781 14.5781C14.2969 14.8594 13.9688 15 13.5938 15H1.40625ZM7.5 11.4141L2.97656 6.89062L3.98438 5.88281L6.79688 8.69531V0H8.20312V8.69531L11.0156 5.88281L12.0234 6.89062L7.5 11.4141Z"
        fill="black"
      />
    </svg>
  );
};

export default DownloadIcon;
