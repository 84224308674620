import React from 'react';
import { SxProps } from '@mui/material/styles';
import theme from 'themes/theme';

export const getHomePageStatCardGridContainerSx = (
  bgColor: string,
  iconFill: string
): SxProps => ({
  backgroundColor: bgColor,
  borderRadius: '10px',
  boxSizing: 'border-box',
  height: '82px',
  width: 'calc(33.33% - 8px)',
  p: '8px',
  pb: 0,
  '& .MuiSvgIcon-root:not([data-testid="InfoOutlinedIcon"])': {
    color: iconFill,
  },
  '&#assets-ready-for-deployment-stat-card': {
    '@media (max-width: 1486px)': {
      height: '102px',
    },
    '@media (max-width: 1200px)': {
      height: '82px',
    },
  },
});

export const HomePageStatCardTitleSx: SxProps = {
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
};

export const HomePageStatCardIconBoxSx: SxProps = {
  position: 'relative',
  bottom: '4px',

  '& .MuiSvgIcon-root': {
    position: 'relative',
    top: '7px',
  },

  '& span.display-stat': {
    position: 'relative',
    color: theme.palette.colors.woodSmoke,
    fontSize: '32px',
    fontWeight: 500,
  },
};

export const InfoSpanStyle: React.CSSProperties = {
  paddingLeft: '5px',
  position: 'relative',
  top: '4px',
  width: '8px',
};

export const InfoIconSx: SxProps = {
  color: theme.palette.colors.gray70,
};

export const HomePageStatSkeletonSx: SxProps = {
  width: '2rem',
  height: '2rem',
  position: 'absolute',
  top: '10px',
  left: '55px',
  bgColor: theme.palette.colors.transparentGrey,
};
