import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const AssetFilterClearButtonSx: SxProps = {
  backgroundColor: `${theme.palette.colors.grayF6} !important`,
  height: '24px',
  position: 'absolute',
  right: '30px',
  transformOrigin: 'top right',
  top: '5px',
  width: '24px',
  zIndex: 1,
  '&:hover': {
    backgroundColor: 'inherit',
  },
};

export const AssetFilterTitleSx: SxProps = {
  color: theme.palette.colors.gray60,
  fontSize: '12px',
  lineHeight: 1,
};

export const DeviceFilterClearButtonSx: SxProps = {
  pr: 0,
  py: 0,
  color: theme.palette.colors.offBlack,
  '&:hover': {
    opacity: 0.6,
  },
  '& .MuiTypography-root': {
    fontSize: '14px',
    fontWeight: 600,
  },
  '& .MuiButton-startIcon': {
    mr: '4px',
  },
};
