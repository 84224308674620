import { SxProps } from '@mui/material';
import theme from 'themes/theme';
import { DEFAULT_FILTER_HEIGHT, DEFAULT_FILTER_WIDTH } from './constants';

export const FilterTitleSx: SxProps = {
  color: theme.palette.colors.gray60,
  fontSize: '12px',
  lineHeight: 1,
};

export const FilterStackSx: SxProps = {
  height: '20px',
  position: 'relative',
};

export const OrderStatusFilterStackSx: SxProps = {
  ...FilterStackSx,
  '& .MuiInputBase-root': {
    width: '300px',
  },
};

export const OrderStatusFilterClearButtonSx: SxProps = {
  backgroundColor: `${theme.palette.colors.grayF6} !important`,
  height: '24px',
  position: 'absolute',
  right: '30px',
  transformOrigin: 'top right',
  top: '5px',
  width: '24px',
  zIndex: 1,
  '&:hover': {
    backgroundColor: 'inherit',
  },
};

export const FilterClearButtonSx: SxProps = {
  pr: 0,
  py: 0,
  color: theme.palette.colors.offBlack,
  '&:hover': {
    opacity: 0.6,
  },
  '& .MuiTypography-root': {
    fontSize: '14px',
    fontWeight: 600,
  },
  '& .MuiButton-startIcon': {
    mr: '4px',
  },
};

export const AutoCompleteStackSx: SxProps = {
  width: DEFAULT_FILTER_WIDTH,
  height: DEFAULT_FILTER_HEIGHT,
  position: 'relative',

  '&#order-management-filters-organization-input .MuiInputBase-root': {
    maxHeight: '35px',
  },
};

export const AutoCompleteSearchTextFieldSx: SxProps = {
  p: '0',
  pl: 3,
  borderRadius: 0,
};

export const AutoCompleteSx: SxProps = {
  p: 0,
  backgroundColor: 'transparent',
  border: 'none',
  height: DEFAULT_FILTER_HEIGHT,
  position: 'relative',

  '& .MuiInputBase-root': {
    backgroundColor: 'transparent',
  },

  '& .MuiFormControl-root': {
    backgroundColor: 'transparent',
    pl: 0,

    '& .MuiFormLabel-root.MuiInputLabel-root': {
      top: '-10px',
      '&.MuiInputLabel-shrink': {
        top: '0px',
      },
    },

    '& .MuiOutlinedInput-input': {},
  },

  '& .MuiInputBase-formControl': {
    backgroundColor: 'transparent',
    height: DEFAULT_FILTER_HEIGHT,
    py: 0,
  },
};

export const getFilterFormControlSx = (inputLabelId: string): SxProps => ({
  [`& #${inputLabelId}`]: {
    top: '-10px',
    '&.MuiInputLabel-shrink': {
      top: 0,
    },
  },
  '& .Mui-disabled.MuiInputBase-formControl': {
    background: 'rgba(0, 0, 0, 0.06)',
    cursor: 'not-allowed',
  },
});

export const FilterSelectSx: SxProps = {
  width: '165px',
  '& .MuiSelect-select': {
    py: '6px',
  },
};

export const OrderTypeFilterClearButtonSx: SxProps = {
  backgroundColor: `${theme.palette.colors.grayF6} !important`,
  height: '24px',
  position: 'absolute',
  right: '30px',
  transformOrigin: 'top right',
  top: '5px',
  width: '24px',
  zIndex: 1,
  '&:hover': {
    backgroundColor: 'inherit',
  },
};
