/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { DocumentNode } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextIconButton from 'components/TextIconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { NavigationContext } from 'context/NavigationContext';
import HomeStatCard from 'pages/HomeUpdate/components/HomeStatCard';
import {
  GET_ASSETS_READY_FOR_DEPLOYMENT_TOTAL,
  GET_ALLOCATED_ASSETS_TOTAL,
  GET_ASSETS_PENDING_RETRIEVAL_TOTAL,
  GET_ASSETS_TOTAL,
} from './queries';
import * as HomeAssetsStyles from './styles';

const HomeAssets = () => {
  const navigate = useNavigate();
  const navigator = React.useContext(NavigationContext);
  const theme = useTheme();
  const userOrganization = useGetUserOrganization();

  const styles = HomeAssetsStyles;
  const nextFetchPolicy = 'cache-and-network';

  const getAssetsQueryResponse = (documentNode: DocumentNode) =>
    useSafeQuery(documentNode, {
      variables: {
        organizationId: userOrganization?.id,
      },
      skip: !userOrganization?.id,
      nextFetchPolicy,
    });

  const allAssetsResponse = getAssetsQueryResponse(GET_ASSETS_TOTAL);
  const deployReadyAssetsResponse = getAssetsQueryResponse(
    GET_ASSETS_READY_FOR_DEPLOYMENT_TOTAL
  );
  const allocatedAssetsResponse = getAssetsQueryResponse(
    GET_ALLOCATED_ASSETS_TOTAL
  );
  const pendingRetrievalAssetsResponse = getAssetsQueryResponse(
    GET_ASSETS_PENDING_RETRIEVAL_TOTAL
  );
  const getInInventoryAssetsTotal = () => {
    if (
      allAssetsResponse?.loading ||
      allocatedAssetsResponse?.loading ||
      pendingRetrievalAssetsResponse?.loading
    )
      return '';

    const allAssetsCount = allAssetsResponse?.data?.searchAssets?.count ?? 0;
    const allocatedAssetsCount =
      allocatedAssetsResponse?.data?.searchAssets?.count ?? 0;
    const pendingRetrievalAssetsCount =
      pendingRetrievalAssetsResponse?.data?.searchAssets?.count ?? 0;

    return (
      allAssetsCount - (allocatedAssetsCount + pendingRetrievalAssetsCount)
    );
  };

  return (
    <Container sx={styles.HomePageSectionContainerSx}>
      <Stack flexDirection="row">
        <Typography variant="h6" className="section-title">
          Assets
        </Typography>
        &nbsp;&nbsp;
        <TextIconButton
          text="View all"
          ariaLabel="Navigate back to Assets page"
          icon={<ArrowForwardIcon fontSize="small" />}
          onClick={() => navigate(navigator.pathToAssetsDevices())}
          textStyles={styles.HomePageSectionViewAllBtnTextStyles}
          buttonStyles={styles.BaseViewAllBtnStyles}
          endIcon
        />
      </Stack>
      <Grid
        container
        flexDirection="row"
        justifyContent="space-between"
        sx={styles.HomePageStatCardRowGridSx}
      >
        <HomeStatCard
          baseId="assets-in-inventory"
          displayText="In inventory"
          cardBgColor="rgba(249, 197, 77, 0.12)"
          tooltipText="Assets located at allwhere warehouses"
          iconFill={theme.palette.colors.maizeYO}
          icon={<WarehouseIcon style={{ fontSize: '40px' }} />}
          displayStat={String(getInInventoryAssetsTotal())}
        />
        <HomeStatCard
          baseId="assets-allocated"
          displayText="Allocated"
          cardBgColor="rgba(77, 183, 198, 0.12)"
          tooltipText="Assets allocated to employee or company locations"
          iconFill={theme.palette.colors.pelorous}
          icon={<CheckCircleIcon style={{ fontSize: '40px' }} />}
          displayStat={String(
            allocatedAssetsResponse?.data?.searchAssets?.count ?? ''
          )}
        />
        <HomeStatCard
          baseId="assets-ready-for-deployment"
          displayText="Ready to deploy"
          cardBgColor="rgba(122, 132, 80, 0.12)"
          tooltipText="Assets that are in inventory and ready to be deployed"
          iconFill={theme.palette.colors.goldFusion}
          icon={<ShoppingCartIcon style={{ fontSize: '40px' }} />}
          displayStat={String(
            deployReadyAssetsResponse?.data?.searchAssets?.count ?? ''
          )}
        />
      </Grid>
    </Container>
  );
};

export default HomeAssets;
