/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import GenericAutocomplete from 'components/GenericAutocomplete';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import useDebouncedSearch from 'hooks/useDebouncedSearch';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import {
  DEVICE_FILTERS_WIDTH,
  DEVICE_FILTERS_HEIGHT,
} from 'pages/Assets/components/DevicesDatagrid/components/DeviceFilters/constants';
import { GET_ASSETS_BY_DISPLAY_SIZE } from './queries';
import { AssetDisplaySizeFilterProps } from './types';
import { ASSET_DISPLAY_SIZE_INPUT_ID } from './constants';
import * as AssetDisplaySizeFilterStyles from './styles';

const AssetDisplaySizeFilter = (props: AssetDisplaySizeFilterProps) => {
  const styles = AssetDisplaySizeFilterStyles;
  const organization = useGetUserOrganization();
  const [assetDisplaySize, setAssetDisplaySize] = React.useState('');
  const [displaySizeOptions, setDisplaySizeOptions] = React.useState<string[]>(
    []
  );
  const debouncedAssetDisplaySize = useDebouncedSearch(assetDisplaySize, 300);

  const [fetchAssetsByDisplaySize] = useSafeLazyQuery(
    GET_ASSETS_BY_DISPLAY_SIZE,
    {
      onCompleted(data) {
        const { searchAssets } = data;
        const assetSet = new Set<string>();
        const assets = searchAssets?.assets || [];
        if (!assets.length) return;
        const tempOptions = assets
          .map((asset: any) => asset.displaySize)
          .filter((displaySize: string) => {
            if (assetSet.has(displaySize)) return false;
            assetSet.add(displaySize);
            return true;
          });

        setDisplaySizeOptions(tempOptions);
      },
    }
  );

  const handleInputChange = (_e: any, value: any) => {
    setAssetDisplaySize(value);
  };

  const clearAllAutoCompleteOptions = () => {
    const clearTextIconButton: HTMLElement = document.querySelector(
      `#${ASSET_DISPLAY_SIZE_INPUT_ID} .MuiAutocomplete-clearIndicator`
    ) as HTMLElement;
    if (clearTextIconButton) {
      clearTextIconButton.click();
    }
    setAssetDisplaySize('');
    setDisplaySizeOptions([]);
    props.setChosenAssetDisplaySize('');
  };

  const handleAutoCompleteChange = (event: any, value: any) => {
    if (value === null) {
      clearAllAutoCompleteOptions();
      return;
    }

    props.setChosenAssetDisplaySize(value);
  };

  React.useEffect(() => {
    if (!organization?.id || !debouncedAssetDisplaySize) return;
    fetchAssetsByDisplaySize({
      variables: {
        organizationId: organization.id,
        iLike: {
          field: 'displaySize',
          value: `%${debouncedAssetDisplaySize}%`,
        },
      },
    });
  }, [organization?.id, debouncedAssetDisplaySize]);

  React.useEffect(() => {
    if (props.chosenAssetDisplaySize) return;
    clearAllAutoCompleteOptions();
  }, [props.chosenAssetDisplaySize]);

  return (
    <Stack
      direction="column"
      sx={styles.AutoCompleteStackSx}
      id={ASSET_DISPLAY_SIZE_INPUT_ID}
    >
      <GenericAutocomplete
        loading={false}
        onChange={handleAutoCompleteChange}
        onInputChange={handleInputChange}
        componentsProps={{
          popper: {
            style: {
              width: DEVICE_FILTERS_WIDTH,
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Start typing"
            label="Display Size"
            aria-label="Display Size"
            sx={styles.AutoCompleteSearchTextFieldSx}
          />
        )}
        sx={styles.AutoCompleteSx}
        options={displaySizeOptions}
      />
    </Stack>
  );
};

export default AssetDisplaySizeFilter;
