import { gql } from '@apollo/client';

export const GET_ALL_ASSET_STATUS_TRANSITIONS_BY_ASSET = gql`
  query getAllAssetStatusTransitionsByAssetId($assetId: String!) {
    getAllAssetStatusTransitionsByAssetId(assetId: $assetId) {
      id
      assetId
      to
      from
      createdAt
    }
  }
`;
