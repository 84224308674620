import React from 'react';
import RestoreIcon from '@mui/icons-material/Restore';
import Stack from '@mui/material/Stack';

import { ColumnFooterProps } from './types';

import { FooterContainerSx, IconSx, TextSx } from './styles';

function ColumnFooter(props: ColumnFooterProps) {
  const { changed, onRestoreColumnDefaults: handleOnRestoreColumnDefaults } =
    props;
  if (!changed) return null;
  return (
    <Stack sx={FooterContainerSx} onClick={handleOnRestoreColumnDefaults}>
      <Stack sx={IconSx}>
        <RestoreIcon />
      </Stack>
      <Stack sx={TextSx}>Restore defaults</Stack>
    </Stack>
  );
}

export default ColumnFooter;
