import { gql } from '@apollo/client';

export const getAssetFieldAutocompleteQuery = (assetQueryOptions: {
  field: string;
  searchOp?: string;
}) => {
  return gql`
    query getAssetAutocompleteSuggestions (
      $searchTerm: String!
      $searchBy: String! = "${assetQueryOptions.searchOp ?? 'iLike'}"
      $limit: Int = 10
      $searchField: String! = "${assetQueryOptions.field}"
    ) {
      getAssetAutocompleteSuggestions(
        assetFieldSuggestionsInput: {
          searchTerm: $searchTerm
          skipValues: []
          searchBy: $searchBy
          limit: $limit
          searchField: $searchField
        }
      ) {
        field
        matches
        matchIds
      }
    }
  `;
};
