import Stack from '@mui/material/Stack';

import { UploadHistoryProps } from './types';
import {
  UploadHistoryContainerSx,
  NoUploadsContainerSx,
  NoUploadsTextSx,
} from './styles';
import { mapViewableUploadsToDisplayableUploads } from '../utils';
import UploadHistoryItem from '../UploadHistoryItem';
import UploadHistoryActions from '../UploadHistoryActions';
import { Typography } from '@mui/material';
import { NO_UPLOADS_COPY } from './constants';

function UploadHistory(props: UploadHistoryProps) {
  const {
    onUploadSelect,
    onLookbackChange,
    onPagination,
    onToggleActionsLookbackDropdown,
    viewableUploads,
    historicQuery,
    actionsLookbackDropdownOpen,
  } = props;

  const displayableUploads =
    mapViewableUploadsToDisplayableUploads(viewableUploads);

  return (
    <Stack sx={UploadHistoryContainerSx}>
      <UploadHistoryActions
        onLookbackChange={onLookbackChange}
        lookback={historicQuery.lookback}
        lookbackDropdownOpen={actionsLookbackDropdownOpen}
        onToggleLookbackDropdown={onToggleActionsLookbackDropdown}
        historicQuery={historicQuery}
        onPagination={onPagination}
      />
      {displayableUploads.length ? (
        displayableUploads?.map((upload) => (
          <UploadHistoryItem
            key={upload.timestamp}
            upload={upload}
            onUploadSelect={onUploadSelect}
          />
        ))
      ) : (
        <Stack sx={NoUploadsContainerSx}>
          <Typography variant="subtitle1" sx={NoUploadsTextSx}>
            {NO_UPLOADS_COPY}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}

export default UploadHistory;
