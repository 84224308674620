/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Button } from 'components';
import { NewModalProps } from './types';
import * as NewModalStyles from './styles';
import GALoadingButton from '../buttons/google_analytics/GALoadingButton';

const NewModal = (props: NewModalProps) => {
  const styles = NewModalStyles;
  const { showCloseButtonTopRight = true } = props;
  const paperPropsSx = props.paperPropsSx
    ? { ...styles.NewModalPaperSx, ...props.paperPropsSx }
    : styles.NewModalPaperSx;
  const outerStackPropsSx = props.outerStackPropsSx
    ? { ...styles.NewModalMainStackSx, ...props.outerStackPropsSx }
    : styles.NewModalMainStackSx;
  const topDividerStyles = props.topDividerCSS
    ? { ...styles.NewModalTopDividerStyles, ...props.topDividerCSS }
    : styles.NewModalTopDividerStyles;
  const bottomDividerStyles = props.bottomDividerCSS
    ? { ...styles.NewModalBottomDividerStyles, ...props.bottomDividerCSS }
    : styles.NewModalBottomDividerStyles;
  const formChildrenBoxSx = props.formChildrenBoxSx
    ? {
        ...styles.NewModalFormChildrenBoxSx,
        ...props.formChildrenBoxSx,
      }
    : styles.NewModalFormChildrenBoxSx;
  const { formSx: FormSx, innerFormStackSx: InnerFormStackSx } = props;

  const dividersPlaced = props?.dividerPlacement ?? 'none';

  const hasTopDivider = () =>
    dividersPlaced === 'top' || dividersPlaced === 'both';
  const hasBottomDivider = () =>
    dividersPlaced === 'bottom' || dividersPlaced === 'both';

  return (
    <Dialog
      open={props.open}
      sx={styles.getNewModalBaseSx(!!props.form)}
      onClose={props.handleClose}
      data-testid={props.dataTestId}
      PaperProps={{
        // @ts-ignore
        sx: { ...paperPropsSx },
      }}
    >
      {props.form ? (
        <Box sx={FormSx}>
          <form onSubmit={props.onSubmit}>
            <Stack
              direction="column"
              spacing={0}
              justifyContent="space-between"
              // @ts-ignore
              sx={outerStackPropsSx}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={InnerFormStackSx}
              >
                <Stack direction="column" spacing={0} sx={{ width: '100%' }}>
                  <Box sx={styles.getNewModalTitlesBoxSx(hasTopDivider())}>
                    <Typography
                      variant="h5"
                      sx={styles.NewModalTitleSx}
                      className="new-modal-form-title-typography"
                    >
                      <Stack direction="row">
                        {props.TitleIcon ? (
                          <Box sx={NewModalStyles.TitleIconSx}>
                            {props.TitleIcon}
                          </Box>
                        ) : null}
                        <Box>{props.title}</Box>
                      </Stack>
                    </Typography>
                    <Typography
                      className="new-modal-form-sub-title-typography"
                      sx={styles.getNewModalSubtitleSx(props.subtitle ?? '')}
                    >
                      {props.subtitle ?? ''}
                    </Typography>
                  </Box>
                  <Box
                    // @ts-ignore
                    sx={formChildrenBoxSx}
                    className="new-modal-form-children-box"
                  >
                    {props.children}
                  </Box>
                </Stack>
                {showCloseButtonTopRight ? (
                  <IconButton
                    aria-label="close-modal-icon-button"
                    onClick={props.handleClose}
                    sx={{
                      bgcolor: 'theme.palette.colors.gray75',
                      position: 'absolute',
                      right: '18px',
                      top: '18px',
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                ) : null}
              </Stack>
              {!props.hideCTAs ? (
                <Stack
                  direction="row"
                  spacing={0}
                  justifyContent="space-between"
                >
                  {props.leftBottomAction ? (
                    <Stack
                      direction="row"
                      spacing={0}
                      justifyContent="flex-start"
                      sx={{ bottom: '24px', position: 'absolute' }}
                    >
                      {props.leftBottomAction}
                    </Stack>
                  ) : null}
                  <Stack
                    id="modal-button-stack"
                    direction="row"
                    spacing={0}
                    justifyContent="flex-end"
                    sx={styles.getNewModalButtonStackSx(hasBottomDivider())}
                  >
                    <Button
                      gaContext={{
                        navigates_to: 'N/A',
                        purpose: 'Closes Modal',
                        textCopy: props.cancelButtonCopy ?? 'No, cancel',
                      }}
                      id="cancel-button"
                      color="secondary"
                      onClick={props.handleClose}
                      sx={styles.NewModalButtonBaseSx}
                      transparentBackgroundOnHover
                    >
                      <Typography variant="button">
                        {props.cancelButtonCopy ?? 'No, cancel'}
                      </Typography>
                    </Button>
                    <GALoadingButton
                      gaContext={{
                        navigates_to: 'N/A',
                        purpose: 'Saves Changes',
                        textCopy: props.submitButtonCopy ?? 'Save',
                      }}
                      disabled={props.disableSaveButton || false}
                      variant="contained"
                      type="submit"
                      value="submit"
                      loading={props.loading || false}
                      color="primary"
                      sx={styles.NewModalSubmitButtonSx}
                      loadingIndicator={
                        <CircularProgress color="primary" size={20} />
                      }
                    >
                      <Typography variant="button">
                        {props.submitButtonCopy ?? 'Save'}
                      </Typography>
                    </GALoadingButton>
                  </Stack>
                </Stack>
              ) : null}
            </Stack>
          </form>
        </Box>
      ) : (
        props.children
      )}
    </Dialog>
  );
};

export default NewModal;
