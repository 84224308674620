/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import OrderStatusPill from 'components/OrderStatusPill';
import { capitalizeWords } from 'services/format';
import { OrderStatus } from 'global-constants';
import { OrderStatusDropdownProps } from './types';
import { ORDER_STATUS_DROP_DOWN_ID } from './constants';

const OrderStatusDropdown = (props: OrderStatusDropdownProps) => {
  if (!props.order) return <></>;

  const theme = useTheme();
  const enumKeys = Object.keys(OrderStatus) as [OrderStatus];

  const [orderStatus, setOrderStatus] = React.useState<string>('');

  React.useEffect(() => {
    if (!props.order?.status) return;
    setOrderStatus(props.order.status);
  }, [props.order?.status]);

  const handleOrderStatusChange = (event: SelectChangeEvent<string | null>) => {
    const newStatus = event.target.value || '';
    if (props.order?.status === newStatus) return;
    props.promptOrderStatusChange(newStatus);
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <InputLabel
        id="order-status-dropdown-label"
        htmlFor={ORDER_STATUS_DROP_DOWN_ID}
        sx={{ fontSize: '12px', ml: '7px' }}
      >
        ORDER STATUS
      </InputLabel>
      <Select
        id={ORDER_STATUS_DROP_DOWN_ID}
        variant="standard"
        sx={{
          borderBottom: '1px solid black',
          minWidth: '110px',
        }}
        value={orderStatus}
        onChange={handleOrderStatusChange}
        renderValue={(selected: string) => (
          <OrderStatusPill showStatusName status={selected as OrderStatus} />
        )}
      >
        {enumKeys.slice().map((statusKey: string) => {
          let label = capitalizeWords(statusKey);
          if (statusKey === OrderStatus.PENDING_APPROVAL) {
            label = 'Preparing Order';
          }
          return <MenuItem value={statusKey}>{label}</MenuItem>;
        })}
      </Select>
    </Stack>
  );
};

export default OrderStatusDropdown;
