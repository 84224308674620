import { SxProps } from '@mui/material';
import { DRAWER_WIDTH } from 'global-styles';
import theme from 'themes/theme';
import { HOME_PAGE_UPDATE_TITLE_TEXT_ID } from './constants';

export const MainContainerSx: SxProps = {
  boxSizing: 'border-box',
  paddingTop: '2rem',
  margin: 'auto',
  position: 'relative',
  '@media (max-width: 1199px)': {
    left: '0',
  },
  '@media (min-width: 1200px)': {
    left: `${DRAWER_WIDTH - DRAWER_WIDTH / 2}px`,
    width: '80%',
  },
  '@media (min-width: 1536px)': {
    left: `${DRAWER_WIDTH - DRAWER_WIDTH / 2}px`,
    maxWidth: '100%',
    width: '85%',
  },
};

export const HomePageStackSx: SxProps = {
  flexWrap: 'nowrap',
  paddingTop: '1rem',
  marginTop: '21px',
};

export const HomePageWelcomeStackSx: SxProps = {
  [`& #${HOME_PAGE_UPDATE_TITLE_TEXT_ID}`]: {
    color: theme.palette.colors.black,
    // fontFamily: 'Inter',
    fontSize: '36px',
    // fontWeight: 600,
    lineHeight: '30px',
    // marginBottom: '16px',
  },
  '@media (max-width: 1199px)': {
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
  },
};

export const HomePageContentStackSx: SxProps = {
  width: '100%',
  '@media (max-width: 1199px)': {
    flexDirection: 'column',
  },
  '@media (min-width: 1200px)': {
    flexDirection: 'row',
  },
};
export const HomePageMainContentStackSx: SxProps = {
  flexDirection: 'column',
  '@media (max-width: 1199px)': {
    width: '100%',
  },
  '@media (min-width: 1200px)': {
    width: '56%',
  },
};
export const HomePageSideBarContentStackSx: SxProps = {
  boxSizing: 'border-box',
  '@media (max-width: 1199px)': {
    mt: '24px',
    width: '100%',
  },
  '@media (min-width: 1200px)': {
    pl: '24px',
    width: '44%',
  },
};

export const HomePageMarketingStackSx: SxProps = {
  border: `1px solid ${theme.palette.colors.transparentGrey}`,
  borderRadius: '10px',
  cursor: 'pointer',
  mt: '24px',
  '& .home-marketing-box': {
    p: '12px',
    pr: '24px',
  },
  '& .home-marketing-title': {
    boxSizing: 'border-box',
    color: theme.palette.colors.woodSmoke,
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    maxHeight: '92px',
    lineHeight: '26px',
    my: '4px',
  },
  '& .home-marketing-date': {
    color: theme.palette.colors.gray60,
  },
};

export const BaseViewAllBtnStyles: SxProps = {
  p: 0,
  position: 'relative',
  bottom: '1px',
};

export const BaseViewAllBtnTextStyles: SxProps = {
  color: theme.palette.colors.woodSmoke,
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '22px',
};
