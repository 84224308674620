import { gql } from '@apollo/client';

export const SEARCH_COLLABORATORS_BY_ORG_ID = gql`
  query collaborators(
    $organizationId: ID!
    $offset: Int!
    $limit: Int!
    $firstName: String
    $lastName: String
    $email: String
    $personalEmail: String
    $workEmail: String
    $orderAsc: String
    $orderDesc: String
    $collaboratorFullTextSearch: String
  ) {
    collaborators(
      collaboratorSearchInput: {
        organizationId: $organizationId
        offset: $offset
        limit: $limit
        firstName: $firstName
        lastName: $lastName
        email: $email
        personalEmail: $personalEmail
        workEmail: $workEmail
        orderAsc: $orderAsc
        orderDesc: $orderDesc
        collaboratorFullTextSearch: $collaboratorFullTextSearch
      }
    ) {
      collaborators {
        id
        firstName
        lastName
        email
        workEmail
        personalEmail
        group
        hireDate
        startDate
        employmentStatus
        hrisEmployeeId
      }
      count
    }
  }
`;

export const RETRIEVE_EMPLOYEE_BY_ID = gql`
  query retrieveCollaborator($id: String!) {
    collaborator(id: $id) {
      id
      hrisEmployeeId
      firstName
      lastName
      email
      workEmail
      personalEmail
      group
      hireDate
      startDate
      endDate
      employmentStatus
      avatar
      primaryAddressId
      collaboratorAddresses {
        id
        collaboratorId
        addressId
        isHome
        isWork
      }
      primaryCollaboratorAddress {
        addressId
        isHome
        isWork
      }
      workAddresses {
        id
        streetAddress1
        streetAddress2
        city
        state
        principalRegion
        country
        phoneNumber
        zipCode
      }
      homeAddresses {
        id
        streetAddress1
        streetAddress2
        city
        state
        principalRegion
        country
        phoneNumber
        zipCode
      }
    }
  }
`;
