export const SPACE_BETWEEN_COLUMNS = '16px';
export const RECIPIENT_INFO_FORM_FIELDS = [
  'firstName',
  'lastName',
  'phoneNumber',
  'email',
];
export const REQUIRED_RECIPIENT_INFO_FORM_FIELDS = [
  'firstName',
  'lastName',
  'email',
];
export const REQUIRED_RECIPIENT_ADDRESS_FIELDS = [
  'streetAddress1',
  'city',
  'zipCode',
  'country',
];
export const RECIPIENT_ADDRESS_FORM_FIELDS = [
  'streetAddress2',
  'state',
  'principalRegion',
  ...REQUIRED_RECIPIENT_ADDRESS_FIELDS,
];
