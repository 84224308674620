import { SxProps } from '@mui/material';

export const OrderManagerRadioGroupSx: SxProps = {
  padding: '0 16px',
};

export const OrderManagerRadioSx: SxProps = {
  color: 'colors.strongBlue',
  '&.Mui-checked': {
    color: 'colors.strongBlue',
  },
};
