import React from 'react';
import { Avatar as MuiAvatar } from '@mui/material';
import { AvatarProps } from './types';

const Avatar = (props: AvatarProps) => {
  return (
    <MuiAvatar
      sx={{
        bgcolor: props.bgcolor || 'colors.maizeYO',
        color: props.color || 'colors.jungleG',
        width: props.width || 'auto',
        height: props.height || 'auto',
      }}
      data-testid={props?.['data-testid'] ?? ''}
      {...props}
    >
      {props.text}
    </MuiAvatar>
  );
};

export default Avatar;
