import { SxProps } from '@mui/material';

export const ShippingCardSx: SxProps = {
  borderRadius: '11px',
  border: '1px solid #C0C0C0',
  borderBoxSizing: 'border-box',
  minHeight: '132px',
  width: '246px',
  boxShadow: 'none',
  position: 'relative',
  '&.MuiCard-root.selected': {
    border: '1px solid #949394',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.07)',
  },
  '& .MuiCardContent-root:last-child': {
    pb: '8px',
  },
};

export const ShippingCardContentSx: SxProps = {
  borderBoxSizing: 'border-box',
  padding: '0 8px',
  height: '100%',
  p: '8px !important',
  pr: '20px !important',
};

export const ShippingCardOuterStackSx: SxProps = {
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
};

export const ShippingCardInnerTopStackSx: SxProps = {
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
};

export const ShippingCardInnerBottomStackSx: SxProps = {
  padding: '7px 5px 0px',
  position: 'absolute',
  bottom: '5px',

  '& .bottom-shipping-card-text': {
    color: 'colors.woodSmoke',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '16px',
  },
  '& .bottom-shipping-card-sub-text': {
    color: 'colors.goldFusion',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '16px',
  },
};

export const ShippingCardOptionTextSx: SxProps = {
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  pl: '36px',
  pt: '3px',
};

export const ShippingCardOptionSubTextBoxSx: SxProps = {
  ml: '7px',
  mt: '7px',
};

export const getShippingCardOptionSubTextSx = (index: number): SxProps => ({
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '16px',
  ...(index ? { mt: '2px' } : {}),
});

export const RadioButtonSx: SxProps = {
  position: 'absolute',
  left: '4px',
  top: 0,

  '&.Mui-checked': {
    color: 'colors.woodSmoke',
  },
};
