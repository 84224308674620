/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextIconButton from 'components/TextIconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import NotificationsIcon from '@mui/icons-material/Notifications';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { NavigationContext } from 'context/NavigationContext';
import HomeStatCard from 'pages/HomeUpdate/components/HomeStatCard';
import {
  GET_ALL_IN_TRANSIT_ORDERS_TOTAL,
  GET_ALL_IN_PROGRESS_ORDERS_TOTAL,
  GET_ALL_NEEDS_ATTENTION_ORDERS_TOTAL,
} from './queries';
import * as HomeOrdersStyles from './styles';

const HomeOrders = () => {
  const navigate = useNavigate();
  const navigator = React.useContext(NavigationContext);
  const theme = useTheme();
  const userOrganization = useGetUserOrganization();

  const styles = HomeOrdersStyles;
  const nextFetchPolicy = 'cache-and-network';

  const { data: inTransitData = {} } = useSafeQuery(
    GET_ALL_IN_TRANSIT_ORDERS_TOTAL,
    {
      variables: {
        organizationId: userOrganization?.id,
      },
      skip: !userOrganization?.id,
      nextFetchPolicy,
    }
  );

  const { data: inProgressData = {} } = useSafeQuery(
    GET_ALL_IN_PROGRESS_ORDERS_TOTAL,
    {
      variables: {
        organizationId: userOrganization?.id,
      },
      skip: !userOrganization?.id,
      nextFetchPolicy,
    }
  );

  const { data: needsAttentionData = {} } = useSafeQuery(
    GET_ALL_NEEDS_ATTENTION_ORDERS_TOTAL,
    {
      variables: {
        organizationId: userOrganization?.id,
      },
      skip: !userOrganization?.id,
      nextFetchPolicy,
    }
  );

  return (
    <Container sx={styles.HomePageSectionContainerSx}>
      <Stack flexDirection="row">
        <Typography variant="h6" className="section-title">
          Orders
        </Typography>
        &nbsp;&nbsp;
        <TextIconButton
          text="View all"
          ariaLabel="Navigates to Orders page"
          icon={<ArrowForwardIcon fontSize="small" />}
          onClick={() => navigate(navigator.pathToAllOrders())}
          textStyles={styles.HomePageSectionViewAllBtnTextStyles}
          buttonStyles={styles.BaseViewAllBtnStyles}
          endIcon
        />
      </Stack>
      <Grid
        container
        flexDirection="row"
        justifyContent="space-between"
        sx={styles.HomePageStatCardRowGridSx}
      >
        <HomeStatCard
          baseId="orders-in-transit"
          displayText="In transit"
          cardBgColor="rgba(249, 197, 77, 0.12)"
          tooltipText="Orders currently in transit with carrier"
          iconFill={theme.palette.colors.maizeYO}
          icon={<LocalShippingIcon style={{ fontSize: '40px' }} />}
          displayStat={String(inTransitData?.searchOrders?.count ?? 0)}
        />
        <HomeStatCard
          baseId="orders-in-progress"
          displayText="In progress"
          cardBgColor="rgba(77, 183, 198, 0.12)"
          tooltipText="Orders currently being processed by allwhere"
          iconFill={theme.palette.colors.pelorous}
          icon={<TimelapseIcon style={{ fontSize: '40px' }} />}
          displayStat={String(inProgressData?.searchOrders?.count ?? 0)}
        />
        <HomeStatCard
          baseId="orders-need-attention"
          displayText="Needs attention"
          cardBgColor="rgba(243, 138, 52, 0.12)"
          tooltipText="Orders in the follow-up required category"
          iconFill={theme.palette.colors.jaffa}
          icon={<NotificationsIcon style={{ fontSize: '40px' }} />}
          displayStat={String(needsAttentionData?.searchOrders?.count ?? 0)}
        />
      </Grid>
    </Container>
  );
};

export default HomeOrders;
