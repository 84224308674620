import React from 'react';
import { Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import BillingModalSpacer from 'components/BillingStatusModal/components/BillingModalSpacer';
import {
  BillingOuterFormStackSx,
  BillingInnerFormStackSx,
  BillingTextFieldBaseSx,
} from './styles';
import { BillingFormProps } from './types';
import { BillingAccountTypes } from './constants';

const BillingForm = (props: BillingFormProps) => {
  const { register, errors, getValues, control } = props;

  return (
    <>
      <Stack
        direction="row"
        spacing="20px"
        justifyContent="space-between"
        sx={BillingOuterFormStackSx}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          sx={BillingInnerFormStackSx}
        >
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="billing-modal-acct-type-label">
              Account Type
            </InputLabel>
            <Controller
              control={control}
              name="accountType"
              render={({ field }) => (
                <Select
                  labelId="billing-modal-acct-type-label"
                  label="Account Type"
                  id="account-type"
                  aria-required
                  required
                  {...field}
                >
                  {Object.values(BillingAccountTypes).map((acctType) => (
                    <MenuItem key={acctType} value={acctType}>
                      {acctType}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          <BillingModalSpacer />
          <Controller
            name="accountNumber"
            control={control}
            render={({ field }) => (
              <TextField
                id="billing-acct-number"
                label="Account number"
                aria-required
                required
                sx={BillingTextFieldBaseSx}
                {...field}
                {...register('accountNumber', {
                  required: true,
                  validate: (value: string | undefined) =>
                    value === getValues('confirmAccountNumber'),
                })}
                error={!!errors.accountNumber}
              />
            )}
          />
          {!!errors.accountNumber && (
            <Typography variant="body2" color="red">
              Account numbers should match.
            </Typography>
          )}
          <BillingModalSpacer />
          <Controller
            name="routingNumber"
            control={control}
            render={({ field }) => (
              <TextField
                id="billing-routing-number"
                label="Routing number"
                aria-required
                required
                sx={BillingTextFieldBaseSx}
                {...field}
                {...register('routingNumber', {
                  required: true,
                  validate: (value: string) =>
                    value === getValues('confirmRoutingNumber'),
                })}
                error={!!errors.routingNumber}
              />
            )}
          />
          {!!errors.routingNumber && (
            <Typography variant="body2" color="red">
              Routing numbers should match.
            </Typography>
          )}
        </Stack>
        <Stack
          direction="column"
          justifyContent="space-between"
          sx={BillingInnerFormStackSx}
        >
          <Controller
            name="accountName"
            control={control}
            render={({ field }) => (
              <TextField
                id="billing-acct-name"
                label="Account name"
                sx={BillingTextFieldBaseSx}
                {...register('accountName', { required: true })}
                error={!!errors.accountName}
                {...field}
              />
            )}
          />
          {!!errors.accountName && (
            <Typography variant="body2" color="red">
              Account needs a designation/name.
            </Typography>
          )}
          <BillingModalSpacer />
          <Controller
            name="confirmAccountNumber"
            control={control}
            render={({ field }) => (
              <TextField
                aria-required
                required
                id="billing-acct-number-confirmation"
                label="Confirm account number"
                sx={BillingTextFieldBaseSx}
                {...register('confirmAccountNumber', {
                  validate: (value: string) =>
                    value === getValues('accountNumber'),
                })}
                error={!!errors.confirmAccountNumber}
                {...field}
              />
            )}
          />
          {!!errors.confirmAccountNumber && (
            <Typography variant="body2" color="red">
              Account numbers should match.
            </Typography>
          )}
          <BillingModalSpacer />
          <Controller
            name="confirmRoutingNumber"
            control={control}
            render={({ field }) => (
              <TextField
                aria-required
                required
                id="billing-routing-number-confirmation"
                label="Confirm routing number"
                sx={BillingTextFieldBaseSx}
                {...register('confirmRoutingNumber', {
                  validate: (value: string) =>
                    value === getValues('routingNumber'),
                })}
                error={!!errors.confirmRoutingNumber}
                {...field}
              />
            )}
          />
          {!!errors.confirmRoutingNumber && (
            <Typography variant="body2" color="red">
              Routing numbers should match.
            </Typography>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default BillingForm;
