import { SxProps } from '@mui/material';

export const ButtonGroupSx: SxProps = {
  '& > span': {
    marginLeft: '0.91rem',
  },
};

export const ActionIconButtonSx: SxProps = {
  backgroundColor: 'colors.maizeYO',
  width: '48px',
  height: '48px',
  '& svg': {
    width: '100%',
    height: '100%',
  },
  ':disabled': {
    backgroundColor: 'colors.wildSandWN',
    '& .custom-stroke': {
      stroke: '#C0C0C0',
    },
    '& .custom-fill': {
      fill: '#C0C0C0',
    },
  },
};

export const getActionIconButtonSx = (): SxProps => ({
  backgroundColor: 'colors.maizeYO',
  width: '48px',
  height: '48px',
  '& svg': {
    width: '100%',
    height: '100%',
  },
  ':disabled': {
    backgroundColor: 'colors.wildSandWN',
    '& .custom-stroke': {
      stroke: '#C0C0C0',
    },
    '& .custom-fill': {
      fill: '#C0C0C0',
    },
  },
});

export const RecycleActionIconButtonSx: SxProps = {
  ...ActionIconButtonSx,
  padding: 0,
};
