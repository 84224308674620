import { gql } from '@apollo/client';

export const GET_ASSETS = gql`
  query searchAssets(
    $orderAsc: String
    $orderDesc: String
    $limit: Int = 25
    $offset: String
    $organizationId: ID!
    $assetNumber: String
    $color: AssetColorsEnum
    $assigneeEmail: String
    $depotName: String
    $displaySize: String
    $keyboard: String
    $cosmeticCondition: AssetCosmeticCondition
    $make: String
    $memory: String
    $model: String
    $processor: String
    $serialNumber: String
    $status: AssetStatusEnum
    $deviceAssessment: String
    $storage: String
    $assetTypeName: String
    $customerNote: String
  ) {
    searchAssets(
      assetsSearchInput: {
        orderAsc: $orderAsc
        orderDesc: $orderDesc
        limit: $limit
        offset: $offset
        organizationId: $organizationId
        assetNumber: $assetNumber
        color: $color
        assigneeEmail: $assigneeEmail
        depotName: $depotName
        displaySize: $displaySize
        keyboard: $keyboard
        cosmeticCondition: $cosmeticCondition
        make: $make
        memory: $memory
        model: $model
        processor: $processor
        serialNumber: $serialNumber
        status: $status
        deviceAssessment: $deviceAssessment
        storage: $storage
        assetTypeName: $assetTypeName
        customerNote: $customerNote
      }
    ) {
      count
      assets {
        id
        assetNumber
        organizationId
        assigneeId
        depotId
        productVariantId
        serialNumber
        make
        model
        displaySize
        status
        deviceAssessment
        memory
        storage
        assetTypeId
        color
        cosmeticCondition
        lastOrderItemNumber
        dataStatus
        keyboard
        processor
        technicalFunctionality
        createdAt
        notes
        updatedAt
        purchaseDate
        warrantyExpiration
        isWarrantyExpirationVerified
        isPurchaseDateVerified
        customerNote
        depot {
          id
          name
          addressId
        }
        assignee {
          id
          organizationId
          firstName
          lastName
          email
          personalEmail
          workEmail
        }
        assetType {
          id
          name
        }
        inventoryLog {
          dateOfLastCheckIn
          dateOfLastCheckOut
          depotId
        }
        assetImages {
          id
          image {
            id
            url
          }
        }
      }
    }
  }
`;

export const GET_COMPLEX_ASSET_SEARCH = gql`
  query getComplexAssetSearch(
    $organizationId: ID
    $orderAsc: String
    $orderDesc: String
    $limit: Int = 25
    $offset: Int = 0
    $searchEntries: [BaseComplexSearchFieldEntry!]! = []
  ) {
    getComplexAssetSearch(
      complexAssetSearchInput: {
        orderAsc: $orderAsc
        orderDesc: $orderDesc
        limit: $limit
        offset: $offset
        searchEntries: $searchEntries
        organizationId: $organizationId
      }
    ) {
      count
      assets {
        id
        assetNumber
        organizationId
        assigneeId
        depotId
        productVariantId
        serialNumber
        make
        model
        displaySize
        status
        deviceAssessment
        memory
        storage
        assetTypeId
        color
        cosmeticCondition
        lastOrderItemNumber
        dataStatus
        keyboard
        processor
        technicalFunctionality
        createdAt
        notes
        updatedAt
        purchaseDate
        warrantyExpiration
        isWarrantyExpirationVerified
        isPurchaseDateVerified
        customerNote
        depot {
          id
          name
          addressId
        }
        assignee {
          id
          organizationId
          firstName
          lastName
          email
          personalEmail
          workEmail
        }
        assetType {
          id
          name
        }
        inventoryLog {
          dateOfLastCheckIn
          dateOfLastCheckOut
        }
        assetImages {
          id
          image {
            id
            url
          }
        }
      }
    }
  }
`;

export const GET_ALL_ASSETS_BY_ORG_ID = gql`
  query getAllAssetsByOrganization($organizationId: ID!) {
    getAllAssetsByOrganization(
      getAllAssetsByOrganizationInput: { organizationId: $organizationId }
    ) {
      id
      assetNumber
      organizationId
      assigneeId
      depotId
      productVariantId
      serialNumber
      make
      model
      displaySize
      status
      deviceAssessment
      memory
      storage
      assetTypeId
      color
      cosmeticCondition
      lastOrderItemNumber
      dataStatus
      keyboard
      processor
      technicalFunctionality
      createdAt
      notes
      updatedAt
      depot {
        id
        name
        addressId
      }
      assignee {
        id
        firstName
        lastName
        email
      }
      assetType {
        id
        name
      }
      inventoryLog {
        dateOfLastCheckIn
        dateOfLastCheckOut
      }
    }
  }
`;

export const SEARCH_COLLABORATORS = gql`
  query collaborators(
    $organizationId: ID!
    $offset: Int!
    $limit: Int!
    $firstOrLastName: String
    $orderAsc: String
    $orderDesc: String
  ) {
    collaborators(
      collaboratorSearchInput: {
        organizationId: $organizationId
        offset: $offset
        limit: $limit
        firstOrLastName: $firstOrLastName
        orderAsc: $orderAsc
        orderDesc: $orderDesc
      }
    ) {
      collaborators {
        id
        firstName
        lastName
        email
      }
      count
    }
  }
`;

export const SEARCH_DEPOTS = gql`
  query searchDepots {
    searchDepots(input: { limit: 100 }) {
      depots {
        id
        name
      }
    }
  }
`;
