import { gql } from '@apollo/client';

export const GET_ASSETS_BY_DISPLAY_SIZE = gql`
  query getAssetsByDisplaySize(
    $organizationId: ID!
    $displaySize: String
    $iLike: AssetILikeParam
  ) {
    searchAssets(
      assetsSearchInput: {
        organizationId: $organizationId
        displaySize: $displaySize
        iLikeFilter: $iLike
        offset: "0"
      }
    ) {
      assets {
        id
        displaySize
      }
      count
    }
  }
`;
