export const ASM_FILTER_DRAWER_DIALOG_DATA_TEST_ID =
  'asm-dash-filter-drawer-test-id';
export enum AMDashComplexFilterTestIds {
  ALLWHERE_INFO = 'asm-dash-filter-allwhere-info-section',
  ASSIGNEE_INFO = 'asm-dash-filter-assignee-info-section',
  DEVICE_INFO = 'asm-dash-filter-device-info-section',
}

export const ASM_DASH_DRAWER_CLEAR_ALL_BUTTON_ID =
  'sm-dash-filter-drawer-clear-all-button';

export const ASM_DASH_DRAWER_INPUT_WIDTH = '212px';
export const ASM_DASH_DRAWER_INPUT_HEIGHT = '40px';
export const ASM_DASH_DRAWER_INPUT_BOX_CLASS = 'asm-dash-drawer-input-box';
export const ASM_DASH_DRAWER_FILTER_BY_CLOSE_ICON_ID =
  'asm-dash-drawer-filter-by-close-icon';
