import React from 'react';
import {
  generateSurveyCalls,
  generateSurveyScripts,
  watchForAddedTarget,
} from './utils';
import { Data } from './types';
import { useLocation } from 'react-router-dom';
import { Navigator } from '../../context/NavigationContext';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import useGetGlobalPurchaser from 'hooks/useGetGlobalPurchaser';

export const useConfigureDelightedSurveys = () => {
  const location = useLocation();
  const [currentPathname, setCurrentPathname] = React.useState<string>(
    location.pathname
  );
  const [delightedButton, setDelightedButton] =
    React.useState<HTMLElement | null>(null);
  const [delightedScripts, setDelightedScripts] = React.useState<string[]>([]);
  const [delightedCalls, setDelightedCalls] = React.useState<string | null>(
    null
  );
  const navigator = new Navigator('/');
  const userOrganization = useGetUserOrganization();
  const userPurchaser = useGetGlobalPurchaser();

  React.useEffect(() => {
    if (
      (delightedButton && currentPathname !== location.pathname) ||
      userPurchaser ||
      userOrganization
    ) {
      delightedButton?.click();
    }
  }, [location.pathname, currentPathname, userPurchaser, userPurchaser]);

  React.useEffect(() => {
    setCurrentPathname(location.pathname);
  }, [location.pathname]);

  React.useEffect(() => {
    setDelightedScripts(generateSurveyScripts());
    const collection = document.getElementsByTagName('body');
    const parent = collection.item(0);
    watchForAddedTarget(
      '.delighted-web.delighted-web-desktop',
      '.delighted-web-survey-close-btn',
      (mutation, data) => {
        const { foundNode: button } = data as Data;
        setDelightedButton(button as HTMLElement);
      },
      parent as Node
    );
  }, []);

  React.useEffect(() => {
    let surveyCalls: string | null;
    if (
      !userPurchaser ||
      !userOrganization ||
      currentPathname !== location.pathname
    ) {
      setDelightedCalls('');
    } else if (userPurchaser && userOrganization) {
      setTimeout(() => {
        surveyCalls = generateSurveyCalls(
          location.pathname,
          userPurchaser,
          userOrganization,
          navigator
        );
        setDelightedCalls(surveyCalls);
      }, 1000);
    }
  }, [
    location,
    location?.search,
    location.pathname,
    userPurchaser,
    userOrganization,
    navigator,
  ]);

  return {
    delightedScripts,
    delightedCalls,
  };
};
