import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AssetManagementState,
  AssetManagementFilterEnumKeys,
} from 'store/types';
import {
  AssetManagementAvailableOptions,
  AssetManagementSelectedOptions,
  AM_DASH_FILTER_KEY_QUERY_FIELD_INDEX,
} from 'store/constants';
import { isStringEnumKey } from 'services/enums';
import { Asset, FilterBankEntry } from 'types';
import { ALLWHERE_INFO_ASSET_TYPE_OPTIONS } from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/components/AllwhereInfoFilterSection/constants';
import {
  ASSET_DATA_STATUS_OPTIONS,
  ASSET_COSMETIC_CONDITION_OPTIONS,
} from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/components/DeviceInfoFilterSection/constants';
import { FilterPlaceholders } from 'pages/AssetManagement/constants';

const initialAssetManagementState: AssetManagementState = {
  // old filters
  oldSearchTerm: null,
  oldSearchValue: null,
  // new filters
  filterBankEntries: [],
  isFiltered: false,
  filterOpen: false,
  enableEdit: false,
  enableDelete: false,
  selectedDevice: undefined,
  assetActionModal: null,
  filters: {
    availableAssetNumbers: [],
    selectedAssetNumbers: [],
    availableDepots: [],
    selectedDepots: [],
    availableStatuses: ALLWHERE_INFO_ASSET_TYPE_OPTIONS,
    selectedStatuses: [],
    availableOrgs: [],
    selectedOrgs: [],
    availableAssignees: [],
    selectedAssignees: [],
    availableAssigneeEmails: [],
    selectedAssigneeEmails: [],
    availableTypes: [],
    selectedTypes: [],
    availableMakes: [],
    selectedMakes: [],
    availableModels: [],
    selectedModels: [],
    availableConditions: ASSET_COSMETIC_CONDITION_OPTIONS,
    selectedConditions: [],
    availableKeyConfigs: [],
    selectedKeyConfigs: [],
    availableSerialNumbers: [],
    selectedSerialNumbers: [],
    availableDeviceIssues: [],
    selectedDeviceIssues: [],
    availableColors: [],
    selectedColors: [],
    availableDisplaySizes: [],
    selectedDisplaySizes: [],
    availableDataStatuses: ASSET_DATA_STATUS_OPTIONS,
    selectedDataStatuses: [],
  },
};

const getQueryFieldIndexPairs = (filterKey: string) => {
  const badFilterKey = !(filterKey in AM_DASH_FILTER_KEY_QUERY_FIELD_INDEX);

  if (badFilterKey) {
    throw new Error(
      `Filter key: "${filterKey}" is not a valid filter key. For "AM_DASH_FILTER_KEY_QUERY_FIELD_INDEX"`
    );
  }

  return AM_DASH_FILTER_KEY_QUERY_FIELD_INDEX[
    filterKey as keyof typeof AM_DASH_FILTER_KEY_QUERY_FIELD_INDEX
  ];
};

const getAvailableMatch =
  (enumToMatch: any) =>
  (enumKey: string): string => {
    const invalidEnumKey = !isStringEnumKey(enumKey, enumToMatch);

    if (invalidEnumKey) {
      throw new Error(
        `Key: "${enumKey}" is an invalid enum key. Tried to find match in enum, ${enumToMatch.toString()}.`
      );
    }

    return enumToMatch[enumKey as keyof typeof enumToMatch];
  };

export const getAvailableOptionsMatchByKey = getAvailableMatch(
  AssetManagementAvailableOptions
);

const getSelectedOptionsMatchByKey = getAvailableMatch(
  AssetManagementSelectedOptions
);

export const getMatchingOptions = (enumKey: string) => ({
  availableOptionsName: getAvailableOptionsMatchByKey(enumKey),
  selectedOptionsName: getSelectedOptionsMatchByKey(enumKey),
});

export const assetManagementSlice = createSlice({
  name: 'assetManagementService',
  initialState: () => initialAssetManagementState,
  reducers: {
    removeFilterReducer: (
      state,
      action: PayloadAction<{
        selectedOptionsList: string;
        displayValue: string;
        queryField: string;
      }>
    ) => {
      const { selectedOptionsList, displayValue, queryField } = action.payload;

      const selectedOptions =
        state.filters[selectedOptionsList as keyof typeof state.filters];

      const bankEntries = state.filterBankEntries;

      const hasOneSelectedOption = selectedOptions.length === 1;

      const hasOneBankEntry = state.filterBankEntries.length === 1;

      if (hasOneSelectedOption) {
        state.filters[selectedOptionsList as keyof typeof state.filters] = [];
      } else {
        state.filters[selectedOptionsList as keyof typeof state.filters] =
          selectedOptions.filter((option) => option[0] !== displayValue);
      }

      if (hasOneBankEntry) {
        state.filterBankEntries = [];
        state.isFiltered = false;
      } else {
        const removalIndex = bankEntries.findIndex(
          (entry) =>
            entry.displayValue === displayValue &&
            entry.selectedOptionsList === selectedOptionsList &&
            entry.queryField === queryField
        );
        state.filterBankEntries = bankEntries.filter(
          (_entry, index) => index !== removalIndex
        );
      }
    },
    removeSelectFilterOptionReducer: (
      state,
      action: PayloadAction<{
        filterKey: string;
        displayValue: string;
      }>
    ) => {
      const { filterKey, displayValue } = action.payload;
      const selectedOptionsList = getSelectedOptionsMatchByKey(filterKey);

      const hasOneBankEntry = state.filterBankEntries.length === 1;

      if (hasOneBankEntry) {
        state.filterBankEntries = [];
        state.isFiltered = false;
        state.filters[selectedOptionsList as keyof typeof state.filters] = [];
      } else {
        const bankEntryRemovalIndex = state.filterBankEntries.findIndex(
          (entry: FilterBankEntry) =>
            entry.displayValue === displayValue &&
            entry.selectedOptionsList === selectedOptionsList
        );

        state.filterBankEntries = state.filterBankEntries.filter(
          (entry, index) => index !== bankEntryRemovalIndex
        );
      }

      const currentSelectedOptions =
        state.filters[selectedOptionsList as keyof typeof state.filters];

      const hasOneSelectedOption = currentSelectedOptions.length === 1;

      if (hasOneSelectedOption) {
        state.filters[selectedOptionsList as keyof typeof state.filters] = [];
      } else {
        state.filters[selectedOptionsList as keyof typeof state.filters] =
          currentSelectedOptions.filter((option) => option[0] !== displayValue);
      }
    },
    selectFilterOptionReducer: (
      state,
      action: PayloadAction<{
        filterKey: string;
        option: [string, string];
      }>
    ) => {
      const { filterKey, option } = action.payload;
      const [queryField, queryValueIndex] = getQueryFieldIndexPairs(filterKey);
      const [displayValue] = option;
      const queryValue = option[queryValueIndex];
      const selectedOptionsList = getSelectedOptionsMatchByKey(filterKey);

      const newFilterBankEntry = {
        queryValue,
        queryField,
        displayValue,
        selectedOptionsList,
      };

      state.oldSearchTerm = initialAssetManagementState.oldSearchTerm;
      state.oldSearchValue = initialAssetManagementState.oldSearchValue;

      state.filterBankEntries.push(newFilterBankEntry);
      state.filters[selectedOptionsList as keyof typeof state.filters].push(
        option
      );

      if (state.isFiltered === false) {
        state.isFiltered = true;
      }
    },
    populateAvailableOptionReducer: (
      state,
      action: PayloadAction<{
        optionKey: string;
        options: string[][];
      }>
    ) => {
      const { optionKey, options } = action.payload;
      const targetAvailableOption = getAvailableOptionsMatchByKey(optionKey);

      state.filters[targetAvailableOption as keyof typeof state.filters] =
        options;
    },
    populateAvailableTypeAHeadsReducer: (
      state,
      action: PayloadAction<{
        optionKey: string;
        options: string[][];
      }>
    ) => {
      const { optionKey, options } = action.payload;

      const { availableOptionsName, selectedOptionsName } =
        getMatchingOptions(optionKey);

      const selectedTypeAheadOptions =
        state.filters[selectedOptionsName as keyof typeof state.filters];

      const selectedTable = Object.fromEntries(selectedTypeAheadOptions);
      const trimmedOptions = options.filter(
        ([displayValue]) => !(displayValue in selectedTable)
      );

      const combinedOptions = [
        [
          FilterPlaceholders.CLEAR_ALL_FILTER_OPTION,
          FilterPlaceholders.CLEAR_ALL_FILTER_OPTION,
        ],
        ...selectedTypeAheadOptions,
        ...trimmedOptions,
      ];

      state.filters[availableOptionsName as keyof typeof state.filters] =
        combinedOptions;
    },
    openFilterDrawerReducer: (state) => {
      state.filterOpen = true;
    },
    closeFilterDrawerReducer: (state) => {
      state.filterOpen = false;
    },
    toggleFilterDrawerReducer: (state) => {
      state.filterOpen = !state.filterOpen;
    },
    clearAvailableOptionReducer: (
      state,
      action: PayloadAction<AssetManagementFilterEnumKeys>
    ) => {
      const targetAvailableOption = getAvailableOptionsMatchByKey(
        action.payload
      );

      state.filters[targetAvailableOption as keyof typeof state.filters] = [];
    },
    clearSelectedFiltersByTypeReducer: (
      state,
      action: PayloadAction<string>
    ) => {
      const selectedListName = getSelectedOptionsMatchByKey(action.payload);
      state.filters[selectedListName as keyof typeof state.filters] = [];
      state.filterBankEntries = state.filterBankEntries.filter(
        (bankEntry: FilterBankEntry) =>
          bankEntry.selectedOptionsList !== selectedListName
      );

      if (state.filterBankEntries.length === 0) {
        state.isFiltered = false;
      }
    },
    clearFilterByTypeReducer: (state, action: PayloadAction<string>) => {
      const { availableOptionsName, selectedOptionsName } = getMatchingOptions(
        action.payload
      );

      if (!selectedOptionsName)
        throw new Error('Invalid selectedOptionsName. Passed falsey value.');

      state.filters[availableOptionsName as keyof typeof state.filters] =
        initialAssetManagementState.filters[
          availableOptionsName as keyof typeof initialAssetManagementState.filters
        ];
      state.filters[selectedOptionsName as keyof typeof state.filters] = [];
      state.filterBankEntries = state.filterBankEntries.filter(
        (bankEntry: FilterBankEntry) =>
          bankEntry.selectedOptionsList !== selectedOptionsName
      );

      if (state.filterBankEntries.length === 0) {
        state.isFiltered = false;
      }
    },
    clearOptionsByTypeReducer: (state, action: PayloadAction<string>) => {
      const availableOptionsListName = getAvailableOptionsMatchByKey(
        action.payload
      );

      state.filters[availableOptionsListName as keyof typeof state.filters] =
        [];
    },
    toggleEditButtonReducer: (state, action: PayloadAction<boolean>) => {
      state.enableEdit = action.payload;
    },
    toggleDeleteButtonReducer: (state, action: PayloadAction<boolean>) => {
      state.enableDelete = action.payload;
    },
    selectDeviceReducer: (state, action: PayloadAction<Asset>) => {
      state.selectedDevice = action.payload;
    },
    deselectDeviceReducer: (state) => {
      state.selectedDevice = undefined;
    },
    openEditActionModalReducer: (state) => {
      state.assetActionModal = 'edit';
    },
    openDeleteActionModalReducer: (state) => {
      state.assetActionModal = 'delete';
    },
    closeActionModalReducer: (state) => {
      state.assetActionModal = null;
    },
    setOldFiltersReducer: (
      state,
      action: PayloadAction<{ term: string; value: string }>
    ) => {
      state.oldSearchTerm = action.payload.term;
      state.oldSearchValue = action.payload.value;
      state.filterBankEntries = initialAssetManagementState.filterBankEntries;
      state.filters = initialAssetManagementState.filters;
      state.isFiltered = initialAssetManagementState.isFiltered;
      state.filterOpen = initialAssetManagementState.filterOpen;

      state.enableEdit = initialAssetManagementState.enableEdit;
      state.enableDelete = initialAssetManagementState.enableDelete;
      state.selectedDevice = initialAssetManagementState.selectedDevice;
    },
    resetOldFiltersReducer: (state) => {
      state.oldSearchTerm = initialAssetManagementState.oldSearchTerm;
      state.oldSearchValue = initialAssetManagementState.oldSearchValue;
    },
    resetModalsReducer: (state) => {
      state.enableEdit = initialAssetManagementState.enableEdit;
      state.enableDelete = initialAssetManagementState.enableDelete;
      state.selectedDevice = initialAssetManagementState.selectedDevice;
      state.assetActionModal = initialAssetManagementState.assetActionModal;
    },
    resetFiltersReducer: (state) => {
      state.filterBankEntries = initialAssetManagementState.filterBankEntries;
      state.filters = initialAssetManagementState.filters;
      state.isFiltered = initialAssetManagementState.isFiltered;
    },
    resetReducer: () => initialAssetManagementState,
  },
});
