import React from 'react';
import { SVGProps } from './types';

const RepairDeviceSVCIcon = (props: SVGProps) => {
  const originalSize = 22;
  const colorFill = props?.fill ?? 'white';
  const strokeFill = props?.stroke ?? 'white';
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="path-1-outside-1_4382_898"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="21"
        height="21"
        fill="black"
      >
        <rect fill="white" width="21" height="21" />
        <path d="M19.5922 16.5955L10.6984 7.69843C11.1258 6.51367 11.0905 5.2113 10.5995 4.05142C10.1083 2.89154 9.19783 1.95972 8.04946 1.44196C6.9013 0.924212 5.59994 0.858973 4.40555 1.25886C4.35643 1.27558 4.31923 1.31675 4.30773 1.36734C4.29603 1.41813 4.3117 1.47122 4.3487 1.50759L7.83374 5.00766L5.00794 7.83346L1.50787 4.33044C1.4715 4.29324 1.41841 4.27777 1.36761 4.28948C1.31703 4.30097 1.27586 4.33797 1.25934 4.3873C0.858855 5.58206 0.924072 6.88406 1.44203 8.03265C1.95999 9.18145 2.89263 10.0923 4.05315 10.5831C5.21366 11.0739 6.51689 11.1086 7.70187 10.6801L16.5989 19.5916C16.9948 19.9909 17.5347 20.2143 18.0972 20.212C18.6591 20.212 19.1981 19.9888 19.5955 19.5916C19.9928 19.1943 20.2161 18.6552 20.2161 18.0934C20.2161 17.5313 19.9928 16.9925 19.5955 16.5951L19.5922 16.5955ZM19.3795 19.3792C19.0385 19.7199 18.5762 19.9114 18.094 19.9114C17.612 19.9114 17.1497 19.7199 16.8085 19.3792L7.84565 10.4163C7.80384 10.3752 7.74176 10.3624 7.68679 10.3833C6.59546 10.8053 5.38587 10.8049 4.29475 10.3821C3.20363 9.95922 2.30965 9.14444 1.78755 8.09726C1.26562 7.05007 1.15298 5.84567 1.47193 4.71988L4.90303 8.15098C4.93125 8.17941 4.9695 8.19529 5.00942 8.19529C5.04934 8.19529 5.08759 8.17941 5.11581 8.15098L8.15126 5.11243C8.17969 5.08442 8.19557 5.04617 8.19557 5.00624C8.19557 4.96611 8.17969 4.92786 8.15126 4.89985L4.72038 1.46875C5.13759 1.35128 5.5692 1.29171 6.00274 1.29192C7.2485 1.28753 8.44412 1.78166 9.32307 2.66434C9.9654 3.30875 10.4075 4.12521 10.5956 5.01545C10.7839 5.90569 10.7103 6.83125 10.3838 7.6805C10.3627 7.73547 10.3755 7.79755 10.4167 7.83936L19.3822 16.8081C19.7221 17.1495 19.9129 17.6118 19.9129 18.0936C19.9129 18.5753 19.7221 19.0378 19.3822 19.3791L19.3795 19.3792Z" />
      </mask>
      <path
        className="custom-fill"
        d="M19.5922 16.5955L10.6984 7.69843C11.1258 6.51367 11.0905 5.2113 10.5995 4.05142C10.1083 2.89154 9.19783 1.95972 8.04946 1.44196C6.9013 0.924212 5.59994 0.858973 4.40555 1.25886C4.35643 1.27558 4.31923 1.31675 4.30773 1.36734C4.29603 1.41813 4.3117 1.47122 4.3487 1.50759L7.83374 5.00766L5.00794 7.83346L1.50787 4.33044C1.4715 4.29324 1.41841 4.27777 1.36761 4.28948C1.31703 4.30097 1.27586 4.33797 1.25934 4.3873C0.858855 5.58206 0.924072 6.88406 1.44203 8.03265C1.95999 9.18145 2.89263 10.0923 4.05315 10.5831C5.21366 11.0739 6.51689 11.1086 7.70187 10.6801L16.5989 19.5916C16.9948 19.9909 17.5347 20.2143 18.0972 20.212C18.6591 20.212 19.1981 19.9888 19.5955 19.5916C19.9928 19.1943 20.2161 18.6552 20.2161 18.0934C20.2161 17.5313 19.9928 16.9925 19.5955 16.5951L19.5922 16.5955ZM19.3795 19.3792C19.0385 19.7199 18.5762 19.9114 18.094 19.9114C17.612 19.9114 17.1497 19.7199 16.8085 19.3792L7.84565 10.4163C7.80384 10.3752 7.74176 10.3624 7.68679 10.3833C6.59546 10.8053 5.38587 10.8049 4.29475 10.3821C3.20363 9.95922 2.30965 9.14444 1.78755 8.09726C1.26562 7.05007 1.15298 5.84567 1.47193 4.71988L4.90303 8.15098C4.93125 8.17941 4.9695 8.19529 5.00942 8.19529C5.04934 8.19529 5.08759 8.17941 5.11581 8.15098L8.15126 5.11243C8.17969 5.08442 8.19557 5.04617 8.19557 5.00624C8.19557 4.96611 8.17969 4.92786 8.15126 4.89985L4.72038 1.46875C5.13759 1.35128 5.5692 1.29171 6.00274 1.29192C7.2485 1.28753 8.44412 1.78166 9.32307 2.66434C9.9654 3.30875 10.4075 4.12521 10.5956 5.01545C10.7839 5.90569 10.7103 6.83125 10.3838 7.6805C10.3627 7.73547 10.3755 7.79755 10.4167 7.83936L19.3822 16.8081C19.7221 17.1495 19.9129 17.6118 19.9129 18.0936C19.9129 18.5753 19.7221 19.0378 19.3822 19.3791L19.3795 19.3792Z"
        fill={colorFill}
      />
      <path
        className="custom-stroke"
        d="M19.5922 16.5955L10.6984 7.69843C11.1258 6.51367 11.0905 5.2113 10.5995 4.05142C10.1083 2.89154 9.19783 1.95972 8.04946 1.44196C6.9013 0.924212 5.59994 0.858973 4.40555 1.25886C4.35643 1.27558 4.31923 1.31675 4.30773 1.36734C4.29603 1.41813 4.3117 1.47122 4.3487 1.50759L7.83374 5.00766L5.00794 7.83346L1.50787 4.33044C1.4715 4.29324 1.41841 4.27777 1.36761 4.28948C1.31703 4.30097 1.27586 4.33797 1.25934 4.3873C0.858855 5.58206 0.924072 6.88406 1.44203 8.03265C1.95999 9.18145 2.89263 10.0923 4.05315 10.5831C5.21366 11.0739 6.51689 11.1086 7.70187 10.6801L16.5989 19.5916C16.9948 19.9909 17.5347 20.2143 18.0972 20.212C18.6591 20.212 19.1981 19.9888 19.5955 19.5916C19.9928 19.1943 20.2161 18.6552 20.2161 18.0934C20.2161 17.5313 19.9928 16.9925 19.5955 16.5951L19.5922 16.5955ZM19.3795 19.3792C19.0385 19.7199 18.5762 19.9114 18.094 19.9114C17.612 19.9114 17.1497 19.7199 16.8085 19.3792L7.84565 10.4163C7.80384 10.3752 7.74176 10.3624 7.68679 10.3833C6.59546 10.8053 5.38587 10.8049 4.29475 10.3821C3.20363 9.95922 2.30965 9.14444 1.78755 8.09726C1.26562 7.05007 1.15298 5.84567 1.47193 4.71988L4.90303 8.15098C4.93125 8.17941 4.9695 8.19529 5.00942 8.19529C5.04934 8.19529 5.08759 8.17941 5.11581 8.15098L8.15126 5.11243C8.17969 5.08442 8.19557 5.04617 8.19557 5.00624C8.19557 4.96611 8.17969 4.92786 8.15126 4.89985L4.72038 1.46875C5.13759 1.35128 5.5692 1.29171 6.00274 1.29192C7.2485 1.28753 8.44412 1.78166 9.32307 2.66434C9.9654 3.30875 10.4075 4.12521 10.5956 5.01545C10.7839 5.90569 10.7103 6.83125 10.3838 7.6805C10.3627 7.73547 10.3755 7.79755 10.4167 7.83936L19.3822 16.8081C19.7221 17.1495 19.9129 17.6118 19.9129 18.0936C19.9129 18.5753 19.7221 19.0378 19.3822 19.3791L19.3795 19.3792Z"
        stroke={strokeFill}
        strokeWidth="0.8"
        mask="url(#path-1-outside-1_4382_898)"
      />
      <mask
        id="path-2-outside-2_4382_898"
        maskUnits="userSpaceOnUse"
        x="15.5215"
        y="15.5312"
        width="4"
        height="4"
        fill="black"
      >
        <rect fill="white" x="15.5215" y="15.5312" width="4" height="4" />
        <path d="M16.8616 16.8582C16.6438 17.0762 16.5213 17.3719 16.5215 17.6802C16.5215 17.9885 16.6442 18.2841 16.8622 18.5021C17.0802 18.7199 17.3758 18.8424 17.684 18.8424C17.9923 18.8424 18.2881 18.7199 18.5061 18.5021C18.7241 18.2841 18.8466 17.9885 18.8468 17.6802C18.8468 17.3719 18.7245 17.0762 18.5067 16.8582C18.2841 16.6481 17.99 16.5312 17.684 16.5312C17.3782 16.5312 17.084 16.6481 16.8616 16.8582ZM18.294 18.2935C18.1276 18.4476 17.9094 18.5333 17.6826 18.5333C17.456 18.5333 17.2376 18.4476 17.0714 18.2935C16.9099 18.1313 16.8194 17.912 16.8196 17.6832C16.82 17.4545 16.9109 17.2352 17.0727 17.0736C17.2347 16.9121 17.4539 16.8211 17.6826 16.8211C17.9113 16.8211 18.1307 16.9121 18.2925 17.0736C18.4543 17.2352 18.5454 17.4545 18.5456 17.6832C18.5458 17.9121 18.4553 18.1313 18.294 18.2935H18.294Z" />
      </mask>
      <path
        className="custom-fill"
        d="M16.8616 16.8582C16.6438 17.0762 16.5213 17.3719 16.5215 17.6802C16.5215 17.9885 16.6442 18.2841 16.8622 18.5021C17.0802 18.7199 17.3758 18.8424 17.684 18.8424C17.9923 18.8424 18.2881 18.7199 18.5061 18.5021C18.7241 18.2841 18.8466 17.9885 18.8468 17.6802C18.8468 17.3719 18.7245 17.0762 18.5067 16.8582C18.2841 16.6481 17.99 16.5312 17.684 16.5312C17.3782 16.5312 17.084 16.6481 16.8616 16.8582ZM18.294 18.2935C18.1276 18.4476 17.9094 18.5333 17.6826 18.5333C17.456 18.5333 17.2376 18.4476 17.0714 18.2935C16.9099 18.1313 16.8194 17.912 16.8196 17.6832C16.82 17.4545 16.9109 17.2352 17.0727 17.0736C17.2347 16.9121 17.4539 16.8211 17.6826 16.8211C17.9113 16.8211 18.1307 16.9121 18.2925 17.0736C18.4543 17.2352 18.5454 17.4545 18.5456 17.6832C18.5458 17.9121 18.4553 18.1313 18.294 18.2935H18.294Z"
        fill={colorFill}
      />
      <path
        className="custom-stroke"
        d="M16.8616 16.8582C16.6438 17.0762 16.5213 17.3719 16.5215 17.6802C16.5215 17.9885 16.6442 18.2841 16.8622 18.5021C17.0802 18.7199 17.3758 18.8424 17.684 18.8424C17.9923 18.8424 18.2881 18.7199 18.5061 18.5021C18.7241 18.2841 18.8466 17.9885 18.8468 17.6802C18.8468 17.3719 18.7245 17.0762 18.5067 16.8582C18.2841 16.6481 17.99 16.5312 17.684 16.5312C17.3782 16.5312 17.084 16.6481 16.8616 16.8582ZM18.294 18.2935C18.1276 18.4476 17.9094 18.5333 17.6826 18.5333C17.456 18.5333 17.2376 18.4476 17.0714 18.2935C16.9099 18.1313 16.8194 17.912 16.8196 17.6832C16.82 17.4545 16.9109 17.2352 17.0727 17.0736C17.2347 16.9121 17.4539 16.8211 17.6826 16.8211C17.9113 16.8211 18.1307 16.9121 18.2925 17.0736C18.4543 17.2352 18.5454 17.4545 18.5456 17.6832C18.5458 17.9121 18.4553 18.1313 18.294 18.2935H18.294Z"
        stroke={strokeFill}
        strokeWidth="0.2"
        mask="url(#path-2-outside-2_4382_898)"
      />
    </svg>
  );
};

export default RepairDeviceSVCIcon;
