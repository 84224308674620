import { gql } from '@apollo/client';

export const RETRIEVE_COLLABORATOR_ADDRESSES = gql`
  query retrieveCollaborator($id: String!) {
    collaborator(id: $id) {
      id
      primaryAddress {
        id
      }
      workAddresses {
        id
        streetAddress1
        streetAddress2
        city
        state
        principalRegion
        country
        phoneNumber
        zipCode
      }
      homeAddresses {
        id
        streetAddress1
        streetAddress2
        city
        state
        principalRegion
        country
        phoneNumber
        zipCode
      }
    }
  }
`;
