/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Button, NewModal } from 'components';
import { ARE_YOU_SURE_TEXT, CHANGES_WONT_BE_SAVED, HEADER } from './constants';
import * as styles from './styles';
import { UnsavedChangesModalProps } from './types';

const UnsavedChangesModal = (props: UnsavedChangesModalProps) => {
  if (!props.isOpen) return null;

  const saveChangesCopy = props.saveButtonText || 'Save & Leave';

  return (
    <NewModal
      open={props.isOpen}
      handleClose={props.handleClose}
      paperPropsSx={styles.PaperSx}
    >
      <Stack
        direction="column"
        spacing={0}
        justifyContent="space-between"
        height="100%"
      >
        <Stack rowGap="1rem">
          <Typography variant="h4" sx={styles.VariantH4Sx}>
            {HEADER}
          </Typography>
          <Typography variant="body1">
            <div>{ARE_YOU_SURE_TEXT}</div>
            <div>{CHANGES_WONT_BE_SAVED}</div>
          </Typography>
        </Stack>
        <Stack sx={styles.ButtonGroupSx}>
          <Button
            gaContext={{
              textCopy: 'Cancel',
              navigates_to: 'N/A',
              purpose: 'Cancel discarding changes',
            }}
            variant="text"
            onClick={props.handleClose}
            sx={{ justifyContent: 'flex-end' }}
          >
            <Typography variant="button" sx={styles.CancelTextSx}>
              Cancel
            </Typography>
          </Button>
          <Button
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Disregard changes',
              purpose: 'Discards all unsaved changes',
            }}
            variant="text"
            onClick={props.handleDiscard}
          >
            <Typography variant="button" sx={styles.DisregardChangesTextSx}>
              Disregard Changes
            </Typography>
          </Button>
          <Button
            gaContext={{
              navigates_to: 'N/A',
              textCopy: saveChangesCopy,
              purpose: 'Saves changes and leaves',
            }}
            onClick={props.handleSaveAndLeave}
            variant="contained"
          >
            <Typography variant="button" sx={styles.BaseButtonTextSx}>
              {saveChangesCopy}
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </NewModal>
  );
};

export default UnsavedChangesModal;
