import { gql } from '@apollo/client';

export const DELETE_ORDER_ITEM = gql`
  mutation deleteOrderItem($id: String!) {
    deleteOrderItem(id: $id) {
      success
      message
    }
  }
`;
