import React from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { notificationSlice } from 'store/slices';
import { useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import NewModal from 'components/NewModal';
import { DetailedOrderViewProps } from './types';
import { Button } from 'components';
import { OrderStatus } from 'global-constants';
import { executeNowThenWait } from 'services/misc';
import {
  DetailedOrderPaperPropSx,
  OrderActionButtonContainerSx,
  OrderActionButtonGroupSx,
  TabsSx,
  SubmitOrderLoadingButtonSx,
} from './styles';
import { OrderDetailTab, AddOrderItemByEnum } from './enum';
import OrderOptions from './components/OrderOptions';
import OrderDetails from './components/OrderDetails';
import RecipientInfo from './components/RecipientInfo';
import OrderSummary from './components/OrderSummary';
import AddOrderItemModal from './components/AddOrderItemModal';
import OrderStatusDropdown from './components/OrderStatusDropdown';
import OrderStatusChangeModal from './components/OrderStatusChangeModal';
import OrderCustomerNotes from './components/OrderCustomerNotes';
import OrderStatusHistory from './components/OrderStatusHistory';
import { Order } from 'types';
import OrderNotifications from './components/OrderNotifications';
import SubmitToERPModal from './components/SubmitToERPModal';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { SEARCH_SHIPPING_TYPES } from 'pages/OrderManagement/queries';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import { FeatureFlagNames } from 'enums';
import GALoadingButton from 'components/buttons/google_analytics/GALoadingButton';

const DetailedOrderView = (props: DetailedOrderViewProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    hasAssets,
    hasProducts,
    toDepot,
    toRecipient,
    toDropRecipient,
    setOrganizationId,
    getPurchaser,
    purchasersSelectable: recipientCollaborators = [],
  } = props.createOrderState;

  const { isFlagOff: isPremiumShippingNotEnabled } = useFeatureFlagService(
    FeatureFlagNames.PREMIUM_SHIPPING,
    {
      debugFlags: true,
    }
  );

  const [detailTab, setDetailTab] = React.useState<OrderDetailTab>(
    OrderDetailTab.ORDER_DETAILS
  );
  const [categoryToAddOrderItemBy, setCategoryToAddOrderItemBy] =
    React.useState<AddOrderItemByEnum | undefined>();

  const [shippingTypeLookupById, setShippingTypeLookupById] = React.useState<
    undefined | Record<string, any>
  >();

  // Order Status state SECTION
  const [openChangeOrderStatusModal, setOpenChangeOrderStatusModal] =
    React.useState<boolean>(false);
  const [passedOrderStatus, setPassedOrderStatus] = React.useState<
    string | undefined
  >();

  const [orderStatusKey, setOrderStatusKey] = React.useState<string>('START');

  const promptOrderStatusChange = (newStatus: string) => {
    setPassedOrderStatus(newStatus);
    setOpenChangeOrderStatusModal(true);
  };

  useSafeQuery(SEARCH_SHIPPING_TYPES, {
    skip: isPremiumShippingNotEnabled(),
    onCompleted(data) {
      const shippingTypes = data?.searchShippingTypes?.shippingTypes ?? [];
      if (!shippingTypes.length) return;
      const shippingTypeLookup = shippingTypes.reduce(
        (acc: Record<string, any>, shippingType: any) => {
          acc[shippingType.id] = shippingType;
          return acc;
        },
        {}
      );

      setShippingTypeLookupById(shippingTypeLookup);
    },
  });

  const handleChangeOrderStatusModalClose = (updateStatusHistory = false) => {
    if (updateStatusHistory) {
      setOrderStatusKey(() => nanoid());
    }
    setOpenChangeOrderStatusModal(false);
  };

  // ERP Modal
  const [showERPModal, setShowERPModal] = React.useState<boolean>(false);

  const closeERPModal = () => {
    setShowERPModal(false);
  };

  const handleSubmitToERP = () => {
    setShowERPModal(true);
  };

  const closeAddOrderItemModal = () => {
    setCategoryToAddOrderItemBy(undefined);
  };

  const setAddOrderItemToProduct = () => {
    setCategoryToAddOrderItemBy(AddOrderItemByEnum.PRODUCT);
  };

  const setAddOrderItemToAsset = () => {
    setCategoryToAddOrderItemBy(AddOrderItemByEnum.ASSET);
  };

  const handleCloseCLick = () => {
    setDetailTab(OrderDetailTab.ORDER_DETAILS);
    props.handleClose();
  };

  const handleOnChange = (
    _event: React.SyntheticEvent,
    value: OrderDetailTab
  ) => {
    setDetailTab(value);
  };

  const showChangesSavedSuccessfully = () => {
    dispatch(
      notificationSlice.actions.setNotice({
        showNotice: true,
        noticeContent: 'Order has been submitted successfully.',
      })
    );
  };

  const closeModalAndRefetch = () => {
    props.handleClose(true);
  };

  React.useEffect(() => {
    if (!props?.order?.organizationObject?.id) return;
    setOrganizationId(props?.order?.organizationObject?.id);
  }, [props?.order?.organizationObject?.id]);

  React.useEffect(() => {
    setOrderStatusKey(() => nanoid());
  }, [passedOrderStatus]);

  const cancelOrderIsDisabled = () =>
    OrderStatus.CANCELLED === props?.order?.status;

  return (
    <>
      <NewModal
        open={props.open}
        handleClose={handleCloseCLick}
        paperPropsSx={DetailedOrderPaperPropSx}
        data-testid="detailed-order-view"
      >
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          sx={OrderActionButtonContainerSx}
        >
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseCLick}
          >
            <CloseIcon
              fontSize="large"
              htmlColor={theme.palette.colors.gray75}
            />
          </IconButton>
          <Stack direction="row" sx={OrderActionButtonGroupSx}>
            <Button
              gaContext={{
                navigates_to: 'N/A',
                textCopy: 'Cancel order',
                purpose: 'Cancels An Order',
              }}
              variant="text"
              color="secondary"
              size="small"
              disabled={cancelOrderIsDisabled()}
              onClick={props.openCancelOrderModal}
            >
              Cancel order
            </Button>
            <GALoadingButton
              gaContext={{
                navigates_to: 'N/A',
                textCopy: 'Send to Acumatica',
                purpose: 'Sends Order To Acumatica',
              }}
              variant="contained"
              type="submit"
              value="submit"
              color="primary"
              sx={SubmitOrderLoadingButtonSx}
              loadingIndicator={<CircularProgress color="primary" size={20} />}
              onClick={handleSubmitToERP}
            >
              <Typography variant="button">Send to Acumatica</Typography>
            </GALoadingButton>
          </Stack>
        </Stack>
        <OrderSummary order={props.order as Order} />
        <OrderOptions
          order={props.order as Order}
          disableAsset={!hasAssets}
          disableProduct={!hasProducts}
          setAddOrderItemToProduct={setAddOrderItemToProduct}
          setAddOrderItemToAsset={setAddOrderItemToAsset}
          {...(shippingTypeLookupById ? { shippingTypeLookupById } : {})}
          orderStatusComponent={
            <OrderStatusDropdown
              order={props.order}
              promptOrderStatusChange={promptOrderStatusChange}
            />
          }
        />
        <TabContext value={detailTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList sx={TabsSx} onChange={handleOnChange}>
              <Tab label="ORDER DETAILS" value={OrderDetailTab.ORDER_DETAILS} />
              <Tab
                label="RECIPIENT INFO"
                value={OrderDetailTab.RECIPIENT_INFO}
              />
              <Tab
                label="ORDER STATUS HISTORY"
                value={OrderDetailTab.ORDER_STATUS_HISTORY}
              />
              <Tab label="NOTIFICATIONS" value={OrderDetailTab.NOTIFICATIONS} />
              <Tab
                label="CUSTOMER NOTES"
                value={OrderDetailTab.CUSTOMER_NOTES}
              />
            </TabList>
          </Box>
          <TabPanel value={OrderDetailTab.ORDER_DETAILS} sx={{ pr: 0 }}>
            <OrderDetails order={props?.order} />
          </TabPanel>
          <TabPanel value={OrderDetailTab.RECIPIENT_INFO} sx={{ pl: 0 }}>
            <RecipientInfo
              orderId={props?.order?.id ?? ''}
              order={props.order as Order}
              dropRecipientId={props?.order?.dropRecipientId ?? ''}
              recipientCollaboratorId={props?.order?.recipient?.id ?? ''}
              dropRecipientCollaboratorId={
                props?.order?.dropRecipient?.id ?? ''
              }
              recipientId={props?.order?.recipient?.id ?? ''}
              depotId={props?.order?.depotId ?? ''}
              refetchOrders={props.refetchOrders}
              toDepot={toDepot}
              toRecipient={toRecipient}
              toDropRecipient={toDropRecipient}
              recipientCollaborators={recipientCollaborators}
              getCollaboratorById={getPurchaser}
            />
          </TabPanel>
          <TabPanel
            value={OrderDetailTab.ORDER_STATUS_HISTORY}
            sx={{ pl: 0 }}
            key={orderStatusKey}
          >
            <OrderStatusHistory order={props.order} />
          </TabPanel>
          <TabPanel value={OrderDetailTab.NOTIFICATIONS} sx={{ pl: 0 }}>
            <OrderNotifications order={props.order} />
          </TabPanel>
          <TabPanel value={OrderDetailTab.CUSTOMER_NOTES} sx={{ pl: 0 }}>
            <OrderCustomerNotes order={props.order as Order} />
          </TabPanel>
        </TabContext>
      </NewModal>
      <AddOrderItemModal
        categoryToAddOrderItemBy={categoryToAddOrderItemBy}
        closeModal={closeAddOrderItemModal}
        order={props.order as Order}
      />
      <OrderStatusChangeModal
        order={props.order as Order}
        open={openChangeOrderStatusModal}
        handleClose={handleChangeOrderStatusModalClose}
        newStatus={passedOrderStatus}
        updateSelectedOrder={props.updateSelectedOrder}
      />
      <SubmitToERPModal
        order={props.order}
        open={showERPModal}
        handleClose={closeERPModal}
      />
    </>
  );
};

export default DetailedOrderView;
