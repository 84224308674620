import { gql } from '@apollo/client';

/**
 * @summary Mutation to create a new (acumatica) DFI order
 */
export const CREATE_DFI_ORDER = gql`
  mutation createDeploymentOrder(
    $purchaserId: ID!
    $isRush: Boolean
    $assetId: ID!
    $collaboratorId: ID
    $recipient: CreateRecipientInput
    $insuranceRequested: String!
    $shippingTypeId: String
  ) {
    createDeploymentOrder(
      deploymentOrderCreateInput: {
        purchaserId: $purchaserId
        isRush: $isRush
        assetId: $assetId
        collaboratorId: $collaboratorId
        recipient: $recipient
        insuranceRequested: $insuranceRequested
        shippingTypeId: $shippingTypeId
      }
    ) {
      id
    }
  }
`;
