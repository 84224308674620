import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {
  AddEmployeeAddressModalProps,
  IAddEmployeeAddressFormValues,
} from './types';
import {
  AddressCancelButtonSx,
  AddressDialogPaperSx,
  AddressDialogSx,
  AddressMainTitleSx,
  AddressSaveButtonSx,
  AddressTextFieldBaseSx,
} from './styles';
import {
  COUNTRY_MATCHER,
  CountryOptions,
  StateOptions,
  UNITED_STATES,
  US_STATE_MATCHER,
} from 'global-constants';
import { MINIMUM_FIELDS_WITH_REQUIRED_VALUES } from './constant';
import { RetrievalAddressCreate } from 'pages/Retrieval/components/FlowStepThrough/types';

const AddEmployeeAddressModal = (props: AddEmployeeAddressModalProps) => {
  const {
    control,
    register,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IAddEmployeeAddressFormValues>({
    defaultValues: {
      country: COUNTRY_MATCHER[UNITED_STATES as string],
    },
    mode: 'onChange',
  });

  const enableState =
    watch()?.country === COUNTRY_MATCHER[UNITED_STATES as string];

  const resetFixedValues = () => {
    setValue('phoneNumber', '');
    setValue('state', '');
    setValue('principalRegion', '');
  };

  React.useEffect(() => {
    resetFixedValues();
  }, [enableState]);

  const resetForm = () => {
    reset();
    //Have to manually certain values
    resetFixedValues();
  };

  const handleCancel = () => {
    resetForm();
    props.closeModal();
  };

  const handleAddressSubmit = (formData: IAddEmployeeAddressFormValues) => {
    const shippingAddress: RetrievalAddressCreate = {
      firstName: props.firstName,
      lastName: props.lastName,
      streetAddress1: formData.streetLine1,
      streetAddress2: formData.streetLine2,
      city: formData.city,
      ...(enableState
        ? { state: formData.state }
        : {
            principalRegion: formData.principalRegion,
          }),
      country: formData.country,
      zipCode: formData.zipCode,
      phoneNumber: formData.phoneNumber,
    };
    props.addAddress(props.collaboratorId, shippingAddress);
    resetForm();
    props.closeModal();
  };

  const isMinimumFieldsWithValuesNotMet =
    watch([
      'streetLine1',
      'city',
      'state',
      'principalRegion',
      'country',
      'zipCode',
      'phoneNumber',
    ]).reduce((acc, value) => acc + !!value, 0) <
    MINIMUM_FIELDS_WITH_REQUIRED_VALUES;

  const isPhoneNumberNotValid = !!errors.phoneNumber;

  const shouldDisableForm =
    isMinimumFieldsWithValuesNotMet || isPhoneNumberNotValid;

  return (
    <Dialog
      open={props.open}
      sx={AddressDialogSx}
      PaperProps={{ sx: { ...AddressDialogPaperSx } }}
      onClose={() => props.closeModal()}
    >
      <form
        onSubmit={handleSubmit(handleAddressSubmit)}
        style={{ height: '100%' }}
      >
        <Stack
          direction="column"
          spacing={0}
          justifyContent="space-between"
          sx={AddressMainTitleSx}
        >
          <Stack direction="column">
            <Typography variant="h5" sx={AddressMainTitleSx}>
              Add {props.firstName} {props.lastName}'s address
            </Typography>
            <Typography>
              By providing the employee's address, we will not reach out to the
              employee. This order will begin the fulfillment process
              immediately.
            </Typography>
            <Grid container rowSpacing={2} columnSpacing={2} mt={1.75}>
              <Grid item xs={12}>
                <Controller
                  name="streetLine1"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="street-line-one"
                      label="Street address 1"
                      aria-required
                      required
                      sx={AddressTextFieldBaseSx}
                      {...field}
                      {...register('streetLine1', {
                        required: true,
                      })}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="streetLine2"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="street-line-two"
                      label="Street address 2"
                      sx={AddressTextFieldBaseSx}
                      {...field}
                      {...register('streetLine2')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="city"
                      label="City"
                      aria-required
                      required
                      sx={AddressTextFieldBaseSx}
                      {...field}
                      {...register('city', {
                        required: true,
                      })}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                {enableState ? (
                  <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        id="state"
                        label="State"
                        aria-required
                        required={enableState}
                        select
                        sx={AddressTextFieldBaseSx}
                        {...field}
                        {...register('state', {
                          required: enableState,
                        })}
                      >
                        {StateOptions.map((stateOption) => (
                          <MenuItem
                            key={stateOption}
                            value={US_STATE_MATCHER[stateOption as string]}
                          >
                            {stateOption}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                ) : (
                  <Controller
                    name="principalRegion"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        id="principalRegion"
                        label="Principal region"
                        aria-required
                        required={!enableState}
                        sx={AddressTextFieldBaseSx}
                        {...field}
                        {...register('principalRegion', {
                          required: !enableState,
                        })}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="zipCode"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="zipCode"
                      label="Postal code"
                      aria-required
                      required
                      sx={AddressTextFieldBaseSx}
                      {...field}
                      {...register('zipCode', {
                        required: true,
                      })}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="country"
                      label="Country"
                      aria-required
                      required
                      select
                      sx={AddressTextFieldBaseSx}
                      {...field}
                      {...register('country', {
                        required: true,
                      })}
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: 'center',
                            horizontal: 'right',
                          },
                        },
                      }}
                    >
                      {CountryOptions.map((countryOption) => (
                        <MenuItem
                          key={countryOption}
                          value={COUNTRY_MATCHER[countryOption as string]}
                        >
                          {countryOption}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{ validate: matchIsValidTel }}
                  render={({ field }) => (
                    <MuiTelInput
                      id="phoneNumber"
                      label="Phone number"
                      aria-required
                      required
                      sx={AddressTextFieldBaseSx}
                      {...field}
                      {...register('phoneNumber', {
                        required: true,
                      })}
                      onChange={(value, info) => field.onChange(value, info)}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Stack>
          <Stack direction="row" spacing="16px" justifyContent="flex-end">
            <Button
              color="secondary"
              onClick={handleCancel}
              size="small"
              sx={AddressCancelButtonSx}
              variant="text"
            >
              <Typography variant="inherit">CANCEL</Typography>
            </Button>
            <Button
              color="primary"
              size="small"
              sx={AddressSaveButtonSx}
              variant="contained"
              disabled={shouldDisableForm}
              value="submit"
              type="submit"
            >
              <Typography variant="inherit">SAVE</Typography>
            </Button>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  );
};

export default AddEmployeeAddressModal;
