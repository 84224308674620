import React from 'react';
import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const OrderDetailsInfoStackSx: SxProps = {
  gap: '4px',
  position: 'relative',
  width: '100%',
};

export const OrderDetailsInfoGridSx: SxProps = {
  alignSelf: 'stretch',
  backgroundColor: 'colors.grayFA',
  borderRadius: '10px',
  padding: '12px',
  width: '100%',
  rowGap: '12px',
};
