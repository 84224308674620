import { AssetStatusMapping } from 'pages/Assets/constants';
import { getAssetMgmtFilterFields } from './utils';

export enum AssetFiltersEnum {
  STATUS = 'Status',
  ORGANIZATION = 'Organization',
}

export const ASSET_FILTER_SETTINGS = {
  [AssetFiltersEnum.STATUS]: getAssetMgmtFilterFields(AssetFiltersEnum.STATUS),
  [AssetFiltersEnum.ORGANIZATION]: getAssetMgmtFilterFields(
    AssetFiltersEnum.ORGANIZATION,
    'organizationId'
  ),
};

export const ASSET_STATUS_OPTIONS = Object.values(AssetStatusMapping);
export const ASSET_STATUS_OPTION_VALUES = Object.keys(AssetStatusMapping);

export const ASSET_FILTER_WIDTH = '212px';
export const ASSET_FILTER_HEIGHT = '40px';
