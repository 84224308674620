import { SxProps } from '@mui/material/styles';
import theme from 'themes/theme';
import { BaseViewAllBtnTextStyles } from 'pages/HomeUpdate/styles';

export const HomePageSectionContainerSx: SxProps = {
  border: `1px solid ${theme.palette.colors.transparentGrey}`,
  borderRadius: '10px',
  boxSizing: 'border-box',
  mt: '24px',
  p: '16px 24px',

  '& .MuiTypography-root.MuiTypography-h6.section-title': {
    lineHeight: '22px',
  },
};

export const HomePageSectionViewAllBtnTextStyles: SxProps = {
  ...BaseViewAllBtnTextStyles,
};

export const HomePageStatCardRowGridSx: SxProps = {
  mt: '12px',
  width: '100%',
  flexWrap: 'wrap',
};

export const HomeOrdersCardSx: SxProps = {
  width: 'calc(calc(100% - 32px)/3)',
};

export const getHomePageStatCardGridContainerSx = (
  bgColor: string,
  iconFill: string
): SxProps => ({
  backgroundColor: bgColor,
  borderRadius: '10px',
  p: '8px 12px',
});

export { BaseViewAllBtnStyles } from 'pages/HomeUpdate/styles';
