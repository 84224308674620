import React from 'react';
import { Button as MuiButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ButtonProps } from './types';
import { useGAButtonClickHandler } from '../buttons/google_analytics/shared/hooks';

const StyledBaseButton = styled(MuiButton, {
  shouldForwardProp: (prop: any) => prop !== 'transparentBackgroundOnHover',
})<{ transparentBackgroundOnHover?: boolean }>(
  ({ transparentBackgroundOnHover }) => ({
    ':hover': {
      backgroundColor: transparentBackgroundOnHover ? 'transparent' : '',
    },
  })
);

const Button = (props: ButtonProps) => {
  const { gaContext, onClick, ...rest } = props;
  const handleClick = useGAButtonClickHandler({ gaContext, onClick });

  return (
    <StyledBaseButton disableRipple={true} {...rest} onClick={handleClick}>
      {props.children}
    </StyledBaseButton>
  );
};

export default Button;
