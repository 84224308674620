import { SxProps } from '@mui/material';

export const EmployeeAddressTextInputBaseSx: SxProps = {
  width: '100%',
  backgroundColor: 'transparent',
  p: 0,
  '&: .MuiFormHelperText-root': {
    color: 'transparent',
    position: 'absolute',
    bottom: '-20px',
  },
};
