/* eslint-disable security/detect-object-injection */
import React from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReviewStepProps } from './types';
import { getCollaboratorsAssetLookup } from './utils';
// WARNING: DO NOT just REMOVE the TEST ID
import {
  TEST_ID_STEP_COMPONENT_REVIEW,
  RetrievalAssetTypes,
} from 'pages/Retrieval/constants';
import RecipientCard from './components/RecipientCard';
import LoadingIndictor from 'components/LoadingIndicator';
import { ReviewStepModals } from './constants';
import { ReviewStepSx } from './styles';
import ReviewSearchFilter from './components/ReviewSearchFilter';
import AddAssetModal from './components/AddAssetModal';
import { useApolloClient } from '@apollo/client';
import { FETCH_RETRIEVAL_OPTIONS } from './components/RecipientAssetDetails/queries';
import AddEmployeeAddressModal from './components/AddEmployeeAddressModal';
import { reviewSearchFilterSlice } from './slice';
import { RetrievalContext } from 'context/RetrievalServiceProvider';
import AddRecipientAddressModal from 'components/AddRecipientAddressModal';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import { SelectableRecipientAddress } from 'types';
import { isAddressIncomplete } from 'services';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import { FeatureFlagNames } from 'enums';
import { set } from 'lodash';

const ReviewStep = (props: ReviewStepProps) => {
  const [reviewSearchFilterState, reviewSearchFilterDispatch] =
    React.useReducer(
      reviewSearchFilterSlice.reducer,
      reviewSearchFilterSlice.getInitialState()
    );

  const [loadingIndicatorOn, setLoadingIndicatorOn] =
    React.useState<boolean>(false);

  const [modal, setModal] = React.useState<ReviewStepModals>(
    ReviewStepModals.NONE
  );
  const [collaboratorId, setCollaboratorId] = React.useState<string>('');
  const [collaboratorFirstName, setCollaboratorFirstName] =
    React.useState<string>('');
  const [collaboratorLastName, setCollaboratorLastName] =
    React.useState<string>('');
  const [assetOptionsState, setAssetOptionsState] = React.useState<
    Record<string, any>
  >({});

  const { isFlagOff: isSelectRecipientAddressDisabled } = useFeatureFlagService(
    FeatureFlagNames.SELECT_RECIPIENT_ADDRESS
  );

  const [
    recipientAddressFormDataPrepopulate,
    setRecipientAddressFormDataPrepopulate,
  ] = React.useState<SelectableRecipientAddress>(
    {} as SelectableRecipientAddress
  );

  const onAddRecipientAddress = (address: SelectableRecipientAddress) => {
    const currentEmployeeShippingAddresses =
      props.retrievalFlow.selectedEmployees.find(
        (employee) => employee.id === collaboratorId
      )?.shippingAddressOptions || [];
    let newShippingAddressOptions;
    const existing = currentEmployeeShippingAddresses.findIndex(
      (existingAddress) => {
        return existingAddress.id === address.id;
      }
    );
    if (existing > -1) {
      newShippingAddressOptions = [
        ...currentEmployeeShippingAddresses.slice(0, existing),
        address,
        ...currentEmployeeShippingAddresses.slice(existing + 1),
      ];
    } else {
      newShippingAddressOptions = [
        ...currentEmployeeShippingAddresses,
        address,
      ];
    }
    props.retrievalFlow.addShippingAddressOptionsToEmployee(
      collaboratorId,
      newShippingAddressOptions
    );
    props.retrievalFlow.addShippingAddressToEmployee(collaboratorId, address);
  };

  const onChangeSelectedRecipientAddress = (
    collaboratorId: string,
    address: SelectableRecipientAddress
  ) => {
    if (!address.id) {
      props.retrievalFlow.removeShippingAddressFromEmployee(collaboratorId);
      return;
    }
    props.retrievalFlow.addShippingAddressToEmployee(collaboratorId, address);
    if (isAddressIncomplete(address)) {
      openAddAddressModal('', '', collaboratorId, address);
    }
  };

  const organization = useGetUserOrganization();

  const client = useApolloClient();

  const assetTypes = Object.values(RetrievalAssetTypes);

  const allRetrievalAssets = props.retrievalFlow.selectedEmployees.flatMap(
    (employee) => employee.assets
  );

  const fetchAllOptionValues = async () => {
    setLoadingIndicatorOn(true);
    const promises = assetTypes.map(async (assetName) => {
      const variables = {
        assetTypeName:
          RetrievalAssetTypes[assetName as keyof typeof RetrievalAssetTypes],
      };

      const { data } = await client.query({
        query: FETCH_RETRIEVAL_OPTIONS,
        variables,
      });

      return data.retrievalOptions;
    });

    // eslint-disable-next-line sonarjs/prefer-immediate-return
    const allOptionValuesData = await Promise.all(promises);

    setAssetOptionsState(() => {
      return allOptionValuesData.reduce((acc, next, index) => {
        acc[assetTypes[index]] = next;
        return acc;
      }, {} as any);
    });
    setLoadingIndicatorOn(false);
  };

  React.useEffect(() => {
    if (Object.values(assetOptionsState).length >= assetTypes.length) return;

    fetchAllOptionValues();
  }, [assetOptionsState, assetTypes.length]);

  const closeModal = () => setModal(ReviewStepModals.NONE);

  const openAddAssetModal = (
    firstName: string,
    lastName: string,
    id: string
  ) => {
    setCollaboratorId(id);
    setCollaboratorFirstName(firstName);
    setCollaboratorLastName(lastName);
    setModal(ReviewStepModals.ADD_ASSET);
  };

  const openAddAddressModal = (
    firstName: string,
    lastName: string,
    id: string,
    addressPrepopulate: SelectableRecipientAddress = {} as SelectableRecipientAddress
  ) => {
    setCollaboratorId(id);
    setCollaboratorFirstName(firstName);
    setCollaboratorLastName(lastName);
    setRecipientAddressFormDataPrepopulate(addressPrepopulate);
    setModal(ReviewStepModals.ADD_ADDRESS);
  };

  const onCancelAddRecipientAddress = () => {
    if (
      isAddressIncomplete(
        props.retrievalFlow.selectedEmployees.find(
          (employee) => employee.id === collaboratorId
        )?.shippingAddress
      )
    ) {
      props.retrievalFlow.removeShippingAddressFromEmployee(collaboratorId);
      setRecipientAddressFormDataPrepopulate({} as SelectableRecipientAddress);
    }
  };

  const setFilter = (assetType: RetrievalAssetTypes, checked: boolean) =>
    reviewSearchFilterDispatch(
      reviewSearchFilterSlice.actions.changeFilter({ assetType, checked })
    );

  const setSearchTerm = (term: string) =>
    reviewSearchFilterDispatch(
      reviewSearchFilterSlice.actions.searchByName(term)
    );

  const clearSearch = () =>
    reviewSearchFilterDispatch(
      reviewSearchFilterSlice.actions.clearSearchByName()
    );

  const toggleAsset = (
    employeeId: string,
    assetId: string,
    selected: boolean
  ) =>
    props.retrievalFlow.toggleGlobalAssetSelection(
      employeeId,
      assetId,
      selected
    );

  const collaboratorsAssetLookup = getCollaboratorsAssetLookup(
    props.retrievalFlow.selectedEmployees
  );

  const reviewFilterEntries = Object.entries(
    reviewSearchFilterState.filterByLookup
  );

  const filteredRetrievalAssetTypes = reviewFilterEntries
    .slice()
    .filter(([key, value]) => value)
    .map(([filterKey]) => filterKey);

  const filteredEmployees = props.retrievalFlow.selectedEmployees
    .slice()
    .filter((employee) => {
      const ignoreCaseSearch = (
        reviewSearchFilterState.searchTerm ?? ''
      ).toLowerCase();
      const { firstName, lastName } = employee;
      return (
        firstName.toLowerCase().includes(ignoreCaseSearch) ||
        lastName.toLowerCase().includes(ignoreCaseSearch)
      );
    });

  const memoRecipients = React.useMemo(() => {
    if (filteredRetrievalAssetTypes.length) {
      return filteredEmployees.slice().filter((employee) => {
        return filteredRetrievalAssetTypes.some(
          (assetType) => !!collaboratorsAssetLookup[employee.id][assetType]
        );
      });
    }
    return filteredEmployees;
  }, [filteredEmployees, filteredRetrievalAssetTypes]);

  const hasSoloRecipient = memoRecipients.length === 1;

  const openAssetModal = modal === ReviewStepModals.ADD_ASSET;
  const openAddressModal = modal === ReviewStepModals.ADD_ADDRESS;

  React.useEffect(() => {
    if (!props.retrievalFlow.selectedEmployees.length) {
      props.retrievalFlow.gotoEmployeeStep();
    }
  }, [props.retrievalFlow.selectedEmployees.length]);

  return (
    <>
      <Grid
        container
        data-testid={TEST_ID_STEP_COMPONENT_REVIEW}
        sx={ReviewStepSx}
      >
        <Grid item xs={12} id="review-step-title-block">
          <Typography id="review-step-title-copy">
            Review selections below
          </Typography>
          <Typography id="review-step-subtitle-copy">
            Please open the employee's card to review and update your
            selections. If a device is MDM locked, please provide the MDM pin.
            Uncheck a device you don't want to retrieve from an employee.
          </Typography>
          <Divider id="review-step-divider" />
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction={{ md: 'column', lg: 'row' }}
            spacing={0}
            id="review-step-content-block"
          >
            <Stack direction="column" id="review-step-search-section">
              <ReviewSearchFilter
                searchFilterState={reviewSearchFilterState}
                setFilter={setFilter}
                setSearchTerm={setSearchTerm}
                clearSearch={clearSearch}
                setLoading={setLoadingIndicatorOn}
              />
            </Stack>
            <Stack direction="column" spacing="28px" sx={{ width: '100%' }}>
              {loadingIndicatorOn ? (
                <LoadingIndictor loading={loadingIndicatorOn} />
              ) : (
                <>
                  {memoRecipients.map((info) => (
                    <RecipientCard
                      key={`${info.firstName}-${info.lastName}`}
                      recipientInfo={info}
                      answerRetrievalOption={
                        props.retrievalFlow.answerRetrievalOptions
                      }
                      openAddAssetModal={openAddAssetModal}
                      openAddAddressModal={openAddAddressModal}
                      toggleAsset={toggleAsset}
                      removeShippingAddressFromEmployee={
                        props.retrievalFlow.removeShippingAddressFromEmployee
                      }
                      setRecipientShipping={
                        props.retrievalFlow.setEmployeeShipping
                      }
                      setRecipientEmail={props.retrievalFlow.setEmployeeEmail}
                      removeSelectedEmployee={
                        props.retrievalFlow.removeEmployee
                      }
                      setRecipientAddress={onChangeSelectedRecipientAddress}
                      retrievalOptionsByType={
                        props.retrievalFlow.assetOptionsByType
                      }
                      retrievalOptionsAnswerKey={
                        props.retrievalFlow.assetsOptionAnswerKey
                      }
                    />
                  ))}
                  {hasSoloRecipient && <div style={{ height: '28px' }} />}
                </>
              )}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <AddAssetModal
        addAsset={props.retrievalFlow.addAssetToEmployee}
        open={openAssetModal}
        closeModal={closeModal}
        firstName={collaboratorFirstName}
        lastName={collaboratorLastName}
        collaboratorId={collaboratorId}
        optionAnswersByType={assetOptionsState}
        allRetrievalAssets={allRetrievalAssets}
      />
      {isSelectRecipientAddressDisabled() ? (
        <AddEmployeeAddressModal
          addAddress={props.retrievalFlow.addShippingAddressToEmployee}
          open={openAddressModal}
          closeModal={closeModal}
          firstName={collaboratorFirstName}
          lastName={collaboratorLastName}
          collaboratorId={collaboratorId}
        />
      ) : (
        <AddRecipientAddressModal
          addAddress={onAddRecipientAddress}
          cancelAddAddress={onCancelAddRecipientAddress}
          open={openAddressModal}
          closeModal={closeModal}
          collaboratorId={collaboratorId}
          address={recipientAddressFormDataPrepopulate}
          addAddressToCollaborator={!organization?.hrisEmployeeDataSyncedAt}
        />
      )}
      {/* {loadingIndicatorOn ? <LoadingIndictor loading={loading} /> : null} */}
    </>
  );
};

export const ReviewStepWrapped = () => {
  const retrievalFlow = React.useContext(RetrievalContext);

  return <ReviewStep retrievalFlow={retrievalFlow} />;
};

export default ReviewStep;
