import { gql } from '@apollo/client';

export const CREATE_UNLOCK_DEVICE_ORDER = gql`
  mutation createDeviceUnlockOrder(
    $assetId: ID!
    $isRush: Boolean
    $purchaserId: ID!
    $checkoutNotes: String
    $mdmPin: String!
  ) {
    createDeviceUnlockOrder(
      deviceUnlockOrderCreateInput: {
        assetId: $assetId
        isRush: $isRush
        purchaserId: $purchaserId
        checkoutNotes: $checkoutNotes
        mdmPin: $mdmPin
      }
    ) {
      id
      orderNumber
    }
  }
`;
