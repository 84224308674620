import Stack from '@mui/material/Stack';

import { UploadErrorDetailsProps } from './types';

import { UploadErrorsContainerSx } from './styles';
import DetailsTable from '../table/DetailsTable';
import DetailsHeader from '../actions/DetailsHeader';

function UploadErrorDetails(props: UploadErrorDetailsProps) {
  return (
    <Stack sx={UploadErrorsContainerSx}>
      <DetailsHeader {...props} />
      <DetailsTable selectedUpload={props.selectedUpload} />
    </Stack>
  );
}

export default UploadErrorDetails;
