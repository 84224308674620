import theme from 'themes/theme';
import { SxProps } from '@mui/material';
import { HEADER_HEIGHT } from 'global-styles';

export const AMDashDataGridBoxSx: SxProps = {
  height: 'calc(100vh - 100px)',
  marginTop: 0,
};

export const AMDashContainerSx: SxProps = {
  position: 'relative',
  mt: HEADER_HEIGHT,
  '@media (min-width: 1536px)': {
    maxWidth: '100%',
    width: '85%',
  },
};

export const MainHeaderSx: SxProps = {
  fontSize: '36px',
  fontWeight: 500,
};

// Complex filter styles START
export const FilterButtonBoxSx: SxProps = {
  position: 'relative',
};

export const getFilterButtonSx = (
  isFilterOpen: boolean,
  filterCount: number
): SxProps => ({
  backgroundColor: isFilterOpen ? '#1314141F' : 'transparent',
  borderRadius: '30px',
  fontWeight: 600,
  maxHeight: '40px',
  minWidth: filterCount > 0 ? '115px' : '70px',
  position: 'relative',
  px: '12px',
  py: '6px',
  width: '94px',
  '&: hover': {
    backgroundColor: '#1314141F',
    cursor: 'pointer',
  },
});

export const FilterPopoverSx: SxProps = {
  minWidth: '630px',
};

export const getFilterBtnCountIconSx = (
  activeFilterCount: number
): SxProps => ({
  backgroundColor: theme.palette.colors.woodSmoke,
  display: activeFilterCount > 0 ? 'block' : 'none',
  height: '20px',
  position: 'relative',
  top: '1px',
  width: '20px',

  '& .MuiTypography-root': {
    fontSize: '12px',
  },
});
