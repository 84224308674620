/* eslint-disable prefer-destructuring */
import React from 'react';
import { AssetNoteStates } from './enums';
import { AssetNoteProps } from './types';
import { Stack, TextField } from '@mui/material';
import * as styles from './styles';
import { Add, Edit } from '@mui/icons-material';
import GAButton from 'components/buttons/google_analytics/GAButton';
import UnsavedChangesModal from 'components/NewModal/UnsavedChangesModal';

export default function AssetNote(props: AssetNoteProps) {
  const [isAssetNoteTextFieldVisible, setAssetNoteTextFieldVisible] =
    React.useState(false);

  const [noteForm, setNoteForm] = React.useState<AssetNoteStates>(
    props.customerNote ? AssetNoteStates.HAS_NOTE : AssetNoteStates.EMPTY_NOTE
  );

  const [isUpdateNoteClicked, setIsUpdateNoteClicked] = React.useState(false);
  const [isCancelConfirmationOpen, setIsCancelConfirmationOpen] =
    React.useState(false);

  // If user deletes note and saves
  React.useEffect(() => {
    if (props.customerNote) return;

    setNoteForm(() =>
      props.originalCustomerNote
        ? AssetNoteStates.EDIT_NOTE
        : AssetNoteStates.EMPTY_NOTE
    );
  }, [props.customerNote]);

  const canAddNote = () => AssetNoteStates.EMPTY_NOTE === noteForm;
  const canUpdateNote = () => AssetNoteStates.HAS_NOTE === noteForm;
  const isEditingNote = () => AssetNoteStates.EDIT_NOTE === noteForm;

  const isNoteDisabled = () => {
    return !isEditingNote();
  };

  const handleUpdateButtonClick = () => {
    setIsUpdateNoteClicked(true);
  };

  const handleClick = () => {
    setNoteForm(AssetNoteStates.EDIT_NOTE);
    setAssetNoteTextFieldVisible(true);
    if (canUpdateNote()) {
      handleUpdateButtonClick();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    props.setCustomerNote(value);
  };

  const handleSave = () => {
    if (props.originalCustomerNote === props.customerNote && props.customerNote)
      return;

    props.submitCustomerNote(props.customerNote);
    setNoteForm(AssetNoteStates.HAS_NOTE);
    setAssetNoteTextFieldVisible(false);
    setIsUpdateNoteClicked(false);
    setIsCancelConfirmationOpen(false);
  };

  const handleOnDiscardUnsavedChanges = () => {
    setIsCancelConfirmationOpen(false);

    // Reset the note form to its original state
    if (props.originalCustomerNote) {
      props.setCustomerNote(props.originalCustomerNote);
      setNoteForm(AssetNoteStates.HAS_NOTE);
    } else {
      props.setCustomerNote(''); // Assuming an empty string is the default state
    }

    // Close any open editing interfaces
    setAssetNoteTextFieldVisible(false);
    setIsUpdateNoteClicked(false);
  };

  const handleCancel = () => {
    setIsCancelConfirmationOpen(true);
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        className={'assetNoteSpanField'}
        sx={styles.AssetNoteSpanField}
      >
        <span>Asset Note</span>
        {!isAssetNoteTextFieldVisible && (
          <GAButton
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Add/Update Note',
              purpose: 'Adds/Updates asset note',
            }}
            onClick={handleClick}
            startIcon={canAddNote() ? <Add /> : <Edit />}
            sx={styles.AddNoteUpdateButton}
          >
            {canAddNote() || props.customerNote === ''
              ? 'Add Note'
              : 'Update Note'}
          </GAButton>
        )}
      </Stack>

      {isAssetNoteTextFieldVisible ||
      (canUpdateNote() && props.customerNote) ? (
        <>
          <Stack>
            <TextField
              label="Note"
              variant="outlined"
              fullWidth
              margin="normal"
              value={props.customerNote}
              onChange={handleChange}
              disabled={isNoteDisabled()}
              sx={styles.TextField}
              multiline
            />

            {!isNoteDisabled() && (
              <Stack direction="row" justifyContent="flex-end">
                <GAButton
                  gaContext={{
                    textCopy: 'Cancel',
                    navigates_to: 'N/A',
                    purpose: 'Cancels customer note modification',
                  }}
                  onClick={handleCancel}
                  sx={styles.CancelButton}
                  className="cancelButton"
                >
                  Cancel
                </GAButton>
                <GAButton
                  gaContext={{
                    textCopy: 'Save',
                    navigates_to: 'N/A',
                    purpose: 'Saves customer note modification',
                  }}
                  onClick={handleSave}
                  sx={styles.SaveButton}
                  className="saveButton"
                >
                  Save
                </GAButton>
              </Stack>
            )}
          </Stack>

          {isCancelConfirmationOpen && (
            <UnsavedChangesModal
              isOpen={isCancelConfirmationOpen}
              handleClose={() => setIsCancelConfirmationOpen(false)}
              handleSaveAndLeave={handleSave}
              handleDiscard={handleOnDiscardUnsavedChanges}
              saveButtonText="Save"
            />
          )}
        </>
      ) : null}
    </>
  );
}
