import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const getAddOrderItemSKUInputSx = (width: string = '100%'): SxProps => ({
  width,
  backgroundColor: 'transparent',
  p: 0,
  '&: .MuiFormHelperText-root': {
    color: 'transparent',
    position: 'absolute',
    bottom: '-20px',
  },
});

export const AddOrderItemQuantityInputSx: SxProps = {
  alignItems: 'flex-start',
  ml: '16px',
  '& > input': { borderBottom: '1px solid', pl: '8px' },
};

export const AddOrderItemQualityGridSx: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  width: '300px',
  justifyContent: 'space-between',
};

export const AddOrderItemSliderSx: SxProps = {
  maxWidth: '200px',
};

export const AddOrderItemCancelButtonGroupSx: SxProps = {
  borderColor: 'transparent',
  borderRadius: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  mr: '24px',

  '& .MuiButtonGroup-grouped.MuiButtonBase-root': {
    borderColor: 'transparent',
    borderRadius: 0,
    color: theme.palette.colors.black,
    fontWeight: 600,
    px: '16px',

    '&:first-child:hover': {
      borderColor: 'transparent',
    },

    '&:last-child': {
      borderRadius: '30px',
      width: '100px',
      ml: '8px',
      '&:hover': {
        backgroundColor: theme.palette.colors.mustardY,
      },
    },
  },
};
