import React from 'react';
import { ProcurementFlowStep } from 'pages/Procurement/hooks/useProcurementService/constants';
import { Button, Footer } from 'components';
import { SelectedCollaboratorBarProps } from './types';
import { Container, Typography } from '@mui/material';

const SelectedCollaboratorBar = (props: SelectedCollaboratorBarProps) => {
  const constructEmployeeSelectedText = () => {
    const numberofSelectedCollaborators = props.selectedCollaborators.length;

    if (!numberofSelectedCollaborators) return '';
    if (numberofSelectedCollaborators === 1) return '1 employee selected';
    return `${numberofSelectedCollaborators} employees selected`;
  };

  const employeeSelectedText = constructEmployeeSelectedText();

  const onClickCancel = () => {
    props.deselectAllCollaborators();
  };

  const onClickAllocate = () => {
    props.updateProcurementFlowStep(ProcurementFlowStep.PRODUCT_CATALOG);
  };

  return (
    <Footer>
      <Container>
        <Typography variant="caption" color="colors.offWhite">
          {employeeSelectedText}
        </Typography>
      </Container>
      <Button
        gaContext={{
          navigates_to: 'N/A',
          textCopy: 'Cancel',
          purpose: 'Cancels Collaborator Selection',
        }}
        variant="outlined"
        onClick={() => onClickCancel()}
      >
        <Typography variant="button">Cancel</Typography>
      </Button>
      <Button
        gaContext={{
          navigates_to: 'N/A',
          textCopy: 'Continue to marketplace',
          purpose: 'Continues To Marketplace',
        }}
        variant="contained"
        onClick={() => onClickAllocate()}
        sx={{
          minWidth: '186px',
          '& .MuiTypography-root': {
            position: 'absolute',
          },
        }}
      >
        <Typography
          variant="button"
          sx={{ width: 'max-content', lineHeight: 1 }}
        >
          Continue to marketplace
        </Typography>
      </Button>
    </Footer>
  );
};

export default SelectedCollaboratorBar;
