import { SxProps } from '@mui/material';
import {
  MAIN_STACK_SIZE_SM,
  MAIN_STACK_SIZE_MD,
} from 'components/NewModal/constants';

export const SubmitToERPModalPaperSx: SxProps = {
  height: '245px',
  width: '545px',
};

export const SubmitToERPModalMainStackSx: SxProps = {
  height: {
    xs: MAIN_STACK_SIZE_SM,
    sm: MAIN_STACK_SIZE_SM,
    md: MAIN_STACK_SIZE_MD,
    lg: MAIN_STACK_SIZE_MD,
    xl: MAIN_STACK_SIZE_MD,
  },
};

export const SubmitToERPModalButtonBaseSx: SxProps = {
  padding: '6px 16px',
  width: 'fit-content',
  height: '36px',
  '& .MuiTypography-root': {
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '24px',
  },
  '&#close-submit-order-erp-button': {
    mr: '16px',
  },
  '&#submit-order-erp-button': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

export const TitleSx: SxProps = {
  fontFamily: 'Inter',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '24px',
};

export const SubtitleSx: SxProps = {
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  marginTop: '8px',
};
