import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const ButtonGroupSx: SxProps = {
  '& > span': {
    marginLeft: '0.91rem',
  },
};

export const ActionIconButtonSx: SxProps = {
  backgroundColor: 'colors.maizeYO',
  width: '48px',
  height: '48px',
  ml: 0,
  '&.delete-button': {
    ml: '0.91rem',
  },
  '& svg': {
    width: '100%',
    height: '100%',
  },
  ':disabled': {
    backgroundColor: 'colors.wildSandWN',
    '& .custom-stroke': {
      stroke: '#C0C0C0',
    },
    '& .custom-fill': {
      fill: '#C0C0C0',
    },
  },
};
