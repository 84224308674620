import { POPOVER_MAX_WIDTH_PAPER } from '../UploadPopover/styles';
import theme from 'themes/theme';

export const UploadFileInputContainerSx = {
  display: 'flex',
  width: POPOVER_MAX_WIDTH_PAPER,
  height: '306px',
  overflow: 'auto',
  px: '24px',
};

export const UploadFileInputHeaderSx = {
  width: 'auto',
  height: '46px',
  gap: '4px',
  mt: '16px',
};

export const UploadFileInputContentSx = {
  width: 'auto',
  border: '1px solid #E0E0E0',
  borderRadius: '4px',
  gap: '8px',
  my: '16px',
  display: 'flex',
  alignItems: 'center',
  py: '8px',
};

export const UploadFileTemplateLinkSx = {
  color: theme.palette.colors.gray60,
  fontWeight: '600',
  cursor: 'pointer',
};

export const UploadFileSelectedFileInfoSx = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  px: '8px',
};

export const ButtonContainerSx = {
  border: '1px solid rgba(19, 20, 20, 0.50)',
  borderRadius: '30px',
  mt: '8px',
  width: '109px',
  height: '36px',
};

export const UploadFileInputButtonSx = {
  ...ButtonContainerSx,

  '.file-upload-button-text': {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    position: 'absolute',
  },
};

export const UploadFileImportButtonSx = {
  ...ButtonContainerSx,

  border: 'none',

  '.import-button-text': {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    position: 'absolute',
    width: '100%',
  },

  '&.MuiButton-root': {
    backgroundColor: theme.palette.colors.maizeYO,
  },

  '&.Mui-disabled.MuiButton-root': {
    border: 'none',
    backgroundColor: theme.palette.colors.grayBD,
  },
};

export const UploadFileImportButtonContainerSx = {
  width: 'auto',
  display: 'flex',
  justifyContent: 'right',
  pb: '35px',
  borderBottom: '1px solid #E0E0E0',
};

export const CloseIconButtonSx = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
};

export const FileInputSx = {
  display: 'none',
};
