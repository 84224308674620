import React from 'react';
import { SxProps } from '@mui/material';
import theme from 'themes/theme';
import { DRAWER_WIDTH, HEADER_HEIGHT } from 'global-styles';
import {
  SEARCH_BAR_EXTERNAL_RADIUS,
  SEARCH_BAR_TYPE_SELECT_WIDTH,
  SEARCH_BAR_WHOLE_WIDTH,
  SEARCH_BAR_WHOLE_HEIGHT,
} from './constants';

export const ContentDivSx: SxProps = {
  margin: 'auto',
  mt: HEADER_HEIGHT,
  position: 'relative',
  '@media (max-width: 1199px)': {
    left: '0',
  },
  '@media (min-width: 1200px)': {
    left: `${DRAWER_WIDTH - DRAWER_WIDTH / 2}px`,
    width: '80%',
  },
  '@media (min-width: 1536px)': {
    left: `${DRAWER_WIDTH - DRAWER_WIDTH / 2}px`,
    maxWidth: '100%',
    width: '85%',
  },
  '& .order-view-title': {
    fontSize: '36px',
    fontWeight: 500,
    lineHeight: '41.04px',
  },
};

export const MainStackSx: SxProps = {
  marginTop: '1.35rem',
};

export const OrderFollowUpBannerBoxSx: SxProps = {
  border: `1px solid ${theme.palette.colors.woodSmoke}`,
  borderRadius: '20px',
  mb: '24px',
  p: '16px 24px',
  position: 'relative',
  width: '100%',
};

export const BannerInnerStackSx: SxProps = {
  display: 'inline-flex',
  fontFamily: 'PP Pangram Sans',
  minHeight: '45px',
  position: 'relative',
  width: 'calc(100% - 32px)',
  '& .image-stack': {
    display: 'inline-flex',
    position: 'absolute',
    width: '45px',
    left: 0,
  },
  '& .copy-stack': {
    justifySelf: 'center',
    color: theme.palette.colors.woodSmoke,
    pl: '60px',
    '& .MuiLink-root': {
      color: 'inherit',
      fontWeight: 600,
      textDecorationColor: 'inherit',
    },
  },
};

export const BannerImageStyle: React.CSSProperties = {
  width: '100%',
};

export const BannerCloseButtonSx: SxProps = {
  position: 'absolute',
  right: '4px',
  top: '8px',
};

export const getDataGridBoxSx = (bannerShown: boolean = false): SxProps => {
  return {
    height: `calc(100vh - ${bannerShown ? 500 : 300}px)`,
    marginTop: '0.75rem',
  };
};

export const DataGridBoxSx: SxProps = {
  height: 'calc(100vh - 300px)',
  marginTop: '0.75rem',
};

export const SearchSwitchSx: SxProps = {
  ml: 1,
  top: '5px',
  '& .MuiSwitch-switchBase:not(.Mui-checked)+.MuiSwitch-track': {
    backgroundColor: theme.palette.colors.jungleG,
    opacity: 0.8,
  },
};
/*  AutoComplete Search Bar styling BELOW */

export const AutoCompleteStackSx: SxProps = {
  backgroundColor: theme.palette.colors.grayF6,
  borderRadius: SEARCH_BAR_EXTERNAL_RADIUS,
  width: SEARCH_BAR_WHOLE_WIDTH,
  height: SEARCH_BAR_WHOLE_HEIGHT,
  position: 'relative',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
  },
  '&:focus': {
    border: '2px solid',
  },

  // only need if the stack contains a searchIcon
  '& hr:first-of-type': {
    display: 'none',
  },
};

export const AutoCompleteSx: SxProps = {
  p: 0,
  backgroundColor: 'transparent',
  border: 'none',
  height: SEARCH_BAR_WHOLE_HEIGHT,
  position: 'relative',
  width: `calc(100% - ${SEARCH_BAR_EXTERNAL_RADIUS})`,

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },

  '& .MuiInputBase-root': {
    backgroundColor: 'transparent',
    borderTopLeftRadius: SEARCH_BAR_EXTERNAL_RADIUS,
    borderBottomLeftRadius: SEARCH_BAR_EXTERNAL_RADIUS,
  },

  '& .MuiFormControl-root': {
    backgroundColor: 'transparent',
  },

  '& .MuiInputBase-formControl': {
    backgroundColor: 'transparent',
    height: SEARCH_BAR_WHOLE_HEIGHT,
    py: 0,
  },

  '& .MuiAutocomplete-endAdornment': {
    '& .MuiAutocomplete-popupIndicator': {
      visibility: 'hidden',
    },
    '& .MuiAutocomplete-clearIndicator': {
      visibility: 'visible',
    },
  },
};

export const AutoCompleteSearchIconSx: SxProps = {
  color: theme.palette.colors.gray75,
  position: 'absolute',
  top: '8px',
  left: '8px',
};

export const AutoCompleteSearchTextFieldSx: SxProps = {
  p: '0',
  pl: 3,
  borderRadius: 0,
  borderTopLeftRadius: SEARCH_BAR_EXTERNAL_RADIUS,
  borderBottomLeftRadius: SEARCH_BAR_EXTERNAL_RADIUS,
  '&:focus': {
    border: '2px solid',
  },
};

export const AutoCompleteSelectSx: SxProps = {
  p: 0,
  width: SEARCH_BAR_TYPE_SELECT_WIDTH,
  backgroundColor: 'transparent',
  borderTopRightRadius: SEARCH_BAR_EXTERNAL_RADIUS,
  borderBottomRightRadius: SEARCH_BAR_EXTERNAL_RADIUS,

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};

export const AutoCompleteSelectMenuSx: SxProps = {
  width: SEARCH_BAR_TYPE_SELECT_WIDTH,
};
