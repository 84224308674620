import { SxProps } from '@mui/material';
import { EDIT_DEVICE_MODAL_MAX_WIDTH } from './constants';

export const ModalPaperSx: SxProps = {
  maxWidth: EDIT_DEVICE_MODAL_MAX_WIDTH,
  '& div > #modal-button-stack': {
    marginBottom: '2rem',
  },
  height: '100vh',
};

export const TabsSx: SxProps = {
  '& .MuiTab-root': {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.4px',
    pb: 0,
    textTransform: 'uppercase',
    '&.Mui-selected': {
      color: 'colors.strongBlue',
    },
  },

  '& .MuiTabs-indicator': {
    backgroundColor: 'colors.strongBlue',
  },
};
