import { useSafeMutation } from 'hooks/useSafeMutation';
import { CREATE_RETRIEVAL_ORDER } from './mutations';

export const useCreateRetrievalOrder = () => {
  const [createRetrievalOrder, { loading: createRetrievalOrderLoading }] =
    useSafeMutation(CREATE_RETRIEVAL_ORDER);

  return {
    createRetrievalOrder,
    isCreateRetrievalOrderLoading: createRetrievalOrderLoading,
  };
};
