/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assetManagementSlice } from 'store/slices';
import { GlobalState } from 'store/types';
import Button from 'components/Button';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AssigneeInfoFilterSection from './components/AssigneeInfoFilterSection';
import DeviceInfoFilterSection from './components/DeviceInfoFilterSection';
import AllwhereInfoFilterSection from './components/AllwhereInfoFilterSection';
import * as AMDashComplexFilterDrawerStyles from './styles';
import {
  ASM_DASH_DRAWER_CLEAR_ALL_BUTTON_ID,
  ASM_DASH_DRAWER_FILTER_BY_CLOSE_ICON_ID,
  ASM_FILTER_DRAWER_DIALOG_DATA_TEST_ID,
} from './constants';
import { AssetManagementComplexFilterDrawerProps } from './types';

const AMDashComplexFilterDrawer = (
  props: AssetManagementComplexFilterDrawerProps
) => {
  if (!props.buttonRef.current) return <></>;

  const { x, y } = props.buttonRef.current.getBoundingClientRect();

  const dispatch = useDispatch();

  const filtered = useSelector<GlobalState, boolean>(
    (state) => state.assetManagement.isFiltered
  );
  const isOpen = useSelector<GlobalState, boolean>(
    (state) => state.assetManagement.filterOpen
  );

  if (!isOpen) return <></>;

  const styles = AMDashComplexFilterDrawerStyles;

  const handleCloseModal = () => {
    dispatch(assetManagementSlice.actions.closeFilterDrawerReducer());
  };

  const clearAllFilters = () => {
    dispatch(assetManagementSlice.actions.resetFiltersReducer());
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseModal}
      data-testid={ASM_FILTER_DRAWER_DIALOG_DATA_TEST_ID}
      sx={styles.getFilterDrawerDialogSx({
        top: y,
        left: x,
      })}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={styles.ComplexFilterTopSectionSx}
      >
        <Typography sx={styles.FilterTitleSx}>Filter by</Typography>
        <IconButton
          id={ASM_DASH_DRAWER_FILTER_BY_CLOSE_ICON_ID}
          aria-label="close-filter-asm-asset-drawer-icon-button"
          onClick={handleCloseModal}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <Box sx={styles.getContentBoxStacksSx(filtered)}>
        <AllwhereInfoFilterSection />
        <AssigneeInfoFilterSection />
        <DeviceInfoFilterSection />
      </Box>
      {filtered ? (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={styles.ComplexFilterBottomSectionSx}
        >
          <Button
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Clear all filters',
              purpose: 'Clears All Filters Values',
            }}
            id={ASM_DASH_DRAWER_CLEAR_ALL_BUTTON_ID}
            variant="contained"
            startIcon={<CloseSharpIcon />}
            onClick={clearAllFilters}
          >
            Clear all filters
          </Button>
        </Stack>
      ) : (
        <></>
      )}
    </Dialog>
  );
};

export default AMDashComplexFilterDrawer;
