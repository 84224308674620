import { gql } from '@apollo/client';

export const CREATE_REPAIR_DEVICE_ORDER = gql`
  mutation createRepairOrder($purchaserId: ID!, $assetId: ID!) {
    createRepairOrder(
      repairOrderCreateInput: { purchaserId: $purchaserId, assetId: $assetId }
    ) {
      id
    }
  }
`;
