import React from 'react';
import { Avatar, Typography } from '@mui/material';
import { BubbleProps } from './types';

const Bubble = (props: BubbleProps) => {
  return (
    <Avatar
      sx={{
        bgcolor: 'colors.wildSandWN',
        color: 'colors.mineShaftWN',
      }}
    >
      <Typography variant="body1">{props.text}</Typography>
    </Avatar>
  );
};

export default Bubble;
