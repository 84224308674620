import { ProductCategory } from 'global-constants';
import { FieldValues } from 'react-hook-form';
import { ProductCategoryDto } from 'services/openapi/apiSchemas';
import { isProductCategory } from 'services/product';
import { RETAIL_PRICE } from '../constants';

const FINAL_DESTINATION = 'Final destination';
const ANOTHER_DESTINATION_KEYWORDS = ['another', 'destination'];

export const fixProductDetailsPrice = (price: string) => {
  const validPrice = !price.split(/[\d.]/).join('').length;
  return validPrice
    ? `$${Number(price).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`
    : RETAIL_PRICE;
};

export const getValidVariantValues = (variantValues: FieldValues) => {
  const keyNamesToNotReturn = [
    'streetAddressOne',
    'streetAddressTwo',
    'city',
    'state',
    'region',
    'country',
    'postalCode',
    'insuranceAmount',
    'protectionPlan',
  ];

  const validVariantFields: Record<string, any> = {};

  Object.keys(variantValues).forEach((variantKey) => {
    if (!keyNamesToNotReturn.includes(variantKey)) {
      validVariantFields[variantKey] = variantValues[variantKey];
    }
  });

  return validVariantFields;
};

export const showDisclaimerAccordionSLA = (
  productCategory: ProductCategoryDto | undefined
) => {
  if (!productCategory) return false;

  const isRetrievalProduct = isProductCategory(
    productCategory,
    ProductCategory.RETRIEVAL
  );

  return !isRetrievalProduct;
};

export const hasRedundantOption =
  (redundancy: string) =>
  (variantOptions: Record<string, any>[]): boolean => {
    if (variantOptions.length === 0) return false;
    const availableOptions = variantOptions.map(({ key }) => key);
    return availableOptions.includes(redundancy);
  };

export const allWatchedFormValuesNonEmpty = (fieldValues: FieldValues) =>
  Object.values(fieldValues).every((value) => !!value);
