import React from 'react';
import { useSelector } from 'react-redux';
import CSVDownloadButton from 'components/buttons/csv/CSVDownloadButton';
import { selectAssetsCSVLoadingState } from 'store/slices/assets/csv/selectors';
import {
  useFileDownload,
  useHandleCSVDownloadError,
  useHandleDownloadAllDataClick,
} from './hooks';

const AssetsCSVDownloadButton = () => {
  useHandleCSVDownloadError();
  useFileDownload();
  const handleDownloadAllDataClick = useHandleDownloadAllDataClick();
  const loadingState = useSelector(selectAssetsCSVLoadingState);

  return (
    <CSVDownloadButton
      text="All data"
      ariaLabel="download all assets data"
      onClick={handleDownloadAllDataClick}
      loading={loadingState === 'pending'}
    />
  );
};

export default AssetsCSVDownloadButton;
