export const ASSET_FIELD_UPDATE_ORIGIN = {
  purchaseDate: 'Purchase date',
  warrantyExpiration: 'Warranty expiration',
};

export const ASSET_DETAILS_TESTID = 'asset-details-test-id';

export const ASSET_ASSIGNEE_TESTID = 'asset-assignee-test-id';

export const ASSET_ASSIGNEE_WORK_EMAIL_TESTID =
  'asset-assignee-work-email-test-id';

export const ASSET_ASSIGNEE_PERSONAL_EMAIL_TESTID =
  'asset-assignee-personal-email-test-id';

export const ASSET_DETAILS_HEADER: Record<string, string> = {
  GENERAL: 'General',
  DEVICE_SPECS: 'Device specs',
  ALLWHERE_INFO: 'Allwhere info',
};

export const ASSET_DETAILS_SUBHEADER: Record<string, string> = {
  ASSIGNEE: 'ASSIGNEE',
  WORK_EMAIL: 'WORK EMAIL',
  PERSONAL_EMAIL: 'PERSONAL EMAIL',
  CONTACT_EMAIL: 'CONTACT EMAIL',
  DEPOT_LOCATION: 'DEPOT LOCATION',
  SERIAL_NUMBER: 'SERIAL NUMBER',
  TYPE: 'TYPE',
  CONDITION: 'CONDITION',
  MAKE: 'MAKE',
  MODEL: 'MODEL',
  DEVICE_ISSUE: 'DEVICE ISSUE',
  COLOR: 'COLOR',
  DISPLAY_SIZE: 'DISPLAY SIZE',
  MEMORY: 'MEMORY',
  STORAGE: 'STORAGE',
  PROCESSOR: 'PROCESSOR',
  KEYBOARD_CONFIGURATION: 'KEYBOARD CONFIGURATION',
  PURCHASE_DATE: 'PURCHASE DATE',
  WARRANTY_EXPIRATION: 'WARRANTY EXPIRATION',
  ASSET_NUMBER: 'ASSET NUMBER',
  STATUS: 'STATUS',
  LAST_UPDATED: 'LAST UPDATED',
  LAST_CHECK_IN_DATE: 'DATE OF LAST CHECK-IN',
  LAST_CHECKOUT_DATE: 'DATE OF LAST CHECK-OUT',
  ORDER_NUMBER_HISTORY: 'ORDER NUMBER HISTORY',
};

export const VIEW_ASSET_IMAGES = 'View asset images';
