import { gql } from '@apollo/client';

export const UPDATE_DETAILED_ASSET = gql`
  mutation updateAsset(
    $id: ID!
    $assigneeId: ID
    $assetTypeId: ID
    $serialNumber: String
    $make: String
    $model: String
    $color: String
    $status: AssetStatusEnum
    $cosmeticCondition: AssetCosmeticCondition
    $displaySize: String
    $memory: String
    $storage: String
    $processor: String
    $keyboard: String
    $deviceAssessment: String
    $warrantyExpiration: DateTime
    $purchaseDate: DateTime
    $isWarrantyExpirationVerified: Boolean
    $isPurchaseDateVerified: Boolean
    $customerNote: String
  ) {
    updateAsset(
      assetUpdateInput: {
        id: $id
        assetTypeId: $assetTypeId
        assigneeId: $assigneeId
        serialNumber: $serialNumber
        make: $make
        model: $model
        color: $color
        status: $status
        cosmeticCondition: $cosmeticCondition
        displaySize: $displaySize
        memory: $memory
        storage: $storage
        processor: $processor
        keyboard: $keyboard
        deviceAssessment: $deviceAssessment
        warrantyExpiration: $warrantyExpiration
        purchaseDate: $purchaseDate
        isWarrantyExpirationVerified: $isWarrantyExpirationVerified
        isPurchaseDateVerified: $isPurchaseDateVerified
        customerNote: $customerNote
      }
    ) {
      id
      assetNumber
      organizationId
      assigneeId
      depotId
      productVariantId
      serialNumber
      make
      model
      displaySize
      status
      deviceAssessment
      memory
      storage
      assetTypeId
      color
      cosmeticCondition
      lastOrderItemNumber
      dataStatus
      keyboard
      processor
      technicalFunctionality
      createdAt
      notes
      updatedAt
      customerNote
      depot {
        id
        name
        addressId
      }
      assignee {
        id
        organizationId
        firstName
        lastName
        email
      }
      assetType {
        id
        name
      }
      inventoryLog {
        dateOfLastCheckIn
        dateOfLastCheckOut
      }
    }
  }
`;
