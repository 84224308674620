import { SxProps } from '@mui/material';
import theme from 'themes/theme';
import { AMComplexFilterBanTestId } from './constants';

const CLEAR_BUTTON_BOX_WIDTH = '150px';

export const AMComplexFilterStackSx: SxProps = {
  width: '100%',
  [`& .MuiBox-root[data-testid="${AMComplexFilterBanTestId.CLEAR_BUTTON_BOX}"]`]:
    {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      minHeight: '36px',
      width: CLEAR_BUTTON_BOX_WIDTH,
    },
};

export const FilterBankBoundingStackSx: SxProps = {
  display: 'flex',
  flexWrap: 'wrap',
  maxWidth: 'calc(100% - 200px)',
  mb: '8px',
  minWidth: `calc(100% - ${CLEAR_BUTTON_BOX_WIDTH})`,
  width: `calc(100% - ${CLEAR_BUTTON_BOX_WIDTH})`,
  '& .MuiBox-root': {
    width: '100%',
  },
};

export const FilterBankClearButtonSx: SxProps = {
  px: '12px',
  py: '6px',
  color: theme.palette.colors.offBlack,
  '&:hover': {
    opacity: 0.6,
  },
  '& .MuiTypography-root': {
    fontSize: '14px',
    fontWeight: 600,
  },
  '& .MuiButton-startIcon': {
    mr: '4px',
  },
};

export const AMComplexFilterBankChipSx: SxProps = {
  backgroundColor: theme.palette.colors.grayE0,
  height: '24px',
  mb: '8px',

  '& .MuiChip-label': {
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '18px',
  },

  '& .MuiChip-deleteIcon': {
    color: theme.palette.colors.woodSmoke,
    opacity: 0.3,
  },
};
