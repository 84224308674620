import React from 'react';
import { SxProps } from '@mui/material';

export const ProductImageStyles: React.CSSProperties = {
  display: 'block',
  maxHeight: '152px',
  maxWidth: '152px',
};

export const ProductTitleSx: SxProps = {
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
};
