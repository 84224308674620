/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { nanoid } from '@reduxjs/toolkit';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import GenericAutocomplete from 'components/GenericAutocomplete';
import useDebouncedSearch from 'hooks/useDebouncedSearch';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { OrderManagementFilterFields } from 'pages/OrderManagement/enum';
import { OM_CLEAR_ALL_FILTERS_BUTTON_ID } from 'pages/OrderManagement/constants';
import {
  GET_ALL_DEPOTS,
  GET_ALL_ORDER_TYPE_NAME_IDS,
  SEARCH_OM_ORGANIZATIONS,
  GET_ALL_ORDER_MANAGERS,
} from './queries';
import {
  OM_ORGANIZATION_INPUT_ID,
  DEFAULT_FILTER_WIDTH,
  OrderStatusMapping,
  OM_ORDER_TYPE_FILTER_SETTINGS,
  OM_ORDER_STATUS_FILTER_SETTINGS,
  OM_ORDER_MANAGER_FILTER_SETTINGS,
  OM_DEPOT_FILTER_SETTINGS,
  DISABLED_FILTER_TOOLTIP_TITLE,
} from './constants';
import { OrderFiltersBarProps } from './types';
import * as OrderFiltersBarStyles from './styles';

const OrderFiltersBar = (props: OrderFiltersBarProps) => {
  // local state
  const [organizationName, setOrganizationName] = React.useState<string>('');
  const debouncedOrganizationNameSearchTerm = useDebouncedSearch(
    organizationName,
    300
  );
  const [organizationOptions, setOrganizationOptions] = React.useState<
    string[]
  >([]);
  const [orgIdByNameTable, setOrgIdByNameTable] = React.useState<
    Record<string, any>
  >({});
  const [orderStatusValueLookup, setOrderStatusValueLookup] = React.useState<
    Record<string, any>
  >({});
  const [orderStatusOptions, setOrderStatusOptions] = React.useState<string[]>(
    []
  );
  const [orderTypesValueLookup, setOrderTypesValueLookup] = React.useState<
    Record<string, any>
  >({});
  const [orderTypeOptions, setOrderTypeOptions] = React.useState<string[]>([]);
  const [orderManagersValueLookup, setOrderManagersValueLookup] =
    React.useState<Record<string, any>>({});
  const [orderManagerOptions, setOrderManagerOptions] = React.useState<
    string[]
  >([]);
  const [depotsValueLookup, setDepotsValueLookup] = React.useState<
    Record<string, any>
  >({});
  const [depotOptions, setDepotOptions] = React.useState<string[]>([]);

  const setupOrderStatusOptions = () => {
    const orderStatusLookup = Object.keys(OrderStatusMapping).reduce(
      (acc, stringKey: string) => {
        const value =
          OrderStatusMapping[stringKey as keyof typeof OrderStatusMapping];
        acc[value] = stringKey;
        return acc;
      },
      {} as Record<string, any>
    );

    setOrderStatusValueLookup(orderStatusLookup);
    setOrderStatusOptions(() => ['', ...Object.keys(orderStatusLookup)]);
  };

  // keys used to manually reset a rendered part
  const [orderTypeFilterKey, setOrderTypeFilterKey] = React.useState<string>(
    OrderManagementFilterFields.ORDER_TYPE_NAME
  );

  const [orderStatusFilterKey, setOrderStatusFilterKey] =
    React.useState<string>(OrderManagementFilterFields.STATUS);

  const [orderManagerFilterKey, setOrderManagerFilterKey] =
    React.useState<string>(OrderManagementFilterFields.ORDER_MANAGER_ID);

  const [orderDepotFilterKey, setOrderDepotFilterKey] = React.useState<string>(
    OrderManagementFilterFields.DEPOT_ID
  );

  // queries
  const { loading: isOrganizationsSearchLoading } = useSafeQuery(
    SEARCH_OM_ORGANIZATIONS,
    {
      variables: {
        ...(debouncedOrganizationNameSearchTerm
          ? { name: debouncedOrganizationNameSearchTerm }
          : {}),
        limit: 25,
        offset: 0,
        orderAsc: 'name',
      },
      fetchPolicy: 'network-only',
      onCompleted(data) {
        const { searchOrganizations = {} } = data;
        const { organizations = [] } = searchOrganizations;
        const tempOrgIdByNameTable: { [k: string]: any } = {};
        // eslint-disable-next-line sonarjs/no-unused-collection
        const tempOptions: string[] = [];
        if (!organizations.length) return;
        organizations.forEach((orgItem: any) => {
          const { id, name } = orgItem;
          // eslint-disable-next-line security/detect-object-injection
          tempOrgIdByNameTable[name] = id;
          tempOptions.push(name);
        });
        setOrgIdByNameTable(tempOrgIdByNameTable);
        setOrganizationOptions(tempOptions);
      },
    }
  );

  const [fetchAllDepots, { loading: loadingDepots }] = useSafeLazyQuery(
    GET_ALL_DEPOTS,
    {
      onCompleted: ({ getAllDepots = [] }) => {
        if (!getAllDepots.length) return;
        const reducedDepots = getAllDepots.reduce(
          (acc: Record<string, any>, nextDepot: any) => {
            const { id, name } = nextDepot;
            acc[name] = id;
            return acc;
          },
          {}
        );
        setDepotsValueLookup(reducedDepots);
        setDepotOptions(() => ['', ...Object.keys(reducedDepots)]);
      },
    }
  );

  const [fetchAllOrderTypes, { loading: loadingOrderTypes }] = useSafeLazyQuery(
    GET_ALL_ORDER_TYPE_NAME_IDS,
    {
      onCompleted({ getAllOrderTypes = [] }) {
        if (!getAllOrderTypes.length) return;
        const orderTypesNameIdLookup = getAllOrderTypes.reduce(
          (acc: Record<string, any>, orderType: any) => {
            const { name } = orderType;
            const nameValue = name
              .split(' ')
              .map((str: string) => str.toUpperCase())
              .join('_');
            acc[name] = nameValue;
            return acc;
          },
          {} as Record<string, any>
        );

        setOrderTypesValueLookup(orderTypesNameIdLookup);
        setOrderTypeOptions(() => ['', ...Object.keys(orderTypesNameIdLookup)]);
      },
    }
  );

  const { loading: loadingOrderManagers } = useSafeQuery(
    GET_ALL_ORDER_MANAGERS,
    {
      onCompleted({ getAllOrderManagers = [] }) {
        if (!getAllOrderManagers.length) return;
        const reducedOrderManagers = getAllOrderManagers.reduce(
          (acc: Record<string, any>, nextOM: any) => {
            const { id, firstName, lastName, email } = nextOM;
            acc[`${firstName} ${lastName} (${email})`] = id;
            return acc;
          },
          {} as Record<string, any>
        );

        setOrderManagerOptions(() => Object.keys(reducedOrderManagers));
        setOrderManagersValueLookup(reducedOrderManagers);
      },
    }
  );

  const clearFilterButtonById = (buttonId: string) => {
    const clearTextIconButton: HTMLElement = document.querySelector(
      `#${buttonId} .MuiAutocomplete-clearIndicator`
    ) as HTMLElement;

    if (clearTextIconButton) {
      clearTextIconButton.click();
    }
  };

  const clearOrgsAutoCompleteOptions = () => {
    clearFilterButtonById(OM_ORGANIZATION_INPUT_ID);

    setOrganizationName('');
    setOrganizationOptions([]);
    setOrgIdByNameTable({});
    props.resetFilter(OrderManagementFilterFields.ORGANIZATION_ID);
  };

  const handleAutoCompleteChange = (event: any, value: any) => {
    if (value === null) {
      clearOrgsAutoCompleteOptions();
      return;
    }

    if (orgIdByNameTable === null) return;

    props.setFilters({
      [OrderManagementFilterFields.ORGANIZATION_ID]:
        orgIdByNameTable[value as string],
    });
  };

  const handleInputChange = (_e: any, value: any) => {
    if (value.length >= 2) {
      setOrganizationName(value);
    }
  };

  const clearOrderStatusFilter = () => {
    setOrderStatusFilterKey(() => nanoid());
    props.resetFilter(OrderManagementFilterFields.STATUS);
  };
  const clearOrderTypeFilter = () => {
    setOrderTypeFilterKey(() => nanoid());
    props.resetFilter(OrderManagementFilterFields.ORDER_TYPE_NAME);
  };

  const clearOrderManagerFilter = () => {
    setOrderManagerFilterKey(() => nanoid());
    props.resetFilter(OrderManagementFilterFields.ORDER_MANAGER_ID);
  };

  const clearDepotFilter = () => {
    setOrderDepotFilterKey(() => nanoid());
    props.resetFilter(OrderManagementFilterFields.DEPOT_ID);
  };

  const clearAllFilters = () => {
    clearOrgsAutoCompleteOptions();
    clearOrderStatusFilter();
    clearOrderTypeFilter();
    clearOrderManagerFilter();
    clearDepotFilter();
  };

  const handleSelectChangeHoF =
    (filterField: string) => (event: SelectChangeEvent) => {
      const { value } = event.target;
      props.setFilters({ [filterField]: value });
    };

  const showClearAllFiltersButton = () =>
    Object.keys(props.filtersTable).length > 0;

  React.useEffect(() => {
    fetchAllDepots();
    fetchAllOrderTypes();
    setupOrderStatusOptions();
  }, []);

  React.useEffect(() => {
    if (!props.disableOrderTypeFilter) return;
    clearOrderTypeFilter();
  }, [props.disableOrderTypeFilter]);

  React.useEffect(() => {
    if (!props.disableStatusFilter) return;
    clearOrderStatusFilter();
  }, [props.disableStatusFilter]);

  const styles = OrderFiltersBarStyles;
  const preloadingFilterOptions =
    loadingDepots || loadingOrderTypes || loadingOrderManagers;

  return (
    <Stack direction="column" gap={1}>
      <Stack
        direction="row"
        gap={2}
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: '36px', mt: 1 }}
      >
        <Typography component="span" sx={styles.FilterTitleSx}>
          FILTER BY
        </Typography>
        {showClearAllFiltersButton() ? (
          <Button
            disableRipple
            startIcon={<CloseIcon />}
            sx={styles.FilterClearButtonSx}
            onClick={() => clearAllFilters()}
            id={OM_CLEAR_ALL_FILTERS_BUTTON_ID}
          >
            <Typography>Clear all filters</Typography>
          </Button>
        ) : null}
      </Stack>
      <Stack direction="row" rowGap={2} gap={2}>
        {preloadingFilterOptions ? (
          <CircularProgress color="primary" size={20} />
        ) : (
          <>
            {/* START of Order type options */}
            <Stack sx={styles.FilterStackSx} key={orderTypeFilterKey}>
              {props.filtersTable[
                OrderManagementFilterFields.ORDER_TYPE_NAME
              ] ? (
                <Tooltip title="Clear" arrow>
                  <IconButton
                    size="small"
                    aria-label="clear order type filter"
                    onClick={clearOrderTypeFilter}
                    sx={styles.OrderTypeFilterClearButtonSx}
                    id={OM_ORDER_TYPE_FILTER_SETTINGS.clearButtonId}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              ) : null}

              <Tooltip
                title={
                  props.disableOrderTypeFilter
                    ? DISABLED_FILTER_TOOLTIP_TITLE
                    : ''
                }
                arrow
                placement="bottom"
              >
                <span>
                  <FormControl
                    sx={styles.getFilterFormControlSx(
                      OM_ORDER_TYPE_FILTER_SETTINGS.labelId
                    )}
                    disabled={props.disableOrderTypeFilter}
                  >
                    <InputLabel id={OM_ORDER_TYPE_FILTER_SETTINGS.labelId}>
                      {OM_ORDER_TYPE_FILTER_SETTINGS.label}
                    </InputLabel>
                    <Select
                      value={
                        props.filtersTable[
                          OrderManagementFilterFields.ORDER_TYPE_NAME
                        ]
                      }
                      onChange={handleSelectChangeHoF(
                        OrderManagementFilterFields.ORDER_TYPE_NAME
                      )}
                      sx={styles.FilterSelectSx}
                      placeholder={OM_ORDER_TYPE_FILTER_SETTINGS.placeholder}
                      label={OM_ORDER_TYPE_FILTER_SETTINGS.label}
                      labelId={OM_ORDER_TYPE_FILTER_SETTINGS.labelId}
                      id={OM_ORDER_TYPE_FILTER_SETTINGS.inputId}
                    >
                      {orderTypeOptions.map((orderTypeOption: string) => (
                        <MenuItem
                          key={orderTypeOption}
                          value={
                            orderTypesValueLookup[
                              orderTypeOption as keyof typeof orderTypesValueLookup
                            ]
                          }
                        >
                          {orderTypeOption}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </span>
              </Tooltip>
            </Stack>
            {/* END of Order type options */}
            {/* START of Order Status options */}
            <Stack
              sx={styles.OrderStatusFilterStackSx}
              key={orderStatusFilterKey}
            >
              {props.filtersTable[OrderManagementFilterFields.STATUS] ? (
                <Tooltip title="Clear" arrow>
                  <IconButton
                    size="small"
                    aria-label="clear order type filter"
                    onClick={clearOrderStatusFilter}
                    sx={styles.OrderStatusFilterClearButtonSx}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              ) : null}

              <Tooltip
                title={
                  props.disableStatusFilter ? DISABLED_FILTER_TOOLTIP_TITLE : ''
                }
                arrow
                placement="bottom"
              >
                <span>
                  <FormControl
                    sx={styles.getFilterFormControlSx(
                      OM_ORDER_STATUS_FILTER_SETTINGS.labelId
                    )}
                    disabled={props.disableStatusFilter}
                  >
                    <InputLabel id={OM_ORDER_STATUS_FILTER_SETTINGS.labelId}>
                      {OM_ORDER_STATUS_FILTER_SETTINGS.label}
                    </InputLabel>
                    <Select
                      value={
                        props.filtersTable[OrderManagementFilterFields.STATUS]
                      }
                      onChange={handleSelectChangeHoF(
                        OrderManagementFilterFields.STATUS
                      )}
                      sx={styles.FilterSelectSx}
                      placeholder={OM_ORDER_STATUS_FILTER_SETTINGS.placeholder}
                      label={OM_ORDER_STATUS_FILTER_SETTINGS.label}
                      labelId={OM_ORDER_STATUS_FILTER_SETTINGS.labelId}
                      id={OM_ORDER_STATUS_FILTER_SETTINGS.inputId}
                    >
                      {orderStatusOptions.map((assetTypeOption: string) => (
                        <MenuItem
                          key={assetTypeOption}
                          value={
                            orderStatusValueLookup[
                              assetTypeOption as keyof typeof orderStatusValueLookup
                            ]
                          }
                        >
                          {assetTypeOption}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </span>
              </Tooltip>
            </Stack>
            {/* END of Order Status options */}
            {/* START of Organization look ahead */}
            <Stack
              direction="column"
              sx={styles.AutoCompleteStackSx}
              id={OM_ORGANIZATION_INPUT_ID}
            >
              <GenericAutocomplete
                loading={isOrganizationsSearchLoading}
                onChange={handleAutoCompleteChange}
                onInputChange={handleInputChange}
                componentsProps={{
                  popper: {
                    style: {
                      width: DEFAULT_FILTER_WIDTH,
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Start typing"
                    label="Organization"
                    aria-label="Organization"
                    sx={styles.AutoCompleteSearchTextFieldSx}
                  />
                )}
                sx={styles.AutoCompleteSx}
                options={organizationOptions}
              />
            </Stack>
            {/* END of Organization look ahead */}
            {/* START of Order Manager options */}
            <Stack sx={styles.FilterStackSx} key={orderManagerFilterKey}>
              {props.filtersTable[
                OrderManagementFilterFields.ORDER_MANAGER_ID
              ] ? (
                <Tooltip title="Clear" arrow>
                  <IconButton
                    size="small"
                    aria-label="clear order type filter"
                    onClick={clearOrderManagerFilter}
                    sx={styles.OrderStatusFilterClearButtonSx}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              ) : null}

              <FormControl
                sx={styles.getFilterFormControlSx(
                  OM_ORDER_MANAGER_FILTER_SETTINGS.labelId
                )}
              >
                <InputLabel id={OM_ORDER_MANAGER_FILTER_SETTINGS.labelId}>
                  {OM_ORDER_MANAGER_FILTER_SETTINGS.label}
                </InputLabel>
                <Select
                  value={
                    props.filtersTable[
                      OrderManagementFilterFields.ORDER_MANAGER_ID
                    ]
                  }
                  onChange={handleSelectChangeHoF(
                    OrderManagementFilterFields.ORDER_MANAGER_ID
                  )}
                  sx={styles.FilterSelectSx}
                  placeholder={OM_ORDER_MANAGER_FILTER_SETTINGS.placeholder}
                  label={OM_ORDER_MANAGER_FILTER_SETTINGS.label}
                  labelId={OM_ORDER_MANAGER_FILTER_SETTINGS.labelId}
                  id={OM_ORDER_MANAGER_FILTER_SETTINGS.inputId}
                >
                  {orderManagerOptions.map((orderManagerOption: string) => (
                    <MenuItem
                      key={orderManagerOption}
                      value={
                        orderManagersValueLookup[
                          orderManagerOption as keyof typeof orderManagersValueLookup
                        ]
                      }
                    >
                      {orderManagerOption}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            {/* END of Order Manager options */}
            {/* START of Depot options */}
            <Stack sx={styles.FilterStackSx} key={orderDepotFilterKey}>
              {props.filtersTable[OrderManagementFilterFields.DEPOT_ID] ? (
                <Tooltip title="Clear" arrow>
                  <IconButton
                    size="small"
                    aria-label="clear order type filter"
                    onClick={clearDepotFilter}
                    sx={styles.OrderTypeFilterClearButtonSx}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              ) : null}

              <FormControl
                sx={styles.getFilterFormControlSx(
                  OM_DEPOT_FILTER_SETTINGS.labelId
                )}
              >
                <InputLabel id={OM_DEPOT_FILTER_SETTINGS.labelId}>
                  {OM_DEPOT_FILTER_SETTINGS.label}
                </InputLabel>
                <Select
                  value={
                    props.filtersTable[OrderManagementFilterFields.DEPOT_ID]
                  }
                  onChange={handleSelectChangeHoF(
                    OrderManagementFilterFields.DEPOT_ID
                  )}
                  sx={styles.FilterSelectSx}
                  placeholder={OM_DEPOT_FILTER_SETTINGS.placeholder}
                  label={OM_DEPOT_FILTER_SETTINGS.label}
                  labelId={OM_DEPOT_FILTER_SETTINGS.labelId}
                  id={OM_DEPOT_FILTER_SETTINGS.inputId}
                >
                  {depotOptions.map((depotOption: string) => (
                    <MenuItem
                      key={depotOption}
                      value={
                        depotsValueLookup[
                          depotOption as keyof typeof depotsValueLookup
                        ]
                      }
                    >
                      {depotOption}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            {/* END of Depot options */}
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default OrderFiltersBar;
