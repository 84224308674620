import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { authMiddleware, errorMiddleware } from './middleware';

export const httpLink = new HttpLink({
  uri: process.env.REACT_APP_BFF_BASE_URL,
});

export const bffClient = new ApolloClient({
  link: from([authMiddleware, errorMiddleware, httpLink]),
  cache: new InMemoryCache({ addTypename: false }),
});
