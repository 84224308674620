import React from 'react';
import {
  Collapse,
  Slide,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { getInitials } from 'services/getInitials';
import styles from './CollaboratorTableRow.module.css';
import { CollaboratorTableRowProps } from './types';
import { Avatar } from 'components';

const SLIDE_TRANSITION_TIMEOUT = 300;
const APPEAR = false;

const CollaboratorTableRow = (props: CollaboratorTableRowProps) => {
  const [animateIn, setAnimateIn] = React.useState(true);
  const theme = useTheme();

  const onClickDelete = () => {
    setAnimateIn(false);
  };

  const { collaborator, removeSelectedCollaborator } = props;

  React.useEffect(() => {
    // We need to wait for the animation to be completely done
    // before we remove the collaborator from state
    // if not, the animation will look glitchy
    if (!animateIn) {
      setTimeout(() => {
        removeSelectedCollaborator(collaborator);
      }, SLIDE_TRANSITION_TIMEOUT);
    }
  }, [animateIn, collaborator, removeSelectedCollaborator]);

  return (
    <Collapse
      in={animateIn}
      appear={APPEAR}
      key={props.collaborator.email}
      sx={{
        '& .MuiCollapse-wrapperInner': {
          display: 'table',
        },
      }}
    >
      <Slide
        appear={APPEAR}
        container={props.containerRef.current}
        direction="right"
        easing={{
          enter: theme.transitions.easing.sharp,
          exit: theme.transitions.easing.sharp,
        }}
        in={animateIn}
        timeout={SLIDE_TRANSITION_TIMEOUT}
      >
        <TableRow
          key={props.collaborator.email}
          sx={{ backgroundColor: 'white' }}
        >
          <TableCell padding="none">
            <div className={styles['person-name-container']}>
              <Avatar
                text={getInitials(props.collaborator)}
                width={40}
                height={40}
              />
              <Typography variant="body2">
                {props.collaborator.firstName} {props.collaborator.lastName}
              </Typography>
            </div>
          </TableCell>
          <TableCell align="right">
            <DeleteIcon
              sx={{ cursor: 'pointer' }}
              htmlColor={theme.palette.colors.silverWN}
              onClick={onClickDelete}
            />
          </TableCell>
        </TableRow>
      </Slide>
    </Collapse>
  );
};

export default CollaboratorTableRow;
