/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { DEFAULT_EMPTY_BILLING_INFO_VALUES } from './constants';
import { billingInfoSlice } from './slice';
import { type BillingInfo } from './types';

export const BillingModalContext = React.createContext({
  open: false,
  setOpen: (arg: boolean) => {},
  hasBillingInfo: false,
  fetchBillingInfoQuery: () => {},
  postBillingInfoQuery: (info: BillingInfo) => {},
  accountName: '',
  routingNumber: '',
  accountType: '',
  accountNumber: '',
});

const BillingModalProvider = (props: any) => {
  const [open, setOpen] = React.useState(false);
  const [billingInfoState, billingDispatch] = React.useReducer(
    billingInfoSlice.reducer,
    DEFAULT_EMPTY_BILLING_INFO_VALUES
  );

  const postBillingInfoQuery = (postBody: BillingInfo) => {
    billingDispatch(billingInfoSlice.actions.setBillingInfo(postBody));
  };

  const { accountName, routingNumber, accountType, accountNumber } =
    billingInfoState;

  const hasBillingInfo = React.useMemo(() => {
    return [accountName, accountNumber, accountType, routingNumber].every(
      (item) => !!item
    );
  }, [accountName, accountNumber, accountType, routingNumber]);

  const value = React.useMemo(
    () => ({
      open,
      setOpen,
      hasBillingInfo,
      accountName,
      routingNumber,
      accountType,
      accountNumber,
      fetchBillingInfoQuery: () => {},
      postBillingInfoQuery,
    }),
    [open, accountName, accountNumber, accountType, routingNumber]
  );

  return (
    <BillingModalContext.Provider value={value}>
      {props.children}
    </BillingModalContext.Provider>
  );
};

export default BillingModalProvider;
