export enum OrderView {
  ALL_NEW_ORDERS = 'ALL_NEW_ORDERS',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  KITS = 'KITS',
  PROCUREMENT = 'PROCUREMENT',
  REDEPLOYMENT = 'REDEPLOYMENT',
  RETRIEVALS = 'RETRIEVALS',
  ALL_ORDERS = 'ALL_ORDERS',
}

export enum OrderManagementFilterFields {
  ORDER_TYPE_NAME = 'orderTypeName',
  STATUS = 'status',
  ORGANIZATION_ID = 'organizationId',
  ORDER_MANAGER_ID = 'orderManagerId',
  DEPOT_ID = 'depotId',
}
