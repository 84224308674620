import { gql } from '@apollo/client';

export const LIMITED_SEARCH_COLLABORATORS_BY_ORG_ID = gql`
  query collaborators(
    $organizationId: ID!
    $limit: Int!
    $firstName: String
    $orderAsc: String
    $collaboratorFullTextSearch: String
  ) {
    collaborators(
      collaboratorSearchInput: {
        organizationId: $organizationId
        limit: $limit
        firstName: $firstName
        orderAsc: $orderAsc
        collaboratorFullTextSearch: $collaboratorFullTextSearch
      }
    ) {
      collaborators {
        id
        firstName
        lastName
        email
        personalEmail
        workEmail
      }
    }
  }
`;
