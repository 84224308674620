/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Spacer from 'components/Spacer';
import { MANAGEMENT_TOGGLE_OPTIONS } from './constants';
import * as ManagementDashboardToggleStyles from './styles';

const ManagementDashboardToggle = (props: any) => {
  const styles = ManagementDashboardToggleStyles;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [toggleValue, setToggleValue] = React.useState<string>('');

  const handleToggleChange = (
    event: React.MouseEvent<Element, MouseEvent>,
    value: any
  ) => {
    navigate(value);
  };

  React.useEffect(() => {
    setToggleValue(pathname);
  }, [pathname]);

  return (
    <>
      <ToggleButtonGroup
        exclusive
        value={toggleValue}
        sx={styles.ToggleMgmtButtonGroupSx}
        onChange={handleToggleChange}
      >
        {MANAGEMENT_TOGGLE_OPTIONS.map((option) => (
          <ToggleButton value={option.url} key={option.url}>
            {option.displayName}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
};

export default ManagementDashboardToggle;
