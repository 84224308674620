/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useDispatch } from 'react-redux';
import { assetManagementSlice } from 'store/slices';
import { Avatar as MuiAvatar } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FilterPlaceholders } from 'pages/AssetManagement/constants';
import { AMDashComplexDropDownProps } from './types';
import * as AMDashComplexDropDownStyles from './styles';

const AMDashComplexDropDown = (props: AMDashComplexDropDownProps) => {
  const dispatch = useDispatch();
  const styles = AMDashComplexDropDownStyles;
  const [openDropDown, setOpenDropDown] = React.useState(false);

  const memoSelectionValues = React.useMemo(() => {
    return props.selectedOptions.map((option) => option[0]);
  }, [props.selectedOptions]);

  const handleDropdownChange = (
    event: SelectChangeEvent<string[][]>,
    child: React.ReactNode
  ) => {
    const {
      target: { value },
    } = event;

    const childPropsValue = React.isValidElement(child)
      ? child.props.value
      : '';

    const [propsDisplay, propsUUID] = childPropsValue.split(
      FilterPlaceholders.TUPLE_SEPARATOR
    );

    const invalidChange = !childPropsValue || propsUUID === undefined;

    if (invalidChange) return;

    const displayValueTable = Object.fromEntries(props.selectedOptions);

    const adding = !displayValueTable[propsDisplay];

    if (adding) {
      dispatch(
        assetManagementSlice.actions.selectFilterOptionReducer({
          filterKey: props.filterKey,
          option: [propsDisplay, propsUUID],
        })
      );
    }

    if (!adding) {
      dispatch(
        assetManagementSlice.actions.removeSelectFilterOptionReducer({
          filterKey: props.filterKey,
          displayValue: propsDisplay,
        })
      );
    }
  };

  const clearAll = () => {
    props.clearFiltersByField(props.filterKey);
    setOpenDropDown(false);
  };

  const getSelectedValuesAvatar = () => {
    const isVisible =
      Boolean(props.selectedOptions?.length ?? 0) && !openDropDown;

    return (
      <MuiAvatar
        data-testid={props.counterAvatarTestId}
        sx={styles.getAMDashDropdownFilterCountSx(isVisible)}
      >
        {props.selectedOptions.length}
      </MuiAvatar>
    );
  };

  const getRenderValueText = () =>
    props.selectedOptions.length ? props.inputLabel : props.makeSelectionText;

  const getMenuItem = (displayValue: string, uuid: string) => {
    const isClearFilterOption =
      displayValue === FilterPlaceholders.CLEAR_ALL_FILTER_OPTION;
    const hasEnoughOptions = props.selectedOptions.length >= 1;

    if (isClearFilterOption) {
      return (
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={styles.getAMDashComplexDropDownClearStackSx(hasEnoughOptions)}
        >
          <Box className="clear-box-copy">Applied</Box>
          <Button
            disableRipple
            startIcon={<CloseIcon />}
            sx={styles.AMDashClearAllButtonSx}
            onClick={() => clearAll()}
          >
            <Typography className="clear-all-btn">Clear all</Typography>
          </Button>
        </Stack>
      );
    }

    return (
      <MenuItem
        key={`UUID::${uuid}-DISPLAY::${displayValue}-FILTER_KEY::${props.filterKey}`}
        value={`${displayValue}${FilterPlaceholders.TUPLE_SEPARATOR}${uuid}`}
        sx={styles.AMDashComplexDropDownMenuItemSx}
      >
        <Checkbox checked={memoSelectionValues.indexOf(displayValue) > -1} />
        <span>{displayValue}</span>
      </MenuItem>
    );
  };

  const hasOptions = Boolean(props.availableOptions.length);

  return (
    <>
      <FormControl sx={styles.AMComplexSelectFormControlSx}>
        <InputLabel>{props.inputLabel}</InputLabel>
        <Select
          multiple
          value={props.selectedOptions}
          open={openDropDown}
          onOpen={() => setOpenDropDown(true)}
          onClose={() => setOpenDropDown(false)}
          sx={styles.AMComplexSelectSx}
          renderValue={getRenderValueText}
          onChange={handleDropdownChange}
          disabled={!hasOptions}
          aria-disabled={!hasOptions}
        >
          {hasOptions ? (
            props.availableOptions.map(([displayValue, uuid]) =>
              getMenuItem(displayValue, uuid)
            )
          ) : (
            <MenuItem
              value={`N/A${FilterPlaceholders.TUPLE_SEPARATOR}N/A`}
              sx={styles.AMDashComplexDropDownMenuItemSx}
            >
              <span>No Options</span>
            </MenuItem>
          )}
        </Select>
      </FormControl>
      {getSelectedValuesAvatar()}
    </>
  );
};

export default AMDashComplexDropDown;
