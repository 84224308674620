import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { BoxSearchSx } from './styles';
import { SearchInputProps } from './types';

const SearchInput = (props: SearchInputProps) => {
  const { debouncedSearchTerm, searchTerm, setSearchTerm, placeholder } = props;
  const showSearchCloseIcon = !!debouncedSearchTerm;

  const handleOnChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleOnSubmit = (event: React.FormEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const resetSearch = () => {
    setSearchTerm('');
  };

  return (
    <Box component="form" sx={BoxSearchSx} onSubmit={handleOnSubmit}>
      <TextField
        size="small"
        className="orders-search-bar"
        placeholder={placeholder}
        onChange={handleOnChangeText}
        value={searchTerm}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {showSearchCloseIcon ? (
                <IconButton onClick={resetSearch}>
                  <CloseIcon />
                </IconButton>
              ) : null}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default SearchInput;
