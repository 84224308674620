import { SxProps, Theme } from '@mui/material';
import { DRAWER_WIDTH } from 'global-styles';

export const ScrollableDivSx: SxProps = {
  height: '75vh',
  overflowY: 'scroll',
  '::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    width: '7px',
  },
  paddingLeft: '1rem',
  width: '75%',
  '::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: 'rgba(0,0,0,.5)',
    WebkitBoxShadow: '0 0 1px rgba(255,255,255,.5)',
  },
};

export const BackButtonSx = (theme: Theme): SxProps => {
  return {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    width: '78px',
    minWidth: '78px',
    maxWidth: '78px',
    height: '36px',
    borderRadius: '4px',
    paddingLeft: '0',
    paddingRight: '0',
    '&:hover': {
      border: `1px solid ${theme.palette.colors.blackPearl}`,
      backgroundColor: 'transparent',
    },
    '&:focus': {
      border: `2px solid ${theme.palette.colors.blackPearl}`,
      backgroundColor: theme.palette.colors.heartOfIce,
    },
  };
};

export const MainCatalogContainerSx: SxProps = {
  padding: '2rem 0',
  margin: '0 auto',
  position: 'relative',
  width: 'calc(100vw - 310px)',
  '@media (max-width: 1199px)': {
    left: '0',
  },
  '@media (min-width: 1200px)': {
    left: `${DRAWER_WIDTH - DRAWER_WIDTH / 2}px`,
  },
  '@media (min-width:1200px) and (max-width:1400px)': {
    width: '80%',
  },
  '@media (min-width: 1536px)': {
    left: `${DRAWER_WIDTH - DRAWER_WIDTH / 2}px`,
    maxWidth: '100%',
    width: '85%',
  },
};

export const CatalogHeaderSx: SxProps = {
  fontWeight: 500,
  fontSize: '36px',
  lineHeight: '41.04px',
};

export const CatalogContentContainerSx: SxProps = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  mt: '1rem',
};
