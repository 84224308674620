/* eslint-disable sonarjs/cognitive-complexity */
import { Tooltip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

export const useGetDetailsColumns = (): GridColDef[] => {
  return [
    {
      field: 'column#',
      headerName: 'Column #',
      flex: 1,
      renderCell: (data: any) => {
        return data.row.column || 'N/A';
      },
    },
    {
      field: 'row#',
      headerName: 'Row #',
      flex: 1,
      renderCell: (data: any) => {
        const { row } = data.row;
        return Number.isNaN(+row) ? 'N/A' : row;
      },
    },
    {
      field: 'error',
      headerName: 'Error',
      flex: 5,
      renderCell: (data: any) => {
        const { message } = data.row;
        return (
          <Tooltip title={message} arrow>
            <Typography>{message}</Typography>
          </Tooltip>
        );
      },
    },
  ];
};
