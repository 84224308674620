import React from 'react';
import Paper from '@mui/material/Paper';

const Spacer = (props: { height?: string }) => (
  <Paper
    elevation={0}
    sx={{ height: props.height ?? '24px', backgroundColor: 'transparent' }}
  />
);

export default Spacer;
