import { SxProps } from '@mui/material';
import theme from 'themes/theme';
import { MARKETPLACE_VIEW_UPPER_BOX_TEST_ID } from './constants';

export const MarketplaceViewLowerStackSx: SxProps = {
  mt: '18px',

  [`& .MuiBox-root[data-testid="${MARKETPLACE_VIEW_UPPER_BOX_TEST_ID}"]`]: {
    '& .MuiTypography-root': {
      color: theme.palette.colors.woodSmoke,

      '&.MuiTypography-h5': {
        fontWeight: 700,
      },
      '&.MuiTypography-h6': {
        fontFamily: 'Inter',
        fontWeight: 400,
        lineHeight: '150%',
        // mt: '8px',
      },
    },
  },
};

export const BackButtonSx: SxProps = {
  color: theme.palette.colors.woodSmoke,
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '24px',
  width: '78px',
  minWidth: '78px',
  maxWidth: '78px',
  height: '36px',
  borderRadius: '4px',
  paddingLeft: '0',
  paddingRight: '0',
  '&:hover': {
    border: `1px solid ${theme.palette.colors.woodSmoke}`,
    backgroundColor: 'transparent',
  },
  '&:focus': {
    border: `2px solid ${theme.palette.colors.woodSmoke}`,
    backgroundColor: theme.palette.colors.heartOfIce,
  },
};

export const CatalogContainerSx: SxProps = {
  flexDirection: 'column',
  justifyContent: 'flex-start',
  mt: '1rem',
};

export const getInnerCatalogStackSx = (isTop: boolean = true): SxProps => ({
  flexDirection: 'row',
  justifyContent: isTop ? 'flex-start' : 'space-between',
});

export const ScrollableBoxSx: SxProps = {
  height: '75vh',
  overflowY: 'scroll',
  '::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    width: '7px',
  },
  paddingLeft: '1rem',
  width: '75%',
  '::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: 'rgba(0,0,0,.5)',
    WebkitBoxShadow: '0 0 1px rgba(255,255,255,.5)',
  },
};
