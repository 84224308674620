import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { UploadHistoryPaginationProps } from './types';
import { UploadHistoryPaginationSx } from './styles';
import { ChevronLeftOutlined, ChevronRightOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { getPaginationText, noMoreUploadsAvailable } from '../utils';

function UploadHistoryPagination(props: UploadHistoryPaginationProps) {
  const { onPagination, historicQuery } = props;

  return (
    <Box sx={UploadHistoryPaginationSx}>
      <IconButton
        disabled={!historicQuery.count || historicQuery.offset < 1}
        onClick={() => onPagination(historicQuery.offset - historicQuery.limit)}
      >
        <ChevronLeftOutlined />
      </IconButton>
      <Typography minWidth="60px" variant="body2">
        {getPaginationText(
          historicQuery.offset,
          historicQuery.limit,
          historicQuery.count
        )}
      </Typography>
      <IconButton
        disabled={noMoreUploadsAvailable(
          historicQuery.offset,
          historicQuery.limit,
          historicQuery.count
        )}
        onClick={() => onPagination(historicQuery.offset + historicQuery.limit)}
      >
        <ChevronRightOutlined />
      </IconButton>
    </Box>
  );
}

export default UploadHistoryPagination;
