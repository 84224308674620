import { CountryEnum, StateEnum } from 'global-constants';
import { RetrievalFlowStep } from 'pages/Retrieval/RetrievalService/constants';
import { UseRetrievalService } from 'pages/Retrieval/RetrievalService/types';
import { getEnumKeyByEnumValue } from 'services/enums';
import { Collaborator, RecipientAddress } from 'types';
import {
  RetrievalOrderPayload,
  NewRetrievalOrderPayload,
  RetrievalRecipient,
  NewRetrievalAssetPayload,
} from './types';

const anyEmployeeWithNoSelectedDevices = (
  retrievalFlow: UseRetrievalService
) => {
  return retrievalFlow.selectedEmployees.some((employee) =>
    employee.assets.every((asset) => asset.removeAsset)
  );
};

export const shouldNextButtonBeDisabled = (
  retrievalFlow: UseRetrievalService
) => {
  const retrievalFlowStep = retrievalFlow.currentStep;
  switch (retrievalFlowStep) {
    case RetrievalFlowStep.CHOOSE_TO_FROM_LOCATIONS: {
      const allowCustomDepot =
        retrievalFlow.retrievalDestination?.allowCustomDepot;

      const isCustomDepotPresent =
        retrievalFlow.retrievalDestination?.customAddress;

      return allowCustomDepot && !isCustomDepotPresent;
    }
    case RetrievalFlowStep.EMPLOYEE_SELECTION:
      return retrievalFlow.selectedEmployees.length === 0;
    case RetrievalFlowStep.REVIEW_SELECTIONS:
      return anyEmployeeWithNoSelectedDevices(retrievalFlow);
    case RetrievalFlowStep.RETRIEVAL_SUBMISSION:
      return false;
    default:
      return true;
  }
};

export const constructCreateRetrievalOrderPayload = (
  retrievalFlow: UseRetrievalService,
  purchaser: Collaborator,
  persistInfoToCollaborator: boolean
): RetrievalOrderPayload => {
  const purchaserId = purchaser?.id;
  const routeId = retrievalFlow.retrievalDestination
    ?.retrievalRouteId as string;
  const customAddress = retrievalFlow.retrievalDestination?.customAddress;
  const isDropRetrieval = !!customAddress;
  const dropRecipientAddress: RecipientAddress | undefined = isDropRetrieval
    ? {
        streetAddress1: retrievalFlow.retrievalDestination?.customAddress
          ?.streetAddress1 as string,
        streetAddress2:
          retrievalFlow.retrievalDestination?.customAddress?.streetAddress2,
        city: retrievalFlow.retrievalDestination?.customAddress?.city as string,
        state: getEnumKeyByEnumValue(
          StateEnum,
          retrievalFlow.retrievalDestination?.customAddress?.state || ''
        ) as string,
        principalRegion:
          retrievalFlow.retrievalDestination?.customAddress?.principalRegion ||
          undefined,
        country: getEnumKeyByEnumValue(
          CountryEnum,
          retrievalFlow.retrievalDestination?.customAddress?.country || ''
        ) as string,
        zipCode: retrievalFlow.retrievalDestination?.customAddress
          ?.zipCode as string,
      }
    : undefined;

  const recipients: RetrievalRecipient[] = retrievalFlow.selectedEmployees.map(
    (employee) => {
      const addressId = [
        ...(employee.homeAddresses || []),
        ...(employee.workAddresses || []),
      ].find((address) => address.id === employee.shippingAddress?.id)?.id;
      return {
        collaboratorId: employee.id,
        email: employee.contactEmail,
        isRush: employee.isRush,
        assets: employee.assets
          .filter((asset) => !asset.removeAsset)
          .map((asset) => {
            return {
              assetId: asset.addedAsset ? undefined : asset.id,
              type: asset.type,
              serialNumber: asset.serialNumber,
              options: retrievalFlow.assetsOptionAnswerKey[asset.id].map(
                (option) => {
                  return {
                    name: option.optionName,
                    value: option.optionValue,
                  };
                }
              ),
            } as NewRetrievalAssetPayload;
          }),
        addressId,
        address: employee.shippingAddress
          ? {
              streetAddress1: employee.shippingAddress
                ?.streetAddress1 as string,
              streetAddress2: employee.shippingAddress?.streetAddress2,
              city: employee.shippingAddress?.city as string,
              state: employee.shippingAddress?.state as string,
              principalRegion:
                employee.shippingAddress.principalRegion || undefined,
              country: employee.shippingAddress?.country as string,
              zipCode: employee.shippingAddress?.zipCode as string,
              isHome: employee.shippingAddress?.isHome,
              isWork: employee.shippingAddress?.isWork,
            }
          : undefined,
        persistInfoToCollaborator,
        phoneNumber: employee.shippingAddress?.phoneNumber as string,
      };
    }
  );

  const payload: NewRetrievalOrderPayload = {
    purchaserId,
    routeId,
    checkoutNotes: '', // TODO: Add checkout notes to retrieval flow post ERP
    recipients,
    ...(isDropRetrieval && {
      dropRecipient: {
        collaboratorId: purchaserId,
        phoneNumber: customAddress.phoneNumber ?? '',
        firstName: customAddress.firstName,
        lastName: customAddress.lastName,
        address: dropRecipientAddress,
      },
    }),
  };

  return payload;
};
