import React from 'react';
import { useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { OrderOptionProps } from './types';
import * as OrderOptionsStyles from './styles';
import TextIconButton from 'components/TextIconButton';
import { AddOrderItemByEnum } from 'pages/OrderManagement/components/DetailedOrderView/enum';
import {
  DISABLED_ASSET_TOOLTIP_TEXT,
  DISABLED_PRODUCT_TOOLTIP_TEXT,
} from './constants';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import { FeatureFlagNames } from 'enums';

const OrderOptions = (props: OrderOptionProps) => {
  const styles = OrderOptionsStyles;
  const theme = useTheme();
  const {
    isFlagOff: isPremiumShippingNotEnabled,
    OnFlagTrueWrapper: ShowOnPremiumShipping,
    OnFlagFalseWrapper: HideOnPremiumShipping,
  } = useFeatureFlagService(FeatureFlagNames.PREMIUM_SHIPPING, {
    debugFlags: true,
  });

  const [shippingTypeName, setShippingTypeName] =
    React.useState<string>('Standard');

  const setClickOrigin = (clickOrigin: string) => () => {
    if (clickOrigin === AddOrderItemByEnum.PRODUCT) {
      props.setAddOrderItemToProduct();
    }
    if (clickOrigin === AddOrderItemByEnum.ASSET) {
      props.setAddOrderItemToAsset();
    }
  };

  const getShippingTypeName = () => {
    let shippingTypeName = props?.order?.rush ? '2 Day' : 'Standard';

    if (props?.shippingTypeLookupById && props?.order?.shippingTypeId) {
      shippingTypeName =
        props.shippingTypeLookupById[props.order.shippingTypeId].name;
    }

    setShippingTypeName(shippingTypeName);
  };

  React.useEffect(() => {
    if (isPremiumShippingNotEnabled()) return;

    getShippingTypeName();
  }, [
    props?.shippingTypeLookupById,
    props?.order?.shippingTypeId,
    props?.order?.rush,
  ]);

  return (
    <Grid
      container
      sx={styles.OrderOptionsGridSx}
      justifyContent="space-between"
    >
      <Grid item justifyItems="flex-start">
        <Stack direction="row">
          {props.orderStatusComponent ? props.orderStatusComponent : null}
          <ShowOnPremiumShipping>
            <Stack direction="row" sx={{ ml: '16px' }}>
              <FormControlLabel
                label="SHIPPING"
                labelPlacement="start"
                disabled
                sx={{
                  '& .MuiFormControlLabel-label.Mui-disabled': {
                    color: theme.palette.colors.gray60,
                    opacity: 1,
                    fontSize: '12px',
                  },
                }}
                control={
                  <Select
                    sx={styles.DetailOrderShippingSelectSx}
                    defaultValue={shippingTypeName}
                    disabled
                    onChange={(e: any) => setShippingTypeName(e.target.value)}
                  >
                    {[shippingTypeName].map((typeName: string) => (
                      <MenuItem value={typeName}>{typeName}</MenuItem>
                    ))}
                  </Select>
                }
              />
            </Stack>
          </ShowOnPremiumShipping>
          <HideOnPremiumShipping>
            <Stack direction="row" sx={{ ml: '60px' }}>
              <FormControlLabel
                value=""
                label="PRIORITY"
                labelPlacement="start"
                disabled
                sx={{
                  '& .MuiFormControlLabel-label.Mui-disabled': {
                    color: theme.palette.colors.gray60,
                    opacity: 1,
                    fontSize: '14px',
                  },
                }}
                control={
                  <FormControlLabel
                    value="rushOrder"
                    sx={{
                      '& .MuiFormControlLabel-label.Mui-disabled': {
                        color: '#131414',
                        fontSize: '14px',
                        ml: '-4px',
                        opacity: '0.4 !important',
                      },
                    }}
                    control={
                      <Checkbox
                        disabled
                        checked={props?.order?.rush ?? false}
                        sx={{ ml: '12px' }}
                      />
                    }
                    label="Rush order"
                    labelPlacement="end"
                  />
                }
              />
            </Stack>
          </HideOnPremiumShipping>
        </Stack>
      </Grid>
      <div>
        <Tooltip
          title={DISABLED_ASSET_TOOLTIP_TEXT}
          disableHoverListener={!props.disableAsset}
        >
          <span>
            <TextIconButton
              text="Asset"
              ariaLabel="Add Asset"
              onClick={setClickOrigin(AddOrderItemByEnum.ASSET)}
              icon={<AddIcon />}
              variant="outlined"
              disabled={props.disableAsset}
              buttonStyles={styles.OrderOptionButtonSx}
            />
          </span>
        </Tooltip>
        <Tooltip
          title={DISABLED_PRODUCT_TOOLTIP_TEXT}
          disableHoverListener={!props.disableProduct}
        >
          <span>
            <TextIconButton
              text="Product"
              ariaLabel="Add Product"
              onClick={setClickOrigin(AddOrderItemByEnum.PRODUCT)}
              icon={<AddIcon />}
              variant="outlined"
              disabled={props.disableProduct}
              buttonStyles={styles.OrderOptionButtonSx}
            />
          </span>
        </Tooltip>
      </div>
    </Grid>
  );
};

export default OrderOptions;
