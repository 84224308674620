import React from 'react';
import Button from '@mui/material/Button';
import { GAButtonProps } from './types';
import { useGAButtonClickHandler } from '../shared/hooks';

const GAButton = (props: GAButtonProps) => {
  const { gaContext, onClick, ...rest } = props;
  const handleClick = useGAButtonClickHandler({ gaContext, onClick });

  return <Button {...rest} onClick={handleClick} />;
};

export default GAButton;
