/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { useForm, Controller } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ErrorMessages } from 'global-constants';
import { Asset } from 'types';
import {
  AssetDialogSx,
  AssetDialogPaperSx,
  AssetMainTitleSx,
  AssetOuterFormStackSx,
  AssetInnerFormStackSx,
  AssetTextFieldBaseSx,
  AssetCancelButtonSx,
  AssetSaveButtonSx,
} from './styles';
import { AddAssetModalProps, IAddAssetFormValues } from './types';
import {
  DefaultAddAssetValues,
  RETRIEVAL_ASSET_TYPES,
  RETRIEVAL_ASSET_TYPES_VALUE_LOOKUP,
} from './constants';
import {
  makeRetrievalAddAsset,
  isSerialNumberInLocalStoredAssets,
} from './utils';
import Spacer from 'components/Spacer';
import { getDefaultAnswers } from 'pages/Retrieval/components/ReviewStep/utils';
import { useGetAssetsBySerialNumber } from './hooks/useGetAssetsBySerialNumber';
import useGetUserOrganization from 'hooks/useGetUserOrganization';

const AddAssetModal = (props: AddAssetModalProps) => {
  const {
    control,
    register,
    watch,
    reset,
    handleSubmit,
    getValues,
    setError,
    setValue,
    formState: { errors },
  } = useForm<IAddAssetFormValues>({
    defaultValues: { ...DefaultAddAssetValues },
  });

  const doesNotKnowSerialNumber = watch('doesNotKnowSerialNumber');

  const userOrganization = useGetUserOrganization();

  const getAssetsBySerialNumber = useGetAssetsBySerialNumber();

  const shouldDisableFormSubmit = () => {
    if (doesNotKnowSerialNumber) {
      return watch(['assetType']).some((item) => !item) || !userOrganization;
    }
    return (
      watch(['assetType', 'serialNumber']).some((item) => !item) ||
      !userOrganization
    );
  };

  const disableFormSubmit = shouldDisableFormSubmit();

  const handleAssetSubmit = (formData: IAddAssetFormValues) => {
    const matchedRetrievalOptions =
      props.optionAnswersByType[formData.assetType];
    const defaultAnswersByType = getDefaultAnswers(props.optionAnswersByType);
    const defaultAnswers = defaultAnswersByType[formData.assetType];
    const fakeTempId = `TEMP-FAKE-ID-${nanoid()}`;
    const assetToAdd = makeRetrievalAddAsset(formData, fakeTempId);
    assetToAdd.optionAnswers = defaultAnswers;
    props.addAsset(props.collaboratorId, assetToAdd);
    reset();
    props.closeModal();
  };

  const handleCancel = () => {
    reset();
    props.closeModal();
  };

  const getDialogSubText = (
    first: string = 'FIRST_NAME',
    last: string = 'LAST_NAME'
  ) =>
    `Please provide required information to add a device for ${first} ${last}. Once the device is added, you will be able retrieve this device.`;

  const handleSave = (formData: IAddAssetFormValues) => {
    if (doesNotKnowSerialNumber) {
      handleAssetSubmit(formData);
    } else {
      const serialNumber = getValues('serialNumber');

      const isSerialNumberStoredLocally = isSerialNumberInLocalStoredAssets(
        serialNumber,
        props.allRetrievalAssets
      );

      if (isSerialNumberStoredLocally) {
        setError('serialNumber', {
          type: 'custom',
          message: ErrorMessages.SERIAL_NUMBER_ALREADY_EXISTS,
        });
        return;
      }

      getAssetsBySerialNumber.searchAssetsBySerialNumber({
        variables: {
          organizationId: userOrganization?.id as string,
          serialNumber,
        },
        onCompleted(data) {
          const assets = data?.searchAssets?.assets || [];
          const isAssetWithSerialNumber = assets.some(
            (asset: Asset) => asset.serialNumber === serialNumber
          );
          if (isAssetWithSerialNumber) {
            setError('serialNumber', {
              type: 'custom',
              message: ErrorMessages.SERIAL_NUMBER_ALREADY_EXISTS,
            });
          } else {
            handleAssetSubmit(formData);
          }
        },
      });
    }
  };

  React.useEffect(() => {
    if (doesNotKnowSerialNumber) {
      setValue('serialNumber', '');
    }
  }, [doesNotKnowSerialNumber]);

  return (
    <Dialog
      open={props.open}
      sx={AssetDialogSx}
      PaperProps={{ sx: { ...AssetDialogPaperSx } }}
      onClose={() => props.closeModal()}
    >
      <form onSubmit={handleSubmit(handleSave)} style={{ height: '100%' }}>
        <Stack
          direction="column"
          spacing={0}
          justifyContent="space-between"
          sx={AssetMainTitleSx}
        >
          <Stack direction="column">
            <Typography variant="h5" sx={AssetMainTitleSx}>
              Add a device
            </Typography>
            <Typography>
              {getDialogSubText(props.firstName, props.lastName)}
            </Typography>
            <Stack
              direction="row"
              spacing="20px"
              justifyContent="space-between"
              sx={AssetOuterFormStackSx}
            >
              <Stack
                direction="column"
                justifyContent="space-between"
                sx={AssetInnerFormStackSx}
              >
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id="add-asset-modal-type-label">
                    Device type
                  </InputLabel>
                  <Controller
                    control={control}
                    name="assetType"
                    render={({ field }) => (
                      <Select
                        labelId="add-asset-modal-type-label"
                        label="Device type"
                        id="add-asset-type"
                        aria-required
                        required
                        {...field}
                      >
                        {RETRIEVAL_ASSET_TYPES.map((assetType) => (
                          <MenuItem
                            key={`${assetType}-menu-item-selection`}
                            value={
                              RETRIEVAL_ASSET_TYPES_VALUE_LOOKUP[assetType]
                            }
                          >
                            {assetType}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <Spacer />
                <Controller
                  name="serialNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      aria-required={!getValues('doesNotKnowSerialNumber')}
                      required={!getValues('doesNotKnowSerialNumber')}
                      id="add-asset-serial-number-text"
                      label="Serial number"
                      sx={AssetTextFieldBaseSx}
                      {...field}
                      {...register('serialNumber', {
                        required: !getValues('doesNotKnowSerialNumber'),
                      })}
                      error={!!errors.serialNumber}
                      helperText={errors.serialNumber?.message}
                    />
                  )}
                />
                <Spacer />
                <Controller
                  name="doesNotKnowSerialNumber"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          color="secondary"
                          checked={watch('doesNotKnowSerialNumber')}
                        />
                      }
                      label="I do not know the serial number"
                    />
                  )}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="row" spacing="16px" justifyContent="flex-end">
            <Button
              color="secondary"
              onClick={handleCancel}
              size="small"
              sx={AssetCancelButtonSx}
              variant="text"
            >
              <Typography variant="inherit">CANCEL</Typography>
            </Button>
            <LoadingButton
              color="primary"
              size="small"
              sx={AssetSaveButtonSx}
              variant="contained"
              disabled={disableFormSubmit}
              type="submit"
              loading={
                getAssetsBySerialNumber.isSearchAssetsBySerialNumberLoading
              }
            >
              <Typography variant="inherit">SAVE</Typography>
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  );
};

export default AddAssetModal;
