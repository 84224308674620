import React from 'react';

const UruguayFlagSVGIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="none"
    >
      <rect
        x="0.25"
        y="0.25"
        width="27.5"
        height="19.5"
        rx="1.75"
        fill="white"
        stroke="#F5F5F5"
        stroke-width="0.5"
      />
      <mask
        id="mask0_3141_38774"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="28"
        height="20"
      >
        <rect
          x="0.25"
          y="0.25"
          width="27.5"
          height="19.5"
          rx="1.75"
          fill="white"
          stroke="white"
          stroke-width="0.5"
        />
      </mask>
      <g mask="url(#mask0_3141_38774)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M28 2.66797H13.3333V5.33464H28V2.66797ZM13.3333 8.0013V10.668H28V8.0013H13.3333ZM13.3333 13.3346L0 13.3346V16.0013H28V13.3346H13.3333ZM0 20.0013V18.668H28V20.0013H0Z"
          fill="#0E4DC5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.66675 9.32234L5.77666 10.651L5.55121 9.06772L4.17279 9.8786L4.65661 8.3543L3.06287 8.48681L4.16015 7.32339L2.66675 6.75127L4.16015 6.17916L3.06287 5.01574L4.65661 5.14824L4.17279 3.62395L5.55121 4.43483L5.77666 2.85156L6.66675 4.18021L7.55683 2.85156L7.78229 4.43483L9.16071 3.62395L8.67689 5.14824L10.2706 5.01574L9.17335 6.17916L10.6667 6.75127L9.17335 7.32339L10.2706 8.48681L8.67689 8.3543L9.16071 9.8786L7.78229 9.06772L7.55683 10.651L6.66675 9.32234ZM6.66675 9.29673C8.07256 9.29673 9.2122 8.15709 9.2122 6.75127C9.2122 5.34546 8.07256 4.20582 6.66675 4.20582C5.26093 4.20582 4.12129 5.34546 4.12129 6.75127C4.12129 8.15709 5.26093 9.29673 6.66675 9.29673ZM8.66675 6.6677C8.66675 7.77227 7.77132 8.6677 6.66675 8.6677C5.56218 8.6677 4.66675 7.77227 4.66675 6.6677C4.66675 5.56313 5.56218 4.6677 6.66675 4.6677C7.77132 4.6677 8.66675 5.56313 8.66675 6.6677Z"
          fill="#FED443"
        />
      </g>
    </svg>
  );
};

export default UruguayFlagSVGIcon;
