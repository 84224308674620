import {
  INPUT,
  DEVICE_FILTER,
} from 'pages/Assets/components/DevicesDatagrid/components/DeviceFilters/constants';
import { AssetConditionEnum } from 'global-constants';

export const CONDITION = 'Condition';
const LC_CONDITION = CONDITION.toLowerCase();

export const DeviceConditionFilterSettings = {
  placeholder: `Physical ${LC_CONDITION}`,
  label: `Physical ${LC_CONDITION}`,
  labelId: `${DEVICE_FILTER}${LC_CONDITION}-${INPUT}-label`,
  inputId: `${DEVICE_FILTER}${LC_CONDITION}-${INPUT}`,
  searchAssetsQueryField: LC_CONDITION,
};

// returns values of AssetConditionEnum without the first value
export const ASSET_CONDITION_OPTIONS = Object.values(AssetConditionEnum);
export const ASSET_CONDITION_OPTIONS_LOOKUP = Object.keys(
  AssetConditionEnum
).reduce((acc, assetConditionKey: string) => {
  const assetConditionValue =
    AssetConditionEnum[assetConditionKey as keyof typeof AssetConditionEnum];
  acc[assetConditionValue] = assetConditionKey;
  return acc;
}, {} as Record<string, string>);
