import { Box, Stack, Typography } from '@mui/material';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import { LOOK_BACK_OPTIONS, UPLOAD_HISTORY_HEADER_COPY } from './constants';
import { UploadHistoryActionsProps } from './types';
import React from 'react';
import {
  SearchControlsBoxSx,
  UploadHistoryActionsHeaderSx,
  UploadHistoryActionsHeaderTextSx,
  UploadHistoryLookbackDropdownButtonSx,
  UploadHistoryLookbackDropdownButtonTextSx,
  UploadHistoryLookbackDropdownItemSx,
  UploadHistoryLookbackDropdownSx,
} from './styles';
import { ArrowDropUp } from '@mui/icons-material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import UploadHistoryPagination from '../UploadHistoryPagination';

function UploadHistoryActions(props: UploadHistoryActionsProps) {
  const {
    lookback,
    onLookbackChange,
    lookbackDropdownOpen,
    onToggleLookbackDropdown,
    historicQuery,
    onPagination,
  } = props;

  const handleLookbackChange = React.useCallback((event: any) => {
    const newLookback = event.target.value;
    onLookbackChange(newLookback);
  }, []);

  return (
    <Stack direction="row" sx={UploadHistoryActionsHeaderSx}>
      <Typography variant="subtitle2" sx={UploadHistoryActionsHeaderTextSx}>
        {UPLOAD_HISTORY_HEADER_COPY}
      </Typography>
      <Box sx={SearchControlsBoxSx}>
        <Dropdown
          type="Menu"
          items={LOOK_BACK_OPTIONS}
          selectedItem={lookback}
          handleSearchOptionChange={handleLookbackChange}
          showCheckIcon={true}
          dropdownSx={UploadHistoryLookbackDropdownSx}
          dropdownItemSx={UploadHistoryLookbackDropdownItemSx}
          onMenuToggle={(open) => onToggleLookbackDropdown(open)}
        >
          <Button
            gaContext={{
              textCopy: 'Select lookback',
              navigates_to: 'select lookback dropdown',
              purpose:
                'Enables user to update the lookback period for the upload history',
            }}
            id="select-lookback-button"
            color="secondary"
            sx={UploadHistoryLookbackDropdownButtonSx}
          >
            <Typography sx={UploadHistoryLookbackDropdownButtonTextSx}>
              {`${
                LOOK_BACK_OPTIONS.find((option) => option.value === lookback)
                  ?.label
              }\u00A0`}
            </Typography>
            {lookbackDropdownOpen ? <ArrowDropUp /> : <ArrowDropDownIcon />}
          </Button>
        </Dropdown>
        <UploadHistoryPagination
          historicQuery={historicQuery}
          onPagination={onPagination}
        />
      </Box>
    </Stack>
  );
}

export default UploadHistoryActions;
