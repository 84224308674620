import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useOrganizationControllerSearch } from 'services/openapi/apiComponents';
import useDebouncedQuery from 'hooks/useDebouncedSearch';
import { useGlobalState } from 'state';
import {
  DefaultOrganizationValue,
  MuiAutoCompleteInputChangeReason,
} from './constants';
import { Organization } from 'types';
import { ToggleSx } from '../styles';

const OrganizationToggle = () => {
  const [userOrganization, setUserOrganization] =
    useGlobalState('userOrganization');

  const [, setGlobalPurchaser] = useGlobalState('globalPurchaser');

  const [organizationNameSearchTerm, setOrganizationNameSearchTerm] =
    React.useState('');
  const debouncedOrganizationNameSearchTerm = useDebouncedQuery(
    organizationNameSearchTerm
  );

  const [organizationOptions, setOrganizationOptions] = React.useState<
    Organization[]
  >([]);

  const handleOnOrganizationSearchInputChange = (
    e: any,
    _value: any,
    reason: string
  ) => {
    e?.target.value
      ? setOrganizationNameSearchTerm(e.target.value)
      : setOrganizationNameSearchTerm('');

    if (reason === MuiAutoCompleteInputChangeReason.CLEAR) {
      setGlobalPurchaser(undefined);
      setUserOrganization(undefined);
    }
  };

  const handleOnOrganizationSelectChange = (
    _e: any,
    selectedOrganization: any
  ) => {
    const organizationToSet = selectedOrganization || DefaultOrganizationValue;
    setUserOrganization(organizationToSet);
    setGlobalPurchaser(undefined);
  };

  const { data } = useOrganizationControllerSearch({
    queryParams: {
      ...(debouncedOrganizationNameSearchTerm
        ? {
            name: debouncedOrganizationNameSearchTerm,
          }
        : {}),
    },
  });

  const organizations = data?.results;

  React.useEffect(() => {
    setOrganizationOptions((organizations || []) as Organization[]);
  }, [organizations]);

  return (
    <Autocomplete
      sx={ToggleSx}
      value={userOrganization || DefaultOrganizationValue}
      options={organizationOptions}
      onInputChange={handleOnOrganizationSearchInputChange}
      renderInput={(params) => (
        <TextField {...params} variant="standard" placeholder="Organization" />
      )}
      getOptionLabel={(option) => option?.name}
      onChange={handleOnOrganizationSelectChange}
    />
  );
};

export default OrganizationToggle;
