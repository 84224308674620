import { SxProps } from '@mui/material';
import { MINIMUM_PREMIUM_SHIPPING_RADIO_GROUP_WIDTH } from 'global-styles';

export const ShippingAndProcessingBoxSx: SxProps = {
  marginBottom: '2rem',
  width: '95%',

  '& .new-assign-flow-question-sub-copy': {
    fontSize: '14px',
    lineHeight: '20.02px',
    color: 'colors.gray60',

    '& > .MuiLink-root': {
      fontWeight: 600,
      color: 'colors.woodSmoke',
      whiteSpace: 'nowrap',
    },
  },
};

export const ShippingAndProcessingHeaderSx: SxProps = {
  fontWeight: '700',
  fontSize: '20px',
  lineHeight: '24px',
  fontStyle: 'normal',
  fontFamily: 'PPPangramSans',
};

export const ShippingAndProcessingSubCopySx: SxProps = {
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '16.94px',
  color: 'colors.gray94',
};

export const RadioGroupSx: SxProps = {
  gap: '1.4rem',
  margin: '0.75rem 0.5rem 1.5rem  0.6rem',
};

export const ShippingRadioGroupSx: SxProps = {
  maxWidth: '600px',
  rowGap: '1rem',
  padding: '0 0.75rem',
  justifyContent: 'space-between',
};

export const NewShippingRadioGroupSx: SxProps = {
  ...ShippingRadioGroupSx,
  minWidth: MINIMUM_PREMIUM_SHIPPING_RADIO_GROUP_WIDTH,
  columnGap: '12px',
  px: 0,
  mt: '24px',
  '& .MuiFormControlLabel-root': {
    mx: 0,
  },
};
