import { gql } from '@apollo/client';

export const GET_ALL_DEPOTS = gql`
  query {
    getAllDepots {
      id
      name
    }
  }
`;

export const GET_ALL_ORDER_TYPE_NAME_IDS = gql`
  query {
    getAllOrderTypes {
      id
      name
    }
  }
`;

export const GET_ALL_ORDER_MANAGERS = gql`
  query {
    getAllOrderManagers {
      id
      firstName
      lastName
      email
    }
  }
`;

export const SEARCH_OM_ORGANIZATIONS = gql`
  query searchOMOrganizations(
    $name: String
    $offset: Int
    $limit: Int
    $orderAsc: String
    $orderDesc: String
  ) {
    searchOrganizations(
      organizationSearchInput: {
        name: $name
        offset: $offset
        limit: $limit
        orderAsc: $orderAsc
        orderDesc: $orderDesc
      }
    ) {
      organizations {
        id
        name
      }
      count
    }
  }
`;
