import { SurveyConfig } from './types';

export const SURVEY_CONFIG: SurveyConfig = {
  keys: [
    {
      clientId: 'wavY8EVTcVHhQkZH',
      name: 'delightedNps2',
      page: undefined,
    },
    {
      clientId: '0NSwglze88wN4ztW',
      name: 'ordersDashCSAT',
      page: 'orders',
    },
    {
      clientId: 'GBtIPmY3FLIjWn8B',
      name: 'assetsDashCSAT',
      page: 'assets',
    },
    {
      clientId: 'JBjlVsoY93uUmsLb',
      name: 'employeesDashCSAT',
      page: 'employees',
    },
  ],
};
