import { capitalize } from 'services/string';

export const GLOBAL_TOAST_NOTIFICATION_TESTID =
  'global-toast-notification-testid';

export const TEMP_NOTIFICATION_TRIGGER_BUTTON_TESTID =
  'temp-global-toast-notification-button-testid';

export enum NotificationMessages {
  SYNC_TO_ERP_REQUEST_SUCCESS = 'Request to sync to ERP sent successfully.',
  CHANGES_SAVED_SUCCESS = 'Changes were successfully saved',
  PROCESS_REQUEST_SENT = 'Process request sent!',
  RECYCLE_REQUEST_SENT = 'Recycle request sent!',
  ORDER_CREATE_REQUEST_SENT = 'Order creation request sent!',
  REPAIR_REQUEST_SENT = 'Repair request sent!',
  RESELL_REQUEST_SENT = 'Resell request sent!',
  GENERIC_SOMETHING_WENT_WRONG = 'Something went wrong. Please try again.',
  NOTIFICATION_SENT_SUCCESSFULLY = 'Notification request sent successfully.',
}

export const CustomNotifications = {
  fieldUpdatedSuccessfully: (fieldName: string) =>
    `${fieldName} has been successfully updated.`,
  entityNotFound: (entityName: string) =>
    `${capitalize(entityName)} could not be found.`,
  entityNotfoundWithFieldValue: (
    entityName: string,
    fieldName: string,
    value: any
  ) => `${entityName} could not be found with ${fieldName} of ${value}.`,
  entityDeletedSuccessfully: (entityName: string) =>
    `${capitalize(entityName)} was successfully deleted.`,
};
