import { SxProps } from '@mui/material';

export const OrderNotesSX: SxProps = {
  width: '100%',
  maxWidth: '700px',

  '& span#add-order-notes-to-checkout-review[role=button]': {
    color: 'colors.offBlack',
    cursor: 'pointer',
    float: 'right',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '15px',
    ml: '16px',
    mt: '3px',

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  '& #order-notes-text-field-stack': {
    position: 'relative',

    '& #order-notes-delete-button': {
      bottom: '50px',
      color: 'colors.offBlack',
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '15px',
      m: '16px',
      position: 'absolute',
      right: '-5px',
      zIndex: 2,
      '&:hover': {
        textDecoration: 'underline',
      },
    },

    '& .MuiTextField-root': {
      borderRadius: 0,
      height: '127px',
      ml: '12px',
      p: 0,
      width: 'calc(100% - 12px)',

      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        backgroundColor: 'colors.white',
        height: '95px',
        pb: '18px',
        pr: '8px',

        '& textarea': {
          fontSize: '14px',
          height: 'calc(100% - 10px)',
          lineHeight: '17px',
        },
      },
    },
  },

  '& span#order-notes-title': {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    ml: '16px',
  },

  '& #order-notes-title-box.MuiBox-root': {
    mt: '5px',
  },

  '& .MuiDivider-root.MuiDivider-fullWidth': {
    pt: '12px',
  },
};
