import { SxProps } from '@mui/material';
import { MINIMUM_PREMIUM_SHIPPING_RADIO_GROUP_WIDTH } from 'global-styles';

export const AssignFlowMainContainerSx: SxProps = {
  padding: '0 !important',
  maxWidth: {
    lg: '100vw !important',
  },
};

export const AssignFlowTopButtonStackSx: SxProps = {
  borderBottom: '1px solid #DCDCDC',
  padding: '0.5rem 1.5rem',

  '& > button': {
    padding: '12px 14px',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '27px',
  },
};

export const AssignFlowInnerContainerSx: SxProps = {
  marginTop: {
    xs: '1.5rem',
    lg: '2rem',
  },
  paddingRight: {
    lg: '7.5rem !important',
  },

  '& .assign-flow-title': {
    fontWeight: '700',
    fontSize: '32px',
    lineHeight: '36px',
  },

  '& .assign-flow-question-label': {
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '22px',
  },

  '& .assign-flow-question-sub-copy': {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16.94px',
    color: 'colors.gray94',
  },

  '& .new-assign-flow-question-sub-copy': {
    fontSize: '14px',
    lineHeight: '20.02px',
    color: 'colors.gray60',

    '& > .MuiLink-root': {
      fontWeight: 600,
      color: 'colors.woodSmoke',
    },
  },

  '& .assign-cost-copy': {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '18px',
    marginTop: '2.5rem',
  },
};

export const EmployeeSearchBoxSx: SxProps = {
  width: {
    xs: '75%',
    md: '50%',
  },
  marginTop: '2.5rem',

  '&.label-top': {
    gap: '2rem',
  },
  gap: '1.5rem',
};

export const InsuranceBoxSx: SxProps = {
  width: {
    md: '50%',
  },
  marginTop: {
    xs: '2.5rem',
    lg: '3rem',
  },
  gap: '1.5rem',
};

export const EmployeeAddressCopyBoxSx: SxProps = {
  width: '100%',
  rowGap: '6px',
};

export const ShippingAndProcessingBoxSx: SxProps = {
  width: '100%',
  marginTop: {
    xs: '2.5rem',
    lg: '3rem',
  },
  gap: '1.5rem',
};

export const ShippingAndProcessingCopyBoxSx: SxProps = {
  width: '75%',
  rowGap: '6px',
};

export const ShippingRadioGroupSx: SxProps = {
  maxWidth: '600px',
  rowGap: '1rem',
  padding: '0 0.75rem',
  justifyContent: 'space-between',
};

export const NewShippingRadioGroupSx: SxProps = {
  ...ShippingRadioGroupSx,
  minWidth: MINIMUM_PREMIUM_SHIPPING_RADIO_GROUP_WIDTH,
  columnGap: '12px',
  px: 0,
  '& .MuiFormControlLabel-root': {
    mx: 0,
  },
};

export const RecipientEmailSelectSx: SxProps = {
  paddingLeft: '0 !important',
  top: '-12px',
  width: '100%',
  backgroundColor: 'transparent',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    borderRadius: '0',
    borderBottom: '1px solid #000',
    width: '100%',
  },
  '& .MuiSelect-select': {
    paddingLeft: '0',
    position: 'relative',
    top: '10px',
  },
  '& .MuiSelect-iconOutlined': {
    top: '25px',
    '@media (min-width: 1500px)': {
      right: '0',
    },
  },
};

export const RecipientEmailSelectLabelSx: SxProps = {
  left: '-12px !important',
};
