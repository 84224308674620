import { EmployeeDetailsTextContent } from './types';

export const DEFAULT_EMPLOYEE_DETAILS_TEXT_CONTENT: EmployeeDetailsTextContent =
  {
    fullName: '-',
    firstName: '-',
    lastName: '-',
    // birthday: '-',
    email: '-',
    personalEmail: '-',
    workEmail: '-',
    phoneNumber: '-',
    homeAddress: '-',
    workAddress: '-',
    homeAddresses: ['-'],
    workAddresses: ['-'],
    team: '-',
    hireDate: '-',
    startDate: '-',
  };

export const EMPLOYEE_DETAILS_TESTID = 'employee-details-test-id';

export const WORK_EMAIL_TESTID = 'work-email-test-id';

export const PERSONAL_EMAIL_TESTID = 'personal-email-test-id';
