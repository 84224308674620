import { gql } from '@apollo/client';

export const GET_ALL_PURCHASERS_BY_ORG_ID = gql`
  query getAllCollaboratorsByOrgId(
    $organizationId: String!
    $isAdmin: Boolean
  ) {
    getAllCollaboratorsByOrgId(
      organizationId: $organizationId
      isAdmin: $isAdmin
    ) {
      id
      firstName
      lastName
      email
      isAdmin
    }
  }
`;

export const GET_ALL_ORDER_TYPES = gql`
  query {
    getAllOrderTypes {
      id
      name
      hasAssets
      hasProducts
      toDepot
      toRecipient
      toDropRecipient
    }
  }
`;
