import { gql } from '@apollo/client';

export const SEARCH_ORDERS_BY_ORDER_NUMBER = gql`
  query searchOrders(
    $organizationId: String
    $orderNumber: String
    $orderTypeName: OrderTypeName
    $status: [OrderStatusEnum!]
    $view: OrderView
    $offset: Int
    $limit: Int
    $orderAsc: String
    $orderDesc: String
  ) {
    searchOrders(
      searchOrdersInput: {
        organizationId: $organizationId
        orderNumber: $orderNumber
        orderTypeName: $orderTypeName
        status: $status
        view: $view
        offset: $offset
        limit: $limit
        orderAsc: $orderAsc
        orderDesc: $orderDesc
      }
    ) {
      orders {
        id
        rush
        orderNumber
        dueDate
        orderType {
          id
          name
        }
        recipient {
          id
          firstName
          lastName
        }
        createdAt
        status
        finalDestination
        orderItems {
          id
        }
      }
      count
    }
  }
`;

export const GET_COLLABORATORS_BY_NAME = gql`
  query collaborators(
    $organizationId: ID!
    $firstOrLastName: String
    $limit: Int = 20
  ) {
    collaborators(
      collaboratorSearchInput: {
        organizationId: $organizationId
        firstOrLastName: $firstOrLastName
        limit: $limit
      }
    ) {
      collaborators {
        id
        firstName
        lastName
        email
      }
      count
    }
  }
`;
