import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  OuterStackPropsSx,
  PaperPropsSx,
  ContainerStackSx,
  SecondaryButtonSx,
  PrimaryButtonSx,
} from './styles';
import { NewModal, Button } from 'components';
import {
  UNLOCK_DEVICE_CANCEL_NO,
  UNLOCK_DEVICE_CANCEL_YES,
  UNLOCK_DEVICE_CANCEL_TITLE,
  UNLOCK_DEVICE_CANCEL_SUBTITLE,
} from './constants';
import { UnlockDeviceSubmitModalProps } from './types';

const UnlockDeviceConfirmation = (props: UnlockDeviceSubmitModalProps) => {
  const { open, showCloseButtonTopRight, handleClose, handleSubmit } = props;
  return (
    <NewModal
      form
      open={open}
      title={UNLOCK_DEVICE_CANCEL_TITLE}
      subtitle={UNLOCK_DEVICE_CANCEL_SUBTITLE}
      handleClose={handleClose}
      hideCTAs
      onSubmit={handleSubmit}
      submitButtonCopy={UNLOCK_DEVICE_CANCEL_YES}
      cancelButtonCopy={UNLOCK_DEVICE_CANCEL_NO}
      paperPropsSx={PaperPropsSx}
      outerStackPropsSx={OuterStackPropsSx}
      showCloseButtonTopRight={showCloseButtonTopRight}
    >
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        spacing={2}
        sx={ContainerStackSx}
      >
        <Button
          gaContext={{
            navigates_to: 'N/A',
            textCopy: UNLOCK_DEVICE_CANCEL_NO,
            purpose: 'Cancels Unlocking Device',
          }}
          color="secondary"
          onClick={handleClose}
          sx={SecondaryButtonSx}
        >
          <Typography variant="button">{UNLOCK_DEVICE_CANCEL_NO}</Typography>
        </Button>
        <Button
          gaContext={{
            navigates_to: 'N/A',
            purpose: 'Unlocks The Device',
            textCopy: UNLOCK_DEVICE_CANCEL_YES,
          }}
          sx={PrimaryButtonSx}
          onClick={handleSubmit}
          variant="contained"
        >
          <Typography variant="button">{UNLOCK_DEVICE_CANCEL_YES}</Typography>
        </Button>
      </Stack>
    </NewModal>
  );
};

export default UnlockDeviceConfirmation;
