/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useDispatch } from 'react-redux';
import { notificationSlice } from 'store/slices';
import { executeNowThenWait } from 'services/misc';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as myComponents from 'components';
import { OrderCancelModalProps } from './types';
import * as styles from './styles';
import { CANCEL_ORDER } from './mutations';
import { useSafeMutation } from 'hooks/useSafeMutation';

const OrderCancelModal = (props: OrderCancelModalProps) => {
  const { Button: MyButton, NewModal } = myComponents;

  if (!props.openModal) return null;

  const headerCopy = 'Are you sure you want to cancel this order?';

  const dispatch = useDispatch();

  const [cancelOrderMutation, { loading: cancelOrderLoading }] =
    useSafeMutation(CANCEL_ORDER);

  const showChangesSavedSuccessfully = () => {
    dispatch(
      notificationSlice.actions.setNotice({
        showNotice: true,
        noticeContent: 'Order has been cancelled successfully.',
      })
    );
  };

  const closeModalAndRefetch = () => {
    props.handleClose(true);
  };

  const handleCancelOrderClick = () => {
    cancelOrderMutation({
      variables: {
        id: props.orderId,
      },
      onCompleted: () => {
        executeNowThenWait(
          closeModalAndRefetch,
          showChangesSavedSuccessfully,
          500
        );
      },
    });
  };

  const handleCloseModal = () => {
    props.handleClose();
  };

  return (
    <NewModal
      open={props.openModal}
      handleClose={handleCloseModal}
      paperPropsSx={styles.CancelModalPaperSx}
    >
      <Stack
        direction="column"
        spacing={0}
        justifyContent="space-between"
        sx={styles.CancelModalMainStackSx}
      >
        <Typography variant="h5" sx={styles.CancelModalTitleSx}>
          {headerCopy}
        </Typography>
        <Stack
          direction="row"
          spacing={0}
          justifyContent="flex-end"
          sx={{ width: '100%' }}
        >
          <MyButton
            gaContext={{
              textCopy: 'Close',
              navigates_to: 'N/A',
              purpose: 'Closes Cancel Order Modal',
            }}
            id="close-cancel-order-button"
            color="secondary"
            onClick={handleCloseModal}
            sx={styles.CancelModalButtonBaseSx}
          >
            <Typography variant="button">Close</Typography>
          </MyButton>
          <MyButton
            id="submit-cancel-order-button"
            sx={styles.CancelModalButtonBaseSx}
            onClick={handleCancelOrderClick}
            variant="contained"
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Yes, cancel',
              purpose: 'Cancels order',
            }}
          >
            <Typography variant="button">Yes, cancel</Typography>
          </MyButton>
        </Stack>
      </Stack>
    </NewModal>
  );
};

export default OrderCancelModal;
