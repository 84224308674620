import { StatusHistoryStep } from 'types';
import { capitalizeWords } from 'services';
import { OrderStatusTransition } from 'pages/OrderManagement/components/DetailedOrderView/types';
import {
  getFormattedDate,
  getFormattedTime,
} from 'pages/OrderManagement/components/DetailedOrderView/components/OrderSummary/utils';
import { ORDER_STATUS_HISTORY_MAP, INACTIVE_ICON_GRAY } from './constants';

export const convertStatusToStep = (
  orderStatusTransition: OrderStatusTransition,
  options: any = {}
): StatusHistoryStep[] => {
  let steps = [];
  const { from, to, createdAt } = orderStatusTransition;
  const { lastStatus = false, isActive = false, orderCreatedAt } = options;
  const toOrderStatusMap =
    ORDER_STATUS_HISTORY_MAP[to as keyof typeof ORDER_STATUS_HISTORY_MAP];

  steps = [
    {
      stepKey: to,
      stepLabelCopy:
        to === 'PENDING_APPROVAL' ? 'Preparing Order' : capitalizeWords(to),
      stepIcon: toOrderStatusMap.statusIcon,
      iconColor: isActive ? toOrderStatusMap.activeColor : INACTIVE_ICON_GRAY,
      createdAt: `${getFormattedDate(createdAt)} ${getFormattedTime(
        createdAt
      )}`,
    },
  ];

  if (lastStatus && !from) {
    console.warn(
      'OrderStatusTransition missing "from" property. But, it is the last status.'
    );
  }

  if (lastStatus && from) {
    const fromOrderStatusMap =
      ORDER_STATUS_HISTORY_MAP[from as keyof typeof ORDER_STATUS_HISTORY_MAP];
    steps = [
      ...steps,
      {
        stepKey: from,
        stepLabelCopy: capitalizeWords(from),
        stepIcon: fromOrderStatusMap.statusIcon,
        iconColor: INACTIVE_ICON_GRAY,
        createdAt: `${getFormattedDate(orderCreatedAt)} ${getFormattedTime(
          orderCreatedAt
        )}`,
      },
    ];
  }

  return steps;
};
