import _capitalize from 'lodash/capitalize';
/**
 * @param splitter - an underscore by default
 * @returns A function that converts a string argument into 1 or more space-separated words based on the splitter param.
 */
export const capitalizeWithSplitter =
  (splitter: RegExp | string = '_') =>
  (word: string) =>
    word
      .split(splitter)
      .map((str) => _capitalize(str))
      .join(' ');

/** Converts an underscore separated string to multiple space-separated, capitalized words.
 * If there is no underscore present, it just capitalizes the word.
 */
export const capitalizeWords = capitalizeWithSplitter();

// "2 Day shipping", or any other phrase with a number at the beginning
export const capitalizePhraseWithNum = (inputString: string): string => {
  const words: string[] = inputString.split(' ');

  let capitalizeNext = true;

  const resultString: string = words
    .map((currentWord) => {
      const processedWord = capitalizeNext
        ? _capitalize(currentWord)
        : currentWord.toLowerCase();
      capitalizeNext = !Number.isNaN(Number(currentWord));
      return processedWord;
    })
    .join(' ');

  return resultString;
};

export const formatNumberToDecimalString = (numberToFormat: number): string => {
  return numberToFormat.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const fromCamelToSentenceCase = (camelCaseStr: string) => {
  const words = [camelCaseStr[0].toUpperCase()];

  for (let i = 1; i < camelCaseStr.length; i += 1) {
    if (camelCaseStr[i] === camelCaseStr[i].toUpperCase()) {
      words.push(' ');
    }

    words.push(camelCaseStr[i].toLowerCase());
  }

  return words.join('');
};

export const getKebabCase = (
  words: string[] | string,
  options: { wedge?: string | RegExp } = {}
) =>
  [words]
    .flatMap((phrase: string | string[]) => phrase)
    .map((word: string) => word.toLowerCase())
    .flatMap((word: string) => word.split(options.wedge || ' '))
    .join('-');
