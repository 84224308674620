import { SxProps } from '@mui/material';
import { CSSProperties } from 'react';

export const ProgressDivCSS: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
};

export const CircularProgressSx: SxProps = {
  position: 'relative',
  right: '24px',
  width: '100px',
};

// START Order Status History Styles
export const StepperMainTitleSx: SxProps = {
  fontWeight: 500,
};
// END Order Status History Styles
