/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AddOrderItemByEnum } from 'pages/OrderManagement/components/DetailedOrderView/enum';
import AddOrderItemByProductForm from './components/AddOrderItemByProductForm';
import AddOrderItemByAssetForm from './components/AddOrderItemByAssetForm';
import { AddOrderItemModalProps } from './types';
import { getAddOrderItemModalCopyTuple } from './utils';
import * as AddOrderItemModalStyles from './styles';

const AddOrderItemModal = (props: AddOrderItemModalProps) => {
  if (!props.categoryToAddOrderItemBy) return null;
  const styles = AddOrderItemModalStyles;

  const getAddOrderItemModalOpen = () =>
    !!props?.categoryToAddOrderItemBy && !!props?.order;

  const handleCloseModal = () => {
    props.closeModal();
  };

  const [title, subtitle] = getAddOrderItemModalCopyTuple(
    props?.categoryToAddOrderItemBy ?? ''
  );

  const isAddOrderItemByAsset =
    props.categoryToAddOrderItemBy === AddOrderItemByEnum.ASSET;

  return (
    <Dialog
      open={getAddOrderItemModalOpen()}
      sx={styles.getAddOrderItemDialogSx(isAddOrderItemByAsset)}
      onClose={handleCloseModal}
    >
      <Stack
        alignItems="flex-start"
        direction="column"
        sx={styles.AddOrderItemOuterStackSx}
      >
        <Stack>
          <Typography variant="h5" sx={styles.AddOrderItemMainTitleSx}>
            {title}
          </Typography>
          <Typography>{subtitle}</Typography>
        </Stack>
        <Stack
          direction="column"
          justifyContent="space-between"
          sx={styles.AddOrderItemFormOuterStackSx}
        >
          {props.categoryToAddOrderItemBy === AddOrderItemByEnum.PRODUCT ? (
            <AddOrderItemByProductForm
              orderId={props.order.id}
              closeModal={handleCloseModal}
            />
          ) : null}
          {props.categoryToAddOrderItemBy === AddOrderItemByEnum.ASSET ? (
            <AddOrderItemByAssetForm
              orderId={props.order.id}
              closeModal={handleCloseModal}
            />
          ) : null}
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default AddOrderItemModal;
