import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/system';
import { notificationSlice } from 'store/slices';
import { Asset } from 'types';
import { AssetStatus } from 'global-constants';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';
import NewModal from 'components/NewModal';
import DeviceInfo from 'components/DeviceInfo';
import { useSafeMutation } from 'hooks/useSafeMutation';
import { RepairDeviceModalProps } from './types';
import { CREATE_REPAIR_DEVICE_ORDER } from './mutations';
import { DeviceInfoBoxSx, OuterStackPropsSx, PaperPropsSx } from './styles';
import {
  REPAIR_DEVICE_SUBTITLE,
  REPAIR_DEVICE_TITLE,
  SAVE_BUTTON_COPY,
} from './constants';

const RepairDeviceModal = (props: RepairDeviceModalProps) => {
  if (!props.device || !props.organizationId || !props.purchaserId)
    return <></>;

  const { handleSubmit } = useForm();

  const dispatch = useDispatch();

  const [mutate, { loading }] = useSafeMutation(CREATE_REPAIR_DEVICE_ORDER);

  const handleCloseModal = () => {
    props.handleClose();
  };

  const onSubmit = () => {
    mutate({
      variables: {
        purchaserId: props.purchaserId,
        assetId: props.device?.id,
      },
      onCompleted() {
        if (props.refetchSearchAssets) props.refetchSearchAssets();
        props.deviceActionsEnablement.enableActionsByDevice({
          ...props.device,
          status: AssetStatus.PENDING_REPAIR,
        });
        dispatch(
          notificationSlice.actions.setNotice({
            showNotice: true,
            noticeContent: NotificationMessages.REPAIR_REQUEST_SENT,
          })
        );
        props.handleClose();
      },
    });
  };

  const isSubmitButtonDisabled = loading;

  return (
    <NewModal
      form
      open={props.open}
      title={REPAIR_DEVICE_TITLE}
      subtitle={REPAIR_DEVICE_SUBTITLE}
      handleClose={handleCloseModal}
      onSubmit={handleSubmit(onSubmit)}
      submitButtonCopy={SAVE_BUTTON_COPY}
      disableSaveButton={isSubmitButtonDisabled}
      loading={loading}
      paperPropsSx={PaperPropsSx}
      outerStackPropsSx={OuterStackPropsSx}
    >
      <Box sx={DeviceInfoBoxSx}>
        <DeviceInfo
          make={props.device.make}
          model={props.device.model}
          serialNumber={props.device.serialNumber}
        />
      </Box>
    </NewModal>
  );
};

export default RepairDeviceModal;
