/* eslint-disable prettier/prettier */
import { gql } from '@apollo/client';

export const GET_ALL_COMPLETE_ORDERS_TOTAL = gql`
  query searchOrders($organizationId: String) {
    searchOrders(
      searchOrdersInput: { organizationId: $organizationId, status: [COMPLETE] }
    ) {
      count
    }
  }
`;
