import React from 'react';
import { SxProps } from '@mui/material';

export const AppBarSx = (showGlobalNotificationBanner: boolean): SxProps => ({
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0.5rem 1.875rem',
  top: showGlobalNotificationBanner ? '78px' : '0',
  position: 'fixed',
});

export const AllwhereLogoStyles: React.CSSProperties = {
  display: 'block',
  height: 'auto',
  maxHeight: '19px',
  maxWidth: '97px',
  width: 'auto',
};

export const NavLinkStyles: React.CSSProperties = {
  textDecoration: 'none',
};
