import { SxProps } from '@mui/material';

import { PAPER_WIDTH } from './constants';

export const PaperPropsSx: SxProps = {
  height: '100%',
  maxWidth: PAPER_WIDTH,
  width: PAPER_WIDTH,
  overflow: 'hidden !important',
  display: 'flex',
};

export const FormChildrenBoxSx: SxProps = {
  height: '100%',
  overflowY: 'scroll',
};

export const OuterStackPropsSx = {
  height: '100%',
};

export const TitleIconSx = { width: '30px', height: '30px' };

export const FormSx = {
  height: '100%',
  '& form': {
    height: '100%',
  },
  '& .new-modal-form-children-box': {
    height: '100%',
  },
};

export const InnerFormStackSx = {
  height: '100%',
};
