import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { store } from 'store';
import { setAuthToken } from 'store/slices';

export const errorMiddleware = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      console.log('graphQLErrors', JSON.stringify(graphQLErrors));
      for (const err of graphQLErrors) {
        if (err.extensions.code === 'UNAUTHENTICATED') {
          store.dispatch(setAuthToken('unauthenticated'));
        } else {
          Sentry.captureException(new Error(err.message), (scope) => {
            scope.setContext('context', {
              query: JSON.stringify(operation.query),
              operationName: operation.operationName,
              variables: JSON.stringify(operation.variables),
              extensions: JSON.stringify(operation.extensions),
            });
            return scope;
          });
        }
      }
    }
    if (networkError) {
      Sentry.captureException(networkError);
      console.log(`[Network error]: ${networkError}`);
    }
  }
);
