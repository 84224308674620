import {
  Collaborator,
  HomeAddress,
  SelectableRecipientAddress,
  WorkAddress,
} from 'types';
import {
  ProcurementFlowEmailType,
  ProcurementFlowModals,
  ProcurementFlowStep,
  ProcurementServiceActions,
} from './constants';
import { reducer } from './reducer';
import {
  AddProductVariantPayload,
  InitialState,
  RemoveProductVariantPayload,
  UseProcurementService,
  CartItem,
  SelectedDepot,
} from './types';
//@ts-ignore
import createPersistedReducer from 'use-persisted-reducer';
import React from 'react';
import { isProductVariantInCart } from './utils';
import useGetUserPermissions from 'hooks/useGetUserPermissions';
import { Depot, UserPermissions } from 'global-constants';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import NoFlagSVGIcon from 'components/Icons/flags/NoFlagSVGIcon';

const initialState: InitialState = {
  cart: [],
  checkoutNotes: '',
  currentModal: ProcurementFlowModals.NONE,
  flowStep: ProcurementFlowStep.COLLABORATOR_VIEW,
  selectedCollaborators: [],
  selectedCollaboratorRecipientEmailMap: {},
  collaboratorsEmailType: ProcurementFlowEmailType.NONE,
  selectedDepotLocation: {
    id: '',
    name: '',
    flag: NoFlagSVGIcon,
  },
  quoteRequested: false,
  shippingTypeId: null,
};

export const useProcurementService = (
  orderType: 'procurement' | 'buy_and_hold' = 'procurement'
): UseProcurementService => {
  const usePersistedReducer = createPersistedReducer(orderType);
  const [state, dispatch] = usePersistedReducer(reducer, initialState);
  const userOrganization = useGetUserOrganization();
  const userPermissions = useGetUserPermissions();
  const isUserInternalAdmin = userPermissions.includes(
    UserPermissions.ORGANIZATION__TOGGLE
  );

  const addSelectedDepot = (depotLocation: SelectedDepot) => {
    dispatch({
      type: ProcurementServiceActions.ADD_SELECTED_DEPOT,
      payload: depotLocation,
    });
  };

  const removeSelectedDepot = () => {
    dispatch({
      type: ProcurementServiceActions.REMOVE_SELECTED_DEPOT,
    });
  };

  const addSelectedCollaborator = (collaborator: Collaborator) => {
    dispatch({
      type: ProcurementServiceActions.ADD_SELECTED_COLLABORATOR,
      payload: collaborator,
    });
  };

  const updateSelectedCollaboratorRecipientEmail =
    (collaboratorSelectedEmailMapping: {
      collaboratorId: string;
      email: string;
    }) => {
      dispatch({
        type: ProcurementServiceActions.UPDATE_SELECTED_COLLABORATOR_RECIPIENT_EMAIL,
        payload: collaboratorSelectedEmailMapping,
      });
    };

  const updateEmailTypeForAllCollaborators = (
    emailType: ProcurementFlowEmailType
  ) => {
    dispatch({
      type: ProcurementServiceActions.UPDATE_EMAIL_TYPE_FOR_ALL_COLLABORATORS,
      payload: emailType,
    });
  };

  const updateSelectedCollaboratorRecipientAddress = (
    id: string,
    address: SelectableRecipientAddress
  ) => {
    dispatch({
      type: ProcurementServiceActions.UPDATE_SELECTED_COLLABORATOR_RECIPIENT_ADDRESS,
      payload: { id, address },
    });
  };

  const removeSelectedCollaboratorRecipientAddress = (id: string) => {
    dispatch({
      type: ProcurementServiceActions.REMOVE_SELECTED_COLLABORATOR_RECIPIENT_ADDRESS,
      payload: { id, address: undefined },
    });
  };

  const addSelectedCollaboratorAddresses = (
    id: string,
    homeAddresses: HomeAddress[],
    workAddresses: WorkAddress[]
  ) => {
    dispatch({
      type: ProcurementServiceActions.ADD_SELECTED_COLLABORATOR_ADDRESSES,
      payload: { id, homeAddresses, workAddresses },
    });
  };

  const addSelectedCollaboratorSelectableAddresses = (
    id: string,
    selectableAddresses: SelectableRecipientAddress[]
  ) => {
    dispatch({
      type: ProcurementServiceActions.ADD_SELECTED_COLLABORATOR_SELECTABLE_ADDRESSES,
      payload: { id, selectableAddresses },
    });
  };

  const deselectAllCollaborators = () => {
    dispatch({
      type: ProcurementServiceActions.DESELECT_ALL_COLLABORATORS,
      payload: [],
    });
  };

  const removeSelectedCollaborator = (collaborator: Collaborator) => {
    dispatch({
      type: ProcurementServiceActions.REMOVE_SELECTED_COLLABORATOR,
      payload: collaborator,
    });
  };

  const updateProcurementFlowStep = React.useCallback(
    (newStep: ProcurementFlowStep) => {
      dispatch({
        type: ProcurementServiceActions.UPDATE_EXPERIENCE_STEP,
        payload: newStep,
      });
    },
    [dispatch]
  );

  const setCheckoutNotes = (checkoutNotes: string) => {
    dispatch({
      type: ProcurementServiceActions.SET_CHECKOUT_NOTES,
      payload: { checkoutNotes },
    });
  };

  const setCurrentModal = (newCurrentModal: ProcurementFlowModals) => {
    dispatch({
      type: ProcurementServiceActions.SET_CURRENT_MODAL,
      payload: newCurrentModal,
    });
  };

  const dismissCurrentModal = React.useCallback(() => {
    dispatch({
      type: ProcurementServiceActions.DISMISS_CURRENT_MODAL,
      payload: ProcurementFlowModals.NONE,
    });
  }, [dispatch]);

  const addProductVariantToCart = (payload: AddProductVariantPayload) => {
    const { productVariantId, insuranceAmount, protectionPlan } = payload;

    const isProductVariantInCartAlready = isProductVariantInCart(
      productVariantId,
      state.cart,
      {
        insuranceAmount,
        protectionPlan,
      }
    );

    if (isProductVariantInCartAlready) {
      dispatch({
        type: ProcurementServiceActions.UPDATE_PRODUCT_VARIANT_QUANTITY_IN_CART,
        payload: {
          productVariantId,
          operand: payload.quantity,
          operation: 'add',
        },
      });
    } else {
      dispatch({
        type: ProcurementServiceActions.ADD_PRODUCT_VARIANT_TO_CART,
        payload,
      });
    }
  };

  const removeProductVariantFromCart = (
    payload: RemoveProductVariantPayload
  ) => {
    dispatch({
      type: ProcurementServiceActions.REMOVE_PRODUCT_VARIANT_FROM_CART,
      payload,
    });
  };

  const checkoutOrder = (quoteRequested = false) => {
    dispatch({
      type: ProcurementServiceActions.CHECKOUT_ORDER,
      payload: {
        flowStep: ProcurementFlowStep.ORDER_SUBMITTED,
        cart: [],
        selectedCollaborators: [],
        quoteRequested,
      },
    });
  };

  const updateShippingTypeId = (shippingTypeId: string) => {
    dispatch({
      type: ProcurementServiceActions.UPDATE_SHIPPING_TYPE_ID,
      payload: shippingTypeId,
    });
  };

  const clearShippingTypeId = () => {
    dispatch({
      type: ProcurementServiceActions.CLEAR_SHIPPING_TYPE_ID,
    });
  };

  const reset = () => {
    dispatch({
      type: ProcurementServiceActions.RESET,
      payload: initialState,
    });
  };

  const removeCheckoutNotes = () => setCheckoutNotes('');

  const shouldGoBackToCollaboratorView = () => {
    let noRecipients =
      orderType === 'procurement'
        ? state.selectedCollaborators.length === 0
        : false;

    noRecipients =
      orderType === 'buy_and_hold'
        ? !state.selectedDepotLocation?.id
        : noRecipients;

    return (
      noRecipients &&
      state.flowStep !== ProcurementFlowStep.COLLABORATOR_VIEW &&
      state.flowStep !== ProcurementFlowStep.ORDER_SUBMITTED
    );
  };

  const changeQuantityOfProductVariant = (
    productVariantId: string,
    newQuantity: number
  ) => {
    dispatch({
      type: ProcurementServiceActions.UPDATE_PRODUCT_VARIANT_QUANTITY_IN_CART,
      payload: {
        productVariantId,
        operand: newQuantity,
        operation: 'replace',
      },
    });
  };

  React.useEffect(() => {
    // If the user deselects all collaborators or removes depotLocation, close any modals and take them back to the collaborator view screen
    if (shouldGoBackToCollaboratorView()) {
      dismissCurrentModal();
      updateProcurementFlowStep(ProcurementFlowStep.COLLABORATOR_VIEW);
    }
  }, [
    orderType,
    dismissCurrentModal,
    state.selectedCollaborators,
    state.flowStep,
    state.selectedDepotLocation,
  ]);

  React.useEffect(() => {
    //If user is on the Order summary page and removes all items from the cart; redirect them back to the product catalog screen
    if (
      state.flowStep === ProcurementFlowStep.ORDER_SUMMARY &&
      state.cart.length === 0
    ) {
      updateProcurementFlowStep(ProcurementFlowStep.PRODUCT_CATALOG);
    }
  }, [state.cart.length, state.flowStep]);

  React.useEffect(() => {
    // Internal admins will change organizations; therefore reset the state
    const isUserOrgInSelectedCollaborators = state.selectedCollaborators.some(
      (selectedCollaborator: Collaborator) =>
        selectedCollaborator.organizationId === userOrganization?.id
    );
    if (
      isUserInternalAdmin &&
      !isUserOrgInSelectedCollaborators &&
      state.flowStep !== ProcurementFlowStep.ORDER_SUBMITTED &&
      !state.selectedDepotLocation
    ) {
      reset();
    }
  }, [
    userOrganization?.id,
    isUserInternalAdmin,
    state.selectedCollaborators,
    state.selectedDepotLocation,
    state.flowStep,
  ]);

  const getCartItemsCount = () => {
    if (!state.cart.length) return 0;
    return state.cart.reduce((total: number, nextItem: CartItem) => {
      return total + nextItem.quantity;
    }, 0);
  };

  const setQuoteRequested = (quoteRequested: boolean) => {
    dispatch({
      type: ProcurementServiceActions.UPDATE_QUOTE_REQUESTED,
      payload: {
        quoteRequested,
      },
    });
  };

  return {
    //methods
    addProductVariantToCart,
    addSelectedCollaborator,
    updateSelectedCollaboratorRecipientEmail,
    updateEmailTypeForAllCollaborators,
    updateSelectedCollaboratorRecipientAddress,
    removeSelectedCollaboratorRecipientAddress,
    addSelectedCollaboratorAddresses,
    addSelectedCollaboratorSelectableAddresses,
    checkoutOrder,
    deselectAllCollaborators,
    dismissCurrentModal,
    removeCheckoutNotes,
    removeProductVariantFromCart,
    removeSelectedCollaborator,
    setCheckoutNotes,
    setCurrentModal,
    updateProcurementFlowStep,
    addSelectedDepot,
    removeSelectedDepot,
    getCartItemsCount,
    changeQuantityOfProductVariant,
    setQuoteRequested,
    updateShippingTypeId,
    clearShippingTypeId,
    reset,

    //variables
    orderTypeFlow: orderType,
    cart: state.cart,
    checkoutNotes: state.checkoutNotes,
    flowStep: state.flowStep,
    currentModal: state.currentModal,
    selectedCollaborators: state.selectedCollaborators,
    selectedCollaboratorRecipientEmailMap:
      state.selectedCollaboratorRecipientEmailMap,
    collaboratorsEmailType: state.collaboratorsEmailType,
    selectedDepotLocation: state.selectedDepotLocation,
    quoteRequested: state.quoteRequested,
    shippingTypeId: state.shippingTypeId,
  };
};
