import { OrderStatus } from 'global-constants';

export const ORDER_MGMT_NEW_MODAL_MAX_PAPER_WIDTH = '1337px';
export const XS_SM_MODAL_HEIGHT_OFFSET = '376px';
export const MD_XL_MODAL_HEIGHT_OFFSET = '272px';
export const XS_SM_MODAL_WIDTH_OFFSET = '64px';
export const MD_XL_MODAL_WIDTH_OFFSET = '48px';
export const MD_XL_MODAL_INNER_HEIGHT_OFFSET = '370px';

const CANCELED = 'CANCELED';
export const READ_ONLY_ORDER_TRANSITION_STATUSES = [
  CANCELED,
  ...Object.keys(OrderStatus).slice(1),
] as const;
