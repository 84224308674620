import { Order } from 'types';
import { OrderType as OrderTypeEnum } from 'global-constants';
import {
  ORDER_ITEM_TITLE_FALLBACKS,
  INBOUND_RETRIEVAL_KIT,
  OUTBOUND_RETRIEVAL_KIT,
} from './constants';

const isRetrievalTypeOrderItem = (order: Partial<Order>): boolean => {
  const isRetrieval = OrderTypeEnum.RETRIEVAL === order?.orderType?.name;
  const isBulkRetrieval =
    OrderTypeEnum.BULK_RETRIEVAL === order?.orderType?.name;
  const isDropRetrieval =
    OrderTypeEnum.DROP_RETRIEVAL === order?.orderType?.name;
  return isRetrieval || isBulkRetrieval || isDropRetrieval;
};

export const getOrderItemTitle = (
  order: Partial<Order>,
  orderItemObject: Record<string, any>
  //eslint-disable-next-line sonarjs/cognitive-complexity
): string[] => {
  const {
    asset = null,
    productVariant = null,
    inboundOrderItemId = null,
  } = orderItemObject;

  if (isRetrievalTypeOrderItem(order) && asset) {
    return [INBOUND_RETRIEVAL_KIT];
  }

  if (isRetrievalTypeOrderItem(order) && !asset && inboundOrderItemId) {
    return [OUTBOUND_RETRIEVAL_KIT];
  }

  if (asset && !productVariant) {
    const { assetMake, assetModel } = ORDER_ITEM_TITLE_FALLBACKS;
    return [asset.make ?? assetMake, asset.model ?? assetModel];
  }

  if (productVariant) {
    const { product, productVariantAttributes } = productVariant;
    const attributesNullOrEmpty =
      !productVariantAttributes || productVariantAttributes.length === 0;

    if (attributesNullOrEmpty) return [product.name];
    const attributeStrings = productVariantAttributes.reduce(
      (acc: string[], attr: Record<string, string>, index: number) => {
        if (attr.value && index < 2) {
          acc.push(attr.value);
        }
        // maintain same number of title parts as the order item originating from an asset
        if (attr.value && index >= 2) {
          acc[1] += `, ${attr.value}`;
        }
        return acc;
      },
      []
    );

    return [product.name, ...attributeStrings];
  }

  return ['N/A'];
};
