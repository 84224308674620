/* eslint-disable sort-exports/sort-exports */
import React, { ReactNode } from 'react';
import { RetrievalOptionInputProps } from './types';
import {
  RADIO,
  SINGLE_SELECT,
  TEXT,
  TEXT_AREA,
} from 'pages/Retrieval/components/ReviewStep/components/RetrievalOptionValueForm/constants';
import TextFieldOption from './components/TextFieldOption';
import RadioOption from './components/RadioOption';
import TextAreaOption from './components/TextAreaOption';
import SingleSelectOption from './components/SingleSelectOption';

const RetrievalOptionValueInput = (props: RetrievalOptionInputProps) => {
  const getRetrievalOptionByType = (retrievalOptionType: string): ReactNode => {
    switch (retrievalOptionType) {
      case TEXT:
        return <TextFieldOption {...props} />;

      case RADIO:
        return <RadioOption {...props} />;

      case TEXT_AREA:
        return <TextAreaOption {...props} />;

      case SINGLE_SELECT:
        return <SingleSelectOption {...props} />;

      default:
        return <></>;
    }
  };

  return <>{getRetrievalOptionByType(props.type)}</>;
};

export default RetrievalOptionValueInput;
