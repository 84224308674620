import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { SxProps } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { OrderType } from 'global-constants';
import theme from 'themes/theme';
import { getEnumValues } from 'services/enums';
import {
  ClosedBoxSVGIcon,
  OpenBoxSVGIcon,
  RecycleDeviceSVGIcon,
} from 'components/Icons';
import { BackupIconStyles } from './styles';

export const getBackupIconByOrderTypeName = (
  orderTypeName: string,
  isInbound: boolean = false
): JSX.Element => {
  const styles = BackupIconStyles;
  const orderTypeEnums = getEnumValues(OrderType);

  const hasOrderTypeName = orderTypeEnums.some(
    (typeName: string) => typeName === orderTypeName
  );

  if (!hasOrderTypeName) {
    console.warn(
      `Order type: ${orderTypeName} was not found. Check OrderType enum definition in 'src/global-constants.ts'`
    );

    return <></>;
  }

  const SVG_SIZE = 60;

  switch (orderTypeName) {
    case OrderType.PROCUREMENT_TO_DEPOT:
    case OrderType.PROCUREMENT_TO_RECIPIENT:
      return (
        <Avatar sx={styles.AvatarSx as SxProps}>
          <ShoppingCartOutlinedIcon height="auto" />
        </Avatar>
      );

    case OrderType.BULK_RETRIEVAL:
    case OrderType.DROP_RETRIEVAL:
    case OrderType.RETRIEVAL:
      return (
        <Avatar sx={styles.AvatarSx as SxProps}>
          {isInbound ? (
            <OpenBoxSVGIcon height={30} width={30} />
          ) : (
            <ClosedBoxSVGIcon height={SVG_SIZE} width={SVG_SIZE} />
          )}
        </Avatar>
      );

    case OrderType.DEPLOYMENT:
      return (
        <Avatar sx={styles.AvatarSx as SxProps}>
          <HomeWorkOutlinedIcon />
        </Avatar>
      );

    case OrderType.REPAIR:
      return (
        <Avatar sx={styles.AvatarSx as SxProps}>
          <BuildOutlinedIcon />
        </Avatar>
      );

    case OrderType.PROCESS:
      return (
        <Avatar sx={styles.AvatarSx as SxProps}>
          <SettingsOutlinedIcon />
        </Avatar>
      );

    case OrderType.RECYCLE:
      return (
        <Avatar sx={styles.AvatarSx as SxProps}>
          <RecycleDeviceSVGIcon
            height={SVG_SIZE}
            width={SVG_SIZE}
            fill={theme.palette.colors.woodSmoke}
          />
        </Avatar>
      );

    case OrderType.DEVICE_UNLOCK:
      return (
        <Avatar sx={styles.AvatarSx as SxProps}>
          <LockOpenIcon
            height={SVG_SIZE}
            width={SVG_SIZE}
            fill={theme.palette.colors.woodSmoke}
          />
        </Avatar>
      );

    default:
      return <></>;
  }
};
