import { gql } from '@apollo/client';

export const UPDATE_ORDER_INTERNAL_NOTES = gql`
  mutation updateOrder($id: ID!, $internalNotes: String) {
    updateOrder(orderUpdateInput: { id: $id, internalNotes: $internalNotes }) {
      id
      internalNotes
    }
  }
`;
