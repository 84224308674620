/* eslint-disable sort-exports/sort-exports */
import Box from '@mui/material/Box';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { UploadPopoverTitleSx, UploadPopoverTopBoxSx } from './styles';
import UploadPopoverHeaderProps from './types';

const UploadPopoverHeader = (props: UploadPopoverHeaderProps) => {
  return (
    <Box sx={UploadPopoverTopBoxSx}>
      <Typography sx={UploadPopoverTitleSx}>
        Import {props.domainLabel}
      </Typography>
      <IconButton
        onClick={props.onClosePopover}
        data-testid={`${props.domainLabel}-filter-by-close-icon`}
        aria-label={`${props.domainLabel}-filter-by-close-icon`}
      >
        <CloseSharpIcon />
      </IconButton>
    </Box>
  );
};

export default UploadPopoverHeader;
