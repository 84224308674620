import React from 'react';
import { SxProps } from '@mui/material';
import theme from 'themes/theme';
import { DRAWER_WIDTH, HEADER_HEIGHT } from 'global-styles';
import {
  SEARCH_BAR_EXTERNAL_RADIUS,
  SEARCH_BAR_TYPE_SELECT_WIDTH,
  SEARCH_BAR_WHOLE_WIDTH,
  SEARCH_BAR_WHOLE_HEIGHT,
} from 'pages/Assets/components/DevicesDatagrid/constants';

export const AutoCompleteStackSx: SxProps = {
  backgroundColor: theme.palette.colors.grayF6,
  borderRadius: SEARCH_BAR_EXTERNAL_RADIUS,
  width: SEARCH_BAR_WHOLE_WIDTH,
  height: SEARCH_BAR_WHOLE_HEIGHT,
  position: 'relative',
  '&:hover': {
    outline: '1px solid',
  },

  // only need if the stack contains a searchIcon
  '& hr:first-of-type': {
    display: 'none',
  },
};

export const AutoCompleteSx: SxProps = {
  p: 0,
  backgroundColor: 'transparent',
  border: 'none',
  height: SEARCH_BAR_WHOLE_HEIGHT,
  position: 'relative',
  width: `calc(100% - ${SEARCH_BAR_EXTERNAL_RADIUS})`,

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },

  '& .MuiInputBase-root': {
    backgroundColor: 'transparent',
    borderTopLeftRadius: SEARCH_BAR_EXTERNAL_RADIUS,
    borderBottomLeftRadius: SEARCH_BAR_EXTERNAL_RADIUS,
  },

  '& .MuiFormControl-root': {
    backgroundColor: 'transparent',
  },

  '& .MuiInputBase-formControl': {
    backgroundColor: 'transparent',
    height: SEARCH_BAR_WHOLE_HEIGHT,
    py: 0,
  },

  '& .MuiAutocomplete-endAdornment': {
    '& .MuiAutocomplete-popupIndicator': {
      visibility: 'hidden',
    },
    '& .MuiAutocomplete-clearIndicator': {
      visibility: 'visible',
    },
  },
};

export const AutoCompleteSearchTextFieldSx: SxProps = {
  p: '0',
  pl: 3,
  borderRadius: 0,
  borderTopLeftRadius: SEARCH_BAR_EXTERNAL_RADIUS,
  borderBottomLeftRadius: SEARCH_BAR_EXTERNAL_RADIUS,
};

export const AutoCompleteSelectSx: SxProps = {
  p: 0,
  width: SEARCH_BAR_TYPE_SELECT_WIDTH,
  backgroundColor: 'transparent',
  borderTopRightRadius: SEARCH_BAR_EXTERNAL_RADIUS,
  borderBottomRightRadius: SEARCH_BAR_EXTERNAL_RADIUS,

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};

export const AutoCompleteSelectMenuSx: SxProps = {
  width: SEARCH_BAR_TYPE_SELECT_WIDTH,
};

export const AutoCompleteSearchIconSx: SxProps = {
  color: theme.palette.colors.gray75,
  position: 'absolute',
  top: '8px',
  left: '8px',
};

export const getClearIndicatorSx = (searchTerm: string): SxProps => ({
  display: searchTerm ? 'inherit' : 'none',
});

export const SelectDisplayCSS: React.CSSProperties = {
  width: SEARCH_BAR_TYPE_SELECT_WIDTH,
  paddingTop: '8px',
  paddingBottom: '8px',
};

export const AutocompletePaperCSS: React.CSSProperties = {
  position: 'relative',
  top: '3px',
  left: '75px',
};
