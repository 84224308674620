import { gql } from '@apollo/client';

export const GET_SHIPPING_TYPE_OPTIONS_BY_ORDER_TYPE = gql`
  query searchOrderTypes($orderTypeName: String) {
    searchOrderTypes(searchOrderTypesInput: { name: $orderTypeName }) {
      count
      orderTypes {
        name
        shippingTypeOptions {
          id
          days
          price
          shippingTypeId
          shippingType {
            name
            description
          }
        }
      }
    }
  }
`;
