/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import { SEARCH_DEPOTS } from 'pages/Assets/components/DevicesDatagrid/queries';
import { GET_ALL_ASSET_TYPES } from './queries';
import AssetTypeFilter from './components/AssetTypeFilter';
import AssetMakeFilter from './components/AssetMakeFilter';
import AssetModelFilter from './components/AssetModelFilter';
import AssetStatusFilter from './components/AssetStatusFilter';
import AssetDepotFilter from './components/AssetDepotFilter';
import AssetConditionFilter from './components/AssetConditionFilter';
import AssetDisplaySizeFilter from './components/AssetDisplaySizeFilter';
import AssetMemoryFilter from './components/AssetMemoryFilter';
import AssetStorageFilter from './components/AssetStorageFilter';
import AssetProcessorFilter from './components/AssetProcessorFilter';
import { selectClearFilters } from 'store/slices/assets/comboBoxFilter/selectors';
import { DeviceFiltersProps } from './types';
import * as DeviceFilterStyles from './styles';
import GAButton from 'components/buttons/google_analytics/GAButton';

const DeviceFilters = (props: DeviceFiltersProps) => {
  const styles = DeviceFilterStyles;
  // component-level filter state
  const [assetTypeOptions, setAssetTypeOptions] = React.useState<string[]>([]);
  const [assetDepotOptions, setAssetDepotOptions] = React.useState<string[]>(
    []
  );
  const [chosenAssetType, setChosenAssetType] = React.useState<string>('');
  const [chosenAssetMake, setChosenAssetMake] = React.useState<string>('');
  const [chosenAssetModel, setChosenAssetModel] = React.useState<string>('');
  const [chosenAssetStatus, setChosenAssetStatus] = React.useState<string>('');
  const [chosenAssetCondition, setChosenAssetCondition] =
    React.useState<string>('');
  const [chosenAssetDepotName, setChosenAssetDepotName] =
    React.useState<string>('');
  const [chosenAssetDisplaySize, setChosenAssetDisplaySize] =
    React.useState<string>('');
  const [chosenAssetMemory, setChosenAssetMemory] = React.useState<string>('');
  const [chosenAssetStorage, setChosenAssetStorage] =
    React.useState<string>('');
  const [chosenAssetProcessor, setChosenAssetProcessor] =
    React.useState<string>('');

  const clearFilters = useSelector(selectClearFilters);

  const [fetchAllAssetTypes, { loading: loadingAssetTypes }] = useSafeLazyQuery(
    GET_ALL_ASSET_TYPES,
    {
      onCompleted: (data) => {
        const assetTypes = data.getAllAssetTypes || [];
        if (!assetTypes.length) return;
        const tempAssetTypeOptions = assetTypes.map(
          (assetItem: any) => assetItem.name
        );
        setAssetTypeOptions(['', ...tempAssetTypeOptions]);
      },
    }
  );

  const [fetchAllDepots, { loading: loadingDepots }] = useSafeLazyQuery(
    SEARCH_DEPOTS,
    {
      onCompleted: (data) => {
        const depots = data?.searchDepots.depots || [];
        if (!depots.length) return;
        const tempDepotOptions = depots.map((depotItem: any) => depotItem.name);
        setAssetDepotOptions(['', ...tempDepotOptions]);
      },
    }
  );

  const fetchStartingQueries = () => {
    fetchAllAssetTypes();
    fetchAllDepots();
  };

  const clearAllFilters = () => {
    props.setAssetTypeName('');
    props.setAssetMake('');
    props.setAssetModel('');
    props.setAssetStatus('');
    props.setAssetPhysicalCondition('');
    props.setAssetDepotName('');
    props.setAssetDisplaySize('');
    props.setAssetMemory('');
    props.setAssetStorage('');
    props.setAssetProcessor('');
    setChosenAssetType('');
    setChosenAssetMake('');
    setChosenAssetModel('');
    setChosenAssetStatus('');
    setChosenAssetCondition('');
    setChosenAssetDepotName('');
    setChosenAssetDisplaySize('');
    setChosenAssetMemory('');
    setChosenAssetStorage('');
    setChosenAssetProcessor('');
  };

  const showClearAllFiltersButton = () =>
    !!chosenAssetType ||
    !!chosenAssetMake ||
    !!chosenAssetModel ||
    !!chosenAssetStatus ||
    !!chosenAssetCondition ||
    !!chosenAssetDisplaySize ||
    !!chosenAssetMemory ||
    !!chosenAssetStorage ||
    !!chosenAssetProcessor ||
    !!chosenAssetDepotName;

  React.useEffect(() => {
    fetchStartingQueries();
  }, []);

  React.useEffect(() => {
    props.setAssetTypeName(chosenAssetType);
  }, [chosenAssetType]);

  React.useEffect(() => {
    props.setAssetMake(chosenAssetMake);
  }, [chosenAssetMake]);

  React.useEffect(() => {
    props.setAssetModel(chosenAssetModel);
  }, [chosenAssetModel]);

  React.useEffect(() => {
    props.setAssetStatus(chosenAssetStatus);
  }, [chosenAssetStatus]);

  React.useEffect(() => {
    props.setAssetPhysicalCondition(chosenAssetCondition);
  }, [chosenAssetCondition]);

  React.useEffect(() => {
    props.setAssetDepotName(chosenAssetDepotName);
  }, [chosenAssetDepotName]);

  React.useEffect(() => {
    props.setAssetDisplaySize(chosenAssetDisplaySize);
  }, [chosenAssetDisplaySize]);

  React.useEffect(() => {
    props.setAssetMemory(chosenAssetMemory);
  }, [chosenAssetMemory]);

  React.useEffect(() => {
    props.setAssetStorage(chosenAssetStorage);
  }, [chosenAssetStorage]);

  React.useEffect(() => {
    props.setAssetProcessor(chosenAssetProcessor);
  }, [chosenAssetProcessor]);

  React.useEffect(() => {
    if (!clearFilters) return;
    clearAllFilters();
  }, [clearFilters]);

  const filtersLoading = loadingAssetTypes || loadingDepots;

  return (
    <Stack direction="column" gap={1}>
      {filtersLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Stack
            direction="row"
            gap={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: '36px', mt: 2 }}
          >
            <Typography component="span" sx={styles.AssetFilterTitleSx}>
              FILTER BY
            </Typography>
            {showClearAllFiltersButton() ? (
              <GAButton
                gaContext={{
                  navigates_to: 'N/A',
                  textCopy: 'Clear all filters',
                  purpose: 'Clears Devices All Filters',
                }}
                disableRipple
                startIcon={<CloseIcon />}
                sx={styles.DeviceFilterClearButtonSx}
                onClick={clearAllFilters}
              >
                <Typography>Clear all filters</Typography>
              </GAButton>
            ) : null}
          </Stack>
          {/* FILTER DROPDOWN STACK START */}
          <Stack direction="column" gap={2}>
            <Stack direction="row" gap={2}>
              <AssetTypeFilter
                assetTypeOptions={assetTypeOptions}
                chosenAssetType={chosenAssetType}
                setChosenAssetType={setChosenAssetType}
              />
              <AssetMakeFilter
                chosenAssetMake={chosenAssetMake}
                setChosenAssetMake={setChosenAssetMake}
              />
              <AssetModelFilter
                chosenAssetModel={chosenAssetModel}
                setChosenAssetModel={setChosenAssetModel}
              />
              <AssetStatusFilter
                chosenAssetStatus={chosenAssetStatus}
                setChosenAssetStatus={setChosenAssetStatus}
              />
              <AssetConditionFilter
                chosenAssetCondition={chosenAssetCondition}
                setChosenAssetCondition={setChosenAssetCondition}
              />
              <AssetDepotFilter
                assetDepotOptions={assetDepotOptions}
                chosenAssetDepot={chosenAssetDepotName}
                setChosenAssetDepot={setChosenAssetDepotName}
              />
            </Stack>

            <Stack direction="row" gap={2}>
              <AssetDisplaySizeFilter
                chosenAssetDisplaySize={chosenAssetDisplaySize}
                setChosenAssetDisplaySize={setChosenAssetDisplaySize}
              />
              <AssetMemoryFilter
                chosenAssetMemory={chosenAssetMemory}
                setChosenAssetMemory={setChosenAssetMemory}
              />
              <AssetStorageFilter
                chosenAssetStorage={chosenAssetStorage}
                setChosenAssetStorage={setChosenAssetStorage}
              />
              <AssetProcessorFilter
                chosenAssetProcessor={chosenAssetProcessor}
                setChosenAssetProcessor={setChosenAssetProcessor}
              />
            </Stack>
          </Stack>
          {/* FILTER DROPDOWN STACK END */}
        </>
      )}
    </Stack>
  );
};

export default DeviceFilters;
