import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const UserAvatarPopoverSx: SxProps = {
  minWidth: '200px',
  top: '10px',
  '& .MuiList-root': {
    pt: '32px',
  },

  '& .MuiListItemButton-root': {
    pl: '24px',
    '&:hover': {
      backgroundColor: theme.palette.colors.backgroundYO,
    },
  },

  '& .MuiListItem-root': {
    pl: '24px',
    py: 0,
    '&.username-dropdown': {
      fontWeight: 500,
    },
    '&.user-email-dropdown': {
      color: theme.palette.colors.gray70,
      pb: '25px',
    },
  },

  '& .MuiListItemIcon-root': {
    mr: '-15px',
  },
};
