import { SxProps } from '@mui/material';

export const FilterContainerSx: SxProps = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
};

export const FilterSx: SxProps = {
  cursor: 'pointer',
};
