import { gql } from '@apollo/client';

export const RETRIEVE_ASSET = gql`
  query getAsset($assetId: ID!) {
    getAsset(retrieveAssetInput: { id: $assetId }) {
      id
      thirdPartyAssetId
      assetNumber
    }
  }
`;
