import { SxProps } from '@mui/material';

export const ModalPaperSx: SxProps = {
  maxWidth: '524px',
  maxHeight: {
    xs: '93vh',
    md: '430px',
  },
};

export const OuterStackSx: SxProps = {
  justifyContent: 'flex-start',
  rowGap: '1rem',
  height: '100%',
};
