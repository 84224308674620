import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const AddNoteUpdateButton: SxProps = {
  backgroundColor: theme.palette.colors.grayF6,
  color: theme.palette.colors.black,
  borderColor: theme.palette.colors.black,
  maxWidth: '100px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxHeight: '30px',
};

export const TextField: SxProps = {
  width: '100%',
  height: '300%',
  borderColor: theme.palette.colors.black,
  backgroundColor: 'transparent',
  p: 0,

  '& .MuiFormHelperText-root': {
    color: 'transparent',
  },
  '& .updateNoteButton': {
    margin: '0.5em',
  },
  '.textFieldContainer': {
    width: '100%',
  },
};

export const saveCancelButtonContainer: SxProps = {
  display: 'flex',
  justifyContent: 'flex-end',
};

export const AssetNoteSpanField: SxProps = {
  justifyContent: 'space-between',
  '.assetNoteSpanField': {
    width: '100%',
    maxWidth: '640px',
  },
};

export const CancelButton: SxProps = {
  color: theme.palette.colors.black,
  minWidth: '5px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxHeight: '30px',
};

export const SaveButton: SxProps = {
  color: theme.palette.colors.black,
  background: theme.palette.colors.maizeYO,
  minWidth: '5px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxHeight: '30px',
};
