import { gql } from '@apollo/client';

export const GET_ASSETS_READY_FOR_DEPLOYMENT_TOTAL = gql`
  query searchAssets($organizationId: ID!) {
    searchAssets(
      assetsSearchInput: {
        organizationId: $organizationId
        status: MONO_IN_INVENTORY
      }
    ) {
      count
    }
  }
`;

export const GET_ALLOCATED_ASSETS_TOTAL = gql`
  query searchAssets($organizationId: ID!) {
    searchAssets(
      assetsSearchInput: {
        organizationId: $organizationId
        status: MONO_ALLOCATED
      }
    ) {
      count
    }
  }
`;

export const GET_ASSETS_PENDING_RETRIEVAL_TOTAL = gql`
  query searchAssets($organizationId: ID!) {
    searchAssets(
      assetsSearchInput: {
        organizationId: $organizationId
        status: MONO_PENDING_RETRIEVAL
      }
    ) {
      count
    }
  }
`;

export const GET_ASSETS_TOTAL = gql`
  query searchAssets($organizationId: ID!) {
    searchAssets(assetsSearchInput: { organizationId: $organizationId }) {
      count
    }
  }
`;
