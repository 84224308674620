const ArgentinaFlagSVGIcon = () => {
  return (
    <svg
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g filter="url(#filter0_d_1_4)">
        <rect
          x="0"
          width="31"
          height="20"
          fill="url(#pattern0_1_4)"
          shape-rendering="crispEdges"
        />
      </g>
      <defs>
        <pattern
          id="pattern0_1_4"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_1_4"
            transform="matrix(0.03125 0 0 0.0520833 0 -0.0208333)"
          />
        </pattern>
        <image
          id="image0_1_4"
          width="28"
          height="20"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAUBAMAAAAevyJ8AAAALVBMVEX///90rN9rp92hx+n68u3v28nMllOjye3fmgmjyu6iyu7YtpbLllPYtZZ0p+ni+S7hAAAAQklEQVQY02MQRAMM1BBQQgMMxhBQtRzKYIAC1xAGFMBy56wDigBrRk8AmkAbqgDLntOoWpAMhdpWOR1mLYbDaOF9AAYNHhyx63o0AAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
};

export default ArgentinaFlagSVGIcon;
