import React from 'react';
import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { SelectChangeEvent } from '@mui/material/Select';
import {
  COMPLEX_FILTER_MODAL_CONFIG,
  COMPLEX_FILTER_TEST_IDS,
  COMPLEX_FILTER_OVERRIDE_SX,
  DATE_TIME_MODES,
} from 'components/complex_filter/constants';
import {
  StateKey,
  BaseFilterEntry,
  FilterEntry,
  DateTimeMode,
} from 'store/shared/types';
import {
  selectPopoverOpen,
  selectExpandedFilter,
  selectIsFiltered,
  selectAvailableEntries,
  selectChosenEntries,
  selectAllChosenEntries,
  selectSearchInputByKey,
  selectDateFilterMode,
  selectFilterOpen,
  selectPickedDate,
  selectDateRange,
} from 'store/shared/selectors';
import { DropdownHandlersConfig } from 'components/complex_filter/types';
import UseGetFilterDrawerConfig from 'components/complex_filter/ComplexFilterDrawer/types';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import {
  renderCommonChipHOC,
  getFilterDateValue,
} from 'components/complex_filter/utils';
import {
  getAssetQueryVariablesHOF,
  getAssetAssigneeQueryVariablesHOF,
} from 'pages/Assets/filters/utils';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { Moment } from 'moment';
import { PickerSelectionState } from '@mui/x-date-pickers/internals';
import { useGetFilterDrawerConfig as useGetFilterDrawerConfigBase } from 'components/complex_filter/hooks';

export const useGetFilterDrawerConfig = (
  filterSubTree: StateKey
): UseGetFilterDrawerConfig | undefined => {
  const userOrg = useGetUserOrganization();
  const baseFilterDrawerConfig = useGetFilterDrawerConfigBase(filterSubTree);

  if (!baseFilterDrawerConfig) return undefined;

  React.useEffect(() => {
    baseFilterDrawerConfig.filterModalConfig.typeAheadHandlers[
      'asset-number'
    ].getQueryVariables = getAssetQueryVariablesHOF('assetNumber', {
      searchBy: 'iLike',
      ...(userOrg?.id ? { organizationId: userOrg?.id } : {}),
    });

    baseFilterDrawerConfig.filterModalConfig.typeAheadHandlers[
      'serial-no'
    ].getQueryVariables = getAssetQueryVariablesHOF('serialNumber', {
      searchBy: 'iLike',
      ...(userOrg?.id ? { organizationId: userOrg?.id } : {}),
    });

    baseFilterDrawerConfig.filterModalConfig.typeAheadHandlers[
      'assignee-email'
    ].getQueryVariables = getAssetAssigneeQueryVariablesHOF({
      ...(userOrg?.id ? { organizationId: userOrg?.id } : {}),
    });

    baseFilterDrawerConfig.filterModalConfig.typeAheadHandlers.assignee.getQueryVariables =
      getAssetAssigneeQueryVariablesHOF({
        ...(userOrg?.id ? { organizationId: userOrg?.id } : {}),
      });
  }, [userOrg?.id]);

  return { ...baseFilterDrawerConfig } as UseGetFilterDrawerConfig;
};
