/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Chip from '@mui/material/Chip';
import DefaultFilterBankChipSx from './styles';
import FilterChipProps from './types';

const FilterChip = (props: FilterChipProps) => {
  const { FilterChipExtraSx = {}, ...restProps } = props;
  const FilterChipSx = { ...DefaultFilterBankChipSx, ...FilterChipExtraSx };

  return <Chip {...restProps} sx={FilterChipSx} />;
};

export default FilterChip;
