import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const DropdownSelectSx: SxProps = {
  p: 0,
  width: '100%',
  backgroundColor: 'transparent',
  paddingTop: '8px',
  paddingBottom: '8px',
};

export const MenuItemSx: SxProps = {
  width: '100%',
  paddingTop: '8px',
  paddingBottom: '8px',
  textOverflow: 'wrap',
  textWrap: 'pretty',
};

export const IconSx: SxProps = {
  marginRight: '8px',
};

export const AddSelectItemSx: SxProps = {
  padding: '8px 16px 8px 16px',
  color: theme.palette.colors.offBlack,
  '&:hover': {
    opacity: 0.6,
  },
  '& .MuiTypography-root': {
    fontSize: '16px',
    fontWeight: 600,
    font: 'Inter',
  },
  '& .MuiButton-startIcon': {
    mr: '4px',
  },
};

export const checkIconSx: SxProps = {
  display: 'flex',
  justifyContent: 'flex-end',
  width: 'auto',
};
