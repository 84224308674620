import { Order } from 'types';

export const constructRecipientName = (order: Order): string => {
  if (order?.dropRecipient) {
    return `${order.dropRecipient.firstName} ${order.dropRecipient.lastName}`;
  }
  if (order.recipient) {
    return `${order.recipient.firstName} ${order.recipient.lastName}`;
  }
  return '';
};
