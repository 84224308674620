import { createSlice } from '@reduxjs/toolkit';
import {
  deselectFilterByIdAndKeyReducer,
  resetFilterStateReducer,
  closeFilterDrawerReducer,
  openFilterDrawerReducer,
  toggleFilterDrawerReducer,
  upsertFilterEntryReducer,
  batchUpsertFilterEntriesReducer,
  openFilterInputReducer,
  closeFilterInputReducer,
  setSearchInputReducer,
  clearSearchInputReducer,
  selectOptionReducer,
  clearAvailableOptionsByKeyReducer,
  toggleFilterByKeyIdReducer,
  clearFilterEntriesByKeyReducer,
  setComplexFilterEntriesReducerHOF,
  replaceDateFilterEntryReducer,
  setDateModeReducer,
  setDateRangeReducer,
  setSingleDateReducer,
  clearDateByModeKeyReducer,
  resetFilterByEntryKeyReducerHOF,
  softResetFilterStateReducerHOF,
} from 'store/shared/reducers';
import { FilterEntry, FilterEntryUnion } from 'store/shared/types';
import { AssetsFilterState } from './types';

const ASSET_FILTERS_MAPPING_TABLE = {
  'asset-number': 'assetNumber',
  depot: 'depotId',
  status: 'status',
  assignee: 'assigneeId',
  'assignee-email': 'assigneeId',
  type: 'assetTypeId',
  make: 'make',
  model: 'model',
  condition: 'cosmeticCondition',
  'keyboard-config': 'keyboard',
  'serial-no': 'serialNumber',
  'device-issue': 'deviceAssessment',
  color: 'color',
  'display-size': 'displaySize',
  processor: 'processor',
  memory: 'memory',
  storage: 'storage',
};

const DESELECT_ONLY_RESET_KEYS = ['depot', 'status', 'type', 'condition'];

export const INITIAL_ASSET_ENTRIES: FilterEntryUnion = {
  'asset-number': {
    options: [] as FilterEntry[],
    searchInput: '',
  },
  depot: { options: [] as FilterEntry[], searchInput: null },
  status: { options: [] as FilterEntry[], searchInput: null },
  assignee: { options: [] as FilterEntry[], searchInput: '' },
  'assignee-email': {
    options: [] as FilterEntry[],
    searchInput: '',
  },
  type: { options: [] as FilterEntry[], searchInput: null },
  make: { options: [] as FilterEntry[], searchInput: '' },
  model: { options: [] as FilterEntry[], searchInput: '' },
  condition: {
    options: [] as FilterEntry[],
    searchInput: null,
  },
  'keyboard-config': {
    options: [] as FilterEntry[],
    searchInput: '',
  },
  'serial-no': {
    options: [] as FilterEntry[],
    searchInput: '',
  },
  'device-issue': {
    options: [] as FilterEntry[],
    searchInput: '',
  },
  color: { options: [] as FilterEntry[], searchInput: '' },
  'display-size': {
    options: [] as FilterEntry[],
    searchInput: '',
  },
  processor: { options: [] as FilterEntry[], searchInput: '' },
  memory: { options: [] as FilterEntry[], searchInput: '' },
  storage: { options: [] as FilterEntry[], searchInput: '' },
};

export const assetsInitialState: AssetsFilterState = {
  entries: INITIAL_ASSET_ENTRIES,
  popover: { open: false },
  expandedFilter: null,
  complexSearchEntriesDTO: [],
};

const assetFilterSlice = createSlice({
  name: 'assetsFilterSlice',
  initialState: assetsInitialState,
  reducers: {
    deselectFilterByIdAndKeyReducer,
    resetFilterStateReducer,
    closeFilterDrawerReducer,
    openFilterDrawerReducer,
    toggleFilterDrawerReducer,
    upsertFilterEntryReducer,
    batchUpsertFilterEntriesReducer,
    openFilterInputReducer,
    closeFilterInputReducer,
    setSearchInputReducer,
    clearSearchInputReducer,
    selectOptionReducer,
    clearAvailableOptionsByKeyReducer,
    toggleFilterByKeyIdReducer,
    clearFilterEntriesByKeyReducer,
    setComplexFilterEntriesReducer: setComplexFilterEntriesReducerHOF(
      ASSET_FILTERS_MAPPING_TABLE
    ),
    resetFilterByEntryKeyReducer: resetFilterByEntryKeyReducerHOF(
      INITIAL_ASSET_ENTRIES
    ),
    softResetFilterStateReducer: softResetFilterStateReducerHOF(
      DESELECT_ONLY_RESET_KEYS
    ),
    // optional reducers for date filters
    replaceDateFilterEntryReducer,
    setDateModeReducer,
    setDateRangeReducer,
    setSingleDateReducer,
    clearDateByModeKeyReducer,
  },
  extraReducers: (builder) => {},
});

export const assetsFilterReducer = assetFilterSlice.reducer;
export const assetFilterActions = assetFilterSlice.actions;
export default assetFilterSlice;
