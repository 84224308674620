import { gql } from '@apollo/client';

export const GET_ASSETS_BY_STORAGE = gql`
  query getAssetsByDisplaySize(
    $organizationId: ID!
    $storage: String
    $iLike: AssetILikeParam
  ) {
    searchAssets(
      assetsSearchInput: {
        organizationId: $organizationId
        storage: $storage
        iLikeFilter: $iLike
        offset: "0"
      }
    ) {
      assets {
        id
        storage
      }
      count
    }
  }
`;
