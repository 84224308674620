export const RECIPIENT_INFO_PROP_NAMES = [
  'recipientId',
  'dropRecipientId',
  'depotId',
];

export enum DepotSelect {
  LABEL = 'Select Depot',
  LABEL_ID = 'recipient-info-depot-select-label',
  SELECT_ID = 'recipient-info-depot-select-label',
}

export const RECIPIENT = 'RECIPIENT';
export const DROP_RECIPIENT = 'DROP_RECIPIENT';
