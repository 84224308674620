import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const DateBoxSx = {
  position: 'relative',
  width: '100%',
};

export const DateRangeCalendarInputSx: SxProps = {
  width: '100%',
  '&.MuiTextField-root.MuiFormControl-root': {
    px: 0,
  },

  '& .MuiInputLabel-shrink': {
    color: theme.palette.colors.woodSmoke,
  },

  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, & .Mui-focused.MuiFormLabel-root, & .Mui-focused.MuiInputLabel-root':
    {
      color: theme.palette.colors.woodSmoke,
      borderColor: `${theme.palette.colors.woodSmoke} !important`,
    },
};

export const RangeCalendarSx = {
  borderTop: '1px solid rgba(0, 0 , 0, .12)',

  '& .MuiButtonBase-root.MuiPickersDay-root.MuiDateRangePickerDay-day': {
    '&.MuiPickersDay-today:not(.Mui-selected)': {
      border: 'none !important',
    },
    '&.Mui-selected:focus': {
      backgroundColor: `${theme.palette.colors.maizeYO} !important`,
    },
    '&.Mui-selected:hover': {
      backgroundColor: `${theme.palette.colors.maizeYO} !important`,
    },
  },
};

export const PopoverSx = {
  mt: '8px',
};

export const RangeInputSx = {
  width: '100%',
  '&.MuiFormControl-root.MuiTextField-root': {
    backgroundColor: 'transparent',
    px: 0,
  },
  '& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd': {
    py: '8px',
  },
  '&:hover': {
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      color: theme.palette.colors.woodSmoke,
    },
  },

  '& .MuiFormLabel-root.MuiInputLabel-root': {
    top: '-7px',
    '&.MuiInputLabel-shrink': {
      top: 0,
    },
    '&.Mui-focused': {
      color: theme.palette.colors.woodSmoke,
    },
  },
};

export const DateInputSx = {
  width: '100%',
  '&.MuiFormControl-root.MuiTextField-root': {
    backgroundColor: 'transparent',
    px: 0,
  },

  '& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd': {
    py: '8px',
  },
  '&:hover': {
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      color: theme.palette.colors.woodSmoke,
    },
  },

  '& .MuiFormLabel-root.MuiInputLabel-root': {
    top: '-7px',
    '&.MuiInputLabel-shrink': {
      top: 0,
    },
    '&.Mui-focused': {
      color: theme.palette.colors.woodSmoke,
    },
  },
};

export const CalendarButtonSx = {
  position: 'absolute',
  right: '2px',
  top: '0px',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

export const ClearButtonSx = {
  position: 'absolute',
  right: '30px',
  top: '0px',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

export const ToggleButtonGroupSx = {
  p: '16px',
  '& .MuiToggleButtonGroup-grouped:not(:last-of-type)': {
    mr: '8px',
  },
};

export const ToggleButtonSx = {
  px: '10px',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '22px',
  '&.MuiToggleButtonGroup-grouped': {
    border: `1px solid ${theme.palette.colors.woodSmoke} !important`,
  },
  '&.MuiButtonBase-root.MuiToggleButton-root': {
    borderRadius: '30px !important',
    '&.MuiToggleButton-standard': {
      color: `${theme.palette.colors.woodSmoke} !important`,
    },
    '&.Mui-selected': {
      backgroundColor: '#131314',
      color: `${theme.palette.colors.white} !important`,
    },
  },
};
