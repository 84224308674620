export const LoadingContainerSx = {
  width: '100%',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const RetrievalContainerSx = (show: boolean) => ({
  paddingTop: show ? '78px' : '0',
});
