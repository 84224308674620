/* eslint-disable sort-exports/sort-exports */
import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const CancelDialogSx: SxProps = {
  '& .MuiDialog-paper': {
    borderRadius: '24px',
    p: '24px',
  },
  '& .MuiDialog-container': {
    '& > .MuiPaper-root': {
      maxHeight: '100vh',
      maxWidth: '100vw',
      minHeight: '350px',
      height: '222px',
      p: '16px 24px',
      pb: 0,
      pr: 0,
      width: '697px',
    },
  },
};

export const CancelOuterStackSx: SxProps = {
  height: '100%',
};

export const CancelMainTitleSx: SxProps = {
  fontWeight: 700,
  lineHeight: '38.4px',
};

export const CancelButtonsStackSx: SxProps = {
  width: '100%',
};

export const CancelButtonGroupSx: SxProps = {
  borderColor: 'transparent',
  borderRadius: 0,
  mb: '24px',
  mr: '24px',

  '& .MuiButtonGroup-grouped.MuiButtonBase-root': {
    borderColor: 'transparent',
    borderRadius: 0,
    color: theme.palette.colors.black,
    fontWeight: 600,
    px: '16px',

    '&:last-child': {
      borderRadius: '30px',
      ml: '8px',
      '&:hover': {
        backgroundColor: theme.palette.colors.mustardY,
      },
    },
  },
};
