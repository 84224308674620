import { Collaborator } from 'types';

export const DEFAULT_PURCHASER_VALUE: Collaborator = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  workEmail: '',
  personalEmail: '',
  organizationId: '',
};
