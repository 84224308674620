/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Avatar as MuiAvatar } from '@mui/material';
import Typography from '@mui/material/Typography';
import { GlobalState } from 'store/types';
import { FilterBankEntry } from 'types';
import * as AMDashComplexFilterCountStyles from './styles';

const AMDashComplexFilterCount = () => {
  const styles = AMDashComplexFilterCountStyles;
  const filterBankEntries = useSelector<GlobalState, FilterBankEntry[]>(
    (state) => state.assetManagement.filterBankEntries
  );

  const filterCount = filterBankEntries.length;

  if (!filterCount) return <></>;

  return (
    <MuiAvatar sx={styles.FilterBtnCountIconSx}>
      <Typography>{filterCount}</Typography>
    </MuiAvatar>
  );
};

export default AMDashComplexFilterCount;
