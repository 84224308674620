import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { LoadingIndicatorProps } from './types';

const LoadingIndictor = (props: LoadingIndicatorProps) => {
  return (
    <>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={props.loading ?? false}
      >
        <CircularProgress color="primary" size={props.size} />
      </Backdrop>
    </>
  );
};

export default LoadingIndictor;
