import { createSlice } from '@reduxjs/toolkit';

const topNavInitialState = {
  disableRetrievalCTA: false,
};

export const topNavSlice = createSlice({
  name: 'topNavigationService',
  initialState: () => topNavInitialState,
  reducers: {
    enableRetrievalCTA: (state) => {
      state.disableRetrievalCTA = false;
    },
    disableRetrievalCTA: (state) => {
      state.disableRetrievalCTA = true;
    },
    reset: () => topNavInitialState,
  },
});
