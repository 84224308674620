import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid-pro';
import Typography from '@mui/material/Typography';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { formatDate } from 'services';
import { UseGetInventoryReceiptsColumnsArgs } from './types';
import { InventoryReceiptActionModals } from '../../enums';
import { InventoryReceipt } from 'types';
import InventoryReceiptProductStatusPill from 'components/InventoryReceiptProductStatusPill';

export const useGetInventoryReceiptsColumns = (
  args: UseGetInventoryReceiptsColumnsArgs
): GridColDef[] => {
  return [
    {
      field: 'checkedInAt',
      filterable: false,
      sortable: false,
      flex: 1,
      valueGetter(params) {
        if (!params.row.checkedInAt) return '';
        return params.row.checkedInAt
          ? formatDate(params.row.checkedInAt, 'MMM D, yyyy h:mm A')
          : '';
      },
      renderCell: (params) => {
        return (
          <Typography variant="body2" sx={{ whiteSpace: 'normal' }}>
            {params.value}
          </Typography>
        );
      },
      renderHeader: () => {
        return (
          <Typography
            sx={{
              whiteSpace: 'normal',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '18px',
            }}
          >
            Check in date
          </Typography>
        );
      },
    },
    {
      field: 'checkedOutAt',
      filterable: false,
      sortable: false,
      flex: 1,
      valueGetter(params) {
        if (!params.row.checkedOutAt) return '';
        return params.row.checkedOutAt
          ? formatDate(params.row.checkedOutAt, 'MMM D, yyyy h:mm A')
          : '';
      },
      renderCell: (params) => {
        return (
          <Typography variant="body2" sx={{ whiteSpace: 'normal' }}>
            {params.value}
          </Typography>
        );
      },
      renderHeader: () => {
        return (
          <Typography
            sx={{
              whiteSpace: 'normal',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '18px',
            }}
          >
            Check out date
          </Typography>
        );
      },
    },
    {
      field: 'inboundOrderItemId',
      filterable: false,
      sortable: false,
      flex: 1,
      valueGetter(params) {
        return params.row.inboundOrderItem?.orderItemNumber || '';
      },
      renderHeader: () => {
        return (
          <Typography
            sx={{
              whiteSpace: 'normal',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '18px',
            }}
          >
            Inbound order item number
          </Typography>
        );
      },
    },
    {
      field: 'outBoundOrderItemId',
      filterable: false,
      sortable: false,
      flex: 1,
      valueGetter(params) {
        return params.row.outboundOrderItem?.orderItemNumber || '';
      },
      renderHeader: () => {
        return (
          <Typography
            sx={{
              whiteSpace: 'normal',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '18px',
            }}
          >
            Outbound order item number
          </Typography>
        );
      },
    },
    {
      field: 'depot',
      headerName: 'Depot',
      filterable: false,
      sortable: false,
      flex: 1,
      valueGetter(params) {
        return params.row.depot?.name || '';
      },
    },
    {
      field: 'identifier',
      headerName: 'Identifier',
      filterable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: 'lastTouchedAt',
      headerName: 'Last touched at',
      filterable: false,
      sortable: false,
      flex: 1,
      valueGetter(params) {
        if (!params.row.lastTouchedAt) return '';
        return params.row.lastTouchedAt
          ? formatDate(params.row.lastTouchedAt, 'MMM D, yyyy h:mm A')
          : '';
      },
      renderCell: (params) => {
        return (
          <Typography variant="body2" sx={{ whiteSpace: 'normal' }}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: 'productStatus',
      headerName: 'Product Status',
      filterable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return <InventoryReceiptProductStatusPill status={params.value} />;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'center',
      flex: 0.5,
      getActions: (actionsParams) => [
        <GridActionsCellItem
          icon={<EditOutlinedIcon />}
          label="Edit"
          showInMenu
          onClick={() => {
            args.setInventoryReceiptAction(
              InventoryReceiptActionModals.EDIT_INVENTORY_RECEIPT,
              actionsParams.row as InventoryReceipt
            );
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteOutlinedIcon />}
          label="Delete"
          showInMenu
          onClick={() => {
            args.setInventoryReceiptAction(
              InventoryReceiptActionModals.DELETE_INVENTORY_RECEIPT,
              actionsParams.row as InventoryReceipt
            );
          }}
        />,
      ],
    },
  ];
};
