import { gql } from '@apollo/client';

export const ADD_ORDER_ITEM_BY_ASSET = gql`
  mutation createOrderItemsByAssets(
    $orderId: ID!
    $assetOrderItems: [AssetOrderItem!]!
  ) {
    createOrderItemsByAssets(
      orderItemsByAssetsCreateInput: {
        orderId: $orderId
        assetOrderItems: $assetOrderItems
      }
    ) {
      id
    }
  }
`;

export const CREATE_ORDER_ITEMS_FOR_ASSETS = gql`
  mutation createOrderItemsForAssets(
    $orderId: ID!
    $assetNumbers: [String!]
    $serialNumbers: [String!]
  ) {
    createOrderItemsForAssets(
      orderItemsForAssetsCreateInput: {
        orderId: $orderId
        assetNumbers: $assetNumbers
        serialNumbers: $serialNumbers
      }
    ) {
      id
    }
  }
`;
