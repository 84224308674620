import React from 'react';
import * as Sentry from '@sentry/react';
import useGetGlobalPurchaser from '../useGetGlobalPurchaser';
import useGetUserOrganization from '../useGetUserOrganization';

export default () => {
  const collaborator = useGetGlobalPurchaser();
  const organization = useGetUserOrganization();
  React.useEffect(() => {
    if (!collaborator || !organization) return;
    Sentry.getGlobalScope().setUser({
      email: collaborator?.email,
      id: collaborator?.id,
      organization: organization?.id,
      organizationName: organization?.name,
    });
  }, [collaborator?.email, organization?.id]);
};
