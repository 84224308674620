import {
  POPOVER_HEADER_HEIGHT,
  POPOVER_MAX_WIDTH_PAPER,
} from '../ColumnSelect/styles';

export const ControlsContainerSx = {
  display: 'flex',
  p: '5%',
  width: '100%',
};

export const HideAllSx = {
  width: '50%',
  alignItems: 'flex-start',
};

export const ShowAllSx = {
  width: '50%',
  display: 'flex',
  justifyContent: 'flex-end',
};

export const JustifyCenterSx = {
  display: 'flex',
  justifyContent: 'center',
  fontWeight: '700',
  cursor: 'pointer',
};

export const SearchInputContainerSx = {
  '& .MuiBox-root': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  '& .MuiFormControl-root.MuiTextField-root': {
    marginTop: '5%',
    paddingTop: '5%',
    width: '90%',
    display: 'flex',
  },
};

export const DisabledSx = {
  pointerEvents: 'none',
  opacity: '0.5',
};

export const HeaderContainerSx = {
  display: 'flex',
  height: POPOVER_HEADER_HEIGHT,
  pt: '20px',
  width: POPOVER_MAX_WIDTH_PAPER,
};
