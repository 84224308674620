import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { GALoadingButtonProps } from './types';
import { useGAButtonClickHandler } from '../shared/hooks';

const GALoadingButton = (props: GALoadingButtonProps) => {
  const { gaContext, onClick, ...rest } = props;
  const handleClick = useGAButtonClickHandler({ gaContext, onClick });

  return <LoadingButton {...rest} onClick={handleClick} />;
};

export default GALoadingButton;
