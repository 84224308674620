import { SxProps } from '@mui/material';
import theme from 'themes/theme';
import { FilterDrawerBookendSx } from 'components/complex_filter/ComplexFilterDrawer/styles';

export const FilterDrawerBottomBoxSx: SxProps = {
  borderTop: `1px solid #c9c9c9`,
  height: '52px',
  justifyContent: 'flex-start',
  ...FilterDrawerBookendSx,
  '& .clear-all-filters-button': {
    backgroundColor: 'transparent',
    borderRadius: '10px',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    px: '12px',
    '&:hover': {
      backgroundColor: theme.palette.colors.transparentGrey,
    },
  },
};
