import theme from 'themes/theme';

export const NewUploadNotificationSx = {
  mt: '80px',
  '& .MuiPaper-root.MuiSnackbarContent-root': {
    backgroundColor: theme.palette.colors.goldFusion,
    width: '719px',
  },
  '& .MuiSnackbarContent-message': {
    color: theme.palette.colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',
  },
};
