/* eslint-disable sort-exports/sort-exports */
import { SxProps } from '@mui/material/styles';
import { RETRIEVAL_TOP_BAR_HEIGHT } from 'pages/Retrieval/styles';

const SM_BREAKPOINT_PADDING_X = '15px';
const MD_BREAKPOINT_PADDING_X = '50px';
const LG_BREAKPOINT_PADDING_X = '144px';
const LG_SEARCH_BAR_WIDTH = '30%';

export const ReviewStepSx: SxProps = {
  boxSizing: 'border-box',
  maxHeight: `calc(100vh - ${RETRIEVAL_TOP_BAR_HEIGHT})`,
  overflow: {
    md: 'scroll',
    lg: 'hidden',
  },
  pt: '20px',
  px: {
    xs: SM_BREAKPOINT_PADDING_X,
    sm: SM_BREAKPOINT_PADDING_X,
    md: MD_BREAKPOINT_PADDING_X,
    lg: LG_BREAKPOINT_PADDING_X,
  },
  '& #review-step-title-copy': {
    fontSize: '27px',
    fontWeight: 700,
  },
  '& #review-step-title-block': {},
  '& .MuiDivider-root#review-step-divider': {
    borderBottom: '0',
    borderLeft: '0',
    borderRight: '0',
    borderTop: '1px solid #DCDCDC',
    mt: '28px',
    mb: '16px',
  },
  '& #review-step-content-block': {
    '& > *': {
      width: {
        md: '100%',
        lg: `calc(100% - ${LG_SEARCH_BAR_WIDTH})`,
      },

      '&:last-child': {
        maxHeight: {
          md: '100%',
          lg: 'calc(100vh - 210px)',
        },
        overflowY: {
          md: 'visible',
          lg: 'scroll',
        },
        p: {
          md: 0,
          lg: '12px 12px 50px',
        },
      },
    },
    '& > #review-step-search-section': {
      mr: '24px',
      width: {
        md: '100%',
        lg: LG_SEARCH_BAR_WIDTH,
      },
    },
  },
};

export const ReviewStepContentSx: SxProps = {
  width: {
    md: `calc(100vw - ${MD_BREAKPOINT_PADDING_X})`,
    lg: `calc(100vw - ${LG_BREAKPOINT_PADDING_X})`,
  },
};
