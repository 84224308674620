import React from 'react';
import every from 'lodash/every';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Stack from '@mui/material/Stack';

import SearchInput from 'components/inputs/SearchInput';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { ColumnControlsProps } from './types';

import {
  ControlsContainerSx,
  SearchInputContainerSx,
  HideAllSx,
  ShowAllSx,
  JustifyCenterSx,
  DisabledSx,
  HeaderContainerSx,
} from './styles';

function ColumnHeader<T>(props: ColumnControlsProps<T>) {
  const {
    onHideAllClick: handleOnHideAllClick,
    onShowAllClick: handleOnShowAllClick,
    onSearchInput: handleOnSearchInput,
    searchInput,
    columns,
  } = props;
  const allActive = every(columns, (column: any) => column?.active);
  const allInactive = every(columns, (column: any) => !column?.active);
  return (
    <Stack sx={HeaderContainerSx}>
      <Box sx={SearchInputContainerSx}>
        <SearchInput
          searchTerm={searchInput}
          placeholder="Find column"
          debouncedSearchTerm={searchInput}
          setSearchTerm={handleOnSearchInput}
        />
        <Stack sx={ControlsContainerSx} direction="row">
          <Stack sx={HideAllSx} direction="row">
            <Typography
              sx={{
                ...JustifyCenterSx,
                ...(allInactive ? DisabledSx : {}),
              }}
              onClick={handleOnHideAllClick}
            >
              <VisibilityOffIcon />
              &nbsp; Hide all
            </Typography>
          </Stack>
          <Stack sx={ShowAllSx} direction="row">
            <Typography
              sx={{
                ...JustifyCenterSx,
                ...(allActive ? DisabledSx : {}),
              }}
              onClick={handleOnShowAllClick}
            >
              <VisibilityIcon />
              &nbsp; Show all
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
}

export default ColumnHeader;
