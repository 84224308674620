/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import HomeListItem from 'pages/HomeUpdate/components/HomeListItem';
import { NavigationContext } from 'context/NavigationContext';
import TextIconButton from 'components/TextIconButton';
import { FAQS_URL, FAQS_ITEMS, FAQS_TITLE_ID } from './constants';
import * as FAQsStyles from './styles';

const FAQs = () => {
  const navigate = useNavigate();
  const navigator = React.useContext(NavigationContext);
  const styles = FAQsStyles;

  return (
    <Box sx={styles.HomeFAQsBoxSx}>
      <List sx={styles.getHomeFAQsListSx(FAQS_TITLE_ID)}>
        <ListSubheader>
          <Typography variant="h6" id={FAQS_TITLE_ID}>
            FAQs
          </Typography>
          <TextIconButton
            text="View all"
            ariaLabel="Navigates to FAQ page"
            icon={<ArrowForwardIcon fontSize="small" />}
            onClick={() => window.open(FAQS_URL, '_blank')}
            textStyles={styles.ViewAllBtnTextStyles}
            endIcon
          />
        </ListSubheader>
        {FAQS_ITEMS.map((item) => (
          <React.Fragment key={nanoid()}>
            <HomeListItem {...item} />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default FAQs;
