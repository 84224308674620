import * as React from 'react';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import { Button } from 'components';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { SwipeableImagesViewProps } from './types';
import { Typography } from '@mui/material';
import { SwipeableImagesViewSx } from './styles';

const SwipeableImagesView = (props: SwipeableImagesViewProps) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = props.images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const dotBackgroundColor =
    !props.color || props.color === 'primary' ? undefined : 'colors.jungleG';

  const BoxSx = {
    ...SwipeableImagesViewSx,
    ...props.mainBoxSx,
  };

  const imageSx = {
    display: 'block',
    overflow: 'hidden',
    width: '100%',
    margin: 'auto',
    ...props.imageBoxSx,
  };

  return (
    <Box sx={BoxSx}>
      <SwipeableViews
        axis="x"
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {props.images.map((image, index) => (
          <div key={image.id}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={imageSx}
                src={image.url}
                alt={image.name}
              />
            ) : null}
          </div>
        ))}
      </SwipeableViews>
      <MobileStepper
        sx={{
          justifyContent: 'space-evenly',
          '.MuiMobileStepper-dotActive': {
            backgroundColor: dotBackgroundColor,
          },
        }}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            gaContext={{
              textCopy: 'Next',
              navigates_to: 'N/A',
              purpose: 'Swipes to next image',
            }}
            color={props.color}
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
            sx={{
              padding: '6px 8px',
              minWidth: 'fit-content',
            }}
          >
            <Typography variant="button">Next</Typography>
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button
            gaContext={{
              textCopy: 'Previous',
              navigates_to: 'N/A',
              purpose: 'Swipes to previous image',
            }}
            color={props.color}
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
            sx={{
              padding: '6px 8px',
              minWidth: 'fit-content',
            }}
          >
            <KeyboardArrowLeft />
            <Typography variant="button">Back</Typography>
          </Button>
        }
      />
    </Box>
  );
};

export default SwipeableImagesView;
