import { HomeListItemProps as HomeListItem } from 'pages/HomeUpdate/components/HomeListItem/types';

export const FAQS_URL =
  'https://faq.allwhere.co/en_us/categories/faq-S1PKSbsci';
export const FAQS_TITLE_ID = 'home-update-faqs-title';
const subListHasBullets = false;

export const FAQS_ITEMS: HomeListItem[] = [
  {
    listItemLinkURL:
      'https://faq.allwhere.co/en_us/categories/using-the-allwhere-platform-HJsv1v6',
    listItemLinkText: "How do you use allwhere's platform?",
    subListHasBullets,
  },
  {
    listItemLinkURL:
      'https://faq.allwhere.co/en_us/categories/our-services-Hy4xRlcqi',
    listItemLinkText: "What are allwhere's services?",
    subListHasBullets,
  },
  {
    listItemLinkURL:
      'https://faq.allwhere.co/en_us/categories/shipping-S1LTCl9ci',
    listItemLinkText: "What are allwhere's shipping policies?",
    subListHasBullets,
  },
];
