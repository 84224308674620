import { SxProps } from '@mui/material';

const MAIN_STACK_SIZE_SM = 'calc(100vh - 112px)';
const MAIN_STACK_SIZE_MD = 'calc(613px - 48px)';

export const NewEmployeeDialogSx: SxProps = {
  '& .MuiDialog-paper': {
    borderRadius: '24px',
    p: '24px',
  },
  '&: .MuiFormHelperText-root': {
    position: 'absolute',
    bottom: '-20px',
  },
};

export const NewEmployeeDialogPaperSx: SxProps = {
  height: {
    xs: '100vh',
    sm: '100vh',
    md: '613px',
    lg: '613px',
    xl: '613px',
  },
  maxWidth: '760px',
  width: '100vw',
};

export const NewEmployeeMainStackSx: SxProps = {
  height: {
    xs: MAIN_STACK_SIZE_SM,
    sm: MAIN_STACK_SIZE_SM,
    md: MAIN_STACK_SIZE_MD,
    lg: MAIN_STACK_SIZE_MD,
    xl: MAIN_STACK_SIZE_MD,
  },
};

export const NewEmployeeMainTitleSx: SxProps = {
  fontWeight: 700,
  lineHeight: '38.4px',
};

export const NewEmployeeFormStackSx: SxProps = {
  boxSizing: 'border-box',
  pt: '25px',
  width: '100%',
};

export const NewEmployeeInnerFormStackSx: SxProps = {
  position: 'relative',
  width: 'calc(50% - 10px)',
};

export const NewEmployeeTextInputBaseSx: SxProps = {
  backgroundColor: 'transparent',
  p: 0,
  '&: .MuiFormHelperText-root': {
    color: 'transparent',
    position: 'absolute',
    bottom: '-20px',
  },
};

export const NewEmployeeButtonBaseSx: SxProps = {
  minWidth: '68px',
  width: '68px',
};

export const NewEmployeeSubmitButtonSx: SxProps = {
  ml: '18px',
  ...NewEmployeeButtonBaseSx,
};
