import { useSafeMutation } from 'hooks/useSafeMutation';
import { CREATE_PROCUREMENT_TO_RECIPIENT_ORDER } from 'pages/Procurement/hooks/useCreateProcurementToRecipientOrder/mutations';

/**
 * @todo replace this middleman hook with the actual mutation hook in the
 * file src/pages/Procurement/components/OrderSummary/index.tsx post premium-shipping feature
 */
export const useCreateProcurementToRecipientOrder = () => {
  const [
    createProcurementToRecipientOrder,
    { loading: procurementToRecipientOrderCreationIsLoading },
  ] = useSafeMutation(CREATE_PROCUREMENT_TO_RECIPIENT_ORDER);

  return {
    createOrder: createProcurementToRecipientOrder,
    isCreateOrderLoading: procurementToRecipientOrderCreationIsLoading,
  };
};
