import React from 'react';
import { Helmet } from 'react-helmet';
import { useConfigureDelightedSurveys } from './hooks';

const Head = () => {
  const { delightedScripts, delightedCalls } = useConfigureDelightedSurveys();

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="icon" href="favicon.ico" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta name="description" content="allwhere" />
      <title>allwhere</title>
      {delightedScripts?.map((script, index) => {
        return (
          <script
            id={`delightedScripts-${index}`}
            key={`delightedScripts-${index}`}
            type="text/javascript"
          >
            {script}
          </script>
        );
      })}
      <script id="delightedCalls" type="text/javascript">
        {delightedCalls}
      </script>
    </Helmet>
  );
};

export default Head;
