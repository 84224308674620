export enum FeatureFlagNames {
  PREMIUM_SHIPPING = 'releasePremiumShipping',
  EMPLOYEE_EMAIL_OPTIONS = 'releaseEmployeeEmailOptions',
  SELECT_RECIPIENT_EMAIL = 'releaseSelectRecipientEmail',
  HRIS_ONBOARDING_OFFBOARDING = 'releaseHrisOnboardingOffboarding',
  COMPLEX_ASSET_FILTERING = 'releaseComplexAssetFilter',
  SELECT_RECIPIENT_ADDRESS = 'releaseSelectRecipientAddress',
  ORDER_ITEMS_DETAILS_VIEW = 'releaseOrderItemsDetailsView',
  RELEASE_DEVICE_UNLOCK = 'releaseDeviceUnlock',
  RELEASE_CSV_DOWNLOAD_LINK = 'releaseCsvDownloadLink',
  COMPLEX_CLIENT_DEVICES_FILTERING = 'releaseComplexDevicesFilter',
  COMPLEX_CLIENT_ORDERS_FILTERING = 'releaseComplexOrdersFilter',
  ASSET_UPLOAD_TOOL = 'releaseAssetUploadTool',
}
