import React from 'react';
import { Widget } from '@typeform/embed-react';
import styles from './RequestNewItem.module.css';

//This form id value is needed to let typeform know which form to embed in our application
const REQUEST_NEW_ITEM_FORM_ID = 'nkOzE6o7';

const RequestNewItem = () => {
  return (
    <div className={styles.content}>
      <Widget
        id={REQUEST_NEW_ITEM_FORM_ID}
        style={{ width: '100%', height: '100%' }}
        className="request-new-item-form"
      />
    </div>
  );
};

export default RequestNewItem;
