import React from 'react';
import IconButton from '@mui/material/IconButton';
import { GAIconButtonProps } from './types';
import { useGAButtonClickHandler } from '../shared/hooks';

const GAIconButton = (props: GAIconButtonProps) => {
  const { gaContext, onClick, ...rest } = props;
  const handleClick = useGAButtonClickHandler({ gaContext, onClick });

  return <IconButton {...rest} onClick={handleClick} />;
};

export default GAIconButton;
