import { SxProps } from '@mui/material';
import { MAIN_STACK_SIZE_SM } from 'components/NewModal/constants';

export const DeviceInfoBoxSx: SxProps = {
  marginTop: '1.5rem',
  '& .wipe-confirmation': {
    marginTop: '1.5rem',
  },
};

export const PaperPropsSx: SxProps = {
  height: {
    xs: '100vh',
    sm: '100vh',
    md: '419px',
    lg: '419px',
    xl: '419px',
  },
  maxWidth: '600px',
};

export const OuterStackPropsSx: SxProps = {
  height: {
    xs: MAIN_STACK_SIZE_SM,
    sm: MAIN_STACK_SIZE_SM,
    md: 'calc(419px - 48px)',
    lg: 'calc(419px - 48px)',
    xl: 'calc(419px - 48px)',
  },
};
