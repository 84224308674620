import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const getDepotLocationColumnSx = (
  hovering: boolean = false,
  disabled: boolean = false
): SxProps => {
  return {
    pr: '6px',
    opacity: disabled ? 0.7 : 1,
    width: '100%',
    '& svg': {
      opacity: disabled || hovering ? 0.7 : 1,
    },
    '& .MuiTypography-root': {
      color: theme.palette.colors.woodSmoke,
    },
    '& .not-available-depot-tooltip.MuiTypography-root': {
      color: hovering ? theme.palette.colors.woodSmoke : 'transparent',
    },
    '&:hover .not-available-depot-tooltip.MuiTypography-root': {
      color: theme.palette.colors.woodSmoke,
    },
  };
};

export const MailToDepotBoxSx: SxProps = {
  pr: '2px',
};
