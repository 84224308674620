import React from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import { NavigationContext } from 'context/NavigationContext';
import CancelConfirmation from './components/CancelConfirmation';
import FlowStepThrough from './components/FlowStepThrough';
import { RETRIEVAL_CONTAINER_STYLES } from './styles';
import { RetrievalContext } from 'context/RetrievalServiceProvider';
import { RetrievalStepSubRoutes } from 'global-constants';
import NotificationBanner from 'components/banners/NotificationBanner';
import { useSelector } from 'react-redux';
import { selectGlobalNotificationBanner } from 'store/slices/global/selectors';
import * as styles from './styles';

const Retrieval = () => {
  const retrievalFlow = React.useContext(RetrievalContext);
  const navigate = useNavigate();
  const navigator = React.useContext(NavigationContext);
  const location = useLocation();

  const [openCancelDialog, setOpenCancelDialog] = React.useState(false);

  React.useEffect(() => {
    switch (location.pathname) {
      case navigator.pathToRetrieval(RetrievalStepSubRoutes.LOCATION):
        retrievalFlow.cancelSelectEmployeesStep();
        break;
      case navigator.pathToRetrieval(RetrievalStepSubRoutes.EMPLOYEES):
        retrievalFlow.cancelReviewStep();
        break;
      case navigator.pathToRetrieval(RetrievalStepSubRoutes.REVIEW):
        retrievalFlow.cancelSubmitStep();
        break;
      default:
        break;
    }
  }, [location.pathname]);

  const cancelRetrievalFlow = () => {
    navigate(navigator.pathToEmployees(), { replace: true });
    retrievalFlow.resetRetrievalState();
  };

  const globalNotificationBanner = useSelector(selectGlobalNotificationBanner);

  return (
    <div style={RETRIEVAL_CONTAINER_STYLES}>
      <Dialog open={true} fullScreen>
        <CancelConfirmation
          open={openCancelDialog}
          onClose={() => setOpenCancelDialog(false)}
          onCancel={cancelRetrievalFlow}
        />
        <NotificationBanner />
        <Stack sx={styles.FlowStepSx(globalNotificationBanner.show)}>
          <FlowStepThrough
            retrievalFlow={retrievalFlow}
            openCancelDialog={() => setOpenCancelDialog(true)}
          />
        </Stack>
        <Outlet />
      </Dialog>
    </div>
  );
};

export default Retrieval;
