import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const AddOrderItemAssetInputSx: SxProps = {
  width: '100%',
  borderRadius: '4px',
  p: 0,
  '& .MuiFormControl-root.MuiTextField-root': {
    borderRadius: '4px',
  },
};

export const AddOrderItemCancelButtonGroupSx: SxProps = {
  borderColor: 'transparent',
  borderRadius: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  mr: '24px',

  '& .MuiButtonGroup-grouped.MuiButtonBase-root': {
    borderColor: 'transparent',
    borderRadius: 0,
    color: theme.palette.colors.black,
    fontWeight: 600,
    px: '16px',

    '&:first-child:hover': {
      borderColor: 'transparent',
    },

    '&:last-child': {
      borderRadius: '30px',
      width: '100px',
      ml: '8px',
      '&:hover': {
        backgroundColor: theme.palette.colors.mustardY,
      },
    },
  },
};
