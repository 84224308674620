import React from 'react';
import { SVGProps } from './types';

const ResellDeviceSVGIcon = (props: SVGProps) => {
  const originalWidth = 10;
  const originalHeight = 19;
  const colorFill = props?.fill ?? 'white';
  const strokeFill = props?.stroke ?? 'white';
  const width = props?.width ?? originalHeight;
  const height = props?.height ?? originalHeight;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="path-1-outside-1_4382_889"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={originalWidth}
        height={originalHeight}
        fill="black"
      >
        <rect fill="white" width="10" height="19" />
        <path d="M5.78669 3.06013V1.61193C5.78669 1.27278 5.51427 1 5.17476 1C4.83561 1 4.56283 1.27243 4.56283 1.61193V2.97473C3.68297 3.0335 2.86531 3.33705 2.27516 3.85147C1.67568 4.37351 1.34586 5.07673 1.34586 5.83248C1.34586 7.22643 1.95917 7.91092 3.25075 8.83403C3.50486 9.01519 3.85956 9.23506 4.26368 9.48606C5.49204 10.2435 7.77653 11.6544 7.77653 12.7185C7.77653 13.7608 7.49476 14.7244 5.44399 14.7244C4.37051 14.7244 3.46608 14.4084 2.89602 13.8383C2.44831 13.3861 2.22083 12.7821 2.22705 12.0415C2.22705 11.7038 1.95462 11.4282 1.61824 11.4265H1.61201C1.27597 11.4265 1.0032 11.6989 1.00008 12.0353C0.993858 13.0994 1.34857 14.0208 2.0255 14.7026C2.65125 15.3315 3.53556 15.7311 4.55788 15.878V17.3387C4.55788 17.6765 4.83031 17.9506 5.16981 17.9506C5.50896 17.9506 5.78175 17.6765 5.78175 17.3387V15.9216C8.41025 15.7805 8.99695 14.1279 8.99695 12.7185C8.99695 10.9747 6.63493 9.51223 4.90634 8.44539C4.52847 8.21169 4.19555 8.00702 3.96014 7.8383C2.81409 7.02067 2.56929 6.6642 2.56929 5.83243C2.56929 5.32456 2.84483 4.97608 3.07715 4.77312C3.61613 4.30535 4.46349 4.0993 5.35854 4.21858C6.45963 4.37035 7.77614 4.863 7.77614 6.34509V7.34563C7.77614 7.6834 8.04856 7.95756 8.38807 7.95756C8.72722 7.95756 9 7.6834 9 7.34563V6.34509C9.00173 4.63066 7.80138 3.42266 5.78648 3.06006L5.78669 3.06013Z" />
      </mask>
      <path
        className="custom-fill"
        d="M5.78669 3.06013V1.61193C5.78669 1.27278 5.51427 1 5.17476 1C4.83561 1 4.56283 1.27243 4.56283 1.61193V2.97473C3.68297 3.0335 2.86531 3.33705 2.27516 3.85147C1.67568 4.37351 1.34586 5.07673 1.34586 5.83248C1.34586 7.22643 1.95917 7.91092 3.25075 8.83403C3.50486 9.01519 3.85956 9.23506 4.26368 9.48606C5.49204 10.2435 7.77653 11.6544 7.77653 12.7185C7.77653 13.7608 7.49476 14.7244 5.44399 14.7244C4.37051 14.7244 3.46608 14.4084 2.89602 13.8383C2.44831 13.3861 2.22083 12.7821 2.22705 12.0415C2.22705 11.7038 1.95462 11.4282 1.61824 11.4265H1.61201C1.27597 11.4265 1.0032 11.6989 1.00008 12.0353C0.993858 13.0994 1.34857 14.0208 2.0255 14.7026C2.65125 15.3315 3.53556 15.7311 4.55788 15.878V17.3387C4.55788 17.6765 4.83031 17.9506 5.16981 17.9506C5.50896 17.9506 5.78175 17.6765 5.78175 17.3387V15.9216C8.41025 15.7805 8.99695 14.1279 8.99695 12.7185C8.99695 10.9747 6.63493 9.51223 4.90634 8.44539C4.52847 8.21169 4.19555 8.00702 3.96014 7.8383C2.81409 7.02067 2.56929 6.6642 2.56929 5.83243C2.56929 5.32456 2.84483 4.97608 3.07715 4.77312C3.61613 4.30535 4.46349 4.0993 5.35854 4.21858C6.45963 4.37035 7.77614 4.863 7.77614 6.34509V7.34563C7.77614 7.6834 8.04856 7.95756 8.38807 7.95756C8.72722 7.95756 9 7.6834 9 7.34563V6.34509C9.00173 4.63066 7.80138 3.42266 5.78648 3.06006L5.78669 3.06013Z"
        fill={colorFill}
      />
      <path
        className="custom-stroke"
        d="M5.78669 3.06013V1.61193C5.78669 1.27278 5.51427 1 5.17476 1C4.83561 1 4.56283 1.27243 4.56283 1.61193V2.97473C3.68297 3.0335 2.86531 3.33705 2.27516 3.85147C1.67568 4.37351 1.34586 5.07673 1.34586 5.83248C1.34586 7.22643 1.95917 7.91092 3.25075 8.83403C3.50486 9.01519 3.85956 9.23506 4.26368 9.48606C5.49204 10.2435 7.77653 11.6544 7.77653 12.7185C7.77653 13.7608 7.49476 14.7244 5.44399 14.7244C4.37051 14.7244 3.46608 14.4084 2.89602 13.8383C2.44831 13.3861 2.22083 12.7821 2.22705 12.0415C2.22705 11.7038 1.95462 11.4282 1.61824 11.4265H1.61201C1.27597 11.4265 1.0032 11.6989 1.00008 12.0353C0.993858 13.0994 1.34857 14.0208 2.0255 14.7026C2.65125 15.3315 3.53556 15.7311 4.55788 15.878V17.3387C4.55788 17.6765 4.83031 17.9506 5.16981 17.9506C5.50896 17.9506 5.78175 17.6765 5.78175 17.3387V15.9216C8.41025 15.7805 8.99695 14.1279 8.99695 12.7185C8.99695 10.9747 6.63493 9.51223 4.90634 8.44539C4.52847 8.21169 4.19555 8.00702 3.96014 7.8383C2.81409 7.02067 2.56929 6.6642 2.56929 5.83243C2.56929 5.32456 2.84483 4.97608 3.07715 4.77312C3.61613 4.30535 4.46349 4.0993 5.35854 4.21858C6.45963 4.37035 7.77614 4.863 7.77614 6.34509V7.34563C7.77614 7.6834 8.04856 7.95756 8.38807 7.95756C8.72722 7.95756 9 7.6834 9 7.34563V6.34509C9.00173 4.63066 7.80138 3.42266 5.78648 3.06006L5.78669 3.06013Z"
        stroke={strokeFill}
        strokeWidth="0.4"
        mask="url(#path-1-outside-1_4382_889)"
      />
    </svg>
  );
};

export default ResellDeviceSVGIcon;
