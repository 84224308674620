export const MARKETPLACE_VIEW_TEST_ID =
  'marketplace-view-outer-container-data-test-id';
export const MARKETPLACE_VIEW_CONTENTS_TEST_ID =
  'marketplace-view-contents-stack-test-id';
export const MARKETPLACE_VIEW_UPPER_BOX_TEST_ID =
  'marketplace-view-upper-box-test-id';

export enum MarketplaceViewTestId {
  MAIN_TEST_ID = 'marketplace-view-outer-container-data-test-id',
  CONTENTS_TEST_ID = 'marketplace-view-all-contents-stack-data-test-id',
  UNIQUE_CONTENTS_STACK_TEST_ID = 'marketplace-view-unique-stack-data-test-id',
}

export const DEFAULT_PAGE_LIMIT = 15;
export const INFINITE_SCROLL_THRESHOLD = 0.8;
export const RETAIL_PRICE = 'Retail Price';
