import { SxProps } from '@mui/material';

const emptySx: SxProps = {};

export const StepperMainTitleSx: SxProps = {
  fontWeight: 500,
  maxHeight: '24px',
};

export const getStepperBoxSx = ({
  stepConnectorSx = {},
  stepLabelSx = {},
}: {
  stepConnectorSx: SxProps;
  stepLabelSx: SxProps;
}): SxProps => {
  const stepperConnectorOverrideSx = {
    '& .MuiStepConnector-line': {
      borderLeftWidth: '1px',
      height: '42px',
      mt: '-22px',
      mb: '-18px',
      position: 'relative',
      width: 'calc(100% - 6px)',
      ...stepConnectorSx,
    },
  };

  const stepLabelOverrideSx = {
    '& .MuiStepLabel-label': {
      boxSizing: 'border-box',
      pl: '12px',
      position: 'relative',
      top: '8px',
      ...stepLabelSx,
    },
  };

  return {
    boxSizing: 'border-box',
    overflowY: 'scroll',
    pb: '24px',

    ...stepperConnectorOverrideSx,
    ...stepLabelOverrideSx,
  };
};
