import { gql } from '@apollo/client';
import { BackendOperations } from 'global-constants';

const assetsQueryName =
  process.env.REACT_APP_OPERATIONS_BACKEND === BackendOperations.ACUMATICA
    ? 'retrievableAssets'
    : 'assets';

const assetsTypeName =
  process.env.REACT_APP_OPERATIONS_BACKEND === BackendOperations.ACUMATICA
    ? `
    type: assetType {
      id
      name
    }`
    : 'type';

const assetsDateIssuedName =
  process.env.REACT_APP_OPERATIONS_BACKEND === BackendOperations.ACUMATICA
    ? `
    inventoryLog {
      dateOfLastCheckOut
    }`
    : 'dateOfLastCheckOut';

export const SEARCH_COLLABORATORS_BY_ORG_ID = gql`
  query collaborators(
    $organizationId: ID!
    $offset: Int!
    $firstName: String
    $lastName: String
    $email: String
    $personalEmail: String
    $workEmail: String
  ) {
    collaborators(
      collaboratorSearchInput: {
        organizationId: $organizationId
        offset: $offset
        firstName: $firstName
        lastName: $lastName
        email: $email
        personalEmail: $personalEmail
        workEmail: $workEmail
      }
    ) {
      collaborators {
        id
        firstName
        lastName
        email
        personalEmail
        workEmail
        assets: ${assetsQueryName} {
          id
          make
          model
          serialNumber
          ${assetsTypeName}
          displaySize
          memory
          storage
          ${assetsDateIssuedName}
          modelNumber
          color
        }
      }
      count
    }
  }
`;

export const RETRIEVE_COLLABORATOR_ADDRESSES = gql`
  query retrieveCollaborator($id: String!) {
    collaborator(id: $id) {
      id
      primaryAddress {
        id
      }
      workAddresses {
        id
        streetAddress1
        streetAddress2
        city
        state
        principalRegion
        country
        phoneNumber
        zipCode
      }
      homeAddresses {
        id
        streetAddress1
        streetAddress2
        city
        state
        principalRegion
        country
        phoneNumber
        zipCode
      }
    }
  }
`;
