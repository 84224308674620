export const CHANGE_ORDER_STATUS_SUBTITLE =
  'This action is irreversible, recipients and admins will be notified accordingly.';
export const SUBMIT_BUTTON_COPY = 'Yes, change status';
export const CANCEL_BUTTON_COPY = 'No, cancel';
const MODAL_PAPER_HEIGHT = 300;
const MODAL_PAPER_PROPS_HEIGHT_OFFSET = 48;
export const MODAL_PAPER_PROPS_HEIGHT = `${MODAL_PAPER_HEIGHT}px`;
export const MODAL_OUTER_STACK_PROPS_HEIGHT = `${
  MODAL_PAPER_HEIGHT - MODAL_PAPER_PROPS_HEIGHT_OFFSET
}px`;
