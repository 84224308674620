import Stack from '@mui/material/Stack';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { DetailsHeaderProps } from './types';

import {
  BackButtonSx,
  BackButtonTextSx,
  DownloadErrorsButtonSx,
  DownloadErrorsButtonTextSx,
  UploadErrorsFileNameTextSx,
  UploadErrorsHeaderSx,
} from './styles';
import { useTheme } from '@mui/material/styles';
import {
  UPLOAD_ERRORS_BACK_BUTTON_COPY,
  UPLOAD_ERRORS_DOWNLOAD_BUTTON_COPY,
} from './constants';
import { ArrowBack, GetApp } from '@mui/icons-material';
import TextIconButton from 'components/TextIconButton';
import { getTotalErrorsText, getUploadedCountsText } from './utils';

function DetailsHeader(props: DetailsHeaderProps) {
  const {
    onDownloadErrorsClick,
    onClearSelectedUploadState,
    selectedUpload,
    domainLabel,
  } = props;
  const theme = useTheme();

  return (
    <Stack sx={UploadErrorsHeaderSx}>
      <Box sx={BackButtonSx}>
        <TextIconButton
          aria-describedby="upload-error-details"
          text={UPLOAD_ERRORS_BACK_BUTTON_COPY}
          textStyles={BackButtonTextSx}
          ariaLabel="Back button"
          onClick={onClearSelectedUploadState}
          icon={<ArrowBack />}
        />
      </Box>
      <Box>
        <Typography variant="subtitle1" sx={UploadErrorsFileNameTextSx}>
          {selectedUpload?.originalFileName}
        </Typography>
        <Stack direction="row" spacing={4}>
          <Stack>
            <Typography fontWeight="500" variant="subtitle2" color="error">
              {getUploadedCountsText(
                selectedUpload.errorCount,
                selectedUpload.errorCount + selectedUpload.successCount,
                domainLabel
              )}
            </Typography>
            <Typography
              variant="body2"
              fontWeight="400"
              fontSize="12px"
              color={theme?.palette?.colors?.gray60}
            >
              {getTotalErrorsText(selectedUpload.errors.length)}
            </Typography>
          </Stack>
          <TextIconButton
            aria-describedby="download-errors-file"
            ariaLabel="Download errors file"
            onClick={onDownloadErrorsClick}
            buttonStyles={DownloadErrorsButtonSx}
            textStyles={DownloadErrorsButtonTextSx}
            text={UPLOAD_ERRORS_DOWNLOAD_BUTTON_COPY}
            icon={<GetApp />}
          />
        </Stack>
      </Box>
    </Stack>
  );
}

export default DetailsHeader;
