export const RADIO = 'RADIO';
export const TEXT = 'TEXT';
export const ADDRESS = 'ADDRESS';
export const TEXT_AREA = 'TEXT_AREA';
export const SINGLE_SELECT = 'SINGLE_SELECT';

export const RETRIEVAL_OPTION_TYPES = [
  ADDRESS,
  RADIO,
  SINGLE_SELECT,
  TEXT_AREA,
  TEXT,
];

export const RETRIEVAL_OPTION_TYPE_LOOKUP = RETRIEVAL_OPTION_TYPES.reduce(
  (table: Record<string, string>, nextType) => {
    // eslint-disable-next-line security/detect-object-injection
    table[nextType] = nextType;
    return table;
  },
  {}
);

export const MOCK_RETRIEVAL_OPTIONS: Record<string, any> = {};
