import React from 'react';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import TextIconButton from '../../TextIconButton';

import {
  IconTextSx,
  PopoverContainerSx,
  PopoverPaperContainerSx,
  ContainerSx,
  UploadPopoverContainer,
  NewUploadsBubbleSx,
  ButtonBoxSx,
  NewUploadsTextSx,
} from './styles';
import { UploadPopoverProps } from './types';
import { Upload } from '@mui/icons-material';
import UploadFileInput from '../UploadFileInput';
import UploadPopoverHeader from '../UploadPopoverHeader';
import Bubble from 'components/Bubble';
import UploadHistory from '../history/UploadHistory';
import UploadErrorDetails from '../details/UploadErrorDetails';
import NewUploadNotification from '../notification/NewUploadNotification';

function UploadPopover(props: UploadPopoverProps) {
  const {
    onFileUpload,
    onDownloadTemplateClick,
    onDownloadErrorsClick,
    searchUploads,
    historicQuery,
    newUploadCount,
    viewableUploads,
    onUploadSelect,
    onHistoryLookbackChange,
    onHistoryPagination,
    selectedUpload,
    domainLabel,
    togglePopover,
    open,
    file,
    setFile,
    showNewUploadNotification,
    onNewUploadNotificationClick,
    onCloseNewUploadNotification,
    onToggleHistoryLookbackDropdown,
    historyLookbackDropdownOpen,
  } = props;
  const id = open ? 'column-select-popover' : undefined;
  const ref = React.useRef();

  const pollingRef = React.useRef<any>(null);
  React.useEffect(() => {
    const startPolling = () => {
      if (!pollingRef.current) {
        searchUploads();
      }
      pollingRef.current = setInterval(() => {
        searchUploads();
      }, 5000);
    };
    startPolling();

    return () => {
      clearInterval(pollingRef.current);
    };
  }, []);

  return (
    <>
      <Stack sx={UploadPopoverContainer}>
        <Box ref={ref} sx={ButtonBoxSx}>
          <TextIconButton
            aria-describedby={id}
            text={`Import ${domainLabel}`}
            textStyles={IconTextSx}
            ariaLabel="Import data"
            onClick={() => togglePopover(true)}
            icon={<Upload />}
          />
          <Bubble
            show={!!newUploadCount}
            text={newUploadCount > 9 ? '9+' : `${newUploadCount}`}
            bubbleSx={NewUploadsBubbleSx(newUploadCount)}
            textSx={NewUploadsTextSx}
          />
        </Box>
        <Popover
          id={id}
          sx={PopoverContainerSx}
          PaperProps={{
            sx: PopoverPaperContainerSx,
          }}
          open={open}
          onClose={() => togglePopover(false)}
          anchorEl={ref.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Stack sx={PopoverContainerSx}>
            <Stack sx={ContainerSx}>
              <UploadPopoverHeader
                domainLabel={domainLabel}
                onClosePopover={() => togglePopover(false)}
              />
              {selectedUpload ? (
                <UploadErrorDetails
                  selectedUpload={selectedUpload}
                  domainLabel={domainLabel}
                  onClearSelectedUploadState={() => onUploadSelect(undefined)}
                  onDownloadErrorsClick={onDownloadErrorsClick}
                />
              ) : (
                <Box>
                  <UploadFileInput
                    onFileUpload={onFileUpload}
                    onDownloadTemplateClick={onDownloadTemplateClick}
                    domainLabel={domainLabel}
                    file={file}
                    setFile={setFile}
                  />
                  <UploadHistory
                    viewableUploads={viewableUploads}
                    historicQuery={historicQuery}
                    onLookbackChange={onHistoryLookbackChange}
                    onPagination={onHistoryPagination}
                    onUploadSelect={onUploadSelect}
                    onToggleActionsLookbackDropdown={
                      onToggleHistoryLookbackDropdown
                    }
                    actionsLookbackDropdownOpen={historyLookbackDropdownOpen}
                  />
                </Box>
              )}
            </Stack>
          </Stack>
        </Popover>
      </Stack>
      <NewUploadNotification
        domainLabel={domainLabel}
        show={showNewUploadNotification}
        onClose={onCloseNewUploadNotification}
        onViewNewUpload={onNewUploadNotificationClick}
      />
    </>
  );
}

export default UploadPopover;
