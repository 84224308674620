/* eslint-disable security/detect-object-injection */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { NavigationContext } from 'context/NavigationContext';
import Button from 'components/Button';
import { FlowStepThroughProps } from './types';
import {
  BoxStepThroughSxProps,
  ButtonStackSxProps,
  CancelButtonSxProps,
  NextButtonSxProps,
  StackStepThroughSxProps,
  StepperSxProps,
} from './styles';
import {
  TEST_ID_CANCEL_BUTTON,
  TEST_ID_NEXT_BUTTON,
  TEST_ID_FLOW_STEP_THROUGH,
  STEP_TEST_ID_BY_ENUM,
  STEP_LABEL_TEST_ID_BY_ENUM,
} from 'pages/Retrieval/constants';
import {
  RetrievalFlowStep,
  RetrievalFlowStepByOrder,
  RetrievalStepNames,
} from 'pages/Retrieval/RetrievalService/constants';
import {
  constructCreateRetrievalOrderPayload,
  shouldNextButtonBeDisabled,
} from './utils';
import { GlobalModals } from 'store/constants';
import { modalSlice } from 'store/slices';
import useGetGlobalPurchaser from 'hooks/useGetGlobalPurchaser';
import { Collaborator } from 'types';
import LoadingIndictor from 'components/LoadingIndicator';
import { RetrievalStepSubRoutes } from 'global-constants';
import { useCreateRetrievalOrder } from './hooks/useCreateRetrievalOrder';
import useGetUserOrganization from 'hooks/useGetUserOrganization';

const FlowStepThrough = (props: FlowStepThroughProps) => {
  const { retrievalFlow } = props;
  const navigate = useNavigate();
  const navigator = React.useContext(NavigationContext);
  const theme = useTheme();
  const atLeastMidScreenWidth = useMediaQuery(theme.breakpoints.down('md'));
  const shouldDisableNextButton = shouldNextButtonBeDisabled(retrievalFlow);
  const dispatch = useDispatch();
  const globalPurchaser = useGetGlobalPurchaser();
  const organization = useGetUserOrganization();

  const createRetrievalOrder = useCreateRetrievalOrder();

  const retrievalGoto = {
    locationStep: () => {
      retrievalFlow.gotoLocationStep();
      navigate(navigator.pathToRetrieval(RetrievalStepSubRoutes.LOCATION));
    },
    employeeStep: () => {
      retrievalFlow.gotoEmployeeStep();
      navigate(navigator.pathToRetrieval(RetrievalStepSubRoutes.EMPLOYEES));
    },
    reviewStep: () => {
      retrievalFlow.gotoReviewStep();
      navigate(navigator.pathToRetrieval(RetrievalStepSubRoutes.REVIEW));
    },
    submitStep: () => {
      retrievalFlow.gotoSubmitStep();
      navigate(navigator.pathToRetrieval(RetrievalStepSubRoutes.SUBMIT));
    },
  };

  const RetrievalStepGoTo: Record<string, Function> = {
    CHOOSE_TO_FROM_LOCATIONS: retrievalGoto.locationStep,
    EMPLOYEE_SELECTION: retrievalGoto.employeeStep,
    REVIEW_SELECTIONS: retrievalGoto.reviewStep,
    RETRIEVAL_SUBMISSION: retrievalGoto.submitStep,
  };

  const handleClickNext = () => {
    const getNextByStep = {
      [RetrievalFlowStep.CHOOSE_TO_FROM_LOCATIONS]: retrievalGoto.employeeStep,
      [RetrievalFlowStep.EMPLOYEE_SELECTION]: retrievalGoto.reviewStep,
      [RetrievalFlowStep.REVIEW_SELECTIONS]: retrievalGoto.submitStep,
      [RetrievalFlowStep.RETRIEVAL_SUBMISSION]: () => {
        const payload = constructCreateRetrievalOrderPayload(
          props.retrievalFlow,
          globalPurchaser as Collaborator,
          !organization?.hrisEmployeeDataSyncedAt
        );
        createRetrievalOrder.createRetrievalOrder({
          variables: payload,
          onCompleted() {
            dispatch(
              modalSlice.actions.setCurrentModal(
                GlobalModals.RETRIEVAL_CONFIRMATION
              )
            );
            retrievalFlow.resetRetrievalState();
            navigate('/', { replace: true });
            navigate(navigator.pathToHome());
          },
        });
      },
    };

    // NOTE: used template (string) literal because of typescript error involving enums and records
    getNextByStep[`${retrievalFlow.currentStep}`]();
  };

  const handleClickCancel = () => {
    props.openCancelDialog();
  };

  const nextCTAText =
    props.retrievalFlow.currentStep === RetrievalFlowStep.RETRIEVAL_SUBMISSION
      ? 'Submit'
      : 'Next';

  return (
    <>
      <Box
        sx={BoxStepThroughSxProps}
        data-testid={TEST_ID_FLOW_STEP_THROUGH}
        data-test-current-step={props.retrievalFlow.currentStep}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={0}
          sx={StackStepThroughSxProps}
        >
          <Stepper
            activeStep={
              RetrievalFlowStepByOrder[props.retrievalFlow.currentStep]
            }
            alternativeLabel={!!atLeastMidScreenWidth}
            sx={StepperSxProps}
          >
            {Object.entries(RetrievalStepNames).map(
              ([stepMacro, stepLabel], index) => {
                const stepKey = `${stepMacro}::${stepLabel}`;
                const stepTestId = STEP_TEST_ID_BY_ENUM[stepMacro];
                const stepLabelTestId = STEP_LABEL_TEST_ID_BY_ENUM[stepMacro];
                const stepActive = stepMacro === retrievalFlow.currentStep;

                return (
                  <Step
                    data-testid={stepTestId}
                    key={stepKey}
                    active={stepActive}
                  >
                    <StepButton onClick={() => RetrievalStepGoTo[stepMacro]()}>
                      <StepLabel data-testid={stepLabelTestId}>
                        {stepLabel}
                      </StepLabel>
                    </StepButton>
                  </Step>
                );
              }
            )}
          </Stepper>

          <Stack
            direction="row"
            alignItems="flex-end"
            spacing={0}
            sx={ButtonStackSxProps}
          >
            <Button
              gaContext={{
                textCopy: 'Cancel',
                navigates_to: 'N/A',
                purpose: 'Cancels Step Through Flow',
              }}
              color="secondary"
              data-testid={TEST_ID_CANCEL_BUTTON}
              onClick={handleClickCancel}
              size="small"
              sx={CancelButtonSxProps}
              variant="text"
            >
              <Typography variant="inherit">Cancel</Typography>
            </Button>
            <Button
              gaContext={{
                textCopy: nextCTAText,
                navigates_to: 'N/A',
                purpose: 'Goes To Next Step ',
              }}
              color="primary"
              data-testid={TEST_ID_NEXT_BUTTON}
              onClick={handleClickNext}
              size="small"
              sx={NextButtonSxProps}
              variant="contained"
              disabled={shouldDisableNextButton}
            >
              <Typography variant="inherit">{nextCTAText}</Typography>
            </Button>
          </Stack>
        </Stack>
      </Box>
      <LoadingIndictor
        loading={createRetrievalOrder.isCreateRetrievalOrderLoading}
      />
    </>
  );
};

export default FlowStepThrough;
