import React from 'react';

const SpainFlagSVGIcon = () => {
  return (
    <svg
      width="30"
      height="20"
      viewBox="0 0 30 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <rect width="28" height="20" rx="2" fill="white" />
        <mask
          id="mask0_3141_38649"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="28"
          height="20"
        >
          <rect width="28" height="20" rx="2" fill="white" />
        </mask>
        <g mask="url(#mask0_3141_38649)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 5.33333H28V0H0V5.33333Z"
            fill="#DD172C"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 20.0013H28V14.668H0V20.0013Z"
            fill="#DD172C"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 14.6654H28V5.33203H0V14.6654Z"
            fill="#FFD133"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.33325 9.33333H8.66659V10H7.33325V9.33333Z"
            fill="#FFEDB1"
          />
          <path
            d="M6.0591 9.36232C6.0429 9.16798 6.19627 9.0013 6.39128 9.0013H8.27555C8.47056 9.0013 8.62393 9.16798 8.60773 9.36232L8.43645 11.4177C8.39326 11.936 7.95999 12.3346 7.4399 12.3346H7.22693C6.70684 12.3346 6.27357 11.936 6.23038 11.4177L6.0591 9.36232Z"
            stroke="#A41517"
            stroke-width="0.666667"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6 10H8.66667V10.6667H8L7.33333 12L6.66667 10.6667H6V10Z"
            fill="#A41517"
          />
          <rect
            x="4"
            y="8"
            width="1.33333"
            height="4.66667"
            rx="0.666667"
            fill="#A41517"
          />
          <rect
            x="9.33325"
            y="8"
            width="1.33333"
            height="4.66667"
            rx="0.666667"
            fill="#A41517"
          />
          <path
            d="M6 7.73464C6 7.14553 6.47756 6.66797 7.06667 6.66797H7.6C8.1891 6.66797 8.66667 7.14553 8.66667 7.73464C8.66667 7.88191 8.54728 8.0013 8.4 8.0013H6.26667C6.11939 8.0013 6 7.88191 6 7.73464Z"
            fill="#A41517"
          />
        </g>
      </g>
    </svg>
  );
};

export default SpainFlagSVGIcon;
