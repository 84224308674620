import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ExpandIcon = () => {
  return (
    <ExpandMoreIcon
      sx={{ color: 'colors.gray75', width: '24px', height: '24px' }}
    />
  );
};

export default ExpandIcon;
