export enum EditEmployeeOperations {
  EDIT_COLLABORATOR = 'editCollaborator',
  ADD_HOME_ADDRESS = 'addHomeAddress',
  EDIT_HOME_ADDRESS = 'editHomeAddress',
  ADD_WORK_ADDRESS = 'addWorkAddress',
  EDIT_WORK_ADDRESS = 'editWorkAddress',
}

export const EDIT_EMPLOYEE_FORM_INPUT_KEYS = [
  'firstName',
  'lastName',
  'email',
  'workEmail',
  'personalEmail',
  'group',
  'hireDate',
  'startDate',
  'phoneNumber',
];

export const EMPLOYEE_DATE_TYPE_FIELDS = ['hireDate', 'startDate'];

export enum CustomEmployeeFormInputKeys {
  ADD_HOME_ADDRESS = 'addHomeAddress',
  ADD_WORK_ADDRESS = 'addWorkAddress',
  EDIT_HOME_ADDRESSES = 'editHomeAddresses',
  EDIT_WORK_ADDRESSES = 'editWorkAddresses',
}

export const EMPLOYEE_BLANK_ADD_ADDRESS_TABLE = {
  streetAddress1: '',
  streetAddress2: '',
  city: '',
  state: '',
  principalRegion: '',
  country: '',
  zipCode: '',
};

export const EMPTY_EMPLOYEE_FORM_INPUTS = {
  firstName: '',
  lastName: '',
  email: '',
  workEmail: '',
  personalEmail: '',
  phoneNumber: '',
  group: null,
  hireDate: null,
  startDate: null,
};

export const EMPLOYEE_FORM_MODAL_TESTID = 'employee-form-modal-testid';

export const FIRST_NAME_TESTID = 'first-name-input-field-testid';

export const LAST_NAME_TESTID = 'last-name-input-field-testid';

export const WORK_EMAIL_TESTID = 'work-email-input-field-testid';

export const PERSONAL_EMAIL_TESTID = 'personal-email-input-field-testid';
