import React from 'react';
import Chip from '@mui/material/Chip';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import { AssetImageChipProps } from './types';

const AssetImageChip = (props: AssetImageChipProps) => {
  const numberOfImages = props.assetImages?.length;

  if (!numberOfImages) return null;

  return (
    <Chip
      icon={<PhotoOutlinedIcon />}
      label={numberOfImages}
      sx={{
        '&:hover': {
          backgroundColor: 'colors.grayBD',
        },
      }}
    />
  );
};

export default AssetImageChip;
