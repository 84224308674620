import { Asset } from 'types';
import { DeviceMap } from './types';
import { nanoid } from 'nanoid';
import { PURCHASE_DATE_TOOLTIP_COPY } from './constants';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';

export const getDeviceMap = (device: Asset) => {
  const deviceMap: DeviceMap[][] = [
    [
      {
        id: nanoid(),
        title: 'DEPOT LOCATION',
        description: device?.depot?.name,
        type: 'text',
      },
      {
        id: nanoid(),
        title: 'SERIAL NUMBER',
        description: device?.serialNumber,
        type: 'text',
      },
      {
        id: nanoid(),
        title: 'COLOR',
        description: device?.color,
        type: 'text',
      },
      {
        id: nanoid(),
        title: 'STORAGE',
        description: device?.storage,
        type: 'text',
      },
      {
        id: nanoid(),
        title: 'ASSET NUMBER',
        description: device?.assetNumber,
        type: 'text',
      },
    ],
    [
      {
        id: nanoid(),
        title: 'MAKE',
        description: device?.make,
        type: 'text',
      },
      {
        id: nanoid(),
        title: 'DEVICE ISSUE',
        description: device?.deviceAssessment,
        type: 'text',
      },
      {
        id: nanoid(),
        title: 'DEVICE SIZE',
        description: device?.displaySize,
        type: 'text',
      },
      {
        id: nanoid(),
        title: 'PROCESSOR',
        description: device?.processor,
        type: 'text',
      },
      {
        id: nanoid(),
        title: 'PURCHASE DATE',
        titleTooltip: PURCHASE_DATE_TOOLTIP_COPY,
        TitleIcon: InfoOutlinedIcon,
        description: device?.purchaseDate,
        DescriptionIcon: VerifiedIcon,
        type: 'date',
      },
    ],
    [
      {
        id: nanoid(),
        title: 'MODEL',
        description: device?.model,
        type: 'text',
      },
      {
        id: nanoid(),
        title: 'CONDITION',
        description: device?.cosmeticCondition,
        type: 'text',
      },
      {
        id: nanoid(),
        title: 'MEMORY',
        description: device?.memory,
        type: 'text',
      },
      {
        id: nanoid(),
        title: 'KEYBOARD CONFIGURATION',
        description: device?.keyboard,
        type: 'text',
      },
      {
        id: nanoid(),
        title: 'WARRANTY EXPIRATION DATE',
        description: device?.warrantyExpiration,
        type: 'date',
      },
    ],
  ];
  return deviceMap;
};
