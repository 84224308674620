/* eslint-disable sort-exports/sort-exports */
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'store/types';
import { assetManagementSlice, getMatchingOptions } from 'store/slices';
import Box from '@mui/material/Box';
import AMDashComplexTypeAhead from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/components/AMDashComplexTypeAhead';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import { AMDComplexTypeAheadProps } from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/types';
import { ASM_DASH_DRAWER_INPUT_BOX_CLASS } from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/constants';

const AssetFieldComplexTypeAhead = (props: AMDComplexTypeAheadProps) => {
  const dispatch = useDispatch();
  const { availableOptionsName, selectedOptionsName } = getMatchingOptions(
    props.filterKey
  );

  if (!availableOptionsName || !selectedOptionsName) return null;

  const availableTypeAheadOptions = useSelector<GlobalState, string[][]>(
    (state) =>
      state.assetManagement.filters[
        availableOptionsName as keyof typeof state.assetManagement.filters
      ]
  );

  const selectedTypeAheadOptions = useSelector<GlobalState, string[][]>(
    (state) =>
      state.assetManagement.filters[
        selectedOptionsName as keyof typeof state.assetManagement.filters
      ]
  );

  const [queryTypeAheadOptions] = useSafeLazyQuery(
    props.lazyQueryDocumentNode,
    {
      onCompleted: (data) => {
        const matchIds = data[props.responseField]?.matchIds ?? [];
        const matches = data[props.responseField]?.matches ?? [];

        const valueIdOptions = matches.map((match: string, index: number) => [
          match,
          matchIds[index],
        ]);

        dispatch(
          assetManagementSlice.actions.populateAvailableTypeAHeadsReducer({
            optionKey: props.filterKey,
            options: valueIdOptions,
          })
        );
      },
    }
  );

  const handleDebounceEffect = (debounceValue: string) => {
    queryTypeAheadOptions({
      variables: {
        searchTerm: `%${debounceValue}%`,
      },
    });
  };

  return (
    <Box className={ASM_DASH_DRAWER_INPUT_BOX_CLASS}>
      <AMDashComplexTypeAhead
        {...props}
        handleDebounceEffect={handleDebounceEffect}
        availableOptions={availableTypeAheadOptions}
        selectedOptions={selectedTypeAheadOptions}
      />
    </Box>
  );
};

export default AssetFieldComplexTypeAhead;
