import React from 'react';
import { SxProps } from '@mui/material';
import {
  MAIN_STACK_SIZE_MD,
  MAIN_STACK_SIZE_SM,
  NEW_MODAL_MAX_HEIGHT,
  NEW_MODAL_GLOBAL_PADDING,
} from './constants';

export const getNewModalBaseSx = (isForm: boolean): SxProps => ({
  '& .MuiDialog-paper': {
    borderRadius: '24px',
    p: isForm ? 0 : NEW_MODAL_GLOBAL_PADDING,
    position: 'relative',
  },
  '&: .MuiFormHelperText-root': {
    position: 'absolute',
    bottom: '-20px',
  },
});

export const NewModalPaperSx: SxProps = {
  height: {
    xs: '100vh',
    sm: '100vh',
    md: NEW_MODAL_MAX_HEIGHT,
    lg: NEW_MODAL_MAX_HEIGHT,
    xl: NEW_MODAL_MAX_HEIGHT,
  },
  maxWidth: '820px',
  width: '100vw',
};

export const getNewModalPaperSx = (maxHeight: string): SxProps => ({
  height: {
    xs: '100vh',
    sm: '100vh',
    md: maxHeight,
    lg: maxHeight,
    xl: maxHeight,
  },
  maxWidth: '760px',
  width: '100vw',
});

export const NewModalMainStackSx: SxProps = {
  height: {
    xs: MAIN_STACK_SIZE_SM,
    sm: MAIN_STACK_SIZE_SM,
    md: MAIN_STACK_SIZE_MD,
    lg: MAIN_STACK_SIZE_MD,
    xl: MAIN_STACK_SIZE_MD,
  },
};

export const getNewModalMainStackSx = (maxHeight: string): SxProps => {
  const newMainStackSize = `calc(${maxHeight} - 48px)`;
  return {
    height: {
      xs: MAIN_STACK_SIZE_SM,
      sm: MAIN_STACK_SIZE_SM,
      md: newMainStackSize,
      lg: newMainStackSize,
      xl: newMainStackSize,
    },
  };
};

export const NewModalTitleSx: SxProps = {
  fontWeight: 700,
  lineHeight: '38.4px',
  p: NEW_MODAL_GLOBAL_PADDING,
  pb: 0,
};

export const getNewModalSubtitleSx = (subtitle: string): SxProps => ({
  ...(subtitle && { p: NEW_MODAL_GLOBAL_PADDING }),
  pb: 0,
});

export const getNewModalTitlesBoxSx = (hasBottomBorder: boolean): SxProps => ({
  ...(hasBottomBorder && {
    borderBottom: '1px solid rgba(19, 20, 20, 0.12)',
    pb: NEW_MODAL_GLOBAL_PADDING,
  }),
});

export const getNewModalButtonStackSx = (hasTopBorder: boolean): SxProps => ({
  width: '100%',
  px: NEW_MODAL_GLOBAL_PADDING,
  ...(hasTopBorder && {
    borderTop: '1px solid rgba(19, 20, 20, 0.12)',
    position: 'relative',
    pt: NEW_MODAL_GLOBAL_PADDING,
    top: NEW_MODAL_GLOBAL_PADDING,
  }),
});

export const NewModalButtonBaseSx: SxProps = {
  padding: '6px 16px',
  width: 'fit-content',
  height: '36px',
  '& .MuiTypography-root': {
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '24px',
  },
  '&#cancel-button': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

export const NewModalSubmitButtonSx: SxProps = {
  ...NewModalButtonBaseSx,
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
  borderRadius: '30px',
};

export const NewModalBaseCloseIconButtonSx: SxProps = {
  position: 'absolute',
  right: '10px',
  top: '12px',
};

export const NewModalBaseDividerStyles: React.CSSProperties = {
  left: 0,
  position: 'absolute',
  width: 'calc(100% - 2px)',
  top: '123px',
  color: 'transparent',
  borderBottom: '1px solid rgba(19, 20, 20, 0.12)',
};

export const NewModalTopDividerStyles: React.CSSProperties = {
  ...NewModalBaseDividerStyles,
  top: '123px',
};

export const NewModalBottomDividerStyles: React.CSSProperties = {
  ...NewModalBaseDividerStyles,
  bottom: '123px',
};

export const NewModalFormChildrenBoxSx: SxProps = {
  p: NEW_MODAL_GLOBAL_PADDING,
};

export const TitleIconSx = { mr: '1rem' };
