import { gql } from '@apollo/client';

export const UPDATE_ORDER_STATUS = gql`
  mutation updateOrder($id: ID!, $status: OrderStatusEnum) {
    updateOrder(orderUpdateInput: { id: $id, status: $status }) {
      id
      status
    }
  }
`;
