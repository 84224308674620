const IrelandFlagSVGIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="none"
    >
      <rect fill="#169b62" width="28" height="20" />
      <rect fill="#fff" x="9.33" width="18.67" height="20" />
      <rect fill="#ff883e" x="18.67" width="9.33" height="20" />
    </svg>
  );
};

export default IrelandFlagSVGIcon;
