import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import * as styles from './styles';

const NotificationBanner = () => {
  const handleReload = React.useCallback(() => {
    window.location.reload();
  }, []);
  return (
    <Stack sx={styles.BannerContentSx}>
      <Stack sx={styles.BannerIconSx}>
        <InfoOutlinedIcon fontSize="small" />
      </Stack>
      <Stack>
        <Stack>Update available</Stack>
        <Stack sx={styles.BannerInnerContentSx}>
          You are using an outdated version of the app. To load the latest
          version, please click 'Update Now'.
          <Button sx={styles.BannerButtonSx} onClick={handleReload}>
            Update now
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default NotificationBanner;
