import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSafeMutation } from 'hooks/useSafeMutation';
import { executeNowThenWait } from 'services/misc';
import { notificationSlice } from 'store/slices';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';
import { DELETE_ORDER_ITEM } from './mutations';
import { DeleteOrderItemModalProps } from './types';
import * as DeleteOrderItemStyles from './styles';

const DeleteOrderItemModal = (props: DeleteOrderItemModalProps) => {
  if (!props.orderItemId) return null;
  const dispatch = useDispatch();
  const styles = DeleteOrderItemStyles;

  const [deleteOrderItem, { loading: deleteOrderItemLoading }] =
    useSafeMutation(DELETE_ORDER_ITEM);

  const handleCloseClick = () => {
    props.closeModal();
  };

  const notifyOnSuccess = () => {
    dispatch(
      notificationSlice.actions.setNotice({
        showNotice: true,
        noticeContent: NotificationMessages.CHANGES_SAVED_SUCCESS,
      })
    );
  };

  const handleDeleteClick = () => {
    deleteOrderItem({
      variables: {
        id: props.orderItemId,
      },
      update: (cache) => {
        cache.modify({
          optimistic: true,
          fields: {
            // keep this to re-run the searchOrderItems query
            searchOrderItems(data) {},
          },
        });
      },
      onCompleted: () => {
        executeNowThenWait(notifyOnSuccess, () => props.closeModal(), 200);
      },
    });
  };

  return (
    <Dialog
      open={Boolean(props.orderItemId)}
      sx={styles.CancelDialogSx}
      disableEscapeKeyDown
      onClose={handleCloseClick}
    >
      <Stack
        alignItems="flex-start"
        direction="column"
        justifyContent="space-between"
        sx={styles.CancelOuterStackSx}
      >
        <Stack>
          <Typography variant="h5" sx={styles.CancelMainTitleSx}>
            {`Are you sure to want to delete ${
              !props.orderItemNumber ? 'the current' : ''
            } order item ${
              props.orderItemNumber ? `#${props.orderItemNumber}` : ''
            } from this order?`}
          </Typography>
        </Stack>
        <Stack
          alignItems="flex-end"
          direction="row-reverse"
          sx={styles.CancelButtonsStackSx}
        >
          <ButtonGroup
            disableRipple
            variant="text"
            sx={styles.CancelButtonGroupSx}
          >
            <Button onClick={handleCloseClick}>Cancel</Button>
            <Button onClick={handleDeleteClick} variant="contained">
              Delete order item
            </Button>
          </ButtonGroup>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default DeleteOrderItemModal;
