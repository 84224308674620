import { SxProps } from '@mui/material';

export const EmployeeFormModalTextInputBaseSx: SxProps = {
  width: '100%',
  backgroundColor: 'transparent',
  p: 0,
  '&: .MuiFormHelperText-root': {
    color: 'transparent',
    position: 'absolute',
    bottom: '-20px',
  },
};

export const EmployeeFormModalPhoneGridSx: SxProps = {
  '& .MuiFormControl-root.MuiTextField-root.MuiTelInput-TextField': {
    pr: '0px !important',
  },
};

export const EmployeeFormModalPhoneInputSx: SxProps = {
  backgroundColor: 'transparent',
  width: '100%',
  '& .MuiDialog-paper': {
    borderRadius: '24px',
    p: '24px',
    pr: 0,
  },
  '&: .MuiFormHelperText-root': {
    position: 'absolute',
    bottom: '-20px',
  },
  '& .MuiInputLabel-root.MuiInputLabel-shrink': {
    position: 'absolute',
    left: '10px',
  },
};
