import { gql } from '@apollo/client';

export const UPDATE_ORDER_MANAGER_ON_ORDER = gql`
  mutation updateOrder($id: ID!, $orderManagerId: ID) {
    updateOrder(
      orderUpdateInput: { id: $id, orderManagerId: $orderManagerId }
    ) {
      id
    }
  }
`;
