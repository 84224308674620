export enum DeviceTypes {
  LAPTOP = 'Laptop',
  TABLET = 'Tablet',
  MOBILE = 'Mobile',
}

export enum DeviceFilters {
  ASSET_TYPE_NAME = 'assetTypeName',
}

export const DEVICE_FILTER_SETTINGS = {
  [DeviceFilters.ASSET_TYPE_NAME]: {
    placeholder: 'Search for device by recipient name',
    optionLabel: 'Recipient name',
    label: 'Type recipient name',
    inputId: 'order-search-by-recipient-name-auto-complete-label',
    searchAssetsQueryField: 'recipientCollaboratorId',
    queryField: 'recipientCollaboratorId',
  },
  EXAMPLE: {
    placeholder: 'Search for device by recipient name',
    optionLabel: 'Recipient name',
    label: 'Type recipient name',
    inputId: 'order-search-by-recipient-name-auto-complete-label',
    searchAssetsQueryField: 'recipientCollaboratorId',
    queryField: 'recipientCollaboratorId',
  },
};

export const DEVICE_FILTER = 'device-filter-';
export const INPUT = 'input';

export const DEVICE_FILTERS_WIDTH = '172px';
export const DEVICE_FILTERS_HEIGHT = '36px';
