/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  RetrievalDialogSx,
  RetrievalDialogPaperSx,
  RetrievalInfoModalBoxSx,
  RetrievalInfoModalTitleSx,
  RetrievalInfoModalBodyCopySx,
  RetrievalInfoModalListStyleSx,
  RetrievalInfoModalFAQSx,
} from './styles';
import RetrievalImage from 'assets/retrievalModalImage.png';
import { RETRIEVAL_MODAL_COPY, RETRIEVAL_MODAL_SEEN } from './constants';
import { CopyListItem } from './types';

const RetrievalInfoModal = () => {
  const [openModal, setOpenModal] = React.useState<boolean>(
    () => !localStorage[RETRIEVAL_MODAL_SEEN]
  );

  const closeModal = () => {
    setOpenModal(false);
    localStorage.setItem(RETRIEVAL_MODAL_SEEN, 'true');
  };

  return (
    <Dialog
      open={openModal}
      sx={RetrievalDialogSx}
      PaperProps={{ sx: { ...RetrievalDialogPaperSx } }}
      onClose={closeModal}
    >
      <IconButton
        edge="start"
        color="inherit"
        onClick={closeModal}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      <Stack
        direction="column"
        spacing={0}
        justifyContent="space-between"
        sx={{ height: '100%' }}
      >
        <Stack direction="column" spacing={0}>
          <Box sx={RetrievalInfoModalBoxSx}>
            <img
              src={RetrievalImage}
              alt="retrieval-image-showing-planes-and-things"
              style={{ width: '379px' }}
            />
          </Box>
          <Typography color="primary" sx={RetrievalInfoModalTitleSx}>
            {RETRIEVAL_MODAL_COPY.title as string}
          </Typography>
          <Typography sx={RetrievalInfoModalBodyCopySx({ pt: '10px' })}>
            {RETRIEVAL_MODAL_COPY.subtitle as string}
          </Typography>
          <Typography sx={RetrievalInfoModalBodyCopySx({ pt: '24px' })}>
            {RETRIEVAL_MODAL_COPY.listIntro as string}
          </Typography>
          <List sx={RetrievalInfoModalListStyleSx}>
            {(RETRIEVAL_MODAL_COPY.list as CopyListItem[]).map(
              (listItem, index) => {
                return (
                  <ListItem key={`${index}-${listItem.bold}`}>
                    <Typography
                      sx={RetrievalInfoModalBodyCopySx({
                        lineHeight: '21.8px',
                      })}
                    >
                      <strong>{listItem.bold}</strong> {listItem.text}
                    </Typography>
                  </ListItem>
                );
              }
            )}
          </List>
        </Stack>
        <Stack>
          <Typography sx={RetrievalInfoModalFAQSx}>
            {RETRIEVAL_MODAL_COPY.textFAQ as string}{' '}
            <Link
              href={RETRIEVAL_MODAL_COPY.linkFAQ as string}
              target="_blank"
              rel="noreferrer"
            >
              FAQ
            </Link>
            .
          </Typography>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default RetrievalInfoModal;
