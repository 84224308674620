import React from 'react';
import { useNavigate } from 'react-router-dom';
import { NavigationContext } from 'context/NavigationContext';
import ProductCatalog from 'pages/Procurement/components/ProductCatalog';
import { useProcurementService } from 'pages/Procurement/hooks/useProcurementService';
import useGetUserPermissions from 'hooks/useGetUserPermissions';
import { UserPermissions } from 'global-constants';

const Catalog = () => {
  const navigate = useNavigate();
  const navigator = React.useContext(NavigationContext);
  const userPermissions = useGetUserPermissions();

  const permissionsAreNotLoaded = userPermissions.includes(
    UserPermissions.NOT__LOADED
  );
  const showProductCatalog = userPermissions.includes(
    UserPermissions.PRODUCT__READONLY_CATALOG__VIEW
  );

  if (permissionsAreNotLoaded && !showProductCatalog)
    navigate(navigator.pathToHome());

  // Have to initialize this to pass to the ProductCatalog component
  const workflow = useProcurementService();

  return <ProductCatalog workflow={workflow} readonly />;
};

export default Catalog;
