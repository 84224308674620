/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { OrderCustomerNotesProps } from './types';
import * as OrderCustomerNotesStyles from './styles';

const OrderCustomerNotes = (props: OrderCustomerNotesProps) => {
  if (!props.order) return null;
  const styles = OrderCustomerNotesStyles;

  const hasCustomerNotes = !!props.order.checkoutNotes;
  const readOnlyCustomerNotes = hasCustomerNotes
    ? props.order.checkoutNotes
    : 'No Customer Notes available';
  return (
    <Container sx={styles.CustomerNotesContainerSx}>
      <Typography sx={styles.CustomerNotesTypographySx}>
        {readOnlyCustomerNotes}
      </Typography>
    </Container>
  );
};

export default OrderCustomerNotes;
