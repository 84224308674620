import { Organization } from 'types';

export const DefaultOrganizationValue: Organization = {
  appleDepNumber: '',
  billingInstructions: '',
  billingNumber: '',
  createdAt: '',
  deletedAt: null,
  id: '',
  internalNotes: '',
  name: '',
  serviceFee: '',
  updatedAt: '',
  hrisEmployeeDataSyncedAt: null,
};

export enum MuiAutoCompleteInputChangeReason {
  CLEAR = 'clear',
}
