import React from 'react';
import { Stack } from '@mui/material';
import { setGlobalState } from 'state';
import { UserPermissions } from 'global-constants';
import OrganizationToggle from './components/OrganizationToggle';
import useGetUserPermissions from '../useGetUserPermissions';
import PurchaserToggle from './components/PurchaserToggle';

const useRenderAppbarComponents = (
  customComponents?: React.ReactNode,
  dependencies?: any[]
) => {
  const userPermissions = useGetUserPermissions();
  const canUserToggleOrganization = userPermissions.includes(
    UserPermissions.ORGANIZATION__TOGGLE
  );
  const canUserProcureOnBehalfOfAdmin = userPermissions.includes(
    UserPermissions.PROCUREMENT__ON_BEHALF
  );

  React.useEffect(() => {
    setGlobalState('appBarCTAs', {
      components: (
        <Stack direction="row" alignItems="center" gap="1rem">
          {canUserToggleOrganization ? <OrganizationToggle /> : null}
          {canUserProcureOnBehalfOfAdmin ? <PurchaserToggle /> : null}
          {customComponents || null}
        </Stack>
      ),
    });

    return () => {
      setGlobalState('appBarCTAs', {
        components: (
          <Stack direction="row" alignItems="center" gap="1rem">
            {canUserToggleOrganization ? <OrganizationToggle /> : null}
            {canUserProcureOnBehalfOfAdmin ? <PurchaserToggle /> : null}
          </Stack>
        ),
      });
    };
  }, [userPermissions, ...(dependencies || [])]);
};

export default useRenderAppbarComponents;
