import { StatusHistoryStep } from 'types';
import { AssetStatusTransition } from 'pages/Assets/components/DevicesDatagrid/types';
import {
  ASSET_STATUS_HISTORY_MAP,
  ASSET_STATUS_KEYWORD_HISTORY_MAP,
  INACTIVE_ICON_GRAY,
} from './constants';

const getFormattedDate = (date: string) => {
  return new Date(date).toLocaleDateString('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
  });
};

const getFormattedTime = (date: string) => {
  return new Date(date).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};

export const getBackUpStatusIconAndColor = (toStatus: string) => {
  let statusIconColor = ASSET_STATUS_KEYWORD_HISTORY_MAP.other;

  const lowerCaseStatus = toStatus.toLowerCase();
  const containsLocked = lowerCaseStatus.includes('locked');
  const containsPending = lowerCaseStatus.includes('pending');

  if (containsLocked) {
    statusIconColor = ASSET_STATUS_KEYWORD_HISTORY_MAP.locked;
  }

  if (containsPending) {
    statusIconColor = ASSET_STATUS_KEYWORD_HISTORY_MAP.pending;
  }

  return statusIconColor;
};

export const convertStatusToStep = (
  assetStatusTransition: AssetStatusTransition,
  options: any = {}
): StatusHistoryStep[] => {
  let steps = [];
  const { from, to, createdAt } = assetStatusTransition;
  const { lastStatus = false, isActive = false, assetCreatedAt } = options;
  const toAssetStatusMap =
    ASSET_STATUS_HISTORY_MAP[to as keyof typeof ASSET_STATUS_HISTORY_MAP];

  const statusIconColor = getBackUpStatusIconAndColor(to);

  const stepIcon = toAssetStatusMap?.statusIcon ?? statusIconColor.statusIcon;

  let iconColor = INACTIVE_ICON_GRAY;

  const hasStatusEntry = Boolean(
    ASSET_STATUS_HISTORY_MAP[to as keyof typeof ASSET_STATUS_HISTORY_MAP]
  );

  if (isActive && hasStatusEntry) {
    iconColor = toAssetStatusMap?.activeColor ?? iconColor;
  }

  if (!hasStatusEntry) {
    iconColor = statusIconColor?.activeColor ?? iconColor;
  }

  steps = [
    {
      stepKey: to,
      stepLabelCopy: to,
      stepIcon,
      iconColor,
      createdAt: `${getFormattedDate(createdAt)} ${getFormattedTime(
        createdAt
      )}`,
    },
  ];

  if (lastStatus && !from) {
    console.warn(
      'AssetStatusTransition missing "from" property. But, it is the last status.'
    );
  }

  if (lastStatus && from) {
    const fromAssetStatusMap =
      ASSET_STATUS_HISTORY_MAP[from as keyof typeof ASSET_STATUS_HISTORY_MAP];
    steps = [
      ...steps,
      {
        stepKey: from,
        stepLabelCopy: from,
        stepIcon: fromAssetStatusMap.statusIcon,
        iconColor: INACTIVE_ICON_GRAY,
        createdAt: `${getFormattedDate(assetCreatedAt)} ${getFormattedTime(
          assetCreatedAt
        )}`,
      },
    ];
  }

  return steps;
};
