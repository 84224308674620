import { SxProps, Theme } from '@mui/material/styles';

export const RecipientAssetsStackSx = (theme: Theme): SxProps => ({
  mt: '18px',
  '& .recipient-assets-control-label': {
    color: '#666666',
    fontSize: '14px',
    height: '20px',
    maxHeight: '20px',

    '&:not(:last-child)': {
      mb: '8px',
    },

    '& .MuiCheckbox-root ~ .MuiTypography-root': {
      color: 'rgba(0, 0, 0, .6)',
      fontSize: '14px',
    },

    '& .Mui-checked': {
      color: theme.palette.colors.blackPearl,
      '& ~ .MuiTypography-root': {
        fontWeight: 600,
      },
    },
  },
});

export const RecipientAssetDividerSx = (
  theme: Theme,
  willRender: boolean
): SxProps => ({
  mb: willRender ? '16px' : 0,
  mt: '16px',
  borderColor: willRender ? theme.palette.colors.gray94 : 'transparent',
});

export const RecipientAssetDetailsSx: SxProps = {
  mt: '16px',
};

export const RecipientAssetImageSx: SxProps = {
  maxWidth: {
    xs: '200px',
    sm: '200px',
    lg: '300px',
    xl: '300px',
  },
};
