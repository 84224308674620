/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { GET_ORDER_ITEMS_BY_ORDER_ID, GET_ORDER_DETAILS } from './queries';
import { OrderItemsPanelProps } from './types';
import {
  ORDER_ITEMS_BOX_CONTAINER_ID,
  ORDER_ITEMS_GRID_CONTAINER_ID,
} from './constants';
import {
  chunkOrderItemsByTrackingId,
  getFlattenedOrderItems,
  getInboundOrderItemAssetDetails,
} from './utils';
import * as OrderItemsPanelStyles from './styles';
import {
  DEFAULT_SCROLL_PAGE_LIMIT,
  INFINITE_SCROLL_THRESHOLD,
  INITIAL_PAGE_NUMBER,
  OrderType,
} from 'global-constants';
import OrderItemPanelCard from './components/OrderItemPanelCard';

const OrderItemsPanel = (props: OrderItemsPanelProps) => {
  if (!props.orderId) return null;

  const styles = OrderItemsPanelStyles;

  const scrollableRef = React.useRef<null | HTMLDivElement>(null);
  const [totalCount, setTotalCount] =
    React.useState<number>(INITIAL_PAGE_NUMBER);
  const [orderItemsList, setOrderItemsList] = React.useState<any[]>([]);
  const [orderItemTrackingChunks, setOrderItemTrackingChunks] = React.useState<
    any[][]
  >([]);
  const [page, setPage] = React.useState<number>(0);
  const [inboundOrderItemAssetDetails, setInboundOrderItemAssetDetails] =
    React.useState<undefined | Record<string, any>>();

  const { data } = useSafeQuery(GET_ORDER_DETAILS, {
    variables: {
      id: props.orderId,
    },
  });

  const { loading: loadingOrderItemsData } = useSafeQuery(
    GET_ORDER_ITEMS_BY_ORDER_ID,
    {
      variables: {
        orderId: props.orderId,
        offset: DEFAULT_SCROLL_PAGE_LIMIT * page,
        limit: DEFAULT_SCROLL_PAGE_LIMIT,
      },
      fetchPolicy: 'network-only',
      onCompleted({ getOrderItemsByOrderIdForDetails }) {
        const initialTotalCount = getOrderItemsByOrderIdForDetails?.count ?? 0;
        if (!page || (!totalCount && totalCount !== initialTotalCount)) {
          setTotalCount(() => initialTotalCount);
        }

        const incomingList = getOrderItemsByOrderIdForDetails?.orderItems ?? [];

        setOrderItemsList((prevList) => {
          return [...prevList, ...incomingList].reduce(
            (
              acc: { uniqueIds: Record<string, boolean>; uniqueItems: any[] },
              item: any
            ) => {
              if (!acc.uniqueIds[item.id]) {
                acc.uniqueIds[item.id] = true;
                acc.uniqueItems.push(item);
              }
              return acc;
            },
            { uniqueIds: {}, uniqueItems: [] }
          ).uniqueItems;
        });
      },
    }
  );

  const canFetchMoreOrderItems = () => totalCount > orderItemsList.length;
  const isRetrieval = [
    OrderType.BULK_RETRIEVAL,
    OrderType.DROP_RETRIEVAL,
    OrderType.RETRIEVAL,
  ].some(
    (retrievalType: string) =>
      retrievalType === (data?.order?.orderType?.name ?? '')
  );
  const isProcurement = [
    OrderType.PROCUREMENT_TO_RECIPIENT,
    OrderType.PROCUREMENT_TO_DEPOT,
  ].some(
    (procureType: string) =>
      procureType === (data?.order?.orderType?.name ?? '')
  );

  const fetchNextPage = () => {
    if (!loadingOrderItemsData) setPage((prevPage) => prevPage + 1);
  };

  const scrollToAboveThreshold = () => {
    const scrollableElm = scrollableRef.current;
    if (scrollableElm) {
      const totalHeight = scrollableElm?.scrollHeight;
      const scrollToHeight = 0.79 * totalHeight;

      const tooLow = scrollableElm.scrollTop > scrollToHeight;

      if (tooLow) {
        scrollableElm.scrollTop = scrollToHeight;
      }
    }
  };

  React.useEffect(() => {
    if (!orderItemsList.length) return;
    setInboundOrderItemAssetDetails(() =>
      getInboundOrderItemAssetDetails(orderItemsList, isRetrieval)
    );
  }, [orderItemsList.length]);

  React.useEffect(() => {
    if (
      !orderItemsList.length ||
      typeof inboundOrderItemAssetDetails !== 'object'
    )
      return;

    setOrderItemTrackingChunks(() =>
      chunkOrderItemsByTrackingId(orderItemsList, inboundOrderItemAssetDetails)
    );
  }, [orderItemsList.length, inboundOrderItemAssetDetails]);

  React.useEffect(() => {
    if (!orderItemTrackingChunks.length) return;
    scrollToAboveThreshold();
  }, [orderItemTrackingChunks.length]);

  return (
    <Grid item container direction="column" sx={{ width: '100%' }}>
      <Stack sx={styles.OrderItemsInfoStackSx} direction="row">
        <Box
          id={ORDER_ITEMS_BOX_CONTAINER_ID}
          sx={styles.OrderItemsScrollBox}
          ref={scrollableRef}
        >
          <InfiniteScroll
            dataLength={orderItemTrackingChunks.length}
            next={fetchNextPage}
            hasMore={canFetchMoreOrderItems()}
            loader={<></>}
            scrollableTarget={ORDER_ITEMS_BOX_CONTAINER_ID}
            scrollThreshold={INFINITE_SCROLL_THRESHOLD}
          >
            <Grid
              container
              sx={styles.OrderItemsInfoGridSx}
              id={ORDER_ITEMS_GRID_CONTAINER_ID}
            >
              <Grid item container xs={12}>
                <Stack direction="row" sx={styles.OrderItemsTitleStackSx}>
                  <Typography className="tracking-number-title">
                    Tracking
                  </Typography>
                  <Typography className="shipment-status-title">
                    Shipment Status
                  </Typography>
                  <Typography className="order-item-title">Title</Typography>
                  <Typography className="asset-number-title">
                    Asset number
                  </Typography>
                </Stack>
              </Grid>
              {orderItemTrackingChunks.length
                ? orderItemTrackingChunks.map(
                    (
                      [
                        trackingIdAsKey /* trackingIdAsKey uses a fake, generated Id when tracking.id unavailable */,
                        orderItems,
                      ],
                      index: number
                    ) => (
                      <OrderItemPanelCard
                        key={trackingIdAsKey}
                        index={index}
                        isProcurement={isProcurement}
                        isRetrieval={isRetrieval}
                        orderItems={orderItems.map((item: any) =>
                          getFlattenedOrderItems(
                            item,
                            data?.order?.orderType?.name,
                            { isRetrieval, isProcurement }
                          )
                        )}
                        orderType={data?.order?.orderType?.name ?? undefined}
                      />
                    )
                  )
                : null}
            </Grid>
          </InfiniteScroll>
        </Box>
      </Stack>
    </Grid>
  );
};

export default OrderItemsPanel;
