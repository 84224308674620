import React from 'react';
import LaptopAsset from 'assets/LaptopAsset.png';
import MobileAsset from 'assets/MobileAsset.png';
import TabletAsset from 'assets/TabletAsset.png';
import { AssetImageProps } from './types';
import { RetrievalAssetTypes } from 'pages/Retrieval/constants';

const AssetImage = (props: AssetImageProps) => {
  switch (props.type) {
    case RetrievalAssetTypes.LAPTOP:
      return <img src={LaptopAsset} alt="laptop device" />;
    case RetrievalAssetTypes.MOBILE:
      return <img src={MobileAsset} alt="mobile device" />;
    case RetrievalAssetTypes.TABLET:
      return <img src={TabletAsset} alt="tablet device" />;
    default:
      return <></>;
  }
};

export default AssetImage;
