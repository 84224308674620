import React from 'react';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import { FieldInputWrapperProps } from './types';

const FieldInputWrapper = (props: FieldInputWrapperProps) => {
  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <FormControl
        sx={{
          width: '100%',
        }}
      >
        {props.children}
      </FormControl>
    </Grid>
  );
};

export default FieldInputWrapper;
