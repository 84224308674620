import React from 'react';
import DeviceForm from 'pages/Assets/components/DevicesDatagrid/components/DeviceForm';
import { EditAssetDetailsProps } from './types';

const EditAssetDetails = (props: EditAssetDetailsProps) => {
  return (
    <DeviceForm
      context="edit"
      device={props.device}
      deviceModalService={props.deviceModalService}
    />
  );
};

export default EditAssetDetails;
