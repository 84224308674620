/* eslint-disable sort-exports/sort-exports */
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as DepotViewStyles from 'pages/BuyAndHold/components/DepotView/styles';
import * as BuyAndHoldCTAStyles from './styles';
import {
  BUY_HOLD_CTA_ITEMS,
  BUY_HOLD_CTA_TITLE_ID,
  BUY_AND_HOLD_CTA_ITEM_ICON,
  BUY_AND_HOLD_CTA_ITEM_TITLE,
  BUY_AND_HOLD_CTA_ITEM_COPY,
} from './constants';
import { BuyAndHoldCTAItem } from './types';

const BuyAndHoldCTA = (props: any) => {
  const parentStyles = DepotViewStyles;
  const styles = BuyAndHoldCTAStyles;

  const getCTAItems = () =>
    BUY_HOLD_CTA_ITEMS.map((item: BuyAndHoldCTAItem, itemIndex: number) => {
      const [header, copy, Icon] = item;

      return (
        <ListItem key={header} sx={styles.getCTAItemSx(itemIndex)}>
          <Stack direction="row">
            <Stack className={BUY_AND_HOLD_CTA_ITEM_ICON}>
              <Icon />
            </Stack>
            <Stack pl="8px">
              <Typography className={BUY_AND_HOLD_CTA_ITEM_TITLE}>
                {header}
              </Typography>
              <Typography className={BUY_AND_HOLD_CTA_ITEM_COPY}>
                {copy}
              </Typography>
            </Stack>
          </Stack>
        </ListItem>
      );
    });

  return (
    <Stack sx={parentStyles.DepotViewBuyAndHoldCTASx}>
      <Box sx={styles.CTABoxSx}>
        <Typography id={BUY_HOLD_CTA_TITLE_ID}>Why do a Buy & Hold?</Typography>
        <List>{getCTAItems()}</List>
      </Box>
    </Stack>
  );
};

export default BuyAndHoldCTA;
