import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CollaboratorView from './components/CollaboratorView';
import OrderSummary from './components/OrderSummary';
import ProductCatalog from './components/ProductCatalog';
import { useProcurementService } from './hooks/useProcurementService';
import { ProcurementFlowStep } from './hooks/useProcurementService/constants';
import { topNavSlice, modalSlice } from 'store/slices';
import { GlobalModals } from 'store/constants';
import { GlobalState } from 'store/types';
import RetrievalConfirmationModal from 'components/RetrievalConfirmationModal';

const Procurement = () => {
  const dispatch = useDispatch();
  const workflow = useProcurementService('procurement');

  const currentModal = useSelector<GlobalState>(
    (state) => state.modal.currentModal
  );

  const showRetrievalConfirmationModal =
    currentModal === GlobalModals.RETRIEVAL_CONFIRMATION;

  const handleCloseRetrievalConfirmationModal = () => {
    dispatch(modalSlice.actions.reset());
  };

  React.useEffect(() => {
    const hasCollaborators = workflow.selectedCollaborators.length > 0;

    if (hasCollaborators) {
      dispatch(topNavSlice.actions.disableRetrievalCTA());
    } else {
      dispatch(topNavSlice.actions.enableRetrievalCTA());
    }
  }, [workflow.selectedCollaborators.length]);

  const content = () => {
    switch (workflow.flowStep) {
      case ProcurementFlowStep.ORDER_SUBMITTED:
      case ProcurementFlowStep.COLLABORATOR_VIEW:
        return <CollaboratorView workflow={workflow} />;
      case ProcurementFlowStep.PRODUCT_CATALOG:
        return <ProductCatalog workflow={workflow} />;
      case ProcurementFlowStep.ORDER_SUMMARY:
        return <OrderSummary workflow={workflow} />;
      default:
        throw new Error(
          `ProcurementFlowStep: ${workflow.flowStep} does not exist`
        );
    }
  };

  return (
    <>
      {content()}{' '}
      <RetrievalConfirmationModal
        open={showRetrievalConfirmationModal}
        handleClose={handleCloseRetrievalConfirmationModal}
      />
    </>
  );
};

export default Procurement;
