import React from 'react';
import * as Sentry from '@sentry/react';
import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { ProcurementFlowModals } from 'pages/Procurement/hooks/useProcurementService/constants';
import { Button } from 'components';
import GAIconButton from 'components/buttons/google_analytics/GAIconButton';
import DefaultProductImage from 'assets/allwhere.png';
import { ProductCatalogItemProps } from './types';
import { displayPrice, doesProductHaveMoreThanOneVariant } from './utils';
import {
  useProductDetailsControllerGetByProductId,
  useProductVariantByAttributesControllerGetByAttributes,
} from 'services/openapi/apiComponents';
import { setGlobalState } from 'state';
import { ProductImageStyles, ProductTitleSx } from './styles';
import { RETAIL_PRICE } from './constants';

const ProductCatalogItem = (props: ProductCatalogItemProps) => {
  const theme = useTheme();

  const { refetch: fetchProductVariant } =
    useProductVariantByAttributesControllerGetByAttributes(
      {
        pathParams: {
          productId: props.item.id,
        },
        //@ts-ignore
        queryParams: {
          // ignoring typescript issues because it expects an "attributes" object but service throws error
          //@ts-ignore
        },
      },
      {
        enabled: false, // initially setting this to false to make this a lazy query
        onError: (error) => {
          setGlobalState('alert', {
            message: <p>Something went wrong</p>,
            severity: 'error',
          });
        },
      }
    );

  const { refetch: fetchProductDetails } =
    useProductDetailsControllerGetByProductId(
      {
        pathParams: {
          productId: props.item.id,
        },
      },
      {
        enabled: false, // initially setting this to false to make this a lazy query
        onError: (error) => {
          setGlobalState('alert', {
            message: <p>Something went wrong</p>,
            severity: 'error',
          });
        },
      }
    );

  const onClickAddToCart = async () => {
    const productDetails = await fetchProductDetails();

    if (productDetails.data) {
      const shouldOpenPdpModal = doesProductHaveMoreThanOneVariant(
        productDetails.data
      );

      if (shouldOpenPdpModal) {
        onClickShowProductDetailsModal();
      } else {
        const productVariant = await fetchProductVariant();
        if (productVariant.data) {
          props.addProductVariantToCart({
            productVariantId: productVariant.data.id,
            productVariantPrice: productVariant.data.price || RETAIL_PRICE,
            productName: props.item.name,
            productCategoryId: props.item.productCategoryId,
            quantity: 1,
          });
        }
      }
    }
  };

  const onClickShowProductDetailsModal = () => {
    props.setProductCatalogItemForModal(props.item);
    props.setCurrentModal(ProcurementFlowModals.PRODUCT_DETAILS);
  };

  return (
    <Grid item xs={12} sm={6} md={6} lg={4} xl={4} padding={2}>
      <Box height="152px" width="152px">
        <img
          alt="product"
          src={props.item.imageUrl || DefaultProductImage}
          style={ProductImageStyles}
        />
      </Box>
      <Box minHeight="56px">
        <Tooltip title={props.item.name} placement="top">
          <Typography variant="subtitle1" align="left" sx={ProductTitleSx}>
            {props.item.name}
          </Typography>
        </Tooltip>
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={2}
      >
        <Typography
          alignContent="center"
          color={theme.palette.colors.boulderWN}
        >
          {displayPrice(props.item)}
        </Typography>
        <Button
          gaContext={{
            textCopy: 'Details',
            navigates_to: 'N/A',
            purpose: 'Opens Product Catalog Item Details',
          }}
          variant="outlined"
          color="secondary"
          sx={{ height: 31, minWidth: 78, width: 78 }}
          onClick={onClickShowProductDetailsModal}
        >
          <Typography variant="button">Details</Typography>
        </Button>
        {props.showCartButton ? (
          <GAIconButton
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Add to cart Icon',
              purpose: 'Adds Item To Cart',
            }}
            size="medium"
            sx={{ backgroundColor: 'colors.maizeYO' }}
            disableRipple
            onClick={onClickAddToCart}
          >
            <ShoppingCartOutlinedIcon
              htmlColor={theme.palette.colors.jungleG}
            />
          </GAIconButton>
        ) : null}
      </Stack>
    </Grid>
  );
};

export default ProductCatalogItem;
