import { ALLWHERE_EMAIL } from 'global-constants';

export const RETRIEVAL_COUNTRY_TOOLTIP_TEXT = `Not seeing the retrieval option you are looking for? Reach out to hello@allwhere.co and provide more information.`;

export const SLA_COPY_TEXT_PART_ONE =
  'Please note, retrievals for items outside the continental US may have unique processes and result in longer SLAs. For more details, please review our ';

export const SLA_COPY_TEXT_PART_TWO = `, or contact ${ALLWHERE_EMAIL}.`;

export const SLA_TIME_FRAMES_LINK =
  'https://faq.allwhere.co/en_us/allwhere-order-estimated-timeframes-HyYGzBTCo';
