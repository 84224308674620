import React from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { NavigationRoutes } from 'global-constants';
import { useAdminModePrompt } from 'hooks/useAdminModePrompt';
import FollowUpBannerImg from 'assets/followup-banner-calendar-img-compressed.png';
import OrderTypeFilter from './components/OrderTypeFilter';
import OrderStatusFilter from './components/OrderStatusFilter';
import OrdersDatagrid from './components/OrdersDatagrid';
import { useDownloadAllOrders } from './hooks/useDownloadAllOrders';
import { OrderPathToTitle, FOLLOW_UP_BANNER_FAQS_URL } from './constants';
import * as OrderStyles from './styles';
import GAIconButton from 'components/buttons/google_analytics/GAIconButton';
import OrdersCSVDownloadButton from './csv/OrdersCSVDownloadButton';
import OrdersComplexFilterBank from './filters/OrdersComplexFilterBank';
import OrdersComplexFilterDrawer from './filters/OrdersComplexFilterDrawer';
import { FeatureFlagNames } from 'enums';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import OrderLookAheadSearch from './components/OrderLookAheadSearch';

const Orders = () => {
  const styles = OrderStyles;
  const { search: queryParam } = useLocation();
  const [searchParams] = useSearchParams();

  const viewParam = searchParams?.get('view') ?? 'ALL';
  const onFollowUpView = queryParam.includes(NavigationRoutes.VIEW_FOLLOW_UP);

  const {
    OnFlagTrueWrapper: ShowIfComplexFilterEnabled,
    OnFlagFalseWrapper: ShowIfComplexFilterDisabled,
  } = useFeatureFlagService(FeatureFlagNames.COMPLEX_CLIENT_ORDERS_FILTERING);

  const downloadAllOrders = useDownloadAllOrders();

  const [showFollowUpBanner, setShowFollowUpBanner] =
    React.useState<boolean>(false);

  const closeFollowUpBanner = () => setShowFollowUpBanner(false);

  const isFollowUpBannerShown = (): boolean =>
    [showFollowUpBanner, onFollowUpView].every(Boolean);

  React.useEffect(() => {
    setShowFollowUpBanner(true);
  }, []);

  React.useEffect(() => {
    setShowFollowUpBanner(true);
  }, [queryParam]);

  useAdminModePrompt();

  return (
    <Container sx={styles.ContentDivSx}>
      <Stack direction="column" sx={styles.MainStackSx}>
        {isFollowUpBannerShown() ? (
          <Box sx={styles.OrderFollowUpBannerBoxSx}>
            <GAIconButton
              gaContext={{
                navigates_to: 'N/A',
                textCopy: 'Close icon',
                purpose: 'Closes follow up orders banner',
              }}
              aria-label="close-follow-up-banner-icon-button"
              onClick={closeFollowUpBanner}
              sx={styles.BannerCloseButtonSx}
            >
              <CloseIcon />
            </GAIconButton>
            <Stack direction="column" sx={styles.BannerInnerStackSx}>
              <Stack className="image-stack">
                <img
                  src={FollowUpBannerImg}
                  alt="follow-up-banner-img"
                  style={styles.BannerImageStyle}
                />
              </Stack>
              <Stack className="copy-stack">
                <Typography>
                  Need more information about allwhere's expiration policy?
                </Typography>
                <Typography>
                  Visit our{' '}
                  <Link
                    href={FOLLOW_UP_BANNER_FAQS_URL}
                    rel="noopener"
                    target="_blank"
                  >
                    FAQ section
                  </Link>{' '}
                  to find all relevant information.
                </Typography>
              </Stack>
            </Stack>
          </Box>
        ) : null}
        <Typography variant="h4" className="order-view-title">
          {OrderPathToTitle[viewParam as keyof typeof OrderPathToTitle]}
        </Typography>
        <Stack direction="row" gap="8px" justifyContent="space-between">
          <OrderLookAheadSearch />
          <Stack direction="row" gap="8px" justifyContent="flex-end">
            <OrdersCSVDownloadButton />
            <ShowIfComplexFilterEnabled>
              <OrdersComplexFilterDrawer />
            </ShowIfComplexFilterEnabled>
          </Stack>
        </Stack>
        <ShowIfComplexFilterEnabled>
          <Stack sx={{ mt: '14px' }}>
            <OrdersComplexFilterBank />
          </Stack>
        </ShowIfComplexFilterEnabled>
        <ShowIfComplexFilterDisabled>
          <Stack direction="row" gap={2} sx={{ mt: '10px' }}>
            <OrderTypeFilter />
            <OrderStatusFilter />
          </Stack>
        </ShowIfComplexFilterDisabled>
        <Box sx={styles.getDataGridBoxSx(isFollowUpBannerShown())}>
          <OrdersDatagrid
            followUpBannerShown={isFollowUpBannerShown()}
            onFollowUpView={onFollowUpView}
            showLoadingIndicator={downloadAllOrders.isFetchingAllOrdersLoading}
          />
        </Box>
      </Stack>
    </Container>
  );
};

export default Orders;
