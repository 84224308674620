/* eslint-disable sort-exports/sort-exports */
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Button } from 'components';
import NewModal from 'components/NewModal';
import { ArchiveEmployeeCopy } from './constants';
import { ArchiveEmployeeModalProps } from './types';
import {
  ArchiveModalMainStackSx,
  ArchiveModalTitleSx,
  ArchiveModalButtonBaseSx,
  ArchiveModalPaperPropsSx,
} from './styles';

const ArchiveEmployeeModal = (props: ArchiveEmployeeModalProps) => {
  if (!props.open) return null;

  return (
    <NewModal
      open={props.open}
      handleClose={props.onCancel}
      paperPropsSx={ArchiveModalPaperPropsSx}
    >
      <Stack
        direction="column"
        spacing={0}
        justifyContent="space-between"
        // @ts-ignore
        sx={ArchiveModalMainStackSx}
      >
        <Stack direction="column" spacing={0}>
          <Typography variant="h5" sx={ArchiveModalTitleSx}>
            {ArchiveEmployeeCopy.TITLE}
          </Typography>
          <Typography>{ArchiveEmployeeCopy.SUBTITLE}</Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          justifyContent="flex-end"
          sx={{ width: '100%' }}
        >
          <Button
            gaContext={{
              navigates_to: 'N/A',
              purpose: 'Cancels Employee Archive',
              textCopy: ArchiveEmployeeCopy.CANCEL_BUTTON_COPY,
            }}
            id="cancel-archive-employee-button"
            color="secondary"
            onClick={props.onCancel}
            sx={ArchiveModalButtonBaseSx}
            transparentBackgroundOnHover
          >
            <Typography variant="button">
              {ArchiveEmployeeCopy.CANCEL_BUTTON_COPY}
            </Typography>
          </Button>
          <Button
            gaContext={{
              navigates_to: 'N/A',
              purpose: 'Archives An Employee',
              textCopy: ArchiveEmployeeCopy.SUBMIT_BUTTON_COPY,
            }}
            id="confirm-cancel-archive-employee-button"
            sx={ArchiveModalButtonBaseSx}
            onClick={props.onSubmit}
            variant="contained"
          >
            <Typography variant="button">
              {ArchiveEmployeeCopy.SUBMIT_BUTTON_COPY}
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </NewModal>
  );
};

export default ArchiveEmployeeModal;
