import { OrderType } from 'global-constants';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

export const ORDER_ITEMS_CONTAINER_TEST_ID = 'order-items-container-testing-id';
export const ORDER_ITEMS_BOX_CONTAINER_ID =
  'order-items-box-infinite-scroll-container-id';
export const ORDER_ITEMS_GRID_CONTAINER_ID =
  'order-items-inner-grid-container-id';

export const ORDER_ITEM_ICON_BY_ORDER_TYPE = {
  [OrderType.PROCUREMENT_TO_RECIPIENT]: ShoppingCartOutlinedIcon,
  [OrderType.PROCUREMENT_TO_DEPOT]: ShoppingCartOutlinedIcon,
};

export const SKIP_SHOWING_LINK_FAKE_TRACKING_ID =
  'SKIP_SHOWING_LINK_FAKE_TRACKING_ID';
export const SKIP_SHOWING_DETAILS_LINK_FAKE_ASSET_ID =
  'SKIP_SHOWING_DETAILS_LINK_FAKE_ASSET_ID';
