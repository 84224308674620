export enum UploadDomain {
  ASSET = 'asset',
  COLLABORATOR = 'collaborator',
  ORDERS = 'order',
}

export enum UploadStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}
