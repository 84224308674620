import { SxProps } from '@mui/material';

export const ModalPaperPropsSx: SxProps = {
  maxWidth: '545px',
  height: '274px',
};

export const ModalTitleSx: SxProps = {
  // TODO: Convert this to global h4 styling
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
};

export const BaseButtonTextSx: SxProps = {
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.4px',
  width: 'fit-content',
};

export const CancelTextSx: SxProps = {
  color: 'colors.black',
  textAlign: 'right',
  ...BaseButtonTextSx,
};

export const DeleteTextSx: SxProps = {
  colors: 'colors.burntSiennaR',
  textAlign: 'right',
  ...BaseButtonTextSx,
};
