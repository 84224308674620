import { gql } from '@apollo/client';

export const CREATE_RESELL_DEVICE_ORDER = gql`
  mutation createResellOrder($purchaserId: ID!, $assetId: ID!) {
    createResellOrder(
      resellOrderCreateInput: { purchaserId: $purchaserId, assetId: $assetId }
    ) {
      id
    }
  }
`;
