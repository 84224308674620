export enum OrderDetailsTab {
  ORDER_DETAILS = 'Order details',
  ORDER_ITEMS = 'Order items',
}

export enum AsyncMatchStatus {
  PENDING = 'PENDING',
  MATCH = 'MATCH',
  NO_MATCH = 'NO_MATCH',
}
