import {
  useGetConfig,
  useSetUploadCollaborator,
  useSetUploadsViewed,
} from './hooks';
import UploadPopover from 'components/uploads/UploadPopover';
import { UploadPopoverProps } from 'components/uploads/UploadPopover/types';
import React from 'react';

const AssetUploadPopover = () => {
  const config: UploadPopoverProps = useGetConfig();
  const { viewableUploads, open } = config;
  const setUploadCollaborator = useSetUploadCollaborator();
  const setUploadsViewed = useSetUploadsViewed();

  React.useEffect(() => {
    if (open) {
      viewableUploads.forEach((upload) => {
        if (upload.collaboratorId && !upload.collaborator) {
          setUploadCollaborator(upload);
        }
      });
      setUploadsViewed();
    }
  }, [viewableUploads]);

  return <UploadPopover {...config} />;
};

export default AssetUploadPopover;
