import { SxProps } from '@mui/material';
import { DetailViewContentContainerSx } from '../../styles';

export const OrderNotificationsContainerSx: SxProps = {
  ...DetailViewContentContainerSx,
  mr: '-12px',
};

export const NotificationTypeStackSx: SxProps = {
  marginTop: '1rem',
  width: '51%',
};

export const SendButtonSx: SxProps = {
  borderRadius: '0px 4px 4px 0px',
  p: '8px 22px',
};

export const SendButtonTextSx: SxProps = {
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.4px',
  width: 'fit-content',
};
