import React from 'react';
import { SVGProps } from './types';

export const BuyAndHoldSVGIcon = (props: SVGProps) => {
  const originalWidth = 20;
  const originalHeight = 20;
  const width = props?.width ?? originalWidth;
  const height = props?.height ?? originalHeight;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3059_39344"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3059_39344)">
        <path
          d="M2.66675 19.1654C2.11446 19.1654 1.66675 18.7176 1.66675 18.1654V7.44988C1.66675 7.13134 1.81851 6.83185 2.07538 6.64348L9.40872 1.2657C9.76071 1.00757 10.2395 1.00757 10.5914 1.2657L17.9248 6.64347C18.1817 6.83185 18.3334 7.13134 18.3334 7.44988V18.1654C18.3334 18.7176 17.8857 19.1654 17.3334 19.1654H2.66675ZM3.75008 16.1283C3.75008 16.6806 4.1978 17.1283 4.75008 17.1283H15.2501C15.8024 17.1283 16.2501 16.6806 16.2501 16.1283V8.4684C16.2501 8.14986 16.0983 7.85037 15.8414 7.66199L10.5914 3.81199C10.2395 3.55387 9.76071 3.55387 9.40872 3.81199L4.15872 7.66199C3.90184 7.85037 3.75008 8.14985 3.75008 8.4684V16.1283Z"
          fill="white"
        />
        <path
          d="M10.3334 10.832V11.332H10.8334H12.8334V11.9987H10.8334H10.3334V12.4987V14.4987H9.66675V12.4987V11.9987H9.16675H7.16675V11.332H9.16675H9.66675V10.832V8.83203H10.3334V10.832Z"
          fill="white"
          stroke="white"
        />
      </g>
    </svg>
  );
};
