import { gql } from '@apollo/client';
import { getAssetFieldAutocompleteQuery } from './utils';

export const SEARCH_DEPOTS_FOR_LOOKUP = gql`
  query searchDepots {
    searchDepots(input: { limit: 100 }) {
      depots {
        id
        name
      }
    }
  }
`;

export const SEARCH_ORGANIZATIONS_FOR_LOOKUP = gql`
  query getOrgInfo($name: String, $limit: Int = 10) {
    getOrganizationAutocompleteSuggestions(
      suggestionInput: { name: $name, limit: $limit }
    ) {
      entity
      field
      matches
      matchIds
    }
  }
`;

export const GET_ASSET_NO_SUGGESTION = getAssetFieldAutocompleteQuery({
  field: 'assetNumber',
});

export const GET_ASSET_MAKE_SUGGESTION = getAssetFieldAutocompleteQuery({
  field: 'make',
});

export const GET_ASSET_MODEL_SUGGESTION = getAssetFieldAutocompleteQuery({
  field: 'model',
});

export const GET_ASSET_CONDITION_SUGGESTION = getAssetFieldAutocompleteQuery({
  field: 'cosmeticCondition',
});

export const GET_ASSET_KEYBOARD_SUGGESTION = getAssetFieldAutocompleteQuery({
  field: 'keyboard',
});

export const GET_ASSET_SERIAL_NO_SUGGESTION = getAssetFieldAutocompleteQuery({
  field: 'serialNumber',
});

export const GET_ASSET_DEVICE_ISSUE_SUGGESTION = getAssetFieldAutocompleteQuery(
  {
    field: 'deviceAssessment',
  }
);

export const GET_ASSET_COLOR_SUGGESTION = getAssetFieldAutocompleteQuery({
  field: 'color',
});

export const GET_ASSET_DISPLAY_SIZE_SUGGESTION = getAssetFieldAutocompleteQuery(
  {
    field: 'displaySize',
  }
);
