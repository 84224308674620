import { SxProps } from '@mui/material';

export const BillingButtonSx: SxProps = {
  borderColor: '#ffffff',
  color: '#ffffff',
  height: '40px',
  mx: '10px !important',
  mt: '32px !important',
  '&:hover': {
    borderColor: 'colors.jellyBeanBlue',
    color: '#ffffff',
    backgroundColor: 'colors.limedSpruce',
  },
};
