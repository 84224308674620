import React from 'react';
import { SVGProps } from './types';

const AllocateDeviceSVGIcon = (props: SVGProps) => {
  const originalSize = 18;
  const strokeFill = props?.stroke ?? 'white';
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_4382_878" fill="white">
        <path d="M15.7048 17.9971H2.29507C1.59418 17.9971 0.941422 17.6968 0.504126 17.1744C0.0630742 16.6467 -0.0990114 15.9669 0.0593183 15.3073C1.01934 11.3131 4.69609 8.52344 9.00004 8.52344C13.304 8.52344 16.9807 11.3129 17.9408 15.3073C18.0989 15.9667 17.937 16.6467 17.4959 17.1744C17.0586 17.6976 16.4059 17.9971 15.7048 17.9971ZM8.99995 9.98107C5.41366 9.98107 2.34923 12.3051 1.54906 15.6331C1.49548 15.8568 1.54974 16.0879 1.69886 16.2657C1.78451 16.3685 1.97559 16.5396 2.29431 16.5396H15.7049C16.0237 16.5396 16.2147 16.3683 16.3004 16.2657C16.4495 16.0871 16.5045 15.8569 16.4502 15.6324C15.6499 12.305 12.5871 9.98107 8.99995 9.98107Z" />
      </mask>
      <path
        className="custom-stroke"
        d="M15.7048 17.9971H2.29507C1.59418 17.9971 0.941422 17.6968 0.504126 17.1744C0.0630742 16.6467 -0.0990114 15.9669 0.0593183 15.3073C1.01934 11.3131 4.69609 8.52344 9.00004 8.52344C13.304 8.52344 16.9807 11.3129 17.9408 15.3073C18.0989 15.9667 17.937 16.6467 17.4959 17.1744C17.0586 17.6976 16.4059 17.9971 15.7048 17.9971ZM8.99995 9.98107C5.41366 9.98107 2.34923 12.3051 1.54906 15.6331C1.49548 15.8568 1.54974 16.0879 1.69886 16.2657C1.78451 16.3685 1.97559 16.5396 2.29431 16.5396H15.7049C16.0237 16.5396 16.2147 16.3683 16.3004 16.2657C16.4495 16.0871 16.5045 15.8569 16.4502 15.6324C15.6499 12.305 12.5871 9.98107 8.99995 9.98107Z"
        stroke={strokeFill}
        strokeWidth="2.66667"
        mask="url(#path-1-inside-1_4382_878)"
      />
      <circle
        className="custom-stroke"
        cx="9.00041"
        cy="3.78947"
        r="3.03947"
        stroke={strokeFill}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default AllocateDeviceSVGIcon;
