import { SxProps } from '@mui/material';
import { DRAWER_WIDTH, HEADER_HEIGHT } from 'global-styles';

export const ContentDivSx: SxProps = {
  margin: 'auto',
  mt: HEADER_HEIGHT,
  position: 'relative',
  '@media (max-width: 1199px)': {
    left: '0',
  },
  '@media (min-width: 1200px)': {
    left: `${DRAWER_WIDTH - DRAWER_WIDTH / 2}px`,
    width: '80%',
  },
  '@media (min-width: 1536px)': {
    left: `${DRAWER_WIDTH - DRAWER_WIDTH / 2}px`,
    maxWidth: '100%',
    width: '85%',
  },
  '& .merchandise-view-title': {
    fontSize: '36px',
    fontWeight: 500,
    lineHeight: '41.04px',
  },

  '& .merchandise-view-subtitle': {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
  },
};

export const MainStackSx: SxProps = {
  marginTop: '1.35rem',
};

export const DataGridBoxSx: SxProps = {
  height: 'calc(100vh - 200px)',
  marginTop: '0.75rem',
};
