import React from 'react';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';

import { UnlockDeviceFormProps, DeviceStatusKey } from './types';
import { DEVICE_STATUS_DISPLAY_NAME } from './constants';
import {
  ContainerSx,
  CodeLabelSx,
  CodeTextFieldSx,
  NotesContainerSx,
  NotesLabelSx,
  NotesTextFieldSx,
} from './styles';

const UnlockDeviceForm = (props: UnlockDeviceFormProps) => {
  const { device, loading, form } = props;
  const { register } = form;
  const status = device.status as unknown;
  const codeDisplayName = DEVICE_STATUS_DISPLAY_NAME[status as DeviceStatusKey];
  return (
    <Stack sx={ContainerSx}>
      <Stack>
        <Typography sx={CodeLabelSx}>Enter {codeDisplayName} Code</Typography>
        <TextField
          sx={CodeTextFieldSx}
          placeholder={`${codeDisplayName} code*`}
          label={`${codeDisplayName} code*`}
          variant="outlined"
          disabled={loading}
          {...register('code')}
        />
      </Stack>
      <Stack sx={NotesContainerSx}>
        <Typography sx={NotesLabelSx}>Order Note</Typography>
        <TextField
          sx={NotesTextFieldSx}
          label="Notes"
          placeholder="Notes"
          disabled={loading}
          variant="outlined"
          {...register('notes')}
        />
      </Stack>
    </Stack>
  );
};

export default UnlockDeviceForm;
