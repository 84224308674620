import { RootState } from 'store';

export const selectOrdersCSVLoadingState = (state: RootState) =>
  state.orders.csv.loadingState;

export const selectOrdersCSVURL = (state: RootState) => state.orders.csv.url;

export const selectOrdersCSVData = (state: RootState) => state.orders.csv.data;

export const selectOrdersCSVErrorMessage = (state: RootState) =>
  state.orders.csv.errorMessage;
