import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { notificationSlice } from 'store/slices';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';
import { NewModal } from 'components';
import { Asset } from 'types';
import { EditDeviceTab } from './enum';
import {
  CANCEL_BUTTON_LABEL,
  EDIT_DEVICE_MODAL_TITLE,
  SAVE_BUTTON_LABEL,
} from './constants';
import { EditDeviceModalProps } from './types';
import { ModalPaperSx, TabsSx } from './styles';
import QuickAssetDetails from './components/QuickAssetDetails';
import SaveConfirmationModal from 'components/NewModal/SaveConfirmationModal';
import UnsavedChangesModal from 'components/NewModal/UnsavedChangesModal';
import InventoryReceipts from './components/InventoryReceipts';
import { useDeviceModal } from './hooks/useDeviceModal';
import EditAssetDetails from './components/EditAssetDetails';
import { useInventoryReceiptForm } from './hooks/useInventoryReceiptForm';
import { InventoryReceiptActionModals } from './components/InventoryReceipts/enums';
import AssetStatusHistory from './components/AssetStatusHistory';

const EditDeviceModal = (props: EditDeviceModalProps) => {
  if (!props.device || !props.open) return <></>;

  const { handleSubmit } = useForm();

  const dispatch = useDispatch();

  const [showUnsavedChangesModal, setShowUnsavedChangesModal] =
    React.useState(false);

  const [showSaveConfirmationModal, setShowSaveConfirmationModal] =
    React.useState(false);

  const [editDeviceTab, setEditDeviceTab] = React.useState<EditDeviceTab>(
    EditDeviceTab.ASSET_DETAILS
  );

  const handleCancel = () => {
    switch (editDeviceTab) {
      case EditDeviceTab.ASSET_DETAILS:
        if (deviceModalService.isDirty()) {
          setShowUnsavedChangesModal(true);
        } else {
          props.handleClose();
        }
        break;
      case EditDeviceTab.INVENTORY_RECEIPTS:
        if (inventoryReceiptFormService.context === 'none') {
          props.handleClose();
        } else if (
          inventoryReceiptFormService.context === 'edit' &&
          inventoryReceiptFormService.isEditFormDirty()
        ) {
          setShowUnsavedChangesModal(true);
        } else {
          inventoryReceiptFormService.setInventoryReceiptActionModal(
            InventoryReceiptActionModals.NONE
          );
        }
        break;
      default:
        props.handleClose();
        break;
    }
  };

  const handleSubmitOnClick = () => {
    setShowSaveConfirmationModal(true);
  };

  const handleOnUnSavedChangesModalClose = () => {
    setShowUnsavedChangesModal(false);
  };

  const handleOnSaveConfirmationModalClose = () => {
    setShowSaveConfirmationModal(false);
  };

  const handleOnDiscardUnsavedChanges = () => {
    setShowUnsavedChangesModal(false);
    switch (editDeviceTab) {
      case EditDeviceTab.ASSET_DETAILS:
        setEditDeviceTab(EditDeviceTab.ASSET_DETAILS);
        props.handleClose();
        break;
      case EditDeviceTab.INVENTORY_RECEIPTS:
        inventoryReceiptFormService.setInventoryReceiptActionModal(
          InventoryReceiptActionModals.NONE
        );
        break;
      default:
        break;
    }
  };

  const handleOnChange = (
    _event: React.SyntheticEvent,
    value: EditDeviceTab
  ) => {
    setEditDeviceTab(value);
  };

  const toHideCTAs = [
    EditDeviceTab.INVENTORY_RECEIPTS,
    EditDeviceTab.ASSET_STATUS_HISTORY,
  ].some((tab: string) => tab === editDeviceTab);
  const deviceModalService = useDeviceModal(props.device);
  const inventoryReceiptFormService = useInventoryReceiptForm();

  const onSubmit = async () => {
    switch (editDeviceTab) {
      case EditDeviceTab.ASSET_DETAILS:
        await deviceModalService.updateAssetDetails(onCompleted);
        break;
      case EditDeviceTab.INVENTORY_RECEIPTS:
        inventoryReceiptFormService.invokeMutation(
          props.device?.id || '',
          onCompleted
        );
        break;
      default:
        break;
    }
  };

  const handleSaveAndLeave = async () => {
    await onSubmit();
    props.handleClose();
  };

  const onCompleted = (): any => {
    dispatch(
      notificationSlice.actions.setNotice({
        showNotice: true,
        noticeContent: NotificationMessages.CHANGES_SAVED_SUCCESS,
      })
    );
    setShowUnsavedChangesModal(false);
    setShowSaveConfirmationModal(false);
    if (props.refetchSearchAssets) props.refetchSearchAssets();
    if (props.enableActionsByDevice && props.device) {
      props.enableActionsByDevice(props.device);
    }
  };

  const loading =
    deviceModalService.updateAssetDetailsLoading ||
    inventoryReceiptFormService.mutationIsLoading;

  return (
    <>
      <NewModal
        form
        hideCTAs={toHideCTAs}
        open={props.open}
        title={EDIT_DEVICE_MODAL_TITLE}
        onSubmit={handleSubmit(handleSubmitOnClick)}
        submitButtonCopy={SAVE_BUTTON_LABEL}
        cancelButtonCopy={CANCEL_BUTTON_LABEL}
        disableSaveButton={false}
        loading={loading}
        handleClose={handleCancel}
        paperPropsSx={ModalPaperSx}
      >
        <QuickAssetDetails device={props.device} />
        <TabContext value={editDeviceTab}>
          <TabList sx={TabsSx} onChange={handleOnChange}>
            <Tab label="ASSET DETAILS" value={EditDeviceTab.ASSET_DETAILS} />
            <Tab
              label="INVENTORY RECEIPTS"
              value={EditDeviceTab.INVENTORY_RECEIPTS}
            />
            <Tab
              label="ASSET STATUS HISTORY"
              value={EditDeviceTab.ASSET_STATUS_HISTORY}
            />
          </TabList>
          <TabPanel value={EditDeviceTab.ASSET_DETAILS}>
            <EditAssetDetails
              device={props.device as Asset}
              deviceModalService={deviceModalService}
            />
          </TabPanel>
          <TabPanel value={EditDeviceTab.INVENTORY_RECEIPTS}>
            <InventoryReceipts
              device={props.device as Asset}
              inventoryReceiptFormService={inventoryReceiptFormService}
              onSubmit={handleSubmitOnClick}
              handleCancel={handleCancel}
            />
          </TabPanel>
          <TabPanel value={EditDeviceTab.ASSET_STATUS_HISTORY}>
            <AssetStatusHistory asset={props.device as Asset} />
          </TabPanel>
        </TabContext>
      </NewModal>
      <UnsavedChangesModal
        handleSaveAndLeave={handleSaveAndLeave}
        handleClose={handleOnUnSavedChangesModalClose}
        handleDiscard={handleOnDiscardUnsavedChanges}
        isOpen={showUnsavedChangesModal}
      />
      <SaveConfirmationModal
        isOpen={showSaveConfirmationModal}
        handleClose={handleOnSaveConfirmationModalClose}
        handleSave={onSubmit}
      />
    </>
  );
};

export default EditDeviceModal;
