/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import { OrderStatusTransition } from 'pages/OrderManagement/components/DetailedOrderView/types';
import { StatusHistoryStep } from 'types';
import { convertStatusToStep } from './utils';

import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import { DetailViewContentContainerSx } from 'pages/OrderManagement/components/DetailedOrderView/styles';
import OrderStatusStepper from './components/OrderStatusStepper';
import { GET_ALL_ORDER_STATUS_TRANSITIONS_BY_ORDER } from './queries';
import { OrderStatusProps } from './types';
import * as OrderStatusHistoryStyles from './styles';

const OrderStatusHistory = (props: OrderStatusProps) => {
  if (!props.order) return null;
  const styles = OrderStatusHistoryStyles;

  const [statusHistorySteps, setStatusHistorySteps] = React.useState<
    StatusHistoryStep[]
  >([]);

  const [
    fetchAllOrderStatusTransitionsByOrder,
    { loading: fetchingAllOrderStatusTransitions, data: fetchedOrderHistory },
  ] = useSafeLazyQuery(GET_ALL_ORDER_STATUS_TRANSITIONS_BY_ORDER, {
    variables: {
      orderId: props.order.id,
    },
    skip: !props.order?.id,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted: ({ getAllOrderStatusTransitionsByOrderId = [] }) => {
      const convertedOrderHistory: StatusHistoryStep[] =
        getAllOrderStatusTransitionsByOrderId.reduce(
          (
            acc: StatusHistoryStep[],
            orderTransition: OrderStatusTransition,
            index: number,
            array: OrderStatusTransition[]
          ) => {
            const options = {
              isActive: index === 0,
              orderCreatedAt: props.order?.createdAt ?? '',
              lastStatus: index === array.length - 1,
            };
            const step = convertStatusToStep(orderTransition, options);
            acc = [...acc, ...step];
            return acc;
          },
          [] as StatusHistoryStep[]
        );

      setStatusHistorySteps(() => convertedOrderHistory);
    },
  });

  const fetchOrderStatusHistory = () => {
    fetchAllOrderStatusTransitionsByOrder();
  };

  React.useEffect(() => {
    if (!props.order?.id) return;
    fetchOrderStatusHistory();
  }, [props.order?.id]);

  const stepperProps = {
    stepperTitle: 'Order status history',
    steps: statusHistorySteps,
  };

  const canRenderOrderStatusHistory = () =>
    statusHistorySteps.length > 0 && !fetchingAllOrderStatusTransitions;

  return (
    <Container sx={DetailViewContentContainerSx}>
      {fetchingAllOrderStatusTransitions ? (
        <div style={styles.ProgressDivCSS}>
          <CircularProgress sx={styles.CircularProgressSx} size={85} />
        </div>
      ) : null}
      {canRenderOrderStatusHistory() ? (
        <>
          <OrderStatusStepper {...stepperProps} />
        </>
      ) : null}
    </Container>
  );
};

export default OrderStatusHistory;
