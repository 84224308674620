/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import { GET_ORDER_TYPE_OPTIONS } from './queries';
import { ORDER_TYPE_FILTER_SETTINGS } from './constants';
import * as OrderTypeFilterStyles from './styles';
import {
  clearSearchFilterByKey,
  setSearchFilterByKey,
} from 'store/slices/orders/search';
import { selectOrderSearchByKey } from 'store/slices/orders/search/selectors';

const OrderTypeFilter = () => {
  const styles = OrderTypeFilterStyles;
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const orderTypeParam = searchParams?.get('orderType') ?? '';

  const SEARCH_KEY = 'orderTypeFilter';
  const selectedOrderTypeFilter = useSelector(
    selectOrderSearchByKey(SEARCH_KEY)
  );
  const [orderTypesValueLookup, setOrderTypesValueLookup] = React.useState<
    Record<string, any> | undefined
  >();
  const [orderTypeOptions, setOrderTypeOptions] = React.useState<string[]>([]);

  const [fetchOrderTypes] = useSafeLazyQuery(GET_ORDER_TYPE_OPTIONS, {
    onCompleted: ({ getAllOrderTypes = [] }) => {
      if (!getAllOrderTypes.length) return;
      const orderTypesNameIdLookup = getAllOrderTypes.reduce(
        (acc: Record<string, any>, orderType: any) => {
          const { name } = orderType;
          const nameValue = name
            .split(' ')
            .map((str: string) => str.toUpperCase())
            .join('_');
          acc[name] = nameValue;
          return acc;
        },
        {} as Record<string, any>
      );

      setOrderTypesValueLookup(orderTypesNameIdLookup);
      setOrderTypeOptions(() => ['', ...Object.keys(orderTypesNameIdLookup)]);
    },
  });

  const handleClose = () => {
    dispatch(clearSearchFilterByKey(SEARCH_KEY));
  };

  const handleOrderTypeChange = (event: SelectChangeEvent) => {
    const { value: newChosenOrderType } = event.target;

    if (!newChosenOrderType || newChosenOrderType === selectedOrderTypeFilter)
      return;

    dispatch(
      setSearchFilterByKey({ key: SEARCH_KEY, value: newChosenOrderType })
    );
  };

  React.useEffect(() => {
    fetchOrderTypes();
  }, []);

  React.useEffect(() => {
    if (orderTypeParam) {
      dispatch(
        setSearchFilterByKey({ key: SEARCH_KEY, value: orderTypeParam })
      );
    }
  }, []);

  if (!orderTypesValueLookup) return null;

  return (
    <Stack sx={styles.FilterStackSx}>
      {selectedOrderTypeFilter ? (
        <Tooltip title="Clear" arrow>
          <IconButton
            size="small"
            aria-label="clear order type filter"
            onClick={handleClose}
            sx={styles.FilterClearButtonSx}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      ) : null}

      <FormControl
        sx={styles.getFilterFormControlSx(ORDER_TYPE_FILTER_SETTINGS.labelId)}
      >
        <InputLabel id={ORDER_TYPE_FILTER_SETTINGS.labelId}>
          {ORDER_TYPE_FILTER_SETTINGS.label}
        </InputLabel>
        <Select
          value={selectedOrderTypeFilter}
          onChange={handleOrderTypeChange}
          sx={styles.FilterSelectSx}
          placeholder={ORDER_TYPE_FILTER_SETTINGS.placeholder}
          label={ORDER_TYPE_FILTER_SETTINGS.label}
          labelId={ORDER_TYPE_FILTER_SETTINGS.labelId}
          id={ORDER_TYPE_FILTER_SETTINGS.inputId}
        >
          {orderTypeOptions.map((assetTypeOption: string) => (
            <MenuItem
              key={assetTypeOption}
              value={
                orderTypesValueLookup[
                  assetTypeOption as keyof typeof orderTypesValueLookup
                ]
              }
            >
              {assetTypeOption}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default OrderTypeFilter;
