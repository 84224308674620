import set from 'lodash/set';
import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/immer';

import { AssetColumnValue, AssetsState } from './types';
import { ColumnValue } from '../../shared/types';
import { INITIAL_STATE } from './constants';

export const setColumnsOpenReducer = (
  state: WritableDraft<AssetsState>,
  action: PayloadAction<boolean>
) => {
  state.popover.open = action.payload;
};

export const setSearchInputReducer = (
  state: WritableDraft<AssetsState>,
  action: PayloadAction<string>
) => {
  state.popover.searchInput = action.payload;
};

export const setColumnsChangedReducer = (
  state: WritableDraft<AssetsState>,
  action: PayloadAction<boolean>
) => {
  state.popover.changed = action.payload;
};

export const setColumnsReducer = (
  state: WritableDraft<AssetsState>,
  action: PayloadAction<ColumnValue>
) => {
  const column = action.payload;
  state.columns.forEach((c) => {
    if (c.id === column.id) {
      c.active = column.active;
    }
  });
};

export const setInitializedReducer = (
  state: WritableDraft<AssetsState>,
  action: PayloadAction<boolean>
) => {
  const initialized = action.payload;
  state.initialized = initialized;
};

export const setAllColumnsReducer = (
  state: WritableDraft<AssetsState>,
  action: PayloadAction<AssetColumnValue[]>
) => {
  const columns = action.payload;
  columns.forEach((column, index) => {
    state.columns[index] = column;
  });
};

export const swapColumnsReducer = (
  state: WritableDraft<AssetsState>,
  action: PayloadAction<{ sourceIndex: number; destinationIndex: number }>
) => {
  const { sourceIndex, destinationIndex } = action.payload;
  const temp = state.columns[sourceIndex];
  state.columns.splice(sourceIndex, 1);
  state.columns.splice(destinationIndex, 0, temp);
};

export const setAllColumnsActiveReducer = (
  state: WritableDraft<AssetsState>,
  action: PayloadAction<boolean>
) => {
  const active = action.payload;
  state.columns.forEach((c) => {
    if (c.key === 'notes') return;
    c.active = active;
  });
};

export const resetStateReducer = (
  state: WritableDraft<AssetsState>,
  action: PayloadAction<{ path: string; value: any }>
) => {
  const { path, value } = action.payload;
  const s = INITIAL_STATE();
  set(s, path, value);
  return s;
};
