import React from 'react';
import Typography from '@mui/material/Typography';

import {
  DisclaimerContentBodyTextSx,
  DisclaimerContentHeaderTextSx,
} from '../../styles';

const ProcurementCopy = () => {
  return (
    <>
      <Typography sx={DisclaimerContentBodyTextSx}>
        Allwhere will always aim to procure the exact model selected. However,
        please be aware that not all models of devices and IT Peripherals will
        be available in the country of your choice. If an IT Peripheral (e.g.
        Monitor, Keyboard, Mouse) is not available to procure in your selected
        country, we will procure an equivalent item in selected country Any
        Custom-To-Order requests may take up to 4-8 business weeks to process,
        as these custom devices are not typically stocked.
      </Typography>
      <br />
      <Typography sx={DisclaimerContentHeaderTextSx}>Lead Times</Typography>
      <Typography sx={DisclaimerContentBodyTextSx}>
        Once an order is placed, the recipient is requested to provide their
        shipping address. Order fulfillment timelines start once allwhere has
        received this information. Domestic lead times are 4-6 business days and
        international lead times are 7-10 business days, depending on the
        region. These lead times include 24 hours of allwhere internal
        processing and handling.
      </Typography>
      <br />
      <Typography sx={DisclaimerContentBodyTextSx}>
        CTO (Custom-to-Order) devices are typically not stocked for immediate
        fulfillment and may take up to 4-8 business weeks to fulfill.
      </Typography>
      <br />
      <Typography sx={DisclaimerContentHeaderTextSx}>Pricing</Typography>
      <Typography sx={DisclaimerContentBodyTextSx}>
        Pricing listed is typical of domestic United States MSRP. Please note
        that for international orders, pricing is subject to change per region
        and based on availability. If actual prices are above the listed amount,
        allwhere will provide you with an updated quote to approve before
        purchasing. Please note, CTO orders are non-refundable.
      </Typography>
      <br />
      <Typography sx={DisclaimerContentHeaderTextSx}>Sourcing</Typography>
      <Typography sx={DisclaimerContentBodyTextSx}>
        allwhere will always aim to procure the exact model selected through
        local suppliers. However, please be aware that not all models may be
        available in your selected country. If the exact model you selected is
        not available to procure in the desired region, allwhere will source the
        next best alternative model and provide you with an updated quote to
        approve before purchasing.
      </Typography>
    </>
  );
};

export default ProcurementCopy;
