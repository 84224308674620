import { AddOrderItemByEnum } from 'pages/OrderManagement/components/DetailedOrderView/enum';

export const getAddOrderItemModalCopyTuple = (
  addOrderItemBy: string
): [title: string, subtitle: string] => {
  if (addOrderItemBy === AddOrderItemByEnum.PRODUCT) {
    return [
      'Add Product',
      'Please enter the product SKU and quantity to the order.',
    ];
  }
  if (addOrderItemBy === AddOrderItemByEnum.ASSET) {
    return [
      'Add Asset(s)',
      'Please enter the asset ID(s) or serial number(s) to add asset(s) to the order. Separate multiple entries with a comma.',
    ];
  }

  return ['UNREACHABLE_TITLE', 'UNREACHABLE_SUBTITLE'];
};
