/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { AssetFilterClearButtonSx } from 'pages/Assets/components/DevicesDatagrid/components/DeviceFilters/styles';
import {
  DeviceConditionFilterSettings,
  ASSET_CONDITION_OPTIONS,
} from './constants';
import { AssetConditionFilterProps } from './types';
import * as AssetConditionFilterStyles from './styles';

const AssetConditionFilter = (props: AssetConditionFilterProps) => {
  const styles = AssetConditionFilterStyles;

  const handleClose = () => {
    props.setChosenAssetCondition('');
  };

  const handleAssetConditionChange = (event: SelectChangeEvent) => {
    const { value: newChosenAssetCondition } = event.target;
    if (
      !newChosenAssetCondition ||
      newChosenAssetCondition === props.chosenAssetCondition
    )
      return;
    props.setChosenAssetCondition(newChosenAssetCondition);
  };

  return (
    <Stack sx={styles.AssetTypeFilterStackSx}>
      {props.chosenAssetCondition ? (
        <Tooltip title="Clear" arrow>
          <IconButton
            size="small"
            aria-label="clear asset condition filter"
            onClick={handleClose}
            sx={AssetFilterClearButtonSx}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      ) : null}

      <FormControl
        sx={styles.getAssetFilterFormControlSx(
          DeviceConditionFilterSettings.labelId
        )}
      >
        <InputLabel id={DeviceConditionFilterSettings.labelId}>
          {DeviceConditionFilterSettings.label}
        </InputLabel>
        <Select
          value={props.chosenAssetCondition}
          onChange={handleAssetConditionChange}
          sx={styles.AssetFilterSelectSx}
          placeholder={DeviceConditionFilterSettings.placeholder}
          label={DeviceConditionFilterSettings.label}
          labelId={DeviceConditionFilterSettings.labelId}
          id={DeviceConditionFilterSettings.inputId}
        >
          {ASSET_CONDITION_OPTIONS.map((assetConditionOption: string) => (
            <MenuItem key={assetConditionOption} value={assetConditionOption}>
              {assetConditionOption}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default AssetConditionFilter;
