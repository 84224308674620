import React from 'react';
import CSVDownloadButton from 'components/buttons/csv/CSVDownloadButton';
import { selectOrdersCSVLoadingState } from 'store/slices/orders/csv/selectors';
import {
  useFileDownload,
  useHandleCSVDownloadError,
  useHandleDownloadAllDataClick,
} from './hooks';
import { useSelector } from 'react-redux';

const OrdersCSVDownloadButton = () => {
  useHandleCSVDownloadError();
  useFileDownload();
  const handleDownloadAllDataClick = useHandleDownloadAllDataClick();
  const loadingState = useSelector(selectOrdersCSVLoadingState);

  return (
    <CSVDownloadButton
      text="All data"
      ariaLabel="download all orders data"
      onClick={handleDownloadAllDataClick}
      loading={loadingState === 'pending'}
    />
  );
};

export default OrdersCSVDownloadButton;
