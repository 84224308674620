import { BackendOperations } from 'global-constants';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import {
  DEPRECATED_SEARCH_ASSETS_BY_SERIAL_NUMBER,
  SEARCH_ASSETS_BY_SERIAL_NUMBER,
} from './queries';

export const useGetAssetsBySerialNumber = () => {
  const returnDeprecatedQuery =
    process.env.REACT_APP_OPERATIONS_BACKEND !== BackendOperations.ACUMATICA;

  const [
    deprecatedSearchAssetsBySerialNumber,
    { loading: deprecatedSearchAssetsIsLoading },
  ] = useSafeLazyQuery(DEPRECATED_SEARCH_ASSETS_BY_SERIAL_NUMBER);

  const [searchAssetsBySerialNumber, { loading: searchAssetsIsLoading }] =
    useSafeLazyQuery(SEARCH_ASSETS_BY_SERIAL_NUMBER);

  return {
    searchAssetsBySerialNumber: returnDeprecatedQuery
      ? deprecatedSearchAssetsBySerialNumber
      : searchAssetsBySerialNumber,
    isSearchAssetsBySerialNumberLoading: returnDeprecatedQuery
      ? deprecatedSearchAssetsIsLoading
      : searchAssetsIsLoading,
  };
};
