import { SxProps } from '@mui/material/styles';

export const InnerProcurementStackSX: SxProps = {
  paddingTop: '3rem',
  marginTop: '2.5rem',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',

  '& .send-equipment-title-copy': {
    fontWeight: 500,
  },

  // eslint-disable-next-line no-useless-computed-key
  ['@media (max-width: 480px)']: {
    flexDirection: 'column',
  },
};
