import { gql } from '@apollo/client';

export const CREATE_DETAILED_COLLABORATOR = gql`
  mutation collaboratorCreate(
    $firstName: String!
    $lastName: String!
    $email: String!
    $personalEmail: String
    $workEmail: String
    $organizationId: ID!
    $phoneNumber: String
    $group: [String!]
    $startDate: DateTime
    $hireDate: DateTime
    $employmentStatus: EmploymentStatusEnum
  ) {
    collaboratorCreate(
      collaboratorCreateInput: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        personalEmail: $personalEmail
        workEmail: $workEmail
        organizationId: $organizationId
        group: $group
        startDate: $startDate
        hireDate: $hireDate
        phoneNumber: $phoneNumber
        employmentStatus: $employmentStatus
      }
    ) {
      id
      firstName
      lastName
      email
      workEmail
      personalEmail
    }
  }
`;

export const UPDATE_COLLABORATOR = gql`
  mutation updateCollaborator(
    $id: ID!
    $firstName: String
    $lastName: String
    $email: String
    $workEmail: String
    $personalEmail: String
    $phoneNumber: String
    $group: [String!]
    $startDate: DateTime
    $endDate: DateTime
    $hireDate: DateTime
  ) {
    updateCollaborator(
      collaboratorUpdateInput: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        email: $email
        workEmail: $workEmail
        personalEmail: $personalEmail
        group: $group
        startDate: $startDate
        endDate: $endDate
        hireDate: $hireDate
        phoneNumber: $phoneNumber
      }
    ) {
      id
      firstName
      lastName
      personalEmail
      workEmail
    }
  }
`;

export const CREATE_ADDRESS = gql`
  mutation createAddress(
    $streetAddress1: String
    $streetAddress2: String
    $city: String!
    $state: StateEnum
    $principalRegion: String
    $zipCode: String
    $phoneNumber: String
    $country: CountryEnum!
  ) {
    createAddress(
      createAddressInput: {
        streetAddress1: $streetAddress1
        streetAddress2: $streetAddress2
        city: $city
        state: $state
        principalRegion: $principalRegion
        zipCode: $zipCode
        phoneNumber: $phoneNumber
        country: $country
      }
    ) {
      id
    }
  }
`;

export const CREATE_COLLABORATOR_ADDRESS = gql`
  mutation createCollaboratorAddress(
    $collaboratorId: ID!
    $addressId: ID!
    $isHome: Boolean
    $isWork: Boolean
  ) {
    createCollaboratorAddress(
      collaboratorAddressCreateInput: {
        collaboratorId: $collaboratorId
        addressId: $addressId
        isHome: $isHome
        isWork: $isWork
      }
    ) {
      id
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation updateAddress(
    $id: ID!
    $streetAddress1: String
    $streetAddress2: String
    $city: String
    $state: StateEnum
    $principalRegion: String
    $country: CountryEnum
    $zipCode: String
  ) {
    updateAddress(
      addressUpdateInput: {
        id: $id
        streetAddress1: $streetAddress1
        streetAddress2: $streetAddress2
        city: $city
        state: $state
        principalRegion: $principalRegion
        country: $country
        zipCode: $zipCode
      }
    ) {
      id
    }
  }
`;
