import React from 'react';
import { DataGridSx, StyledDataGrid } from './styles';
import { DataGridProps } from './types';
import {
  DEFAULT_GRID_FILTER_MODE,
  DEFAULT_HEADER_HEIGHT,
  DEFAULT_PAGINATION_MODE,
  DEFAULT_ROW_HEIGHT,
} from './constants';

const DataGrid = <T extends DataGridProps['rows']>(props: DataGridProps) => {
  const combinedDataGridSx = {
    ...DataGridSx,
    ...(props?.dataGridSx ?? {}),
  };

  return (
    <>
      <StyledDataGrid
        {...props}
        autoHeight={props.autoHeight ?? true}
        keepNonExistentRowsSelected
        pagination={props.pagination}
        pageSizeOptions={props.pageSizeOptions ?? []}
        paginationModel={props.paginationModel}
        filterMode={props.filterMode || DEFAULT_GRID_FILTER_MODE}
        columnHeaderHeight={DEFAULT_HEADER_HEIGHT}
        rowHeight={DEFAULT_ROW_HEIGHT}
        paginationMode={props.paginationMode || DEFAULT_PAGINATION_MODE}
        getRowHeight={props.enableDynamicRowHeight ? () => 'auto' : undefined}
        rows={props.rows as T[]}
        sx={combinedDataGridSx}
        onRowClick={props.onRowClick}
        onCellClick={props.onCellClick}
        detailPanelExpandedRowIds={props.detailPanelExpandedRowIds}
      />
    </>
  );
};

export default DataGrid;
