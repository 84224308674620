/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { SxProps } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { OptionInfoTooltipProps } from './types';

const OptionInfoTooltip = (props: OptionInfoTooltipProps) => {
  const TooltipInfoSx: SxProps = {
    ml: '8px',
  };

  return (
    <>
      {props.description ? (
        <Tooltip title={props.description} placement="top" sx={TooltipInfoSx}>
          <InfoOutlinedIcon />
        </Tooltip>
      ) : null}
    </>
  );
};

export default OptionInfoTooltip;
