import { SxProps } from '@mui/material';

export const BoxSearchSx: SxProps = {
  display: 'flex',
  padding: '0 !important',
  margin: '0 !important',
  width: '100%',

  '& .orders-search-bar': {
    display: 'flex',
    padding: '0 !important',
    margin: '0 !important',
    border: 'none !important',
    backgroundColor: 'rgba(19, 20, 20, 0.04)',
    '& .MuiOutlinedInput-notchedOutline ': {
      border: 'none !important',
    },
  },
};
