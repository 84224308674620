import { gql } from '@apollo/client';

export const SEARCH_NOTIFICATIONS = gql`
  query searchNotifications(
    $recordId: ID!
    $offset: Int
    $limit: Int
    $orderAsc: String
    $orderDesc: String
  ) {
    searchNotifications(
      notificationSearchInput: {
        recordId: $recordId
        offset: $offset
        limit: $limit
        orderAsc: $orderAsc
        orderDesc: $orderDesc
      }
    ) {
      notifications {
        id
        recordId
        status
        notificationType {
          id
          name
          typeName
        }
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const DEPRECATED_SEARCH_NOTIFICATIONS = gql`
  query searchOrderNotifications(
    $orderId: ID!
    $offset: Int
    $limit: Int
    $orderAsc: String
    $orderDesc: String
  ) {
    searchOrderNotifications(
      orderNotificationSearchInput: {
        orderId: $orderId
        offset: $offset
        limit: $limit
        orderAsc: $orderAsc
        orderDesc: $orderDesc
      }
    ) {
      orderNotifications {
        id
        orderId
        status
        orderNotificationType {
          id
          name
          typeName
        }
        createdAt
        updatedAt
      }
      count
    }
  }
`;
