import { RetrievalAsset } from 'pages/Retrieval/RetrievalService/types';
import { IAddAssetFormValues } from './types';

const deriveAssetName = (addAssetFormValues: IAddAssetFormValues): string => {
  const { make, model, assetType } = addAssetFormValues;
  const displayModel = model ? ` ${model}` : '';

  return `${make}${displayModel} ${assetType}`;
};

export const makeRetrievalAddAsset = (
  addAssetFormValues: IAddAssetFormValues,
  fakeId: string
): RetrievalAsset => {
  const { assetType, ...restOfAssetForm } = addAssetFormValues;
  return {
    id: fakeId,
    addedAsset: true,
    type: assetType,
    name: deriveAssetName(addAssetFormValues),
    optionAnswers: [],
    ...restOfAssetForm,
  };
};

export const isSerialNumberInLocalStoredAssets = (
  serialNumber: string,
  localStoredAssets: RetrievalAsset[]
): boolean => {
  return localStoredAssets.some((asset) => asset.serialNumber === serialNumber);
};
