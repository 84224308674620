/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Pill from 'components/Pill';
import { useTheme } from '@mui/material';
import { getShipmentStatusConfig } from './utils';
import { ShipmentStatusPillProps } from './types';

const ShipmentStatusPill = (props: ShipmentStatusPillProps) => {
  const theme = useTheme();
  const config = getShipmentStatusConfig(props.status, theme);
  const overrideSx = props?.overrideSx ?? {};

  return (
    <Pill
      size="small"
      toolTipArrow
      text={config.text}
      color={config.color}
      textColor={config.textColor}
      toolTipHoverText={config.hoverText}
      toolTipPlacement={props.toolTipPlacement ?? 'right'}
      chipOverrideSx={overrideSx}
    />
  );
};

export default ShipmentStatusPill;
