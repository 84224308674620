import { gql } from '@apollo/client';

export const GET_ALL_RECIPIENT_ORDERS = gql`
  query getAllRecipientOrders($organizationId: ID!) {
    getAllRecipientOrders(
      recipientOrderAllInput: { organizationId: $organizationId }
    ) {
      recipientName
      recipientOrderID
      internalOrderType
      numberOfOrderItems
      products
      status
      orderDate
      purchaserName
      checkoutNotes
    }
  }
`;

export const GET_ALL_ORDERS_BY_ORG = gql`
  query getAllOrders($organizationId: ID!) {
    getAllOrders(getAllOrdersInput: { organizationId: $organizationId }) {
      id
      orderNumber
      checkoutNotes
      dueDate
      orderType {
        id
        name
      }
      dropRecipient {
        id
        firstName
        lastName
      }
      recipient {
        id
        firstName
        lastName
      }
      purchaser {
        id
        firstName
        lastName
      }
      createdAt
      status
      finalDestination
      orderItems {
        id
      }
    }
  }
`;
