import { SxProps } from '@mui/material';

export const AddAssetButtonSx: SxProps = {
  padding: '6px 16px',
};

export const AddAssetButtonTextSx: SxProps = {
  width: '54px',
  height: '24px',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '24px',
};
