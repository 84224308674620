import { CSSProperties } from 'react';
import theme from 'themes/theme';

export const RETRIEVAL_TOP_BAR_HEIGHT = '80px';

export const RETRIEVAL_CONTAINER_STYLES: CSSProperties = {
  width: '100vw',
  height: '100vh',
  backgroundColor: '#ffffff',
  zIndex: theme.zIndex.underModal,
};

export const FlowStepSx = (show: boolean) => ({
  paddingTop: show ? '78px' : '0',
});
