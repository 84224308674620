import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const FilterStackSx: SxProps = {
  height: '20px',
  position: 'relative',
};

export const FilterClearButtonSx: SxProps = {
  backgroundColor: `${theme.palette.colors.grayF6} !important`,
  height: '24px',
  position: 'absolute',
  right: '30px',
  transformOrigin: 'top right',
  top: '5px',
  width: '24px',
  zIndex: 1,
  '&:hover': {
    backgroundColor: 'inherit',
  },
};

export const getFilterFormControlSx = (inputLabelId: string): SxProps => ({
  [`& #${inputLabelId}`]: {
    top: '-10px',
    '&.MuiInputLabel-shrink': {
      top: 0,
    },
  },
});

export const FilterSelectSx: SxProps = {
  width: '165px',
  '& .MuiSelect-select': {
    py: '6px',
  },
};
