import { SxProps } from '@mui/material';
import { DRAWER_WIDTH } from 'global-styles';
import theme from 'themes/theme';

export const DepotViewLowerStackSx: SxProps = {
  mt: '22px',
  width: '100%',
  flexDirection: {
    md: 'column',
    lg: 'row',
  },
  gap: '24px',
  justifyContent: {
    md: 'flex-start',
    lg: 'space-between',
  },
};

export const DepotViewSelectionStackSx: SxProps = {
  width: {
    md: '100%',
    lg: 'calc(63% - 12px)',
  },
};

export const DepotViewBuyAndHoldCTASx: SxProps = {
  width: {
    md: '100%',
    lg: 'calc(37% - 12px)',
  },
};

export const DepotViewContinueButtonSx: SxProps = {
  minWidth: '200px',
  px: 0,
  '& .MuiTypography-root': {
    color: theme.palette.colors.woodSmoke,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px',
  },
};
