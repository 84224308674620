import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid-pro';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { Button } from 'components';
import { fromCamelToSentenceCase, formatDate } from 'services';
import { Order } from 'types';
import {
  FailedTimeStampTextSx,
  PendingTimeStampTextSx,
  SentTimeStampTextSx,
  TimeStampBaseTextSx,
} from './styles';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import { FeatureFlagNames } from 'enums';

export const useGetOrderNotificationsColumns = (
  order: Order,
  sendNotification: any
): GridColDef[] => {
  // Feature flags: TODO: Remove logic when the feature flag is removed
  const { isFlagOn: HrisOnboardingOffboardingEnabled } = useFeatureFlagService(
    FeatureFlagNames.HRIS_ONBOARDING_OFFBOARDING,
    {
      debugFlags: true,
    }
  );

  return [
    {
      field: 'timeStamp',
      headerName: 'Time stamp',
      align: 'left',
      sortable: false,
      filterable: false,
      flex: 1,
      valueGetter(params) {
        if (!params.row.createdAt) return '';
        return params.row.createdAt
          ? formatDate(params.row.createdAt, 'MMM D, yyyy h:mm A')
          : '';
      },
      renderCell: (params) => {
        return (
          <Typography variant="body2" sx={{ whiteSpace: 'normal' }}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: 'notification',
      headerName: 'Notification',
      align: 'left',
      sortable: false,
      filterable: false,
      flex: 2,
      renderCell: (params) => {
        const orderNotificationTypeName = fromCamelToSentenceCase(
          params.row.notificationType.name
        );

        const timeStampComponent = () => {
          if (params.row.status === 'PENDING') {
            return (
              <Stack direction="row" alignItems="flex-end" columnGap="4px">
                <QueryBuilderIcon fontSize="small" htmlColor="#1314148A" />
                <Typography variant="body2" sx={PendingTimeStampTextSx}>
                  PENDING
                </Typography>
              </Stack>
            );
          }

          if (params.row.status === 'FAILED') {
            return (
              <Stack direction="row" alignItems="flex-end" columnGap="4px">
                <ErrorOutlineOutlinedIcon
                  fontSize="small"
                  sx={{ color: 'colors.burntSiennaR' }}
                />
                <Typography variant="body2" sx={FailedTimeStampTextSx}>
                  FAILED ON{' '}
                  {formatDate(params.row.updatedAt, 'MM/DD/YY @ hh:mmA')}
                </Typography>
              </Stack>
            );
          }

          return (
            <Stack direction="row" alignItems="flex-end" columnGap="4px">
              <CheckIcon fontSize="small" sx={{ color: 'colors.goldFusion' }} />
              <Typography variant="body2" sx={SentTimeStampTextSx}>
                SENT ON {formatDate(params.row.updatedAt, 'MM/DD/YY @ hh:mmA')}
              </Typography>
            </Stack>
          );
        };

        return (
          <div style={{}}>
            <>
              <Typography variant="body2" sx={TimeStampBaseTextSx}>
                {orderNotificationTypeName}
              </Typography>
              {timeStampComponent()}
            </>
          </div>
        );
      },
    },
    {
      field: 'sentTo',
      headerName: 'Sent to',
      align: 'left',
      sortable: false,
      filterable: false,
      flex: 1,
      valueGetter(params) {
        const isRecipientEmail =
          params.row.notificationType.typeName === 'RECIPIENT';
        if (isRecipientEmail) {
          if (order.dropRecipient) {
            return `${order.dropRecipient.firstName} ${order.dropRecipient.lastName}`;
          }
          if (order.recipient) {
            return `${order.recipient.firstName} ${order.recipient.lastName}`;
          }
          return '';
        }
        return order?.purchaser
          ? `${order?.purchaser?.firstName} ${order?.purchaser?.lastName}`
          : '';
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      type: 'actions',
      renderCell(params) {
        return (
          <Button
            gaContext={{
              textCopy: 'Resend',
              navigates_to: 'N/A',
              purpose: 'Resends User Notification',
            }}
            variant="outlined"
            color="secondary"
            sx={{ padding: '8px 22px', minWidth: '85px' }}
            onClick={() => {
              // TODO: Remove conditional variable selection when feature flag is removed (property to be 'seearchNotifications' when feature flag is removed)
              sendNotification({
                variables: {
                  ...(HrisOnboardingOffboardingEnabled()
                    ? {
                        recordId: order.id,
                        notificationTypeId: params.row.notificationType.id,
                      }
                    : {
                        orderId: order.id,
                        orderNotificationTypeId: params.row.notificationType.id,
                      }),
                },
              });
            }}
          >
            <Typography variant="button" color="secondary">
              Resend
            </Typography>
          </Button>
        );
      },
    },
  ];
};
