import React from 'react';
import { Stack, TextField, Box, Divider } from '@mui/material';
import Fade from '@mui/material/Fade';
import { OrderNotesProps } from './types';
import { OrderNotesSX } from './styles';

const OrderNotes = (props: OrderNotesProps) => {
  const [openTextField, setOpenTextField] = React.useState(false);

  const toggleNotes = () => {
    setOpenTextField((prevOpenState) => !prevOpenState);
  };

  const handleDeleteIconClick = () => {
    props.setCheckoutNotes('');
    setOpenTextField(false);
  };

  const handleOnChangeNotes = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setCheckoutNotes(event.target.value);
  };

  // eslint-disable-next-line consistent-return
  const getNotesButtonText = () => {
    // The switch statement will match against EXPRESSIONS as well as VALUES. EXPRESSIONS in a CASE will be evaluated before matching; this pattern reduces nested if/else and prioritizes early returns
    switch (true) {
      case openTextField:
        return '- hide order notes';

      case !openTextField && !!props.checkoutNotes:
        return '+ show order notes';

      case !openTextField && !props.checkoutNotes:
        return '+ add order notes';

      default:
        return '??? this should not be reachable';
    }
  };

  return (
    <Stack sx={OrderNotesSX}>
      <Box id="order-notes-title-box">
        <span id="order-notes-title">
          {`Order Notes?${props.checkoutNotes ? ' (1)' : ''}`}
        </span>

        <span
          aria-role="button"
          id="add-order-notes-to-checkout-review"
          role="button"
          onClick={toggleNotes}
        >
          {getNotesButtonText()}
        </span>
      </Box>

      <Fade
        easing={{ enter: 'ease' }}
        in={openTextField}
        style={{
          display: openTextField ? 'block' : 'none',
          height: props.isProcurement ? '100%' : '152px',
        }}
        timeout={{ enter: 400, exit: 200 }}
        unmountOnExit={true}
      >
        <Stack id="order-notes-text-field-stack">
          <Fade
            easing={{ enter: 'ease' }}
            in={!!props.checkoutNotes}
            timeout={{ enter: 300, exit: 200 }}
            unmountOnExit={true}
          >
            <span
              aria-role="button"
              id="order-notes-delete-button"
              role="button"
              onClick={handleDeleteIconClick}
            >
              clear all
            </span>
          </Fade>

          <TextField
            maxRows={3}
            rows={3}
            multiline
            onChange={handleOnChangeNotes}
            placeholder="Type notes here..."
            value={props.checkoutNotes}
            sx={{ maxHeight: '92px' }}
          />
        </Stack>
      </Fade>
      {props.isProcurement && <Divider />}
    </Stack>
  );
};

export default OrderNotes;
