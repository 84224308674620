import { SxProps } from '@mui/material';

export const getFilterButtonSxHOF = (
  filterDrawerOpen: boolean,
  hasFilters: boolean,
  optionalSx: SxProps
): SxProps => ({
  backgroundColor: filterDrawerOpen ? '#1313130A' : 'transparent',
  borderRadius: '30px',
  fontWeight: 600,
  maxHeight: '40px',
  minWidth: hasFilters ? '115px' : '70px',
  position: 'relative',
  px: '12px',
  py: '4px',
  width: '94px',
  '&: hover': {
    backgroundColor: '#1313130A',
    cursor: 'pointer',
  },
  ...optionalSx,
});
