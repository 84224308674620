import React from 'react';
import Box from '@mui/material/Box';
import { GridPaginationModel } from '@mui/x-data-grid-pro';
import {
  DEFAULT_PAGINATION_MODEL,
  GENERIC_PAGE_SIZE_OPTIONS,
} from 'global-constants';
import DataGrid from 'components/DataGrid';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { NotificationsTableProps } from './types';
import { DataGridBoxSx } from './styles';
import {
  DEPRECATED_SEARCH_NOTIFICATIONS,
  SEARCH_NOTIFICATIONS,
} from './queries';
import { useGetOrderNotificationsColumns } from './hooks/useGetOrderNotificationsColumns';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import { FeatureFlagNames } from 'enums';

const NotificationsTable = (props: NotificationsTableProps) => {
  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>(DEFAULT_PAGINATION_MODEL);

  // Feature flags: TODO: Remove this when the feature flag is removed (lines 24-51)
  const { isFlagOn: HrisOnboardingOffboardingEnabled } = useFeatureFlagService(
    FeatureFlagNames.HRIS_ONBOARDING_OFFBOARDING,
    {
      debugFlags: true,
    }
  );

  const getQuery = () => {
    return HrisOnboardingOffboardingEnabled()
      ? SEARCH_NOTIFICATIONS
      : DEPRECATED_SEARCH_NOTIFICATIONS;
  };
  const getOrderNotificationsData = (data: any) => {
    if (HrisOnboardingOffboardingEnabled()) {
      return data?.searchNotifications.notifications || [];
    }

    return (data?.searchOrderNotifications.orderNotifications || []).map(
      (notification: any) => {
        return {
          ...notification,
          recordId: notification.orderId,
          notificationType: notification.orderNotificationType,
        };
      }
    );
  };

  // TODO: Remove call to getQuery() when feature flag is removed
  const { data, loading } = useSafeQuery(getQuery(), {
    variables: {
      ...(HrisOnboardingOffboardingEnabled()
        ? { recordId: props.order.id }
        : { orderId: props.order.id }),
      offset: paginationModel.page * paginationModel.pageSize,
      limit: paginationModel.pageSize,
      orderDesc: 'createdAt',
    },
  });

  // TODO: Remove call to getOrderNotificationsData when feature flag is removed
  const orderNotifications = getOrderNotificationsData(data);

  // TODO: Remove conditional property access when feature flag is removed (property to be 'seearchNotifications' when feature flag is removed)
  const rowCount =
    data?.[
      `${
        HrisOnboardingOffboardingEnabled()
          ? 'searchNotifications'
          : 'searchOrderNotifications'
      }`
    ].count || 0;

  const columns = useGetOrderNotificationsColumns(
    props.order,
    props.sendNotification
  );

  return (
    <Box sx={DataGridBoxSx}>
      <DataGrid
        rows={orderNotifications}
        rowCount={rowCount}
        columns={columns}
        loading={loading || props.showLoadingIndicator}
        autoHeight={false}
        rowSelection={false}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        pageSizeOptions={GENERIC_PAGE_SIZE_OPTIONS}
      />
    </Box>
  );
};

export default NotificationsTable;
