import React from 'react';
import { useDispatch } from 'react-redux';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';
import { NewModal, Button } from 'components';
import { useSafeMutation } from 'hooks/useSafeMutation';
import { notificationSlice } from 'store/slices';
import * as SubmitToERPCopy from './constants';
import { SUBMIT_ORDER_TO_ERP } from './mutations';
import * as styles from './styles';
import { SubmitOrderToERPInputProps } from './types';
import GALoadingButton from 'components/buttons/google_analytics/GALoadingButton';
import * as Sentry from '@sentry/react';

const SubmitToERPModal = (props: SubmitOrderToERPInputProps) => {
  if (!props.order) return null;

  const [submitOrderToERP, { loading: submitOrderToERPIsLoading }] =
    useSafeMutation(SUBMIT_ORDER_TO_ERP);

  const dispatch = useDispatch();

  const onSubmit = () => {
    submitOrderToERP({
      variables: {
        id: props?.order?.id,
      },
      onCompleted() {
        dispatch(
          notificationSlice.actions.setNotice({
            showNotice: true,
            noticeContent: NotificationMessages.SYNC_TO_ERP_REQUEST_SUCCESS,
          })
        );
        props.handleClose();
      },
      onError(error) {
        Sentry.captureException(error, (scope) => {
          scope.setContext('context', {
            query: SUBMIT_ORDER_TO_ERP,
            variables: {
              id: props?.order?.id,
            },
          });
          return scope;
        });
        dispatch(
          notificationSlice.actions.setNotice({
            showNotice: true,
            noticeContent: NotificationMessages.GENERIC_SOMETHING_WENT_WRONG,
          })
        );
      },
    });
  };
  return (
    <NewModal
      open={props.open}
      handleClose={props.handleClose}
      paperPropsSx={styles.SubmitToERPModalPaperSx}
    >
      <Stack
        direction="column"
        spacing={0}
        justifyContent="space-between"
        sx={styles.SubmitToERPModalMainStackSx}
      >
        <Stack>
          <Typography variant="h5" sx={styles.TitleSx}>
            {SubmitToERPCopy.SUBMIT_TO_ERP_MODAL_TITLE}
          </Typography>

          <Typography variant="h5" sx={styles.SubtitleSx}>
            {SubmitToERPCopy.SUBMIT_TO_ERP_MODAL_SUBTITLE}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          justifyContent="flex-end"
          sx={{ width: '100%' }}
        >
          <Button
            gaContext={{
              navigates_to: 'N/A',
              purpose: 'Cancels ERP Data Send',
              textCopy: SubmitToERPCopy.SUBMIT_TO_ERP_CANCEL_BUTTON_TEXT,
            }}
            id="close-submit-order-erp-button"
            color="secondary"
            onClick={props.handleClose}
            sx={styles.SubmitToERPModalButtonBaseSx}
          >
            <Typography variant="button">
              {SubmitToERPCopy.SUBMIT_TO_ERP_CANCEL_BUTTON_TEXT}
            </Typography>
          </Button>
          <GALoadingButton
            gaContext={{
              navigates_to: 'N/A',
              purpose: 'Sends Data To ERP',
              textCopy: SubmitToERPCopy.SUBMIT_TO_ERP_SUBMIT_BUTTON_TEXT,
            }}
            id="submit-order-erp-button"
            sx={styles.SubmitToERPModalButtonBaseSx}
            onClick={onSubmit}
            variant="contained"
            loading={submitOrderToERPIsLoading}
          >
            <Typography variant="button">
              {SubmitToERPCopy.SUBMIT_TO_ERP_SUBMIT_BUTTON_TEXT}
            </Typography>
          </GALoadingButton>
        </Stack>
      </Stack>
    </NewModal>
  );
};

export default SubmitToERPModal;
