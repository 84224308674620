import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { procurementToDepotSlice } from 'store/slices';
import { GlobalState } from 'store/types';
import Box from '@mui/system/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Stack from '@mui/system/Stack';
import Toolbar from '@mui/material/Toolbar';
import useTheme from '@mui/material/styles/useTheme';
import { useLocation } from 'react-router-dom';
import {
  SideBarDividerSx,
  SidebarContainerSx,
  SidebarFooterContainerSx,
  FulLHeightSx,
  SidebarStackContainerSx,
} from './styles';
import { NavigationContext } from 'context/NavigationContext';
import { useGetPreDividerNavLinks } from './hooks/useGetPreDividerNavLinks';
import {
  getPostDividerNavLinks,
  getFooterNavLinkItems,
  calculateBuyNHoldCartItemCount,
} from './utils';
import BillingCTA from './components/BillingCTA';
import NavLinks from './components/NavLinks';
import useGetUserPermissions from 'hooks/useGetUserPermissions';
import { UserPermissions } from 'global-constants';
import { useProcurementService } from 'pages/Procurement/hooks/useProcurementService';
import { selectGlobalNotificationBanner } from 'store/slices/global/selectors';

const Sidebar = () => {
  /* REMOVE below to render Sidebar according to route and NOT Acumatica Maintenance Page */
  // const siteMaintenanceContext = React.useContext(SiteMaintenanceContext);
  // if (siteMaintenanceContext.showMaintenancePage) return null;

  const dispatch = useDispatch();
  const globalNotificationBanner = useSelector(selectGlobalNotificationBanner);

  // Buy & Hold is officially called Procurement to Depot
  const buyNHoldServices = useProcurementService('buy_and_hold');

  const getAndStoreCartItemCount = (cartItems: any[]) => {
    const newTotal = calculateBuyNHoldCartItemCount(cartItems);
    dispatch(procurementToDepotSlice.actions.setCartTotal(newTotal));
  };

  React.useEffect(() => {
    if (!buyNHoldServices?.cart || !buyNHoldServices?.cart?.length) return;
    getAndStoreCartItemCount(buyNHoldServices?.cart ?? []);
  }, [buyNHoldServices?.cart]);

  const buyNHoldCartItemCount = useSelector<GlobalState, number>(
    (state) => state.procurementToDepot.totalCartItems
  );

  const userPermissions = useGetUserPermissions();

  const canSeeBuyAndHold = userPermissions?.includes(
    UserPermissions.BUY__AND__HOLD
  );

  const { pathname, search } = useLocation();

  const fullPathName = `${pathname}${search}`;

  const theme = useTheme();

  const navigator = React.useContext(NavigationContext);

  const preDividerNavLinkItems = useGetPreDividerNavLinks();

  const postDividerNavLinkItems = getPostDividerNavLinks(
    navigator,
    fullPathName,
    {
      canSeeBuyAndHold,
      buyNHoldCartItemCount,
    }
  );

  const footerNavLinkItems = getFooterNavLinkItems();

  return (
    <Box
      id="sidebar"
      display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block' }}
    >
      <Drawer
        sx={SidebarContainerSx(theme, globalNotificationBanner.show)}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <Stack justifyContent="space-between" sx={FulLHeightSx}>
          <List sx={FulLHeightSx}>
            <Stack spacing={2} sx={SidebarStackContainerSx}>
              <NavLinks navLinkItems={preDividerNavLinkItems} />
              <BillingCTA hidden />
              <Divider sx={SideBarDividerSx} />
              <NavLinks navLinkItems={postDividerNavLinkItems} />
              <NavLinks
                propsContainerSx={SidebarFooterContainerSx}
                navLinkItems={footerNavLinkItems}
              />
            </Stack>
          </List>
        </Stack>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
