import { SxProps } from '@mui/material';
import { DRAWER_WIDTH, HEADER_HEIGHT } from 'global-styles';

export const MainContainerDivSx = {
  margin: 'auto',
  mt: HEADER_HEIGHT,
  position: 'relative',
  '@media (max-width: 1199px)': {
    left: '0',
  },
  '@media (min-width: 1200px)': {
    left: `${DRAWER_WIDTH - DRAWER_WIDTH / 2}px`,
    width: '80%',
  },
  '@media (min-width: 1536px)': {
    left: `${DRAWER_WIDTH - DRAWER_WIDTH / 2}px`,
    maxWidth: '100%',
    width: '85%',
  },
};

export const ContentDivSx = {
  marginTop: '1rem',
  padding: '24px 72px',
  gap: '16px',
  borderRadius: '10px',
  border: '1px solid #E0E0E0',
};

export const TitleDividerSx = {
  width: '100%',
  height: '1px',
  borderTop: '1px solid rgba(19, 20, 20, 0.12)',
  boxShadow: 'none',
};

export const TabPanelSx = {
  width: '100%',
  '&.MuiTabPanel-root': {
    pt: '10px',
    px: 0,
  },
};

export const TabsSx = {
  '& .MuiTab-root': {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.4px',
    pb: 0,
    mt: '-10px',
    '&.Mui-selected': {
      color: 'colors.woodSmoke',
    },
  },

  '& .MuiTabs-indicator': {
    backgroundColor: 'colors.woodSmoke',
  },
};
