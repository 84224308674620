export const ASSIGN_DEVICE_COST_COPY =
  '*Assigning devices from inventory will incur a fee of $65 for laptops, $125 for Monitors < 27", $45 for tablets, $35 for mobile devices, and $20 + pass-through costs for other items.';

export const DFI_INSURANCE_OPTIONS = [
  {
    optionText: 'None',
    value: {
      insuranceCharged: '0',
      insuranceRequested: '0',
    },
  },
  {
    optionText: '$500 ($7.50 cost)',
    value: {
      insuranceCharged: '$7.50',
      insuranceRequested: '$500',
    },
  },
  {
    optionText: '$1000 ($15 cost)',
    value: {
      insuranceCharged: '$15',
      insuranceRequested: '$1000',
    },
  },
  {
    optionText: '$1500 ($22.50 cost)',
    value: {
      insuranceCharged: '$22.50',
      insuranceRequested: '$1500',
    },
  },
  {
    optionText: '$2000 ($30 cost)',
    value: {
      insuranceCharged: '$30',
      insuranceRequested: '$2000',
    },
  },
  {
    optionText: '$2500 ($37.50 cost)',
    value: {
      insuranceCharged: '$37.50',
      insuranceRequested: '$2500',
    },
  },
  {
    optionText: '$3000 ($45.00 cost)',
    value: {
      insuranceCharged: '$45.00',
      insuranceRequested: '$3000',
    },
  },
];

export const DEFAULT_DFI_INSURANCE_OPTION_VALUE = JSON.stringify({
  insuranceCharged: '$30',
  insuranceRequested: '$2000',
});
