import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProcurementCopy from './components/ProcurementCopy';
import { DisclaimerSummaryTextSx } from './styles';

const DisclaimerSLA = () => (
  <Accordion square data-testid="disclaimer-sla-accordion">
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Typography sx={DisclaimerSummaryTextSx}>
        Shipping and handling
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      {/* TODO Add logic to show Retrieval copy when its implemented */}
      <ProcurementCopy />
    </AccordionDetails>
  </Accordion>
);

export default DisclaimerSLA;
