import { gql } from '@apollo/client';

export const GET_ALL_ORDER_STATUS_TRANSITIONS_BY_ORDER = gql`
  query getAllOrderStatusTransitionsByOrderId($orderId: String!) {
    getAllOrderStatusTransitionsByOrderId(orderId: $orderId) {
      id
      orderId
      to
      from
      createdAt
    }
  }
`;
