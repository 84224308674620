import React from 'react';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import TextIconButton from 'components/TextIconButton';
import { NavigationContext } from 'context/NavigationContext';
import { useTheme } from '@mui/material';
import { useGetCollaboratorColumns } from 'hooks/useGetCollaboratorColumns';

export const useGetEmployeeColumns = (
  rowSelectionModel: GridRowSelectionModel,
  rowHoveringModel: string
): GridColDef[] => {
  const navigator = React.useContext(NavigationContext);

  return [
    ...useGetCollaboratorColumns(rowSelectionModel),
    {
      // ...GRID_CHECKBOX_SELECTION_COL_DEF,
      field: 'null',
      renderHeader: () => <></>,
      filterable: false,
      sortable: false,
      renderCell(params) {
        const isRowHovered = params.id === rowHoveringModel;

        return (
          <Tooltip title="View Employee Details" arrow placement="bottom">
            <div style={{ flex: 1 }}>
              {isRowHovered ? (
                <TextIconButton
                  text="Details"
                  ariaLabel="View Employee Details"
                  icon={<InfoOutlinedIcon sx={{ color: 'colors.woodSmoke' }} />}
                  onClick={() =>
                    window.open(
                      navigator.constructPathToEmployeeDetails(
                        params.id.toString()
                      ),
                      '_blank'
                    )
                  }
                  size="small"
                  textStyles={{
                    color: 'colors.woodSmoke',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    lineHeight: '24px',
                    textDecoration: 'underline',
                  }}
                />
              ) : null}
            </div>
          </Tooltip>
        );
      },
    },
  ];
};
