import { SearchOption } from 'components/SearchScopeDropdown/types';

export enum AssetManagementSuggestionFilterName {
  SERIAL_NUMBER = 'serialNumber',
  THIRD_PARTY_ASSET = 'thirdPartyAssetId',
  ASSET_NUMBER = 'assetNumber',
}

export enum AssetManagementSuggestionDisplayName {
  SERIAL_NUMBER = 'Serial Number',
  THIRD_PARTY_ASSET = '3P Asset ID',
  ASSET_NUMBER = 'Asset Number',
}

export const ASSET_UUID = 'assetUUID';

export const AM_SEARCH_OPTIONS: SearchOption[] = [
  {
    displayName: '3P Asset ID',
    filterName: AssetManagementSuggestionFilterName.THIRD_PARTY_ASSET,
  },
  {
    displayName: 'Serial Number',
    filterName: AssetManagementSuggestionFilterName.SERIAL_NUMBER,
  },
  {
    displayName: 'Asset Number',
    filterName: AssetManagementSuggestionFilterName.ASSET_NUMBER,
  },
];
