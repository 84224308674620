import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/system';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { notificationSlice } from 'store/slices';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';
import NewModal from 'components/NewModal';
import DeviceInfo from 'components/DeviceInfo';
import { useSafeMutation } from 'hooks/useSafeMutation';
import { CREATE_PROCESS_DEVICE_ORDER } from './mutations';
import { DeviceInfoBoxSx, OuterStackPropsSx, PaperPropsSx } from './styles';
import { ProcessDeviceModalProps } from './types';
import {
  PROCESS_DEVICE_SUBTITLE,
  PROCESS_DEVICE_TITLE,
  SAVE_BUTTON_COPY,
  WIPE_CONFIRMATION_CHECKBOX_LABEL,
} from './constants';
import { AssetStatus } from 'global-constants';

const ProcessDeviceModal = (props: ProcessDeviceModalProps) => {
  if (!props.device || !props.organizationId || !props.purchaserId)
    return <></>;
  const { handleSubmit } = useForm();

  const dispatch = useDispatch();

  const [confirmedWipe, setConfirmedWipe] = React.useState<boolean>(true);

  const handleConfirmedWipeOnChange = () => {
    setConfirmedWipe(!confirmedWipe);
  };

  const [mutate, { loading }] = useSafeMutation(CREATE_PROCESS_DEVICE_ORDER);

  const onSubmit = () => {
    mutate({
      variables: {
        purchaserId: props.purchaserId,
        assetId: props.device?.id,
      },
      onError(error, clientOptions) {
        console.warn('error:\n', error);
        console.warn('clientOptions:\n', clientOptions);
      },
      onCompleted() {
        if (props.refetchSearchAssets) props.refetchSearchAssets();
        props.deviceActionsEnablement.enableActionsByDevice({
          ...props.device,
          status: AssetStatus.PROCESSING_DEVICE,
        });
        dispatch(
          notificationSlice.actions.setNotice({
            showNotice: true,
            noticeContent: NotificationMessages.PROCESS_REQUEST_SENT,
          })
        );
        props.handleClose();
      },
    });
  };

  const isSubmitButtonDisabled = !confirmedWipe || loading;

  const handleCloseModal = () => {
    props.handleClose();
  };

  return (
    <NewModal
      form
      open={props.open}
      title={PROCESS_DEVICE_TITLE}
      subtitle={PROCESS_DEVICE_SUBTITLE}
      handleClose={handleCloseModal}
      onSubmit={handleSubmit(onSubmit)}
      submitButtonCopy={SAVE_BUTTON_COPY}
      disableSaveButton={isSubmitButtonDisabled}
      loading={loading}
      paperPropsSx={PaperPropsSx}
      outerStackPropsSx={OuterStackPropsSx}
    >
      <Box sx={DeviceInfoBoxSx}>
        <DeviceInfo
          make={props.device.make}
          model={props.device.model}
          serialNumber={props.device.serialNumber}
        />
        <FormGroup className="wipe-confirmation">
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                checked={confirmedWipe}
                onChange={handleConfirmedWipeOnChange}
              />
            }
            label={WIPE_CONFIRMATION_CHECKBOX_LABEL}
          />
        </FormGroup>
      </Box>
    </NewModal>
  );
};

export default ProcessDeviceModal;
