import React from 'react';
import Stack from '@mui/material/Stack';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { UploadFileInputProps } from './types';

import {
  CloseIconButtonSx,
  FileInputSx,
  UploadFileImportButtonContainerSx,
  UploadFileImportButtonSx,
  UploadFileInputButtonSx,
  UploadFileInputContainerSx,
  UploadFileInputContentSx,
  UploadFileInputHeaderSx,
  UploadFileSelectedFileInfoSx,
  UploadFileTemplateLinkSx,
} from './styles';
import Button from 'components/Button';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import {
  UPLOAD_FILE_DOWNLOAD_TEMPLATE_LINK_COPY,
  UPLOAD_FILE_GUIDANCE_COPY,
  UPLOAD_FILE_HEADER_COPY,
  UPLOAD_FILE_MAX_ROWS_COPY,
  UPLOAD_FILE_SUB_HEADER_COPY,
  UPLOAD_FILE_UPLOAD_BUTTON_COPY,
} from './constants';
import { CloseSharp, UploadFile } from '@mui/icons-material';
import { Avatar, IconButton } from '@mui/material';
import { formatBytes } from './utils';
import UploadConfirmationModal from 'components/NewModal/UploadConfirmationModal';
import { useDispatch, useSelector } from 'react-redux';
import { modalSlice } from 'store/slices';
import { GlobalModals } from 'store/constants';
import { GlobalState } from 'store/types';

function UploadFileInput(props: UploadFileInputProps) {
  const { onDownloadTemplateClick, onFileUpload, domainLabel, file, setFile } =
    props;
  const theme = useTheme();
  const dispatch = useDispatch();

  const hiddenFileInput =
    React.createRef() as React.RefObject<HTMLInputElement>;

  const handleFileUploadButtonClick = () => {
    if (hiddenFileInput.current) {
      const input = hiddenFileInput.current as HTMLInputElement;
      input.click();
    }
  };

  //Modal state
  const showConfirmationModal =
    useSelector<GlobalState>((state) => state.modal.currentModal) ===
    GlobalModals.UPLOAD_CONFIRMATION;

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      setFile(files[0]);
    }
  };

  const handleImportClick = React.useCallback(() => {
    dispatch(
      modalSlice.actions.setCurrentModal(GlobalModals.UPLOAD_CONFIRMATION)
    );
  }, [file]);

  const handleImportConfirmClick = React.useCallback(() => {
    if (file) {
      onFileUpload(file);
    }
    dispatch(modalSlice.actions.reset());
  }, [file]);

  const handleImportCancelClick = React.useCallback(() => {
    setFile(null);
    dispatch(modalSlice.actions.reset());
  }, [file]);

  return (
    <>
      <Stack sx={UploadFileInputContainerSx}>
        <Stack sx={UploadFileInputHeaderSx}>
          <Typography fontWeight="500" variant="subtitle2">
            {UPLOAD_FILE_HEADER_COPY}
          </Typography>
          <Typography fontWeight="400" variant="body2">
            {UPLOAD_FILE_SUB_HEADER_COPY}
          </Typography>
        </Stack>
        <Stack sx={UploadFileInputContentSx}>
          {!file ? (
            <>
              <Button
                gaContext={{
                  textCopy: 'Choose file',
                  navigates_to: 'File selection from computer',
                  purpose:
                    'Enables user to select a file from their computer as input',
                }}
                id="upload-file-button"
                color="secondary"
                onClick={handleFileUploadButtonClick}
                sx={UploadFileInputButtonSx}
              >
                <Typography
                  className="file-upload-button-text"
                  variant="button"
                >
                  {UPLOAD_FILE_UPLOAD_BUTTON_COPY}
                </Typography>
              </Button>
              <input
                ref={hiddenFileInput}
                type="file"
                onChange={handleFileChange}
                style={FileInputSx}
              />
              <Stack direction="row" spacing={1}>
                <Typography
                  variant="body2"
                  fontWeight="400"
                  color={theme?.palette?.colors?.gray60}
                >
                  {UPLOAD_FILE_MAX_ROWS_COPY}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  variant="body2"
                  fontWeight="400"
                  color={theme?.palette?.colors?.gray60}
                >
                  {UPLOAD_FILE_GUIDANCE_COPY}
                </Typography>
                <Link
                  onClick={onDownloadTemplateClick}
                  sx={UploadFileTemplateLinkSx}
                >
                  {UPLOAD_FILE_DOWNLOAD_TEMPLATE_LINK_COPY}
                </Link>
              </Stack>
            </>
          ) : (
            <Stack sx={UploadFileSelectedFileInfoSx}>
              <Stack direction="row" spacing={2}>
                <Avatar sx={{ bgcolor: theme.palette.colors.backgroundYO }}>
                  <UploadFile color="primary" />
                </Avatar>
                <Stack direction="column">
                  <Typography variant="subtitle1" fontWeight="600">
                    {file.name}
                  </Typography>
                  <Typography variant="body2" fontWeight="300">
                    {formatBytes(file.size)} • Complete
                  </Typography>
                </Stack>
              </Stack>
              <Box sx={CloseIconButtonSx}>
                <IconButton onClick={() => setFile(null)}>
                  <CloseSharp />
                </IconButton>
              </Box>
            </Stack>
          )}
        </Stack>
        <Stack direction="row" sx={UploadFileImportButtonContainerSx}>
          <Button
            gaContext={{
              textCopy: `Import ${domainLabel}`,
              navigates_to: 'File selection from computer',
              purpose:
                'Enables user to select a file from their computer as input',
            }}
            id={`import-${domainLabel}-button`}
            color="secondary"
            onClick={handleImportClick}
            sx={UploadFileImportButtonSx}
            disabled={!file}
          >
            <Typography className="import-button-text" variant="button">
              Import {domainLabel}
            </Typography>
          </Button>
        </Stack>
      </Stack>
      <UploadConfirmationModal
        isOpen={showConfirmationModal}
        handleCancel={handleImportCancelClick}
        handleContinue={handleImportConfirmClick}
      />
    </>
  );
}

export default UploadFileInput;
