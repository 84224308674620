import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const getComplexFilterBankStackSx = (
  buttonBoxClassName: string,
  clearButtonWidth: string,
): SxProps => ({
  mt: '8px',
  width: '100%',
  maxHeight: '58px',
  [`& .MuiBox-root.${buttonBoxClassName}`]: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    minHeight: '36px',
    width: clearButtonWidth,
  },
});

export const FilterBankPillBoxSX: SxProps = {
  display: 'flex',
  flex: 1,
  width: 'calc(100% - 200px)',
  height: '66px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    width: '4px',
    WebkitBoxShadow: '0 0 1px rgba(255,255,255,.2)',
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: '10px',
    backgroundColor: 'rgba(0,0,0,.1)',
    WebkitBoxShadow: '0 0 1px rgba(255,255,255,.8)',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '11px',
    backgroundColor: 'rgba(0,0,0,.5)',
    WebkitBoxShadow: '0 0 1px rgba(255,255,255,.5)',
  },
};

export const FilterBankBoundingStackSx: SxProps = {
  boxSizing: 'border-box',
  display: 'flex',
  flexWrap: 'wrap',
  width: 'calc(100% - 4px)',
  '& .MuiBox-root': {
    width: '100%',
  },
};

export const ComplexFilterClearAllSx: SxProps = {
  px: '12px',
  py: '6px',
  color: theme.palette.colors.offBlack,
  '&:hover': {
    opacity: 0.6,
  },
  '& .MuiTypography-root': {
    fontSize: '14px',
    fontWeight: 600,
  },
  '& .MuiButton-startIcon': {
    mr: '4px',
  },
};
