import { SxProps } from '@mui/material';

export const EmployeeSearchTextField: SxProps = {
  padding: '0',
  backgroundColor: 'transparent',
  '&.MuiTextField-root.MuiFormControl-root': {
    borderRadius: '0',
    borderBottom: '1px solid #000',
  },
};
