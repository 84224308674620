/* eslint-disable sort-exports/sort-exports */
import Grid from '@mui/material/Grid';
import { RetrievalOptionFormProps } from './types';
import { OptionValueFormSx } from './styles';
import RetrievalOptionValueInput from 'pages/Retrieval/components/ReviewStep/components/RetrievalOptionValueInput';

const RetrievalOptionValueForm = (props: RetrievalOptionFormProps) => {
  if (!props.retrievalOptions?.length) return null;

  return (
    <Grid container sx={OptionValueFormSx}>
      {props.retrievalOptions.flatMap((option, index) => {
        return (
          <Grid
            item
            xs={12}
            key={`${option.type}-${index < 10 ? '0' : ''}${index}-${
              props.collaboratorId
            }`}
          >
            <RetrievalOptionValueInput
              retrievalAnswer={props.retrievalAnswers[index]}
              answerRetrievalOption={props.answerRetrievalOption}
              {...option}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default RetrievalOptionValueForm;
