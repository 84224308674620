import { gql } from '@apollo/client';

export const DELETE_COLLABORATOR_BY_ID = gql`
  mutation deleteCollaborator($id: String!) {
    deleteCollaborator(id: $id) {
      success
      message
    }
  }
`;
