import React from 'react';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { StatusHistoryStep } from 'types';
import { GenericVerticalStepper } from 'components';
import { DetailViewContentContainerSx } from 'pages/OrderManagement/components/DetailedOrderView/styles';
import { OrderStatusStepperProps } from './types';
import * as OrderStatusStepperStyles from './styles';
/* eslint-disable sort-exports/sort-exports */

const OrderStatusStepper = (props: OrderStatusStepperProps) => {
  const styles = OrderStatusStepperStyles;
  if (!props.steps.length) return null;

  const getStepLabelClassName = (keyString: string): string =>
    `${keyString.toLowerCase().replaceAll('_', '-')}-icon-bespoke-label`;

  const getStepContents = (paramSteps: StatusHistoryStep[]): JSX.Element[] =>
    paramSteps.map((step: any, _index: number) => {
      const { stepKey, stepLabelCopy, createdAt, stepIcon, iconColor } = step;

      return (
        <Step key={stepKey} active>
          <StepLabel
            sx={styles.getStepperLabelSx(iconColor)}
            StepIconComponent={stepIcon}
            className={getStepLabelClassName(stepKey)}
          >
            <Typography>{stepLabelCopy}</Typography>
            <Typography variant="caption">{createdAt}</Typography>
          </StepLabel>
        </Step>
      );
    });

  return (
    <GenericVerticalStepper
      mainTitleSx={styles.StepperMainTitleSx}
      stepperBoxSx={DetailViewContentContainerSx}
      steps={props.steps}
      renderStepsFunction={getStepContents}
      stepperTitle={props.stepperTitle}
      activeStep={props.steps.length - 1}
    />
  );
};

export default OrderStatusStepper;
