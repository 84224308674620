import { CopyListItem } from './types';

export const RETRIEVAL_MODAL_COPY: Record<string, string | CopyListItem[]> = {
  title: 'Retrievals made easier',
  subtitle:
    "Today we're launching updates to make retrievals quicker and more intuitive, allowing you to offboard employees and retrieve equipment with ease.",
  listIntro: 'Our new retrieval workflow includes:',
  list: [
    {
      bold: 'Intuitive navigation:',
      text: ' Access retrievals from anywhere within the allwhere platform',
    },
    {
      bold: 'Employee-to-equipment matching:',
      text: 'View employees and their currently assigned equipment and add or remove accordingly',
    },
    {
      bold: 'Customizable retrievals:',
      text: 'Modify devices and add valuable information as necessary to speed up the process',
    },
    {
      bold: 'Immediate address collection:',
      text: 'Add addresses directly, bypassing the need to request information from employees',
    },
  ],
  textFAQ: 'For more information about retrievals, please visit our',
  linkFAQ:
    'https://faq.allwhere.co/en_us/retrieval-rework-release-notes-BJuBXTY3',
};

export const RETRIEVAL_MODAL_SEEN = 'retrievalModalSeen';
