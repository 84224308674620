/* eslint-disable sort-exports/sort-exports */
import { SxProps } from '@mui/material';
import theme from 'themes/theme';

const buttonBaseSx = {
  fontVariant: 'button',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '27px',
  m: 0,
};

export const AssetDialogSx = {
  '& .MuiDialog-paper': {
    borderRadius: '24px',
    p: '24px',
  },
  '&: .MuiFormHelperText-root': {
    position: 'absolute',
    bottom: '-20px',
  },
};

export const AssetDialogPaperSx = {
  height: {
    xs: '100vh',
    sm: '100vh',
    md: '513px',
    lg: '513px',
    xl: '513px',
  },
  maxWidth: '760px',
  width: '100vw',
};

export const AssetMainTitleSx = {
  fontWeight: 700,
  height: '100%',
  lineHeight: '38.4px',
  position: 'relative',
};

export const AssetOuterFormStackSx = {
  boxSizing: 'border-box',
  pt: '25px',
  width: '100%',
};

export const AssetInnerFormStackSx = {
  position: 'relative',
  width: '100%',
};

export const AssetTextFieldBaseSx = {
  backgroundColor: 'transparent',
  p: 0,
  '&: .MuiFormHelperText-root': {
    color: 'transparent',
    position: 'absolute',
    bottom: '-20px',
  },
};

export const AssetCancelButtonSx = {
  ...buttonBaseSx,
  '&: hover': {
    backgroundColor: 'transparent',
  },
};

export const AssetSaveButtonSx = {
  ...buttonBaseSx,
  boxShadow: 2,
  '&:hover': {
    backgroundColor: theme.palette.colors.maizeYO,
  },
};
