/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { HomeListItemProps, LinkItemTuple } from './types';
import * as HomeListItemStyles from './styles';

const HomeListItem = (props: HomeListItemProps) => {
  const styles = HomeListItemStyles;
  const listItemSx = {
    ...styles.HomeListItemDefaultSx,
    ...(props?.listItemSx ?? {}),
  };

  const { listItemLinkText = '', listItemLinkURL = '' } = props;
  const hasLinkText = [listItemLinkText, listItemLinkURL].every(Boolean);

  const getSubListItem = (subListItem: string | LinkItemTuple) => {
    if (typeof subListItem === 'string') {
      return (
        <ListItemText sx={styles.getSubListItemSx(props.subListHasBullets)}>
          {subListItem}
        </ListItemText>
      );
    }

    const [url, urlText, startCopy, endCopy, options] = subListItem;

    let hasLinkBullet = props.subListHasBullets
      ? props.subListHasBullets
      : false;
    hasLinkBullet = options?.hasBullet ?? false;

    const listSx = styles.getSubListItemSx(
      hasLinkBullet,
      options?.linkItemSx ?? {}
    );

    const getEndCopy = () => {
      if (!endCopy) {
        return '';
      }

      if (endCopy.length === 1) {
        return endCopy;
      }

      return ` ${endCopy}`;
    };

    return (
      <ListItemText sx={listSx}>
        {startCopy ? `${startCopy} ` : ''}
        <Link
          sx={{ fontWeight: 600 }}
          href={url}
          target="_blank"
          rel="noopener"
          color="colors.strongBlue"
          underline="none"
        >
          {urlText}
        </Link>
        {getEndCopy()}
      </ListItemText>
    );
  };

  return (
    <>
      <Divider />
      <ListItem sx={listItemSx}>
        {props.hasWarningIcon ? <WarningAmberIcon /> : <></>}
        {props.listItemDate ? (
          <Typography className="list-item-date" variant="overline">
            {props.listItemDate}
          </Typography>
        ) : (
          <></>
        )}
        {props.listItemTitle ? (
          <Typography className="list-item-title">
            {props.listItemTitle}
          </Typography>
        ) : (
          <></>
        )}
        {hasLinkText ? (
          <Link
            color="secondary"
            underline="none"
            href={listItemLinkURL}
            target="_blank"
          >
            <Typography className="list-item-link-text">
              {listItemLinkText}
            </Typography>
          </Link>
        ) : (
          <></>
        )}
        {props.listItemSubList
          ? props.listItemSubList.map(getSubListItem)
          : null}
        {props.listItemDetails ? (
          <Typography className="list-item-details">
            {props.listItemDetails}
          </Typography>
        ) : (
          <></>
        )}
      </ListItem>
    </>
  );
};

export default HomeListItem;
