import { ApolloLink } from '@apollo/client';
import { getLocalStorageValue } from 'api/storage';

export const authMiddleware = new ApolloLink((operation, forward) => {
  const authToken = getLocalStorageValue('authToken');
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${authToken}`,
    },
  }));

  return forward(operation);
});
