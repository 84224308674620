export const RETAIL_FALLBACK_PRICE = 'N/A';

export const ZERO_ESTIMATED_TAX = 0.0;

export const RETRIEVAL = 'retrieval';

export const EARLIEST_DELIVERY_DATE = 'The earliest delivery date:';

export const PROCUREMENT_RECIPIENTS_SECTION_INFORMATION =
  'If you send a request, delivery will take longer, to get it faster, select an address from saved addresses.';

export const ORDER_ITEMS_LABEL = 'Order Items';

export const RECIPIENTS_LABEL = 'Recipient(s)';

export const SHIPPING_LABEL = 'Shipping & Processing';
