import {
  ApolloCache,
  DefaultContext,
  DocumentNode,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
} from '@apollo/client';
import { useDispatch } from 'react-redux';
import { notificationSlice } from 'store/slices';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';
import * as Sentry from '@sentry/react';

export const useSafeMutation = <
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>
>(
  mutation: DocumentNode | TypedDocumentNode<any, OperationVariables>,
  options?: MutationHookOptions<TData, TVariables, TContext, TCache> | undefined
): MutationTuple<TData, TVariables, TContext, TCache> => {
  const dispatch = useDispatch();

  return useMutation(mutation, {
    ...options,
    onError(error) {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: NotificationMessages.GENERIC_SOMETHING_WENT_WRONG,
        })
      );
    },
  });
};
