import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ViewTabsProps } from './types';
import { OrderView } from 'pages/OrderManagement/enum';
import { TabsSx } from './styles';

const ViewTabs = (props: ViewTabsProps) => {
  const handleOnChange = (_event: React.SyntheticEvent, value: OrderView) => {
    props.setViewTab(value);
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        sx={TabsSx}
        value={props.viewTab}
        onChange={handleOnChange}
        aria-label="order management view filter panel"
      >
        <Tab label="ALL ORDERS" value={OrderView.ALL_ORDERS} />
        <Tab label="ALL NEW ORDERS" value={OrderView.ALL_NEW_ORDERS} />
        <Tab label="PROCUREMENT" value={OrderView.PROCUREMENT} />
        <Tab label="KITS" value={OrderView.KITS} />
        <Tab label="RETRIEVALS" value={OrderView.RETRIEVALS} />
        <Tab label="REDEPLOYMENT" value={OrderView.REDEPLOYMENT} />
        <Tab label="CANCELLED" value={OrderView.CANCELLED} />
        <Tab label="EXPIRED" value={OrderView.EXPIRED} />
      </Tabs>
    </Box>
  );
};

export default ViewTabs;
