import { SxProps } from '@mui/material';
import theme from 'themes/theme';
import { HEADER_HEIGHT } from 'global-styles';

export const MainContainerSx: SxProps = {
  display: 'relative',
  mt: HEADER_HEIGHT,
  '@media (min-width: 1536px)': {
    maxWidth: '100%',
    width: '85%',
  },
};

export const MainHeaderSx: SxProps = {
  fontSize: '36px',
  fontWeight: 500,
};

export const DataGridBoxSx: SxProps = {
  height: 'calc(100vh - 335px)',
  marginTop: '0.75rem',
};

export const AddOrderButtonStylesSx: SxProps = {
  backgroundColor: theme.palette.colors.charlestonGreen,
  borderRadius: '30px',
  color: theme.palette.colors.white,
  height: '40px',
  width: '175px',
};

export const AddOrderButtonTextStylesSx: SxProps = {
  fontSize: '14px',
  fontWeight: 600,
};
