import { SxProps } from '@mui/material';
import theme from 'themes/theme';
import themeColors from 'themes/colors';
import { FontFamily } from 'enums';
import { DEPOT_TABLE_TITLE_ID } from './constants';

const { woodSmoke, gray75, transparentGrey, snow } = themeColors;

export const DepotTableContentsSx: SxProps = {
  p: {
    md: 0,
    lg: '24px',
  },
  [`& .MuiTypography-root#${DEPOT_TABLE_TITLE_ID}`]: {
    color: woodSmoke,
    fontFamily: FontFamily.PPP_GRAM_SANS,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: 'normal',
    mb: '24px',
  },
};

export const DepotTableBoxSx: SxProps = {
  cursor: 'pointer',
  '& .MuiDataGrid-row:not(.Mui-selected):hover': {
    backgroundColor: theme.palette.colors.merino,
    border: `2px solid transparent`,
    boxShadow: 'none',
  },

  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: theme.palette.colors.merino,
    border: `2px solid transparent`,
    boxShadow: 'none',
    '&:hover, &.Mui-hovered': {
      backgroundColor: theme.palette.colors.merino,
      border: `2px solid transparent`,
    },
    // '& .MuiDataGrid-root'
  },
  '& .MuiButtonBase-root.MuiRadio-root:not(.Mui-disabled)': {
    color: theme.palette.colors.woodSmoke,
  },
};
