/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';
import { OrderType } from 'global-constants';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import { OrderDetailFieldsSlice } from './slice';
import { GET_ALL_PURCHASERS_BY_ORG_ID, GET_ALL_ORDER_TYPES } from './queries';
import { CreateOrderState } from './types';

const useCreateOrderState = () => {
  const orderTypesSelectable = Object.entries(OrderType) as [
    orderTypeEnumField: string,
    orderTypeEnumValue: string
  ][];
  const [orderType, setOrderType] = React.useState<string>('');
  const [organizationId, setOrganizationId] = React.useState<string>('');
  const [purchasersSelectable, setPurchasersSelectable] = React.useState<
    [collaboratorId: string, collaboratorName: string][]
  >([]);
  const [purchaserId, setPurchaserId] = React.useState<string>('');
  const [orderTypeLookup, setOrderTypeLookup] = React.useState<
    Record<string, any>
  >({});

  const orderTypeEntries = Object.entries(OrderType);

  // Order Field Values
  const [orderDetailsFields, dispatch] = React.useReducer(
    OrderDetailFieldsSlice.reducer,
    OrderDetailFieldsSlice.getInitialState()
  );
  const { hasAssets, hasProducts, toDepot, toRecipient, toDropRecipient } =
    orderDetailsFields;

  const [fetchAllOrderTypes] = useSafeLazyQuery(GET_ALL_ORDER_TYPES, {
    onCompleted: ({ getAllOrderTypes = [] }) => {
      if (getAllOrderTypes.length === 0) return;
      const orderTypeValuesLookup = getAllOrderTypes.reduce(
        (acc: Record<string, any>, orderType: any) => {
          const { name, ...restOfOrderType } = orderType;
          acc[name] = restOfOrderType;
          return acc;
        },
        {} as Record<string, any>
      );
      setOrderTypeLookup(() =>
        orderTypeEntries.reduce((acc, [field, value]) => {
          const tempInfo = orderTypeValuesLookup[value];
          acc[field] = { matchValue: value, ...tempInfo };
          acc[value] = { matchValue: field, ...tempInfo };
          return acc;
        }, {} as Record<string, any>)
      );
    },
  });

  const getOrderTypeId = (): string => {
    if (!orderType || !(orderType in orderTypeLookup)) return '';
    return orderTypeLookup[orderType].id;
  };

  const [
    fetchOrgPurchasers,
    { loading: loadingPurchasers, data: purchasersData },
  ] = useSafeLazyQuery(GET_ALL_PURCHASERS_BY_ORG_ID, {
    onCompleted: (data) => {
      const { getAllCollaboratorsByOrgId = [] } = data;
      if (getAllCollaboratorsByOrgId.length === 0) return;
      const selectablePurchasers = getAllCollaboratorsByOrgId
        .slice()
        .sort((a: any, b: any) =>
          a?.lastName
            .toLowerCase()
            .replace(' ', '')
            .localeCompare(b?.lastName.toLowerCase().replace(' ', ''))
        )
        .map((collaborator: any) => {
          const { id, firstName, lastName } = collaborator;
          return [id, `${firstName} ${lastName}`];
        });
      setPurchasersSelectable(selectablePurchasers);
    },
  });

  const getPurchaser = (collaboratorId: string = '') => {
    const searchId = collaboratorId || purchaserId;
    const matchingPurchaser = purchasersData.getAllCollaboratorsByOrgId.find(
      (purchaser: any) => purchaser.id === searchId
    );
    if (!matchingPurchaser)
      return { id: '', firstName: '', lastName: '', email: '' };
    return matchingPurchaser;
  };

  const isPurchaserDisabled = (): boolean =>
    purchasersSelectable.length === 0 || !organizationId;

  const resetPurchaser = () => {
    setPurchaserId('');
    setPurchasersSelectable([]);
  };

  const resetAllPendingOrderSelections = () => {
    setOrderType('');
    setOrganizationId('');
    resetPurchaser();
  };

  const getOrgPurchasers = () => {
    fetchOrgPurchasers({
      variables: {
        organizationId,
        isAdmin: true,
      },
    });
  };

  const setOrderDetailsByOrderType = (orderType: string) => {
    // eslint-disable-next-line security/detect-object-injection
    // if (!(orderType in FIELD_VALUES_BY_ORDER_TYPE))
    if (!(orderType in orderTypeLookup)) {
      throw new Error(
        `Order type "${orderType}" did not have matching field values.`
      );
    }

    const matchingRow =
      orderTypeLookup[orderType as keyof typeof orderTypeLookup];
    const { id, matchValue, ...restOfOrderTypeLookup } = matchingRow;

    dispatch(
      OrderDetailFieldsSlice.actions.setFieldValues(restOfOrderTypeLookup)
    );
  };

  const resetOrderDetailFields = () => {
    dispatch(OrderDetailFieldsSlice.actions.reset());
  };

  React.useEffect(() => {
    if (!orderType) return;
    setOrderDetailsByOrderType(orderType);
  }, [orderType]);

  React.useEffect(() => {
    if (orderType) return;
    resetOrderDetailFields();
  }, [orderType]);

  React.useEffect(() => {
    resetAllPendingOrderSelections();
    fetchAllOrderTypes();
  }, []);

  React.useEffect(() => {
    if (organizationId) return;
    resetPurchaser();
  }, [organizationId]);

  React.useEffect(() => {
    if (!organizationId) return;
    getOrgPurchasers();
  }, [organizationId]);

  const isUseCreateOrderStateReady = (): boolean => {
    const selectablesReady = [orderTypesSelectable].every(
      (array: any[]) => array.length > 0
    );
    const orderLookupReady =
      orderTypeLookup && Object.keys(orderTypeLookup).length > 0;
    return selectablesReady && orderLookupReady;
  };

  return {
    orderTypeLookup,
    // field-values
    // - booleans
    hasAssets,
    hasProducts,
    toDepot,
    toRecipient,
    toDropRecipient,
    // - strings
    orderType,
    organizationId,
    purchaserId,
    purchasersSelectable,
    orderTypesSelectable,
    // loading statuses
    loadingPurchasers,
    // functions & useStates
    isUseCreateOrderStateReady,
    getOrderTypeId,
    getPurchaser,
    setOrderDetailsByOrderType,
    resetAllPendingOrderSelections,
    isPurchaserDisabled,
    resetPurchaser,
    setOrderType,
    setOrganizationId,
    setPurchasersSelectable,
    setPurchaserId,
  } as CreateOrderState;
};

export default useCreateOrderState;
