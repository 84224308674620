import React from 'react';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FooterProps } from './types';
import { PaperSx } from './styles';
import {
  calculateSalesTax,
  calculateServiceFee,
  calculateShippingCost,
  calculateSubtotal,
} from './utils';
import { formatNumberToDecimalString } from 'services/format';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import { FeatureFlagNames } from 'enums/feature-flags';

const Footer = (props: FooterProps) => {
  const { isFlagOn: isPremiumShippingOn } = useFeatureFlagService(
    FeatureFlagNames.PREMIUM_SHIPPING
  );
  const getSubtotal = calculateSubtotal(props.optionAnswerKey);

  const subtotal = getSubtotal(
    props.employees,
    props.assetPrices,
    props.retrievalRouteId
  );
  const formattedSubtotal = formatNumberToDecimalString(subtotal);

  const serviceFee = calculateServiceFee(subtotal);
  const formattedServiceFee = formatNumberToDecimalString(serviceFee);

  const expeditedCharge = isPremiumShippingOn() ? 40 : 35;

  // remove the "expeditedCharge" parameter once Premium Shipping is fully released
  const shippingCost = calculateShippingCost(props.employees, expeditedCharge);
  const formattedShippingCost = formatNumberToDecimalString(shippingCost);

  const salesTax = calculateSalesTax(subtotal, serviceFee);
  const formattedSalesTax = formatNumberToDecimalString(salesTax);

  const lowTotal = formatNumberToDecimalString(
    subtotal + serviceFee + shippingCost
  );
  const highTotal = formatNumberToDecimalString(
    subtotal + serviceFee + shippingCost + salesTax
  );

  return (
    <Paper sx={PaperSx} square component="footer" variant="outlined">
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        columnSpacing={2}
      >
        <Grid item xs={6} sm={7} md={9} justifySelf="flex-end">
          <Typography align="right">Subtotal (est)</Typography>
        </Grid>
        <Grid item xs={6} sm={5} md={3}>
          <Typography align="right">${formattedSubtotal}</Typography>
        </Grid>
        <Grid item xs={6} sm={7} md={9} justifySelf="flex-end">
          <Typography align="right">Service fee (est)</Typography>
        </Grid>
        <Grid item xs={6} sm={5} md={3}>
          <Typography align="right">${formattedServiceFee}</Typography>
        </Grid>
        <Grid item xs={6} sm={7} md={9} justifySelf="flex-end">
          <Typography align="right">Shipping (est)*</Typography>
        </Grid>
        <Grid item xs={6} sm={5} md={3}>
          <Typography align="right">${formattedShippingCost}</Typography>
        </Grid>
        <Grid item xs={6} sm={7} md={9} justifySelf="flex-end">
          <Typography align="right">Tax (est)*</Typography>
        </Grid>
        <Grid item xs={6} sm={5} md={3}>
          <Typography align="right">$0.00 - ${formattedSalesTax}</Typography>
        </Grid>
      </Grid>
      <Divider id="review-page-footer-divider" />
      <Grid container direction="row" justifyContent="flex-end">
        <Grid item xs={5} sm={7} md={9} justifySelf="flex-end">
          <Typography align="right" fontWeight={600}>
            Total*
          </Typography>
        </Grid>
        <Grid item xs={7} sm={5} md={3}>
          <Typography align="right" fontWeight={600}>
            ${lowTotal} - ${highTotal}
          </Typography>
        </Grid>
        <Grid xs={0} md={6}></Grid>
        <Grid item mt={2} xs={12} md={6}>
          <Typography variant="caption" component="p" align="right">
            * Final shipping and tax depends on recipient's location
            <br />
            By submitting the order, you agree to{' '}
            <Link
              href="https://www.allwhere.co/terms"
              target="_blank"
              rel="noopener"
              color="secondary"
            >
              terms and conditions
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Footer;
