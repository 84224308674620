import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Modal from 'components/Modal';
import { SentEquipmentConfirmationModalProps } from './types';
import handshake from 'assets/Handshake.png';
import GAIconButton from 'components/buttons/google_analytics/GAIconButton';

const CLOSE_MODAL_AFTER_SECONDS = 6000;

const SentEquipmentConfirmationModal = (
  props: SentEquipmentConfirmationModalProps
) => {
  function closeModal() {
    props.handleClose();
  }

  setTimeout(closeModal, CLOSE_MODAL_AFTER_SECONDS);

  return (
    <Modal
      open={props.open}
      modalBoxSXProps={{
        display: 'flex',
        flexDirection: 'column',
        height: '436px',
        justifyContent: 'flex-start',
        maxWidth: '100vw',
        width: '608px',
        '@media (max-width:500px)': {
          height: '100vh',
        },
      }}
    >
      <>
        <Modal.Header hideHeaderDivider>
          <GAIconButton
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Close icon',
              purpose: 'Closes Sent Equipment Confirmation modal',
            }}
            aria-label="close-retrieval-confirmation-modal"
            onClick={props.handleClose}
            sx={{
              bgcolor: 'theme.palette.colors.gray75',
              position: 'absolute',
              right: '18px',
              top: '18px',
            }}
          >
            <CloseIcon />
          </GAIconButton>
        </Modal.Header>
        <Modal.Body>
          <Stack alignItems="center">
            <img
              src={handshake}
              alt="allwhere=hand-shake"
              height="190px"
              width="204px"
            />
          </Stack>
          <Typography
            variant="h4"
            color="colors.mediumGreen"
            sx={{
              fontWeight: 700,
              fontSize: '36px',
              lineHeight: '41px',
              textAlign: 'center',
              marginTop: '1rem',
            }}
          >
            Thanks for your order!
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 500,
              fontSize: '18px',
              lineHeight: '21px',
              textAlign: 'center',
              marginTop: '0.5rem',
            }}
          >
            We'll reach out to your recipients shortly to collect their details.
          </Typography>
        </Modal.Body>
      </>
    </Modal>
  );
};

export default SentEquipmentConfirmationModal;
