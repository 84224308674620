import React from 'react';
import Pill from 'components/Pill';
import { OrderStatusPillProps } from './types';
import { useGetOrderStatusConfig } from './hooks/useGetOrderStatusConfig';

const OrderStatusPill = (props: OrderStatusPillProps) => {
  const showStatusName = props.showStatusName ?? false;
  const showClientStatus = props.showClientStatus ?? false;
  const config = useGetOrderStatusConfig(props.status, {
    showStatusName,
    showClientStatus,
  });

  return (
    <Pill
      size="small"
      toolTipArrow
      text={config.text}
      color={config.color}
      textColor={config.textColor}
      toolTipHoverText={config.hoverText}
      toolTipPlacement={props.toolTipPlacement ?? 'right'}
    />
  );
};

export default OrderStatusPill;
