import { gql } from '@apollo/client';

export const FETCH_RETRIEVAL_PRICING = gql`
  query retrievalPricing(
    $retrievalRouteId: ID!
    $assetTypeName: AssetTypeName!
  ) {
    retrievalPricing(
      retrievalPricingSearchInput: {
        retrievalRouteId: $retrievalRouteId
        assetTypeName: $assetTypeName
      }
    ) {
      id
      assetTypeId
      retrievalRouteId
      price
    }
  }
`;
