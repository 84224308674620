import { createSlice } from '@reduxjs/toolkit';

const initialComboBoxState = {
  searchedQueryVariable: {},
  complexEntry: null,
  clearFilters: false,
};

const comboBoxFilterSlice = createSlice({
  name: 'ComboBoxFilterSlice',
  initialState: initialComboBoxState,
  reducers: {
    setSearchQueryVariableReducer: (state, action) => {
      state.searchedQueryVariable = action.payload;
    },
    setComplexEntryReducer: (state, action) => {
      state.complexEntry = action.payload;
    },
    clearComplexEntryReducer: (state) => {
      state.complexEntry = initialComboBoxState.complexEntry;
    },
    setClearFiltersReducer: (state, action) => {
      state.clearFilters = action.payload;
    },
    resetAllReducer: (state) => {
      state.complexEntry = initialComboBoxState.complexEntry;
      state.searchedQueryVariable = initialComboBoxState.searchedQueryVariable;
      state.clearFilters = initialComboBoxState.clearFilters;
    },
  },
});

export const {
  setSearchQueryVariableReducer: setSearchQueryVariable,
  setClearFiltersReducer: setClearFilters,
  setComplexEntryReducer: setComplexEntry,
  clearComplexEntryReducer: clearComplexEntry,
  resetAllReducer: resetComboBoxFilter,
} = comboBoxFilterSlice.actions;
export default comboBoxFilterSlice;
