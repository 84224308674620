import {
  Box,
  FormControlLabel,
  Link,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { PremiumShippingRadioBox } from 'components';
import * as ShippingInformationStyles from './styles';
import * as OrderSummaryStyles from '../../styles';
import {
  ALLWHERE_PREMIUM_FULFILLMENT_SPEEDS_COPY,
  ALLWHERE_PREMIUM_FULFILLMENT_SPEEDS_LINK_COPY,
  ALLWHERE_PREMIUM_FULFILLMENT_SPEEDS_LINK_URL,
} from 'global-constants';
import { ShippingInformationProps } from './types';
import { getPremiumShippingDisplayInfo } from '../../utils';

export const ShippingInformation = (props: ShippingInformationProps) => {
  const styles = ShippingInformationStyles;
  const parentStyles = OrderSummaryStyles;

  const showPremiumShippingOptions = () => {
    const hasShippingOptions = props.availableShippingTypeNames.length > 0;
    return [hasShippingOptions, props.hasShippingInfo].every(
      (condition: boolean) => !!condition
    );
  };

  return (
    <>
      <Box sx={styles.ShippingAndProcessingBoxSx}>
        <Stack spacing={0.75}>
          <Typography sx={parentStyles.SectionHeaderSx}>
            Shipping & processing
          </Typography>

          <Typography className="new-assign-flow-question-sub-copy">
            {`${ALLWHERE_PREMIUM_FULFILLMENT_SPEEDS_COPY}${' '}`}
            <Link
              color="secondary"
              href={ALLWHERE_PREMIUM_FULFILLMENT_SPEEDS_LINK_URL}
              target="_blank"
            >
              {ALLWHERE_PREMIUM_FULFILLMENT_SPEEDS_LINK_COPY}
            </Link>
          </Typography>
        </Stack>
        {props.hasShippingInfo && (
          <RadioGroup
            row
            name="premium-shipping-type"
            value={props.shippingTypeId}
            sx={styles.NewShippingRadioGroupSx}
            onChange={props.onShippingRadioButtonChange}
          >
            {showPremiumShippingOptions()
              ? props.availableShippingTypeNames.map((typeName: string) => {
                  const premiumCopyInfo = getPremiumShippingDisplayInfo(
                    typeName,
                    { lookup: props.shippingInfoTable }
                  );

                  return (
                    <FormControlLabel
                      label=""
                      key={premiumCopyInfo.shippingTypeId}
                      value={premiumCopyInfo.shippingTypeId}
                      control={
                        <PremiumShippingRadioBox
                          selectedShippingTypeId={props.shippingTypeId}
                          shippingTypeId={premiumCopyInfo.shippingTypeId}
                          title={premiumCopyInfo.title}
                          subTexts={premiumCopyInfo.subTexts}
                          bottomText={premiumCopyInfo.bottomText}
                          bottomSubText={premiumCopyInfo.bottomSubText}
                        />
                      }
                    />
                  );
                })
              : null}
          </RadioGroup>
        )}
      </Box>
    </>
  );
};
