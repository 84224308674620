/* eslint-disable sonarjs/cognitive-complexity */
import { Container, SelectChangeEvent, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid-pro';
import Avatar from 'components/Avatar';
import * as RecipientsDataGridStyles from '../../styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Collaborator, SelectableRecipientAddress } from 'types';
import {
  DeleteOutlined,
  HomeOutlined,
  SvgIconComponent,
  WorkOutline,
} from '@mui/icons-material';
import Dropdown from 'components/Dropdown';
import { DropdownItem } from 'components/Dropdown/types';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { ProcurementFlowEmailType } from 'pages/Procurement/hooks/useProcurementService/constants';
import { getAddressIcon, getAddressLabel, isAddressIncomplete } from 'services';
import { SendPaperPlaneOutlined } from 'components/Icons/SendPaperPlaneSVGIcon';
import { SelectedCollaborator } from 'pages/Procurement/hooks/useProcurementService/types';

type CollaboratorEmail = {
  icon: SvgIconComponent;
  label: string;
};

export const useGetRecipientsColumns = (
  emailType: ProcurementFlowEmailType,
  singleEmailChangeHandler: (collaboratorSelectedEmailMapping: {
    collaboratorId: string;
    email: string;
  }) => void,
  allEmailChangeHandler: (type: ProcurementFlowEmailType) => void,
  removeRecipientHandler: (collaborator: Collaborator) => void,
  addressChangeHandler: (
    collaboratorId: string,
    address: SelectableRecipientAddress
  ) => void,
  addressAddHandler: (
    collaboratorId: string,
    address?: SelectableRecipientAddress
  ) => void
): GridColDef[] => {
  const styles = RecipientsDataGridStyles;
  const theme = useTheme();

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const contactEmailOptions: DropdownItem<string>[] = [
    {
      label: 'Personal Email',
      icon: HomeOutlined,
      value: ProcurementFlowEmailType.PERSONAL,
    },
    {
      label: 'Work Email',
      icon: WorkOutline,
      value: ProcurementFlowEmailType.WORK,
    },
  ];

  const getCollaboratorEmailItems = (
    collaborator: Collaborator
  ): DropdownItem<string>[] => {
    const collaboratorEmails: DropdownItem<string>[] = [];
    if (collaborator.personalEmail) {
      collaboratorEmails.push({
        label: collaborator.personalEmail,
        icon: HomeOutlined,
        value: collaborator.personalEmail,
      });
    }
    if (collaborator.workEmail) {
      collaboratorEmails.push({
        label: collaborator.workEmail,
        icon: WorkOutline,
        value:
          collaborator.workEmail === collaborator.personalEmail
            ? `${collaborator.workEmail}@work`
            : collaborator.workEmail,
      });
    }
    if (
      collaboratorEmails.length === 0 ||
      (!collaborator.personalEmail &&
        collaborator.email !== collaborator.workEmail)
    ) {
      collaboratorEmails.push({
        icon: HomeOutlined,
        label: collaborator.email,
        value: collaborator.email,
      });
    }
    return collaboratorEmails;
  };

  const defaultAddressOption: DropdownItem<SelectableRecipientAddress> = {
    icon: SendPaperPlaneOutlined as SvgIconComponent,
    label: 'Request address confirmation',
    value: {} as SelectableRecipientAddress,
  };

  const getAddressDisplay = (address: SelectableRecipientAddress) => {
    return (
      <span>
        {`${getAddressLabel(address)};`}{' '}
        <span style={address.phoneNumber ? {} : { color: 'red' }}>
          {address.phoneNumber
            ? `Phone: ${address.phoneNumber}`
            : 'Missing phone number'}
        </span>
      </span>
    );
  };

  const getCollaboratorAddressItems = (
    collaborator: SelectedCollaborator
  ): DropdownItem<SelectableRecipientAddress>[] => {
    return [
      defaultAddressOption,
      ...(collaborator.selectableAddresses
        ? collaborator.selectableAddresses.map(
            (address: SelectableRecipientAddress) => ({
              label: getAddressDisplay(address),
              icon: getAddressIcon(address),
              value: address,
            })
          )
        : []),
    ];
  };

  const handleEmailChange =
    (collaboratorId: string) =>
    (event: SelectChangeEvent<CollaboratorEmail>) => {
      singleEmailChangeHandler({
        collaboratorId,
        email: event.target.value as string,
      });
    };

  const handleEmailTypeChange = (event: SelectChangeEvent<string>) => {
    allEmailChangeHandler(event.target.value as ProcurementFlowEmailType);
  };

  const handleAddressChange =
    (collaboratorId: string) =>
    (event: SelectChangeEvent<SelectableRecipientAddress>) => {
      const address: SelectableRecipientAddress = event.target
        .value as SelectableRecipientAddress;
      if (address.id && isAddressIncomplete(address)) {
        addressAddHandler(collaboratorId, address);
        return;
      }
      addressChangeHandler(collaboratorId, address);
    };

  return [
    {
      field: 'recipientName',
      headerName: 'Recipient',
      flex: 1,
      renderCell: (data: any) => {
        const { firstName, lastName, avatar } = data.row;
        return (
          <Stack direction="row" spacing={1}>
            {avatar && (
              <Avatar
                alt={`${firstName} ${lastName}`}
                src={avatar}
                width={24}
                height={24}
                color={theme.palette.colors.white}
                bgcolor={theme.palette.colors.grayBD}
                text=""
              />
            )}
            <Typography variant="body1">
              {`${firstName} ${lastName}`}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'contactEmail',
      headerName: 'Contact email',
      renderHeader: () => {
        return (
          <Dropdown
            type="Menu"
            items={contactEmailOptions}
            selectedItem={emailType}
            handleSearchOptionChange={handleEmailTypeChange}
            showCheckIcon={true}
            dropdownSx={styles.RecipientsDataGridContactEmailDropdownSx}
            dropdownItemSx={styles.RecipientsDataGridContactEmailDropdownItemSx}
          >
            <Typography sx={styles.RecipientsDataGridContactEmailHeaderSx}>
              Contact email
              <ArrowDropDownIcon />
            </Typography>
          </Dropdown>
        );
      },
      flex: isLargeScreen ? 2 : 1,
      renderCell: (data: any) => {
        const collaboratorEmailItems = getCollaboratorEmailItems(data.row);
        const defaultingToPersonal =
          emailType === ProcurementFlowEmailType.PERSONAL &&
          data.row.selectedEmail !== data.row.workEmail;
        const error =
          !defaultingToPersonal && !!emailType && !data.row[`${emailType}`];
        return (
          <Dropdown
            type="Select"
            items={collaboratorEmailItems}
            selectedItem={data.row.selectedEmail}
            handleSearchOptionChange={handleEmailChange(data.row.id)}
            dropdownSx={styles.RecipientsDataGridContactEmailDropdownSx}
            error={error}
          />
        );
      },
    },
    {
      field: 'shippingAddress',
      headerName: 'Shipping Address',
      headerClassName: 'shipping-address-header',
      flex: isLargeScreen ? 2.5 : 1,
      renderCell: (data: any) => {
        const collaboratorAddressItems = getCollaboratorAddressItems(data.row);
        return (
          <Dropdown
            type="Select"
            items={collaboratorAddressItems}
            selectedItem={
              data.row.selectedAddress || defaultAddressOption.value
            }
            handleSearchOptionChange={handleAddressChange(data.row.id)}
            dropdownSx={styles.RecipientsDataGridAddressDropdownSx}
            showCheckIcon={true}
            allowAddSelectItem={true}
            addSelectItemText="Add address manually"
            handleAddSelectItem={() => addressAddHandler(data.row.id)}
          />
        );
      },
    },
    {
      field: 'removeItem',
      headerName: '',
      flex: 0.25,
      renderCell: (data: any) => (
        <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={() => removeRecipientHandler(data.row)}>
            <DeleteOutlined />
          </IconButton>
        </Container>
      ),
    },
  ];
};
