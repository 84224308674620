/* eslint-disable sort-exports/sort-exports */
import { useSelector } from 'react-redux';
import { Avatar as MuiAvatar } from '@mui/material';
import Typography from '@mui/material/Typography';
import FilterCounterBadgeProps from './types';
import * as FilterCounterBadgeStyles from './styles';

const FilterCounterBadge = (props: FilterCounterBadgeProps) => {
  const styles = FilterCounterBadgeStyles;
  const getFilterEntries = props.selectAllChosenEntries(props.filterSubTree);

  const selectFilterEntries = useSelector(getFilterEntries) || [];

  const filterCount = selectFilterEntries.length;

  if (!filterCount) return <></>;

  const selectFilterBadgeTestId =
    props?.selectFilterBadgeTestId ??
    `${props.filterSubTree}-select-filter-badge`;

  const selectFilterBadgeSx = {
    ...styles.FilterBadgeCountIconSx,
    ...(props?.selectFilterBadgeSx ?? {}),
  };

  return (
    <MuiAvatar sx={selectFilterBadgeSx} data-testid={selectFilterBadgeTestId}>
      <Typography>{filterCount}</Typography>
    </MuiAvatar>
  );
};

export default FilterCounterBadge;
