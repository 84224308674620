import { DETAILS_HEADER_HEIGHT } from '../../UploadErrorDetails/styles';

export const UploadErrorsHeaderSx = {
  width: 'auto',
  height: DETAILS_HEADER_HEIGHT,
  gap: '4px',
  mt: '16px',
};

export const BackButtonSx = {
  pl: '4px',
  pb: '15px',
  display: 'flex',
  alignItems: 'center',
};

export const BackButtonTextSx = {
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '24px',
};

export const UploadErrorsFileNameTextSx = {
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '28px',
  letterSpacing: '0.15px',
};

export const DownloadErrorsButtonSx = {
  border: '1px solid #131414',
  borderRadius: '30px',
  mt: '8px',
  width: '190px',
  height: '36px',
  gap: '4px',
  display: 'flex',
  justifyContent: 'space-between',

  '.MuiSvgIcon-root': {
    ml: '8px',
  },
};

export const DownloadErrorsButtonTextSx = {
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '24px',
  position: 'absolute',
  ml: '18%',
};
