import { gql } from '@apollo/client';

export const SEARCH_ORGANIZATIONS = gql`
  query searchOrganizations(
    $name: String
    $offset: Int
    $limit: Int
    $orderAsc: String
    $orderDesc: String
  ) {
    searchOrganizations(
      organizationSearchInput: {
        name: $name
        offset: $offset
        limit: $limit
        orderAsc: $orderAsc
        orderDesc: $orderDesc
      }
    ) {
      organizations {
        id
        name
      }
      count
    }
  }
`;
