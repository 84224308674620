import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const SingleSelectOuterSx: SxProps = {
  mt: '16px',
};

export const SingleSelectInputSx: SxProps = {
  // borderBottom: '1px solid',
  borderRadius: 0,
  p: 0,
  maxWidth: '220px',
  '& .MuiSelect-select': {
    width: '240px',
  },

  '& .MuiFormLabel-root': {
    backgroundColor: theme.palette.colors.white,
    px: '5px',
  },
};
