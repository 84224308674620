import { AssetStatusSelfDescriptive } from 'global-constants';
import { capitalizeWords } from 'services';

export const transformAssetStatusDetailsText = (
  assetStatus: AssetStatusSelfDescriptive
) => {
  if (
    assetStatus === AssetStatusSelfDescriptive.MONO_IN_INVENTORY_DEVICE_ISSUE
  ) {
    return 'In inventory - device issue';
  }
  if (assetStatus === AssetStatusSelfDescriptive.MONO_MDM_LOCKED) {
    return 'MDM Locked';
  }
  if (assetStatus === AssetStatusSelfDescriptive.MONO_HOLD) {
    return 'HOLD';
  }

  return capitalizeWords(assetStatus?.replace('MONO_', ''));
};
