import React from 'react';

const BrazilFlagSVGIcon = () => {
  return (
    <svg
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="28" height="20" rx="2" fill="white" />
      <mask
        id="mask0_3141_38743"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="28"
        height="20"
      >
        <rect width="28" height="20" rx="2" fill="white" />
      </mask>
      <g mask="url(#mask0_3141_38743)">
        <rect width="28" height="20" fill="#05AB41" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.53176 10.561C3.12576 10.2983 3.12576 9.70429 3.53176 9.44159L13.6379 2.90231C13.8583 2.75971 14.1419 2.75971 14.3622 2.90231L24.4684 9.44159C24.8744 9.70429 24.8744 10.2983 24.4684 10.561L14.3622 17.1003C14.1419 17.2429 13.8583 17.2429 13.6379 17.1003L3.53176 10.561Z"
          fill="#FDD216"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.9999 14.6654C16.5772 14.6654 18.6666 12.576 18.6666 9.9987C18.6666 7.42137 16.5772 5.33203 13.9999 5.33203C11.4226 5.33203 9.33325 7.42137 9.33325 9.9987C9.33325 12.576 11.4226 14.6654 13.9999 14.6654Z"
          fill="#053087"
        />
        <mask
          id="mask1_3141_38743"
          maskUnits="userSpaceOnUse"
          x="9"
          y="5"
          width="10"
          height="10"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.9999 14.6654C16.5772 14.6654 18.6666 12.576 18.6666 9.9987C18.6666 7.42137 16.5772 5.33203 13.9999 5.33203C11.4226 5.33203 9.33325 7.42137 9.33325 9.9987C9.33325 12.576 11.4226 14.6654 13.9999 14.6654Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_3141_38743)">
          <path
            d="M8.77987 8.78008C9.51257 8.18676 11.8769 8.74883 14.084 9.33757C16.291 9.92631 18.5929 11.1603 19.179 11.8841"
            stroke="white"
            stroke-width="1.33333"
            stroke-linecap="square"
          />
        </g>
      </g>
    </svg>
  );
};

export default BrazilFlagSVGIcon;
