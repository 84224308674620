import { gql } from '@apollo/client';

export const FETCH_RETRIEVAL_OPTIONS = gql`
  query retrievalOptions($assetTypeName: AssetTypeName!) {
    retrievalOptions(
      assetTypeRetrieveInput: { assetTypeName: $assetTypeName }
    ) {
      id
      index
      type
      name
      prompt
      description
      placeholder
      retrievalOptionValues {
        id
        name
        index
        selected
        value
      }
    }
  }
`;
