import React from 'react';
import { useForm } from 'react-hook-form';
import { NewModal } from 'components';
import { AddInventoryReceiptModalProps } from './types';
import {
  ADD_INVENTORY_RECEIPT_MODAL_TITLE,
  CANCEL_BUTTON_LABEL,
  SAVE_BUTTON_LABEL,
} from './constants';
import { ModalPaperSx, OuterStackSx } from './styles';
import InventoryReceiptForm from '../InventoryReceiptForm';

const AddInventoryReceiptModal = (props: AddInventoryReceiptModalProps) => {
  const { handleSubmit } = useForm();

  React.useEffect(() => {
    if (props.open) {
      props.inventoryReceiptFormService.setContext('add');
    }
    return () => {
      props.inventoryReceiptFormService.setContext('none');
    };
  }, [props.open]);

  return (
    <NewModal
      form
      open={props.open}
      title={ADD_INVENTORY_RECEIPT_MODAL_TITLE}
      onSubmit={handleSubmit(props.onSubmit)}
      submitButtonCopy={SAVE_BUTTON_LABEL}
      cancelButtonCopy={CANCEL_BUTTON_LABEL}
      disableSaveButton={!props.inventoryReceiptFormService.isValid}
      loading={props.inventoryReceiptFormService.mutationIsLoading}
      handleClose={props.handleClose}
      paperPropsSx={ModalPaperSx}
      outerStackPropsSx={OuterStackSx}
    >
      <InventoryReceiptForm
        inventoryReceiptFormService={props.inventoryReceiptFormService}
      />
    </NewModal>
  );
};

export default AddInventoryReceiptModal;
