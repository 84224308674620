import { gql } from '@apollo/client';

export const RETRIEVE_ASSET = gql`
  query getAsset($assetId: ID!) {
    getAsset(retrieveAssetInput: { id: $assetId }) {
      id
      assetNumber
      color
      cosmeticCondition
      deviceAssessment
      displaySize
      lastOrderItemNumber
      associatedOrders {
        id
        orderNumber
      }
      make
      memory
      model
      processor
      keyboard
      serialNumber
      status
      storage
      purchaseDate
      warrantyExpiration
      isWarrantyExpirationVerified
      isPurchaseDateVerified
      customerNote
      updatedAt
      assetType {
        id
        name
      }
      depot {
        id
        name
      }
      assignee {
        id
        email
        personalEmail
        workEmail
        firstName
        lastName
      }
      inventoryLog {
        dateOfLastCheckIn
        dateOfLastCheckOut
        depotId
      }
      assetImages {
        id
        image {
          id
          url
        }
      }
    }
  }
`;
