import {
  POPOVER_CONTENT_MAX_HEIGHT,
  POPOVER_MAX_WIDTH_PAPER,
} from '../ColumnSelect/styles';

export const ListItemSx = {
  display: 'flex',
  cursor: 'pointer',
  pl: '7px',
  pr: '10px',
  pt: '0',
  pb: '0',
  mt: '0',
  mb: '0',
};

export const ListItemLeftSx = {
  display: 'flex',
  width: '80%',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

export const ListItemRightSx = {
  display: 'flex',
  width: '20%',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
};

export const ContentsContainerSx = {
  display: 'flex',
  width: POPOVER_MAX_WIDTH_PAPER,
  height: POPOVER_CONTENT_MAX_HEIGHT,
  overflow: 'auto',
};
