/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { notificationSlice } from 'store/slices';
import { executeNowThenWait } from 'services/misc';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as myComponents from 'components';
import { DeleteDeviceModalProps } from './types';
import * as styles from './styles';
import { DELETE_DEVICE } from './mutations';
import { useSafeMutation } from 'hooks/useSafeMutation';
import { NavigationContext } from 'context/NavigationContext';

const DeleteDeviceModal = (props: DeleteDeviceModalProps) => {
  if (!props.open) return <></>;

  const { Button: MyButton, NewModal } = myComponents;

  const headerCopy = 'Are you sure you want to delete this asset?';

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const navigator = React.useContext(NavigationContext);

  const showChangesSavedSuccessfully = () => {
    dispatch(
      notificationSlice.actions.setNotice({
        showNotice: true,
        noticeContent: 'Asset has been deleted successfully.',
      })
    );
    if (props.refetchSearchAssets) props.refetchSearchAssets();
    if (props.onSuccessfulDelete) props.onSuccessfulDelete();
    if (props.navigateToTableOnDelete)
      navigate(navigator.pathToAssetsDevices());
  };

  const closeModalAndRefetch = () => {
    props.handleClose(true);
  };

  const [deleteAsset] = useSafeMutation(DELETE_DEVICE, {
    onCompleted: () => {
      if (props.refetchSearchAssets) props.refetchSearchAssets();
    },
  });

  const handleDeleteDeviceClick = () => {
    deleteAsset({
      variables: {
        id: props.assetId,
      },
      onCompleted: () => {
        executeNowThenWait(
          closeModalAndRefetch,
          showChangesSavedSuccessfully,
          500
        );
      },
    });
  };

  const handleCloseModal = () => {
    props.handleClose();
  };

  return (
    <NewModal
      open={props.open}
      handleClose={handleCloseModal}
      paperPropsSx={styles.DeleteDeviceModalPaperSx}
    >
      <Stack
        direction="column"
        spacing={0}
        justifyContent="space-between"
        sx={styles.DeleteDeviceModalMainStackSx}
      >
        <Typography variant="h5" sx={styles.DeleteDeviceModalTitleSx}>
          {headerCopy}
        </Typography>
        <Stack
          direction="row"
          spacing={0}
          justifyContent="flex-end"
          sx={{ width: '100%' }}
        >
          <MyButton
            gaContext={{
              textCopy: 'NO',
              navigates_to: 'N/A',
              purpose: 'Closes delete device modal',
            }}
            id="close-delete-device-button"
            color="secondary"
            onClick={handleCloseModal}
            sx={styles.DeleteDeviceModalButtonBaseSx}
          >
            <Typography variant="button">No</Typography>
          </MyButton>
          <MyButton
            gaContext={{
              textCopy: 'Yes',
              navigates_to: 'N/A',
              purpose: 'Deletes Device',
            }}
            id="submit-delete-device-button"
            sx={styles.DeleteDeviceModalButtonBaseSx}
            onClick={handleDeleteDeviceClick}
            variant="contained"
          >
            <Typography variant="button">Yes</Typography>
          </MyButton>
        </Stack>
      </Stack>
    </NewModal>
  );
};

export default DeleteDeviceModal;
