import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const DataGridSx: SxProps = {
  '& .MuiDataGrid-footerContainer': {
    boxShadow: 'none !important',
  },
  '& .shipping-address-header': {
    ml: '3%',
  },
};

export const RecipientsDataGridContactEmailHeaderSx: SxProps = {
  '@media (min-width: 800px)': {
    width: '200px',
  },
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '18px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  overflow: 'visible',
};

export const RecipientsDataGridDropdownSx = {
  '&.Mui-error .MuiOutlinedInput-notchedOutline': {
    border: `1.5px solid ${theme.palette.colors.chiGong}`,
    overflow: 'visible',
    top: '-3px',
    bottom: '3px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};

export const RecipientsDataGridContactEmailDropdownSx = {
  ...RecipientsDataGridDropdownSx,
  '@media (min-width: 1450px)': {
    width: '230px',
  },
};

export const RecipientsDataGridAddressDropdownSx = {
  ...RecipientsDataGridDropdownSx,
  '@media (min-width: 1450px)': {
    width: '320px',
  },
};

export const RecipientsDataGridContactEmailDropdownItemSx = {
  '@media (min-width: 1000px)': {
    width: '280px',
  },
  '@media (min-width: 1450px)': {
    width: '320px',
  },
};
