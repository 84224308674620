import { SxProps } from '@mui/material';

export const NavLinkStyles: React.CSSProperties = {
  textDecoration: 'none',
};

export const DRAWER_WIDTH = 250;

export const HEADER_HEIGHT = '5rem';

export const EMBEDDED_APP_FOOTER_OFFSET = '100vh';

export const EMBEDDED_APP_MAX_HEIGHT = `calc(${EMBEDDED_APP_FOOTER_OFFSET} - ${HEADER_HEIGHT})`;

export const DEFAULT_GLOBAL_FOOTER_TOP_MARGIN = '50px';

export const DEFAULT_TOP_BAR_HEIGHT = '60px';

export const MINIMUM_PREMIUM_SHIPPING_RADIO_GROUP_WIDTH = '765px';

export const NO_DISPLAY_SX: SxProps = {
  display: 'none',
};
