/* eslint-disable sort-exports/sort-exports */
import { RetrievalFlowStep } from 'pages/Retrieval/RetrievalService/constants';

// FlowStepThrough Test ID
export const TEST_ID_FLOW_STEP_THROUGH = 'flow-step-through-component';

// FlowStepThrough Buttons Test IDs
export const TEST_ID_CANCEL_BUTTON = 'flow-step-cancel-button';
export const TEST_ID_NEXT_BUTTON = 'flow-step-next-button';

// FlowStepThrough Step Components (content) Test IDs
export const TEST_ID_STEP_COMPONENT_EMPLOYEE =
  'retrieval-step-component-employee';
export const TEST_ID_STEP_COMPONENT_LOCATION =
  'retrieval-step-component-location';
export const TEST_ID_STEP_COMPONENT_REVIEW = 'retrieval-step-component-review';
export const TEST_ID_STEP_COMPONENT_SUBMIT = 'retrieval-step-component-submit';

// FlowStepThrough Step Test IDs
export const TEST_ID_STEP_EMPLOYEE = 'employee-selection-step';
export const TEST_ID_STEP_LOCATION = 'choose-to-from-locations-step';
export const TEST_ID_STEP_REVIEW = 'review-selections-step';
export const TEST_ID_STEP_SUBMIT = 'retrieval-submission-step';

// FlowStepThrough Step Test IDs
export const TEST_ID_STEP_LABEL_EMPLOYEE = 'employee-selection-step-label';
export const TEST_ID_STEP_LABEL_LOCATION =
  'choose-to-from-locations-step-label';
export const TEST_ID_STEP_LABEL_REVIEW = 'review-selections-step-label';
export const TEST_ID_STEP_LABEL_SUBMIT = 'retrieval-submission-step-label';

/**
 * @description Record with key-value pairs of RetrievalFlowStep enum value - step test Ids
 */
export const STEP_TEST_ID_BY_ENUM: Record<string, string> = {
  [RetrievalFlowStep.CHOOSE_TO_FROM_LOCATIONS]: TEST_ID_STEP_LOCATION,
  [RetrievalFlowStep.EMPLOYEE_SELECTION]: TEST_ID_STEP_EMPLOYEE,
  [RetrievalFlowStep.REVIEW_SELECTIONS]: TEST_ID_STEP_REVIEW,
  [RetrievalFlowStep.RETRIEVAL_SUBMISSION]: TEST_ID_STEP_SUBMIT,
};

/**
 * @description Record with key-value pairs of RetrievalFlowStep enum value - step label test Ids
 */
export const STEP_LABEL_TEST_ID_BY_ENUM: Record<string, string> = {
  [RetrievalFlowStep.CHOOSE_TO_FROM_LOCATIONS]: TEST_ID_STEP_LABEL_LOCATION,
  [RetrievalFlowStep.EMPLOYEE_SELECTION]: TEST_ID_STEP_LABEL_EMPLOYEE,
  [RetrievalFlowStep.REVIEW_SELECTIONS]: TEST_ID_STEP_LABEL_REVIEW,
  [RetrievalFlowStep.RETRIEVAL_SUBMISSION]: TEST_ID_STEP_LABEL_SUBMIT,
};

export enum RetrievalAssetTypes {
  LAPTOP = 'LAPTOP',
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
}
