/* eslint-disable sort-exports/sort-exports */
import { SxProps } from '@mui/material';
import theme from 'themes/theme';

const buttonBaseSx: SxProps = {
  fontVariant: 'button',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '27px',
  m: 0,
};

export const AddressDialogSx: SxProps = {
  '& .MuiDialog-paper': {
    borderRadius: '24px',
    p: '24px',
  },
  '&: .MuiFormHelperText-root': {
    position: 'absolute',
    bottom: '-20px',
  },
};

export const AddressDialogPaperSx: SxProps = {
  height: {
    xs: '100vh',
    sm: '100vh',
    md: '613px',
    lg: '613px',
    xl: '613px',
  },
  maxWidth: '760px',
  width: '100vw',
};

export const AddressMainTitleSx: SxProps = {
  fontWeight: 700,
  height: '100%',
  lineHeight: '38.4px',
  position: 'relative',
};

export const AddressTextFieldBaseSx: SxProps = {
  width: '100%',
  backgroundColor: 'transparent',
  p: 0,
  '&: .MuiFormHelperText-root': {
    color: 'transparent',
    position: 'absolute',
    bottom: '-20px',
  },
};

export const CloseRecipientIconSx: SxProps = {
  fontWeight: '700',
};

export const AddressCancelButtonSx: SxProps = {
  ...buttonBaseSx,
  '&: hover': {
    backgroundColor: 'transparent',
  },
};

export const AddressSaveButtonSx: SxProps = {
  ...buttonBaseSx,
  boxShadow: 2,
  '&:hover': {
    backgroundColor: theme.palette.colors.maizeYO,
  },
};
