import React from 'react';
import Autocomplete from '@mui/lab/Autocomplete';
import TextField from '@mui/material/TextField';
import { Collaborator } from 'types';
import useDebouncedQuery from 'hooks/useDebouncedSearch';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { LIMITED_SEARCH_COLLABORATORS_BY_ORG_ID } from './queries';
import { EMPLOYEE_SEARCH_LIMIT, EMPLOYEE_SEARCH_ORDER_ASC } from './constants';
import { EmployeeSearchTextField } from './styles';
import { EmployeeSearchProps } from './types';

const EmployeeSearch = (props: EmployeeSearchProps) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const debouncedSearchTerm = useDebouncedQuery(searchTerm);

  const userOrganization = useGetUserOrganization();

  const { data, loading } = useSafeQuery(
    LIMITED_SEARCH_COLLABORATORS_BY_ORG_ID,
    {
      variables: {
        organizationId: userOrganization?.id,
        limit: EMPLOYEE_SEARCH_LIMIT,
        orderAsc: EMPLOYEE_SEARCH_ORDER_ASC,
        ...(debouncedSearchTerm
          ? { collaboratorFullTextSearch: debouncedSearchTerm }
          : {}),
      },
      skip: !userOrganization?.id,
    }
  );

  const employees: Collaborator[] = data?.collaborators?.collaborators || [];

  const handleOnInputChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setSearchTerm(value);
  };

  const handleOnChange = (
    _e: React.SyntheticEvent<Element, Event>,
    value: Collaborator | null
  ) => {
    props.setSelectedEmployee(value);
  };

  return (
    <Autocomplete
      value={props.selectedEmployee}
      options={employees}
      loading={loading}
      onChange={handleOnChange}
      onInputChange={handleOnInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          placeholder="Search employees"
          sx={EmployeeSearchTextField}
        />
      )}
      getOptionLabel={(option: Collaborator) =>
        `${option.firstName} ${option.lastName}`
      }
    />
  );
};

export default EmployeeSearch;
