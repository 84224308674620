import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GridApi } from '@mui/x-data-grid-pro';

import { selectColumns } from 'store/slices/assets/columns/selectors';
import {
  COLUMNS_TO_FIELDS_MAP,
  COLUMNS_DEFAULT_VISIBILITY,
  FIELDS_TO_COLUMNS_MAP,
} from './constants';
import { UseGetDeviceActionsEnablement } from './hooks/useGetDeviceActionsEnablement/types';
import { swapColumns } from 'store/slices/assets/columns';

export const useColumnVisibilityModel = () => {
  const columns = useSelector(selectColumns);

  return React.useCallback(() => {
    return columns.reduce((accumulator, column) => {
      const keys = COLUMNS_TO_FIELDS_MAP;
      accumulator[keys[column.key]] = column.active;
      return accumulator;
    }, COLUMNS_DEFAULT_VISIBILITY);
  }, [columns]);
};

export const useResetDeviceEnablementOnColumnEmpty = (
  deviceActionsEnablement: UseGetDeviceActionsEnablement,
  setSelectionModel: (selectionModel: string[]) => void
) => {
  const columns = useSelector(selectColumns);

  return React.useEffect(() => {
    const allColumnsOff = columns.every((column) => !column.active);
    if (allColumnsOff) {
      deviceActionsEnablement.resetState();
      setSelectionModel([]);
    }
  }, [columns]);
};

export const useHandleColumnHeaderDragAndDrop = (
  apiRef: React.MutableRefObject<GridApi>
) => {
  const dispatch = useDispatch();
  const [initial, setInitial] = React.useState('');
  const [destination, setDestination] = React.useState('');
  const columns = useSelector(selectColumns);
  React.useEffect(() => {
    const removeColumnHeaderDragStart = apiRef.current.subscribeEvent(
      'columnHeaderDragStart',
      (params, dragEvent, y) => {
        setInitial(params.colDef?.field || '');
      }
    );
    return () => {
      removeColumnHeaderDragStart();
    };
  }, [apiRef]);

  React.useEffect(() => {
    const removeColumnHeaderDragOver = apiRef.current.subscribeEvent(
      'columnHeaderDragOver',
      (params, dragEvent, y) => {
        if (initial !== params?.field) {
          setDestination(params.colDef?.field || '');
        }
      }
    );
    return () => {
      removeColumnHeaderDragOver();
    };
  }, [apiRef, initial]);

  return React.useEffect(() => {
    const removeColumnHeaderDragEnd = apiRef.current.subscribeEvent(
      'columnHeaderDragEnd',
      (params, dragEvent, y) => {
        const source = params?.field;
        const destinationKey = FIELDS_TO_COLUMNS_MAP[destination];
        const destinationIndex = columns.findIndex((column) => {
          if (column.key === destinationKey) return column;
          return null;
        });
        const sourceKey = FIELDS_TO_COLUMNS_MAP[source];
        const sourceIndex = columns.findIndex((column) => {
          if (column.key === sourceKey) return column;
          return null;
        });
        if (sourceIndex >= 0 && destinationIndex >= 0) {
          dispatch(swapColumns({ sourceIndex, destinationIndex }));
        }
      }
    );
    return () => {
      removeColumnHeaderDragEnd();
    };
  }, [apiRef, columns, destination, initial]);
};
