/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {
  CountryOptions,
  StateOptions,
  UNITED_STATES,
  COUNTRY_MATCHER,
  FormModeContext,
} from 'global-constants';
import { capitalize } from 'services';
import { EditEmployeeAddressInputDTO, EmployeeAddressFormProps } from './types';
import * as EmployeeAddressFormStyles from './styles';
import { getEmployeeAddressIds } from './utils';

const EmployeeAddressForm = (props: EmployeeAddressFormProps) => {
  const styles = EmployeeAddressFormStyles;
  const [enableState, setEnableState] = React.useState<boolean>(true);

  React.useEffect(() => {
    setEnableState(() => props.country === UNITED_STATES);
  }, [props.country]);

  const changedFieldString = (props?.changedFields ?? [''])
    .slice()
    .sort()
    .join('-');

  React.useEffect(() => {
    if (
      !changedFieldString ||
      props.context !== FormModeContext.EDIT ||
      !props.addEditEmployeeInputDTO ||
      !props.addressId
    )
      return;
    props.addEditEmployeeInputDTO(
      props.addressId,
      generateEditAddressInputDTO(changedFieldString.split('-'))
    );
  }, [props.fieldsState, changedFieldString, props.context, props.addressId]);

  React.useEffect(() => {
    if (
      changedFieldString ||
      props.context !== FormModeContext.EDIT ||
      !props.addressId
    )
      return;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    props.removeEditEmployeeInputDTO &&
      props.removeEditEmployeeInputDTO(props.addressId);
  }, [props.fieldsState, changedFieldString, props.context, props.addressId]);

  const generateEditAddressInputDTO = (
    changedFields: string[]
  ): EditEmployeeAddressInputDTO =>
    changedFields.reduce(
      (acc: Record<string, any>, field: string) => {
        if (field in props && typeof field === 'string') {
          const tempField = field;
          const valueAtPropsField = props[tempField as keyof typeof props];
          // eslint-disable-next-line security/detect-object-injection
          acc[field] = valueAtPropsField;
        }
        return acc;
      },
      {
        ...(enableState && props.state
          ? {
              principalRegion: null,
              state: props.state,
            }
          : { state: null, principalRegion: props.principalRegion }),
        country: props.country,
      }
    ) as EditEmployeeAddressInputDTO;

  const addressIds = getEmployeeAddressIds(
    props.addressType,
    props.context,
    props.context === FormModeContext.EDIT ? props.addressId : ''
  );

  const handleChangeByField = (field: string) => (event: any) => {
    props.setAddressFieldValues({
      [field]: event.target.value,
      ...(field === 'country' && {
        [event.target.value === UNITED_STATES ? 'principalRegion' : 'state']:
          '',
      }),
    });
  };

  return (
    <>
      <Grid item container xs={12} pt="36px">
        <Typography variant="body2">{`${
          props.context === FormModeContext.ADD ? 'Add ' : ''
        }${capitalize(props.addressType)} address`}</Typography>
        <Grid item xs={12} pt="16px">
          <TextField
            id={addressIds.street1}
            label="Street address 1"
            variant="outlined"
            sx={styles.EmployeeAddressTextInputBaseSx}
            value={props.streetAddress1}
            required={props.addressFieldsRequired}
            onChange={handleChangeByField('streetAddress1')}
          />
        </Grid>
        <Grid item xs={12} pt="16px">
          <TextField
            id={addressIds.street2}
            label="Street address 2"
            variant="outlined"
            sx={styles.EmployeeAddressTextInputBaseSx}
            value={props.streetAddress2}
            onChange={handleChangeByField('streetAddress2')}
          />
        </Grid>
        <Grid item container xs={12} pt="16px" columnSpacing="25px">
          <Grid item xs={6}>
            <TextField
              id={addressIds.city}
              label="City"
              variant="outlined"
              sx={styles.EmployeeAddressTextInputBaseSx}
              value={props.city}
              required={props.addressFieldsRequired}
              onChange={handleChangeByField('city')}
            />
          </Grid>
          <Grid item xs={6}>
            <Box>
              {enableState ? (
                <TextField
                  id={addressIds.state}
                  label="State"
                  select
                  sx={styles.EmployeeAddressTextInputBaseSx}
                  value={props.state}
                  required={props.addressFieldsRequired}
                  onChange={handleChangeByField('state')}
                >
                  {StateOptions.map((stateOption) => (
                    <MenuItem key={stateOption} value={stateOption}>
                      {stateOption}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <TextField
                  id={addressIds.principalRegion}
                  label="Principal region"
                  sx={styles.EmployeeAddressTextInputBaseSx}
                  value={props.principalRegion}
                  required={props.addressFieldsRequired}
                  onChange={handleChangeByField('principalRegion')}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={6} pt="16px">
            <TextField
              id={addressIds.postalCode}
              label="Postal code"
              variant="outlined"
              value={props.zipCode}
              sx={styles.EmployeeAddressTextInputBaseSx}
              required={props.addressFieldsRequired}
              onChange={handleChangeByField('zipCode')}
            />
          </Grid>
          <Grid item xs={6} pt="16px">
            <TextField
              id={addressIds.country}
              label="Country"
              select
              sx={styles.EmployeeAddressTextInputBaseSx}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'center',
                    horizontal: 'right',
                  },
                },
              }}
              value={props.country}
              required={props.addressFieldsRequired}
              onChange={handleChangeByField('country')}
            >
              {CountryOptions.map((countryOption) => (
                <MenuItem key={countryOption} value={countryOption}>
                  {countryOption}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default EmployeeAddressForm;
