import {
  GridFilterModel,
  GetColumnForNewFilterArgs,
  GridFilterItem,
} from '@mui/x-data-grid-pro';
import { getEnumValues } from 'services/enums';
import { SortOrder } from 'types';

//Common utils that multiple instances of the datagrid component may need

// If two filters are applied to the same column, it removes the latter filter because our API does not support multiple filters on one column
export const removeDuplicateFilters = (model: GridFilterModel) => {
  const filteredItems = model.items.filter((item, index, self) => {
    return (
      index === self.findIndex((filterItem) => filterItem.field === item.field)
    );
  });
  model.items = filteredItems;
};

export const getNewColumnFilterHandler = (columnEnum: any) => {
  const availableFilters = getEnumValues(columnEnum);

  if (!availableFilters || !availableFilters.length)
    throw new Error(
      'Arg must be an enum w/ values that correspond to the filter fields.'
    );

  return (args: GetColumnForNewFilterArgs): string | null => {
    const currentFilters = args.currentFilters.map(
      (currentFilter) => currentFilter.field
    );
    const uniqueFilters = availableFilters
      .slice()
      .filter((filter) => !currentFilters.includes(filter));

    return uniqueFilters[0] ?? undefined;
  };
};

export const areFiltersApplied = (filterItems: GridFilterItem[]): boolean =>
  filterItems.slice().filter((item) => item.value).length > 0;

export const getSortParams = (sortOrder: SortOrder) => {
  const { direction, field } = sortOrder;
  return { [direction]: field };
};
