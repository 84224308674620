import { SxProps } from '@mui/material/styles';
import theme from 'themes/theme';

export const CreateModalDividersSx: SxProps = {
  left: '-24px',
  position: 'absolute',
  // width: `calc(100% + ${2 * NEW_MODAL_GLOBAL_PADDING_NUMBER}px)`,
};

export const CreateModalStepSectionSx: SxProps = {
  background: theme.palette.colors.grayFA,
  height: '40px',
  pl: '24px',
};

export const getCreateModalFormControlSx = (
  disabled: boolean = false
): SxProps => ({
  opacity: disabled ? 0.6 : 1,
  m: 0,
  // mx: 1.5,
  // width: `calc(50% - 12px)`,
  width: 'calc(50% - 12px)',
});

export const CreateModalBottomDividerSx: SxProps = {
  position: 'absolute',
  left: '-24px',
  top: '-24px',
  width: 'calc(100% + 48px)',
};

export const CreateButtonBaseSx: SxProps = {
  padding: '6px 16px',
  width: 'fit-content',
  height: '36px',
  '& .MuiTypography-root': {
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '24px',
  },
  '&#cancel-button': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

export const CreateOrderModalSubmitButtonSx: SxProps = {
  ...CreateButtonBaseSx,
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
  borderRadius: '30px',
};

export const CreateOrderBoxStyleSx: SxProps = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100vw',
  height: '100vh',
  maxHeight: {
    xs: '100vh',
    sm: '100vh',
    md: '450px',
  },
  maxWidth: '800px',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  borderRadius: '24px',
  boxShadow: 24,
  p: 0,
  '&:focus-visible': {
    outline: 'none',
  },
};
