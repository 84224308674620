import { SxProps } from '@mui/material';

export const SearchAndFiltersContainerSx: SxProps = {
  alignItems: 'flex-start',
  maxHeight: '73.25vh',
  overflowY: 'scroll',
  marginBottom: '1rem',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  width: '25%',
  '::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    width: '7px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: 'rgba(0,0,0,.5)',
    WebkitBoxShadow: '0 0 1px rgba(255,255,255,.5)',
  },
  '@media (max-width: 600px)': {
    width: '55%',
  },
};

export const ProductCatalogSearchBarSx: SxProps = {
  height: '34px',
  marginTop: '1rem',
  width: '100%',
};

export const FiltersContainerSx: SxProps = {
  gap: '8px',
  marginTop: '1.5rem',
  width: '100%',
};
