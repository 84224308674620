import {
  ProductCatalogItem,
  ProductDetailsResponse,
} from 'services/openapi/apiSchemas';
import { RETAIL_PRICE } from '../constants';

const formatPrice = (price: any) => {
  if (typeof price !== 'string') {
    return RETAIL_PRICE;
  }

  const priceIsNothing = !price.split(/[0.]/).join('');
  const formattedPrice = priceIsNothing
    ? price
    : Number(price).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

  return `$${formattedPrice}`;
};

export const displayPrice = (item: ProductCatalogItem) => {
  return item.price ? `${formatPrice(item.price)}` : RETAIL_PRICE;
};

export const doesProductHaveMoreThanOneVariant = (
  productDetails: ProductDetailsResponse
) => {
  const optionWithMoreThanOneVariant = productDetails.options.find(
    (option) => option?.values.length > 1
  );

  return !!optionWithMoreThanOneVariant;
};
