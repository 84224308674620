import { SxProps } from '@mui/material';
import { CSSProperties } from 'react';
import { EDIT_DEVICE_MODAL_MAX_WIDTH } from 'pages/Assets/components/DevicesDatagrid/components/EditDeviceModal/constants';

export const StepperContainerSx: SxProps = {
  ml: '-8px',
  mr: '-24px',
  // mt: '-16px',
  p: '0 !important',
  position: 'relative',
  width: '100vw',
  maxWidth: EDIT_DEVICE_MODAL_MAX_WIDTH,
};

export const ProgressDivCSS: CSSProperties = {
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
};

export const CircularProgressSx: SxProps = {
  position: 'absolute',
  left: '50%',
  top: '200px',
  transform: 'translateX(-50%)',
  width: '100px',
};

// START Asset Status History Styles
export const StepperMainTitleSx: SxProps = {
  fontWeight: 500,
};
// END Asset Status History Styles
