import { SxProps } from '@mui/material';

export const NewModalPaperSx: SxProps = {
  maxHeight: '309px',
  maxWidth: '471px',
};

export const IconCloseIconButtonSx: SxProps = {
  color: 'theme.palette.colors.gray94',
  position: 'absolute',
  right: '18px',
  top: '18px',
};

export const TitleSx: SxProps = {
  fontWeight: '700',
  fontSize: '28px',
  lineHeight: '32px',
  maxWidth: '75%',
  marginTop: '10px',
};

export const CopySx: SxProps = {
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '20px',
  marginTop: '10px',
};
