import { CollaboratorPayload } from './types';

const DUPLICATE_COLLABORATOR_ENTRY_ERROR_MESSAGE =
  'Failed to save. Duplicate entry, please add a different email address.';

const GENERAL_COLLABORATOR_ENTRY_ERROR_MESSAGE =
  'Failed to save. Please try again.';

const duplicateCollaboratorEmailEntryServerMessage = 'email must be unique';

export const constructCreateCollaboratorErrorMessage = (
  errors: string[] | string
) => {
  return errors.includes(duplicateCollaboratorEmailEntryServerMessage)
    ? DUPLICATE_COLLABORATOR_ENTRY_ERROR_MESSAGE
    : GENERAL_COLLABORATOR_ENTRY_ERROR_MESSAGE;
};

export const constructCreateCollaboratorPayload = (
  firstName: string,
  lastName: string,
  email: string,
  organizationId: string,
  workEmail?: string,
  personalEmail?: string
): CollaboratorPayload => ({
  firstName,
  lastName,
  email,
  workEmail,
  personalEmail,
  organizationId,
});
