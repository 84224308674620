import { SxProps } from '@mui/material';

export const BoxSx: SxProps = {
  backgroundColor: '#FDF4E0',
  borderRadius: '11px',
  padding: '12px 20px',
  marginTop: '1rem',

  '& .assign-device-info-outer-stack': {
    gap: {
      xs: 10,
      sm: 15,
    },
  },

  '& .assign-device-info-header': {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '24px',
    color: 'colors.gray94',
  },

  '& .assign-device-info-content': {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '22.88px',
  },
};
