import { gql } from '@apollo/client';

export const GET_ALL_PRODUCT_CATEGORIES = gql`
  query getAllProductCategories($orderTypeId: ID!) {
    getAllProductCategories(
      productCategoryAllInput: { orderTypeId: $orderTypeId }
    ) {
      id
      imageId
      name
      description
      index
    }
  }
`;

export const GET_ALL_ORDER_TYPES = gql`
  query {
    getAllOrderTypes {
      id
      name
    }
  }
`;
