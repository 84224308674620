import React from 'react';
import { SVGProps } from './types';

const BoxSVGIcon = (props: SVGProps) => {
  const originalSize = 15;
  const colorFill = props?.fill ?? 'white';
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33945 10.6548L7.31455 12.2187C7.3421 12.2258 7.37023 12.2293 7.39812 12.2293C7.42602 12.2293 7.45415 12.2258 7.48158 12.2187L13.4567 10.6548C13.6022 10.617 13.7034 10.4856 13.7034 10.3351V1.89432C13.7034 1.79184 13.656 1.69524 13.575 1.63276C13.5392 1.6052 13.4988 1.58733 13.4561 1.57627L13.4566 1.57465L7.48176 0.0108072C7.42689 -0.00360241 7.36925 -0.00360241 7.31438 0.0108072L1.33958 1.57454L1.33993 1.57604C1.29762 1.5871 1.25681 1.60508 1.22131 1.63275C1.14015 1.69523 1.09277 1.79195 1.09277 1.89432V10.3354C1.09266 10.4854 1.1941 10.6169 1.33958 10.655L1.33945 10.6548ZM1.75352 2.33952L7.06757 3.73035V11.4708L1.75352 10.0801V2.33952ZM13.0424 10.08L7.72862 11.4707V3.71341L13.0424 2.32258V10.08ZM7.39809 0.672151L12.0673 1.8942L7.39809 3.11637L2.72857 1.89408L7.39809 0.672151Z"
        fill={colorFill}
      />
      <path
        d="M0.0970165 13.6602L0.111887 13.6751C0.11915 13.683 0.126412 13.6904 0.134367 13.6976L0.964128 14.5273C1.09324 14.6564 1.30247 14.6564 1.43146 14.5273C1.56057 14.3982 1.56057 14.1887 1.43146 14.06L1.15595 13.7845H14.5681C14.7506 13.7845 14.8986 13.6363 14.8986 13.454C14.8986 13.2715 14.7505 13.1235 14.5681 13.1235H1.10136L1.43117 12.7937C1.56028 12.6645 1.56028 12.4553 1.43117 12.3263C1.3665 12.2617 1.28212 12.2295 1.1975 12.2295C1.11289 12.2295 1.02839 12.2618 0.963835 12.3263L0.0968314 13.1929C0.0348111 13.2549 0 13.3389 0 13.4265C0 13.5139 0.0349291 13.5982 0.0968314 13.6602L0.0970165 13.6602Z"
        fill={colorFill}
      />
    </svg>
  );
};

export default BoxSVGIcon;
