import { gql } from '@apollo/client';

export const UPDATE_ORDER_ITEM = gql`
  mutation updateOrderItem(
    $id: ID!
    $insuranceRequested: String
    $serialNumber: String
    $quantity: Int = 1
  ) {
    updateOrderItem(
      orderItemUpdateInput: {
        id: $id
        insuranceRequested: $insuranceRequested
        serialNumber: $serialNumber
        quantity: $quantity
      }
    ) {
      id
      insuranceRequested
      serialNumber
      quantity
    }
  }
`;
