import {
  getDropDownEntries,
  upsertDummyOptionsHOF,
} from 'components/complex_filter/utils';
import {
  FilterLayoutSection,
  FilterModalConfig,
  FilterTestIds,
  BaseFilterBankConfig,
  DropDownOption,
} from 'components/complex_filter/types';
import { orderFilterActions } from 'store/slices/orders/filter';
import {
  OrdersDropdownConfig,
  OrdersTypeaheadConfig,
} from 'store/slices/orders/filter/types';
import {
  DUMMY_NODE_GET_NOTHING,
  GET_ALL_ORDER_TYPES,
  GET_SHIPPING_SELECTION_TYPES,
  GET_PURCHASER_OPTIONS,
  GET_RECIPIENT_EMAIL_OPTIONS,
  GET_RECIPIENT_OPTIONS,
  GET_ORDER_NUMBER_OPTIONS,
  GET_ASSET_AUTOCOMPLETE_SUGGESTIONS,
} from 'pages/Orders/filters/queries';
import {
  getTypeaheadOnCompletedDummyHOF,
  getOrderTypesOnCompletedHOF,
  getShippingTypesOnCompletedHOF,
  getPurchaserTypeaheadOnCompletedHOF,
  getRecipientTypeaheadOnCompletedHOF,
  getOrderNumberTypeaheadOnCompletedHOF,
  getAssetTypeaheadOnCompletedHOF,
  setupOrderStatusOptions,
} from 'pages/Orders/filters/utils';
import { capitalize } from 'services';

export const ORDER_STATUS_TABLE: Record<string, string> = {
  Canceled: 'CANCELED',
  Complete: 'COMPLETE',
  Created: 'CREATED',
  Exception: 'EXCEPTION',
  'Expired Pending Recipient Information':
    'EXPIRED_PENDING_RECIPIENT_INFORMATION',
  'Expired Pending Return': 'EXPIRED_PENDING_RETURN',
  'In Fulfillment': 'IN_FULFILLMENT',
  'In Return': 'IN_RETURN',
  'Pending Approval': 'PENDING_APPROVAL',
  'Pending Certificate': 'PENDING_CERTIFICATE',
  'Pending Quote Approval': 'PENDING_QUOTE_APPROVAL',
  'Pending Recycle': 'PENDING_RECYCLE',
  'Pending Recipient Information': 'PENDING_RECIPIENT_INFORMATION',
  'Pending Repair': 'PENDING_REPAIR',
  'Pending Return': 'PENDING_RETURN',
  // Placed: 'Placed',
  'Processing Device': 'PROCESSING_DEVICE',
  // Quoted: 'Quoted',
  'Quote In Progress': 'QUOTE_IN_PROGRESS',
  Shipped: 'SHIPPED',
};

export const ORDER_STATUS_KEYS = Object.keys(ORDER_STATUS_TABLE);
export const ORDER_STATUS_DROPDOWN_OPTIONS: DropDownOption[] =
  setupOrderStatusOptions();

export const SHIPMENT_STATUSES = [
  'ATTEMPT_FAILED',
  'AVAILABLE_FOR_PICKUP',
  'CREATED',
  'DELIVERED',
  'EXCEPTION',
  'EXPIRED',
  'INFO_RECEIVED',
  'IN_TRANSIT',
  'OUT_FOR_DELIVERY',
  'PENDING',
];

export const SHIPMENT_STATUS_DROPDOWN_OPTIONS: DropDownOption[] =
  SHIPMENT_STATUSES.map((status) => ({
    displayName: status.split('_').map(capitalize).join(' '),
    value: status,
  }));

export const OrderFilterKeys = {
  ORDER_NUMBER: 'order-number',
  ORDER_TYPE: 'order-type',
  ORDER_DATE: 'order-date',
  STATUS: 'order-status',
  SHIPPING_SELECTION: 'shipping-selection',
  TRACKING_NUMBER: 'tracking-number',
  PURCHASER: 'purchaser',
  ASSET_NUMBER: 'asset-number',
  SHIPMENT_STATUS: 'shipment-status',
  RECIPIENT: 'recipient',
  RECIPIENT_EMAIL: 'recipient-email',
};

export const ORDER_FILTER_BANK_CONFIG: BaseFilterBankConfig = {
  actions: orderFilterActions,
  componentTestId: 'orders-filter-bank-outer',
  bankTestId: 'orders-filter-bank-inner',
};

export const ORDERS_FILTER_LAYOUT: FilterLayoutSection[] = [
  {
    section: 'Allwhere Info',
    layout: [
      {
        filterKey: OrderFilterKeys.ORDER_NUMBER,
        placeholder: 'Order number',
        fieldType: 'typeahead',
        span: 'half',
      },
      {
        filterKey: OrderFilterKeys.ORDER_TYPE,
        placeholder: 'Order type',
        fieldType: 'dropdown',
        span: 'half',
      },
      {
        filterKey: OrderFilterKeys.ORDER_DATE,
        placeholder: 'Order date',
        fieldType: 'datetime',
        span: 'full',
      },
      {
        filterKey: OrderFilterKeys.STATUS,
        placeholder: 'Status',
        fieldType: 'dropdown',
        span: 'half',
      },
      {
        filterKey: OrderFilterKeys.SHIPPING_SELECTION,
        placeholder: 'Shipping selection',
        fieldType: 'dropdown',
        span: 'half',
      },
      // {
      //   filterKey: OrderFilterKeys.TRACKING_NUMBER,
      //   placeholder: 'Tracking number',
      //   fieldType: 'typeahead',
      //   span: 'half',
      // },
      {
        filterKey: OrderFilterKeys.PURCHASER,
        placeholder: 'Purchaser',
        fieldType: 'typeahead',
        span: 'half',
      },
    ],
  },
  {
    section: 'Asset Info',
    layout: [
      {
        filterKey: OrderFilterKeys.ASSET_NUMBER,
        placeholder: 'Asset number',
        fieldType: 'typeahead',
        span: 'half',
      },
      // {
      //   filterKey: OrderFilterKeys.SHIPMENT_STATUS,
      //   placeholder: 'Shipment status',
      //   fieldType: 'dropdown',
      //   span: 'half',
      // },
    ],
  },
  {
    section: 'Recipient Info',
    layout: [
      {
        filterKey: OrderFilterKeys.RECIPIENT,
        placeholder: 'Recipient',
        fieldType: 'typeahead',
        span: 'half',
      },
      {
        filterKey: OrderFilterKeys.RECIPIENT_EMAIL,
        placeholder: 'Recipient email',
        fieldType: 'typeahead',
        span: 'half',
      },
    ],
  },
];

export const ORDER_FILTER_TEST_IDS: FilterTestIds = {
  outerBoxTestId: 'orders-filter-drawer-outer-box',
  filterButtonTestId: 'orders-filter-drawer-toggle-btn',
  selectFilterBadgeTestId: 'orders-filter-counter-badge',
};

export const ORDERS_TYPEAHEAD_HANDLERS: OrdersTypeaheadConfig = {
  'order-number': {
    lookupQuery: GET_ORDER_NUMBER_OPTIONS,
    onCompletedHOF: getOrderNumberTypeaheadOnCompletedHOF,
    counterAvatarRightOffset: 55,
  },
  'tracking-number': {
    lookupQuery: DUMMY_NODE_GET_NOTHING,
    onCompletedHOF: getTypeaheadOnCompletedDummyHOF,
    counterAvatarRightOffset: 55,
  },
  purchaser: {
    lookupQuery: GET_PURCHASER_OPTIONS,
    onCompletedHOF: getPurchaserTypeaheadOnCompletedHOF,
    counterAvatarRightOffset: 88,
  },
  // 'asset-number': {
  //   lookupQuery: DUMMY_NODE_GET_NOTHING,
  //   onCompletedHOF: getTypeaheadOnCompletedDummyHOF,
  //   counterAvatarRightOffset: 55,
  // },
  'asset-number': {
    lookupQuery: GET_ASSET_AUTOCOMPLETE_SUGGESTIONS,
    onCompletedHOF: getAssetTypeaheadOnCompletedHOF,
    counterAvatarRightOffset: 55,
  },
  recipient: {
    lookupQuery: GET_PURCHASER_OPTIONS,
    onCompletedHOF: getPurchaserTypeaheadOnCompletedHOF,
    counterAvatarRightOffset: 92,
  },
  'recipient-email': {
    lookupQuery: GET_RECIPIENT_EMAIL_OPTIONS,
    onCompletedHOF: getPurchaserTypeaheadOnCompletedHOF,
    counterAvatarRightOffset: 50,
  },
};

export const ORDERS_DROPDOWN_HANDLERS: OrdersDropdownConfig = {
  'order-type': {
    asyncOptionsQuery: GET_ALL_ORDER_TYPES,
    counterOffsetRight: 85,
    inputLabel: 'Order type',
    makeSelectionText: 'Select Order type',
    optionsType: 'async',
    presetOptions: [],
    upsertOptionsHOF: getOrderTypesOnCompletedHOF,
    getPreloadedEntries: (key: string, options: DropDownOption[]) => [],
  },
  'order-status': {
    asyncOptionsQuery: DUMMY_NODE_GET_NOTHING,
    counterOffsetRight: 120,
    inputLabel: 'Status',
    makeSelectionText: 'Select status',
    optionsType: 'hard-coded',
    presetOptions: ORDER_STATUS_DROPDOWN_OPTIONS,
    upsertOptionsHOF: upsertDummyOptionsHOF,
    getPreloadedEntries: getDropDownEntries,
  },
  'shipping-selection': {
    asyncOptionsQuery: GET_SHIPPING_SELECTION_TYPES,
    counterOffsetRight: 28,
    inputLabel: 'Shipping selection',
    makeSelectionText: 'Select shipping',
    optionsType: 'async',
    presetOptions: [],
    upsertOptionsHOF: getShippingTypesOnCompletedHOF,
    getPreloadedEntries: (key: string, options: DropDownOption[]) => [],
  },
  'shipment-status': {
    asyncOptionsQuery: DUMMY_NODE_GET_NOTHING,
    counterOffsetRight: 45,
    inputLabel: 'Shipment status',
    makeSelectionText: 'Select Shipment status',
    optionsType: 'hard-coded',
    presetOptions: SHIPMENT_STATUS_DROPDOWN_OPTIONS,
    upsertOptionsHOF: upsertDummyOptionsHOF,
    getPreloadedEntries: getDropDownEntries,
  },
};

export const ORDERS_FILTER_MODAL_CONFIG: FilterModalConfig = {
  blueprint: ORDERS_FILTER_LAYOUT,
  typeAheadHandlers: ORDERS_TYPEAHEAD_HANDLERS,
  dropdownHandlers: ORDERS_DROPDOWN_HANDLERS,
  actions: orderFilterActions,
};
