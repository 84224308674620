import { createSlice } from '@reduxjs/toolkit';
import { GlobalModals } from 'store/constants';

const modalInitialState = {
  currentModal: GlobalModals.NONE,
};

export const modalSlice = createSlice({
  name: 'modalService',
  initialState: () => modalInitialState,
  reducers: {
    setCurrentModal: (state, action) => {
      state.currentModal = action.payload;
    },
    reset: () => modalInitialState,
  },
});
