/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import * as Sentry from '@sentry/react';
import { useApolloClient, ApolloError } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { notificationSlice } from 'store/slices';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { GET_ORDER_ITEMS_BY_ORDER_ID } from 'pages/OrderManagement/components/DetailedOrderView/components/OrderDetails/queries';
import { useSafeMutation } from 'hooks/useSafeMutation';
import {
  IAddOrderItemByProductFieldValues,
  AddOrderItemByProductFormProps,
} from './types';
import * as AddOrderItemFormStyles from './styles';
import { ADD_ORDER_ITEM_BY_PRODUCT } from './mutations';
import {
  PRODUCT_SKU_INPUT_ID,
  PRODUCT_QUANTITY_TEXT_ID,
  PRODUCT_QUANTITY_MIN,
  ADD_ORDER_ITEM_BY_PRODUCT_SKU_ERROR_MESSAGE,
  PRODUCT_VARIANT,
  PRODUCT_SKU_FIELD,
  QUANTITY_FIELD,
} from './constants';
import { executeNowThenWait } from 'services/misc';
import {
  NotificationMessages,
  CustomNotifications,
} from 'components/GlobalToastNotification/constants';

const AddOrderItemByProductForm = (props: AddOrderItemByProductFormProps) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const styles = AddOrderItemFormStyles;
  const {
    control,
    register,
    setError,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<IAddOrderItemByProductFieldValues>({
    defaultValues: {
      quantity: 1,
      insuranceRequested: '0.00',
    },
    mode: 'onSubmit',
  });

  const isUpdateDisabled = () => !(isDirty && isValid);

  const [addOrderItemByProduct, { loading }] = useSafeMutation(
    ADD_ORDER_ITEM_BY_PRODUCT
  );

  const showChangesSavedSuccessfully = () => {
    dispatch(
      notificationSlice.actions.setNotice({
        showNotice: true,
        noticeContent: NotificationMessages.CHANGES_SAVED_SUCCESS,
      })
    );
  };

  const showChangesSavedUnsuccessfully = (reason: string) => {
    dispatch(
      notificationSlice.actions.setNotice({
        showNotice: true,
        noticeContent: reason,
      })
    );
  };

  const refetchThenCloseModal = async () => {
    await client.refetchQueries({
      include: [GET_ORDER_ITEMS_BY_ORDER_ID],
    });
    props.closeModal();
  };

  const throwSKUError = (customError: string = '') => {
    setError(PRODUCT_SKU_FIELD, {
      type: 'custom',
      message: customError || ADD_ORDER_ITEM_BY_PRODUCT_SKU_ERROR_MESSAGE,
    });
  };

  const isIntegerGreaterThanZero = (value: string | number) => {
    const valueAsNumber = Number(value);
    const isInteger = Number.isInteger(valueAsNumber);
    return valueAsNumber > 0 && isInteger;
  };

  const handleChangeQuantityHoF =
    (changeField: Function) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === '0') return;

      changeField(event.target.value);
      if (
        !isIntegerGreaterThanZero(event.target.value) &&
        event.target.value !== ''
      ) {
        changeField(1);
      }
    };

  const handleAddOrderItemByProductSubmit = (
    formData: IAddOrderItemByProductFieldValues
  ) => {
    const skuIsValid = isIntegerGreaterThanZero(formData.sku);

    if (!skuIsValid) {
      throwSKUError();
      return;
    }

    formData.quantity = Number(formData.quantity);

    addOrderItemByProduct({
      variables: {
        orderId: props.orderId,
        productVariantOrderItems: [
          {
            ...formData,
          },
        ],
      },
      onCompleted: () => {
        executeNowThenWait(
          refetchThenCloseModal,
          showChangesSavedSuccessfully,
          200
        );
      },
      onError: (error: ApolloError) => {
        const stringifiedError = JSON.stringify(error);
        if (stringifiedError.toLowerCase().includes(formData.sku)) {
          const errorReason = CustomNotifications.entityNotfoundWithFieldValue(
            PRODUCT_VARIANT,
            PRODUCT_SKU_FIELD,
            formData.sku
          );
          showChangesSavedUnsuccessfully(errorReason);
          throwSKUError(errorReason);
        }
        console.error(
          'Mutation ADD_ORDER_ITEM_BY_PRODUCT error: /n',
          stringifiedError.toLowerCase()
        );
      },
    });
  };

  return (
    <form
      onSubmit={handleSubmit(handleAddOrderItemByProductSubmit)}
      style={{ height: '100%' }}
    >
      <Stack
        direction="column"
        justifyContent="space-between"
        sx={{ height: '100%' }}
      >
        <Grid item xs={12} sx={{ mt: '16px', position: 'relative' }}>
          <Controller
            name={PRODUCT_SKU_FIELD}
            control={control}
            render={({ field }) => (
              <TextField
                id={PRODUCT_SKU_INPUT_ID}
                label="SKU"
                aria-required
                required
                variant="outlined"
                {...field}
                sx={styles.getAddOrderItemSKUInputSx()}
                {...register(PRODUCT_SKU_FIELD, {
                  required: true,
                })}
                error={!!errors.sku}
              />
            )}
          />
          {!!errors.sku && (
            <Typography
              variant="body2"
              color="red"
              sx={{ position: 'absolute', top: '58px' }}
            >
              {errors.sku.message}
            </Typography>
          )}
        </Grid>
        <Grid item container xs={12} sx={{ mt: '30px', position: 'relative' }}>
          <Grid item xs={8}>
            <Controller
              name={QUANTITY_FIELD}
              control={control}
              defaultValue={PRODUCT_QUANTITY_MIN}
              render={({ field }) => (
                <TextField
                  id={PRODUCT_QUANTITY_TEXT_ID}
                  label="Quantity"
                  aria-required
                  required
                  variant="outlined"
                  type="number"
                  sx={styles.getAddOrderItemSKUInputSx('35%')}
                  {...field}
                  {...register(QUANTITY_FIELD, { required: true })}
                  onChange={handleChangeQuantityHoF(field.onChange)}
                />
              )}
            />
          </Grid>
        </Grid>
        <ButtonGroup sx={styles.AddOrderItemCancelButtonGroupSx}>
          <Button onClick={props.closeModal} sx={{ mr: '16px' }}>
            Cancel
          </Button>
          <LoadingButton
            disabled={isUpdateDisabled()}
            variant="contained"
            type="submit"
            value="submit"
            loading={loading}
            color="primary"
            loadingIndicator={<CircularProgress color="primary" size={20} />}
          >
            Save
          </LoadingButton>
        </ButtonGroup>
      </Stack>
    </form>
  );
};

export default AddOrderItemByProductForm;
