import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { AssetsCSVInitialState } from './types';
import {
  downloadAssetErrors,
  downloadAssetTemplate,
  getAssetsCSV,
  getAssetsCSVDirect,
  getAssetUploadCollaborator,
  searchAssetUploads,
  uploadAssets,
} from './actions';
import { WritableDraft } from 'immer';
import { OrdersCSVInitialState } from '../../orders/csv/types';
import { processIncomingUploads } from 'store/shared/uploads/utils';
import { ViewableUploadMetadata } from 'store/shared/uploads/types';

export const getAssetsPendingReducer: CaseReducer<
  AssetsCSVInitialState,
  ReturnType<typeof getAssetsCSV.pending>
> = (state, action) => {
  state.loadingState = 'pending';
  state.errorMessage = '';
  state.url = '';
};

export const getAssetsFulfilledReducer: CaseReducer<
  AssetsCSVInitialState,
  ReturnType<typeof getAssetsCSV.fulfilled>
> = (state, action) => {
  state.loadingState = 'fulfilled';
  state.errorMessage = '';
  state.url = action.payload.downloadUrl;
};

export const getAssetsRejectedReducer: CaseReducer<
  AssetsCSVInitialState,
  ReturnType<typeof getAssetsCSV.rejected>
> = (state, action) => {
  state.loadingState = 'rejected';
  state.url = '';
  const { error } = action;
  if (
    error &&
    error.code === 'ERR_BAD_REQUEST' &&
    error?.message?.match('413')
  ) {
    state.errorMessage = 'CSV file is too large. Please contact support';
  } else {
    state.errorMessage = 'Unknown error occurred. Please contact support';
  }
};

// Direct File
export const getAssetsDirectPendingReducer: CaseReducer<
  AssetsCSVInitialState,
  ReturnType<typeof getAssetsCSVDirect.pending>
> = (state, action) => {
  state.loadingState = 'pending';
  state.data = '';
  state.errorMessage = '';
};

export const getAssetsDirectFulfilledReducer: CaseReducer<
  AssetsCSVInitialState,
  ReturnType<typeof getAssetsCSVDirect.fulfilled>
> = (state, action) => {
  state.loadingState = 'fulfilled';
  state.data = action.payload.data;
  state.errorMessage = '';
};

export const getAssetsDirectRejectedReducer: CaseReducer<
  AssetsCSVInitialState,
  ReturnType<typeof getAssetsCSVDirect.rejected>
> = (state, action) => {
  state.loadingState = 'rejected';
  state.data = '';
  const { error } = action;
  if (
    error &&
    error.code === 'ERR_BAD_REQUEST' &&
    error?.message?.match('413')
  ) {
    state.errorMessage = 'CSV file is too large. Please contact support';
  } else {
    state.errorMessage = 'Unknown error occurred. Please contact support';
  }
};

export const setAssetsCSVDownloadURLReducer = (
  state: WritableDraft<OrdersCSVInitialState>,
  action: PayloadAction<string>
) => {
  state.url = action.payload;
};

export const uploadAssetsRejectedReducer: CaseReducer<
  AssetsCSVInitialState,
  ReturnType<typeof uploadAssets.rejected>
> = (state) => {
  state.upload.fileUploadLoadingState = 'rejected';
  state.upload.errorMessage = 'Unknown error occurred. Please contact support';
};

export const resetCSVDownloadStateReducer: CaseReducer<
  AssetsCSVInitialState
> = (state) => {
  state.loadingState = 'idle';
  state.url = '';
  state.errorMessage = '';
  state.data = '';
};

export const uploadAssetsPendingReducer: CaseReducer<
  AssetsCSVInitialState,
  ReturnType<typeof uploadAssets.pending>
> = (state) => {
  state.upload.fileUploadLoadingState = 'pending';
  state.upload.errorMessage = '';
};

export const uploadAssetsFulfilledReducer: CaseReducer<
  AssetsCSVInitialState,
  ReturnType<typeof uploadAssets.fulfilled>
> = (state) => {
  state.upload.fileUploadLoadingState = 'fulfilled';
  state.upload.errorMessage = '';
};

export const getAssetTemplatePendingReducer: CaseReducer<
  AssetsCSVInitialState,
  ReturnType<typeof downloadAssetTemplate.pending>
> = (state, action) => {
  state.upload.templateLoadingState = 'pending';
  state.upload.templateData = '';
  state.upload.errorMessage = '';
};

export const getAssetTemplateFulfilledReducer: CaseReducer<
  AssetsCSVInitialState,
  ReturnType<typeof downloadAssetTemplate.fulfilled>
> = (state, action) => {
  state.upload.templateLoadingState = 'fulfilled';
  state.upload.templateData = action.payload.data;
  state.upload.errorMessage = '';
};

export const getAssetTemplateRejectedReducer: CaseReducer<
  AssetsCSVInitialState,
  ReturnType<typeof downloadAssetTemplate.rejected>
> = (state, action) => {
  state.upload.templateLoadingState = 'rejected';
  state.upload.templateData = '';
  const { error } = action;
  if (
    error &&
    error.code === 'ERR_BAD_REQUEST' &&
    error?.message?.match('413')
  ) {
    state.upload.errorMessage = 'CSV file is too large. Please contact support';
  } else {
    state.upload.errorMessage =
      'Unknown error occurred. Please contact support';
  }
};

export const getAssetErrorsPendingReducer: CaseReducer<
  AssetsCSVInitialState,
  ReturnType<typeof downloadAssetErrors.pending>
> = (state, action) => {
  state.upload.selectedUpload.errorsLoadingState = 'pending';
  state.upload.selectedUpload.errorsData = '';
  state.upload.errorMessage = '';
};

export const getAssetErrorsFulfilledReducer: CaseReducer<
  AssetsCSVInitialState,
  ReturnType<typeof downloadAssetErrors.fulfilled>
> = (state, action) => {
  state.upload.selectedUpload.errorsLoadingState = 'fulfilled';
  state.upload.selectedUpload.errorsData = action.payload.data;
  state.upload.errorMessage = '';
};

export const getAssetErrorsRejectedReducer: CaseReducer<
  AssetsCSVInitialState,
  ReturnType<typeof downloadAssetErrors.rejected>
> = (state, action) => {
  state.upload.selectedUpload.errorsLoadingState = 'rejected';
  state.upload.selectedUpload.errorsData = '';
  const { error } = action;
  if (
    error &&
    error.code === 'ERR_BAD_REQUEST' &&
    error?.message?.match('413')
  ) {
    state.upload.errorMessage = 'CSV file is too large. Please contact support';
  } else {
    state.upload.errorMessage =
      'Unknown error occurred. Please contact support';
  }
};

export const searchAssetUploadsPendingReducer: CaseReducer<
  AssetsCSVInitialState,
  ReturnType<typeof downloadAssetErrors.pending>
> = (state, action) => {
  state.upload.selectedUpload.errorsLoadingState = 'pending';
  state.upload.selectedUpload.errorsData = '';
  state.upload.errorMessage = '';
};

export const searchAssetUploadsFulfilledReducer: CaseReducer<
  AssetsCSVInitialState,
  ReturnType<typeof searchAssetUploads.fulfilled>
> = (state, action) => {
  const { uploads, showNewUploadNotification } = processIncomingUploads(
    action.payload.items,
    state.upload.uploads,
    state.upload.newUploadQuery.lookback
  );
  state.upload.uploads = uploads;
  state.upload.showNewUploadNotification =
    showNewUploadNotification || state.upload.showNewUploadNotification;
  state.upload.historicUploadQuery.count =
    action.payload.count || state.upload.historicUploadQuery.count;
};

export const getAssetUploadCollaboratorFulfilledReducer: CaseReducer<
  AssetsCSVInitialState,
  ReturnType<typeof getAssetUploadCollaborator.fulfilled>
> = (state, action) => {
  state.upload.uploads = state.upload.uploads.map((upload) => {
    const collaborator = action.payload;
    if (upload.collaboratorId === collaborator.id) {
      return {
        ...upload,
        collaborator: {
          id: collaborator.id,
          name: `${collaborator.firstName} ${collaborator.lastName}`,
        },
      };
    }
    return upload;
  });
};

export const setUploadsReducer: CaseReducer<
  AssetsCSVInitialState,
  PayloadAction<ViewableUploadMetadata[]>
> = (state, action) => {
  state.upload.uploads = action.payload;
};

export const setUploadViewedReducer: CaseReducer<
  AssetsCSVInitialState,
  PayloadAction<number>
> = (state, action) => {
  state.upload.uploads = state.upload.uploads.map((upload) => {
    if (upload.timestamp === action.payload) {
      return {
        ...upload,
        viewed: true,
      };
    }
    return upload;
  });
};

export const setHistoricUploadQueryReducer: CaseReducer<
  AssetsCSVInitialState,
  PayloadAction<{ lookback?: number; offset?: number; limit?: number }>
> = (state, action) => {
  state.upload.historicUploadQuery = {
    ...state.upload.historicUploadQuery,
    ...action.payload,
  };
};

export const resetHistoricUploadQueryReducer: CaseReducer<
  AssetsCSVInitialState
> = (state) => {
  state.upload.historicUploadQuery = {
    lookback: 14,
    limit: 5,
    offset: 0,
    count: 0,
  };
};

export const setSelectedUploadStateReducer: CaseReducer<
  AssetsCSVInitialState,
  PayloadAction<number | undefined>
> = (state, action) => {
  if (state.upload.selectedUpload.timestamp === action.payload) return;

  state.upload.selectedUpload = {
    timestamp: action.payload,
    errorsData: '',
    errorsLoadingState: 'idle',
  };
};

export const setPopoverOpenStateReducer: CaseReducer<
  AssetsCSVInitialState,
  PayloadAction<boolean>
> = (state, action) => {
  state.upload.popover.open = action.payload;
};

export const setPopoverFileReducer: CaseReducer<
  AssetsCSVInitialState,
  PayloadAction<File | null>
> = (state, action) => {
  state.upload.popover.file = action.payload;
};

export const clearShowNewUploadNotificationReducer: CaseReducer<
  AssetsCSVInitialState
> = (state) => {
  state.upload.showNewUploadNotification = false;
};

export const togglePopoverHistoryLookbackDropdownReducer: CaseReducer<
  AssetsCSVInitialState,
  PayloadAction<boolean>
> = (state, action) => {
  state.upload.popover.history.lookbackDropdownOpen = action.payload;
};

export const resetUploadTemplateDownloadStateReducer: CaseReducer<
  AssetsCSVInitialState
> = (state) => {
  state.upload.templateLoadingState = 'idle';
  state.upload.templateData = '';
  state.upload.errorMessage = '';
};

export const resetUploadErrorsDownloadStateReducer: CaseReducer<
  AssetsCSVInitialState
> = (state) => {
  state.upload.selectedUpload.errorsLoadingState = 'idle';
  state.upload.selectedUpload.errorsData = '';
  state.upload.errorMessage = '';
};

export const resetUploadFileStateReducer: CaseReducer<
  AssetsCSVInitialState
> = (state) => {
  state.upload.fileUploadLoadingState = 'idle';
  state.upload.errorMessage = '';
};
