import { FileDownloadService } from 'api/rest/config';
import { AxiosResponse } from 'axios';

export const getOrdersCSV = async (userId: string, orgId: string) => {
  return FileDownloadService.get<
    { userId: string; orgId: string },
    Promise<{ downloadUrl: string }>
  >(`/orders/${orgId}/collaborators/${userId}`);
};

export const getOrdersCSVDirect = async (userId: string, orgId: string) => {
  return FileDownloadService.get<
    { userId: string; orgId: string },
    Promise<AxiosResponse>
  >(`/orders/${orgId}/collaborators/${userId}/file`);
};
