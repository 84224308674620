import { AlertColor } from '@mui/material';
import React from 'react';
import { createGlobalState } from 'react-hooks-global-state';
import { Collaborator, Organization } from 'types';

export type Alert = {
  message: React.ReactNode;
  severity: AlertColor;
};

export type AppBarCTAs = {
  components: React.ReactNode;
};

export type InitialState = {
  alert: Alert | undefined;
  appBarCTAs: AppBarCTAs | undefined;
  userOrganization: Organization | undefined;
  globalPurchaser: Collaborator | undefined;
};

const initialState: InitialState = {
  alert: undefined,
  appBarCTAs: undefined,
  userOrganization: undefined,
  globalPurchaser: undefined,
};

const { useGlobalState, setGlobalState } = createGlobalState(initialState);

export { useGlobalState, setGlobalState };
