import { gql } from '@apollo/client';

export const SEARCH_INVENTORY_RECEIPTS = gql`
  query searchInventoryReceipts(
    $assetId: ID!
    $offset: Int
    $limit: Int
    $orderAsc: String
    $orderDesc: String
  ) {
    searchInventoryReceipts(
      inventoryReceiptSearchInput: {
        assetId: $assetId
        offset: $offset
        limit: $limit
        orderAsc: $orderAsc
        orderDesc: $orderDesc
      }
    ) {
      inventoryReceipts {
        id
        assetId
        checkedInAt
        checkedOutAt
        depotId
        inboundOrderItem {
          id
          orderItemNumber
        }
        outboundOrderItem {
          id
          orderItemNumber
        }
        depot {
          id
          name
        }
        identifier
        lastTouchedAt
        productStatus
      }
      count
    }
  }
`;
