/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'store/types';
import { assetManagementSlice } from 'store/slices';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FilterBankEntry } from 'types';
import { AMComplexFilterBanTestId } from './constants';
import * as AMComplexFilterBankStyles from './styles';
import GAButton from 'components/buttons/google_analytics/GAButton';

const AMComplexFilterBank = () => {
  const styles = AMComplexFilterBankStyles;
  const dispatch = useDispatch();
  const filterBankEntries = useSelector<GlobalState, FilterBankEntry[]>(
    (state) => state.assetManagement.filterBankEntries
  );

  const hasFilters = useSelector<GlobalState, boolean>(
    (state) => state.assetManagement.isFiltered
  );

  if (!hasFilters) return <></>;

  const getChip = (filterEntry: FilterBankEntry) => {
    const deleteChip = () => {
      dispatch(
        assetManagementSlice.actions.removeFilterReducer({ ...filterEntry })
      );
    };

    return (
      <Chip
        key={`${filterEntry.displayValue}-${
          filterEntry.selectedOptionsList
        }-${nanoid()}`}
        label={filterEntry.displayValue}
        onDelete={deleteChip}
        sx={styles.AMComplexFilterBankChipSx}
      />
    );
  };

  const clearFilters = () => {
    dispatch(assetManagementSlice.actions.resetFiltersReducer());
  };

  return (
    <Stack
      direction="row"
      sx={styles.AMComplexFilterStackSx}
      justifyContent="space-between"
    >
      <Stack
        direction="row"
        spacing="8px"
        data-testid={AMComplexFilterBanTestId.FILTER_BANK}
        sx={styles.FilterBankBoundingStackSx}
      >
        {filterBankEntries.map((filterEntry: FilterBankEntry) =>
          getChip(filterEntry)
        )}
      </Stack>
      <Box data-testid={AMComplexFilterBanTestId.CLEAR_BUTTON_BOX}>
        <GAButton
          gaContext={{
            navigates_to: 'N/A',
            textCopy: 'Clear all filters',
            purpose: 'Clears All AM Filters',
          }}
          disableRipple
          startIcon={<CloseIcon />}
          sx={styles.FilterBankClearButtonSx}
          onClick={clearFilters}
        >
          <Typography>Clear all filters</Typography>
        </GAButton>
      </Box>
    </Stack>
  );
};

export default AMComplexFilterBank;
