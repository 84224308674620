import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { UserPermissions } from 'global-constants';
import useGetUserPermissions from 'hooks/useGetUserPermissions';
import { BillingButtonSx } from './styles';
import { BillingModalContext } from 'context/BillingModalProvider';
import { BillingCTAProps } from './types';

const BillingCTA = (props: BillingCTAProps) => {
  const { isAuthenticated } = useAuth0();
  const userPermissions = useGetUserPermissions();

  const billingContext = React.useContext(BillingModalContext);

  const buttonName = billingContext.hasBillingInfo
    ? 'EDIT AUTO BILLING'
    : 'SET UP AUTO BILLING';

  const handleClick = () => {
    billingContext.setOpen(true);
  };

  const returnNull =
    props.hidden || !userPermissions.includes(UserPermissions.BILLING__VIEW);

  if (returnNull) return null;

  return (
    <>
      {!isAuthenticated ? (
        <Skeleton
          variant="rounded"
          width={250}
          height={40}
          sx={{
            mx: 0,
            my: 'auto',
          }}
        />
      ) : (
        <Button
          variant="outlined"
          sx={BillingButtonSx}
          onClick={handleClick}
          disableRipple
        >
          <Typography variant="body2">{buttonName}</Typography>
        </Button>
      )}
    </>
  );
};

export default BillingCTA;
