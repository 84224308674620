import { NavigationRoutes } from 'global-constants';

export const MANAGEMENT_TOGGLE_OPTIONS: { url: string; displayName: string }[] =
  [
    {
      url: NavigationRoutes.ORDER_MANAGEMENT,
      displayName: 'Order Management',
    },
    {
      url: NavigationRoutes.ASSET_MANAGEMENT,
      displayName: 'Asset Management',
    },
  ];
