export const ContainerSx = {
  mt: '15px',
};

export const CodeLabelSx = {
  fontSize: '16px',
  fontWeight: '700',
  mb: '15px',
};

export const CodeTextFieldSx = {
  width: '30%',
  backgroundColor: 'white',
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    top: 'auto',
    left: 'auto',
  },
};

export const NotesContainerSx = {
  mt: '15px',
};

export const NotesLabelSx = {
  fontSize: '16px',
  fontWeight: '700',
  mb: '15px',
};

export const NotesTextFieldSx = {
  width: '50%',
  backgroundColor: 'white',
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    top: 'auto',
    left: 'auto',
  },
};
