/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as HomeUpdateStyles from './styles';
import {
  CONTAINER_TEST_DATA_ID,
  HOME_MARKETING_LINK,
  HOME_PAGE_UPDATE_TITLE_TEXT_ID,
} from './constants';
import HomePageOrderSection from './components/HomeOrders';
import HomePageAssetsSection from './components/HomeAssets';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import WhatsNewSection from './components/WhatsNew';
import FAQsSection from './components/FAQs';
import RetrievalConfirmationModal from 'components/RetrievalConfirmationModal';
import TimeSavingsSection from './components/TimeSavings';
import allwhereRunningEarthCropped from 'assets/allwhereRunningEarthCrop.png';
import allwhereMarketingImg from 'assets/homepageMarketingPic001.png';
import { GlobalModals } from 'store/constants';
import { GlobalState } from 'store/types';
import { modalSlice } from 'store/slices';

const HomeUpdate = () => {
  const styles = HomeUpdateStyles;
  const dispatch = useDispatch();
  const userOrganization = useGetUserOrganization();

  const getOrganizationName = () => String(userOrganization?.name ?? '');

  const currentModal = useSelector<GlobalState>(
    (state) => state.modal.currentModal
  );

  const showRetrievalConfirmationModal =
    currentModal === GlobalModals.RETRIEVAL_CONFIRMATION;

  const handleCloseRetrievalConfirmationModal = () => {
    dispatch(modalSlice.actions.reset());
  };

  return (
    <>
      <Container
        sx={styles.MainContainerSx}
        data-testid={CONTAINER_TEST_DATA_ID}
      >
        <Stack sx={styles.HomePageStackSx} direction="column">
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="flex-end"
            sx={styles.HomePageWelcomeStackSx}
          >
            <Typography id={HOME_PAGE_UPDATE_TITLE_TEXT_ID} pb="24px">
              Welcome, {getOrganizationName()}
            </Typography>

            <Box>
              <img
                src={allwhereRunningEarthCropped}
                alt="allwhere-running-earth-cropped"
                width="217px"
              />
            </Box>
          </Stack>
          <Stack sx={styles.HomePageContentStackSx}>
            <Stack sx={styles.HomePageMainContentStackSx}>
              <TimeSavingsSection />
              <HomePageOrderSection />
              <HomePageAssetsSection />
            </Stack>
            <Stack sx={styles.HomePageSideBarContentStackSx}>
              <WhatsNewSection />
              <FAQsSection />
              <Stack
                sx={styles.HomePageMarketingStackSx}
                onClick={() => window.open(HOME_MARKETING_LINK, '_blank')}
                flexDirection="row"
                justifyContent="flex-center"
              >
                <Box>
                  <img
                    src={allwhereMarketingImg}
                    alt="marketing-link-img"
                    height="110px"
                  />
                </Box>
                <Box className="home-marketing-box">
                  <Typography
                    className="home-marketing-date"
                    variant="overline"
                  >
                    JUN 7 2023
                  </Typography>
                  <Typography className="home-marketing-title">
                    The Dummy's Guide to IT for the HR Leader
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Container>
      <RetrievalConfirmationModal
        open={showRetrievalConfirmationModal}
        handleClose={handleCloseRetrievalConfirmationModal}
      />
    </>
  );
};

export default HomeUpdate;
