import { ReviewSearchFilterValues } from './types';
import { RetrievalAssetTypes } from 'pages/Retrieval/constants';

export const LAPTOP = 'Laptop';
export const MOBILE = 'Mobile';
export const TABLET = 'Tablet';

export const FILTER_PREFIX = 'checkbox';
export const FILTER_TERMS = [LAPTOP, TABLET, MOBILE];
export const NAME_SEARCH_FIELD = 'nameSearch';

export const DERIVED_FILTER_FIELDS = FILTER_TERMS.map(
  (term) => FILTER_PREFIX + term
);

const FILTER_TERMS_DEFAULT_VALUES = DERIVED_FILTER_FIELDS.reduce(
  (acc: Record<string, boolean>, nextTerm: string) => {
    acc[`${nextTerm}`] = false;
    return acc;
  },
  {}
);

export const DEFAULT_SEARCH_FILTER_VALUES: ReviewSearchFilterValues = {
  ...FILTER_TERMS_DEFAULT_VALUES,
  [NAME_SEARCH_FIELD]: '',
};

export enum ReviewStepModals {
  NONE,
  ADD_ASSET,
  ADD_ADDRESS,
}

export const CHECKBOX_ASSET_TYPES = Object.values(RetrievalAssetTypes);
