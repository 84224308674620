/* eslint-disable sort-exports/sort-exports */
import Stack from '@mui/material/Stack';
import { useDispatch } from 'react-redux';
import { assetManagementSlice } from 'store/slices';
import Typography from '@mui/material/Typography';
import ComplexTypeAheadContainer from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/components/ComplexTypeAheadContainer';
import { AMDashComplexFilterTestIds } from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/constants';
import * as AMDashComplexFilterStyles from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/styles';
import { ASSET_MANAGEMENT_FILTER_KEYS } from 'store/constants';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import {
  GET_ASSIGNEE_NAME_OPTIONS,
  GET_ASSIGNEE_EMAIL_OPTIONS,
} from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/components/AssigneeInfoFilterSection/queries';
import {
  ASSIGNEE_NAME_AVATAR_TESTID,
  ASSIGNEE_EMAIL_AVATAR_TESTID,
} from './constants';
import { AssigneeInfoFilterSectionProps } from './types';

const AssigneeInfoFilterSection = (props: AssigneeInfoFilterSectionProps) => {
  const dispatch = useDispatch();

  const drawerStyles = AMDashComplexFilterStyles;

  const [getAssigneeNameOptions] = useSafeLazyQuery(GET_ASSIGNEE_NAME_OPTIONS, {
    onCompleted: ({ getCollaboratorAutocompleteSuggestions }) => {
      const matchIds = getCollaboratorAutocompleteSuggestions?.matchIds ?? [];
      const matches = getCollaboratorAutocompleteSuggestions?.matches ?? [];

      const valueIdOptions = matches.map((match: string, index: number) => [
        match,
        matchIds[index],
      ]);

      dispatch(
        assetManagementSlice.actions.populateAvailableTypeAHeadsReducer({
          optionKey: ASSET_MANAGEMENT_FILTER_KEYS.ASSIGNEES,
          options: valueIdOptions,
        })
      );
    },
  });

  const [getAssigneeEmailOptions] = useSafeLazyQuery(
    GET_ASSIGNEE_EMAIL_OPTIONS,
    {
      onCompleted: ({ getCollaboratorAutocompleteSuggestions }) => {
        const matchIds = getCollaboratorAutocompleteSuggestions?.matchIds ?? [];
        const matches = getCollaboratorAutocompleteSuggestions?.matches ?? [];

        const valueIdOptions = matches.map((match: string, index: number) => [
          match,
          matchIds[index],
        ]);

        dispatch(
          assetManagementSlice.actions.populateAvailableTypeAHeadsReducer({
            optionKey: ASSET_MANAGEMENT_FILTER_KEYS.ASSIGNEE_EMAILS,
            options: valueIdOptions,
          })
        );
      },
    }
  );

  const handleAssigneeNameDebounceEffect = (debounceValue: string) => {
    getAssigneeNameOptions({
      variables: {
        searchTerm: `%${debounceValue}%`,
      },
    });
  };

  const handleAssigneeEmailDebounceEffect = (debounceValue: string) => {
    getAssigneeEmailOptions({
      variables: {
        searchTerm: `%${debounceValue}%`,
      },
    });
  };

  const clearFiltersByField = (filterKey: string) => {
    dispatch(assetManagementSlice.actions.clearFilterByTypeReducer(filterKey));
  };

  const clearOptionsByField = (filterKey: string) => {
    dispatch(assetManagementSlice.actions.clearOptionsByTypeReducer(filterKey));
  };

  return (
    <Stack
      direction="column"
      data-testid={AMDashComplexFilterTestIds.ASSIGNEE_INFO}
      sx={drawerStyles.AMComplexFilterStackSx}
    >
      <Typography sx={drawerStyles.AMComplexFilterSectionTitleSx}>
        Assignee Info
      </Typography>
      <Stack direction="row" rowGap="12px" justifyContent="space-between">
        <ComplexTypeAheadContainer
          alias="Assignee Name Lookup"
          filterKey={ASSET_MANAGEMENT_FILTER_KEYS.ASSIGNEES}
          counterAvatarTestId={ASSIGNEE_NAME_AVATAR_TESTID}
          renderInputLabel="Assignee"
          clearFiltersByField={clearFiltersByField}
          clearOptionsByField={clearOptionsByField}
          handleDebounceEffect={handleAssigneeNameDebounceEffect}
        />
        <ComplexTypeAheadContainer
          counterAvatarTestId={ASSIGNEE_EMAIL_AVATAR_TESTID}
          alias="Assignee Email Lookup"
          filterKey={ASSET_MANAGEMENT_FILTER_KEYS.ASSIGNEE_EMAILS}
          renderInputLabel="Assignee Email"
          clearFiltersByField={clearFiltersByField}
          clearOptionsByField={clearOptionsByField}
          handleDebounceEffect={handleAssigneeEmailDebounceEffect}
        />
      </Stack>
    </Stack>
  );
};

export default AssigneeInfoFilterSection;
