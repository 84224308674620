import React from 'react';
import { RetrievalFlowStep } from 'pages/Retrieval/RetrievalService/constants';
import Stack from '@mui/material/Stack';
import {
  RetrievalOptionAnswer,
  RetrievalDestination,
  UseRetrievalService,
  RetrievalAsset,
  RetrievalCollaborator,
} from 'pages/Retrieval/RetrievalService/types';
import {
  initialState,
  retrievalServiceSlice,
} from 'pages/Retrieval/RetrievalService/store';
import {
  HomeAddress,
  RetrievalOrigin,
  SelectableRecipientAddress,
  WorkAddress,
} from 'types';
import { RetrievalOption } from 'pages/Retrieval/components/ReviewStep/components/RetrievalOptionValueForm/types';
import { useSelector } from 'react-redux';
import { selectGlobalNotificationBanner } from '../store/slices/global/selectors';
import * as styles from './styles';

const {
  addAssetToEmployee,
  addShippingAddressOptionsToEmployee,
  addHomeAndWorkAddressesToEmployee,
  addShippingAddressToEmployee,
  answerRetrievalOptions,
  assignRetrievalOrigin,
  assignRetrievalDestination,
  cancelReviewStep,
  cancelSelectEmployeesStep,
  cancelSelectLocationsStep,
  cancelSubmitStep,
  clearEmployees,
  initOptionValuesByType,
  initAssetsOptionAnswerKey,
  removeEmployee,
  removeShippingAddressFromEmployee,
  resetRetrievalState,
  selectEmployees,
  setEmployeeShipping,
  setEmployeeEmail,
  toggleEmployeeAsset,
  toggleGlobalAssetSelection,
  updateActiveStep,
} = retrievalServiceSlice.actions;

export const RetrievalContext = React.createContext<UseRetrievalService>({
  ...initialState,
  addAssetToEmployee: (id: string, asset: RetrievalAsset) => {},
  addShippingAddressOptionsToEmployee: (
    id: string,
    addresses: SelectableRecipientAddress[]
  ) => {},
  addHomeAndWorkAddressesToEmployee: (
    id: string,
    homeAddresses: HomeAddress[],
    workAddresses: WorkAddress[]
  ) => {},
  addShippingAddressToEmployee: (
    id: string,
    address: SelectableRecipientAddress
  ) => {},
  removeShippingAddressFromEmployee: (id: string) => {},
  answerRetrievalOptions: (
    employeeId: string,
    assetId: string,
    retrievalAnswer: RetrievalOptionAnswer
  ) => {},
  assignRetrievalOrigin: (payload: RetrievalOrigin) => {},
  assignRetrievalDestination: (payload: RetrievalDestination | undefined) => {},
  cancelReviewStep: () => {},
  cancelSelectEmployeesStep: () => {},
  cancelSelectLocationsStep: () => {},
  cancelSubmitStep: () => {},
  clearEmployees: () => {},
  gotoEmployeeStep: () => {},
  gotoLocationStep: () => {},
  gotoReviewStep: () => {},
  gotoSubmitStep: () => {},
  initOptionValuesByType: (payload: Record<string, RetrievalOption[]>) => {},
  initAssetsOptionAnswerKey: (
    payload: Record<string, RetrievalOptionAnswer[]>
  ) => {},
  removeEmployee: (payload: string) => {},
  resetRetrievalState: () => {},
  selectEmployees: (payload: RetrievalCollaborator[]) => {},
  setEmployeeShipping: (employeeId: string, isRush: boolean) => {},
  setEmployeeEmail: (employeeId: string, email: string) => {},
  toggleEmployeeAsset: (payload: object) => {},
  toggleGlobalAssetSelection: (
    employeeId: string,
    assetId: string,
    selected: boolean
  ) => {},
  updateActiveStep: (payload: object) => {},
});

const RetrievalServiceProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [retrievalState, dispatch] = React.useReducer(
    retrievalServiceSlice.reducer,
    retrievalServiceSlice.getInitialState()
  );

  const globalNotificationBanner = useSelector(selectGlobalNotificationBanner);

  const memoContextValue = React.useMemo(
    () => ({
      ...retrievalState,
      addAssetToEmployee: (id: string, asset: RetrievalAsset) =>
        dispatch(addAssetToEmployee({ id, asset })),
      addShippingAddressOptionsToEmployee: (
        id: string,
        addresses: SelectableRecipientAddress[]
      ) => {
        dispatch(addShippingAddressOptionsToEmployee({ id, addresses }));
      },
      addHomeAndWorkAddressesToEmployee: (
        id: string,
        homeAddresses: HomeAddress[],
        workAddresses: WorkAddress[]
      ) => {
        dispatch(
          addHomeAndWorkAddressesToEmployee({
            id,
            homeAddresses,
            workAddresses,
          })
        );
      },
      addShippingAddressToEmployee: (
        id: string,
        address: SelectableRecipientAddress
      ) => {
        dispatch(addShippingAddressToEmployee({ id, address }));
      },
      answerRetrievalOptions: (
        employeeId: string,
        assetId: string,
        retrievalAnswer: RetrievalOptionAnswer
      ) =>
        dispatch(
          answerRetrievalOptions({ employeeId, assetId, retrievalAnswer })
        ),
      assignRetrievalOrigin: (payload: RetrievalOrigin) =>
        dispatch(assignRetrievalOrigin(payload)),
      assignRetrievalDestination: (payload: RetrievalDestination | undefined) =>
        dispatch(assignRetrievalDestination(payload)),
      cancelReviewStep: () => dispatch(cancelReviewStep()),
      cancelSelectEmployeesStep: () => dispatch(cancelSelectEmployeesStep()),
      cancelSelectLocationsStep: () => dispatch(cancelSelectLocationsStep()),
      cancelSubmitStep: () =>
        dispatch(cancelSubmitStep(RetrievalFlowStep.RETRIEVAL_SUBMISSION)),
      clearEmployees: () => dispatch(clearEmployees()),
      gotoEmployeeStep: () =>
        dispatch(updateActiveStep(RetrievalFlowStep.EMPLOYEE_SELECTION)),
      gotoLocationStep: () =>
        dispatch(updateActiveStep(RetrievalFlowStep.CHOOSE_TO_FROM_LOCATIONS)),
      gotoReviewStep: () =>
        dispatch(updateActiveStep(RetrievalFlowStep.REVIEW_SELECTIONS)),
      gotoSubmitStep: () =>
        dispatch(updateActiveStep(RetrievalFlowStep.RETRIEVAL_SUBMISSION)),
      initOptionValuesByType: (payload: Record<string, RetrievalOption[]>) =>
        dispatch(initOptionValuesByType(payload)),
      initAssetsOptionAnswerKey: (
        payload: Record<string, RetrievalOptionAnswer[]>
      ) => dispatch(initAssetsOptionAnswerKey(payload)),
      removeEmployee: (payload: string) => dispatch(removeEmployee(payload)),
      removeShippingAddressFromEmployee: (id: string) =>
        dispatch(removeShippingAddressFromEmployee({ id })),
      resetRetrievalState: () => dispatch(resetRetrievalState()),
      selectEmployees: (payload: RetrievalCollaborator[]) =>
        dispatch(selectEmployees(payload)),
      setEmployeeShipping: (employeeId: string, isRush: boolean) =>
        dispatch(setEmployeeShipping([employeeId, isRush])),
      setEmployeeEmail: (employeeId: string, email: string) =>
        dispatch(setEmployeeEmail([employeeId, email])),
      toggleEmployeeAsset: (payload: object) =>
        dispatch(toggleEmployeeAsset(payload)),
      toggleGlobalAssetSelection: (
        employeeId: string,
        assetId: string,
        selected: boolean
      ) =>
        dispatch(toggleGlobalAssetSelection({ assetId, employeeId, selected })),
      updateActiveStep: (payload: object) =>
        dispatch(updateActiveStep(payload)),
    }),
    [retrievalState]
  );

  return (
    <RetrievalContext.Provider value={memoContextValue as any}>
      <Stack sx={styles.RetrievalContainerSx(globalNotificationBanner.show)}>
        {children}
      </Stack>
    </RetrievalContext.Provider>
  );
};

export default RetrievalServiceProvider;
