import { SxProps } from '@mui/material';

export const BoxSx: SxProps = {
  alignItems: 'flex-start',
  backgroundColor: 'colors.grayFA',
  display: 'flex',
  flexDirection: 'column',
  height: '52px',
  justifyContent: 'center',
  mt: '0.5rem',
  ml: '-24px',
  mr: '-24px',
  p: '1.5rem',
};
