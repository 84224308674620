/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useDebouncedSearch from 'hooks/useDebouncedSearch';
import { useSafeQuery } from 'hooks/useSafeQuery';
import GenericAutocomplete from 'components/GenericAutocomplete';
import { SEARCH_ASSET_ORGANIZATIONS } from './queries';
import {
  AssetFiltersEnum,
  ASSET_FILTER_SETTINGS,
  ASSET_STATUS_OPTIONS,
  ASSET_STATUS_OPTION_VALUES,
  ASSET_FILTER_WIDTH,
} from './constants';
import { AssetManagementDashFilterProps } from './types';
import * as AssetManagementDashFilterStyles from './styles';
import GAButton from 'components/buttons/google_analytics/GAButton';

const AssetManagementDashFilters = (props: AssetManagementDashFilterProps) => {
  const styles = AssetManagementDashFilterStyles;

  // Asset Status Filter Settings
  const DeviceStatusFilterSettings =
    ASSET_FILTER_SETTINGS[AssetFiltersEnum.STATUS];
  const statusQueryField = DeviceStatusFilterSettings.searchAssetsQueryField;
  const chosenAssetStatus = props.filterTable?.status ?? '';
  const chosenOrgId = props?.filterTable?.organizationId ?? '';

  const handleAssetStatusClear = () => {
    props.resetFilterField(statusQueryField);
  };

  const handleAssetStatusChange = (event: SelectChangeEvent) => {
    const { value: newChosenAssetStatus } = event.target;
    if (!newChosenAssetStatus || newChosenAssetStatus === chosenAssetStatus)
      return;
    props.setFilterField({ [statusQueryField]: newChosenAssetStatus });
  };

  // Organization Filter Settings
  const OrganizationFilterSettings =
    ASSET_FILTER_SETTINGS[AssetFiltersEnum.ORGANIZATION];
  const [organizationName, setOrganizationName] = React.useState<string>('');

  const debouncedOrganizationNameSearchTerm = useDebouncedSearch(
    organizationName,
    300
  );
  const [organizationOptions, setOrganizationOptions] = React.useState<
    string[]
  >([]);
  const [orgIdByNameTable, setOrgIdByNameTable] = React.useState<
    Record<string, any>
  >({});

  const { loading: isOrganizationsSearchLoading } = useSafeQuery(
    SEARCH_ASSET_ORGANIZATIONS,
    {
      variables: {
        ...(debouncedOrganizationNameSearchTerm
          ? { name: debouncedOrganizationNameSearchTerm }
          : {}),
        limit: 25,
        offset: 0,
        orderAsc: 'name',
      },
      fetchPolicy: 'network-only',
      onCompleted(data) {
        const { searchOrganizations = {} } = data;
        const { organizations = [] } = searchOrganizations;
        const tempOrgIdByNameTable: { [k: string]: any } = {};
        // eslint-disable-next-line sonarjs/no-unused-collection
        const tempOptions: string[] = [];
        if (!organizations.length) return;
        organizations.forEach((orgItem: any) => {
          const { id, name } = orgItem;
          // eslint-disable-next-line security/detect-object-injection
          tempOrgIdByNameTable[name] = id;
          tempOptions.push(name);
        });
        setOrgIdByNameTable(tempOrgIdByNameTable);
        setOrganizationOptions(tempOptions);
      },
    }
  );

  const handleInputChange = (_e: any, value: any) => {
    if (value.length >= 2) {
      setOrganizationName(value);
    }
  };

  const clearOrgsAutoCompleteOptions = () => {
    const clearTextIconButton: HTMLElement = document.querySelector(
      `#${OrganizationFilterSettings.inputId} .MuiAutocomplete-clearIndicator`
    ) as HTMLElement;

    if (clearTextIconButton) {
      clearTextIconButton.click();
    }

    setOrganizationName('');
    setOrganizationOptions([]);
    setOrgIdByNameTable({});
    props.resetFilterField(OrganizationFilterSettings.searchAssetsQueryField);
  };

  const handleAutoCompleteChange = (event: any, value: any) => {
    if (value === null) {
      clearOrgsAutoCompleteOptions();
      return;
    }

    if (orgIdByNameTable === null) return;

    props.setFilterField({
      [OrganizationFilterSettings.searchAssetsQueryField]:
        orgIdByNameTable[value as string],
    });
  };

  const clearAllFilters = (resetClearFiltersBar?: boolean) => {
    clearOrgsAutoCompleteOptions();
    props.resetFilterField(statusQueryField);

    if (resetClearFiltersBar) {
      props.setClearFiltersBar(false);
    }
  };

  const showClearAllFiltersButton = () =>
    [chosenOrgId, chosenAssetStatus].some((item) => !!item);

  React.useEffect(() => {
    clearAllFilters(true);
  }, []);

  React.useEffect(() => {
    if (!props.clearFiltersBar) return;
    clearAllFilters(true);
  }, [props.clearFiltersBar]);

  React.useEffect(() => {
    if (debouncedOrganizationNameSearchTerm) return;
    clearOrgsAutoCompleteOptions();
  }, [debouncedOrganizationNameSearchTerm]);

  return (
    <Stack direction="column" gap={1}>
      <>
        <Stack
          direction="row"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: '36px', mt: 2 }}
        >
          <Typography component="span" sx={styles.AssetFilterTitleSx}>
            FILTER BY
          </Typography>
          {showClearAllFiltersButton() ? (
            <GAButton
              gaContext={{
                navigates_to: 'N/A',
                textCopy: 'Clear filters',
                purpose: 'Clears all AM filters',
              }}
              disableRipple
              startIcon={<CloseIcon />}
              sx={styles.DeviceFilterClearButtonSx}
              onClick={() => clearAllFilters()}
            >
              <Typography>Clear all filters</Typography>
            </GAButton>
          ) : null}
        </Stack>

        <Stack direction="row" rowGap={2} gap={2}>
          <Stack
            direction="column"
            sx={styles.AutoCompleteStackSx}
            id={OrganizationFilterSettings.inputId}
          >
            <GenericAutocomplete
              loading={isOrganizationsSearchLoading}
              onChange={handleAutoCompleteChange}
              onInputChange={handleInputChange}
              componentsProps={{
                popper: {
                  style: {
                    width: ASSET_FILTER_WIDTH,
                  },
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Start typing"
                  label="Organization"
                  aria-label="Organization"
                  sx={styles.AutoCompleteSearchTextFieldSx}
                />
              )}
              sx={styles.AutoCompleteSx}
              options={organizationOptions}
            />
          </Stack>
          {/* END of working area WARNING */}

          <Stack
            sx={styles.AssetFilterStackSx}
            id={DeviceStatusFilterSettings.stackId}
          >
            {chosenAssetStatus ? (
              <Tooltip title="Clear" arrow>
                <IconButton
                  size="small"
                  aria-label="clear asset status filter"
                  onClick={handleAssetStatusClear}
                  sx={styles.AssetFilterClearButtonSx}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            ) : null}

            <FormControl
              sx={styles.getAssetFilterFormControlSx(
                DeviceStatusFilterSettings.labelId
              )}
            >
              <InputLabel id={DeviceStatusFilterSettings.labelId}>
                {DeviceStatusFilterSettings.label}
              </InputLabel>
              <Select
                value={chosenAssetStatus}
                onChange={handleAssetStatusChange}
                sx={styles.AssetFilterSelectSx}
                placeholder={DeviceStatusFilterSettings.placeholder}
                label={DeviceStatusFilterSettings.label}
                labelId={DeviceStatusFilterSettings.labelId}
                id={DeviceStatusFilterSettings.inputId}
              >
                {ASSET_STATUS_OPTIONS.map(
                  (assetStatusOption: string, optionIndex) => (
                    <MenuItem
                      key={assetStatusOption}
                      // eslint-disable-next-line security/detect-object-injection
                      value={ASSET_STATUS_OPTION_VALUES[optionIndex]}
                    >
                      {assetStatusOption}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </>
    </Stack>
  );
};

export default AssetManagementDashFilters;
