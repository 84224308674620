import { UNREACHABLE } from 'global-constants';
import { RecipientChangeState } from './types';

export const getDefaultRecipientChangeState = (): RecipientChangeState => ({
  origin: UNREACHABLE,
  collaboratorEmail: '',
  collaboratorId: '',
  collaboratorName: '',
  openModal: false,
  changed: false,
});
