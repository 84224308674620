import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DeviceInfoProps } from './types';
import { BoxSx } from './styles';
import { Typography } from '@mui/material';

const DeviceInfo = (props: DeviceInfoProps) => {
  return (
    <Box sx={BoxSx} justifySelf="flex-end !important">
      <Stack
        className="device-info-outer-stack"
        direction="row"
        justifyContent="flex-start"
      >
        <Stack direction="column">
          <Typography className="device-info-header">MAKE</Typography>
          <Typography className="device-info-content">
            {props.make ?? 'N/A'}
          </Typography>
        </Stack>
        <Stack direction="column">
          <Typography className="device-info-header">MODEL</Typography>
          <Typography className="device-info-content">
            {props.model ?? 'N/A'}
          </Typography>
        </Stack>
        <Stack direction="column">
          <Typography className="device-info-header">SERIAL NUMBER</Typography>
          <Typography className="device-info-content">
            {props.serialNumber ?? 'N/A'}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default DeviceInfo;
