export enum RetrievalFlowStep {
  CHOOSE_TO_FROM_LOCATIONS = 'CHOOSE_TO_FROM_LOCATIONS',
  EMPLOYEE_SELECTION = 'EMPLOYEE_SELECTION',
  REVIEW_SELECTIONS = 'REVIEW_SELECTIONS',
  // eslint-disable-next-line typescript-sort-keys/string-enum
  RETRIEVAL_SUBMISSION = 'RETRIEVAL_SUBMISSION',
}

export enum RetrievalFlowStepByOrder {
  CHOOSE_TO_FROM_LOCATIONS,
  EMPLOYEE_SELECTION,
  REVIEW_SELECTIONS,
  RETRIEVAL_SUBMISSION,
}

export enum RetrievalStepNames {
  CHOOSE_TO_FROM_LOCATIONS = 'Select location',
  EMPLOYEE_SELECTION = 'Select employees',
  REVIEW_SELECTIONS = 'Review',
  // eslint-disable-next-line typescript-sort-keys/string-enum
  RETRIEVAL_SUBMISSION = 'Submit',
}
