import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {
  AddRecipientAddressModalProps,
  IAddRecipientAddressFormValues,
} from './types';
import {
  AddressCancelButtonSx,
  AddressDialogPaperSx,
  AddressDialogSx,
  AddressMainTitleSx,
  AddressSaveButtonSx,
  AddressTextFieldBaseSx,
  CloseRecipientIconSx,
} from './styles';
import {
  COUNTRY_MATCHER,
  CountryOptions,
  StateOptions,
  UNITED_STATES,
  US_STATE_MATCHER,
} from 'global-constants';
import { MINIMUM_FIELDS_WITH_REQUIRED_VALUES } from './constant';
import { HOME, WORK, SelectableRecipientAddress } from 'types';
import { Box, Divider } from '@mui/material';
import {
  Close,
  HomeOutlined,
  WorkOutline,
  InfoOutlined,
} from '@mui/icons-material';
import { nanoid } from '@reduxjs/toolkit';
import GAButton from '../buttons/google_analytics/GAButton';
import GAIconButton from '../buttons/google_analytics/GAIconButton';

const AddRecipientAddressModal = (props: AddRecipientAddressModalProps) => {
  const getRecipientAddressFormValues = (
    address: SelectableRecipientAddress
  ): IAddRecipientAddressFormValues => {
    return {
      ...address,
      country: address?.country || COUNTRY_MATCHER[UNITED_STATES as string],
      addressType: address.isWork ? WORK : HOME,
      streetAddress1: address.streetAddress1 || '',
      streetAddress2: address.streetAddress2 || '',
      city: address.city || '',
      zipCode: address.zipCode || '',
      phoneNumber: address.phoneNumber || '',
    };
  };

  const {
    control,
    register,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm<IAddRecipientAddressFormValues>({
    values: getRecipientAddressFormValues(props.address),
    mode: 'onChange',
  });

  const enableState =
    watch()?.country === COUNTRY_MATCHER[UNITED_STATES as string];

  const addressTypeOptions = [
    <MenuItem key={HOME} value={HOME}>
      <Stack direction="row" spacing={1}>
        <HomeOutlined />
        <Typography>Home address</Typography>
      </Stack>
    </MenuItem>,
    <MenuItem key={WORK} value={WORK}>
      <Stack direction="row" spacing={1}>
        <WorkOutline />
        <Typography>Work address</Typography>
      </Stack>
    </MenuItem>,
  ];

  const resetFixedValues = (full: boolean = false) => {
    setValue('phoneNumber', '');
    setValue('state', '');
    setValue('principalRegion', '');
    if (full) {
      setValue('streetAddress1', '');
      setValue('streetAddress2', '');
      setValue('city', '');
      setValue('zipCode', '');
    }
  };

  React.useEffect(() => {
    if (
      touchedFields.country ||
      (watch().country !== COUNTRY_MATCHER[UNITED_STATES as string] &&
        !props.address.id)
    ) {
      resetFixedValues();
    }
  }, [enableState]);

  React.useEffect(() => {
    if (!props.address.id) {
      resetFixedValues(true);
    }
  }, [props.address]);

  const resetForm = () => {
    reset();
    //Have to manually certain values
    resetFixedValues(true);
  };

  const handleCancel = () => {
    props.cancelAddAddress(props.collaboratorId);
    resetForm();
    props.closeModal(true);
  };

  const handleAddressSubmit = (formData: IAddRecipientAddressFormValues) => {
    const shippingAddress: SelectableRecipientAddress = {
      id: props.address.id || `temp-id-${nanoid()}`,
      collaboratorId: props.collaboratorId,
      streetAddress1: formData.streetAddress1,
      streetAddress2: formData.streetAddress2,
      city: formData.city,
      ...(enableState
        ? { state: formData.state }
        : {
            principalRegion: formData.principalRegion,
          }),
      country: formData.country,
      zipCode: formData.zipCode,
      phoneNumber: formData.phoneNumber,
      ...(props.addAddressToCollaborator && { isHome: true }),
      isHome:
        props.address?.isHome ||
        (props.addAddressToCollaborator && formData.addressType === HOME),
      isWork:
        props.address?.isWork ||
        (props.addAddressToCollaborator && formData.addressType === WORK),
    };
    props.addAddress(shippingAddress);
    resetForm();
    props.closeModal();
  };

  const disableField = (fieldName: string) => {
    return (
      !!props.address?.[fieldName as keyof SelectableRecipientAddress] &&
      !!watch()[fieldName as string]
    );
  };

  const isMinimumFieldsWithValuesNotMet =
    watch([
      'streetAddress1',
      'city',
      'state',
      'principalRegion',
      'country',
      'zipCode',
      'phoneNumber',
    ]).reduce((acc, value) => acc + !!value, 0) <
    MINIMUM_FIELDS_WITH_REQUIRED_VALUES;

  const isPhoneNumberNotValid = !!errors.phoneNumber;

  const phoneNumberErrorState =
    !!props.address.id && !props.address.phoneNumber && !watch('phoneNumber');

  const shouldDisableForm =
    isMinimumFieldsWithValuesNotMet || isPhoneNumberNotValid;

  return (
    <Dialog
      open={props.open}
      sx={AddressDialogSx}
      PaperProps={{ sx: { ...AddressDialogPaperSx } }}
      onClose={() => handleCancel()}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(handleAddressSubmit)}
        style={{ height: '100%' }}
      >
        <Stack
          direction="column"
          spacing={2}
          justifyContent="space-between"
          sx={AddressMainTitleSx}
        >
          <Stack direction="row" display="flex" justifyContent="space-between">
            <Typography variant="h5" sx={AddressMainTitleSx}>
              Add Employee's address
            </Typography>
            <GAIconButton
              gaContext={{
                navigates_to: 'N/A',
                textCopy: 'Close Icon',
                purpose: 'Closes Add Recipient Address Modal',
              }}
              onClick={handleCancel}
              sx={CloseRecipientIconSx}
            >
              <Close />
            </GAIconButton>
          </Stack>
          <Divider sx={{ width: '100%' }} />
          <Stack direction="column">
            {!props.addAddressToCollaborator && (
              <Stack
                direction="row"
                display="flex"
                spacing={1}
                sx={{ backgroundColor: 'colors.grayFA' }}
              >
                <InfoOutlined />
                <Typography>
                  This employee has been synced with HRIS. As a result, this
                  address will be used for this order, but not stored to this
                  employee's profile.
                </Typography>
              </Stack>
            )}
            <Grid container rowSpacing={2} columnSpacing={2} mt={1.75}>
              {props.addAddressToCollaborator && (
                <Grid item xs={12}>
                  <Controller
                    name="addressType"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        id="addressType"
                        label="Address type"
                        aria-required
                        required
                        select
                        disabled={disableField('addressType')}
                        data-testid="address-type-test-id"
                        sx={AddressTextFieldBaseSx}
                        {...field}
                        {...register('addressType', {
                          required: true,
                        })}
                        SelectProps={{
                          MenuProps: {
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'center',
                            },
                          },
                        }}
                      >
                        {addressTypeOptions.map(
                          (addressTypeOption) => addressTypeOption
                        )}
                      </TextField>
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="country"
                      label="Country"
                      aria-required
                      required
                      select
                      disabled={disableField('country')}
                      data-testid="country-test-id"
                      sx={AddressTextFieldBaseSx}
                      {...field}
                      {...register('country', {
                        required: true,
                      })}
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: 'center',
                            horizontal: 'right',
                          },
                        },
                      }}
                    >
                      {CountryOptions.map((countryOption) => (
                        <MenuItem
                          key={countryOption}
                          value={COUNTRY_MATCHER[countryOption as string]}
                          data-testid="country-option-test-id"
                        >
                          {countryOption}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="streetAddress1"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="street-line-one"
                      label="Street address 1"
                      aria-required
                      required
                      disabled={disableField('streetAddress1')}
                      data-testid="street-line-one-test-id"
                      sx={AddressTextFieldBaseSx}
                      {...field}
                      {...register('streetAddress1', {
                        required: true,
                      })}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="streetAddress2"
                  control={control}
                  data-testid="street-line-two-test-id"
                  render={({ field }) => (
                    <TextField
                      id="street-line-two"
                      label="Suite / unit /apt #"
                      disabled={
                        disableField('streetAddress2') || !!props.address.id
                      }
                      sx={AddressTextFieldBaseSx}
                      {...field}
                      {...register('streetAddress2')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="city"
                      label="City"
                      aria-required
                      required
                      disabled={disableField('city')}
                      data-testid="city-test-id"
                      sx={AddressTextFieldBaseSx}
                      {...field}
                      {...register('city', {
                        required: true,
                      })}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                {enableState ? (
                  <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        id="state"
                        label="Region"
                        aria-required
                        required={enableState}
                        select
                        disabled={disableField('state')}
                        data-testid="state-test-id"
                        sx={AddressTextFieldBaseSx}
                        {...field}
                        {...register('state', {
                          required: enableState,
                        })}
                      >
                        {StateOptions.map((stateOption) => (
                          <MenuItem
                            key={stateOption}
                            value={US_STATE_MATCHER[stateOption as string]}
                            data-testid="state-option-test-id"
                          >
                            {stateOption}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                ) : (
                  <Controller
                    name="principalRegion"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        id="principalRegion"
                        label="Region"
                        aria-required
                        required={!enableState}
                        disabled={disableField('principalRegion')}
                        data-testid="principal-region-test-id"
                        sx={AddressTextFieldBaseSx}
                        {...field}
                        {...register('principalRegion', {
                          required: !enableState,
                        })}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="zipCode"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="zipCode"
                      label="Zip / postal code"
                      aria-required
                      required
                      disabled={disableField('zipCode')}
                      data-testid="postal-code-test-id"
                      sx={AddressTextFieldBaseSx}
                      {...field}
                      {...register('zipCode', {
                        required: true,
                      })}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{ validate: matchIsValidTel }}
                  render={({ field }) => (
                    <MuiTelInput
                      id="phoneNumber"
                      label="Phone number"
                      aria-required
                      required
                      disabled={disableField('phoneNumber')}
                      data-testid="phone-number-test-id"
                      sx={AddressTextFieldBaseSx}
                      {...field}
                      {...register('phoneNumber', {
                        required: true,
                      })}
                      onChange={(value, info) => field.onChange(value, info)}
                      error={phoneNumberErrorState}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Stack>
          <Divider sx={{ width: '100%' }} />
          <Stack direction="row" spacing="16px" justifyContent="flex-end">
            <GAButton
              gaContext={{
                textCopy: 'Cancel',
                navigates_to: 'N/A',
                purpose: 'Cancels Add Recipient Address Modal',
              }}
              color="secondary"
              onClick={handleCancel}
              size="small"
              sx={AddressCancelButtonSx}
              variant="text"
              data-testid="cancel-button-test-id"
            >
              <Typography variant="inherit">CANCEL</Typography>
            </GAButton>
            <GAButton
              gaContext={{
                textCopy: 'Save',
                navigates_to: 'N/A',
                purpose: 'Saves Recipient Address',
              }}
              color="primary"
              size="small"
              sx={AddressSaveButtonSx}
              variant="contained"
              disabled={shouldDisableForm}
              value="submit"
              type="submit"
              data-testid="save-button-test-id"
            >
              <Typography variant="inherit">SAVE</Typography>
            </GAButton>
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default AddRecipientAddressModal;
