import { SxProps } from '@mui/material';
import theme from 'themes/theme';
import {
  DEVICE_FILTERS_WIDTH,
  DEVICE_FILTERS_HEIGHT,
} from 'pages/Assets/components/DevicesDatagrid/components/DeviceFilters/constants';

export const AutoCompleteStackSx: SxProps = {
  width: DEVICE_FILTERS_WIDTH,
  height: DEVICE_FILTERS_HEIGHT,
  position: 'relative',
};

export const AutoCompleteSx: SxProps = {
  p: 0,
  backgroundColor: 'transparent',
  border: 'none',
  height: DEVICE_FILTERS_HEIGHT,
  position: 'relative',

  '& .MuiInputBase-root': {
    backgroundColor: 'transparent',
  },

  '& .MuiFormControl-root': {
    backgroundColor: 'transparent',
    pl: 0,

    '& .MuiFormLabel-root.MuiInputLabel-root': {
      top: '-10px',
      '&.MuiInputLabel-shrink': {
        top: '0px',
      },
    },

    '& .MuiOutlinedInput-input': {},
  },

  '& .MuiInputBase-formControl': {
    backgroundColor: 'transparent',
    height: DEVICE_FILTERS_HEIGHT,
    py: 0,
  },
};

export const AutoCompleteSearchIconSx: SxProps = {
  color: theme.palette.colors.gray75,
  position: 'absolute',
  top: '8px',
  left: '8px',
};

export const AutoCompleteSearchTextFieldSx: SxProps = {
  p: '0',
  pl: 3,
  borderRadius: 0,
};

export const AutoCompleteSelectSx: SxProps = {
  p: 0,
  width: DEVICE_FILTERS_WIDTH,
  backgroundColor: 'transparent',
  '& .MuiFormLabel-root': {
    top: '-10px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};

export const AutoCompleteSelectMenuSx: SxProps = {
  width: DEVICE_FILTERS_WIDTH,
};
