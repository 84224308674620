import { gql } from '@apollo/client';

export const CREATE_PROCUREMENT_TO_RECIPIENT_ORDER = gql`
  mutation createProcurementToRecipientOrders(
    $purchaserId: ID!
    $isRush: Boolean = false
    $checkoutNotes: String
    $sendRecipientForm: Boolean = true
    $collaboratorIds: [ID!]
    $recipients: [CreateRecipientInput!]
    $items: [CreateProcurementItemsInput!]!
    $shippingTypeId: String
  ) {
    createProcurementToRecipientOrders(
      procurementToRecipientOrdersCreateInput: {
        purchaserId: $purchaserId
        isRush: $isRush
        checkoutNotes: $checkoutNotes
        sendRecipientForm: $sendRecipientForm
        collaboratorIds: $collaboratorIds
        recipients: $recipients
        items: $items
        shippingTypeId: $shippingTypeId
      }
    ) {
      id
    }
  }
`;
