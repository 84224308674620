import React from 'react';

/**
 * Handles step input key down event
 * @param event
 */
export const handleStepInputKeyDown = (
  event: React.KeyboardEvent<HTMLInputElement>
) => {
  const allowedKeyPress = ['ArrowDown', 'ArrowUp'];
  if (!allowedKeyPress.includes(event.key)) event.preventDefault();
};
