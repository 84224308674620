/* eslint-disable sort-exports/sort-exports */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProcurementToDepotState } from 'store/types';

const procurementToDepotInitialState: ProcurementToDepotState = {
  alias: 'Buy-&-Hold',
  totalCartItems: 0,
};

export const procurementToDepotSlice = createSlice({
  name: 'procurementToDepotService',
  initialState: () => procurementToDepotInitialState,
  reducers: {
    setCartTotal: (state, action: PayloadAction<number>) => {
      state.totalCartItems = action.payload;
      return state;
    },
    reset: () => procurementToDepotInitialState,
  },
});
