import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { type OrderDetailFields } from './types';

export const DEFAULT_ORDER_TYPE_FIELD_VALUES = {
  hasAssets: false,
  hasProducts: false,
  toDepot: false,
  toRecipient: false,
  toDropRecipient: false,
} as OrderDetailFields;

export const OrderDetailFieldsSlice = createSlice({
  name: 'OrderDetailFields',
  initialState: DEFAULT_ORDER_TYPE_FIELD_VALUES,
  reducers: {
    setFieldValues: (
      state,
      action: PayloadAction<Partial<OrderDetailFields>>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    reset: () => DEFAULT_ORDER_TYPE_FIELD_VALUES,
  },
});
