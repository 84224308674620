import React from 'react';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import theme from 'themes/theme';

import { DeviceInfoProps } from './types';
import { TitleSx, ContentSx, TitleIconSx } from './styles';

const DeviceInfo = (props: DeviceInfoProps) => {
  const { title, TitleIcon, titleTooltip, description, DescriptionIcon, type } =
    props;
  if (type === 'text') {
    return (
      <Stack>
        <Typography color="colors.gray60" sx={TitleSx}>
          {title}
        </Typography>
        <Typography color="colors.woodSmoke" sx={ContentSx}>
          {description || '-'}
        </Typography>
      </Stack>
    );
  }

  let isInvalidDate = false;
  const date = moment(description).format('MM/DD/YYYY');
  if (date === 'Invalid date') {
    isInvalidDate = true;
  }

  return (
    <Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography color="colors.gray60" sx={TitleSx}>
          {title}
        </Typography>
        {TitleIcon && (
          <Typography color="colors.gray60" sx={TitleSx}>
            <Tooltip title={titleTooltip || ''} placement="bottom" arrow>
              <TitleIcon
                fontSize="small"
                htmlColor="rgba(0, 0, 0, .4)"
                sx={TitleIconSx}
              />
            </Tooltip>
          </Typography>
        )}
      </Stack>
      <Stack>
        <Typography color="colors.woodSmoke" sx={ContentSx}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Stack>{!isInvalidDate ? date : '-'}</Stack>
            {DescriptionIcon && !isInvalidDate ? (
              <Stack>
                <DescriptionIcon
                  fontSize="small"
                  htmlColor={theme.palette.colors.maizeYO}
                />
              </Stack>
            ) : null}
          </Stack>
        </Typography>
      </Stack>
    </Stack>
  );
};

export default DeviceInfo;
