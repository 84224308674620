import React from 'react';

const UKFlagSVGIcon = () => {
  return (
    <svg
      width="30"
      height="20"
      viewBox="0 0 30 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <rect width="28" height="20" rx="2" fill="white" />
        <mask
          id="mask0_3141_38614"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="28"
          height="20"
        >
          <rect width="28" height="20" rx="2" fill="white" />
        </mask>
        <g mask="url(#mask0_3141_38614)">
          <rect width="28" height="20" fill="#0A17A7" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M-1.2825 -1.91797L10.6666 6.14182V-1.33486H17.3333V6.14182L29.2824 -1.91797L30.7736 0.292798L21.3262 6.66514H28V13.3318H21.3262L30.7736 19.7042L29.2824 21.9149L17.3333 13.8551V21.3318H10.6666V13.8551L-1.2825 21.9149L-2.77368 19.7042L6.67371 13.3318H-3.14713e-05V6.66514H6.67371L-2.77368 0.292798L-1.2825 -1.91797Z"
            fill="white"
          />
          <path
            d="M18.668 6.33219L31.3333 -2"
            stroke="#DB1F35"
            stroke-width="0.666667"
            stroke-linecap="round"
          />
          <path
            d="M20.0127 13.6987L31.3665 21.3516"
            stroke="#DB1F35"
            stroke-width="0.666667"
            stroke-linecap="round"
          />
          <path
            d="M8.00562 6.30957L-3.8374 -1.67188"
            stroke="#DB1F35"
            stroke-width="0.666667"
            stroke-linecap="round"
          />
          <path
            d="M9.29012 13.603L-3.8374 22.3086"
            stroke="#DB1F35"
            stroke-width="0.666667"
            stroke-linecap="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 12H12V20H16V12H28V8H16V0H12V8H0V12Z"
            fill="#E6273E"
          />
        </g>
      </g>
    </svg>
  );
};

export default UKFlagSVGIcon;
