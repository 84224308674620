import React, { useCallback } from 'react';

interface Props {
  step: number;
  minimum: number;
  stepInputValueChange: (value: number) => void;
}

export const useStepInputValueChange = (props: Props) => {
  return useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { minimum, step, stepInputValueChange } = props;
      const newValue = Number(event.target.value);

      if (Number.isNaN(newValue)) stepInputValueChange(minimum / step);
      if (newValue <= 0) stepInputValueChange(minimum / step);
      else stepInputValueChange(newValue / step);
    },
    [props]
  );
};
