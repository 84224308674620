import PickGlobalDataRetoolPrompt from 'components/PickGlobalDataRetoolPrompt';
import { UserPermissions } from 'global-constants';
import useGetGlobalPurchaser from 'hooks/useGetGlobalPurchaser';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import useGetUserPermissions from 'hooks/useGetUserPermissions';

export const useAdminModePrompt = () => {
  const globalPurchaser = useGetGlobalPurchaser();
  const userOrganization = useGetUserOrganization();
  const userPermissions = useGetUserPermissions();
  const canToggleOrganization = userPermissions.includes(
    UserPermissions.ORGANIZATION__TOGGLE
  );
  //Admins
  if (
    (!globalPurchaser?.email || !userOrganization?.id) &&
    canToggleOrganization
  )
    return <PickGlobalDataRetoolPrompt />;

  //Non-admin users
  if (!globalPurchaser?.email || !userOrganization?.id) return <></>;

  return <></>;
};
