import {
  GridColDef,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import Radio from '@mui/material/Radio';
import { DEFAULT_BLANK_TABLE_CELL_VALUE } from 'global-constants';
import { formatToMonthDayYear } from 'services/date';
import { useTheme, useMediaQuery } from '@mui/material';
import { FeatureFlagNames } from 'enums';
import useFeatureFlagService from 'hooks/useFeatureFlagService';

export const useGetCollaboratorColumns = (
  rowSelectionModel: GridRowSelectionModel
): GridColDef[] => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const { isFlagOff: isEmployeeEmailOptionsDisabled } = useFeatureFlagService(
    FeatureFlagNames.EMPLOYEE_EMAIL_OPTIONS,
    {
      debugFlags: true,
    }
  );

  const employeeColumns: GridColDef[] = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderHeader: () => <></>,
      filterable: false,
      renderCell(params) {
        const isRowSelected = rowSelectionModel.length
          ? rowSelectionModel.includes(params.id)
          : false;

        return (
          <Radio
            id={`employee_id_${params.id}_radio_button`}
            checked={isRowSelected}
          />
        );
      },
    },
    {
      field: 'firstName',
      headerName: 'First name',
      flex: 1.5,
      filterable: false,
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      flex: 1.5,
      filterable: false,
    },
    {
      field: 'workEmail',
      headerName: 'Work email',
      flex: isLargeScreen ? 2 : 1.5,
      filterable: false,
    },
    {
      field: 'personalEmail',
      headerName: 'Personal email',
      flex: isLargeScreen ? 2 : 1.5,
      filterable: false,
    },
    {
      field: 'group',
      headerName: 'Team',
      flex: isLargeScreen ? 1.5 : 1,
      filterable: false,
      sortable: false,
      valueGetter(params) {
        return params?.row?.group?.join(', ') ?? DEFAULT_BLANK_TABLE_CELL_VALUE;
      },
    },
    {
      field: 'hireDate',
      headerName: 'Hire Date',
      flex: 1,
      filterable: false,
      valueGetter(params) {
        return params?.row?.hireDate
          ? formatToMonthDayYear(params.row.hireDate)
          : DEFAULT_BLANK_TABLE_CELL_VALUE;
      },
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      flex: 1,
      filterable: false,
      valueGetter(params) {
        return params?.row?.startDate
          ? formatToMonthDayYear(params.row.startDate)
          : DEFAULT_BLANK_TABLE_CELL_VALUE;
      },
    },
  ];

  // TODO: remove when flag is removed
  if (isEmployeeEmailOptionsDisabled()) {
    const emailColumn = {
      field: 'email',
      headerName: 'Contact Email',
      flex: isLargeScreen ? 2 : 1.5,
      filterable: false,
    };
    employeeColumns.splice(3, 2, emailColumn);
    employeeColumns[1].flex = 1;
    employeeColumns[2].flex = 1;
  }

  return employeeColumns;
};
