import React from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalNotificationBanner } from 'store/slices/global';
import { AllWhereWebsiteService } from 'api/rest/config';
import OutdatedDeploymentBannerContent from 'components/banners/OutdatedDeploymentBannerContent';
import { AnyAction, Dispatch } from 'redux';

export const LATEST_DEPLOYMENT_TIMEOUT = 1000 * 60;

export const poll = async (once: boolean, dispatch: Dispatch<AnyAction>) => {
  let response;
  let assetManifestMainVersion = '';
  let currentMainVersion = '';
  try {
    response = await AllWhereWebsiteService.get('/asset-manifest.json');
    assetManifestMainVersion = response.data.files['main.js'];
    currentMainVersion = window.mainFile;
    if (assetManifestMainVersion !== currentMainVersion) {
      dispatch(
        setGlobalNotificationBanner({
          show: true,
          content: <OutdatedDeploymentBannerContent />,
        })
      );
    } else {
      dispatch(setGlobalNotificationBanner({ show: false, content: null }));
    }
  } catch (e) {
    console.error(e);
    return;
  }

  if (!once) {
    setTimeout(poll.bind(this, once, dispatch), LATEST_DEPLOYMENT_TIMEOUT);
  }
};

export const usePollForLatestDeployment = (once: boolean = false) => {
  const dispatch = useDispatch();
  let id: number | undefined;

  return React.useEffect(() => {
    poll(once, dispatch);
    return () => {
      clearTimeout(id);
    };
  }, []);
};
