export const getAMOldFilterActionPayload = (
  fieldKey: string,
  fieldValue: string
) => {
  let term = '';

  switch (fieldKey) {
    case 'assetNumber':
    case 'serialNumber':
      term = fieldKey;
      break;

    case 'assetUUID':
      term = 'id';
      break;

    default:
      console.warn(`Invalid field key: ${fieldKey} passed.`);
      term = 'invalidFieldKey';
      break;
  }

  return {
    term,
    value: fieldValue,
  };
};
