/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import ComplexFilterDrawer from 'components/complex_filter/ComplexFilterDrawer';
import { useGetFilterDrawerConfig } from './hooks';

const OrdersComplexFilterDrawer = () => {
  const filterDrawerConfig = useGetFilterDrawerConfig('orders');

  if (!filterDrawerConfig) return <></>;

  return <ComplexFilterDrawer {...filterDrawerConfig} />;
};

export default OrdersComplexFilterDrawer;
