import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { GenericAutocompleteProps } from './types';
import { DefaultSx } from './styles';

const GenericAutocomplete = <T extends {}>(
  props: GenericAutocompleteProps<T>
) => {
  const stylesSx = { ...DefaultSx, ...props.sx };
  return <Autocomplete value={props.selectedItem} {...props} sx={stylesSx} />;
};

export default GenericAutocomplete;
