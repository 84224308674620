import theme from 'themes/theme';
import { SxProps } from '@mui/material';

export const FilterButtonBoxSx: SxProps = {
  position: 'relative',
};

export const getFilterButtonSx = (
  filterDrawerOpen: boolean,
  hasFilters: boolean
): SxProps => ({
  backgroundColor: filterDrawerOpen ? '#1314141F' : 'transparent',
  borderRadius: '30px',
  fontWeight: 600,
  maxHeight: '40px',
  minWidth: hasFilters ? '115px' : '70px',
  position: 'relative',
  px: '12px',
  py: '6px',
  width: '94px',
  '&: hover': {
    backgroundColor: '#1314141F',
    cursor: 'pointer',
  },
});
