import React from 'react';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import PauseOutlinedIcon from '@mui/icons-material/PauseOutlined';

import TextIconButton from '../../TextIconButton';
import ColumnHeader from '../ColumnHeader';
import ColumnContents from '../ColumnContents';
import ColumnFooter from '../ColumnFooter';
import { ColumnSelectProps } from './types';

import {
  IconTextSx,
  PopoverContainerSx,
  PopoverPaperContainerSx,
  ContainerSx,
  ColumnSelectContainer,
} from './styles';

function ColumnSelect<T>(props: ColumnSelectProps<T>) {
  const {
    onHideAllClick,
    onShowAllClick,
    onSearchInput,
    onPopoverClose,
    onPopoverOpen,
    onHandleDragEnd,
    onColumnToggle,
    onRestoreColumnDefaults,
    searchInput,
    changed,
    open,
    columns,
  } = props;
  const id = open ? 'column-select-popover' : undefined;
  const ref = React.useRef();
  const filteredColumns = columns.filter((column) => {
    if (!searchInput) return true;
    return column.displayName.toLowerCase().match(searchInput.toLowerCase());
  });
  return (
    <Stack sx={ColumnSelectContainer}>
      <Box ref={ref}>
        <TextIconButton
          aria-describedby={id}
          text="Columns"
          textStyles={IconTextSx}
          ariaLabel="Edit you columns"
          onClick={onPopoverOpen}
          icon={<PauseOutlinedIcon />}
        />
      </Box>
      <Popover
        id={id}
        sx={PopoverContainerSx}
        PaperProps={{
          sx: PopoverPaperContainerSx(changed),
        }}
        open={open}
        onClose={onPopoverClose}
        anchorEl={ref.current || null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack sx={PopoverContainerSx}>
          <Stack sx={ContainerSx}>
            <ColumnHeader<T>
              onHideAllClick={onHideAllClick}
              onShowAllClick={onShowAllClick}
              onSearchInput={onSearchInput}
              searchInput={searchInput}
              columns={columns}
            />
            <ColumnContents<T>
              columns={filteredColumns}
              searchInput={searchInput}
              onColumnToggle={onColumnToggle}
              onHandleDragEnd={onHandleDragEnd}
            />

            <ColumnFooter
              changed={changed}
              onRestoreColumnDefaults={onRestoreColumnDefaults}
            />
          </Stack>
        </Stack>
      </Popover>
    </Stack>
  );
}

export default ColumnSelect;
