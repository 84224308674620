import { UploadMetadata } from 'api/rest/uploads/types';
import { HistoricUploadQuery, ViewableUploadMetadata } from './types';
import { UploadStatus } from 'api/rest/uploads/constants';

export const uploadSorter = (a: UploadMetadata, b: UploadMetadata) => {
  if (a.timestamp < b.timestamp) {
    return 1;
  }
  if (a.timestamp > b.timestamp) {
    return -1;
  }
  return 0;
};

export const processIncomingUploads = (
  incoming: UploadMetadata[],
  current: ViewableUploadMetadata[],
  lookback: number = 3
): {
  uploads: ViewableUploadMetadata[];
  showNewUploadNotification: boolean;
} => {
  const newUploadCutoffTimestamp = getLookbackTimestamp(lookback);

  const uploadsToAdd: ViewableUploadMetadata[] = incoming
    .filter(
      (upload) =>
        !current.find(
          (i) => i.timestamp === upload.timestamp && i.status === upload.status
        )
    )
    .map((upload) => ({ ...upload, viewed: false }));

  const showNewUploadNotification = uploadsToAdd.some(
    (upload) =>
      upload.status !== UploadStatus.PENDING &&
      upload.timestamp > newUploadCutoffTimestamp
  );

  const uploads = [
    ...uploadsToAdd,
    ...current.filter(
      (upload) => !uploadsToAdd.find((i) => i.timestamp === upload.timestamp)
    ),
  ].sort(uploadSorter);

  return { uploads, showNewUploadNotification };
};

export const getLookbackTimestamp = (lookback: number) => {
  const lookbackInMilli = lookback * 24 * 60 * 60 * 1000;
  return Date.now() - lookbackInMilli;
};
