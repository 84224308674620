import { SxProps } from '@mui/material';
import theme from 'themes/theme';
import { BaseViewAllBtnTextStyles } from 'pages/HomeUpdate/styles';

export const HomeWhatsNewBoxSx: SxProps = {
  border: `1px solid ${theme.palette.colors.transparentGrey}`,
  borderRadius: '10px',
  boxSizing: 'border-box',
  p: '16px',
  pb: '4px',
  width: '100%',
};

export const getHomeWhatsNewListSx = (titleId: string): SxProps => ({
  p: 0,
  px: '8px',
  '& > .MuiListSubheader-root': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    lineHeight: 1,
    p: 0,
    pb: '12px',
    [`& #${titleId}`]: {
      color: theme.palette.colors.woodSmoke,
      fontSize: '16px',
      fontHeight: '13.3px',
      fontWeight: 600,
    },
  },
});

export const ViewAllBtnTextStyles: SxProps = {
  ...BaseViewAllBtnTextStyles,
};
