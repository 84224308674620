import { SxProps } from '@mui/material';

export const PaperSx: SxProps = {
  height: '231px',
  width: '545px',
};

export const ButtonGroupSx: SxProps = {
  flexDirection: 'row',
  justifyContent: 'flex-end',
  columnGap: '16px',
  width: '100%',
  '& > button': {
    p: '8px 12px',
  },
};

export const VariantH4Sx: SxProps = {
  // TODO: Apply this style to all h4 variants
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: 'normal',
  fontStyle: 'normal',
};

export const BaseButtonTextSx: SxProps = {
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.4px',
  width: 'fit-content',
};

export const CancelTextSx: SxProps = {
  color: 'colors.black',
  textAlign: 'right',
  ...BaseButtonTextSx,
};

export const DisregardChangesTextSx: SxProps = {
  color: 'colors.burntSiennaR',
  ...BaseButtonTextSx,
};
