import React from 'react';
import { ProductStatus } from 'global-constants';
import { InventoryReceipt } from 'types';
import { UseInventoryReceiptForm } from './types';
import { useSafeMutation } from 'hooks/useSafeMutation';
import {
  CREATE_INVENTORY_RECEIPT,
  UPDATE_INVENTORY_RECEIPT,
} from './mutations';
import { InventoryReceiptActionModals } from '../../components/InventoryReceipts/enums';
import { formatDate } from 'services';

export const useInventoryReceiptForm = (): UseInventoryReceiptForm => {
  // Form State
  const [checkedInAt, setCheckedInAt] = React.useState<string>('');
  const [checkedOutAt, setCheckedOutAt] = React.useState<string>('');
  const [inboundOrderItemNumber, setInboundOrderItemNumber] =
    React.useState<string>('');
  const [outboundOrderItemNumber, setOutboundOrderItemNumber] =
    React.useState<string>('');

  const [depotId, setDepotId] = React.useState<string>('');
  const [identifier, setIdentifier] = React.useState<string>('');
  const [lastTouchedAt, setLastTouchedAt] = React.useState<string>('');
  const [productStatus, setProductStatus] = React.useState<ProductStatus>(
    ProductStatus.COMPLETE
  );

  //Hook State
  const [context, setContext] = React.useState<'add' | 'edit' | 'none'>('none');

  const [inventoryReceiptActionModal, setInventoryReceiptActionModal] =
    React.useState<InventoryReceiptActionModals>(
      InventoryReceiptActionModals.NONE
    );

  const [selectedInventoryReceipt, setSelectedInventoryReceipt] =
    React.useState<InventoryReceipt | undefined>(undefined);

  const setDefaultValues = (inventoryReceipt?: InventoryReceipt) => {
    if (!inventoryReceipt) {
      clearForm();
      return;
    }
    setCheckedInAt(formatDate(inventoryReceipt.checkedInAt, 'YYYY-MM-DD'));
    setCheckedOutAt(
      formatDate(inventoryReceipt?.checkedOutAt || '', 'YYYY-MM-DD')
    );
    setInboundOrderItemNumber(
      inventoryReceipt?.inboundOrderItem?.orderItemNumber || ''
    );
    setOutboundOrderItemNumber(
      inventoryReceipt.outboundOrderItem?.orderItemNumber || ''
    );
    setDepotId(inventoryReceipt.depotId);
    setIdentifier(inventoryReceipt?.identifier || '');
    setLastTouchedAt(
      formatDate(inventoryReceipt?.lastTouchedAt || '', 'YYYY-MM-DD')
    );
    setProductStatus(inventoryReceipt?.productStatus || ProductStatus.COMPLETE);
  };

  const isValid = !!checkedInAt && !!inboundOrderItemNumber && !!depotId;

  const [createInventoryReceipt, { loading: createInventoryIsLoading }] =
    useSafeMutation(CREATE_INVENTORY_RECEIPT);

  const [updateInventoryReceipt, { loading: updateInventoryIsLoading }] =
    useSafeMutation(UPDATE_INVENTORY_RECEIPT);

  const addInventoryReceipt = (assetId: string, onCompleted: any) => {
    createInventoryReceipt({
      variables: {
        assetId,
        checkedInAt: new Date(checkedInAt)?.toISOString(),
        depotId,
        inboundOrderItemNumber,
        outboundOrderItemNumber,
        lastTouchedAt: lastTouchedAt
          ? new Date(lastTouchedAt)?.toISOString()
          : undefined,
        checkedOutAt: checkedOutAt
          ? new Date(checkedOutAt)?.toISOString()
          : undefined,
        identifier,
        productStatus,
      },
      onCompleted,
      update: (cache, { data: { createInventoryReceipt } }) => {
        cache.modify({
          optimistic: true,
          fields: {
            searchInventoryReceipts(data) {
              const inventoryReceipts: InventoryReceipt[] = (
                data.inventoryReceipts || []
              )
                .filter(
                  (receipt: InventoryReceipt) => receipt.assetId === assetId
                )
                .unshift(createInventoryReceipt);

              return inventoryReceipts;
            },
          },
        });
      },
    });
  };

  const editInventoryReceipt = (
    inventoryReceiptId: string,
    onCompleted: any
  ) => {
    updateInventoryReceipt({
      variables: {
        id: inventoryReceiptId,
        checkedInAt: new Date(checkedInAt)?.toISOString(),
        depotId,
        outboundOrderItemNumber,
        lastTouchedAt:
          lastTouchedAt && lastTouchedAt !== 'Invalid date'
            ? new Date(lastTouchedAt)?.toISOString()
            : undefined,
        checkedOutAt:
          checkedOutAt && checkedOutAt !== 'Invalid date'
            ? new Date(checkedOutAt)?.toISOString()
            : undefined,
        identifier,
        productStatus,
      },
      onCompleted,
      update: (cache, { data: { updateInventoryReceipt } }) => {
        cache.modify({
          optimistic: true,
          fields: {
            searchInventoryReceipts(data) {
              const inventoryReceipts: InventoryReceipt[] =
                data.inventoryReceipts || [];

              return inventoryReceipts.map((receipt: InventoryReceipt) => {
                return {
                  ...receipt,
                  ...updateInventoryReceipt,
                };
              });
            },
          },
        });
      },
    });
  };

  const clearForm = () => {
    setCheckedInAt('');
    setCheckedOutAt('');
    setInboundOrderItemNumber('');
    setOutboundOrderItemNumber('');
    setDepotId('');
    setIdentifier('');
    setLastTouchedAt('');
    setProductStatus(ProductStatus.COMPLETE);
  };

  const invokeMutation = (id: string, onCompleted: any) => {
    if (context === 'add') {
      addInventoryReceipt(id, () => {
        onCompleted();
        clearForm();
        setInventoryReceiptActionModal(InventoryReceiptActionModals.NONE);
      });
    } else {
      editInventoryReceipt(selectedInventoryReceipt?.id as string, () => {
        onCompleted();
        clearForm();
        setInventoryReceiptActionModal(InventoryReceiptActionModals.NONE);
      });
    }
  };

  const isEditFormDirty = () => {
    if (!selectedInventoryReceipt) return false;

    return (
      checkedInAt !==
        formatDate(selectedInventoryReceipt?.checkedInAt, 'YYYY-MM-DD') ||
      checkedOutAt !==
        formatDate(
          selectedInventoryReceipt?.checkedOutAt || '',
          'YYYY-MM-DD'
        ) ||
      outboundOrderItemNumber !==
        (selectedInventoryReceipt?.outboundOrderItem?.orderItemNumber || '') ||
      depotId !== selectedInventoryReceipt?.depotId ||
      identifier !== (selectedInventoryReceipt?.identifier || '') ||
      lastTouchedAt !==
        formatDate(
          selectedInventoryReceipt?.lastTouchedAt || '',
          'YYYY-MM-DD'
        ) ||
      productStatus !== selectedInventoryReceipt?.productStatus
    );
  };

  return {
    checkedInAt,
    setCheckedInAt,
    checkedOutAt,
    setCheckedOutAt,
    inboundOrderItemNumber,
    setInboundOrderItemNumber,
    outboundOrderItemNumber,
    setOutboundOrderItemNumber,
    depotId,
    setDepotId,
    identifier,
    setIdentifier,
    lastTouchedAt,
    setLastTouchedAt,
    productStatus,
    setProductStatus,
    isValid,
    context,
    setContext,
    setDefaultValues,
    invokeMutation,
    mutationIsLoading: createInventoryIsLoading || updateInventoryIsLoading,
    inventoryReceiptActionModal,
    setInventoryReceiptActionModal,
    isEditFormDirty,
    selectedInventoryReceipt,
    setSelectedInventoryReceipt,
  };
};
