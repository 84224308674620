import React from 'react';
import { OrderType as OrderTypeEnum } from 'global-constants';
import { AssetDevice, AssetService, OrderType } from 'types';
import { UseGetDeviceActionsEnablement } from './types';
import { SEARCH_ASSET_SERVICES } from './queries';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';

export const useGetDeviceActionsEnablement =
  (): UseGetDeviceActionsEnablement => {
    const [enableProcessDeviceAction, setEnableProcessDeviceAction] =
      React.useState(false);
    const [enableRepairDeviceAction, setEnableRepairDeviceAction] =
      React.useState(false);
    const [enableRecycleDeviceAction, setEnableRecycleDeviceAction] =
      React.useState(false);
    const [enableResellDeviceAction, setEnableResellDeviceAction] =
      React.useState(false);
    const [enableAllocateDeviceAction, setEnableAllocateDeviceAction] =
      React.useState(false);
    const [enableEditDeviceAction, setEnableEditDeviceAction] =
      React.useState(false);
    const [enableDeleteDeviceAction, setEnableDeleteDeviceAction] =
      React.useState(false);
    const [enableUnlockDeviceAction, setEnableUnlockDeviceAction] =
      React.useState(false);

    const orderTypeToActionMapper: { [key: string]: any } = {
      [OrderTypeEnum.PROCESS]: setEnableProcessDeviceAction,
      [OrderTypeEnum.REPAIR]: setEnableRepairDeviceAction,
      [OrderTypeEnum.RECYCLE]: setEnableRecycleDeviceAction,
      [OrderTypeEnum.RESELL]: setEnableResellDeviceAction,
      [OrderTypeEnum.DEPLOYMENT]: setEnableAllocateDeviceAction,
      [OrderTypeEnum.DEVICE_UNLOCK]: setEnableUnlockDeviceAction,
    };

    const [searchAssetServices] = useSafeLazyQuery(SEARCH_ASSET_SERVICES, {
      onCompleted: (data) => {
        const availableOrderTypes: OrderType[] =
          data.assetServices?.assetServices.map(
            (assetService: AssetService) => assetService.orderType
          ) ?? [];

        availableOrderTypes.forEach((orderType: OrderType) => {
          const actionSetter = orderTypeToActionMapper?.[orderType.name];
          if (actionSetter) {
            actionSetter(true);
          }
        });
      },
    });

    const resetState = () => {
      setEnableProcessDeviceAction(false);
      setEnableRepairDeviceAction(false);
      setEnableRecycleDeviceAction(false);
      setEnableResellDeviceAction(false);
      setEnableAllocateDeviceAction(false);
      setEnableEditDeviceAction(false);
      setEnableDeleteDeviceAction(false);
      setEnableUnlockDeviceAction(false);
    };

    const enableActionsByDevice = (device: AssetDevice) => {
      resetState(); // reset state first before enabling other actions
      setEnableEditDeviceAction(true);
      setEnableDeleteDeviceAction(true);
      const depotId = device?.depot?.id || device?.inventoryLog?.depotId;
      if (depotId) {
        searchAssetServices({
          variables: {
            depotId,
            assetStatus: device?.status,
            assetTypeId: device?.assetType?.id,
          },
        });
      }
    };

    return {
      enableProcessDeviceAction,
      enableRepairDeviceAction,
      enableRecycleDeviceAction,
      enableResellDeviceAction,
      enableAllocateDeviceAction,
      enableEditDeviceAction,
      enableDeleteDeviceAction,
      enableUnlockDeviceAction,
      enableActionsByDevice,
      resetState,
      setEnableUnlockDeviceAction,
    };
  };
