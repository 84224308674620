export const DATA_TESTID_ASSET_MGMT_DASH = 'asset-management-dash';
export const DATA_TESTID_ASSET_MGMT_TOP_STACK =
  'asset-management-dash-top-stack';
export const DATA_TESTID_ASSET_MGMT_MID_STACK =
  'asset-management-dash-middle-stack';
export const DATA_TESTID_ASSET_MGMT_END_STACK =
  'asset-management-dash-end-stack';

export enum ManagerDashboards {
  ORDER_MGMT = 'order-management',
  ASSET_MGMT = 'asset-management',
}

export enum AdminAssetAction {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export enum FilterPlaceholders {
  CLEAR_ALL_FILTER_OPTION = 'CLEAR_ALL_FILTER_OPTION',
  TUPLE_SEPARATOR = '__SEPARATOR__', // Used to separate displayValue and uuid/other values
}
