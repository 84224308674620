import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { InventoryReceiptFormProps } from './types';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { SEARCH_DEPOTS } from './queries';
import MenuItem from '@mui/material/MenuItem';
import { AllwhereDepot } from 'types';
import { ProductStatus } from 'global-constants';
import InventoryReceiptProductStatusPill from 'components/InventoryReceiptProductStatusPill';

const InventoryReceiptForm = (props: InventoryReceiptFormProps) => {
  const { data } = useSafeQuery(SEARCH_DEPOTS);

  const depotOptions = data?.searchDepots.depots || [];

  const productStatusOptions = Object.entries(ProductStatus).map(
    ([key, value]) => {
      return {
        key,
        value,
      };
    }
  );

  const isEditMode = props.inventoryReceiptFormService.context === 'edit';

  return (
    <Grid
      container
      columnSpacing={'1rem'}
      rowSpacing={'1rem'}
      marginTop="0.05rem"
    >
      <Grid item xs={12} md={6}>
        <TextField
          id="check-in-date-input"
          type="date"
          size="medium"
          variant="outlined"
          sx={{
            backgroundColor: 'transparent',
            p: '0',
            width: '100%',
          }}
          label="Check In date"
          InputLabelProps={{
            shrink: true,
            placeholder: '',
          }}
          value={props.inventoryReceiptFormService.checkedInAt}
          onChange={(e) =>
            props.inventoryReceiptFormService.setCheckedInAt(e.target.value)
          }
          required={true}
          aria-required={true}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          id="check-out-date-input"
          type="date"
          size="medium"
          variant="outlined"
          sx={{
            backgroundColor: 'transparent',
            p: '0',
            width: '100%',
          }}
          label="Check Out date"
          InputLabelProps={{
            shrink: true,
            placeholder: '',
          }}
          value={props.inventoryReceiptFormService.checkedOutAt}
          onChange={(e) =>
            props.inventoryReceiptFormService.setCheckedOutAt(e.target.value)
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          id="inbound-order-item-number-text"
          label="Inbound order item #"
          sx={{
            backgroundColor: 'transparent',
            p: '0',
            width: '100%',
          }}
          value={props.inventoryReceiptFormService.inboundOrderItemNumber}
          onChange={(e) =>
            props.inventoryReceiptFormService.setInboundOrderItemNumber(
              e.target.value
            )
          }
          required={true}
          aria-required={true}
          InputProps={{
            readOnly: isEditMode,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          id="outbound-order-item-number-text"
          label="Outbound order item #"
          sx={{
            backgroundColor: 'transparent',
            p: '0',
            width: '100%',
          }}
          value={props.inventoryReceiptFormService.outboundOrderItemNumber}
          onChange={(e) =>
            props.inventoryReceiptFormService.setOutboundOrderItemNumber(
              e.target.value
            )
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl sx={{ maxWidth: '300px', width: '100%' }}>
          <InputLabel id="depot-label">Depot *</InputLabel>
          <Select
            labelId="depot-label"
            id="depot-select"
            variant="outlined"
            label="Depot"
            value={props.inventoryReceiptFormService.depotId}
            onChange={(e) =>
              props.inventoryReceiptFormService.setDepotId(e.target.value)
            }
            required={true}
            aria-required={true}
          >
            {depotOptions.map((depotOption: AllwhereDepot) => (
              <MenuItem key={depotOption.id} value={depotOption.id}>
                {depotOption.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          id="identifier-text"
          label="Identifier"
          sx={{
            backgroundColor: 'transparent',
            p: '0',
            width: '100%',
          }}
          value={props.inventoryReceiptFormService.identifier}
          onChange={(e) =>
            props.inventoryReceiptFormService.setIdentifier(e.target.value)
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          id="last-touched-date-input"
          type="date"
          size="medium"
          variant="outlined"
          sx={{
            backgroundColor: 'transparent',
            p: '0',
            width: '100%',
          }}
          label="Last Touched at"
          InputLabelProps={{
            shrink: true,
            placeholder: '',
          }}
          value={props.inventoryReceiptFormService.lastTouchedAt}
          onChange={(e) =>
            props.inventoryReceiptFormService.setLastTouchedAt(e.target.value)
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl sx={{ maxWidth: '300px', width: '100%' }}>
          <InputLabel id="product-status-label">Product Status</InputLabel>
          <Select
            labelId="product-status-label"
            id="product-status-select"
            variant="outlined"
            label="Product Status"
            value={props.inventoryReceiptFormService.productStatus}
            onChange={(e) =>
              props.inventoryReceiptFormService.setProductStatus(
                e.target.value as ProductStatus
              )
            }
          >
            {productStatusOptions.map((productStatusOption) => (
              <MenuItem
                key={productStatusOption.key}
                value={productStatusOption.value}
              >
                <InventoryReceiptProductStatusPill
                  status={productStatusOption.key as ProductStatus}
                  sx={{ height: '21px' }}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default InventoryReceiptForm;
