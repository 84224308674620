import { gql } from '@apollo/client';

export const RETRIEVE_EMPLOYEE = gql`
  query retrieveCollaborator($id: String!) {
    collaborator(id: $id) {
      id
      hrisEmployeeId
      firstName
      lastName
      email
      workEmail
      personalEmail
      group
      hireDate
      startDate
      endDate
      # birthday
      employmentStatus
      avatar
      phoneNumber
      primaryAddressId
      primaryCollaboratorAddress {
        addressId
        isHome
        isWork
      }
      workAddresses {
        id
        streetAddress1
        streetAddress2
        city
        state
        principalRegion
        country
        phoneNumber
        zipCode
      }
      homeAddresses {
        id
        streetAddress1
        streetAddress2
        city
        state
        principalRegion
        country
        phoneNumber
        zipCode
      }
    }
  }
`;
