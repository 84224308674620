import { SxProps } from '@mui/material';
import theme from 'themes/theme';
import {
  BUY_HOLD_CTA_TITLE_ID,
  BUY_AND_HOLD_CTA_ITEM_ICON,
  BUY_AND_HOLD_CTA_ITEM_TITLE,
  BUY_AND_HOLD_CTA_ITEM_COPY,
} from './constants';
import { FontFamily } from 'enums';
import themeColors from 'themes/colors';

const { woodSmoke, gray75, transparentGrey } = themeColors;

export const CTABoxSx: SxProps = {
  border: `1px solid ${transparentGrey}`,
  borderRadius: '10px',
  boxSizing: 'border-box',
  p: '24px',
  width: '100%',

  [`& .MuiTypography-root#${BUY_HOLD_CTA_TITLE_ID}`]: {
    color: woodSmoke,
    fontFamily: FontFamily.PPP_GRAM_SANS,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: 'normal',
    mb: '24px',
  },

  '& .MuiList-root': {
    p: 0,
  },
};

export const getCTAItemSx = (index: number): SxProps => ({
  ...(!!index && { mt: '16px' }),
  p: 0,
  [`& .${BUY_AND_HOLD_CTA_ITEM_ICON}`]: {
    color: gray75,
  },
  [`& .${BUY_AND_HOLD_CTA_ITEM_TITLE}`]: {
    color: woodSmoke,
    fontFamily: FontFamily.PPP_GRAM_SANS,
    fontWeight: 700,
    mb: '4px',
  },
  [`& .${BUY_AND_HOLD_CTA_ITEM_COPY}`]: {
    color: woodSmoke,
    fontFamily: FontFamily.INTER,
    fontSize: '14px',
    fontWeight: 500,
  },
});
