import React from 'react';

const GreenCheckmark = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.0007 0.835938C11.6607 0.835938 0.833984 11.6626 0.833984 25.0026C0.833984 38.3426 11.6607 49.1693 25.0007 49.1693C38.3407 49.1693 49.1673 38.3426 49.1673 25.0026C49.1673 11.6626 38.3407 0.835938 25.0007 0.835938ZM20.1673 37.0859L8.08398 25.0026L11.4915 21.5951L20.1673 30.2468L38.5098 11.9043L41.9173 15.3359L20.1673 37.0859Z"
        fill="#BACEB1"
      />
    </svg>
  );
};

export default GreenCheckmark;
