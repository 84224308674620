import { SxProps } from '@mui/material';

export const PaperSx: SxProps = {
  marginTop: 'calc(20% + 60px)',
  padding: '1rem 5rem',
  position: 'fixed',
  bottom: 0,
  width: '100%',
  '& #review-page-footer-divider': {
    margin: '0.5rem 0',
    marginLeft: {
      xs: '0',
      md: '50%',
    },
  },
};
