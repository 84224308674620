/* eslint-disable sort-exports/sort-exports */
import { useDispatch } from 'react-redux';
import { assetManagementSlice } from 'store/slices';
import { ASSET_MANAGEMENT_FILTER_KEYS } from 'store/constants';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DeviceInfoComplexTypeAhead from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/components/AssetFieldComplexTypeAhead';
import DeviceInfoComplexDropDown from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/components/AssetFieldComplexDropDown';
import {
  AMDashComplexFilterTestIds,
  ASM_DASH_DRAWER_INPUT_BOX_CLASS,
} from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/constants';
import { GET_ALL_ASSET_TYPES } from './queries';
import {
  GET_ASSET_MAKE_SUGGESTION,
  GET_ASSET_MODEL_SUGGESTION,
  GET_ASSET_KEYBOARD_SUGGESTION,
  GET_ASSET_SERIAL_NO_SUGGESTION,
  GET_ASSET_DEVICE_ISSUE_SUGGESTION,
  GET_ASSET_COLOR_SUGGESTION,
  GET_ASSET_DISPLAY_SIZE_SUGGESTION,
} from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/queries';
import * as AMDashComplexFilterStyles from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/styles';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { FilterPlaceholders } from 'pages/AssetManagement/constants';
import { AMDashDeviceInfoTestId } from './constants';

const DeviceInfoFilterSection = () => {
  const dispatch = useDispatch();

  const drawerStyles = AMDashComplexFilterStyles;
  const assignAvailableOptions = (optionKey: string, options: string[][]) => {
    dispatch(
      assetManagementSlice.actions.populateAvailableOptionReducer({
        optionKey,
        options,
      })
    );
  };

  useSafeQuery(GET_ALL_ASSET_TYPES, {
    onCompleted: ({ getAllAssetTypes = [] }) => {
      const assetTypeValueIdOptions = getAllAssetTypes.map((assetType: any) => {
        const { id, name } = assetType;

        return [name, id];
      });

      const assetTypeOptions = [
        [FilterPlaceholders.CLEAR_ALL_FILTER_OPTION, ''],
        ...assetTypeValueIdOptions,
      ];

      assignAvailableOptions(
        ASSET_MANAGEMENT_FILTER_KEYS.ASSET_TYPES,
        assetTypeOptions
      );
    },
  });

  const clearFiltersByField = (filterKey: string) => {
    dispatch(assetManagementSlice.actions.clearFilterByTypeReducer(filterKey));
  };

  const clearOptionsByField = (filterKey: string) => {
    dispatch(assetManagementSlice.actions.clearOptionsByTypeReducer(filterKey));
  };

  const clearDropdownFiltersByField = (filterKey: string) => {
    dispatch(
      assetManagementSlice.actions.clearSelectedFiltersByTypeReducer(filterKey)
    );
  };

  return (
    <Stack
      direction="column"
      data-testid={AMDashComplexFilterTestIds.DEVICE_INFO}
      sx={drawerStyles.AMComplexFilterStackSx}
    >
      <Typography sx={drawerStyles.AMComplexFilterSectionTitleSx}>
        Device Info
      </Typography>
      <Stack direction="column" columnGap="12px" rowGap="12px">
        <Stack direction="row" rowGap="12px" justifyContent="space-between">
          <Box className={ASM_DASH_DRAWER_INPUT_BOX_CLASS}>
            <DeviceInfoComplexDropDown
              alias="ASSET_TYPE_FILTER"
              filterKey={ASSET_MANAGEMENT_FILTER_KEYS.ASSET_TYPES}
              counterAvatarTestId={AMDashDeviceInfoTestId.ASSET_TYPE}
              inputLabel="Type"
              makeSelectionText="Select type"
              clearFiltersByField={clearDropdownFiltersByField}
            />
          </Box>
          <DeviceInfoComplexTypeAhead
            alias="ASSET_MAKE"
            filterKey={ASSET_MANAGEMENT_FILTER_KEYS.ASSET_MAKES}
            lazyQueryDocumentNode={GET_ASSET_MAKE_SUGGESTION}
            responseField="getAssetAutocompleteSuggestions"
            renderInputLabel="Make"
            counterAvatarTestId={AMDashDeviceInfoTestId.ASSET_MAKE}
            clearFiltersByField={clearFiltersByField}
            clearOptionsByField={clearOptionsByField}
          />
        </Stack>
        <Stack direction="row" rowGap="12px" justifyContent="space-between">
          <DeviceInfoComplexTypeAhead
            alias="ASSET_MODEL"
            filterKey={ASSET_MANAGEMENT_FILTER_KEYS.ASSET_MODELS}
            lazyQueryDocumentNode={GET_ASSET_MODEL_SUGGESTION}
            responseField="getAssetAutocompleteSuggestions"
            renderInputLabel="Model"
            counterAvatarTestId={AMDashDeviceInfoTestId.ASSET_MODEL}
            clearFiltersByField={clearFiltersByField}
            clearOptionsByField={clearOptionsByField}
          />
          <Box className={ASM_DASH_DRAWER_INPUT_BOX_CLASS}>
            <DeviceInfoComplexDropDown
              alias="ASSET_COSMETIC_CONDITION"
              filterKey={ASSET_MANAGEMENT_FILTER_KEYS.CONDITIONS}
              counterAvatarTestId={AMDashDeviceInfoTestId.ASSET_CONDITION}
              inputLabel="Condition"
              makeSelectionText="Select condition"
              clearFiltersByField={clearDropdownFiltersByField}
            />
          </Box>
        </Stack>
        <Stack direction="row" rowGap="12px" justifyContent="space-between">
          <DeviceInfoComplexTypeAhead
            alias="ASSET_KEYBOARD"
            filterKey={ASSET_MANAGEMENT_FILTER_KEYS.KEY_CONFIGS}
            lazyQueryDocumentNode={GET_ASSET_KEYBOARD_SUGGESTION}
            responseField="getAssetAutocompleteSuggestions"
            renderInputLabel="Keyboard Config"
            counterAvatarTestId={AMDashDeviceInfoTestId.ASSET_KEYBOARD}
            clearFiltersByField={clearFiltersByField}
            clearOptionsByField={clearOptionsByField}
          />
          <DeviceInfoComplexTypeAhead
            alias="ASSET_SERIAL_NO"
            filterKey={ASSET_MANAGEMENT_FILTER_KEYS.SERIAL_NO}
            lazyQueryDocumentNode={GET_ASSET_SERIAL_NO_SUGGESTION}
            responseField="getAssetAutocompleteSuggestions"
            renderInputLabel="Serial Number"
            counterAvatarTestId={AMDashDeviceInfoTestId.ASSET_SERIAL_NO}
            clearFiltersByField={clearFiltersByField}
            clearOptionsByField={clearOptionsByField}
          />
        </Stack>
        <Stack direction="row" rowGap="12px" justifyContent="space-between">
          <DeviceInfoComplexTypeAhead
            alias="ASSET_DEVICE_ISSUE"
            filterKey={ASSET_MANAGEMENT_FILTER_KEYS.DEVICE_ISSUES}
            lazyQueryDocumentNode={GET_ASSET_DEVICE_ISSUE_SUGGESTION}
            responseField="getAssetAutocompleteSuggestions"
            renderInputLabel="Device Issue"
            counterAvatarTestId={AMDashDeviceInfoTestId.ASSET_DEVICE_ISSUE}
            clearFiltersByField={clearFiltersByField}
            clearOptionsByField={clearOptionsByField}
          />
          <DeviceInfoComplexTypeAhead
            alias="ASSET_COLOR"
            filterKey={ASSET_MANAGEMENT_FILTER_KEYS.COLORS}
            lazyQueryDocumentNode={GET_ASSET_COLOR_SUGGESTION}
            responseField="getAssetAutocompleteSuggestions"
            renderInputLabel="Color"
            counterAvatarTestId={AMDashDeviceInfoTestId.ASSET_COLOR}
            clearFiltersByField={clearFiltersByField}
            clearOptionsByField={clearOptionsByField}
          />
        </Stack>
        <Stack direction="row" rowGap="12px" justifyContent="space-between">
          <DeviceInfoComplexTypeAhead
            alias="ASSET_DISPLAY_SIZE"
            filterKey={ASSET_MANAGEMENT_FILTER_KEYS.DISPLAY_SIZES}
            lazyQueryDocumentNode={GET_ASSET_DISPLAY_SIZE_SUGGESTION}
            responseField="getAssetAutocompleteSuggestions"
            renderInputLabel="Display Size"
            counterAvatarTestId={AMDashDeviceInfoTestId.ASSET_DISPLAY_SIZE}
            clearFiltersByField={clearFiltersByField}
            clearOptionsByField={clearOptionsByField}
          />
          <Box className={ASM_DASH_DRAWER_INPUT_BOX_CLASS}>
            <DeviceInfoComplexDropDown
              alias="ASSET_DATA_STATUS_FILTER"
              filterKey={ASSET_MANAGEMENT_FILTER_KEYS.DATA_STATUS}
              counterAvatarTestId={AMDashDeviceInfoTestId.ASSET_DATA_STATUS}
              inputLabel="Data Status"
              makeSelectionText="Select Data status"
              clearFiltersByField={clearDropdownFiltersByField}
            />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DeviceInfoFilterSection;
