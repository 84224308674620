import React from 'react';
import { AutoCompleteOptionTuple } from 'types';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import { SEARCH_OPTION_SEPARATOR } from 'components/SearchScopeDropdown/constants';
import { SearchOption } from 'components/SearchScopeDropdown/types';
import { AssetManagementSuggestionFilterName } from 'pages/AssetManagement/components/AMDashSearchBar/constants';
import {
  ADMIN_GET_ASSETS_BY_SERIAL_NUMBER,
  ADMIN_GET_ASSETS_BY_ASSET_NUMBER,
  GET_INVENTORY_RECEIPTS_BY_THIRD_PARTY_ASSET_ID,
} from './queries';

export const useGetAssetManagementSuggestions = (
  searchTerm: string,
  searchOption: SearchOption,
  setAutocompleteOptions: React.Dispatch<
    React.SetStateAction<AutoCompleteOptionTuple[]>
  >
) => {
  const [fetchAdminAssetsBySerialNo] = useSafeLazyQuery(
    ADMIN_GET_ASSETS_BY_SERIAL_NUMBER,
    {
      onCompleted(data) {
        const { adminSearchAssets = {} } = data;
        const { assets = [] } = adminSearchAssets;
        if (!assets?.length ?? 0) return;
        const assetOptions = assets
          .slice()
          .sort((a: any, b: any) =>
            a.serialNumber.localeCompare(b.serialNumber)
          )
          .map((asset: any) => [
            'serialNumber',
            asset?.serialNumber,
            `${searchOption.displayName}${SEARCH_OPTION_SEPARATOR}${asset?.serialNumber}`,
          ]);
        setAutocompleteOptions(assetOptions);
      },
    }
  );
  const [fetchAdminAssetsByAssetNo] = useSafeLazyQuery(
    ADMIN_GET_ASSETS_BY_ASSET_NUMBER,
    {
      onCompleted(data) {
        const { adminSearchAssets = {} } = data;
        const { assets = [] } = adminSearchAssets;
        if (!assets?.length ?? 0) return;
        const assetOptions = assets
          .slice()
          .sort((a: any, b: any) => a.assetNumber.localeCompare(b.assetNumber))
          .map((asset: any) => [
            'assetNumber',
            asset?.assetNumber,
            `${searchOption.displayName}${SEARCH_OPTION_SEPARATOR}${asset?.assetNumber}`,
          ]);
        setAutocompleteOptions(assetOptions);
      },
    }
  );

  const [fetchInventoryReceipts] = useSafeLazyQuery(
    GET_INVENTORY_RECEIPTS_BY_THIRD_PARTY_ASSET_ID,
    {
      onCompleted(data) {
        const { searchInventoryReceipts = {} } = data;
        const { inventoryReceipts = [] } = searchInventoryReceipts;
        if (!inventoryReceipts?.length ?? 0) return;
        const inventoryReceiptOptions = inventoryReceipts
          .slice()
          .filter(
            (inventoryReceipt: any) =>
              inventoryReceipt?.assetId && inventoryReceipt?.identifier
          )
          .sort((a: any, b: any) => a.identifier.localeCompare(b.identifier))
          .map((inventoryReceipt: any) => [
            'thirdPartyAssetId',
            inventoryReceipt?.assetId,
            `${searchOption.displayName}${SEARCH_OPTION_SEPARATOR}${inventoryReceipt?.identifier}`,
          ]);
        setAutocompleteOptions(inventoryReceiptOptions);
      },
    }
  );

  React.useEffect(() => {
    if (!searchTerm) return;
    switch (searchOption.filterName) {
      case AssetManagementSuggestionFilterName.SERIAL_NUMBER:
        fetchAdminAssetsBySerialNo({
          variables: {
            serialNumber: searchTerm,
          },
        });
        break;
      case AssetManagementSuggestionFilterName.ASSET_NUMBER:
        fetchAdminAssetsByAssetNo({
          variables: {
            assetNumber: searchTerm,
          },
        });
        break;
      case AssetManagementSuggestionFilterName.THIRD_PARTY_ASSET:
        fetchInventoryReceipts({
          variables: { identifier: searchTerm },
        });
        break;
      default:
        break;
    }
  }, [searchOption.filterName, searchTerm]);
};
