import { gql } from '@apollo/client';

export const SUBMIT_ORDER_TO_ERP = gql`
  mutation submitToERP($id: ID!) {
    submitToERP(submitOrderToERPInput: { id: $id }) {
      id
      success
    }
  }
`;
