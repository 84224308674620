import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { bffClient } from 'api/apollo';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import {
  useSetAuthTokenToReduxFromLocalStorage,
  useDoAuth0LoginFlow,
} from './hooks';
import { selectAuthToken } from 'store/slices/global/selectors';
import { LoadingContainerSx } from './styles';

const BffProvider = (props: { children: React.ReactNode }) => {
  const authToken = useSelector(selectAuthToken);
  const { isAuthenticated } = useAuth0();

  const shouldRenderApp =
    !['initial', 'logged-out', 'unauthenticated'].includes(authToken) &&
    isAuthenticated;

  useSetAuthTokenToReduxFromLocalStorage();
  useDoAuth0LoginFlow();

  if (process.env.NODE_ENV === 'test') {
    return <ApolloProvider client={bffClient}>{props.children}</ApolloProvider>;
  }
  return shouldRenderApp ? (
    <ApolloProvider client={bffClient}>{props.children}</ApolloProvider>
  ) : (
    <Stack sx={LoadingContainerSx}>
      <CircularProgress />
    </Stack>
  );
};

export default BffProvider;
