import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const TextAreaOptionInputSx: SxProps = {
  height: '100%',
  p: 0,
  width: '100%',
  '&.MuiTextField-root.MuiFormControl-root': {
    borderRadius: 0,
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottom: '1px solid rgba(0, 0, 0, .42)',
    position: 'relative',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    borderRadius: 0,
    // borderBottom: '1px solid rgba(0, 0, 0, .42)',
  },
  '& .MuiFormHelperText-root.MuiFormHelperText-contained': {
    color: 'rgba(0, 0, 0, .6)',
    fontSize: '14px',
    position: 'absolute',
    top: 0,
  },
  '& .MuiInputBase-formControl': {
    pt: '32px',
  },
};

export const TextAreaOptionStackSx: SxProps = {
  height: '148px',
  mt: '16px',
  width: '100%',
};
