import { ApiContext } from './apiContext';
import { AUTH0_LOGIN_REQUIRED_ERROR } from './constants';
import { getLocalStorageValue } from 'api/storage';
import { store } from 'store';
import { setAuthToken } from 'store/slices';

const baseUrl = process.env.REACT_APP_API_BASE_URL || '';

export type ErrorWrapper<TError> =
  | TError
  | { status: 'unknown'; payload: string };

export type ApiFetcherOptions<TBody, THeaders, TQueryParams, TPathParams> = {
  url: string;
  method: string;
  body?: TBody;
  headers?: THeaders;
  queryParams?: TQueryParams;
  pathParams?: TPathParams;
} & ApiContext['fetcherOptions'];

export async function apiFetch<
  TData,
  TError,
  TBody extends {} | undefined | null,
  THeaders extends {},
  TQueryParams extends {},
  TPathParams extends {}
>({
  url,
  method,
  body,
  headers,
  pathParams,
  queryParams,
  auth0,
}: ApiFetcherOptions<
  TBody,
  THeaders,
  TQueryParams,
  TPathParams
>): Promise<TData> {
  //@ts-ignore
  try {
    let token = '';
    try {
      token = getLocalStorageValue('authToken');
    } catch (e) {
      console.error('Could not get access token silently');
      console.error(e);
    }

    const response = await window.fetch(
      `${baseUrl}${resolveUrl(url, queryParams, pathParams)}`,
      {
        method: method.toUpperCase(),
        body: body ? JSON.stringify(body) : undefined,
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_KEY || '',
          Authorization: `Bearer ${token}`,
          ...headers,
        },
      }
    );
    if (!response.ok) {
      let error: ErrorWrapper<TError>;
      try {
        error = await response.json();
      } catch (e) {
        error = {
          status: 'unknown' as const,
          payload:
            e instanceof Error
              ? `Unexpected error (${e.message})`
              : 'Unexpected error',
        };
      }

      throw error;
    }

    if (response.headers.get('content-type')?.includes('json')) {
      return await response.json();
    }
    // if it is not a json response, asume it is a blob and cast it to TData
    return (await response.blob()) as unknown as TData;
  } catch (e) {
    //@ts-ignore
    if (e?.error === AUTH0_LOGIN_REQUIRED_ERROR) {
      store.dispatch(setAuthToken('unauthenticated'));
    }
    throw e;
  }
}

const resolveUrl = (
  url: string,
  queryParams: Record<string, string> = {},
  pathParams: Record<string, string> = {}
) => {
  let query = new URLSearchParams(queryParams).toString();
  if (query) query = `?${query}`;
  return url.replace(/\{\w*\}/g, (key) => pathParams[key.slice(1, -1)]) + query;
};
