import React from 'react';
import * as Sentry from '@sentry/react';
import { useQuery } from '@apollo/client';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { Link, Typography, useTheme } from '@mui/material';
// WARNING: DO NOT just REMOVE the TEST ID
import { TEST_ID_STEP_COMPONENT_LOCATION } from 'pages/Retrieval/constants';
import { RetrievalOrigin } from 'types';
import {
  RETRIEVAL_COUNTRY_TOOLTIP_TEXT,
  SLA_COPY_TEXT_PART_ONE,
  SLA_COPY_TEXT_PART_TWO,
  SLA_TIME_FRAMES_LINK,
} from './constants';
import { FETCH_RETRIEVAL_ORIGINS } from './queries';
import { LocationStepProps } from './types';
import {
  QuestionLabelSx,
  RadioButtonSx,
  RadioGroupSx,
  SlaTimelineTextSx,
} from './styles';
import { RetrievalDestination } from 'pages/Retrieval/RetrievalService/types';
import CustomAddress from './components/CustomAddress';
import { RetrievalContext } from 'context/RetrievalServiceProvider';

const LocationStep = (props: LocationStepProps) => {
  const theme = useTheme();
  const { data, loading: isFetchingRetrievalOrigins } = useQuery(
    FETCH_RETRIEVAL_ORIGINS,
    {
      onCompleted(data) {
        const origin = props.retrievalFlow.retrievalOrigin;
        const destination = props.retrievalFlow.retrievalDestination;
        if (!!origin || !!destination) return;
        props.retrievalFlow.assignRetrievalOrigin(data.retrievalOrigins[0]);
        props.retrievalFlow.assignRetrievalDestination(
          data.retrievalOrigins[0].retrievalDepots[0]
        );
      },
      onError: (error) => {
        Sentry.captureException(error, (scope) => {
          scope.setContext('context', {
            query: JSON.stringify(FETCH_RETRIEVAL_ORIGINS),
            variables: {},
          });
          return scope;
        });
      },
    }
  );

  const retrievalOrigins = (data?.retrievalOrigins || []) as RetrievalOrigin[];

  const retrievalDepotOptionsToShow =
    props.retrievalFlow.retrievalOrigin?.retrievalDepots || [];

  const showRetrievalOriginChoices =
    retrievalOrigins.length > 0 && props.retrievalFlow.retrievalOrigin?.id;

  const showRetrievalDestinationChoices =
    retrievalDepotOptionsToShow.length > 0;

  const showCustomAddressForm =
    props.retrievalFlow.retrievalDestination?.allowCustomDepot;

  const onRetrievalOriginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOrigin = retrievalOrigins.find(
      (origin) => origin.id === e.target.value
    ) as RetrievalOrigin;

    props.retrievalFlow.assignRetrievalOrigin(selectedOrigin);

    const preSelectedRetrievalDestination = selectedOrigin.retrievalDepots[0];

    props.retrievalFlow.assignRetrievalDestination(
      preSelectedRetrievalDestination
    );
  };

  const onRetrievalDestinationChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedRetrievalDepot = retrievalDepotOptionsToShow.find(
      (depot) => depot.depotId === e.target.value
    ) as RetrievalDestination;
    props.retrievalFlow.assignRetrievalDestination(selectedRetrievalDepot);
  };

  return (
    <>
      <Container
        data-testid={TEST_ID_STEP_COMPONENT_LOCATION}
        sx={{ paddingBottom: '1rem', overflowY: 'scroll' }}
      >
        <Stack marginTop="3rem" gap="1rem">
          <>
            <Backdrop
              sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={isFetchingRetrievalOrigins}
            >
              <CircularProgress color="primary" />
            </Backdrop>
          </>
          <FormControl>
            <FormLabel
              id="origin-country-label"
              sx={QuestionLabelSx(theme)}
              color="secondary"
            >
              Where are you retrieving from?
              <span className="retrieval-country-tip">
                <Tooltip
                  title={RETRIEVAL_COUNTRY_TOOLTIP_TEXT}
                  placement="top"
                  arrow
                >
                  <InfoOutlinedIcon />
                </Tooltip>
              </span>
              <Box maxWidth="845px" mt="5px">
                <Typography sx={SlaTimelineTextSx}>
                  {SLA_COPY_TEXT_PART_ONE}
                  <Link
                    color="colors.curiousBlue"
                    href={SLA_TIME_FRAMES_LINK}
                    underline="hover"
                    rel="noopener"
                    target="_blank"
                    fontWeight="600"
                  >
                    shipping policies
                  </Link>
                  {SLA_COPY_TEXT_PART_TWO}
                </Typography>
              </Box>
            </FormLabel>
            {showRetrievalOriginChoices ? (
              <RadioGroup
                value={props.retrievalFlow.retrievalOrigin?.id}
                aria-labelledby="origin-country-label"
                name="country-origin-radio-group"
                sx={RadioGroupSx}
                onChange={onRetrievalOriginChange}
              >
                {retrievalOrigins.map((retrievalOrigin) => {
                  return (
                    <FormControlLabel
                      key={retrievalOrigin.id}
                      control={<Radio sx={RadioButtonSx(theme)} />}
                      value={retrievalOrigin.id}
                      label={retrievalOrigin.countryName}
                    />
                  );
                })}
              </RadioGroup>
            ) : null}
          </FormControl>
          <FormControl sx={{ marginTop: '2.5rem' }}>
            <FormLabel
              id="shipping-country-label"
              sx={QuestionLabelSx(theme)}
              color="secondary"
            >
              Where are you shipping to?
            </FormLabel>
            {showRetrievalDestinationChoices ? (
              <RadioGroup
                value={props.retrievalFlow.retrievalDestination?.depotId}
                aria-labelledby="shipping-country-label"
                name="country-shipping-radio-group"
                sx={RadioGroupSx}
                onChange={onRetrievalDestinationChange}
              >
                {retrievalDepotOptionsToShow.map((retrievalDepot) => {
                  return (
                    <FormControlLabel
                      key={retrievalDepot.depotId}
                      control={<Radio sx={RadioButtonSx(theme)} />}
                      value={retrievalDepot.depotId}
                      label={retrievalDepot.name}
                    />
                  );
                })}
              </RadioGroup>
            ) : null}
          </FormControl>
          {showCustomAddressForm ? (
            <CustomAddress retrievalFlow={props.retrievalFlow} />
          ) : null}
        </Stack>
      </Container>
    </>
  );
};

export const LocationStepWrapped = () => {
  const retrievalFlow = React.useContext(RetrievalContext);

  return <LocationStep retrievalFlow={retrievalFlow} />;
};

export default LocationStep;
