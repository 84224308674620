import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import NewModal from 'components/NewModal';
import QuickAssetDetails from 'pages/Assets/components/DevicesDatagrid/components/EditDeviceModal/components/QuickAssetDetails';
import { AssetImagesModalProps } from './types';
import * as styles from './styles';
import SwipeableImagesView from 'components/SwipeableImagesView';
import GAIconButton from '../buttons/google_analytics/GAIconButton';

const AssetImagesModal = (props: AssetImagesModalProps) => {
  if (!props.open || !props.asset) return null;

  const images =
    props.asset.assetImages?.map((assetImage) => assetImage.image) || [];

  return (
    <NewModal open={props.open} handleClose={props.handleClose}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="column" spacing={0}>
          <Typography variant="h5" sx={styles.ModalTitleSx}>
            Asset condition details
          </Typography>
        </Stack>
        <GAIconButton
          gaContext={{
            navigates_to: 'N/A',
            textCopy: 'Close icon',
            purpose: 'Closes Asset Images Modal',
          }}
          aria-label="close-modal-icon-button"
          onClick={props.handleClose}
          sx={{
            bgcolor: 'theme.palette.colors.gray75',
            position: 'absolute',
            right: '18px',
            top: '18px',
          }}
        >
          <CloseIcon />
        </GAIconButton>
      </Stack>
      <Stack rowGap="24px">
        <QuickAssetDetails device={props.asset} />
        <Typography variant="h6">{props.asset.cosmeticCondition}</Typography>
        <SwipeableImagesView
          images={images}
          color="info"
          mainBoxSx={{
            maxWidth: '100%',
            width: '100%',
            padding: '1rem',
            margin: '0',
          }}
          imageBoxSx={{
            maxHeight: '325px',
            maxWidth: '570px',
          }}
        />
      </Stack>
    </NewModal>
  );
};

export default AssetImagesModal;
