import { SxProps } from '@mui/material';

export const InternalActionsStackSx: SxProps = {
  maxWidth: '700px',
};

export const InternalActionsTitleSx: SxProps = {
  color: 'colors.grayWN',
  fontSize: '18px',
  lineHeight: '21px',
  ml: '16px',
};

export const InternalActionsContainerSx: SxProps = {
  marginTop: '1rem',
};

export const InternalActionsCarrierSelectionStack: SxProps = {
  flexDirection: 'row',
  columnGap: '2rem',
  justifyContent: 'flex-start',
  marginTop: '0.5rem',
  marginBottom: '1rem',
};

export const InternalActionsCarrierSelectSx: SxProps = {
  height: '34px',
};
