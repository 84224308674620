/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { GlobalState, ToastNotificationState } from 'store/types';
import { notificationSlice } from 'store/slices';
import { getGlobalNotificationSx } from './styles';
import { GLOBAL_TOAST_NOTIFICATION_TESTID } from './constants';

const GlobalToastNotification = () => {
  const {
    showNotice,
    noticeContent,
    noticeColor,
    noticeBGColor,
    hideNoticeDelay,
    verticalOrigin,
    horizontalOrigin,
  } = useSelector<GlobalState, ToastNotificationState>(
    (state) => state.notification
  );
  const dispatch = useDispatch();

  const handleClose = () => dispatch(notificationSlice.actions.reset());

  const textColor = noticeColor ?? '';
  const backgroundColor = noticeBGColor ?? '';

  if (!noticeContent) return null;

  const vertical = verticalOrigin ?? 'top';
  const horizontal = horizontalOrigin ?? 'right';

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      data-testid={GLOBAL_TOAST_NOTIFICATION_TESTID}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      open={showNotice}
      message={noticeContent}
      autoHideDuration={hideNoticeDelay}
      sx={getGlobalNotificationSx({
        textColor,
        backgroundColor,
      })}
      onClose={handleClose}
    />
  );
};

export default GlobalToastNotification;
