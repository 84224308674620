import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const HomeListItemDefaultSx: SxProps = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '8px',
  py: '12px',
  '& *[data-testid="WarningAmberIcon"]': {
    color: theme.palette.colors.jaffa,
    fontSize: '20px',
    position: 'absolute',
    right: 0,
    top: '14px',
  },
  '& .list-item-date': {
    fontFamily: 'Inter',
    color: theme.palette.colors.gray60,
  },
  '& .list-item-title': {
    color: theme.palette.colors.woodSmoke,
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '26px',
    my: '4px',
  },
  '& .list-item-details': {
    color: theme.palette.colors.gray60,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '143%',
  },
  '& .list-item-link-text': {
    color: theme.palette.colors.woodSmoke,
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '26px',
  },
  '& .MuiListItemText-root > .MuiTypography-root': {
    color: theme.palette.colors.gray60,
    display: 'list-item',
    fontSize: '14px',
    fontWeight: 400,
    listStyle: 'outside',
    ml: '16px',
  },
};

export const getSubListItemSx = (
  hasBullet: boolean,
  optionalSx?: SxProps
): SxProps => ({
  '&.MuiListItemText-root > .MuiTypography-root': {
    color: theme.palette.colors.gray60,
    display: hasBullet ? 'list-item' : 'block',
    ...(!hasBullet && { ml: 0 }),
  },
  ...optionalSx,
});
