import theme from 'themes/theme';

export const UploadHistoryActionsHeaderTextSx = {
  fontWeight: '500',
  minWidth: '100px',
};

export const UploadHistoryLookbackDropdownSx = {
  width: '50%',
  pr: '0px',
};

export const UploadHistoryLookbackDropdownButtonSx = {
  width: '150px',
  height: '36px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  px: '0px',
  borderRadius: '30px',
  hover: {
    backgroundColor: theme.palette.colors.grayF6,
  },
};

export const UploadHistoryLookbackDropdownButtonTextSx = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: '600',
  overflow: 'no-wrap',
  height: '10px',
  '&.MuiTypography-root.MuiTypography-button': {
    position: 'relative',
    top: '1px',
  },
};

export const UploadHistoryLookbackDropdownItemSx = {
  width: '210px',
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  pr: '0px',
  hover: {
    backgroundColor: theme.palette.colors.grayF6,
  },
};

export const UploadHistoryActionsHeaderSx = {
  width: 'auto',
  height: '46px',
  gap: '20%',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const SearchControlsBoxSx = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '60%',
};
