/* eslint-disable sort-exports/sort-exports */
import { useDispatch, useSelector } from 'react-redux';
import { assetManagementSlice } from 'store/slices';
import { GlobalState, AssetActionType } from 'store/types';
import DeleteDeviceModal from 'pages/Assets/components/DevicesDatagrid/components/DeleteDeviceModal';
import EditDeviceModal from 'pages/Assets/components/DevicesDatagrid/components/EditDeviceModal';
import { Asset } from 'types';
import AMDashModalContainerProps from './types';

const AMDashModalContainer = (props: AMDashModalContainerProps) => {
  const dispatch = useDispatch();
  const actionModal = useSelector<GlobalState, AssetActionType | null>(
    (state) => state.assetManagement.assetActionModal
  );

  const selectedDevice = useSelector<GlobalState, Asset | undefined>(
    (state) => state.assetManagement.selectedDevice
  );

  const enableDelete = useSelector<GlobalState, boolean>(
    (state) => state.assetManagement.enableDelete
  );

  const enableEdit = useSelector<GlobalState, boolean>(
    (state) => state.assetManagement.enableEdit
  );

  const editModalOpen = actionModal === 'edit' && enableEdit;
  const deleteModalOpen = actionModal === 'delete' && enableEdit;

  const onSuccessfulDelete = () => {
    dispatch(assetManagementSlice.actions.resetModalsReducer());
  };

  const handleAssetActionModalClose = () => {
    dispatch(assetManagementSlice.actions.closeActionModalReducer());
  };

  return (
    <>
      <DeleteDeviceModal
        open={deleteModalOpen}
        refetchSearchAssets={props.refetchQuery}
        handleClose={handleAssetActionModalClose}
        assetId={selectedDevice?.id ?? undefined}
        onSuccessfulDelete={onSuccessfulDelete}
      />
      <EditDeviceModal
        open={editModalOpen}
        refetchSearchAssets={props.refetchQuery}
        handleClose={handleAssetActionModalClose}
        device={selectedDevice ?? undefined}
      />
    </>
  );
};

export default AMDashModalContainer;
