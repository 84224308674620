import React from 'react';
//@ts-ignore
import Retool from 'react-retool';
import Container from '@mui/material/Container';
import { useSearchParams } from 'react-router-dom';
import { ContentDivSx } from './styles';
import { RETOOL_BASE_URL } from 'global-constants';

const Billing = () => {
  const [searchParams] = useSearchParams();
  const idQueryParam = searchParams.get('id');

  const url = `${RETOOL_BASE_URL}/${
    process.env.REACT_APP_RETOOL_ID_BILLING
  }?_environment=${process.env.REACT_APP_RETOOL_ENVIRONMENT}${
    idQueryParam ? `#id=${idQueryParam}` : ''
  }`;

  return (
    <Container sx={ContentDivSx}>
      <Retool width="100%" height="100%" frameborder="0" url={url} />
    </Container>
  );
};

export default Billing;
