import { gql } from '@apollo/client';

export const SEARCH_ASSETS_BY_SERIAL_NUMBER = gql`
  query searchAssets($organizationId: ID!, $serialNumber: String) {
    searchAssets(
      assetsSearchInput: {
        organizationId: $organizationId
        serialNumber: $serialNumber
      }
    ) {
      assets {
        id
        serialNumber
      }
    }
  }
`;

export const DEPRECATED_SEARCH_ASSETS_BY_SERIAL_NUMBER = gql`
  query assets($organizationId: ID!, $serialNumber: String) {
    assets(
      assetSearchInput: {
        organizationId: $organizationId
        serialNumber: $serialNumber
      }
    ) {
      assets {
        id
        serialNumber
      }
    }
  }
`;
