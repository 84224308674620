import { UploadStatus } from 'api/rest/uploads/constants';
import { stat } from 'fs';
import { RootState } from 'store';
import { getLookbackTimestamp } from 'store/shared/uploads/utils';

export const selectAssetsCSVLoadingState = (state: RootState) =>
  state.assets.csv.loadingState;

export const selectAssetsCSVURL = (state: RootState) => state.assets.csv.url;

export const selectAssetsCSVData = (state: RootState) => state.assets.csv.data;
export const selectAssetsCSVErrorMessage = (state: RootState) =>
  state.assets.csv.errorMessage;

export const selectAssetUploads = (state: RootState) =>
  state.assets.csv.upload.uploads;

export const selectAssetNewUploadCount = (state: RootState) => {
  const cutOffTimestamp = getLookbackTimestamp(
    state.assets.csv.upload.newUploadQuery.lookback
  );

  return state.assets.csv.upload.uploads.filter(
    (upload) =>
      !upload.viewed &&
      upload.status !== UploadStatus.PENDING &&
      upload.timestamp > cutOffTimestamp
  ).length;
};

export const selectAssetShowNewUploadNotification = (state: RootState) =>
  state.assets.csv.upload.showNewUploadNotification;

export const selectAssetUploadTemplateLoadingState = (state: RootState) =>
  state.assets.csv.upload.templateLoadingState;

export const selectAssetUploadFileUploadLoadingState = (state: RootState) =>
  state.assets.csv.upload.fileUploadLoadingState;

export const selectAssetUploadTemplateData = (state: RootState) =>
  state.assets.csv.upload.templateData;

export const selectAssetUploadErrorsLoadingState = (state: RootState) =>
  state.assets.csv.upload.selectedUpload.errorsLoadingState;

export const selectAssetUploadErrorsData = (state: RootState) =>
  state.assets.csv.upload.selectedUpload.errorsData;

export const selectAssetUploadViewable = (state: RootState) => {
  const { offset, limit } = state.assets.csv.upload.historicUploadQuery;
  const total = state.assets.csv.upload.uploads.length;
  return state.assets.csv.upload.uploads.slice(
    Math.min(offset, total - 1),
    Math.min(offset + limit, total)
  );
};

export const getSelectedUpload = (state: RootState) => {
  return state.assets.csv.upload.uploads.find(
    (upload) =>
      upload.timestamp === state.assets.csv.upload.selectedUpload.timestamp
  );
};

export const selectAssetUploadPopoverOpen = (state: RootState) =>
  state.assets.csv.upload.popover.open;

export const selectAssetUploadPopoverFile = (state: RootState) =>
  state.assets.csv.upload.popover.file;

export const selectAssetUploadErrorMessage = (state: RootState) =>
  state.assets.csv.upload.errorMessage;

export const selectAssetUploadHistoricUploadQuery = (state: RootState) =>
  state.assets.csv.upload.historicUploadQuery;

export const selectAssetUploadNewUploadQuery = (state: RootState) =>
  state.assets.csv.upload.newUploadQuery;

export const selectAssetUploadPopoverHistoryLookbackDropdownOpen = (
  state: RootState
) => state.assets.csv.upload.popover.history.lookbackDropdownOpen;
