import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import { AssignDeviceInfoProps } from './types';
import { BoxSx } from './styles';

const AssignDeviceInfo = (props: AssignDeviceInfoProps) => {
  return (
    <Box className="assign-device-info-box" sx={BoxSx}>
      <Stack
        className="assign-device-info-outer-stack"
        direction="row"
        justifyContent="flex-start"
      >
        <Stack direction="column">
          <Typography className="assign-device-info-header">MAKE</Typography>
          <Typography className="assign-device-info-content">
            {props.make ?? 'N/A'}
          </Typography>
        </Stack>
        <Stack direction="column">
          <Typography className="assign-device-info-header">MODEL</Typography>
          <Typography className="assign-device-info-content">
            {props.model ?? 'N/A'}
          </Typography>
        </Stack>
        <Stack direction="column">
          <Typography className="assign-device-info-header">
            SERIAL NUMBER
          </Typography>
          <Typography className="assign-device-info-content">
            {props.serialNumber ?? 'N/A'}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AssignDeviceInfo;
