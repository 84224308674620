/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import DataGrid from 'components/DataGrid';
import AMDashModalContainer from 'pages/AssetManagement/components/AMDashModalContainer';
import { assetManagementSlice } from 'store/slices';
import {
  OLD_ADMIN_GET_ASSETS,
  GET_COMPLEX_ASSET_SEARCH,
} from 'pages/AssetManagement/queries';
import { useSafeQuery } from 'hooks/useSafeQuery';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import { FeatureFlagNames } from 'enums';
import useGetAssetManagementColumns from 'pages/AssetManagement/hooks/useGetAssetManagementColumns';
import { getSortParams } from 'components/DataGrid/utils';
import { GlobalState } from 'store/types';
import {
  GridCallbackDetails,
  GridCellParams,
  GridPaginationModel,
  GridRowSelectionModel,
  useGridApiRef,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import {
  PaginationChangeReason,
  ENABLE_HORIZONTAL_SCROLLING_CLASS_NAME,
} from 'components/DataGrid/constants';
import {
  SortDirection,
  GRID_SORT_FIELD_TO_SERVER_TABLE,
  GENERIC_PAGE_SIZE_OPTIONS,
  DEFAULT_PAGINATION_MODEL,
} from 'global-constants';
import * as AMDashTableComboStyles from './styles';
import { Asset, FilterBankEntry, SortOrder } from 'types';
import AMDashTableComboProps from './types';

const AMDashTableCombo = (props: AMDashTableComboProps) => {
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const styles = AMDashTableComboStyles;

  const { isFlagOn: complexFilterOn, isFlagOff: complexFilterOff } =
    useFeatureFlagService(FeatureFlagNames.COMPLEX_ASSET_FILTERING, {
      debugFlags: true,
      forceFlagValue: true,
    });

  const filterBankEntries = useSelector<GlobalState, FilterBankEntry[]>(
    (state) => state.assetManagement.filterBankEntries
  );

  const oldFilterKey = useSelector<GlobalState, string | null>(
    (state) => state.assetManagement.oldSearchTerm
  );

  const oldFilterValue = useSelector<GlobalState, string | null>(
    (state) => state.assetManagement.oldSearchValue
  );

  //Pagination -- see Employees Datagrid for more details
  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>(DEFAULT_PAGINATION_MODEL);

  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>(() => []);
  const [rowHoveringModel, setRowHoveringModel] = React.useState(() => '');

  // Sorting
  const [devicesSortModel, setDevicesSortModel] =
    React.useState<SortOrder | null>(null);

  const handleOnRowOver = (event: any) => {
    const rowId = event.currentTarget.getAttribute('data-id');
    setRowHoveringModel(rowId);
  };

  const handleOnRowLeave = () => {
    setRowHoveringModel('');
  };

  const handleOnPaginationModelChange = (
    model: GridPaginationModel,
    details: GridCallbackDetails
  ) => {
    if (details?.reason === PaginationChangeReason.SET_PAGINATION_MODEL) {
      setPaginationModel(model);
    }
  };

  const columns = useGetAssetManagementColumns(
    rowSelectionModel,
    rowHoveringModel
  );

  /* START: Query SECTION */
  const {
    data: assetsData,
    loading: loadingAssetsMgmtData,
    refetch: refetchAdminSearchAssets,
  } = useSafeQuery(OLD_ADMIN_GET_ASSETS, {
    variables: {
      limit: paginationModel.pageSize,
      offset: `${paginationModel.page * paginationModel.pageSize}`,
      ...props.reducedFilterFields,
      ...(devicesSortModel && getSortParams(devicesSortModel)),
      ...(!devicesSortModel && {
        sort: { field: 'createdAt', direction: 'DESC' },
      }),
    },
    skip: complexFilterOn(),
    nextFetchPolicy: 'network-only',
    onError(error: any) {
      console.warn('error:\n', error);
    },
  });

  const memoSearchEntries = React.useMemo(() => {
    if (oldFilterValue && oldFilterKey) {
      return [
        {
          complexSearchTerm: oldFilterKey,
          complexSearchValues: [oldFilterValue],
        },
      ];
    }

    if (!filterBankEntries.length) return [];

    const searchFilterEntryTable: { [key: string]: any } = {};
    filterBankEntries.forEach((entry: FilterBankEntry) => {
      const { queryValue, queryField } = entry;

      searchFilterEntryTable[queryField] =
        queryField in searchFilterEntryTable
          ? [...searchFilterEntryTable[queryField], queryValue]
          : [queryValue];
    });

    return Object.keys(searchFilterEntryTable).map(
      (tempQueryField: string) => ({
        complexSearchTerm: tempQueryField,
        complexSearchValues: searchFilterEntryTable[tempQueryField],
      })
    );
  }, [filterBankEntries.length, oldFilterValue, oldFilterKey]);

  const {
    data: assetsComplexData,
    loading: loadingComplexAssetsMgmtData,
    refetch: refetchComplexSearchAssets,
  } = useSafeQuery(GET_COMPLEX_ASSET_SEARCH, {
    variables: {
      limit: paginationModel.pageSize,
      offset: paginationModel.page * paginationModel.pageSize,
      searchEntries: memoSearchEntries,
      orderDesc: 'createdAt',
    },
    skip: complexFilterOff(),
    nextFetchPolicy: 'network-only',
    onError(error: any) {
      console.warn('error:\n', error);
    },
  });

  const refetchQuery = () =>
    complexFilterOn()
      ? refetchComplexSearchAssets()
      : refetchAdminSearchAssets();

  const devices: Asset[] =
    (complexFilterOn()
      ? assetsComplexData?.getComplexAssetSearch?.assets || []
      : assetsData?.adminSearchAssets?.assets) || [];

  const totalRowCount =
    (complexFilterOn()
      ? assetsComplexData?.getComplexAssetSearch?.count || 0
      : assetsData?.adminSearchAssets?.count) || 0;

  const loadingAssetTable = complexFilterOn()
    ? loadingComplexAssetsMgmtData
    : loadingAssetsMgmtData;
  /* END: Query SECTION */

  const onRowSelectionModelChange = (ids: GridRowSelectionModel) => {
    const isRowBeingUnSelected = ids.length === 0;
    if (isRowBeingUnSelected) {
      setRowSelectionModel([]);
      dispatch(assetManagementSlice.actions.deselectDeviceReducer());
    } else {
      const selectedDeviceId = ids[ids.length - 1];
      // eslint-disable-next-line sonarjs/no-empty-collection
      const selectedDevice = devices.filter(
        // Using double equals because Asset id type is string but is number from codebase
        // Changing the type to be correct is breaking other parts of the codebase
        // TODO: Create Asset id type to be number
        //eslint-disable-next-line eqeqeq
        (device: Asset) => device.id == selectedDeviceId.toString()
      )[0];
      setRowSelectionModel([selectedDeviceId]);
      dispatch(
        assetManagementSlice.actions.selectDeviceReducer(selectedDevice)
      );
    }
  };

  const handleSortModelChange = (
    sortModel: GridSortModel,
    _callbackDetails: GridCallbackDetails
  ): void => {
    if (!sortModel.length) {
      setDevicesSortModel(null);
      return;
    }
    const { field, sort: sortDirection } = sortModel[0];

    const serverSortDirection = GRID_SORT_FIELD_TO_SERVER_TABLE[
      sortDirection as keyof typeof GRID_SORT_FIELD_TO_SERVER_TABLE
    ] as SortDirection;

    setDevicesSortModel({
      direction: serverSortDirection,
      field,
    });
  };

  const handleOnCellClick = (params: GridCellParams) => {
    if (params.id === rowSelectionModel[0]) {
      dispatch(assetManagementSlice.actions.toggleEditButtonReducer(false));
      dispatch(assetManagementSlice.actions.toggleDeleteButtonReducer(false));
      onRowSelectionModelChange([]);
    } else {
      onRowSelectionModelChange([params.id]);
      dispatch(assetManagementSlice.actions.toggleEditButtonReducer(true));
      dispatch(assetManagementSlice.actions.toggleDeleteButtonReducer(true));
    }
  };

  return (
    <>
      <Box sx={styles.AMDashDataGridBoxSx}>
        <DataGrid
          apiRef={apiRef}
          rows={devices}
          rowCount={totalRowCount}
          columns={columns}
          autoHeight={false}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={handleOnPaginationModelChange}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          disableMultipleColumnsSorting
          checkboxSelection
          rowSelectionModel={rowSelectionModel}
          onCellClick={handleOnCellClick}
          loading={loadingAssetTable}
          pageSizeOptions={GENERIC_PAGE_SIZE_OPTIONS}
          disableMultipleRowSelection
          hideFooterSelectedRowCount
          keepNonExistentRowsSelected
          slotProps={{
            panel: {
              placement: 'bottom-end',
            },
            filterPanel: {
              sx: { visibility: 'hidden' },
            },
            row: {
              onMouseOver: handleOnRowOver,
              onMouseLeave: handleOnRowLeave,
            },
          }}
          classes={{
            virtualScroller: ENABLE_HORIZONTAL_SCROLLING_CLASS_NAME,
          }}
        />
      </Box>
      <AMDashModalContainer refetchQuery={refetchQuery} />
    </>
  );
};

export default AMDashTableCombo;
